/** @format */

/** @format */

import { ID, Response } from "../../../../../../theme/helpers";
export type User = {
	id?: ID;
	name?: string;
	username?: string;
	avatar?: string;
	email?: string;
	position?: string;
	role?: string;
	last_login?: string;
	two_steps?: boolean;
	joined_day?: string;
	online?: boolean;
	initials?: {
		label: string;
		state: string;
	};
};
export type Source = {
	draftId?: string;
	docName?: string;
	fileName?: string;
	noOfResponses?: string | null;
	noOfPages?: string | null;
	createdBy?: string | null;
	createdDate?: string;
};
export type SourceQueryResponse = Response<Array<Source>>;

export const initialSource: Source = {
	draftId: "4",
	docName: "",
	fileName: "Sample.pdf",
	noOfResponses: null,
	noOfPages: null,
	createdBy: null,
	createdDate: "1/16/23 11:57 ,AM",
};

export type Doc = {
	documentType?: string | null;
	draftId?: string | null;
	subject?: string | null;
	documentName?: string | null;
	participantId?: string | null;
	organisationId?: string | null;
};

export type RULE = {
	masterRuleId: string | null;
	name: string | null;
	rule: string | null;
	status: string | null;
	createdBy: string | null;
	updatedBy: string | null;
	createdDate: string | null;
	updatedDate: string | null;
	suggestion: string | null;
};

export type Responses = {
	fileName: string;
	pages: string | number | null;
	assignedTo: string | null;
	submittedBy: string;
	submittedOn: string;
	status: string;
};

export type UsersQueryResponse = Response<Array<User>>;
export type ResponseQuery = Array<Responses>;

export const initialUser: User = {
	avatar: "avatars/300-6.jpg",
	position: "Art Director",
	role: "Administrator",
	name: "",
	email: "",
};

export type AddSubmission = {
	id?: ID;
	participants?: any;
	organisation?: any;
	file?: any;
};

export type AddAnnotation = {
	documentPageId?: any;
	documentId?: any;
	lineNumberFrom?: number;
	charFrom?: number;
	lineNumberTo?: number;
	charTo?: number;
	selectedText?: any;
	fieldName?: any;
	fieldText?: any;
	type?: any;
};
