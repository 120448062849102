/** @format */

import {
	FC,
	useState,
	useEffect,
	createContext,
	useContext,
	useRef,
	Dispatch,
	SetStateAction,
} from "react";
import { LayoutSplashScreen } from "../../../../theme/layout/core";
import { AuthModel, UserModel } from "./_models";
import * as authHelper from "./AuthHelpers";
import { getUserByToken } from "./_requests";
import { WithChildren } from "../../../../theme/helpers";

type AuthContextProps = {
	auth: AuthModel | undefined;
	saveAuth: (auth: AuthModel | undefined) => void;
	currentUser: UserModel | undefined;
	currentUsers: UserModel | undefined;
	current: UserModel | undefined;
	setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
	setCurrentUsers: Dispatch<SetStateAction<UserModel | undefined>>;
	setCurrent: Dispatch<SetStateAction<UserModel | undefined>>;


	logout: () => void;
	breadCrumb: String | undefined;
	breadCrumbs: String | undefined;
	bread: String | undefined;
	setBreadCrumb: Dispatch<SetStateAction<String | undefined>>;
	setBreadCrumbs: Dispatch<SetStateAction<String | undefined>>;
	setBread: Dispatch<SetStateAction<String | undefined>>;
	indexing: number | undefined;
	setIndexes: Dispatch<SetStateAction<number | undefined>>;
};

const initAuthContextPropsState = {
	auth: authHelper.getAuth(),
	saveAuth: () => {},
	currentUser: undefined,
	setCurrentUser: () => {},
	currentUsers: undefined,
	setCurrentUsers: () => {},
	current:undefined,
	setCurrent:()=>{},
	logout: () => {},
	breadCrumb: "",
	breadCrumbs:"",
	bread:"",
	setBreadCrumb: () => {},
	setBreadCrumbs: () => {},
	setBread: () => {},
	indexing: 0,
	setIndexes: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
	return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
	const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
	const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
	const [currentUsers, setCurrentUsers] = useState<UserModel | undefined>();
	const [current, setCurrent] = useState<UserModel | undefined>();


	const [breadCrumb, setBreadCrumb] = useState<String | undefined>();
	const [breadCrumbs, setBreadCrumbs] = useState<String | undefined>();
	const [bread, setBread] = useState<String | undefined>();

	const [indexing, setIndexes] = useState<number | undefined>();

	const saveAuth = (auth: AuthModel | undefined) => {
		setAuth(auth);
		if (auth) {
			authHelper.setAuth(auth);
		} else {
			authHelper.removeAuth();
		}
	};

	const logout = () => {
		saveAuth(undefined);
		setCurrentUser(undefined);
		setCurrentUsers(undefined)
		setCurrent(undefined)
		
	};

	return (
		<AuthContext.Provider
			value={{
				auth,
				saveAuth,
				currentUser,
				setCurrentUser,
				currentUsers,
				setCurrentUsers,
				current,
				setCurrent,
				logout,
				breadCrumb,
				breadCrumbs,
				bread,
				setBreadCrumb,
				setBreadCrumbs,
				setBread,
				indexing,
				setIndexes,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

const AuthInit: FC<WithChildren> = ({ children }) => {
	const { auth, logout, setCurrentUser, currentUser } = useAuth();
	const didRequest = useRef(false);
	const [showSplashScreen, setShowSplashScreen] = useState(true);
	// We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
	useEffect(() => {
		const requestUser = async (apiToken: string) => {
			try {
				if (!didRequest.current) {
					const { data } = await getUserByToken(apiToken);

					if (data) {
						setCurrentUser(data);
					}
				}
			} catch (error) {
				console.error(error);
				if (!didRequest.current) {
					logout();
				}
			} finally {
				setShowSplashScreen(false);
			}

			return () => (didRequest.current = true);
		};

		if (auth && auth.token) {
			requestUser(auth.token);
		} else {
			logout();
			setShowSplashScreen(false);
		}
		// eslint-disable-next-line
	}, []);

	return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
