/**
 * /* eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

import { Fragment, useEffect, useState } from "react";
import { KTCard, KTSVG } from "../../../../theme/helpers";
import { getCampaignHighlights } from "../../../modules/application/_requests";
import { useQuery } from "react-query";
import { useParams, useLocation } from "react-router-dom";
import { IoSparkles } from "react-icons/io5";
import { BsExclamationCircle, BsTag } from "react-icons/bs";
import CampaignType from "./CampaignType";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { IoMdLink } from "react-icons/io";
import CheckBox from "./CheckBox";
import warning from "../../../../assets/baseline-warning.png";
import CustomQuestion from "./customQuestion";

type Props = {
    className: string;
};

const MerchantLink = ({ className }: Props) => {

    const [showCampaignType, setShowCampaignType] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const handleClick = () => {
        setShowCampaignType(true);
    };

    return (
        <>




            <div className="container px-10 mt-10 pt-7 pb-10" style={{ backgroundColor: "white", boxShadow: '0 2px 8px rgba(0, 0, 0, 0.5)', borderRadius: '10px' }}>

                {/* <div className="row gy-5 gx-xl-10 mb-10"> */}

                <div className="card-body">
                    <div className="row gx-xl-12 gy-10">
                        <div className="col-xl-8">
                            {/* <KTCard className="card h-lg-100 col-xxl-12"> */}
                            <div
                                className="container p-1"
                                style={{
                                    backgroundColor: "white",
                                    borderRadius: "10px",
                                }}
                            >
                                <div className="row gy-5 gx-xl-10">
                                    <div className="d-flex align-items-center">

                                        <span
                                            className="d-flex"
                                            style={{ fontSize: "14px", color: '#3c4043', fontFamily: 'Arial' }}
                                        >
                                            Select a linked account with products to advertise in this campaign 
                                            <span className="" style={{marginLeft:'5px'}}><CustomQuestion>Select the linked Merchant Center account that will
                                                 provide the products to advertise. Each campaign can only have
                                                  one linked account for products, and it can't be changed 
                                                  after you've finished creating the campaign.</CustomQuestion> </span> 
                                        </span>
                                    </div>
                                    <div className=" d-flex">
                                        <img src={warning} style={{ marginRight: '2px' }} /> <div className=" text-muted d-flex" 
                                        style={{ fontSize: "13px", fontFamily: 'Arial', color:'#5F6368' }}>No linked accounts</div>
                                    </div>
                                    <div style={{ marginTop: '-10px' }}> <hr style={{ margin: "0, 10px", 
                                    border: "1px solid #CCCCCC", width: "50%" }} /></div>
                                    <div
                                        className="d-flex"
                                        style={{ fontSize: "14px", color: '#3c4043', fontFamily: 'Arial' }}
                                    >
                                        All products from the selected account will be available to advertise in this campaign.
                                        <div style={{ fontSize: "14px", color: 'rgba(60,64,67,.38)', fontFamily: 'Arial',
                                         marginLeft:'10px', cursor:'not-allowed' }}> Select specific feeds</div>
                                    </div>
                                </div>
                            </div>
                            {/* </KTCard> */}

                        </div>
                        
                        <div className="col-xl-4 pl-0" style={{borderLeft: "1px solid #CFD0D0",}}>
                            <div className=" d-flex">
                                <img src={warning} style={{ marginRight: '10px',height:'25px', width:'25px' }} />
                                <div className=" text text-bold d-flex" style={{ fontSize: "14px", color: '#3c4043',
                                 fontFamily: 'Arial'  }}><b>
                               Before you go on</b></div></div> <div className=" text" style={{ fontSize: "14px",
                                color: '#3c4043', fontFamily: 'Arial', marginLeft:'35px'  }}>
                                You'll need to link your Merchant Center account with your product data.
                                 Send the link request from Merchant Center.
                                <div><u style={{color:'#009EF7'}}><a href="#"> Learn more</a></u></div>
                            </div> 
                        </div>

                    </div>

                </div>

            </div>

            {/* </div> */}


            {/* <div className="">
        {!showCampaignType && (
          <div className="gap-2" style={{ position: "absolute", right: 30 }}>
            <a
              href="#"
              className="btn btn-sm fw-bold btn-color-gray-700 btn-active-color-primary"
            >
              Cancel
            </a>
            <a
              href="#"
              className="btn btn-sm fw-bold btn-primary"
              onClick={handleClick}
            >
              Continue
            </a>
          </div>
        )}
        {showCampaignType && <CampaignType />}
      </div> */}
        </>
    );
};
export default MerchantLink;
