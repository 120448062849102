/**
 * /* eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

/** @format */

import React, { FC, useEffect, useState, useRef } from "react";
import { KTCard } from "../../../../theme/helpers";
import facebook from "../../../../assets/facebook.png";
import youtube from "../../../../assets/youtube.png";
import instagram from "../../../../assets/instagram.png";
import google from "../../../../assets/google.png";
import linkedin from "../../../../assets/linkedin.png";
import twitter from "../../../../assets/twitter.png";
import camper from "../../../../assets/camper.png";
import "./AllCampaign.scss";
// import "../../../../theme/assets/sass/core/vendors/plugins/_select2.scss";
import ChangeDateFormat from "../Custom_Functions/ChangeDateFormat";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import {
  editCampaign,
  getAllCampaigns,
  getAllListing,
} from "../../../modules/application/_requests";
import RandomColorGen from "../Custom_Functions/RandomColorGen";
import "./dropdownListing.css";
import "./Select.scss";
import { useAuth } from "../../../modules/auth";
import { FaExpand, FaGreaterThan, FaPlus } from "react-icons/fa";
import {
  MdContentCopy,
  MdOutlineContentCopy,
  MdOutlineFilterAlt,
  MdSegment,
} from "react-icons/md";
import {
  BsChevronDown,
  BsChevronUp,
  BsDot,
  BsFillCheckCircleFill,
  BsFillCircleFill,
  BsPlusLg,
} from "react-icons/bs";
import {
  AiFillAlert,
  AiFillEye,
  AiFillFolder,
  AiFillPauseCircle,
  AiFillPlayCircle,
  AiOutlineLeft,
  AiOutlinePlus,
  AiOutlineRight,
} from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { BsFileBarGraph, BsLayoutThreeColumns } from "react-icons/bs";
import {
  BiCircleThreeQuarter,
  BiDotsVerticalRounded,
  BiDownArrowAlt,
  BiDownload,
  BiFolder,
} from "react-icons/bi";
import {
  IoIosCloseCircle,
  IoMdArrowDropdown,
  IoMdSettings,
} from "react-icons/io";
import Edit from "./Edit";
import CampaignName from "./CampaignName";
import Budget from "./Budget";
import Dates from "./Dates";
import Location from "./Location";
import { IoClose, IoPencil, IoSettingsOutline } from "react-icons/io5";
import CustomQuestion from "./customQuestion";
import { FiSettings } from "react-icons/fi";
import { HiCheckCircle, HiPencil } from "react-icons/hi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import StartNewModal from "./StartNewModal";

const Listing: React.FC = () => {
  const { setBreadCrumb } = useAuth();
  const queryClient = useQueryClient();

  const {
    isLoading,
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    [`GET_ALL_LIST`],
    () => {
      return getAllListing();
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setTimeout(() => {
          // refetch(); // Call the refetch function to fetch the updated data after 2 seconds
        }, 5000);
      },
    }
  );
  console.log(response, "listing");
  const dataWithBudget = response?.filter((item) => item.budget);

  console.log(dataWithBudget, "data with budget");
  const itemsPerPage = 5;
  const totalPages = Math.ceil(dataWithBudget?.length / itemsPerPage);

  const [currentPage, setCurrentPage] = useState(1);
  const [clickedNextPage, setClickedNextPage] = useState(false);
  const [clickedPrevPage, setClickedPrevPage] = useState(false);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      setClickedNextPage(true);
      setClickedPrevPage(false);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      setClickedPrevPage(true); // Mark the "Previous Page" button click
      setClickedNextPage(false);
    }
  };
  useEffect(() => {
    setClickedNextPage(false);
    setClickedPrevPage(false);
  }, [currentPage]);

  // Calculate the startIndex and endIndex for the data to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, dataWithBudget?.length);
  const visibleData = dataWithBudget?.slice(startIndex, endIndex);
  console.log(visibleData, "visible");
  const navigate = useNavigate();

  interface campaignList {
    id: string;
    name: string;
    tag: string;
    createdDate: string;
    associatedChannel: string[];
    target: string;
    startDate: string;
    endDate: string;
    stage: string;
    status: string;
    suggestion: string;
  }
  const [completed, setCompleted] = useState<campaignList[] | undefined>([]);
  const popupRef = useRef<HTMLDivElement>(null);
  const [pending, setPending] = useState<campaignList[] | undefined>([]);
  const [showData, setShowData] = useState<campaignList[] | undefined>([]);
  const [selectedTab, setSelectedTab] = useState<string>("All Campaigns");
  const [type, setType] = useState<string>("Show All");
  const [status, setStatus] = useState<string>("Show All");
  const [filtered, setFiltered] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPop, setShowPop] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showResume, setShowResume] = useState(false);
  const [showLoad, setShowLoad] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [campaignValue, setCampaignValue] = useState("");
  const [budgetValue, setBudgetValue] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDropClickOutside);
    return () => {
      document.removeEventListener("click", handleDropClickOutside);
    };
  }, []);

  const [selectedCampaignId, setSelectedCampaignId] = useState();
  const handleSettingsIconClick = (campaignId) => {
    setSelectedCampaignId(campaignId);
    setShowDrawer(true);
  };
  console.log(selectedCampaignId, "selectedCampaignId");
  // console.log(response?.id,"response.id")

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownRefsFilter = useRef<HTMLDivElement>(null);

  const Stages = ["All Campaigns", "Pending", "Completed"];
  const handleAddInput = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  // const DrawerOpener = () => {
  //   setShowDrawer(true);
  // };
  let cardImage: JSX.Element | null = null;
  cardImage = <img src={camper} alt="Sales" style={{ maxWidth: '20px', maxHeight: '20px' }} />;

  const addNew = () => {
    navigate("/newCampaign");
  };

  // dunnoooo
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(false);
  const [isExpanded4, setIsExpanded4] = useState(false);
  const [isExpanded5, setIsExpanded5] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [dropHover, setdropHover] = useState(false);

  const [hoveredOptionIndex, setHoveredOptionIndex] = useState(null);
  const [selectedOptionIndex, setselectedOptionIndex] = useState(null);
  // const [selectedOptionIndex, setselectedOptionIndex] = useState(item?.status);

  // const dropdownRef = useRef<HTMLDivElement>(null);
  const divStyles = {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    width: "150px",
    height: "50px",
    borderRadius: "5px",
    border: "1px solid #80868B",
    cursor: "pointer",
    color: "#3c4043",
    outline: "none",
    transition: "border-color 20s ease-in-out",
    borderColor: showOptions ? "#196DDC" : "#80868B",
    borderWidth: showOptions ? "2px" : "1px",
  };

  const scrollContainerStyle = {
    scrollbarWidth: "10px",
    scrollbarColor: "#888 #f1f1f1",
    /* Add any other desired styles */
  };

  const options = [
    { value: "Live Now", label: "Live Now" },
    { value: "Paused", label: "Paused" },
    { value: "Reviewing", label: "Reviewing" },
    { value: "Completed", label: "Completed" },
  ];

  let cardIcon: JSX.Element | null = null;

  // const [type, setType] = useState<string>("Conversions");
  // const [status, setStatus] = useState<string>("Conversions");

  const [isChecked2, setIsChecked2] = useState(true);

  const [showInputBox, setShowInputBox] = useState(false);
  // const [showPopup, setShowPopup] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [hoveredLanguage, setHoveredLanguage] = useState("");
  const [headerColor, setHeaderColor] = useState("");
  const [headerColor2, setHeaderColor2] = useState("");
  const [headerColor4, setHeaderColor4] = useState("");
  const [campaignId, setCampaignId] = useState("");
  let closeDropdownTimeout;

  const [openedDropdownRows, setOpenedDropdownRows] = useState([]);

  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [showDropdown, setShowDropdown] = useState(false);

  const [hover, setHover] = useState(-1);
  const handleMouseEnter = (index) => {
    setHover(index);
    if (selectedRowIndex !== index) {
      setShowDropdown(false);
      setshowEditModal(false);
      setshowEditBudgetModal(false);
    }
    setSelectedRowIndex(index);
  };

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    setHover(-1);
  };

  const handleMouseEnter3 = () => {
    setShowPop(true);
  };

  const handleMouseLeave3 = () => {
    setShowPop(false);
  };

  const handleMouseEnter4 = () => {
    setShowNew(true);
  };

  const handleMouseLeave4 = () => {
    setShowNew(false);
  };
  const handleMouseEnter5 = () => {
    setShowResume(true);
  };

  const handleMouseLeave5 = () => {
    setShowResume(false);
  };

  const handleMouseEnter6 = () => {
    setShowLoad(true);
  };

  const handleMouseLeave6 = () => {
    setShowLoad(false);
  };

  // const handleDropClick = (index) => {
  //   if (selectedRowIndex === index) {
  //     setShowDropdown(!showDropdown);
  //   } else {
  //     setSelectedRowIndex(index);
  //     setShowDropdown(true);
  //   }
  // };

  const [editValue, setEditValue] = useState("");
  const [showEditModal, setshowEditModal] = useState(false);
  const [editBudgetValue, setEditBudgetValue] = useState("");
  const [showEditBudgetModal, setshowEditBudgetModal] = useState(false);

  const EditBudgetModalClick = (id) => {
    setshowEditBudgetModal(!showEditBudgetModal);
    setSelectedId(id); // Set the id in state
  };

  const EditBudgetModalCancel = () => {
    setshowEditBudgetModal(false);
    // setEditValue(item?.name);
  };

  // Function to handle the save button click
  const EditBudgetModalsave = (item) => {
    console.log("aaaaaaaaaaaaaaaaae", item);

    // Perform save logic here
    const save = {
      id: selectedId,
      status: item.status,
      name: item.name,
      budget: budgetValue,
      budgetGoal: item.budgetGoal,
      targetLocation: item.targetLocation,
      startdate: item.startdate,
      endDate: item.endDate,
      target: item.target,
      stage: item.stage,
      actualCpg: item.actualCpg,
      targetCpg: item.targetCpg,
      campaignObjective: item.campaignObjective,
      campaignGoal: item.campaignGoal,
      campaignType: item.campaignType,
      reachGoalName: item.reachGoalName,
      reachGoalValue: item.reachGoalValue,
      campaignAdsRotation: item.campaignAdsRotation,
      websiteUrl: item.websiteUrl,
      websiteUrlSuffix: item.websiteUrlSuffix,
      // targetLocation:camp?.name,
      targetLanguage: item.targetLanguage,
      tag: item.tag,
      createdBy: null,
      updatedBy: item.updatedBy,
      campaignBiddingFocusOn: item.campaignBiddingFocusOn,
      campaignBiddingFocusOnValue: item.campaignBiddingFocusOnValue,
      campaignCustomerAcquisitionValue: item.campaignCustomerAcquisitionValue,
      campaignNetwork: item.campaignNetwork,
      audienceSegment: item.audienceSegment,

      adsNoOfDays: item.adsNoOfDays,
      adsStartTime: item.adsStartTime,
      adsEndTime: item.adsEndTime,

      urlName: item.urlName,
      urlValue: item.urlValue,
      scanUrl: item.scanUrl,
      productServices: item.productServices,
      searchKeywords: item.searchKeywords,
      createdDate: "2023-04-01 19:36:48",
      updatedDate: item.updatedDate,
    };
    console.log(save, "ppppppp");
    const res = editCampaign(save)
      .then((res) => {
        console.log("Response Updated Successfully");
        console.log(res, "mmmmmmmmm");
        queryClient.refetchQueries(["GET_ALL_LIST"]);
      })
      .catch((err) => {
        alert("Request Failed!");
      });

    setshowEditBudgetModal(false);
    // name: campaignValue !== "" ? campaignValue : camp?.name,
  };

  // const [selectedOptionIndex, setselectedOptionIndex] = useState(item?.status);

  const EditModalClick = (id) => {
    setshowEditModal(!showEditModal);
    setSelectedId(id); // Set the id in state
  };

  const EditModalCancel = () => {
    setshowEditModal(false);
    // setEditValue(item?.name);
  };

  // Function to handle the save button click
  const EditModalsave = (item) => {
    console.log("aaaaaaaaaaaaaaaaae", item);

    // Perform save logic here
    const save = {
      id: selectedId,
      status: item.status,
      name: campaignValue,
      budget: item.budget,
      budgetGoal: item.budgetGoal,
      targetLocation: item.targetLocation,
      startdate: item.startdate,
      endDate: item.endDate,
      target: item.target,
      stage: item.stage,
      actualCpg: item.actualCpg,
      targetCpg: item.targetCpg,
      campaignObjective: item.campaignObjective,
      campaignGoal: item.campaignGoal,
      campaignType: item.campaignType,
      reachGoalName: item.reachGoalName,
      reachGoalValue: item.reachGoalValue,
      campaignAdsRotation: item.campaignAdsRotation,
      websiteUrl: item.websiteUrl,
      websiteUrlSuffix: item.websiteUrlSuffix,
      // targetLocation:camp?.name,
      targetLanguage: item.targetLanguage,
      tag: item.tag,
      createdBy: null,
      updatedBy: item.updatedBy,
      campaignBiddingFocusOn: item.campaignBiddingFocusOn,
      campaignBiddingFocusOnValue: item.campaignBiddingFocusOnValue,
      campaignCustomerAcquisitionValue: item.campaignCustomerAcquisitionValue,
      campaignNetwork: item.campaignNetwork,
      audienceSegment: item.audienceSegment,

      adsNoOfDays: item.adsNoOfDays,
      adsStartTime: item.adsStartTime,
      adsEndTime: item.adsEndTime,

      urlName: item.urlName,
      urlValue: item.urlValue,
      scanUrl: item.scanUrl,
      productServices: item.productServices,
      searchKeywords: item.searchKeywords,
      createdDate: "2023-04-01 19:36:48",
      updatedDate: item.updatedDate,
    };
    console.log(save, "ppppppp");
    const res = editCampaign(save)
      .then((res) => {
        console.log("Response Updated Successfully");
        console.log(res, "mmmmmmmmm");
        queryClient.refetchQueries(["GET_ALL_LIST"]);
      })
      .catch((err) => {
        alert("Request Failed!");
      });

    setshowEditModal(false);
    // name: campaignValue !== "" ? campaignValue : camp?.name,
  };

  // const handleMouseLeave = () => {
  //   setHover(false);
  // };
  const handleFilter = () => {
    setShowPopup(true);
  };

  const handleDay = () => {
    navigate("/listing");
  };

  const handleDropEnter = (index) => {
    setdropHover(true);
    setHoveredOptionIndex(index);
  };

  const handleDropLeave = () => {
    setdropHover(false);
    setHoveredOptionIndex(null);
  };

  const handleTogglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleMouseEnter1 = (language) => {
    setHoveredLanguage(language);
  };

  const handleMouseLeave1 = () => {
    setHoveredLanguage("");
  };

  const handleToggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  const handleToggleContent2 = () => {
    setIsExpanded2(!isExpanded2);
  };

  const handleToggleContent3 = () => {
    setIsExpanded3(!isExpanded3);
  };
  const handleToggleContent4 = () => {
    setIsExpanded4(!isExpanded4);
  };
  //sorting function

  //newest to oldest
  function sortObjectsByDate(
    objects: campaignList[] | undefined,
    type: string
  ) {
    console.log(objects, "vvvvvvv");
    return objects?.sort((a, b) => {
      const dateA = new Date(a?.createdDate);
      const dateB = new Date(b?.createdDate);

      return type?.toLowerCase() === "newest"
        ? dateB?.getTime() - dateA?.getTime()
        : type?.toLowerCase() === "oldest"
          ? dateA?.getTime() - dateB?.getTime()
          : 0;
    });
  }

  const ids = response?.map((item) => item.id);
  console.log(ids, "kkkkkkkkkkkk");

  const stat = response?.map((item) => item.status);
  console.log(stat, "kooooooooo");

  const camp = response?.find((item) => item.id === selectedCampaignId);
  console.log(camp, "camppppppppp");
  const status_data = [
    {
      name: "Status",
      dropdownData: [
        "Budget",
        "Budget Goal",
        "Status",
        // "Tag",
        "Target Language",
        "Target Location",
        "Start Date",
        "End Date",
        "Target",
        "Stage",
        "Actual CPG",
        "Target CPG",
        "Campaign Objective",
        "Campaign Goal",
        "Campaign Type",
        "Reach Goal Name",
        "Reach Goal Value",
        "Network",
        "Audience Segment",
        "Ads Rotation",
        "Website URL",
        "Suffix",
        "URL Name",
        "URL Value",
        "Scan",
        "Product Services",
        "Search Keywords",
        // "No of Days",
        // "Start Time",
        // "End Time",

        // "Create Date",
      ],
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsPopupOpen(false);
        // setShowPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRefsFilter.current &&
        !dropdownRefsFilter.current.contains(event.target as Node)
      ) {
        // setIsPopupOpen(false);
        setShowPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClick = (
    name: string | undefined,
    id: string | undefined,
    status: string | undefined
  ) => {
    setBreadCrumb(name);
    if (status != "reviewing") {
      navigate(`/dashboard/${id}`, { state: { name, id } });
    }
    // navigate("/detail",  { state: { id: item.id } })
  };

  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const filteredData = status_data.map((item) => {
    const filteredItem = {
      dropdownData: item.dropdownData.filter((column) =>
        selectedColumns.includes(column)
      ),
    };
    return filteredItem;
  });
  // Initialize the selectedColumns state with all available columns
  useEffect(() => {
    const allColumns = status_data[0]?.dropdownData || [];
    setSelectedColumns(allColumns);
  }, []);

  console.log(filteredData, "filteredData");
  const handleColumnCheckboxChange = (column) => {
    setSelectedColumns((prevSelectedColumns) => {
      if (prevSelectedColumns.includes(column)) {
        return prevSelectedColumns.filter((col) => col !== column);
      } else {
        return [...prevSelectedColumns, column];
      }
    });
  };
  const [selectedId, setSelectedId] = useState(null);

  const handleDropdownClick = (id) => {
    setShowDropdown(!showDropdown);
    setSelectedId(id); // Set the id in state
  };

  console.log(selectedId, "showwwwwwwwww");
  const [selectedLabel, setSelectedLabel] = useState({
    value: "",
    label: "",
  });

  // let extractedName = "";
  // let location = "";

  // const itemId = selectedId; // Replace with the desired id
  // const item = response?.find((item) => item.id === itemId);
  // if (item) {
  //   // Extract the fields from the item object
  //   const {
  //     name,
  //     targetLocation,
  //     startdate,
  //     budget,
  //     endDate,
  //     target,
  //     budgetGoal,
  //     stage,
  //     tag,
  //     createdBy,
  //     updatedBy,
  //     actualCpg,
  //     targetCpg,
  //     campaignObjective,
  //     campaignGoal,
  //     campaignType,
  //     reachGoalName,
  //     reachGoalValue,
  //     campaignBiddingFocusOn,
  //     campaignBiddingFocusOnValue,
  //     campaignCustomerAcquisitionValue,
  //     campaignNetwork,
  //     audienceSegment,
  //     campaignAdsRotation,
  //     adsNoOfDays,
  //     adsStartTime,
  //     adsEndTime,
  //     websiteUrl,
  //     websiteUrlSuffix,
  //     urlName,
  //     urlValue,
  //     scanUrl,
  //     productServices,
  //     searchKeywords,
  //     createdDate,
  //     updatedDate,
  //   } = item;
  //   extractedName = name;
  //   location=targetLocation
  // } else {
  //   console.log("Item not found");
  // }

  // console.log(extractedName, "xxxxxxxx");

  // console.log(location);

  const handleOptionChange = (option, index, item) => {
    console.log(item, "bvcvcbv");
    setSelectedLabel(option);
    // setSelectedaOption(option);
    setShowOptions(false);
    setShowDropdown(false);
    setdropbg(true);
    setselectedOptionIndex(index);
    const save = {
      id: selectedId,
      status: option?.value || camp?.status,
      name: item.name,
      budget: item.budget,
      budgetGoal: item.budgetGoal,
      targetLocation: item.targetLocation,
      startdate: item.startdate,
      endDate: item.endDate,
      target: item.target,
      stage: item.stage,
      actualCpg: item.actualCpg,
      targetCpg: item.targetCpg,
      campaignObjective: item.campaignObjective,
      campaignGoal: item.campaignGoal,
      campaignType: item.campaignType,
      reachGoalName: item.reachGoalName,
      reachGoalValue: item.reachGoalValue,
      campaignAdsRotation: item.campaignAdsRotation,
      websiteUrl: item.websiteUrl,
      websiteUrlSuffix: item.websiteUrlSuffix,
      // targetLocation:camp?.name,
      targetLanguage: item.targetLanguage,
      tag: item.tag,
      createdBy: null,
      updatedBy: item.updatedBy,
      campaignBiddingFocusOn: item.campaignBiddingFocusOn,
      campaignBiddingFocusOnValue: item.campaignBiddingFocusOnValue,
      campaignCustomerAcquisitionValue: item.campaignCustomerAcquisitionValue,
      campaignNetwork: item.campaignNetwork,
      audienceSegment: item.audienceSegment,

      adsNoOfDays: item.adsNoOfDays,
      adsStartTime: item.adsStartTime,
      adsEndTime: item.adsEndTime,

      urlName: item.urlName,
      urlValue: item.urlValue,
      scanUrl: item.scanUrl,
      productServices: item.productServices,
      searchKeywords: item.searchKeywords,
      createdDate: "2023-04-01 19:36:48",
      updatedDate: item.updatedDate,
    };
    // console.log(save, "vvvvvvvvvvvvvvvvvvv");
    const res = editCampaign(save)
      .then((res) => {
        console.log("Response Updated Successfully");
        console.log(res, "mmmmmmmmm");
        queryClient.refetchQueries(["GET_ALL_LIST"]);
      })
      .catch((err) => {
        alert("Request Failed!");
      });
  };
  const [dropbg, setdropbg] = useState(false);

  console.log(filteredData, "heyyyyyyyy");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleAllClear = () => {
    setSelectedColumns([]); // Clear the selectedColumns state array
  };

  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [sortDirection, setSortDirection] = useState("asc");
  const [sortedData, setSortedData] = useState(filteredData); // Assuming filteredData is your initial data

  // const handleSortCampaign = () => {
  //   // Toggle the sort direction
  //   const newSortDirection = sortDirection === "asc" ? "desc" : "asc";
  //   setSortDirection(newSortDirection);

  //   // Sort the data based on the campaign name
  //   const sortedCampaigns = [...response]; // Make a copy of the data
  //   sortedCampaigns.sort((a, b) => {
  //     const campaignA = a.name ? a.name.toLowerCase() : ""; // Default to empty string if name is null or undefined
  //     const campaignB = b.name ? b.name.toLowerCase() : ""; // Default to empty string if name is null or undefined

  //     if (newSortDirection === "asc") {
  //       return campaignA.localeCompare(campaignB);
  //     } else {
  //       return campaignB.localeCompare(campaignA);
  //     }
  //   });

  //   setSortedData(sortedCampaigns);
  //   console.log(sortedCampaigns, "filteredData");
  // };

  const [isRotated, setIsRotated] = useState(false);
  const [showArrowCampaign, setShowArrowCampaign] = useState(false);
  const [showArrowBudget, setShowArrowBudget] = useState(false);

  const handleSortCampaign = () => {
    // Toggle the sort direction
    const newSortDirection = sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newSortDirection);

    // Sort the data based on the campaign name
    const sortedCampaigns = [...visibleData]; // Make a copy of the data
    sortedCampaigns.sort((a, b) => {
      const campaignA = a.name ? a.name.toLowerCase() : ""; // Default to empty string if name is null or undefined
      const campaignB = b.name ? b.name.toLowerCase() : ""; // Default to empty string if name is null or undefined

      if (newSortDirection === "asc") {
        return campaignA.localeCompare(campaignB);
      } else {
        return campaignB.localeCompare(campaignA);
      }
    });

    // Reverse the sorted array to get descending order
    const descSortedCampaigns = sortedCampaigns.reverse();

    setSortedData(descSortedCampaigns);
    console.log(descSortedCampaigns, "filteredData");
    setIsRotated(!isRotated);
    setShowArrowCampaign(true);
  };

  const handleSortBudget = () => {
    // Toggle the sort direction
    const newSortBudget = sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newSortBudget);

    // Sort the data based on the campaign budget
    const sortedBudget = [...visibleData]; // Make a copy of the data
    sortedBudget.sort((a, b) => {
      const budgetA = a.budget ? parseInt(a.budget) : 0; // Convert budget to a number, use 0 if budget is null or undefined
      const budgetB = b.budget ? parseInt(b.budget) : 0; // Convert budget to a number, use 0 if budget is null or undefined

      if (newSortBudget === "asc") {
        return budgetA - budgetB;
      } else {
        return budgetB - budgetA;
      }

    });

    setSortedData(sortedBudget);
    console.log(sortedBudget, "sortedBudget");
    setIsRotated(!isRotated);
    setShowArrowBudget(true);
  };
  const handleCampaignCLick = () => {
    // This function will be called when the focus is lost from the column header
    // You can set the state to false to hide the arrow icon
    setShowArrowCampaign(true);
    setShowArrowBudget(false);
  };

  const handleBudgetClick = () => {
    // This function will be called when the focus is lost from the column header
    // You can set the state to false to hide the arrow icon
    setShowArrowBudget(true);
    setShowArrowCampaign(false);
  };
  const [showModal, setShowModal] = useState(false)

  const handleShowModal = () => {
    setShowModal(true)
  }
  return (
    <div
      className="card card-flush h-xl-100 pb-7"
      style={{
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
        overflow: "visible",
       
      }}
    >
      <>{console.log("SHOWWWWWxx", showData)}</>

      {/* drawer */}

      <div
        id="kt_drawer_example_basic"
        className="bg-white"
        data-kt-drawer="true"
        data-kt-drawer-activate="true"
        data-kt-drawer-toggle="#kt_drawer_example_basic_button"
        data-kt-drawer-close="#kt_drawer_example_advanced_close"
        data-kt-drawer-width="50%"
        style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
      >
        <div
          className="mt-20"
          style={{
            backgroundColor: "#fff",
            width: "100%",
          }}
        >
          <div
            className="header fs-1 px-10 d-flex align-items-center"
            style={{
              width: "100%",
              color: "white",
              backgroundColor: "#7E7F80",
              height: "75px",
              marginTop: "-70px",
            }}
          >
            <div>
              {" "}
              <IoClose />
            </div>
            <div className="px-10 fw-semibold">Settings </div>
          </div>
          <div className="modal-body py-10 px-10" style={{ width: "100%" }}>
            <Edit id={selectedCampaignId} camp={camp} />
          </div>
        </div>
      </div>

      {/* drawer end */}

      {/* header */}

      <div
        className="header d-flex px-5"
        style={{ height: "57px", boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)" }}
      >
        <div
          className="first set"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
              paddingLeft: "24px",
              color: "#202124",
              fontWeight: "400",
              fontSize: "22px",
              lineHeight: "1.75rem",
              alignSelf: "center",
            }}
          >
            Campaigns
          </div>
        </div>

        <div
          className=""
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <div
              className="px-2"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: "5px",
              }}
            >
              <span
                style={{ fontSize: "15px", marginTop: "3px", color: "#616161" }}
              >
                Custom
              </span>
            </div>

            <div
              className="px-2"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: "5px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <DatePicker
                  id="dateInput"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select a date"
                  style={{ fontSize: "10px", border: "1px solid #ccc" }}
                />
              </div>
            </div> */}
            {/* <div
              className="px-2"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: "5px",
              }}
            >
              <AiOutlineLeft size={20} />
      
            </div> */}
            {/* <div
              className="px-2"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: "4px",
              }}
            >
              <AiOutlineRight size={20} />
            
            </div> */}
            {/* <div
              className="px-2"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: "5px",
              }}
            >
              <button
                className="btn d-flex nav-link btn btn-sm  btn-active btn-active-light fw-bold p-2"
                style={{ fontSize: "15px", marginTop: "3px", color: "#59A3F1" }}
                onMouseEnter={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "#F6F9FD";
                }}
                onMouseLeave={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "white";
                }}
                onClick={handleDay}
              >
                Show last 30 days
              </button>
            </div> */}
          </div>
        </div>
      </div>

      <div className="header d-flex px-5 mt-3" style={{ height: "57px" }}>
        <div
          className="first set"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <AiOutlinePlus
              size={40}
              style={{
                padding: "7px",
                color: "white",
                borderRadius: "50px",
                backgroundColor: "#1A73E8",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
              }}
              onClick={handleAddInput}
              onMouseEnter={handleMouseEnter3}
              onMouseLeave={handleMouseLeave3}
            />
          </div>
          {showPop && (
            <div
              className="fw-semibold d-flex align-items-center flex-wrap px-2"
              style={{
                position: "absolute",
                // top: "60%",
                // right: "20px",
                left: "-430px",
                backgroundColor: "#4F5355",
                padding: "5px",
                // border: "1px solid #ccc",
                borderRadius: "5px",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0)",
                zIndex: "3",
                // width: "110px",
                // height: "25px",
                fontSize: "12px",
                marginLeft: "500px",
                color: "#fff",
              }}
            >
              Create Campaign
            </div>
          )}

          <div
            style={{
              marginRight: "10px",
              padding: "5px",
              color: "#5f6368",
            }}
            onClick={handleFilter}
          >
            <MdOutlineFilterAlt size={25} />
          </div>

          {/* add filter button */}

          <button
            className="btn d-flex nav-link btn btn-sm  btn-active btn-active-light fw-bold p-2"
            style={{
              fontSize: "14px",
              color: "#5f6368",
              flexWrap: "wrap",
              width: "80px",
            }}
            onMouseEnter={(e) => {
              (e.target as HTMLButtonElement).style.backgroundColor = "#F5F5F5";
            }}
            onMouseLeave={(e) => {
              (e.target as HTMLButtonElement).style.backgroundColor = "white";
            }}
            onClick={handleFilter}
          >
            Add Filter
          </button>

          {showPopup &&
            status_data?.map((item) => {
              return (
                <div
                  className="py-2"
                  ref={dropdownRefsFilter}
                  style={{
                    position: "absolute",
                    top: "3%",
                    left: "9%",
                    backgroundColor: "#fff",
                    padding: "10px",
                    border: "1px solid white",
                    borderRadius: "5px",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                    zIndex: 999999,
                    width: "200px",
                    maxHeight: "300px",
                    overflowY: "auto",
                    fontSize: "15px",
                    transition: "height 10.0s ease",
                    cursor: "pointer",
                  }}
                >
                  <div className="p-2">
                    {item?.dropdownData?.map((data) => {
                      return (
                        <>
                          <div className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              checked={selectedColumns.includes(data)}
                              onChange={() => handleColumnCheckboxChange(data)}
                              style={{
                                width: "10px",
                                height: "10px",
                                borderRadius: "0",
                                marginLeft: "8px",
                                // marginRight: "100px",
                                transform: "scale(2)",
                              }}
                            />
                            {/* <BsFillCircleFill
                              size={7}
                              style={{
                                color: selectedColumns.includes(data)
                                  ? "green"
                                  : "transparent",
                              }}
                            /> */}
                            <span className="dropdown-item text-hover-primary">
                              {data}
                            </span>
                          </div>
                        </>
                      );
                    })}
                  </div>

                  {/* clear all button */}
                  <div className=" d-flex justify-content-end">
                    <button
                      type="button"
                      style={{ fontSize: "14px", color: "#3c4043" }}
                      className="nav-link btn btn-sm  btn-active btn-active-light fw-bold px-4 me-1"
                      onMouseEnter={(e) => {
                        (e.target as HTMLButtonElement).style.backgroundColor =
                          "#F5F5F5";
                      }}
                      onMouseLeave={(e) => {
                        (e.target as HTMLButtonElement).style.backgroundColor =
                          "white";
                      }}
                      onClick={handleAllClear}
                    >
                      Clear All
                    </button>
                  </div>
                </div>
              );
            })}
        </div>

        <div
          className=""
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className="px-2"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: "5px",
              }}
            >
              <AiOutlineSearch size={20} />

              <span
                style={{ fontSize: "13px", marginTop: "3px", fontWeight: 500 }}
              >
                Search
              </span>
            </div>
            <div
              className="px-2"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: "5px",
              }}
            >
              <MdSegment size={20} />

              <span
                style={{ fontSize: "13px", marginTop: "3px", fontWeight: 500 }}
              >
                Segment
              </span>
            </div>
            <div
              className="px-2"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: "5px",
              }}
            >
              <BsLayoutThreeColumns size={20} />

              <span
                style={{ fontSize: "13px", marginTop: "3px", fontWeight: 500 }}
              >
                Columns
              </span>
            </div>
            <div
              className="px-2"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: "5px",
              }}
            >
              <BsFileBarGraph size={20} />
              <span
                style={{ fontSize: "13px", marginTop: "3px", fontWeight: 500 }}
              >
                Reports
              </span>
            </div>
            <div
              className="px-2"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: "5px",
              }}
            >
              <BiDownload size={20} />
              <span
                style={{ fontSize: "13px", marginTop: "3px", fontWeight: 500 }}
              >
                Download
              </span>
            </div>
            <div
              className="px-2"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: "5px",
              }}
            >
              <FaExpand size={20} />
              <span
                style={{ fontSize: "13px", marginTop: "3px", fontWeight: 500 }}
              >
                Expand
              </span>
            </div>
            <div
              className="px-2"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: "5px",
              }}
            >
              <BiDotsVerticalRounded size={20} />
              <span
                style={{ fontSize: "13px", marginTop: "3px", fontWeight: 500 }}
              >
                More
              </span>
            </div>
          </div>
        </div>

        {isPopupOpen && (
          <div
            className="py-2"
            ref={dropdownRef}
            style={{
              position: "absolute",
              top: "2.2%",
              left: "-53.5px",
              backgroundColor: "#fff",
              // padding: "20px",
              border: "1px solid white",
              borderRadius: "5px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
              zIndex: 3,
              width: "250px",
              // height: "120px",
              fontSize: "13px",
              marginLeft: "70px",
              transition: "height 10.0s ease",
              cursor: "pointer",
            }}
          >
            <div
              className="p-5 d-flex align-items-center"
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "#F5F5F5";
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor = "white";
              }}
            >
              <BsPlusLg className="mr-2" size={20} />
              <div
                className=""
                style={{ marginLeft: "20px" }}
                onClick={addNew}
                onMouseEnter={handleMouseEnter4}
                onMouseLeave={handleMouseLeave4}
              >
                New Campaign
              </div>
            </div>
            {showNew && (
              <div
                className="fw-semibold d-flex align-items-center flex-wrap px-2"
                style={{
                  position: "absolute",
                  top: "12%",
                  // right: "40px",
                  left: "-98%",
                  backgroundColor: "#4F5355",
                  padding: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0)",
                  zIndex: "3",
                  width: "105px",
                  // height: "25px",
                  fontSize: "12px",
                  marginLeft: "500px",
                  color: "#fff",
                }}
              >
                New Campaign
              </div>
            )}
            <div
              className="p-5 d-flex align-items-center"
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "#F5F5F5";
                // (e.target as HTMLButtonElement).style.borderRadius = "50px"; // Change the background color on hover
                // (e.target as HTMLButtonElement).style.cursor= inputValue2 === '' && cards.length === 0 ? "not-allowed" : "pointer";
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor = "white"; // Restore the initial background color
                // (e.target as HTMLButtonElement).style.color = "#1a73e8"; // Change the background color on hover
              }}
            >
              <BiFolder className="mr-2" size={20} />

              <div
                className=""
                style={{ marginLeft: "20px" }}
                onMouseEnter={handleMouseEnter5}
                onMouseLeave={handleMouseLeave5}
                onClick={handleShowModal} >
                {showModal && <StartNewModal campaignId={campaignId} onBackClick={(e)=>setShowModal(false)}/> }

                Resume Campaign Draft
              </div>
            </div>
            {showResume && (
              <div
                className="fw-semibold d-flex align-items-center flex-wrap px-2"
                style={{
                  position: "absolute",
                  top: "45%",
                  // right: "40px",
                  left: "-98%",
                  backgroundColor: "#4F5355",
                  padding: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0)",
                  zIndex: "3",
                  width: "155px",
                  // height: "25px",
                  fontSize: "12px",
                  marginLeft: "500px",
                  color: "#fff",
                }}
              >
                Resume Campaign Draft
              </div>
            )}

            {/* <div
              className="p-5 d-flex align-items-center"
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "#F5F5F5";
                // (e.target as HTMLButtonElement).style.borderRadius = "50px"; // Change the background color on hover
                // (e.target as HTMLButtonElement).style.cursor= inputValue2 === '' && cards.length === 0 ? "not-allowed" : "pointer";
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor = "white"; // Restore the initial background color
                // (e.target as HTMLButtonElement).style.color = "#1a73e8"; // Change the background color on hover
              }}
            >
              <MdContentCopy
                className="mr-2"
                size={20}
                style={{ transform: "rotate(-90deg)" }}
              />

              <div
                className=""
                style={{ marginLeft: "20px" }}
                onMouseEnter={handleMouseEnter6}
                onMouseLeave={handleMouseLeave6}
              >
                Load Campaign Settings
              </div>
            </div>
            {showLoad && (
              <div
                className="fw-semibold d-flex align-items-center flex-wrap px-2"
                style={{
                  position: "absolute",
                  top: "75%",
                  // right: "40px",
                  left: "-98%",
                  backgroundColor: "#4F5355",
                  padding: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0)",
                  zIndex: "3",
                  width: "250px",
                  // height: "25px",
                  fontSize: "12px",
                  marginLeft: "500px",
                  color: "#fff",
                }}
              >
                Load settings from an existing campaign
              </div>
            )} */}
          </div>
        )}
      </div>

      {/* list begins */}

      <div
        className=" d-flex flex-stack flex-wrap p-5"
        style={{
          // overflow: "auto",
          marginBottom: "10px",
          scrollbarColor: "red yellow",
        }}
      >
        {/* <div className="separator separator-dashed "></div> */}

        {/* dummy table 1*/}
        <div className="container d-flex justify-content-center" style={{ width: '100%' }}>
          <div className=" d-flex flex-stack flex-wrap py-0"
            style={{
              overflowX: "auto",
              borderRight: "1px solid #DADCE0",
              // position:'relative',
              // borderLeft: "1px solid #DADCE0",
            }}
          >
            <div className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3"
              id="kt_widget_table_3"
              data-kt-table-widget-3="all"
              style={{
                width: "40%",
                backgroundColor: "white",
                // overflow: "hidden",
                left: 0,
                position: "sticky",
                scrollbarColor: "red yellow",
                // boxShadow: "8px 0 0.02px rgba(0, 0, 0, 0.0005)",
                zIndex: 2,
              }}
            >
              <thead
                style={{
                  height: "51px", // Set the height to 41px
                  left: 0,
                  // zIndex: 1,
                  borderBottom: "3px solid #80868B",
                  borderTop: "1px solid #DADCE0",
                  // borderLeft: "1px solid #DADCE0", // Set initial border to transparent
                  borderRight: "1px solid #DADCE0",
                }}
              >
                <th
                  className="text-semibold py-0"
                  style={{
                    color: "#3C4043",
                    // borderRight: "1px solid #DADCE0",
                    borderLeft: "1px solid #DADCE0",
                    fontSize: "13px",
                    backgroundColor: "white",
                    overflowX: "auto",

                    top: 0,
                    zIndex: "3",
                    left: 0,
                    // position: "sticky",
                    right: 0, // Assuming your component needs to be aligned to the right
                    // boxShadow: "8px 0 8px rgba(0, 0, 0, 0.1)",
                    // zIndex: 7,
                    height: "51px",
                    // width: "500px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  onClick={handleCampaignCLick}
                // tabIndex="0"
                >
                  <b className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="d-flex flex-start"
                      style={{
                        width: "10px",
                        height: "10px",
                        // borderRadius: "0",
                        marginLeft: "14px",
                        marginRight: "90px",
                        transform: "scale(2)",
                        borderRadius: "0px",
                      }}
                    />
                    Campaign
                    {showArrowCampaign && (
                      <BiDownArrowAlt
                        className={`mr-2 ${sortDirection === "asc" ? "active" : ""
                          }`}
                        size={20}
                        style={{
                          transform: `rotate(${isRotated ? "180deg" : "0deg"})`,
                        }}
                        onClick={handleSortCampaign}
                      />
                    )}
                  </b>
                </th>
              </thead>

              <tbody style={{ borderBottom: "1px solid #DADCE0" }}>
                {console.log(selectedColumns, "yyyyyyyyy")}
                {/* {response?.map((item, index) => { */}
                {(sortDirection === "asc" ? visibleData : sortedData)?.map(
                  (item, index) => {
                    const isRowHovered = hover === index;
                    // const isRowHovered = hover === index;

                    return (
                      <tr
                        className="py-0 mb-20"
                        style={{
                          borderRight: "1px solid #DADCE0", // Set initial border to transparent
                          borderLeft: "1px solid #DADCE0",
                          borderBottom: "1px solid #DADCE0", // Set initial border to transparent
                          borderColor: "#DADCE0", // Set initial border color
                          borderWidth: "1px",
                          height: "40px",

                          fontSize: "12px",
                          // minHeight: "0px",
                          position: "relative",
                          backgroundColor: isRowHovered ? "#F7F7F7" : "white",
                          overflow: "hidden",
                        }}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <td
                          className="py-0 pe-5"
                          style={{
                            // minWidth: "500px",
                            width: "40%",
                            zIndex: "2",
                            left: 0,
                            // position: "sticky",
                            // top: '10px',
                            right: 0, // Assuming your component needs to be aligned to the right
                            // boxShadow: "8px 0 8px rgba(0, 0, 0, 0.1)", // This will create a shadow on the right side
                            // zIndex: 1, // Ensure the shadow is above other content

                            backgroundColor: isRowHovered ? "#F8F9FA" : "white",
                            // borderLeft: "1px solid #DADCE0",
                            // position: "relative",
                          }}
                        >
                          <div
                            className="row gx-xl-12 gy-0 d-flex align-items-center ps-10 pe-1 py-0"
                            style={{
                              // backgroundColor: isRowHovered  ? "#F8F9FA" : "white",
                              height: "100%",
                              flexWrap: "wrap",

                              //  border: "1px solid #DADCE0", // Set initial border to transparent
                              // boxShadow: '0 2px 8px rgba(0, 0, 0, 0.5)'
                            }}
                          >
                            {/* <div className="position-relative ps-6 pe-3 py-2"> */}
                            {/* <div
                          className={`position-absolute start-0 top-0 w-4px h-100 rounded-2`}
                        ></div> */}

                            <div className=" col-sm-2 d-flex align-items-center px-0 mx-0"
                              style={{ flex: "1 0 auto", justifyContent: 'space-between' }}
                            // style={{ justifyContent: "space-between" }}
                            >
                              <input
                                type="checkbox"
                                className="d-flex flex-start"
                                // checked={showRadioButtons}
                                // onChange={handleAllDownloadsCheckboxChange}
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  // borderWidth: "8px",
                                  // marginLeft: "8px",
                                  // marginRight: "5px",
                                  transform: "scale(2)",
                                  borderRadius: "0px",
                                }}
                              />

                              <div
                                className="d-flex align-items-center"
                                style={{ flexWrap: "wrap" }}
                              >
                                {item?.status?.toLowerCase() === "reviewing" ? (
                                  <AiFillEye
                                    size={20}
                                    style={{
                                      color: "#07A1F7",
                                    }}
                                  />
                                ) : item?.status?.toLowerCase() ===
                                  "live now" ? (
                                  <AiFillPlayCircle
                                    size={20}
                                    style={{
                                      color: "#73D9A2",
                                    }}
                                  />
                                ) : item?.status?.toLowerCase() === "paused" ? (
                                  <AiFillPauseCircle
                                    size={20}
                                    style={{
                                      color: "#FFCA0E",
                                    }}
                                  />
                                ) : item?.status?.toLowerCase() ===
                                  "completed" ? (
                                  <HiCheckCircle
                                    size={20}
                                    style={{
                                      color: "#7A44EB",
                                    }}
                                  />
                                ) : (
                                  <AiFillEye
                                    size={20}
                                    style={{
                                      color: "#07A1F7",
                                    }}
                                  />
                                )}
                              </div>

                            </div>
                            <div className=" col-sm-1 d-flex align-items-center justify-content-center flex-wrap py-0"
                              style={{ flex: "1 0 auto" }}
                            // style={{ justifyContent: "space-between" }}
                            >
                              {isRowHovered && (
                                <div
                                  className="d-flex text-dark-400 mt-1 fw-semibold fs-5"
                                  style={{
                                    position: "relative",
                                    zIndex: 9,
                                  }}
                                  ref={dropdownRef}
                                >
                                  {showDropdown && (
                                    <ul
                                      style={{
                                        position: "absolute",
                                        top: "calc(100% + 2px)",
                                        left: "35%",
                                        width: "200px",
                                        padding: "10px",
                                        margin: "10px",
                                        listStyle: "none",
                                        background: "#fff",
                                        boxShadow:
                                          "0 2px 4px rgba(0, 0, 0, 0.1)",
                                        borderRadius: "5px",
                                        zIndex: 9,
                                        overflow: "auto",
                                        scrollbarColor: "red yellow",
                                      }}
                                    >
                                      {options?.map((items, index) => (
                                        <li
                                          className="d-flex align-items-center"
                                          key={items.value}
                                          style={{
                                            padding: "10px",
                                            cursor: "pointer",
                                            zIndex: 8,
                                            borderBottom: "0px solid #ced4da",
                                            backgroundColor:
                                              selectedOptionIndex === index
                                                ? "#E8F0FE"
                                                : hoveredOptionIndex === index
                                                  ? "#EFEFEF"
                                                  : "white",
                                          }}
                                          onMouseEnter={() =>
                                            handleDropEnter(index)
                                          }
                                          onMouseLeave={handleDropLeave}
                                          onClick={() =>
                                            handleOptionChange(
                                              items,
                                              index,
                                              item
                                            )
                                          }
                                        >
                                          {items?.value === "Reviewing" && (
                                            <AiFillEye
                                              size={15}
                                              style={{ color: "#07A1F7" }}
                                            />
                                          )}
                                          {items?.value === "Paused" && (
                                            <AiFillPauseCircle
                                              size={15}
                                              style={{ color: "#FFCA0E" }}
                                            />
                                          )}
                                          {items?.value === "Live Now" && (
                                            <AiFillPlayCircle
                                              size={15}
                                              style={{ color: "#73D9A2" }}
                                            />
                                          )}
                                          {items?.value === "Completed" && (
                                            <HiCheckCircle
                                              size={15}
                                              style={{ color: "#7A44EB" }}
                                            />
                                          )}
                                          <div className="px-2"></div>
                                          {items?.label}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              )}

                              {isRowHovered && (
                                <div
                                  className="d-flex flex-end flex-grow"
                                  onClick={() => handleDropdownClick(item?.id)}
                                >
                                  <IoMdArrowDropdown
                                    size={20}
                                    style={{
                                      // marginLeft: "5px",
                                      color: "#5f6368",
                                    }}
                                  />
                                </div>
                              )}
                            </div>

                            <div className="col-sm-8 py-0 d-flex align-items-center">
                              <div style={{
                                color: "#8A8A8A",
                                marginRight: "10px",
                                marginLeft: "-5px",
                                // height:'10px',
                                // width:'10px'

                              }}>
                                <div className="">{cardImage}</div>
                              </div>

                              <div
                                className=" text-hover-primary cursor-pointer"
                                style={{ color: "#418BEC" }}
                                onClick={() =>
                                  handleClick(
                                    item?.name,
                                    item?.id,
                                    item?.status
                                  )
                                }
                              >
                                {/* {item.name} */}
                                {item?.name?.length > 38
                                  ? `${item?.name?.slice(0, 38)}`
                                  : item?.name}
                              </div>

                              {isRowHovered && (
                                <div>
                                  <span className="text-dark-400 mt-1 fw-semibold fs-5">
                                    {/* {status_data?.map((item, Index) => {
                                    return ( */}
                                    <div
                                      className="d-flex align-items-center fw-bold"
                                      style={{ zIndex: 9 }}
                                    // key={Index}
                                    >
                                      <div
                                        className="d-flex justify-content-center"
                                        style={{
                                          // position: "relative",
                                          zIndex: 9,
                                        }}
                                        ref={dropdownRef}
                                      >
                                        {/* edit campaign */}

                                        {showEditModal && (
                                          <div
                                            className="dropdown p-5"
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              left: "18%",
                                              width: "60%",
                                              // height:'auto',
                                              padding: "5px",
                                              // margin: "10px",
                                              // height: "200px",
                                              listStyle: "none",
                                              background: "#fff",
                                              boxShadow:
                                                "0 2px 8px rgba(0, 0, 0, 0.5)",
                                              borderRadius: "5px",
                                              zIndex: 100,
                                              overflow: "auto",
                                              scrollbarColor: "red yellow",
                                              // position: "sticky",
                                            }}
                                          >
                                            <p
                                              className=""
                                              style={{
                                                fontSize: "14px",
                                                color: "#5f6368",
                                              }}
                                            >
                                              {" "}
                                              Campaign
                                            </p>
                                            <input
                                              // placeholder="Sales-Search-31"
                                              defaultValue={
                                                item ? item.name : campaignValue
                                              }
                                              // value={camp ? camp.name : inputValue}
                                              style={{
                                                padding: "10px",
                                                paddingLeft: "20px",
                                                width: "90%",
                                                height: "45px",
                                                borderRadius: "5px",
                                                fontSize: "13px",
                                                border: "1px solid #80868B", // Set initial border to transparent
                                                borderColor: "#80868B", // Set initial border color
                                                borderWidth: "1px", // Set initial border width
                                                color: "80868B",
                                                outline: "none", // Remove default outline style
                                                transition:
                                                  "border-color 0.3s ease-in-out", // Add transition effect
                                              }}
                                              onChange={(e) =>
                                                setCampaignValue(e.target.value)
                                              }
                                              onFocus={(e) => {
                                                e.target.style.borderColor =
                                                  "#0d6efd"; // Change border color on focus
                                                e.target.style.borderWidth =
                                                  "2px"; // Change border width on focus
                                              }}
                                              onBlur={(e) => {
                                                e.target.style.borderColor =
                                                  "#80868B"; // Reset border color on blur
                                                e.target.style.borderWidth =
                                                  "1px"; // Reset border width on blur
                                              }}
                                            />

                                            <div
                                              className="d-flex flex-end mb-1 mt-5"
                                              style={{
                                                width: "100%",
                                                backgroundColor: "white",
                                              }}
                                            >
                                              <button
                                                type="button"
                                                className="nav-link btn btn-sm btn-color-primary btn-active btn-active-light d-flex flex-wrap"
                                                style={{ fontSize: "14px" }}
                                                onMouseEnter={(e) => {
                                                  (
                                                    e.target as HTMLButtonElement
                                                  ).style.backgroundColor =
                                                    "#F5F5F5";
                                                }}
                                                onMouseLeave={(e) => {
                                                  (
                                                    e.target as HTMLButtonElement
                                                  ).style.backgroundColor =
                                                    "#FFFFFF";
                                                }}
                                                onClick={EditModalCancel}
                                              // onClick={() => setShowModal(false)}
                                              // onClick={onClick}
                                              >
                                                Cancel
                                              </button>
                                              <button
                                                type="button"
                                                className="nav-link btn btn-sm btn-color-primary btn-active btn-active-light px-2 d-flex flex-wrap"
                                                style={{ fontSize: "14px" }}
                                                onClick={() => {
                                                  EditModalsave(item);
                                                }}
                                                // onClick={() => navigate(-1)}
                                                onMouseEnter={(e) => {
                                                  (
                                                    e.target as HTMLButtonElement
                                                  ).style.backgroundColor =
                                                    "#F5F5F5";
                                                }}
                                                onMouseLeave={(e) => {
                                                  (
                                                    e.target as HTMLButtonElement
                                                  ).style.backgroundColor =
                                                    "#FFFFFF";
                                                }}
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    {/* );
                                  })} */}
                                  </span>
                                </div>
                              )}

                              {isRowHovered && (
                                <button
                                  className="btn btn-transparent d-flex align-items-center mx-0 px-0"
                                  onClick={() => EditModalClick(item?.id)}
                                  style={{ height: "3px" }}
                                >
                                  <HiPencil
                                    size={39}
                                    style={{
                                      color: "#757575",
                                      // marginRight: "7px",
                                      padding: "8px",
                                    }}
                                    onMouseEnter={(e) => {
                                      (
                                        e.target as HTMLButtonElement
                                      ).style.backgroundColor = "#EAEAEA";
                                      (
                                        e.target as HTMLButtonElement
                                      ).style.borderRadius = "50px"; // Change the background color on hover
                                      // (e.target as HTMLButtonElement).style.cursor= inputValue2 === '' && cards.length === 0 ? "not-allowed" : "pointer";
                                    }}
                                    onMouseLeave={(e) => {
                                      (
                                        e.target as HTMLButtonElement
                                      ).style.backgroundColor = "#F8F9FA"; // Restore the initial background color
                                      // (e.target as HTMLButtonElement).style.color = "#1a73e8"; // Change the background color on hover
                                    }}
                                  />
                                </button>
                              )}
                            </div>

                            <div className="col-sm-1 py-0 d-flex align-items-center flex-start">
                              {/* {isRowHovered && ( */}
                              {isRowHovered && (
                                <button
                                  id="kt_drawer_example_basic_button"
                                  className="btn btn-transparent d-flex align-items-center px-3"
                                  onClick={() =>
                                    handleSettingsIconClick(item.id)
                                  }
                                  style={{ height: "3px" }}
                                >
                                  <FiSettings
                                    size={39}
                                    style={{
                                      color: "#757575",
                                      marginLeft: "-20px",
                                      padding: "10px",
                                    }}
                                    onMouseEnter={(e) => {
                                      (
                                        e.target as HTMLButtonElement
                                      ).style.backgroundColor = "#EAEAEA";
                                      (
                                        e.target as HTMLButtonElement
                                      ).style.borderRadius = "50px"; // Change the background color on hover
                                      // (e.target as HTMLButtonElement).style.cursor= inputValue2 === '' && cards.length === 0 ? "not-allowed" : "pointer";
                                    }}
                                    onMouseLeave={(e) => {
                                      (
                                        e.target as HTMLButtonElement
                                      ).style.backgroundColor = "#F8F9FA"; // Restore the initial background color
                                      // (e.target as HTMLButtonElement).style.color = "#1a73e8"; // Change the background color on hover
                                    }}
                                  />
                                </button>
                              )}

                              {/* modaaaal */}

                              {/* <div className="text-muted fw-bold fs-7">
                            Created on {ChangeDateFormat(item?.createdDate)}
                          </div> */}
                            </div>
                          </div>
                        </td>
                        {/* end::first column */}
                        {/* budget */}
                      </tr>
                    );
                  }
                )}
                <tr
                  className="py-0"
                  style={{
                    height: "40px",
                    // maxHeight: "40px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                    color: "#3c4043",
                    // borderTop: "1px solid #DADCE0",
                    borderLeft: "1px solid #DADCE0",
                    borderRight: "1px solid #DADCE0",
                    backgroundColor: "#F7F7F7",
                  }}
                >
                  <td className="py-0" style={{
                    paddingLeft: "130px", zIndex: "2",
                    left: 0,
                    position: "sticky",
                    right: 0, // Assuming your component needs to be aligned to the right
                    // boxShadow: "8px 0 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#F8F9FA",// This will create a shadow on the right side                      
                  }}>
                    Total: All but removed campaigns in your ...{" "}
                    <CustomQuestion>
                      Total traffic to your campaigns that match your current
                      workspace filters
                    </CustomQuestion>
                  </td>
                </tr>
                <tr
                  style={{
                    height: "40px",
                    // maxHeight: "40px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    borderTop: "1px solid #DADCE0",
                    borderLeft: "1px solid #DADCE0",
                    borderRight: "1px solid #DADCE0",
                    backgroundColor: "#F8F9FA",
                  }}
                >
                  <td style={{
                    left: 0,
                    position: "sticky",
                    right: 0, // Assuming your component needs to be aligned to the right
                    // boxShadow: "8px 0 8px rgba(0, 0, 0, 0.1)",
                    zIndex: 1,
                    backgroundColor: "#F8F9FA",
                  }}>

                  </td>

                </tr>
                <tr
                  style={{
                    height: "40px",
                    // maxHeight: "40px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    borderTop: "1px solid #DADCE0",
                    borderLeft: "1px solid #DADCE0",
                    borderRight: "1px solid #DADCE0",
                    backgroundColor: "#F8F9FA",
                  }}
                >
                  <td style={{
                    left: 0,
                    position: "sticky",
                    right: 0, // Assuming your component needs to be aligned to the right
                    // boxShadow: "8px 0 8px rgba(0, 0, 0, 0.1)",
                    zIndex: 1,
                    backgroundColor: "#F8F9FA",
                  }}>

                  </td>

                </tr>
              </tbody>
            </div>
            <div className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3"
              id="kt_widget_table_3"
              data-kt-table-widget-3="all"
              style={{
                width: "60%",
                backgroundColor: "white",
                // overflow: "auto",
              }}
            >
              <thead
                style={{
                  height: "51px", // Set the height to 41px
                  left: 0,
                  // zIndex: 1,
                  borderBottom: "3px solid #80868B",
                  borderTop: "1px solid #DADCE0",
                  // borderLeft: "1px solid #DADCE0", // Set initial border to transparent
                  borderRight: "1px solid #DADCE0",
                }}
              >
                {filteredData[0]?.dropdownData?.map((column) => (
                  <th
                    className="text-semibold py-0"
                    style={{
                      color: "#3C4043",
                      // borderRight: "1px solid #DADCE0",
                      // borderLeft: "1px solid #DADCE0",
                      fontSize: "13px",
                      // position: 'sticky',

                      height: "51px",
                      minWidth: "130px",

                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    key={column}
                  >
                    {/* <b className="d-flex align-items-center">{column}</b> */}
                    {column === "Budget" ? (
                      <div
                        className="d-flex align-items-center"
                        onClick={handleBudgetClick}
                      >
                        {showArrowBudget && (
                          <BiDownArrowAlt
                            className="mr-2"
                            onClick={handleSortBudget}
                            size={20}
                            style={{
                              transform: `rotate(${isRotated ? "180deg" : "0deg"
                                })`,
                            }}
                          />
                        )}
                        <b>{column}</b>
                      </div>
                    ) : (
                      <b className="d-flex align-items-center">{column}</b>
                    )}
                  </th>
                ))}
              </thead>

              <tbody style={{ borderBottom: "1px solid #DADCE0" }}>
                {console.log(selectedColumns, "yyyyyyyyy")}
                {/* {response?.map((item, index) => { */}
                {(sortDirection === "asc" ? visibleData : sortedData)?.map(
                  (item, index) => {
                    const isRowHovered = hover === index;
                    // const isRowHovered = hover === index;

                    return (
                      <tr
                        className="py-0 mb-20"
                        style={{
                          borderRight: "1px solid #DADCE0", // Set initial border to transparent
                          // borderLeft: "1px solid #DADCE0",
                          borderBottom: "1px solid #DADCE0", // Set initial border to transparent
                          borderColor: "#DADCE0", // Set initial border color
                          borderWidth: "1px",
                          height: "40px",

                          fontSize: "12px",
                          // minHeight: "0px",
                          position: "relative",
                          backgroundColor: isRowHovered ? "#F7F7F7" : "white",
                          overflow: "hidden",
                        }}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                      >
                        {/* end::first column */}
                        {/* budget */}

                        {filteredData[0]?.dropdownData?.includes("Budget") && (
                          <td
                            className="px-1 py-0"
                            style={{
                              height: "40px",

                              width: "400px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <div className="d-flex align-items-center px-3">
                              {/* social media icons */}
                              {item.budget}

                              {/* edit budget Modal */}
                              {isRowHovered && (
                                <div>
                                  <span className="text-dark-400 mt-1 fw-semibold fs-5">
                                    {/* {status_data?.map((item, Index) => {
                                    return ( */}
                                    <div
                                      className="d-flex align-items-center fw-bold"
                                    // key={Index}
                                    >
                                      <div
                                        className="d-flex justify-content-center"
                                        style={{
                                          // position: "relative",
                                          zIndex: 1,
                                        }}
                                        ref={dropdownRef}
                                      >
                                        {showEditBudgetModal && (
                                          <div
                                            className="dropdown p-3"
                                            style={{
                                              // flexWrap: "wrap",
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              width: "7%",
                                              // height:'auto',
                                              // padding: "5px",
                                              // margin: "10px",
                                              height: "200px",
                                              listStyle: "none",
                                              background: "#fff",
                                              boxShadow:
                                                "0 2px 8px rgba(0, 0, 0, 0.5)",
                                              // borderRadius: "5px",
                                              zIndex: 2,
                                              overflow: "hidden",
                                              scrollbarColor: "red yellow",
                                              // position: "sticky",
                                            }}
                                          >
                                            <p
                                              className=""
                                              style={{
                                                fontSize: "14px",
                                                color: "#5f6368",
                                              }}
                                            >
                                              {" "}
                                              Budget
                                            </p>
                                            <input
                                              // placeholder="Sales-Search-31"
                                              defaultValue={
                                                item ? item.budget : budgetValue
                                              }
                                              type="number"
                                              // value={camp ? camp.name : inputValue}
                                              style={{
                                                padding: "10px",
                                                paddingLeft: "20px",
                                                width: "90%",
                                                height: "45px",
                                                borderRadius: "5px",
                                                fontSize: "13px",
                                                border: "1px solid #80868B", // Set initial border to transparent
                                                borderColor: "#80868B", // Set initial border color
                                                borderWidth: "1px", // Set initial border width
                                                color: "80868B",
                                                outline: "none", // Remove default outline style
                                                transition:
                                                  "border-color 0.3s ease-in-out", // Add transition effect
                                              }}
                                              onChange={(e) =>
                                                setBudgetValue(e.target.value)
                                              }
                                              onFocus={(e) => {
                                                e.target.style.borderColor =
                                                  "#0d6efd"; // Change border color on focus
                                                e.target.style.borderWidth =
                                                  "2px"; // Change border width on focus
                                              }}
                                              onBlur={(e) => {
                                                e.target.style.borderColor =
                                                  "#80868B"; // Reset border color on blur
                                                e.target.style.borderWidth =
                                                  "1px"; // Reset border width on blur
                                              }}
                                            />

                                            <p
                                              className="d-flex py-3 align-items-center px-1"
                                              style={{
                                                color: "rgba(0,0,0,.54)",
                                                fontSize: "14px",
                                                width: "20px",
                                                // width: "30%",
                                              }}
                                            >
                                              Actual daily spend may vary
                                              <CustomQuestion>.</CustomQuestion>
                                            </p>

                                            <div
                                              className="d-flex flex-end mb-1 mt-5"
                                              style={{
                                                width: "100%",
                                                backgroundColor: "white",
                                              }}
                                            >
                                              <button
                                                type="button"
                                                className="nav-link btn btn-sm btn-color-primary 
                                                  btn-active btn-active-light d-flex flex-wrap"
                                                style={{ fontSize: "14px" }}
                                                onMouseEnter={(e) => {
                                                  (
                                                    e.target as HTMLButtonElement
                                                  ).style.backgroundColor =
                                                    "#F5F5F5";
                                                }}
                                                onMouseLeave={(e) => {
                                                  (
                                                    e.target as HTMLButtonElement
                                                  ).style.backgroundColor =
                                                    "#FFFFFF";
                                                }}
                                                onClick={EditBudgetModalCancel}
                                              // onClick={() => setShowModal(false)}
                                              // onClick={onClick}
                                              >
                                                Cancel
                                              </button>
                                              <button
                                                type="button"
                                                className="nav-link btn btn-sm btn-color-primary btn-active btn-active-light px-2 d-flex flex-wrap"
                                                style={{ fontSize: "14px" }}
                                                onClick={() => {
                                                  EditBudgetModalsave(item);
                                                }}
                                                // onClick={() => navigate(-1)}
                                                onMouseEnter={(e) => {
                                                  (
                                                    e.target as HTMLButtonElement
                                                  ).style.backgroundColor =
                                                    "#F5F5F5";
                                                }}
                                                onMouseLeave={(e) => {
                                                  (
                                                    e.target as HTMLButtonElement
                                                  ).style.backgroundColor =
                                                    "#FFFFFF";
                                                }}
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    {/* );
                                  })} */}
                                  </span>
                                </div>
                              )}

                              {isRowHovered && (
                                <button
                                  className="btn btn-transparent d-flex align-items-center px-0 mx-0 ps-1"
                                  onClick={() => EditBudgetModalClick(item?.id)}
                                  style={{ height: "3px" }}
                                >
                                  <HiPencil
                                    size={39}
                                    style={{
                                      color: "#757575",

                                      padding: "8px",
                                    }}
                                    onMouseEnter={(e) => {
                                      (
                                        e.target as HTMLButtonElement
                                      ).style.color = "black";
                                    }}
                                    onMouseLeave={(e) => {
                                      (
                                        e.target as HTMLButtonElement
                                      ).style.color = "#757575"; // Restore the initial background color
                                      // (e.target as HTMLButtonElement).style.color = "#1a73e8"; // Change the background color on hover
                                    }}
                                  />
                                </button>
                              )}
                            </div>
                            {/* <div className="fs-7 text-muted fw-bold">
                          {item?.target}
                        </div> */}
                          </td>
                        )}
                        {filteredData[0].dropdownData.includes(
                          "Budget Goal"
                        ) && (
                            <td
                              className="py-0"
                              style={{
                                height: "40px",
                                // maxHeight: "60px",
                                // overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.budgetGoal}
                            </td>
                          )}
                        {filteredData[0].dropdownData.includes("Status") && (
                          <td
                            className="py-0"
                            style={{
                              height: "40px",
                              // maxHeight: "60px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <span
                              className={`badge badge-light-${item?.status?.toLowerCase() === "reviewing"
                                  ? "primary"
                                  : item?.status?.toLowerCase() === "live now"
                                    ? "success"
                                    : item?.status?.toLowerCase() === "paused"
                                      ? "warning"
                                      : item?.status?.toLowerCase() === "completed"
                                        ? "info"
                                        : item?.status?.toLowerCase() === "new"
                                          ? "primary"
                                          : "reviewing"
                                } fs-8 fw-bold text-capitalize`}
                            >
                              {item.status}
                            </span>
                          </td>
                        )}
                        {/* Suggestion */}
                        {/* <div className="mx-2 w-20">
										<span></span>
									</div> */}
                        {/* date range */}

                        {/* {filteredData[0].dropdownData.includes("Tag") && (
                        <td  style={{
      height: '60px',
      maxHeight: '60px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }}>{item.tag}</td>
                      )} */}

                        {filteredData[0].dropdownData.includes(
                          "Target Language"
                        ) && (
                            <td
                              className="py-0"
                              style={{
                                height: "40px",
                                // maxHeight: "60px",
                                maxWidth: "200px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item?.targetLanguage?.replace(/[\[\]]+/g, "") ||
                                ""}
                            </td>
                          )}
                        {/* {filteredData[0].dropdownData.includes(
                        "Target Language"
                      ) && (
                          <td  style={{
      height: '60px',
      maxHeight: '60px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }}>{item.targetLanguage}</td>
                        )} */}

                        {filteredData[0].dropdownData.includes(
                          "Target Location"
                        ) && (
                            <td
                              className="py-0"
                              style={{
                                height: "40px",
                                // maxHeight: "60px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.targetLocation}
                            </td>
                          )}
                        {filteredData[0].dropdownData.includes(
                          "Start Date"
                        ) && (
                            <td
                              className="py-0"
                              style={{
                                height: "40px",
                                // maxHeight: "60px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.startdate}
                            </td>
                          )}
                        {filteredData[0].dropdownData.includes("End Date") && (
                          <td
                            className="py-0"
                            style={{
                              height: "40px",
                              // maxHeight: "60px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.endDate}
                          </td>
                        )}
                        {filteredData[0].dropdownData.includes("Target") && (
                          <td
                            className="py-0"
                            style={{
                              height: "40px",
                              // maxHeight: "60px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.target}
                          </td>
                        )}
                        {filteredData[0].dropdownData.includes("Stage") && (
                          <td
                            className="py-0"
                            style={{
                              height: "40px",
                              // maxHeight: "60px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.stage}
                          </td>
                        )}
                        {filteredData[0].dropdownData.includes(
                          "Actual CPG"
                        ) && (
                            <td
                              className="py-0"
                              style={{
                                height: "40px",
                                // maxHeight: "60px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.actualCpg}
                            </td>
                          )}
                        {filteredData[0].dropdownData.includes(
                          "Target CPG"
                        ) && (
                            <td
                              className="py-0"
                              style={{
                                height: "40px",
                                // maxHeight: "60px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.targetCpg}
                            </td>
                          )}
                        {filteredData[0].dropdownData.includes(
                          "Campaign Objective"
                        ) && (
                            <td
                              className="py-0"
                              style={{
                                height: "40px",
                                // maxHeight: "60px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.campaignObjective}
                            </td>
                          )}
                        {filteredData[0].dropdownData.includes(
                          "Campaign Goal"
                        ) && (
                            <td
                              className="py-0"
                              style={{
                                height: "40px",
                                // maxHeight: "60px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.campaignGoal}
                            </td>
                          )}
                        {filteredData[0].dropdownData.includes(
                          "Campaign Type"
                        ) && (
                            <td
                              className="py-0"
                              style={{
                                height: "40px",
                                // maxHeight: "60px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.campaignType}
                            </td>
                          )}
                        {filteredData[0].dropdownData.includes(
                          "Reach Goal Name"
                        ) && (
                            <td
                              className="py-0"
                              style={{
                                height: "40px",
                                // maxHeight: "60px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.reachGoalName}
                            </td>
                          )}
                        {filteredData[0].dropdownData.includes(
                          "Reach Goal Value"
                        ) && (
                            <td
                              className="py-0"
                              style={{
                                height: "40px",
                                // maxHeight: "60px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.reachGoalValue}
                            </td>
                          )}
                        {/* {filteredData[0].dropdownData.includes(
                          "Campaign Type"
                        ) && <td  style={{
      height: '60px',
      maxHeight: '60px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }}>{item.campaignBiddingFocusOn}</td>}
                        {filteredData[0].dropdownData.includes(
                          "Campaign Type"
                        ) && <td  style={{
      height: '60px',
      maxHeight: '60px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }}>{item.campaignBiddingFocusOnValue}</td>} */}
                        {/* {filteredData[0].dropdownData.includes(
                          "Campaign Type"
                        ) && <td  style={{
      height: '60px',
      maxHeight: '60px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }}>{item.campaignCustomerAcquisitionValue}</td>} */}

                        {filteredData[0].dropdownData.includes("Network") && (
                          <td
                            className="py-0"
                            style={{
                              height: "40px",
                              // maxHeight: "60px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item?.campaignNetwork?.replace(/[\[\]]+/g, "") ||
                              ""}
                          </td>
                        )}

                        {/* {filteredData[0].dropdownData.includes("Network") && (
                        <td  style={{
      height: '60px',
      maxHeight: '60px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }}>{item.campaignNetwork}</td>
                      )} */}

                        {filteredData[0].dropdownData.includes(
                          "Audience Segment"
                        ) && (
                            <td
                              className="py-0"
                              style={{
                                height: "40px",
                                maxWidth: "300px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item?.audienceSegment?.replace(/[\[\]]+/g, "") ||
                                ""}
                            </td>
                          )}
                        {/* {filteredData[0].dropdownData.includes(
                        "Audience Segment"
                      ) && <td  style={{
      height: '60px',
      maxHeight: '60px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }}>{item.audienceSegment}</td>} */}
                        {filteredData[0].dropdownData.includes(
                          "Ads Rotation"
                        ) && (
                            <td
                              className="py-0"
                              style={{
                                height: "40px",
                                // maxHeight: "60px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.campaignAdsRotation}
                            </td>
                          )}
                        {filteredData[0].dropdownData.includes(
                          "Website URL"
                        ) && (
                            <td
                              className="py-0"
                              style={{
                                height: "40px",
                                // maxHeight: "60px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.websiteUrl}
                            </td>
                          )}
                        {filteredData[0].dropdownData.includes("Suffix") && (
                          <td
                            className="py-0"
                            style={{
                              height: "40px",
                              // maxHeight: "60px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.websiteUrlSuffix}
                          </td>
                        )}

                        {filteredData[0].dropdownData.includes("URL Name") && (
                          // item.urlName && (
                          <td
                            className="py-0"
                            style={{
                              height: "40px",
                              // maxHeight: "60px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.urlName &&
                              item.urlName.startsWith("[{") &&
                              item.urlName.endsWith("}]")
                              ? item.urlName
                                .substring(2, item.urlName.length - 2)
                                .split(",")[0]
                                .split("=")[1]
                              : ""}
                          </td>
                        )}
                        {filteredData[0].dropdownData.includes("URL Value") && (
                          // item.urlName && (
                          <td
                            className="py-0"
                            style={{
                              height: "40px",
                              // maxHeight: "60px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.urlName &&
                              item.urlName.startsWith("[{") &&
                              item.urlName.endsWith("}]")
                              ? item.urlName
                                .substring(2, item.urlName.length - 2)
                                .split(",")[1]
                                .split("=")[1]
                              : ""}
                          </td>
                        )}

                        {filteredData[0].dropdownData.includes("Scan") && (
                          <td
                            className="py-0"
                            style={{
                              height: "40px",
                              // maxHeight: "60px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.scanUrl}
                          </td>
                        )}
                        {filteredData[0].dropdownData.includes(
                          "Product Services"
                        ) && (
                            <td
                              className="py-0"
                              style={{
                                height: "40px",
                                // maxHeight: "60px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.productServices}
                            </td>
                          )}
                        {filteredData[0].dropdownData.includes(
                          "Search Keywords"
                        ) && (
                            <td
                              className="py-0"
                              style={{
                                height: "40px",
                                // maxHeight: "60px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.searchKeywords}
                            </td>
                          )}

                        {/* foul */}
                        {/* <td
                            className="py-0"
                            style={{
                              height: "40px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                          </td> */}

                        {/* {filteredData[0].dropdownData.includes(
                      "Search Keywords"
                    ) && (
                      <td
                        className="py-0"
                        style={{
                          height: "40px",
                          // maxHeight: "60px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.searchKeywords}
                      </td>
                    )} */}
                      </tr>
                    );
                  }
                )}
                <tr
                  className="py-0"
                  style={{
                    height: "40px",
                    // maxHeight: "40px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                    color: "#3c4043",
                    // borderTop: "1px solid #DADCE0",
                    // borderLeft: "1px solid #DADCE0",
                    borderRight: "1px solid #DADCE0",
                    backgroundColor: "#F8F9FA",
                  }}
                >
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr
                  style={{
                    height: "40px",
                    // maxHeight: "40px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    borderTop: "1px solid #DADCE0",
                    // borderLeft: "1px solid #DADCE0",
                    borderRight: "1px solid #DADCE0",
                    backgroundColor: "#F8F9FA",
                  }}
                >
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr
                  style={{
                    height: "40px",
                    // maxHeight: "40px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    borderTop: "1px solid #DADCE0",
                    // borderLeft: "1px solid #DADCE0",
                    borderRight: "1px solid #DADCE0",
                    backgroundColor: "#F8F9FA",
                  }}
                >
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="px-9 pt-3">
					<span className="text-muted fw-bolder  mb-0  fs-6  ">
						{pagination.total !== 0
							? `Displaying ${pagination?.from || 0} to ${pagination?.to || 0
							} of
					${pagination?.total || 0} records`
							: `Displaying 0 to 0 of 0 records`}
					</span>
				</div> */}
      <div className="d-flex justify-content-end px-9 pt-3 ">
        <span className="text-muted fw-bolder mb-0 fs-6">
          <span
            style={{
              color: clickedNextPage
                ? clickedPrevPage
                  ? "black"
                  : "rgba(0, 0, 0, 0.2)"
                : "rgba(0, 0, 0, 0.2)",
              fontWeight: clickedPrevPage ? "bold" : "normal",
              cursor: clickedNextPage ? "pointer" : "default",
            }}
            onClick={handlePrevPage}
          >
            &lt;
          </span>{" "}
          {`Displaying ${startIndex + 1} to ${endIndex} of ${dataWithBudget?.length} records`}{" "}
          <span
            style={{
              color: clickedPrevPage
                ? clickedNextPage
                  ? "black"
                  : "rgba(0, 0, 0, 0.2)"
                : "rgba(0, 0, 0, 0.2)",
              fontWeight: clickedNextPage ? "bold" : "normal",
              cursor: clickedPrevPage ? "pointer" : "default",
            }}
            onClick={handleNextPage}
            // style={{ cursor: "pointer" }}
            className="text-muted fw-bolder mb-0 fs-6"
          >
            &gt;
          </span>
        </span>
      </div>

      {/* Pagination controls */}

    </div>
  );
};

export default Listing;
