/** @format */

import React, { useEffect, useRef, useState } from "react";
import { KTCard } from "../../../../theme/helpers";
import { Charts } from "../../../modules/widgets/components/Charts";
import { useThemeMode } from "../../../../theme/partials";
import {
  getCSS,
  getCSSVariableValue,
} from "../../../../theme/assets/ts/_utils";
import ApexCharts, { ApexOptions } from "apexcharts";
import { KTIcon } from "../../../../theme/helpers/components/KTIcon";
import { useQuery } from "react-query";
import {
  getAllSocialData,
  getNextSocialList,
} from "../../../modules/application/_requests";
import { useAuth } from "../../../modules/auth";
import {
  getDataFromBackend,
  URLS,
} from "../../../modules/dashboards/DashboardRequests";
import { AiOutlineZoomIn } from "react-icons/ai";
import Modal from "../marketting_component/Modal";
import { primaryColors } from "../../../../theme/assets/ts/_utils/AppColors";

type Props = {
  productId: string;
  startDate: string;
  endDate: string;
  platformId: number;
  productType: string;
};

const ColGraph: React.FC<Props> = ({
  productId,
  platformId,
  startDate,
  endDate,
  productType
}) => {
  const location = window.location.pathname.split("/").pop();
  const [respon, setRespon] = useState<any>([
    {
      name: "cc-pa-ntb",
      status: "ACTIVE",
      day: [],
      week: [],
      month: [],
    },
  ]);
  const [graph, setGraph] = useState();
  const [activeTab, setActiveTab] = useState<string>("day");
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [selectedLead, setSelectedLead] = useState<string>("Impression");
  const [filteredRep, setFilteredRep] = useState<any>([]);
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);

  const getData = async () => {
    let requestData = {
      productId,
      platformId,
      startDate,
      endDate,
      productType
    };
    let performanceByCreditResponse = await getDataFromBackend(
      requestData,
      URLS.GET_PLATFORM_PERFORMANCEBYCREDIT
    );
    console.log(
      "performanceByCreditResponse : ",
      performanceByCreditResponse,
      requestData
    );

    if (performanceByCreditResponse?.data?.length > 1) {
      setRespon(performanceByCreditResponse?.data);
    } else {
      setRespon(respon);
    }
  };

  useEffect(() => {
    getData();
  }, [productId, platformId, startDate, endDate, productType]);

  useEffect(() => {
    if (graph) {
      getData();
    }
  }, [graph]);

  const timeIntervals = [
    { id: "day", label: "Day" },
    { id: "week", label: "Week" },
    { id: "month", label: "Month" },
  ];

  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();

  useEffect(() => {
    setFilteredRep(
      activeTab === "day"
        ? respon[currentIndex]?.day?.filter(
            (item) => item.dataFor === selectedLead
          )
        : activeTab === "week"
        ? respon[currentIndex]?.week.filter(
            (item) => item.dataFor === selectedLead
          )
        : respon[currentIndex]?.month.filter(
            (item) => item.dataFor === selectedLead
          )
    );
  }, [respon, activeTab, currentIndex, selectedLead]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === respon.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    let chart;
    if (respon?.length > 0 && filteredRep.length > 0) {
      chart = refreshChart(filteredRep[0]);
    }
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [
    chartRef,
    mode,
    selectedLead,
    currentIndex,
    activeTab,
    respon,
    filteredRep,
    isZoomModalOpen,
  ]);

  const refreshChart = (filteredData) => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(250, respon || [], filteredData, selectedLead)
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  const leads = ["Impression", "Clicks", "CTR"];

  console.log("respon console", respon);

  function getChartOptions(
    height: number,
    response: any[],
    filteredData: any,
    selectedLead: string
  ): ApexOptions {
    const labelColor = getCSSVariableValue("--kt-gray-500");
    const borderColor = getCSSVariableValue("--kt-gray-200");
    const baseColor = primaryColors.black;
    const secondaryColor = getCSSVariableValue("--kt-gray-300");

    const valuesArray = filteredData?.data?.map((entry: any) => entry.value);
    const periodArray = filteredData?.data?.map((entry: any) => entry.period);

    const formattedArray = periodArray?.map((item: string) => {
      const dateRegex = /^\d{4}-\d{1,2}-\d{1,2}$/;

      if (dateRegex.test(item)) {
        const parts = item.split("-");
        const year = parseInt(parts[0]);
        const month = parseInt(parts[1]);
        const day = parseInt(parts[2]);

        if (month >= 1 && month <= 12 && day >= 1 && day <= 31) {
          const date = new Date(year, month - 1, day);
          const options: Intl.DateTimeFormatOptions = {
            month: "short",
            day: "numeric",
          };
          return date.toLocaleDateString("en-US", options);
        }
      }

      return item;
    });
    console.log("current index data", formattedArray);

    return {
      series: [
        {
          name: selectedLead,
          data: valuesArray,
        },
      ],
      chart: {
        fontFamily: "inherit",
        type: "bar",
        height: height,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "20%",
          borderRadius: 5,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: formattedArray,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },

        labels: {
          show: true,
          style: {
            colors: primaryColors.black,
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          show: false,
          style: {
            colors: primaryColors.black,
            fontSize: "12px",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return val + " ";
          },
        },
      },
      colors: [primaryColors.black, primaryColors.red],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    };
  }

  function getFormattedValue(value) {
    if (typeof value !== "number") {
      return "";
    }
    if (value < 10000) {
      return `${(value / 1000).toFixed(2)}K`;
    } else if (value >= 10000 && value < 1000000) {
      return `${(value / 1000).toFixed(0)}K`;
    } else {
      return `${(value / 1000000).toFixed(2)}M`;
    }
  }

  const renderPerformanceOverview = () => {
    return (
      <>
        <div
          className="card card-flush"
          data-bs-theme="light"
          style={{
            backgroundColor: primaryColors.red,
          }}
        >
          <div className="card-header">
            <h3 className="card-title">
              <span className="text-white fs-3 fw-bold">
                {respon
                  ? respon[currentIndex ? currentIndex : 0]?.name
                  : "Competition"}
              </span>
              <span className="badge badge-success">Live Now</span>
            </h3>
            <div className="card-toolbar">
              <ul className="nav">
                {timeIntervals.map((interval) => (
                  <li className="nav-item" key={interval.id}>
                    <a
                      className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bold px-4 ${
                        activeTab === interval.id ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href={`#kt_table_widget_5_tab_${interval.id}`}
                      onClick={() => setActiveTab(interval.id)}
                    >
                      {interval.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="card-toolbar">
              <button
                className="btn btn-icon bg-white bg-opacity-10 btn-color-white btn-active-success w-25px h-20px"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-overflow="true"
                onClick={() => handleNext()}
              >
                <i className="bi bi-arrow-right-short fs-5"></i>
              </button>
            </div>
          </div>
          <div className="card-body d-flex justify-content-between flex-column pt-1 px-0 pb-0">
            <div className="d-flex justify-content-between px-3" style={{}}>
              {leads.map((lead, index) => {
                console.log("leads", lead);
                const selectedGraphIndex = respon[currentIndex];
                const tempActiveTab = activeTab;

                const selectedGraphs = selectedGraphIndex[tempActiveTab];

                let sumImpression = 0;
                let sumClicks = 0;
                let sumCTR = 0;

                // Loop through each graph object and calculate the sum of values
                selectedGraphs.forEach((graph) => {
                  const sum = graph.data.reduce(
                    (total, entry) => total + entry.value,
                    0
                  );
                  if (graph.dataFor === "Impression") {
                    sumImpression = Math.round(sum);
                  } else if (graph.dataFor === "Clicks") {
                    sumClicks = Math.round(sum);
                  } else if (graph.dataFor === "CTR") {
                    sumCTR = sum;
                  }
                });
                let fixedCtr = (sumClicks / sumImpression) * 100;
                let ctr = fixedCtr.toFixed(2);
                return (
                  <div
                    key={`item${lead}`}
                    className="rounded min-w-60px py-3 px-5 "
                    style={{
                      border: "1px dashed white ",
                      position: "relative",
                      cursor: "pointer",
                      backgroundColor: selectedLead === lead ? primaryColors.black : "",
                    }}
                    onClick={() => setSelectedLead(lead)}
                  >
                    <div className="d-flex align-items-center">
                      <div className="text-white fs-2 fw-bold">
                        {lead === "Impression"
                          ? sumImpression.toLocaleString("en-IN")
                          : lead === "Clicks"
                          ? sumClicks.toLocaleString("en-IN")
                          : lead === "CTR"
                          ? `${ctr}%`
                          : ""}
                      </div>
                    </div>
                    <div className="fw-semibold fs-6 text-white opacity-50">
                      {location === "dashboard" ? "Total " : ""}
                      {lead}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="">
              <div
                ref={chartRef}
                id="kt_charts_widget_1_chart"
                data-kt-chart-color="primary"
                style={
                  isZoomModalOpen ? { height: "300px", width: "100%" } : {}
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="card card-flush h-lg-100 p-5"
        data-bs-theme="light"
        style={{
          backgroundColor: primaryColors.red,
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="card-title d-flex justify-content-between align-items-center">
          <h3 className="text-white fs-3 fw-bold">
            Performance Overview by Credit Cards
          </h3>
          <div
            className="fw-bold"
            style={{
              fontSize: "12px",
              textAlign: "end",
              cursor: "pointer",
            }}
            onClick={() => setIsZoomModalOpen(true)}
          >
            <AiOutlineZoomIn size={25} color={"white"} />
          </div>
        </div>
        {!isZoomModalOpen ? (
          renderPerformanceOverview()
        ) : (
          <Modal
            isOpen={isZoomModalOpen}
            onClose={() => setIsZoomModalOpen(!isZoomModalOpen)}
            title={"Performance Overview by Credit Cards"}
          >
            {renderPerformanceOverview()}
          </Modal>
        )}
      </div>
    </>
  );
};

export default ColGraph;
