import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  getDataFromBackend,
  URLS,
} from "../../../../app/modules/dashboards/DashboardRequests";
import CustomLoader from "../../../../app/pages/dashboard/marketting_component/CustomLoader";
import { AiOutlineZoomIn } from "react-icons/ai";
import Modal from "../../../../app/pages/dashboard/marketting_component/Modal";
import CustomDropdown from "../../../../app/pages/dashboard/marketting_component/CustomDropdown";
import { IoTriangleSharp } from "react-icons/io5";

interface MarketingDataItem {
  productName: string;
  value: number;
  percentage: any;
}
type Statistic = {
  title: string;
  value: number;
  baseLine: number;
};

interface DataEntryMock {
  data: {
    title: any;
    value: number;
    baseLine: any;
  }[];
  product: string;
}

function SpendsByChannnelStats({
  chartID,
  platformId,
  productId,
  startDate,
  endDate,
  channelType,
  campaignId,
  title,
  subUrl,
  baseLine,
}) {
  const [mockData, setMockData] = useState<DataEntryMock[]>([]);

  const [statsData, setStatsData] = useState<Statistic[]>([
    {
      title: "CRIF EL",
      value: 8667937.79,
      baseLine: 8697730.99,
    },
    {
      title: "CPC",
      value: 41501,
      baseLine: 43221,
    },
    {
      title: "CPA",
      value: 4607,
      baseLine: 5797,
    },
  ]);
  const [marketingPieData, setMarketingPieData] = useState<
    Array<{
      total: any;
      value: Array<{
        value: number;
        productName: string;
      }>;
    }>
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownData, setDropdownData] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<string>("Credit Cards");
  const handleDropdownItemClick = (data: string) => {
    setSelectedItems(data);
  };

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      let requestData = {
        productId,
        platformId,
        startDate,
        endDate,
        baseLine,
        // productType,
      };
      let response = await getDataFromBackend(
        requestData,
        URLS.BASE_URL + channelType + subUrl
      );
      console.log(
        "Responses of spends by channel stats api call : ",
        response?.data
      );
      setIsLoading(false);
      let data = response?.data;
      if (response?.data) {
        setMockData(data);
      } else {
        setStatsData([]);
      }
    };

    getData();

    return () => {
      // this now gets called when the component unmounts
    };
  }, [startDate, endDate, productId, platformId, baseLine]);

  useEffect(() => {
    if (mockData?.length > 0) {
      const mockDropdown = Array.from(
        new Set(mockData?.map((item) => item?.product))
      );
      setDropdownData(mockDropdown);
      const selectedDrpdown = selectedItems;
      const selectedData = mockData?.filter(
        (item) => item.product === selectedDrpdown
      );
      setStatsData(selectedData[0]?.data);
      console.log("selectedData[0]?.data", selectedData[0]?.data);

      // Find the 'Eligible Leads' value
    }
  }, [mockData, selectedItems]);

  const calculateBaseComparison = (
    value: number,
    metricName: string,
    baseLine: number
  ) => {
    const tempChange = ((value - baseLine) / baseLine) * 100;
    const percentageChange = Math.abs(Number(tempChange.toFixed(2)));
    return percentageChange;
  };

  const renderBaseLineComparison = (
    value: any,
    metricName: any,
    baseLine: any
  ) => {
    const lossCss = {
      transform: "rotate(180deg)",
      marginRight: "2px",
    };
    const profitCss = { marginRight: "2px" };
    const compareValue =
      ((Number(value) - Number(baseLine)) / Number(baseLine)) * 100;

    const isTotalSpend =
      metricName === "CPCL" ||
      metricName === "CPA" ||
      metricName === "CPL" ||
      metricName === "CPC" ||
      metricName === "Cost per Disbursal" ||
      metricName === "Sanctions" ||
      metricName === "Disbursement";

    const colorValue =
      Number(compareValue) > 0
        ? isTotalSpend
          ? "#97291E"
          : "#068F2A"
        : "#97291E";

    return (
      <div
        className="px-3"
        style={{
          fontSize: metricName === "CRIF EL" ? "16px" : "14px",
          // color: Number(compareValue) > 0 ? (isTotalSpend ? "#97291E" : "#068F2A") : "#97291E",
          // backgroundColor: Number(compareValue) > 0 ? (isTotalSpend ? "#dba19c" : "#bdf2cb") : "#dba19c",
          color:
            Number(compareValue) > 0
              ? isTotalSpend
                ? "#97291E"
                : "#068F2A"
              : isTotalSpend
                ? "#068F2A"
                : "#97291E",

          alignItems: "center",
          display: "flex",
        }}
      >
        <div>
          <IoTriangleSharp
            size={10}
            style={Number(compareValue) > 0 ? profitCss : lossCss}
          />
        </div>
        <div>
          {baseLine > 0
            ? `(${calculateBaseComparison(value, metricName, baseLine)}%)`
            : "(N/A)"}
        </div>
      </div>
    );
  };

  const renderItem = (statistic: Statistic) => {
    const { title, value, baseLine } = statistic;
    return (
      <div
        key={`stat${title}`}
        className="py-6 card card-flush w-lg-10 my-3"
        style={{
          borderRadius: "5px",
          width:
            title === "CRIF EL" ||
              title == "Cost per Disbursal" ||
              title === "Disbursement"
              ? "190px"
              : "170px",
          height:
            title === "CRIF EL" ||
              title == "Cost per Disbursal" ||
              title === "Disbursement"
              ? "120px"
              : "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "2px solid #A0A0A0",
          background: `linear-gradient(90deg,${title == "App Ratio" || title == "Card Ratio"
              ? "#97291E"
              : "#E84A42"
            }  2%, rgba(0,0,0,0)0%)`,
          marginLeft: "-15px",
        }}
      >
        {statsData?.length > 0 ? (
          <div className="justify-content-center align-items-center my-3">
            <div
              className="fw-semibold d-flex justify-content-center"
              style={{
                fontSize:
                  title === "CRIF EL" ||
                    title == "Cost per Disbursal" ||
                    title === "Disbursement"
                    ? "18px"
                    : "15px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
                color: "black",
                fontWeight: "600",
              }}
            >
              {title}
            </div>
            <div
              className="d-flex justify-content-center mb-1"
              style={{
                fontWeight: "bold",
                color:
                  title === "CRIF EL" ||
                    title == "Cost per Disbursal" ||
                    title === "Disbursement"
                    ? "black"
                    : "grey",
                fontSize:
                  title === "CRIF EL" ||
                    title == "Cost per Disbursal" ||
                    title === "Disbursement"
                    ? "20px"
                    : "18px",
                cursor: "pointer",
              }}
            >
              {title === "CPC" ||
                title === "CPA" ||
                title === "CPL" ||
                title === "CPCL" ||
                title == "Cost per Disbursal" ||
                title == "Sanctions" ||
                title === "Disbursement"
                ? ` ₹${Math.round(Number(value)).toLocaleString("en-IN")}`
                : title === "App Ratio" || title === "Card Ratio"
                  ? `${Math.round(Number(value)).toLocaleString("en-IN")}%`
                  : Math.round(Number(value)).toLocaleString("en-IN") || ""}
            </div>
            <div
              className="d-flex justify-content-center"
              // onMouseEnter={() => handleMouseEnter1(baseLine)}
              // onMouseLeave={handleMouseLeave1}
              style={{ cursor: "pointer" }}
              title={
                title === "CPC" ||
                  title === "CPA" ||
                  title === "CPL" ||
                  title === "CPCL" ||
                  title === "CRIF EL" ||
                  title == "Cost per Disbursal" ||
                  title == "Sanctions" ||
                  title === "Disbursement"
                  ? `Baseline : ₹ ${Math.round(baseLine).toLocaleString(
                    "en-IN"
                  )}`
                  : title === "App Ratio" || title === "Card Ratio"
                    ? `Baseline : ${Math.round(baseLine)}%`
                    : `Baseline : ${Math.round(baseLine).toLocaleString("en-IN")}`
              }
            >
              {renderBaseLineComparison(value, title, baseLine)}
            </div>
            {/* </div> */}
          </div>
        ) : (
          <div className="py-5" id={title} style={{ alignSelf: "center" }}>
            <CustomLoader />
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="card h-lg-100 py-4 px-5"
      style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
    >
      <div className="card-title d-flex justify-content-between align-items-center">
        <div className="card-label fw-semibold text-dark fs-6">{title}</div>
        <div>
          <CustomDropdown
            dropdownData={dropdownData}
            selectedItem={selectedItems}
            onItemClick={handleDropdownItemClick}
          />
        </div>
      </div>

      {isLoading ? (
        <div
          className="py-5 d-flex align-items-center justify-content-center"
          style={{ height: "200px", width: "100%" }}
        >
          <CustomLoader />
        </div>
      ) : (
        <div
          className="row gx-xl-12"
          style={{ justifyContent: "space-around" }}
        >
          <div className="col-xl-4 d-flex align-items-center">
            {statsData
              ?.filter(
                (statistic) =>
                  statistic.title == "CRIF EL" ||
                  statistic?.title == "Cost per Disbursal" ||
                  statistic?.title === "Disbursement"
              )
              .map((statistic) => (
                <div className="" key={statistic.title}>
                  {renderItem(statistic)}
                </div>
              ))}
          </div>
          <div className="col-xl-4 d-flex align-items-center row">
            {statsData
              ?.filter(
                (statistic) =>
                  statistic.title !== "CRIF EL" &&
                  statistic?.title !== "Cost per Disbursal" &&
                  statistic?.title !== "Disbursement"
              )
              .map((statistic) => (
                <div className="" key={statistic.title}>
                  {renderItem(statistic)}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
export default SpendsByChannnelStats;
