import React, { useEffect, useRef, useState , useContext } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import CampCard from "./CampCard";
import Leads from "./Leads";
import Website from "./Website";
import BrandAwareness from "./BrandAwareness";
import AppPromotion from "./AppPromotion";
import Product from "./Product";
// import ImproveSales from "./ImproveSales";
import LocalStores from "./LocalStores";
import Goal from "./Goal";
// import ImproveLead from "./ImproveLead";
// import ImproveWebsite from "./ImproveWebsite";
import CampaignType from "./CampaignType";
import Video from "./Video";
import Display from "./Display";
// import { App } from "../../../App";import { useState } from "react";
import display from "../../../../assets/display.png";
import { RiCheckLine } from "react-icons/ri";
import { BsTag } from "react-icons/bs";
import outline from "../../../../assets/outline-preview.png";
import CampTypeCard from "./CampTypeCard";
import ImproveCard from "./ImproveCard";
import CancelContinue from "./CancelContinue";
import StartNewModal from "./StartNewModal";
import { createCampaign } from "../../../modules/application/_requests";
import { useListView } from "../../../modules/apps/user-management/users-list/core/ListViewProvider";
import { response } from "express";
import SelectedTypeContext from './SelectedTypeContext';
import DemoImprove from "./DemoImprove";

// import ImproveCard from "./improveCard";
// import ImproveCard from "./ImproveCard";

type CardData = {
  id: number;
  // icon: string;
  title: string;
  description: string;
};
type TypeCardData = {
  id: number;
  // icon: string;
  title: string;
  description: string;
};

type Props = {
  id: number;
  className?: string;
};



const NewCampaign = () => {
  const selectedTypeCardTitle = useContext(SelectedTypeContext);
  
  const [selectedId, setSelectedId] = useState(-1);
  // const [selectedTypeCardTitle, setSelectedTypeCardTitle] = useState('');
  const [selectedTypeId, setSelectedTypeId] = useState(-1);
  const [selectedCardTitle, setSelectedCardTitle] = useState('');
  // const [selectedTypeCardTitle, setSelectedTypeCardTitle] = useState('');
  const [showCampaignType, setShowCampaignType] = useState(false);
  const [CardClicked, setCardClicked] = useState(false);
  const [EmptyClick, setEmptyClick] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { setItemIdForUpdate } = useListView();
  const { setToastMessage } = useListView();
  const navigate = useNavigate();
console.log(selectedTypeCardTitle,'abc')
  // console.log(selectedTypeCardTitle,"hhhhhhhhhhhkkkkkkkkk")

  const handleCardSelect = (id: number, title: string) => {
    setSelectedId(id);
    setSelectedCardTitle(title);}

    const handleTypeCardSelect = (id: number, title: string) => {
      setSelectedTypeId(id);
      // setSelectedTypeCardTitle(title);
    
  };
  console.log(selectedTypeCardTitle,"hhhhhhhhhhhkkkkkkkkk")

  useEffect(() => {
    document.title = "Camp AI Gain - Detail";
  }, []);
  const btnRef = useRef<HTMLButtonElement | null>(null);

  console.log(selectedId, "aaaaaaaaaaaaaaaaaaaaaaa");
  const { id } = useParams();
  const location = useLocation();
  const state = location.state;

  console.log(state, "hhhhhhh");

  const handleClick = () => {
    setShowCampaignType(true);
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 0);
    // if (containerRef.current) {
    //   containerRef.current.scrollIntoView({ behavior: "smooth" });
    // }
  };

  const handleImproveClick = () => {
    setShowCampaignType(true);
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 0);
    // if (containerRef.current) {
    //   containerRef.current.scrollIntoView({ behavior: "smooth" });
    // }
  };

  const [cardVisibility, setCardVisibility] = useState({
    isImproveSalesVisible: false,
    isImproveLeadVisible: false,
    isImproveWebsiteVisible: false,
    isImproveProductVisible: false,
    isImproveBrandVisible: false,
    isImproveAppVisible: false,
    isImproveGoalVisible: false,
    isSalesClicked: false,
    // EmptyClick: true,
  });

  // const handleCardClickSalesdemo = () => {
  //   setTimeout(() => {
  //     window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
  //   }, 0);
  //   setCardVisibility((prevState) => ({
  //     ...prevState,
  //     // isSalesClicked: cardName === "sales",
  //     isImproveSalesVisible: !prevState.isImproveSalesVisible,
  //     isImproveLeadVisible: false,
  //     isImproveWebsiteVisible: false,
  //     isImproveProductVisible: false,
  //     isImproveBrandVisible: false,
  //     isImproveAppVisible: false,

  //     // <Selector id={1} data-id="1" />
  //   }));
  // };

  const emptyClick = () => {
    if (!CardClicked) {
      setTimeout(() => {
        window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
      }, 0);
      setEmptyClick(true);
    }}
   

  // const TypeOpenClick = () => {

  //     setTimeout(() => {
  //       window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
  //     }, 0);
  //     setShowCampaignType(true);
  // }

  const [campaignId, setCampaignId] = useState(""); // State to store the campaignId
  // const navigate = useNavigate();
  const handleSave=(values:any)=> {
    // if()
    // setTimeout(() => {
    //   window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
    // }, 0);
   
    const saveddata =
    {
      "campaign_id":"",
      "campaignObjective": selectedCardTitle,
      "campaignGoals": [
      {
      "name": "Page views"
      }
      ]
      }
  console.log(saveddata,"savedddddddddd")
  const data = createCampaign(saveddata)
    // fetch('https://apps.trisysit.com/campback/home/createCampaign/page/1', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(saveddata),
    // })
    .then((data) => {
        
      console.log("Campaign Objective Added Successfully!");
      console.log(data,"heyyyyyyyyy");
      setTimeout(() => {
        console.log(undefined);
      }, 2000);
      const campaignId = data.campaign_id;
      setCampaignId(campaignId);

      // const campaignId = data.campaign_id;
      // navigate("/Biding", { state: { campaignId } })
      // const campaignId = data.campaign_id;
     
      setShowCampaignType(true);
      setTimeout(() => {
        window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
      }, 0);
     
    })
    .catch((err) => {
      alert("Request Failed!");

    });

     
      // setShowModal(true);
      

     
  }
  console.log(showModal,"show me modal")

  const handleCardClickSales = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 0);
    setCardClicked(true);
    setEmptyClick(false);
    setCardVisibility((prevState) => ({
      ...prevState,
      // isSalesClicked: cardName === "sales",
      isImproveSalesVisible: !prevState.isImproveSalesVisible,
      // isImproveSalesVisible: false,
      isImproveLeadVisible: false,
      isImproveWebsiteVisible: false,
      isImproveProductVisible: false,
      isImproveBrandVisible: false,
      isImproveAppVisible: false,

      // <Selector id={1} data-id="1" />
    }));
  };
  console.log(id, "jjjjjjjjjjjjjjjjj");
  const handleCardClickLeads = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 0);
    setCardClicked(true);
    setEmptyClick(false);
    setCardVisibility((prevState) => ({
      ...prevState,
      isImproveLeadVisible: !prevState.isImproveLeadVisible,
      isImproveSalesVisible: false,
      isImproveWebsiteVisible: false,
      isImproveProductVisible: false,
      isImproveBrandVisible: false,
      isImproveAppVisible: false,
      // setCardClicked: true,
    }));
  };

  const handleCardClickWebsite = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 0);
    setCardClicked(true);
    setEmptyClick(false);
    setCardVisibility((prevState) => ({
      ...prevState,
      isImproveWebsiteVisible: !prevState.isImproveWebsiteVisible,
      isImproveSalesVisible: false,
      isImproveLeadVisible: false,
      isImproveProductVisible: false,
      isImproveBrandVisible: false,
      isImproveAppVisible: false,
    }));
  };

  const handleCardClickProduct = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 0);
    setEmptyClick(false);
    setCardClicked(true);
    setCardVisibility((prevState) => ({
      ...prevState,
      isImproveProductVisible: !prevState.isImproveProductVisible,
      isImproveSalesVisible: false,
      isImproveLeadVisible: false,
      isImproveWebsiteVisible: false,
      isImproveBrandVisible: false,
      isImproveAppVisible: false,
      setCardClicked: true,
    }));
  };

  const handleCardClickBrand = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 0);
    setCardClicked(true);
    setEmptyClick(false);
    setCardVisibility((prevState) => ({
      ...prevState,
      isImproveBrandVisible: !prevState.isImproveBrandVisible,
      isImproveSalesVisible: false,
      isImproveLeadVisible: false,
      isImproveWebsiteVisible: false,
      isImproveProductVisible: false,
      isImproveAppVisible: false,
    }));
  };

  const handleCardClickApp = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 0);
    setCardClicked(true);
    setEmptyClick(false);
    setCardVisibility((prevState) => ({
      ...prevState,
      isImproveAppVisible: !prevState.isImproveAppVisible,
      isImproveSalesVisible: false,
      isImproveLeadVisible: false,
      isImproveWebsiteVisible: false,
      isImproveProductVisible: false,
      isImproveBrandVisible: false,
    }));
  };

  const handleCardClickGoal = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 0);
    setCardClicked(true);
    setEmptyClick(false);
    setCardVisibility((prevState) => ({
      ...prevState,
      isImproveGoalVisible: !prevState.isImproveGoalVisible,
      isImproveSalesVisible: false,
      isImproveLeadVisible: false,
      isImproveWebsiteVisible: false,
      isImproveProductVisible: false,
      isImproveBrandVisible: false,
      isImproveAppVisible: false,
    }));
  };

  // {!setCardVisibility ?
  //   <CancelContinue onClick={emptyClick}/>
  //   :null}

  return (
    <>
      <div className="container px-10 mb-10">
        <div
          className="pb-3 pt-5"
          style={{
            backgroundColor: "white",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
            borderRadius: "10px",
          }}
        >
          <span
            className="fw-bold text-dark mx-10"
            style={{ fontSize: "16px" }}
          >
            Choose your objective
          </span>
          <hr
            style={{
              margin: "10px,0",
              border: "1px solid #CCCCCC",
              width: "100%",
            }}
          />
          <span
            className="pt-10 px-10"
            style={{ color: "#5f6368", fontSize: "14px" }}
          >
            Select an objective to tailor your experience to the goals and
            settings that will work best for your campaign
          </span>
          <div className="px-7">
            <div className="row gy-5 gx-xl-12 mb-6 mt-2 d-flex">
              <div className="col-md-3" onClick={handleCardClickSales}>
                <CampCard
                  id={1}
                  data-id="1"
                  selectedCard={selectedId}
                  onSelect={handleCardSelect}
                />
              </div>
              <div className="col-md-3 " onClick={handleCardClickLeads}>
                <CampCard
                  id={2}
                  data-id="2"
                  selectedCard={selectedId}
                  onSelect={handleCardSelect}
                />
              </div>
              <div className="col-md-3 " onClick={handleCardClickWebsite}>
                <CampCard
                  id={3}
                  data-id="3"
                  selectedCard={selectedId}
                  onSelect={handleCardSelect}
                />
              </div>
              <div className="col-md-3" onClick={handleCardClickProduct}>
                <CampCard
                  id={4}
                  data-id="4"
                  selectedCard={selectedId}
                  onSelect={handleCardSelect}
                />
              </div>
            </div>

            <div className="row row gy-5 gx-md-12 mb-7">
              <div className="col-md-3" onClick={handleCardClickBrand}>
                <CampCard
                  id={5}
                  data-id="5"
                  selectedCard={selectedId}
                  onSelect={handleCardSelect}
                />
              </div>
              <div className="col-md-3 " onClick={handleCardClickApp}>
                <CampCard
                  id={6}
                  data-id="6"
                  selectedCard={selectedId}
                  onSelect={handleCardSelect}
                />
              </div>
              <div className="col-md-3 ">
                <CampCard
                  id={7}
                  data-id="7"
                  selectedCard={selectedId}
                  onSelect={handleCardSelect}
                />
              </div>
              <div className="col-md-3 " onClick={handleCardClickGoal}>
                <CampCard
                  id={8}
                  data-id="8"
                  selectedCard={selectedId}
                  onSelect={handleCardSelect}
                />
              </div>
            </div>
            <div>
              {" "}
              {EmptyClick ? (
                <p style={{ color: "red" }}>Please select a goal</p>
              ) : null}
            </div>
          </div>
        </div>
        {!CardClicked && <CancelContinue onClick={emptyClick} />}


        {(cardVisibility.isImproveSalesVisible || cardVisibility.isImproveLeadVisible || cardVisibility.isImproveWebsiteVisible) && (
          <> <div className="px-5 pt-10 pb-3">
            <div className="row gy-5 gx-xl-10 mb-1">
              {cardVisibility.isImproveSalesVisible && <DemoImprove title="Sales" />}
              {/* {!cardVisibility.isImproveSalesVisible && (<CancelContinue onClick={() => handleCardClickGoal()} />)} */}
              {cardVisibility.isImproveLeadVisible && <ImproveCard title="Leads" />}
              {cardVisibility.isImproveWebsiteVisible && <ImproveCard title="Website" />}
            </div></div>
            {/* {!showCampaignType && (<CancelContinue onClick={handleImproveClick} />)} */}
            {!showCampaignType && <CancelContinue onClick={handleSave} />}
            {showCampaignType && <CampaignType campaignId={campaignId} selectedCardTitle={selectedCardTitle}/>}
</>
        )}

{/* {!CardClicked && <CancelContinue onClick={TypeOpenClick} />} */}
{/* {showCampaignType && <CancelContinue onClick={handleSave} />} */}


        {cardVisibility.isImproveProductVisible && (
          <>
            <div
              className="pb-3 pt-5 mt-10"
              style={{
                backgroundColor: "white",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
                borderRadius: "10px",
              }}
            >
              <span
                className="fw-bold text-dark mx-5"
                style={{ fontSize: "16px" }}
              >
                Select a campaign type
              </span>
              <hr
                style={{
                  // marginTop: "-10px",
                  border: "1px solid #CCCCCC",
                }}
              />
              <div className="px-7 py-5">
                <div className="row gy-5 gx-xl-10 mb-1">
                  <div className="col-xl-3 ">{cardVisibility.isImproveProductVisible && <CampTypeCard id={15} data-id="5" selectedCard={selectedId} onSelect={handleTypeCardSelect} />}</div>
                </div></div>
            </div>
            {/* <CancelContinue onClick={handleClick} /> */}
          </>
        )}

        {cardVisibility.isImproveBrandVisible && (
          <>
            <div
              className="pb-3 pt-5 mt-10"
              style={{
                backgroundColor: "white",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
                borderRadius: "10px",
              }}
            >
              <span
                className="fw-bold text-dark mx-5"
                style={{ fontSize: "16px" }}
              >
                Select a campaign type
              </span>
              <hr
                style={{
                  // marginTop: "-10px",
                  border: "1px solid #CCCCCC",
                }}
              />
              <div className="px-7 py-5">
                {/* <div className="row gy-5 gx-xl-12 mb-1">
                  <div className="col-xl-3 " >{cardVisibility.isImproveBrandVisible &&
                    <CampTypeCard id={13} data-id="3" selectedCard={selectedId} onSelect={handleCardSelect} />}</div>
                  <div className="col-xl-3 ">{cardVisibility.isImproveBrandVisible &&
                    <CampTypeCard id={15} data-id="5" selectedCard={selectedId} onSelect={handleCardSelect} />}</div>
                </div> */}
                </div>
            </div>
            {/* <CancelContinue onClick={handleClick} /> */}
          </>
        )}

        {/* app promotion */}

        {cardVisibility.isImproveAppVisible && (
          <>
            <div
              className="container py-10 px-7"
              style={{
                backgroundColor: "white",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
                borderRadius: "10px",
                marginTop: "30px",
              }}
            >
              <div className="row gy-5 gx-xl-10 mb-1">
                <div className="col-md-3 " onClick={handleCardClickApp}>
                  <CampCard
                    id={6}
                    data-id="6"
                    selectedCard={selectedId}
                    onSelect={handleCardSelect}
                  />
                </div>
              </div>
            </div>
            {/* <CancelContinue onClick={handleClick} /> */}

          </>
        )}
        {/* {!showCampaignType && (
      <div className="gap-2 mt-5 d-flex" style={{ justifyContent: "flex-end" }}>
        <a
          href="#"
          className="btn btn-sm fw-bold btn-color-gray-700 btn-active-color-primary"
        >
          Cancel
        </a>
        <a
          href="#"
          className="btn btn-sm fw-bold btn-primary"
          onClick={handleClick}
        >
          Continue
        </a>
      </div>

    )} */}
      </div>
      

      {/* {showModal && <StartNewModal/> } */}

    </>
  );
};

export default NewCampaign;
