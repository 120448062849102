/**
 * /* eslint-disable react/jsx-no-target-blank
 *
 * @format
 */

import { useEffect } from "react";
import { ILayout, useLayout } from "../../core";

const Footer = () => {
	const { config } = useLayout();
	useEffect(() => {
		updateDOM(config);
	}, [config]);
	return (
		<>
			<div className="d-flex justify-content-between align-items-center w-100">
				<div className="text-dark ">
					<span className="text-muted fw-semibold me-1">2023&copy;</span>
					<a
						href="https://www.trisysit.com"
						target="_blank"
						className="text-gray-800 text-hover-primary"
					>
						trisysit
					</a>
				</div>
				<div>
					<ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
						<li className="menu-item">
							<a
								href="https://trisysit.com/"
								target="_blank"
								className="menu-link px-2"
							>
								About
							</a>
						</li>

						<li className="menu-item">
							<a
								href="https://www.trisysit.com/"
								target="_blank"
								className="menu-link px-2"
							>
								Support
							</a>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
};

const updateDOM = (config: ILayout) => {
	if (config.app?.footer?.fixed?.desktop) {
		document.body.classList.add("data-kt-app-footer-fixed", "true");
	}

	if (config.app?.footer?.fixed?.mobile) {
		document.body.classList.add("data-kt-app-footer-fixed-mobile", "true");
	}
};

export { Footer };
