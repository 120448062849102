import React, { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import { ChartTheme } from "../../../../theme/assets/ts/_utils/ChartTheme";
import {
  getDataFromBackend,
  URLS,
} from "../../../modules/dashboards/DashboardRequests";
import CustomLoader from "./CustomLoader";
import Modal from "./Modal";
import { AiOutlineZoomIn } from "react-icons/ai";
import CustomDropdown from "./CustomDropdown";

type Props = {
  chartID: string;
  productId: string;
  startDate: string;
  endDate: string;
  channelType: string;
  campaignId: string;
  title: string;
  subUrl: string;
  platformId: string;
  productType: string;
};
const AmFunnelChart: React.FC<Props> = ({
  productId,
  chartID,
  startDate,
  endDate,
  channelType,
  campaignId,
  title,
  subUrl,
  platformId,
  productType,
}) => {
  interface DataEntry {
    stage: string;
    applicants?: any;
    order: number;
  }
  interface DataEntryMock {
    data: {
      applicants: any;
      order: number;
      stage: string;
    }[];
    dataFor: string;
  }

  const [funnelData, setFunnelData] = useState<DataEntry[]>([]);
  const [mockData, setMockData] = useState<DataEntryMock[]>([]);
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string>(
    productType || "All"
  );
  const [dropdownData, setDropdownData] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleDropdownItemClick = (data: string) => {
    setSelectedItem(data);
  };

  const myChartId = isZoomModalOpen ? `${chartID}-modal` : chartID;

  useEffect(() => {
    if (dropdownData?.includes(productType)) {
      setSelectedItem(productType);
    }
  }, [productType]);

  const getData = async () => {
    setIsLoading(true);
    let requestData = {
      productId,
      startDate,
      endDate,
      campaignId,
      platformId,
      productType,
    };
    let response = await getDataFromBackend(
      requestData,
      URLS.BASE_URL + channelType + subUrl
    );
    // let response = await getDataFromBackend(requestData, URLS.GET_CAMPAIGN_REACHENGAGECONVERT);
    console.log(
      "Response of funnel by changing producrType : ",
      response?.data,
      URLS.BASE_URL + channelType + subUrl
    );
    let data = response?.data;

    let newData = data.map((item) => {
      return {
        ...item,
        data: item?.data?.filter(
          (entry) => entry.applicants !== "0" && entry.applicants !== 0
        ),
      };
    });

    setIsLoading(false);

    if (data && channelType == "campaign") {
      const applicantsArray = data.map(entry => entry.applicants);
      const eligibleLeadsEntry = Math.max(...applicantsArray);
      
      const newData = data.map(item => ({
        ...item,
        applicants: parseFloat(item?.applicants),
        percentageChange: eligibleLeadsEntry > 0 ? ((item.applicants / eligibleLeadsEntry) * 100).toFixed(2) : 0,
      }));
      // data = data.map((item) => ({
      //   ...item,
      //   applicants: parseFloat(item.applicants),
      // }));
      setFunnelData(newData);
    } else if (data && channelType !== "campaign") {
      console.log("Response of funnel by changing producrType : mock", newData);

      setMockData(newData);
    } else {
      return;
    }
  };

  useEffect(() => {
    getData();
    return () => {};
  }, [productId, platformId, campaignId, startDate, endDate, productType]);

  useEffect(() => {
    if (mockData?.length > 0) {
      // const mockDropdown = Array.from(
      //   new Set(mockData?.map((item) => item.dataFor))
      // );
      // setDropdownData(mockDropdown);

      // const selectedData = mockData?.filter(
      //   (item) => item.dataFor === selectedItem
      // );

      // Find the 'Eligible Leads' value
      const eligibleLeadsEntry = Math.max(...mockData[0]?.data?.map(entry => entry.applicants));

      
      const eligibleLeadsValue = eligibleLeadsEntry;
      
      const newData = mockData[0]?.data.map((item, index) => ({
        ...item,
        applicants: parseFloat(item?.applicants),
        percentageChange: 
        ((item.applicants / eligibleLeadsEntry) * 100).toFixed(2)
          
        }));
      setFunnelData(newData);
      setSelectedItem(productType);
    }
  }, [mockData, selectedItem]);

  useEffect(() => {
    let root = am5.Root.new(myChartId);
    root._logo?.dispose();
    root.setThemes([ChartTheme.getReverseGradientTheme(root)]);
    var chart = root.container.children.push(
      am5percent.SlicedChart.new(root, {
        layout: root.horizontalLayout,
      })
    );

    const data = funnelData;

    // Create series
    var series = chart.series.push(
      am5percent.FunnelSeries.new(root, {
        name: "Series",
        valueField: "applicants",
        categoryField: "stage",
        orientation: "vertical",
        legendValueText: "percentageChange",
        alignLabels: true,
        bottomRatio: 1,
        startLocation: 0.2,
        endLocation: 0.8,
      })
    );

    series.data.setAll(data);

    // series.get("colors").set("colors", [
    //   am5.color(0x095256),
    //   am5.color(0x087f8c),
    //   am5.color(0x5aaa95),
    //   am5.color(0x86a873),
    //   am5.color(0xbb9f06)
    // ]);

    // series.slices.template.set("fillGradient", am5.LinearGradient.new(root, {
    //   stops: [{
    //     color: am5.color(0xfacba6)
    //   }, {
    //     color: am5.color(0xE84A42)
    //   }],
    //   rotation: 90
    // }));

    // series.slices.template.cornerRadius = 20;
    series.ticks.template.setAll({
      stroke: am5.color(0xe8e8e8),
      strokeWidth: 2,
    });

    series.labels.template.set(
      "text",
      "{category}: [bold]{dataItem.dataContext.percentageChange}%[/]"
    );
    series.ticks.template.setAll({
      stroke: am5.color(0x000917),
      strokeWidth: 1,
    });

    series.links.template.setAll({
      height: 0,
    });

    series.slices.template.set("tooltipText", "{category}: [bold]{value}");
    // series.slices.template.tooltipText = "{category}: {value}";
    // series.slices.template.set("fill", am5.color(0xE84A42));
    // series.slices.template.setAll({
    //   // fillOpacity: 0.5,
    //   // strokeWidth: 0,
    //   templateField: "slicecolor"
    // });

    // series.get("colors").set("colors", [
    //   am5.color(0x095256),
    //   am5.color(0x087f8c),
    //   am5.color(0x5aaa95),
    //   am5.color(0x86a873),
    //   am5.color(0xbb9f06),
    // ]);
    return () => {
      root.dispose();
    };
  }, [myChartId, funnelData]);

  const chartContainerStyle = {
    height: "200%",
    width: "100%",
  };

  return (
    <div
      className="card card-flush h-100"
      style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
    >
      <div className="pt-4 px-4">
        <div className="card-title d-flex justify-content-between align-items-center">
          <div className="card-label fw-bold text-dark fs-4">{title}</div>
          {/* {channelType !== "campaign" && (
            <div>
              <CustomDropdown
                dropdownData={dropdownData}
                selectedItem={selectedItem}
                onItemClick={handleDropdownItemClick}
              />
            </div>
          )} */}
          {channelType == "newproduct" && (
            <div
              className="fw-bold"
              style={{
                fontSize: "12px",
                textAlign: "end",
                cursor: "pointer",
              }}
              onClick={() => setIsZoomModalOpen(true)}
            >
              <AiOutlineZoomIn size={25} />
            </div>
          )}
        </div>
      </div>
      {isLoading ? (
        <div
          className="py-5 d-flex align-self-center justify-content-center min-vh-50"
          id={myChartId}
          // style={chartContainerStyle}
        >
          <CustomLoader />
        </div>
      ) : (
        <div
          className="card-body d-flex justify-content-between flex-column pt-1 px-5 pb-0"
          style={{ margin: "-100px,0", position: "relative" }}
        >
          {!isZoomModalOpen && !isLoading ? (
            <div id={myChartId} style={{ height: "100%", width: "100%" }}></div>
          ) : (
            <Modal
              isOpen={isZoomModalOpen}
              onClose={() => setIsZoomModalOpen(!isZoomModalOpen)}
              title={title}
            >
              <div
                id={myChartId}
                style={{ height: "600px", width: "100%" }}
              ></div>
            </Modal>
          )}
        </div>
      )}
    </div>
  );
};

export default AmFunnelChart;
