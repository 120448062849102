/** @format */

import { KTSVG } from "../../../../../../../theme/helpers";
import { useListView } from "../../core/ListViewProvider";
import { UsersListFilter } from "./UsersListFilter";
import {FaPlus} from 'react-icons/fa'

const UsersListToolbar = (props: any) => {
	const { setItemIdForUpdate } = useListView();
	const openAddUserModal = () => {
		setItemIdForUpdate(null);
	};

	return (
		<div
			className="d-flex justify-content-end"
			data-kt-user-table-toolbar="base"
			title={props.title}
		>
			{/* <UsersListFilter /> */}

			{/* begin::Export */}
			{/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
			{/* end::Export */}

			{/* begin::Add user */}
			<button
				type="button"
				className="btn text-white btn-primary"
				onClick={openAddUserModal}
				// style={{backgroundColor:"#333E58"}}

			> 
				{/* <KTSVG
					path="/media/icons/duotune/arrows/arr075.svg"
					className="svg-icon-2"
				/> */}
				<FaPlus style={{marginRight:'5'}} size={15}/>
				{props.title}
			</button>
			
			
			{/* end::Add user */}
		</div>
	);
};

export { UsersListToolbar };
