import React, { useEffect, useMemo, useState } from "react";
import {
    AiOutlineFieldTime,
    AiOutlineProject,
    AiTwotoneCalendar,
} from "react-icons/ai";
import { Link, useLocation } from 'react-router-dom';
import { IoLocationOutline, IoPeople } from "react-icons/io5";
import { FaBusinessTime, FaCircle, FaEdit, FaEye, FaRegUser, FaSearch, FaTimes } from "react-icons/fa";
import { BsCalendarPlus, BsFiletypeXls } from "react-icons/bs";
// import { useAuth } from "../../modules/auth";
import { useNavigate } from "react-router-dom";
// import { colors } from "./colors";
import { IoMdAddCircleOutline, IoMdArrowDropdown } from "react-icons/io";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BiSolidFilePdf } from "react-icons/bi";
import { primaryColors } from "../../../../theme/assets/ts/_utils/AppColors";
import GeneralDropdown from "./GeneralDropdown";
import { MdOutlineCancel } from "react-icons/md";
import GeneralDatePicker from "./GeneralDatePicker";
import GeneralMultiSelectDropdown from "./GeneralMultiSelectDropdown";
import GeneralInputBox from "./GeneralInputBox";
import {
    URLS,
    getCampaignList
  } from "../../../modules/dashboards/DashboardRequests";

  const API_BASE_URL = process.env.REACT_APP_API_URL;

function RuleCreate() {

    const params = useLocation();
    const navigate = useNavigate();
    console.log("params in incident detail", params.state);
    const ruleData = params?.state?.item
    console.log('ruleitem: ', ruleData);

    // const formatDate = (dateString: any) => {
    //     try {
    //         if (!dateString) {
    //             return null;
    //         }

    //         const dateObject = new Date(dateString);

    //         if (isNaN(dateObject.getTime())) {
    //             throw new Error("Invalid date");
    //         }

    //         // Format date as yyyy-mm-dd
    //         const formattedDate = dateObject.toISOString().split('T')[0];
    //         console.log("Formatted Date:", formattedDate);
    //         return formattedDate;
    //     } catch (error) {
    //         console.error("Error formatting date:", (error as Error).message);
    //         return null; // Return null for invalid dates
    //     }
    // };
    interface Rule {
        "id": string;
        "name": string;
        "priority": string;
        "initiationType": string;
        "parameter": string;
        "metric": string;
        "conditions": string;
        "action": string;
        "triggerTime": string;
        "validFrom"?: any;
        "validTo"?: any;
        "createdBy": string;
        "createdDate"?: string;
        "updatedBy"?: string;
        "updatedDate": string;
        "status": string;
        "linkedCampaigns"?: string;
        "valueFrom": string;
        "valueTo": string;
        "email": string;
        "percentage":string,
        "targetValue":string
    }

    const demoRuleDetailData = {
        "id": "088d1ad5-9d2c-415d-b270-f84e709f055c",
        "name": "test",
        "priority": "high",
        "initiationType": "pooling",
        "parameter": "cpc",
        "metric": " ",
        "conditions": "<",
        "action": "no",
        "triggerTime": "2024-01-08T10:41:05.000+00:00",
        "validFrom": "2024-01-08T00:00:00.000+00:00",
        "validTo": "2024-01-08T00:00:00.000+00:00",
        "createdBy": "me",
        "createdDate": "2024-01-08T12:04:03.000+00:00",
        "updatedBy": "me",
        "updatedDate": "2024-01-08T12:04:03.000+00:00",
        "status": "ACTIVE",
        "linkedCampaigns": " ",
        "valueFrom": " ",
        "valueTo": " ",
        "email": " ",
        "percentage":"",
        "targetValue":""
    }

    const [ruleDetailData, setRuleDetailData] = useState<Rule>();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://apps.trisysit.com/rulesengine/rules/getrules");
                // const response = await fetch("http://10.10.1.45:8087/trisys/demo/api/v0/listincident");

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const allData = await response.json();
                console.log('Rule Data newdata:', allData);

                // Filtering data based on incident_id
                const filteredData = allData.filter((item) => item?.id === ruleData?.id);

                // Checking if there is at least one item in the filteredData array
                if (filteredData.length > 0) {
                    const firstItem = filteredData[0];
                    // const parsedIncidentDetailData = JSON.parse(firstItem?.incident_data);

                    setRuleDetailData(firstItem);
                } else {
                    // Handling the case where no matching incident is found
                    console.warn('No matching incident found');
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [ruleData?.incident_id]);

    console.log(ruleDetailData, 'ddata')

    const [filterApplied, setFilterApplied] = useState(false);

    const [selectedRuleId, setSelectedRuleId] = useState(ruleData ? ruleData.id : '');


    const [selectedRuleName, setSelectedRuleName] = useState(ruleData ? ruleData.name : '');
    const ruleNames = ['Rule 1', 'Rule 2', 'Rule 3'];

    const [selectedStatus, setSelectedStatus] = useState(ruleData ? ruleData.status : '');
    const statuses = ['Active', 'Inactive'];

    const [selectedValidFrom, setSelectedValidFrom] = useState<any>(null);

    const [selectedValidTo, setSelectedValidTo] = useState<any>(null);
    // ruleDetailData?ruleDetailData.validFrom:

    const [selectedLinkedCampaign, setSelectedLinkedCampaign] = useState<any>([]);
    const linkedCampaigns = ['Camp 1', 'Camp 2', 'Camp 3', 'Camp 4', 'Camp 5', 'Camp 6', 'Camp 7'];

    const [percentage, setPercentage] = useState<any>(ruleData ? ruleData.percentage : '');
    const [targetValue, setTargetValue] = useState<any>(ruleData ? ruleData.targetValue : '');

    const [selectedPriority, setSelectedPriority] = useState(ruleData ? ruleData.priority : '');
    const priorities = ['high', 'medium', 'low'];

    const [selectedMetricName, setSelectedMetricName] = useState(ruleData ? ruleData.metric : '');
    const metricNames = ['COST', 'LEADS', 'CPC', 'CPA','CLICKS'];

    const [selectedOperation, setSelectedOperation] = useState(ruleData ? (ruleData?.conditions === '>' ? 'Greater than' : (ruleDetailData?.conditions === '<' ? 'Less than' : 'Between')) : '');
    const operations = ['Greater than', 'Less than', 'Between'];

    const [valueFrom, setValueFrom] = useState<any>(ruleData ? ruleData.valueFrom : '');

    const [valueTo, setValueTo] = useState<any>(ruleData ? ruleData.valueTo : '');

    const [selectedAction, setSelectedAction] = useState(ruleData ? ruleData.action : '');
    const actions = ['Email', 'SMS'];

    const [email, setEmail] = useState<any>(ruleData ? ruleData.email : '');

    const [selectedEmailTemplate, setSelectedEmailTemplate] = useState('');
    const emailTemplates = ['Template 1', 'Template 2', 'Template 3'];

    const [formattedValidFrom, setFormattedValidFrom] = useState("");
    const [formattedValidTo, setFormattedValidTo] = useState("");
    const [campaigndata, setCampaignData] = useState<any>([]);
    const [check, setCheck] = useState([]);

    console.log(selectedValidFrom, selectedPriority, 'vfrom');

    useEffect(() => {
        if (ruleDetailData) {
            setSelectedRuleId(ruleDetailData?.id);
            setSelectedRuleName(ruleDetailData?.name);
            setSelectedStatus(ruleDetailData.status);
            setSelectedValidFrom(new Date(ruleDetailData.validFrom));
            setSelectedValidTo(new Date(ruleDetailData.validTo));
            setFormattedValidFrom(new Date(ruleDetailData.validFrom).toLocaleString());
            setFormattedValidTo(new Date(ruleDetailData.validTo).toLocaleString());
            setSelectedLinkedCampaign(ruleDetailData?.linkedCampaigns);
            setSelectedPriority(ruleDetailData?.priority);
            setSelectedMetricName(ruleDetailData?.metric);
            setSelectedOperation(ruleDetailData?.conditions === '>' ? 'Greater than' : (ruleDetailData?.conditions === '<' ? 'Less than' : 'Between'));
            setValueFrom(ruleDetailData?.valueFrom);
            setPercentage(ruleDetailData?.percentage);
            setTargetValue(ruleDetailData?.targetValue);
            setValueTo(ruleDetailData?.valueTo);
            setSelectedAction(ruleDetailData?.action);
            setEmail(ruleDetailData?.email);
            setSelectedEmailTemplate(ruleDetailData?.email);
        }
    }, [ruleDetailData]);

    useEffect(() => {
        if (selectedValidFrom) {
            setFormattedValidFrom(selectedValidFrom.toLocaleString());
        }
    }, [selectedValidFrom]);

    useEffect(() => {
        if (selectedValidTo) {
            setFormattedValidTo(selectedValidTo.toLocaleString());
        }
    }, [selectedValidTo]);

    const handleStatusChange = (data) => {
        setFilterApplied(false);
        setSelectedStatus(data);
    };

    const handlePriorityChange = (data) => {
        setFilterApplied(false);
        setSelectedPriority(data);
    };

    const handleMetricNameChange = (data) => {
        setFilterApplied(false);
        setSelectedMetricName(data);
    };

    const handleValidFromChange = (data) => {
        setFilterApplied(false);
        setSelectedValidFrom(data);
    };

    const handleValidToChange = (data) => {
        setFilterApplied(false);
        setSelectedValidTo(data);
    };

    const handleLinkedCampaignChange = (data) => {
        setFilterApplied(false);
        setSelectedLinkedCampaign(data);
    };

    const handleOperationChange = (data) => {
        setFilterApplied(false);
        // const operation = data === 'Greater than' ? 'GREATER' :
        //     data === 'Less than' ? 'LESSER' :
        //         data === 'Between' ? 'BETWEEN' : '';
        setSelectedOperation(data);
    };

    const handleActionChange = (data) => {
        setFilterApplied(false);
        setSelectedAction(data);
    };

    const handleEmailTemplateChange = (data) => {
        setFilterApplied(false);
        setSelectedEmailTemplate(data);
    };

    const createRule = async () => {
        const formatDate = (dateString) => {
            if (typeof dateString !== 'string') {
                return dateString;
            }
            const [datePart, timePart] = dateString.split(', ');
            const [day, month, year] = datePart.split('/');
            const formattedTimePart = "00:00:00";
            const isoDateString = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${formattedTimePart}.000+00:00`;

            return isoDateString;
        };

        const formattedValidFrom = formatDate(selectedValidFrom);
        const formattedValidTo = formatDate(selectedValidTo);
        const saveUpdateUrl = "rules/saveorupdaterule"
        const url = `${URLS.BASE_URL}${saveUpdateUrl}`;
        const requestBody = [
            {
                "id": selectedRuleId,
                "name": selectedRuleName,
                "priority": selectedPriority,
                "metric": selectedMetricName,
                "conditions": selectedOperation,
                "action": selectedAction,
                "validFrom": formattedValidFrom,
                "validTo": formattedValidTo,
                "createdBy": "me",
                "status": selectedStatus,
                "linkedCampaigns": selectedLinkedCampaign.join(','),
                "valueFrom": valueFrom,
                "valueTo": valueTo,
                "email": email,
                "percentage":percentage,
                "targetValue":targetValue
            }
        ];

        console.log(requestBody, 'reqq');

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error('Failed to create rule');
            }

            const data = await response.json();
            console.log('Rule created successfully:', data);
            navigate(-1)
        } catch (error) {
            console.error('Error creating rule:', error);
        }
    };

    useEffect(() => {
        console.log("UseEffect");
        fetchCampaignData();
    }, []);
    const fetchCampaignData = async () => {
        try {
            let response: any = await getCampaignList();
            const result =  response?.data;
            console.log("result",result)
            setCampaignData(result);
           
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    console.log(campaigndata, "campaigndata")

    const dropdownItems: string[] = campaigndata.map((campaign) => campaign.campaign);

    const cancelRule = () => {
        if (ruleDetailData) {
            setSelectedRuleId(ruleDetailData?.id);
            setSelectedRuleName(ruleDetailData?.name);
            setSelectedStatus(ruleDetailData.status);
            setSelectedValidFrom(new Date(ruleDetailData.validFrom));
            setSelectedValidTo(new Date(ruleDetailData.validTo));
            setFormattedValidFrom(new Date(ruleDetailData.validFrom).toLocaleString());
            setFormattedValidTo(new Date(ruleDetailData.validTo).toLocaleString());
            setSelectedLinkedCampaign(ruleDetailData?.linkedCampaigns);
            setSelectedPriority(ruleDetailData?.priority);
            setSelectedMetricName(ruleDetailData?.metric);
            setSelectedOperation(ruleDetailData?.conditions === '>' ? 'Greater than' : (ruleDetailData?.conditions === '<' ? 'Less than' : 'Between'));
            setValueFrom(ruleDetailData?.valueFrom);
            setPercentage(ruleDetailData?.percentage);
            setTargetValue(ruleDetailData?.targetValue);
            setValueTo(ruleDetailData?.valueTo);
            setSelectedAction(ruleDetailData?.action);
            setEmail(ruleDetailData?.email);
            setSelectedEmailTemplate(ruleDetailData?.email);
        }
        else {
            setSelectedRuleId('');
            setSelectedRuleName('');
            setSelectedStatus('');
            setSelectedValidFrom(null);
            setSelectedValidTo(null);
            setFormattedValidFrom('');
            setFormattedValidTo('');
            setSelectedLinkedCampaign('');
            setSelectedPriority('');
            setSelectedMetricName('');
            setSelectedOperation('');
            setValueFrom('');
            setPercentage('');
            setTargetValue('');
            setValueTo('');
            setSelectedAction('');
            setEmail('');
            setSelectedEmailTemplate('');
        }
        navigate(-1);
    }



    return (
        <>
            <div className=''>

                {/* heading row */}

                <div className="d-flex align-items-center mb-7" style={{ marginTop: '-27px', }}>
                    <div className="" style={{ color: primaryColors.grey, fontWeight: 'bold', fontSize: '25px' }}>{ruleData ? 'Edit Rule' : 'Create Rule'}</div>
                </div>


                {/* filters */}

                <div
                    className='mb-10 px-5 pb-7 pt-7'
                    style={{
                        marginTop: 10,
                        backgroundColor: '#fff',
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
                        // overflow: 'hidden',
                        borderRadius: 5,
                        // padding: 10,
                    }}>

                    <div className="row gy-5 px-0 mb-5">

                        <div className="col-xl-3" >
                            <div className="fs-4 fw-semibold ps-7" style={{ color: primaryColors.black }}>Rule Name</div>
                            <GeneralInputBox
                                selectedValue={selectedRuleName}
                                onChange={(e) => setSelectedRuleName(e.target.value)}
                                title={'Type'}
                            />
                        </div>

                        <div className="col-xl-3">
                            <div className="fs-4 fw-semibold ps-7" style={{ color: primaryColors.black }}>Status</div>
                            <GeneralDropdown
                                selectedValue={selectedStatus}
                                dropdownItems={statuses}
                                onDropdownItemClick={handleStatusChange}
                                title={'Select'}
                            />
                        </div>

                        <div className="col-xl-3">
                            <div className="fs-4 fw-semibold ps-7" style={{ color: primaryColors.black }}>Valid From</div>
                            <GeneralDatePicker
                                selectedValue={selectedValidFrom}
                                onDropdownItemClick={handleValidFromChange}
                                title={'Select'}
                            />
                        </div>

                        <div className="col-xl-3">
                            <div className="fs-4 fw-semibold ps-7" style={{ color: primaryColors.black }}>Valid To</div>
                            <GeneralDatePicker
                                selectedValue={selectedValidTo}
                                onDropdownItemClick={handleValidToChange}
                                title={'Select'}
                            />
                        </div>

                        <div className="col-xl-3">
                            <div className="fs-4 fw-semibold ps-7" style={{ color: primaryColors.black }}>Linked Campaigns</div>
                            <GeneralMultiSelectDropdown
                                selectedValue={selectedLinkedCampaign}
                                dropdownItems={dropdownItems}
                                onDropdownItemClick={handleLinkedCampaignChange}
                                title={'Select'}
                            />
                        </div>

                        <div className="col-xl-3">
                            <div className="fs-4 fw-semibold ps-7" style={{ color: primaryColors.black }}>Priority</div>
                            <GeneralDropdown
                                selectedValue={selectedPriority}
                                dropdownItems={priorities}
                                onDropdownItemClick={handlePriorityChange}
                                title={'Select'}
                            />
                        </div>

                    </div>
                    <div>
                        <div className="fs-3 fw-bold ps-7 py-5" style={{ color: 'black' }}>Metrics</div>

                        <div className="row gy-5 px-0 mb-5">
                            <div className="col-xl-3">
                                <div className="fs-4 fw-semibold ps-7" style={{ color: 'black' }}>Name</div>
                                <GeneralDropdown
                                    selectedValue={selectedMetricName}
                                    dropdownItems={metricNames}
                                    onDropdownItemClick={handleMetricNameChange}
                                    title={'Select'}
                                />
                            </div>

                            <div className="col-xl-3">
                                <div className="fs-4 fw-semibold ps-7" style={{ color: 'black' }}>Operation</div>
                                <GeneralDropdown
                                    selectedValue={selectedOperation}
                                    dropdownItems={operations}
                                    onDropdownItemClick={handleOperationChange}
                                    title={'Select'}
                                />
                            </div>

                            {selectedOperation === 'Between' || selectedOperation === '' ? (
                                <>
                                    <div className="col-xl-3">
                                        <div className="fs-4 fw-semibold ps-7" style={{ color: 'black' }}>Value From</div>
                                        <GeneralInputBox
                                            selectedValue={valueFrom}
                                            onChange={(e) => setValueFrom(e.target.value)}
                                            title={'Enter Number'}
                                        />
                                    </div>

                                    <div className="col-xl-3">
                                        <div className="fs-4 fw-semibold ps-7" style={{ color: 'black' }}>Value To</div>
                                        <GeneralInputBox
                                            selectedValue={valueTo}
                                            onChange={(e) => setValueTo(e.target.value)}
                                            title={'Enter Number'}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="col-xl-3">
                                        <div className="fs-4 fw-semibold ps-7" style={{ color: 'black' }}>Value (in%)</div>
                                        <GeneralInputBox
                                            selectedValue={percentage}
                                            onChange={(e) => setPercentage(e.target.value)}
                                            title={'Enter Number'}
                                        />
                                    </div>

                                    <div className="col-xl-3">
                                        <div className="fs-4 fw-semibold ps-7" style={{ color: 'black' }}>Target Value</div>
                                        <GeneralInputBox
                                            selectedValue={targetValue}
                                            onChange={(e) => setTargetValue(e.target.value)}
                                            title={'Enter Number'}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="fs-3 fw-bold ps-7 py-5" style={{ color: primaryColors.black }}>Action</div>

                    <div className="row gy-5 px-0 mb-10">

                        <div className="col-xl-3" >
                            <div className="fs-4 fw-semibold ps-7" style={{ color: primaryColors.black }}>Action</div>
                            <GeneralDropdown
                                selectedValue={selectedAction}
                                dropdownItems={actions}
                                onDropdownItemClick={handleActionChange}
                                title={'Select'}
                            />
                        </div>

                        {selectedAction === 'Email' || selectedAction === '' ? (
                            <div className="col-xl-3">
                                <div className="fs-4 fw-semibold ps-7" style={{ color: primaryColors.black }}>Email</div>
                                <GeneralInputBox
                                    selectedValue={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    title={'Type'}
                                />
                            </div>
                        ) : selectedAction === 'SMS' ? (
                            <div className="col-xl-3">
                                <div className="fs-4 fw-semibold ps-7" style={{ color: primaryColors.black }}>Phone</div>
                                <GeneralInputBox
                                    selectedValue={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    title={'Type'}
                                />
                            </div>
                        ) : null}

                        {/* <div className="col-xl-3">
                            <div className="fs-4 fw-semibold ps-7" style={{ color: primaryColors.black }}>Email Template</div>
                            <GeneralDropdown
                                selectedValue={selectedEmailTemplate}
                                dropdownItems={emailTemplates}
                                onDropdownItemClick={handleEmailTemplateChange}
                                title={'Select'}
                            />
                        </div> */}

                    </div>

                    <div className="d-flex align-items-center justify-content-center my-5 ps-1">
                        <div
                            className="d-flex align-items-center justify-content-center me-15"
                            style={{
                                width: '130px',
                                height: '50px',
                                padding: '8px',
                                border: `2px solid ${primaryColors.red}`,
                                borderRadius: '10px',
                                fontSize: '18px',
                                cursor: 'pointer',
                                outline: 'none',
                                fontWeight: 'bold',
                                color: 'white',
                                backgroundColor: primaryColors.red,
                            }}
                            onClick={createRule}
                        >
                            {ruleData ? 'UPDATE' : 'CREATE'}
                        </div>

                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                                width: '130px',
                                height: '50px',
                                padding: '8px',
                                border: `2px solid ${primaryColors.grey}`,
                                borderRadius: '10px',
                                fontSize: '18px',
                                cursor: 'pointer',
                                outline: 'none',
                                fontWeight: 'bold',
                                color: primaryColors.grey,
                                backgroundColor: 'white',
                            }}
                            onClick={cancelRule}
                        >
                            CANCEL
                        </div>
                    </div>

                </div>
            </div >
        </>
    );
};


export default RuleCreate;
