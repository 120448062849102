import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { ChartTheme } from "../../../../theme/assets/ts/_utils/ChartTheme";
import { getDataFromBackend, URLS } from "../../../modules/dashboards/DashboardRequests";
import CustomLoader from "../marketting_component/CustomLoader";

function BarGraph({ chartID, platformId, productId, startDate, endDate, channelType, campaignId, title }) {
	//const chart = useRef(null);
	const myChartID = chartID;
	const [myAgeData, setMyAgeData] = useState<Array<{
		data: Array<{
			value: number;
			age: string;
		}>;
		dataFor: string;
	}>>([]);

	const [selectedItem, setSelectedItem] = useState('clicks');
	// const [datas, setDatas] = useState<any>([]);
	const [filteredData, setFilteredData] = useState<Array<{
		value: number;
		age: string;
	}>>([]);


	const [dropdownData, setDropdownData] = useState<string[]>([]);


	useEffect(() => {
		const getData = async () => {
			let requestData = {
				productId,
				platformId,
				startDate,
				endDate,
				campaignId
			}
			let response = await getDataFromBackend(requestData, URLS.GET_CAMPAIGN_AGESTATS);
			console.log("Response of GET_CAMPAIGN_AGESTATS : ", response?.data);
			// setMyAgeData(response.data);
			if (response?.data) {
				setMyAgeData(response?.data);

			} else {
				return
			}
		};

		getData(); // run it, run it

		return () => {
			// this now gets called when the component unmounts
		};
	}, [productId, platformId, campaignId, startDate, endDate]);
	console.log(myAgeData, 'datt')

	useEffect(() => {
		if (myAgeData?.length > 0) {
			const mockDropdown = Array.from(
				new Set(myAgeData?.map((item) => item.dataFor))
			);
			setDropdownData(mockDropdown);
			const selectedData = myAgeData?.filter(item => item.dataFor === selectedItem);
			const newData = selectedData[0]?.data.map((item) => ({
				...item,
			}));
			setFilteredData(newData)
			console.log(newData, 'selected data')
		}
	}, [myAgeData, selectedItem])





	let wholeValue;
	useLayoutEffect(() => {

		let root = am5.Root.new(chartID);
		root._logo?.dispose();

		root.setThemes([ChartTheme.getTheme(root)]);


		let chart = root.container.children.push(
			am5xy.XYChart.new(root, {
				panX: false,
				panY: false,
				wheelX: "none",
				wheelY: "none",
				layout: root.verticalLayout,
			})
		);

		let xRenderer = am5xy.AxisRendererX.new(root, {});
		let xAxis = chart.xAxes.push(
			am5xy.CategoryAxis.new(root, {
				categoryField: "age",
				// maxDeviation: 1.0,
				renderer: xRenderer,
				tooltip: am5.Tooltip.new(root, {}),
			})
		);

		xRenderer.grid.template.setAll({
			location: 1,
		});

		xAxis.data.setAll(filteredData);

		// Create y-Axis
		let yAxis = chart.yAxes.push(
			am5xy.ValueAxis.new(root, {
				min: 0,
				max: wholeValue, renderer: am5xy.AxisRendererY.new(root, {
					strokeOpacity: 0.1,
				}),
			})
		);

		function makeSeries(name, fieldName) {
			var series = chart.series.push(
				am5xy.ColumnSeries.new(root, {
					name: name,
					stacked: true,
					xAxis: xAxis,
					yAxis: yAxis,
					valueYField: "value",
					categoryXField: "age",
				})
			);

			series.columns.template.setAll({
				tooltipText: `${selectedItem} : {valueY}`,
				// tooltipY: am5.percent(10),
			});
			series.data.setAll(filteredData);
			series.appear();

			series.bullets.push(function () {
				return am5.Bullet.new(root, {
					sprite: am5.Label.new(root, {
						text: "{valueY}",
						fill: root.interfaceColors.get("alternativeText"),
						centerY: am5.p50,
						centerX: am5.p50,
						populateText: true,
					}),
				});
			});
		}

		makeSeries("Series", "value");

		chart.appear(1000, 100);

		return () => {
			root.dispose();
		};
	}, [myChartID, filteredData]);


	useLayoutEffect(() => { }, []);

	return (
		<div className="card h-lg-100 py-5 px-7" style={{ boxShadow: '0 2px 8px rgba(0, 0, 0, 0.5)' }}>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<div
					className="text-start fw-bold text-gray-800 fs-4"
					style={{ paddingTop: "7px", paddingBottom: "9px" }}
				>
					<span className="card-label fw-bold text-dark fs-6">{title}</span>
				</div>
				<div className="d-flex align-items-center fw-bold flex-end">
					<div className="dropdown">
						<div
							data-kt-daterangepicker="true"
							data-kt-daterangepicker-opens="left"
							id="dropdownMenuButton1"
							data-bs-toggle="dropdown"
							aria-expanded="false"
							className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary d-flex align-items-center px-0 dropdown-toggle"
						>
							{selectedItem || "clicks"}
						</div>
						<ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
							{dropdownData.map((data) => (
								<li key={data}
									onClick={() => {
										setSelectedItem(data);
										console.log(data, 'dddhh');
									}}
								>
									<span className="dropdown-item text-hover-primary">{data}</span>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
			<div className="card-body d-flex justify-content-between align-items-start flex-column">
				{/* <div id={chartID} style={{ height: "200px", width: "100%" }}></div> */}
				{filteredData?.length > 0 ?
					<div id={chartID} style={{ height: "200px", width: "100%" }}></div>
					:
					<div id={chartID} style={{ alignSelf: 'center', marginBottom: '30px', }}>
						<CustomLoader />
					</div>
				}
			</div>
		</div>
	);
}
export default BarGraph;
