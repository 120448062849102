import React, { useEffect, useMemo, useState } from "react";
import {
    AiOutlineFieldTime,
    AiOutlineProject,
    AiTwotoneCalendar,
} from "react-icons/ai";
import { Link } from 'react-router-dom';
import { IoLocationOutline, IoPeople } from "react-icons/io5";
import { FaBusinessTime, FaCircle, FaEdit, FaEye, FaRegUser, FaSearch, FaTimes } from "react-icons/fa";
import { BsCalendarPlus, BsFiletypeXls } from "react-icons/bs";
// import { useAuth } from "../../modules/auth";
import { useNavigate } from "react-router-dom";
// import { colors } from "./colors";
import { IoMdAddCircleOutline, IoMdArrowDropdown } from "react-icons/io";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BiSolidFilePdf } from "react-icons/bi";
import { primaryColors } from "../../../../theme/assets/ts/_utils/AppColors";
import GeneralDropdown from "./GeneralDropdown";
import { MdOutlineCancel } from "react-icons/md";
import colapse from "../../../../../src/assets/cllaspe.png"
import CommaSeparatedText from "./CommaSeperatedText";

const API_BASE_URL = process.env.REACT_APP_API_URL;

function RuleListing() {

    //   const { setBreadCrumbs, breadCrumb } = useAuth();
    const navigate = useNavigate();
    interface Rule {
        "id": string;
        "name": string;
        "priority": string;
        "initiationType": string;
        "parameter": string;
        "metric": string;
        "conditions": string;
        "action": string;
        "triggerTime": string;
        "validFrom": string;
        "validTo": string;
        "createdBy": string;
        "createdDate": string;
        "updatedBy": string;
        "updatedDate": string;
        "status": string;
        "linkedCampaigns"?: string;
        "valueFrom": string;
        "valueTo": string;
        "email": string;
        "targetValue"?:string
    }

    const demoRuleData: Rule[] = [
        {
            "id": "088d1ad5-9d2c-415d-b270-f84e709f055c",
            "name": "test",
            "priority": "high",
            "initiationType": "pooling",
            "parameter": "cpc",
            "metric": " ",
            "conditions": "<",
            "action": "no",
            "triggerTime": "2024-01-08T10:41:05.000+00:00",
            "validFrom": "2024-01-08T00:00:00.000+00:00",
            "validTo": "2024-01-08T00:00:00.000+00:00",
            "createdBy": "me",
            "createdDate": "2024-01-08T12:04:03.000+00:00",
            "updatedBy": "me",
            "updatedDate": "2024-01-08T12:04:03.000+00:00",
            "status": "ACTIVE",
            "linkedCampaigns": " ",
            "valueFrom": " ",
            "valueTo": " ",
            "email": " "
        },
        {
            "id": "7cfeec23-271e-4f99-9da0-67850f1c500a",
            "name": "test",
            "priority": "high",
            "initiationType": "pooling",
            "parameter": "cpc",
            "metric": "-",
            "conditions": "<",
            "action": "no",
            "triggerTime": "2024-01-08T10:41:05.000+00:00",
            "validFrom": "2024-01-07T00:00:00.000+00:00",
            "validTo": "2024-01-07T00:00:00.000+00:00",
            "createdBy": "me",
            "createdDate": "2024-01-08T12:05:53.000+00:00",
            "updatedBy": "me",
            "updatedDate": "2024-01-08T12:05:53.000+00:00",
            "status": "ACTIVE",
            "linkedCampaigns": " ",
            "valueFrom": "0",
            "valueTo": "100",
            "email": "self@gmail.com"
        },
        {
            "id": "dcd0223f-5d25-4748-9662-049aaccee6f4",
            "name": "test",
            "priority": "high",
            "initiationType": "pooling",
            "parameter": "cpc",
            "metric": " ",
            "conditions": "<",
            "action": "no",
            "triggerTime": "2024-01-08T10:41:05.000+00:00",
            "validFrom": "2024-01-08T00:00:00.000+00:00",
            "validTo": "2024-01-08T00:00:00.000+00:00",
            "createdBy": "me",
            "createdDate": "2024-01-08T12:02:49.000+00:00",
            "updatedBy": "me",
            "updatedDate": "2024-01-08T12:02:49.000+00:00",
            "status": "ACTIVE",
            "linkedCampaigns": " ",
            "valueFrom": " ",
            "valueTo": " ",
            "email": " "
        }
    ]

    const formatDate = (dateString: string | undefined | null) => {
        try {
            if (!dateString) {
                return null;
            }

            const dateObject = new Date(dateString);

            if (isNaN(dateObject.getTime())) {
                throw new Error("Invalid date");
            }

            // Format date as yyyy-mm-dd
            const formattedDate = dateObject.toISOString().split('T')[0];
            console.log("Formatted Date:", formattedDate);
            return formattedDate;
        } catch (error) {
            console.error("Error formatting date:", (error as Error).message);
            return null; // Return null for invalid dates
        }
    };


    const millisecondsToDate = (milliseconds: number): string => {
        const date = new Date(milliseconds);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [incidentData, setIncidentData] = useState<Rule[]>(demoRuleData);

    const getDate = (currentDate) => {
        const offset = currentDate.getTimezoneOffset();
        let yourDateObj = new Date(currentDate.getTime() - offset * 60 * 1000);
        return yourDateObj.toISOString().split("T")[0];
    };

    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const handleRowsPerPageChange = (value: number) => {
        setRowsPerPage(value);
        setCurrentPage(1);
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

   

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(API_BASE_URL+"/rules/getrules");

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response?.json();
                console.log('newdata:', data);

                const processedData = data;

                console.log("processedData-----", processedData);
                setIncidentData(processedData); // Update state with processedData
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []); // Empty dependency array means this effect runs once when the component mounts

    const [filterApplied, setFilterApplied] = useState(false);
    const [searchApplied, setSearchApplied] = useState(false);

    const [filteredRuleData, setFilteredRuleData] = useState<Rule[]>(incidentData);


    const [selectedMetric, setSelectedMetric] = useState('');
    const metrics = ['CPC', 'Cost', 'Account balance'];

    const [selectedActionName, setSelectedActionName] = useState('');
    const actionNames = ['Email', 'SMS', 'Notification'];

    const [selectedStatus, setSelectedStatus] = useState('');
    const statuses = ['Active', 'Inactive'];

    const [selectedCreatedBy, setSelectedCreatedBy] = useState('');
    const createdBys = ['Me', 'Sam', 'Maddison'];

    // const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    const handleDropdownClick = () => {
        setIsCollapsed(false);
    };




    const handleMetricChange = (data) => {
        setFilterApplied(false);
        setSelectedMetric(data);
    };

    const handleActionNameChange = (data) => {
        setFilterApplied(false);
        setSelectedActionName(data);
    };

    const handleStatusChange = (data) => {
        setFilterApplied(false);
        setSelectedStatus(data);
    };

    const handleCreatedByChange = (data) => {
        setFilterApplied(false);
        setSelectedCreatedBy(data);
    };

    const onFilterCancel = () => {
        setSelectedMetric('');
        setSelectedActionName('');
        setSelectedStatus('');
        setSelectedCreatedBy('');
        setFilterApplied(false);
    }

    const handleDateChange = (date) => {
        const utcDate = new Date(Date.UTC(date?.getFullYear(), date?.getMonth(), date?.getDate()));
        setSelectedDate(utcDate);
        console.log("Selected Date:", utcDate?.toISOString().split('T')[0]);
    };


    const filteredData = useMemo(() => {
        return incidentData.filter((item) => {
            const metricMatch = !selectedMetric || item?.metric?.toLowerCase() === selectedMetric?.toLowerCase();
            const actionNameMatch = !selectedActionName || item?.action?.toLowerCase() === selectedActionName?.toLowerCase();
            const statusMatch = !selectedStatus || item?.status?.toLowerCase() === selectedStatus.toLowerCase();
            const createdByMatch = !selectedCreatedBy || item?.createdBy?.toLowerCase() === selectedCreatedBy?.toLowerCase();
            //   console.log("Selected Date:", selectedDate?.toISOString().split('T')[0]);

            // Check if selectedDate is not null before comparing
            //   const formattedItemDate = formatDate(item?.incident_data?.timeOfIncident);
            //   const selectedDateMatch = !selectedDate || formattedItemDate === selectedDate.toISOString().split('T')[0];

            return metricMatch && actionNameMatch && statusMatch && createdByMatch;
        });
    }, [incidentData, selectedMetric, selectedActionName, selectedStatus, selectedCreatedBy]);


    const finalIncidentData = filterApplied ? filteredData : incidentData

    const fieldNames = Object.keys(incidentData[0]);

    const handleClick = (item) => {
        const incidentTag = item?.incident_data?.assetTag || 'Incident Details';
        // setBreadCrumbs(`${incidentTag} Details`);
        console.log("first", item);
        navigate("/rule-details", {
            state: { item },
        });
    };

    const handleCreateRule = (item) => {
        console.log("item", item)
        navigate(`/rule-create`, {
            state: { item },
        })
    }

    const handleCreateRules = (item) => {
        console.log("item", item)
        navigate(`/rule-creates`, {
            state: { item },
        })
    }

    const tableHeaders = [
        "Rule Name",
        "Campaign Name",
        "Metrics",
        "Operation",
        "Value",
        "Action Name",
        "Created By",
        "Status",
        "Actions",

        // ... Add more headers as needed
    ];
    const colors = {
        mainColor: primaryColors.red,
        blackColor: primaryColors.black,
        whiteTextColor: primaryColors.white,
        greyColor: 'lightgrey'
    };

    const [searchTerm, setSearchTerm] = useState('');
    const [searchedFilteredData, setSearchedFilteredData] = useState<Rule[]>(filteredRuleData);


    const handleSearch = (value) => {
        // Perform filtering based on the incidentName
        // You can adjust this logic based on your data structure
        setSearchTerm(value);
        const searchedFilteredData = incidentData?.filter(
            (item) => item?.name?.toLowerCase().includes(searchTerm.toLowerCase()) || item?.id?.toLowerCase().includes(searchTerm.toLowerCase())
        );
        // Render the filtered data or update the state to trigger a re-render
        setSearchedFilteredData(searchedFilteredData);
        setFilterApplied(true);
    };

    const usableData = searchTerm ? searchedFilteredData : finalIncidentData

    const totalPages = Math.ceil(usableData.length / rowsPerPage);
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, usableData.length);
    const currentPageData = usableData.slice(startIndex, endIndex);


    const goToPreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const goToNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const renderValue=(item)=>{
        if(item.conditions==='Greater than' || item.conditions==='Less than'){
            if(item.percentage){
                return `${item.percentage} %`
            }else{
                return `${item.targetValue}`
            }
        }else{
            return `${item.valueFrom} - ${item.valueTo}`
        }
    }


    return (
        <>
            <div className=''>

                {/* heading row */}

                <div className="d-flex align-items-center justify-content-between mb-7" style={{ marginTop: '-27px', }}>
                    <div className="" style={{ color: primaryColors.grey, fontWeight: 'bold', fontSize: '25px' }}>Rule Listing</div>
                    <div className="d-flex align-items-center justify-content-end ps-5">
                        <div className="d-flex align-items-center ">
                            <div className='me-3' style={{ position: "relative" }}>
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={(e) => handleSearch(e.target.value)
                                    }
                                    placeholder={searchTerm ? searchTerm : 'Search'}
                                    style={{
                                        width: '300px',
                                        height: '40px',
                                        paddingLeft: '40px',
                                        paddingRight: '40px',
                                        border: '2px solid lightgrey',
                                        backgroundColor: primaryColors.white,
                                        borderRadius: '10px',
                                        fontSize: '14px',
                                        cursor: 'pointer',
                                        outline: 'none',
                                        fontWeight: 'bold',
                                        color: 'grey',
                                        marginRight: '15px',
                                    }}
                                />
                                <FaSearch
                                    size={15}
                                    style={{
                                        position: "absolute",
                                        left: "10px",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        color: primaryColors.grey,
                                        cursor: "pointer",
                                    }}
                                />
                                <MdOutlineCancel
                                    size={20}
                                    style={{
                                        position: "absolute",
                                        right: "30px",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        color: primaryColors.grey,
                                        cursor: "pointer",
                                    }}
                                    onClick={() => (setSearchTerm(''))}
                                />
                            </div>
                            <div className="d-flex justify-content-between" style={{ width: '330px' }}>
                                <div className="button-container">
                                    <button
                                        className="custom-button"
                                        style={{
                                            width: '150px',
                                            height: '40px',
                                            padding: '5px 8px 7px 8px', // Adjusted padding for text alignment
                                            border: `2px solid ${colors.mainColor}`,
                                            borderRadius: '10px',
                                            fontSize: '10px', // Increased font size for the first button
                                            cursor: 'pointer',
                                            outline: 'none',
                                            fontWeight: 'bold',
                                            color: 'white',
                                            backgroundColor: colors.mainColor,
                                        }}
                                        onClick={() => handleCreateRule('')}>
                                        CREATE CAMPAIGN RULE
                                    </button>
                                </div>
                                <div className="button-container">
                                    <button
                                        className="custom-button"
                                        style={{
                                            width: '150px',
                                            height: '40px',
                                            padding: '5px 8px 7px 8px',
                                            border: `2px solid ${colors.mainColor}`,
                                            borderRadius: '10px',
                                            fontSize: '10px',
                                            cursor: 'pointer',
                                            outline: 'none',
                                            fontWeight: 'bold',
                                            color: 'white',
                                            backgroundColor: colors.mainColor,
                                        }}
                                        onClick={() => handleCreateRules('')}>
                                        CREATE PLATFORM RULE
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                {/* filters */}
                {isCollapsed &&
                    <div className="card-header d-flex justify-content-end align-items-end" onClick={toggleCollapse} style={{ cursor: 'pointer' }}>
                        <div className="card p-1" style={{ transform: 'rotate(-90deg)', borderRadius: '30px 30px 0 0', boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)' }}>
                            <div className="dropdown dropdown-toggle px-3" style={{ color: 'red', transform: '', fontSize: '25px' }}>
                            </div>
                            <img src={isCollapsed ? colapse : colapse} height={'30px'} width={'30px'} alt={isCollapsed ? 'Expand' : 'Collapse'} />
                        </div>

                    </div>
                }
                <div className={`card-body ${isCollapsed ? 'collapse' : 'show'}`} >
                    <div className="d-flex " style={{ boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', width: "5%", borderRadius: '0 20px 20px 0' }} onClick={toggleCollapse}>
                        <img
                            height={'35px'}
                            width={'35px'}
                            src={isCollapsed ? colapse : colapse}
                            alt={isCollapsed ? 'Expand' : 'Collapse'} />
                        <div className="dropdown dropdown-toggle px-3" style={{ color: 'red', transform: 'rotate(-90deg)', fontSize: '25px' }}></div></div>

                    <div
                        className='mb-10 px-5 pb-7 pt-3'
                        style={{
                            marginTop: 10,
                            backgroundColor: '#fff',
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
                            // overflow: 'hidden',
                            borderRadius: 5,
                            // padding: 10,
                        }}>

                        <div className="fs-1 fw-bold ps-5 py-2" style={{ color: primaryColors.black }}>Filters</div>

                        <div className="row">

                            <div className="col-xl-9">

                                <div className="row gy-5 px-0">

                                    <div className="col-xl-4" >
                                        <GeneralDropdown
                                            selectedValue={selectedMetric}
                                            dropdownItems={metrics}
                                            onDropdownItemClick={handleMetricChange}
                                            title={'Metrics'}
                                        />
                                    </div>

                                    <div className="col-xl-4">
                                        <GeneralDropdown
                                            selectedValue={selectedActionName}
                                            dropdownItems={actionNames}
                                            onDropdownItemClick={handleActionNameChange}
                                            title={'Action Name'}
                                        />
                                    </div>

                                    <div className="col-xl-4">
                                        <GeneralDropdown
                                            selectedValue={selectedStatus}
                                            dropdownItems={statuses}
                                            onDropdownItemClick={handleStatusChange}
                                            title={'Status'}
                                        />
                                    </div>

                                    <div className="col-xl-4">
                                        <GeneralDropdown
                                            selectedValue={selectedCreatedBy}
                                            dropdownItems={createdBys}
                                            onDropdownItemClick={handleCreatedByChange}
                                            title={'Created By'}
                                        />
                                    </div>

                                </div>

                            </div>

                            <div className="col-xl-3 d-flex align-items-center justify-content-end pe-10">
                                <div
                                    className="d-flex align-items-center justify-content-center me-10"
                                    style={{
                                        width: '100px',
                                        height: '40px',
                                        padding: '8px',
                                        border: `2px solid ${colors.mainColor}`,
                                        borderRadius: '10px',
                                        fontSize: '14px',
                                        cursor: 'pointer',
                                        outline: 'none',
                                        fontWeight: 'bold',
                                        color: 'white',
                                        backgroundColor: colors.mainColor,
                                    }}
                                    onClick={() => {
                                        setFilterApplied(true);
                                        setFilteredRuleData(filteredData);
                                    }}
                                >
                                    APPLY
                                </div>

                                <div
                                    className="d-flex align-items-center justify-content-center"
                                    style={{
                                        width: '100px',
                                        height: '40px',
                                        padding: '8px',
                                        border: `2px solid ${colors.mainColor}`,
                                        borderRadius: '10px',
                                        fontSize: '14px',
                                        cursor: 'pointer',
                                        outline: 'none',
                                        fontWeight: 'bold',
                                        color: primaryColors.grey,
                                        // backgroundColor: colors.greyColor,
                                    }}
                                    onClick={onFilterCancel}
                                >
                                    CANCEL
                                </div>
                            </div>

                        </div>

                        {/* </div> */}
                    </div>
                </div>

                {/* body */}

                <div
                    className='mb-10'
                    style={{
                        marginTop: 10,
                        backgroundColor: '#fff',
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
                        // overflow: 'hidden',
                        borderRadius: 5,
                        // padding: 10,
                    }}>
                    <div className="p-5 mb-5 pb-5 pt-5 px-5" style={{ borderRadius: 15 }}>
                        <table style={{
                            width: '100%', borderCollapse: 'collapse', borderRadius: 15,
                        }}>
                            <thead style={{
                                border: `1.5px solid ${primaryColors.red}`,
                            }}>
                                <tr style={{
                                    border: `1.5px solid ${primaryColors.red}`, height: '60px',
                                }}>
                                    {tableHeaders?.map((header, index) => (
                                        <th key={index} style={{
                                            border: '1.5px solid lightgrey', height: '60px', textAlign: 'center',
                                            maxWidth: '50px', backgroundColor: primaryColors.red, color: "white",
                                        }}>
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {currentPageData?.map((item:any, index:number) => (
                                    <tr key={`rowItem${index}`} className="fs-4" style={{ border: '1.5px solid lightgrey', height: '60px' }}>
                                        <td style={{ border: '1.5px solid lightgrey', textAlign: 'center', maxWidth: '50px' }}>{item?.name}</td>
                                        <td style={{ border: '1.5px solid lightgrey', textAlign: 'center', maxWidth: '50px' }}><CommaSeparatedText text={item?.linkedCampaigns || ""} limit={1} />{}</td>
                                        <td style={{ border: '1.5px solid lightgrey', textAlign: 'center', maxWidth: '50px' }}>{item?.metric}</td>
                                        <td style={{ border: '1.5px solid lightgrey', textAlign: 'center', maxWidth: '50px' }}>{item?.conditions}</td>
                                        <td style={{ border: '1.5px solid lightgrey', textAlign: 'center', maxWidth: '50px' }}>{renderValue(item)}</td>
                                        <td style={{ border: '1.5px solid lightgrey', textAlign: 'center', maxWidth: '50px' }}>{item?.action}</td>
                                        <td style={{ border: '1.5px solid lightgrey', textAlign: 'center', maxWidth: '50px' }}>{item?.createdBy}</td>
                                        <td style={{ border: '1.5px solid lightgrey', textAlign: 'center', maxWidth: '50px' }}>{item?.status}</td>
                                        <td style={{ border: '1.5px solid lightgrey', textAlign: 'center', maxWidth: '50px', color: colors.mainColor }}>
                                            <FaEye style={{ color: colors.mainColor, marginRight: '20px' }} size={20} onClick={() => handleClick(item)} />
                                            <FaEdit style={{ color: colors.mainColor, }} size={18} onClick={() => handleCreateRule(item)} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* table footer */}

                        <div className="d-flex flex-end align-items-center mt-5">

                            {/* Rows per page dropdown */}
                            <div className="me-10 d-flex align-items-center" style={{ position: 'relative' }}>
                                <select
                                    className="fw-bold"
                                    value={rowsPerPage}
                                    onChange={(e) => handleRowsPerPageChange(parseInt(e.target.value))}
                                    style={{ border: `0px solid ${primaryColors.red}`, color: 'grey', outline: 'none', paddingRight: '30px' }}>
                                    <option value={5}>Show 5</option>
                                    <option value={6}>Show 6</option>
                                    <option value={7}>Show 7</option>
                                </select>
                                <div style={{ position: 'absolute', top: '50%', right: '5px', transform: 'translateY(-50%)' }}>
                                    <IoMdArrowDropdown size={25} style={{ color: 'grey' }} />
                                </div>
                            </div>

                            {/* Pagination buttons */}
                            <div>
                                <button className="fw-bold" onClick={goToPreviousPage} disabled={currentPage === 1}
                                    style={{
                                        backgroundColor: primaryColors.white,
                                        border: `1px solid ${currentPage !== 1 ? primaryColors.red : primaryColors.grey}`,
                                        color: currentPage !== 1 ? primaryColors.red : primaryColors.grey,
                                        width: '80px',
                                        height: '30px',
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5
                                    }}>
                                    Previous
                                </button>
                                {pageNumbers?.map((page) => (
                                    <button className="fw-bold" key={page} onClick={() => handlePageChange(page)}
                                        style={{
                                            border: `1px solid ${currentPage === page ? primaryColors.red : primaryColors.grey}`,
                                            backgroundColor: primaryColors.white,
                                            color: currentPage === page ? primaryColors.red : primaryColors.grey,
                                            height: '30px',
                                            width: '30px',
                                        }}>
                                        {page}
                                    </button>
                                ))}
                                <button className="fw-bold" onClick={goToNextPage} disabled={currentPage === totalPages}
                                    style={{
                                        backgroundColor: primaryColors.white,
                                        border: `1px solid ${currentPage !== totalPages ? primaryColors.red : primaryColors.grey}`,
                                        color: currentPage !== totalPages ? primaryColors.red : primaryColors.grey,
                                        width: '80px',
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5,
                                        height: '30px',
                                    }}>
                                    Next
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

            </div >
        </>
    );
};


export default RuleListing;
