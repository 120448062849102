/** @format */

import { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  AiFillShopping,
  AiOutlineFieldTime,
  AiOutlineProject,
  AiTwotoneCalendar,
} from "react-icons/ai";
import TrendsComponent from "./TrendsComponent";
import ColGraph from "../capaign_components/ColGraph";
import DeviceCard from "./DeviceCard";
import GenderCard from "./GenderCard";
import NetworkingGraph from "./NetworkingGraph";
import PlatformCard from "./PlatformCard";
import TreeMap from "./TreeMap";
import MultiHorizontalBarGraph from "../components/MultiHorizontalBarGraph";
import { DateRangePicker } from "materialui-daterange-picker";
import ProductGenderCard from "./ProductGenderCard";
import CampaignStats from "../../../../theme/layout/components/header/header-menus/CampaignStats";
import ChannelTable from "../components/ChannelTable";
import AmChartStickBarGrouped from "../AmChartStickBarGrouped";
import AmFunnelChart from "./AmFunnelChart";
import SideGNW from "../SideGNW";
import AmBubbleGraphXY from "./AmBubbleGraphXY";
import GroupedVbarMarketing from "../components/GroupedVbarMarketing";
import ProductStatsCard from "../../../../theme/layout/components/header/header-menus/ProductStatsCard";
import CustomDateRangePicker from "../Custom_Components/CustomDateRangePicker";
import HeaderDropdown from "../Custom_Components/HeaderDropdown";
import SpendLineGraph from "../capaign_components/SpendLineGraph";
import {
  URLS,
  getMethodDataFromBackend,
} from "../../../modules/dashboards/DashboardRequests";

const PlatformDashboard = () => {
  const location = useLocation();
  const state: any = location.state;
  console.log("params in platform dashboard", state);
  const [platformId, setPlatformId] = useState<any>(state?.platformId);
  const [productId, setProductId] = useState<string>(state?.productId);
  const [startDate, setStartDate] = useState("2023-06-01");
  const [endDate, setEndDate] = useState("2023-06-30");
  const [platformProductTypeDropdown, setPlatformProductTypeDropdown] =
    useState(["All"]);
  const [selectedPlatformProductType, setSelectedPlatformProductType] =
    useState("All");

  useEffect(() => {
    document.title = "Camp AI Gain - Detail";
    setDateFilter();
  }, []);

  const [baseLineDropdown, setBaseLineDropdown] = useState(["custom"]);
  const [selectedBaselineValue, setSelectedBaselineValue] =
    useState("Baseline");

  const setDateFilter = async () => {
    try {
      const response = await getMethodDataFromBackend(URLS.BASE_URL + "platform/getdates");
      console.log("setDateFilter response:-", response)
      if(response?.data?.dashboardLevel === "Platform") {
        setStartDate(formatDate(response.data.startDate));
        setEndDate(formatDate(response.data.endDate));
      }
    } catch(err) {

    }
  }

  // Function to format date
const formatDate = (inputDate) => {
  // Parse input date string
  const date = new Date(inputDate);
  
  // Extract year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because month index starts from 0
  const day = String(date.getDate()).padStart(2, '0');
  
  // Return formatted date string
  return `${year}-${month}-${day}`;
}


  const handleDropdownItemClick = (data) => {
    console.log(data);
    setSelectedBaselineValue(data);
  };

  const handlePlatfomrTypeDropdownItemClick = (data) => {
    if (data == "all") {
      setSelectedPlatformProductType("All");
    } else {
      setSelectedPlatformProductType(data);
    }
  };

  const getDate = (currentDate) => {
    const offset = currentDate.getTimezoneOffset();
    let yourDateObj = new Date(currentDate.getTime() - offset * 60 * 1000);
    return yourDateObj.toISOString().split("T")[0];
  };

  const handleDateRangeChange = (dateRange) => {
    setStartDate(getDate(dateRange?.startDate));
    setEndDate(getDate(dateRange?.endDate));
  };

  const getData = async () => {
    try {
      let responseBaseline = await getMethodDataFromBackend(
        URLS.BASE_URL + "baseline/options"
      );

      let responsePlatformTypes = await getMethodDataFromBackend(
        URLS.GET_PLATFORMTYPES
      );

      console.log("responsePlatformTypes", responsePlatformTypes);
      setPlatformProductTypeDropdown(responsePlatformTypes?.data);
      let data = responseBaseline?.data;

      if (data) {
        console.log("responseBaseline", data);
        setBaseLineDropdown(data);
      } else {
        console.log("No data received from the backend");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const element = document.getElementById("testId2");
      if (element) {
        element.style.position = scrollPosition > 10 ? "fixed" : "absolute";
        element.style.right = "30px";
        // element.style.zIndex = 1;
        element.style.width = "100%";
        element.style.top = scrollPosition > 10 ? "70px" : "85px";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        id="testId2"
        className={`d-flex flex-end`}
        style={{
          position: "absolute",
          right: "30px",
          zIndex: 1,
          width: "100%",
          top:'85px'
        }}
      >
        <HeaderDropdown
          selectedValue={selectedPlatformProductType}
          dropdownItems={platformProductTypeDropdown}
          onDropdownItemClick={handlePlatfomrTypeDropdownItemClick}
        />
        <HeaderDropdown
          selectedValue={selectedBaselineValue}
          dropdownItems={baseLineDropdown}
          onDropdownItemClick={handleDropdownItemClick}
        />

        <CustomDateRangePicker
          startDate={startDate}
          endDate={endDate}
          onDateRangeChange={handleDateRangeChange}
        />
      </div>

      {/* <div className="d-flex align-items-center justify-content-end px-0">
        <SideGNW
          startDate={startDate}
          endDate={endDate}
          platformId={platformId}
          productId={productId}
          channelType={"platform"}
          statsSubUrl={"/stats"}
          baseLineType={"Baseline"}
          productType={""}
        />
      </div> */}

      {/* <CampaignStats
        platformId={platformId}
        productId={productId}
        startDate={startDate}
        endDate={endDate}
        channelType={"platform"}
        baseLineType={selectedBaselineValue}
        statsSubUrl={"/stats"}
        productType={""}
      /> */}

      <ProductStatsCard
        platformId={platformId}
        productId={productId}
        startDate={startDate}
        endDate={endDate}
        channelType={"platform"}
        baseLine={selectedBaselineValue}
        productType={selectedPlatformProductType}
        statsSubUrl={"/overallstats"}
      />

      <AmChartStickBarGrouped
        platformId={platformId}
        productId={productId}
        startDate={startDate}
        endDate={endDate}
        channelType={"platform"}
        productType={selectedPlatformProductType}
        baseLine={selectedBaselineValue}
      />

      <div className="row gx-xl-10 gy-10 mb-10">
        <div className="col-xl-12">
          <ChannelTable
            platformId={platformId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            campaignId={"channelId"}
            tableTitle={"Cost Parameter"}
            channelType={"platform"}
            subUrl={"/costcomparison"}
            baseLine={selectedBaselineValue}
            productType={selectedPlatformProductType}
          />
        </div>
        {/* <div className="col-xl-6">
          <ChannelTable
            platformId={platformId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            campaignId={"channelId"}
            title={"Overall Parameter"}
            channelType={"platform"}
            subUrl={"/overallcomparison"}
          />
        </div> */}
      </div>

      <div className="row gx-xl-10 gy-10 mb-10">
        <div className="col-xl-12">
          <ChannelTable
            platformId={platformId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            campaignId={"channelId"}
            tableTitle={"Overall Parameter"}
            channelType={"platform"}
            subUrl={"/overallcomparison"}
            baseLine={selectedBaselineValue}
            productType={selectedPlatformProductType}
          />
        </div>
        {/* <div className="col-xl-6">
          <ChannelTable
            platformId={platformId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            campaignId={"channelId"}
            title={"Overall Parameter"}
            channelType={"platform"}
            subUrl={"/overallcomparison"}
          />
        </div> */}
      </div>
      <div className="row gx-xl-10 gy-10 mb-10">
        <div className="col-xl-6">
          <GroupedVbarMarketing
            label={"Cost Metrics"}
            startDate={startDate}
            endDate={endDate}
            channelType={"platform"}
            productId={productId}
            platformId={platformId}
            subTypeUrl={"/costmetrics"}
            productType={selectedPlatformProductType}
          />
        </div>
        <div className="col-xl-6">
          <GroupedVbarMarketing
            label={"Overall Numbers"}
            startDate={startDate}
            endDate={endDate}
            channelType={"platform"}
            productId={productId}
            platformId={platformId}
            subTypeUrl={"/overallnumbers"}
            productType={selectedPlatformProductType}
          />
        </div>
      </div>

      <TrendsComponent
        platformId={platformId}
        productId={productId}
        startDate={startDate}
        endDate={endDate}
        channelType={"platform"}
        campaignId={""}
        productType={selectedPlatformProductType}
      />
      <div className="row gy-5 gx-xl-10 mb-10">
        <div className="col-xl-4 ">
          <AmFunnelChart
            title={"Reach to Conversion"}
            chartID="funnelChart1"
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            channelType={"platform"}
            campaignId={""}
            subUrl={"/reachtoconversion"}
            platformId={platformId}
            productType={selectedPlatformProductType}
          />
        </div>
        <div className="col-xl-8 ">
          <SpendLineGraph
            chartID={`spendlinegraph`}
            title={"Spends in INR"}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            productType={selectedPlatformProductType}
            subUrl={"platform/spends"}
            platformId={platformId}
            channelType="platform"
          />
        </div>
        {/* <div className="col-xl-6 ">
          <AmBubbleGraphXY
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            platformId={platformId}
          />
        </div> */}
      </div>
      <div className="row gy-5 gx-xl-10 mb-10">
        <div className="col-xl-12 ">
          <PlatformCard
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            platformId={platformId}
            channelType={"platform"}
            subUrl={"campaigntypes"}
            title={"Campaign Types"}
            productType={selectedPlatformProductType}
          />
        </div>
        <div className="col-xl-12 mt-12 ">
          <PlatformCard
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            platformId={platformId}
            channelType={"platform"}
            subUrl={"campaignbycredit"}
            title={"Campaigns by Credit Cards"}
            productType={selectedPlatformProductType}
          />
        </div>
      </div>
      <div className="row gy-5 gx-xl-12 gx-10 mb-10">
        <div className="col-xxl-4 ">
          <ColGraph
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            platformId={platformId}
            productType={selectedPlatformProductType}
          />
        </div>
        <div className="col-xxl-4">
          <NetworkingGraph
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            platformId={platformId}
            productType={selectedPlatformProductType}
          />
        </div>
        <div className="col-xxl-4">
          <TreeMap
            chartID={"treeMap3"}
            channelType="platform"
            apiTitle="devicetype"
            title="Device Type (Clicks)"
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            platformId={platformId}
            productType={selectedPlatformProductType}
          />
        </div>
      </div>
      <div className="row gy-5 gx-xl-10 mb-7">
        <div className="col-xl-8">
          <div className="row gy-5 gx-xl-10 ">
            <div className="col-xl-12 mb-5">
              <DeviceCard
                platformId={platformId}
                productId={productId}
                startDate={startDate}
                endDate={endDate}
                channelType="platform"
                productType={selectedPlatformProductType}
                subUrl={"platform/mobiledevicestats"}
              />
            </div>
            <div className="col-xl-12 mb-5">
              <DeviceCard
                platformId={platformId}
                productId={productId}
                startDate={startDate}
                endDate={endDate}
                channelType="platform"
                productType={selectedPlatformProductType}
                subUrl={"platform/desktopdevicestats"}
              />
            </div>
            <div className="col-xl-12 mb-5">
              <DeviceCard
                platformId={platformId}
                productId={productId}
                startDate={startDate}
                endDate={endDate}
                channelType="platform"
                productType={selectedPlatformProductType}
                subUrl={"platform/tabletdevicestats"}
              />
            </div>
            <div className="col-xl-12">
              <MultiHorizontalBarGraph
                chartID="budgetGraph"
                productId={productId}
                startDate={startDate}
                endDate={endDate}
                platformId={platformId}
                productType={selectedPlatformProductType}
                channelType={"platform"}
                title={"Budget"}
                subUrl=""
                baseLine={selectedBaselineValue}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="row gy-5 gx-xl-10 ">
            <div className="col-xl-12 mb-5">
              <ProductGenderCard
                chartID={"genderChart2"}
                productId={productId}
                startDate={startDate}
                endDate={endDate}
                platformId={platformId}
                productType={selectedPlatformProductType}
                channelType={"platform"}
              />
            </div>
            <div className="col-xl-12">
              <GenderCard
                chartID={"genderChart"}
                productId={productId}
                startDate={startDate}
                endDate={endDate}
                platformId={platformId}
                productType={selectedPlatformProductType}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlatformDashboard;
