/** @format */

import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLayout } from "../../../core";
import { usePageData } from "../../../core/PageData";
import { useNavigate } from "react-router-dom";
const PageTitle = () => {
	const { pageTitle, pageDescription, pageBreadcrumbs, pageShowBackButton } = usePageData();
	const { config, classes } = useLayout();
	const appPageTitleDirection = config.app?.pageTitle?.direction;
	const nav = useNavigate();
	return (
		<div
			id="kt_page_title"
			data-kt-swapper="true"
			data-kt-swapper-mode="prepend"
			data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
			className={clsx(
				"page-title d-flex flex-wrap me-3 mb-1 mt-0",
				classes.pageTitle.join(" "),
				config.app?.pageTitle?.class,
				{
					"flex-row justify-content-center":
						appPageTitleDirection === "column",
					"align-items-center": appPageTitleDirection !== "column",
				}
			)}
		>
			<div className="flex-column">
			{/* begin::Title */}
			{config.app?.pageTitle?.display && pageTitle && (
				<h1
					className={clsx("page-heading d-flex text-dark fw-bold fs-3 my-0", {
						"flex-column justify-content-center": appPageTitleDirection,
						"align-items-center": !appPageTitleDirection,
					})}
				>
					{pageTitle}
					{pageDescription &&
						config.app?.pageTitle &&
						config.app?.pageTitle?.description && (
							<span
								className={clsx("page-desc text-muted fs-7 fw-semibold", {
									"pt-2": appPageTitleDirection === "column",
								})}
							>
								{config.app?.pageTitle?.direction === "row" && (
									<span className="h-20px border-1 border-gray-300 border-start ms-3 mx-2"></span>
								)}
								{pageDescription}{" "}
							</span>
						)}
				</h1>
			)}
			{/* end::Title */}

			{pageBreadcrumbs &&
				pageBreadcrumbs?.length > 0 &&
				config.app?.pageTitle &&
				config.app?.pageTitle?.breadCrumb && (
					<>
						{config.app?.pageTitle?.direction === "row" && (
							<span className="h-20px border-gray-300 border-start mx-4"></span>
						)}
						<ul className="breadcrumb breadcrumb-item fw-semibold fs-20 my-0 ">
							{Array.from(pageBreadcrumbs).map((item, index) => (
								<li
									className={clsx(
										"breadcrumb-item text-mute"
										// {
										// 	"text-mute":
										// 		(!item.isSeparator && item.isActive) ||
										// 		(!item.isSeparator && !item.isActive),
										// 	// 'text-custom': !item.isSeparator && !item.isActive,
										// 	// 'text-dark': !item.isSeparator && !item.isActive,
										// }
									)}
									key={`${item.path}${index}`}
								>
									{!item.isSeparator ? (
										item.path === "goBack" ? (
											<span
												onClick={() => nav(-1)}
												className="text-muted text-hover-primary"
												style={{ cursor: "pointer" }}
											>
												{item.title}
											</span>
										) : item.path === "" ? (
											<span className="text-muted" style={{fontSize:"12.35px"}}>{item.title}</span>
										) : (
											<Link
												className="text-muted text-hover-primary" style={{fontSize:"12.35px"}}
												to={item.path}
											>
												{item.title}
											</Link>
										)
									) : (
										//   <p className='text-muted text-hover-primary' onClick={()=>nav(-1)} style={{cursor:'pointer'}}>
										//   {item.title}
										// </p>
										<span className="bullet bg-gray-200 w-5px h-2px"></span>
									)}
								</li>
							))}
							{/* <li className='breadcrumb-item text-dark'>{pageTitle}</li> */}
						</ul>
						
					</>
				)}
				</div>
				{/* {pageShowBackButton && 
				<button
				type="button"
				style={{
				  fontSize: "14px",
				  color: "#5680DD",
				  backgroundColor: "rgb(230,236,250)",
				  marginLeft:"15px"
				}}
				className="nav-link btn btn-sm btn-active fw-bold px-4 me-1"
				onMouseEnter={(e) => {
				  (e.target as HTMLButtonElement).style.backgroundColor =
					"rgb(230,236,250)";
				}}
				onMouseLeave={(e) => {
				  (e.target as HTMLButtonElement).style.backgroundColor = "#F5F8FA";
				}}
				onClick={() => nav(-1)}>
				Back
			  </button>
				} */}
				
		</div>
	);
};

export { PageTitle };
