/** @format */

import { FC, useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import {
	QueryClient,
	useMutation,
	useQuery,
	useQueryClient,
} from "react-query";
import { createOrganization, editOrganization } from "../../../../application/_requests";
import { useListView } from "../core/ListViewProvider";
import { useOrganizationQueryResponse } from "../core/QueryResponseProvider";
import { useQueryRequest } from "../core/QueryRequestProvider";
import { stringifyRequestQuery } from "../../../../../../theme/helpers";

const editUserSchema = Yup.object().shape({
	name: Yup.string()
		.trim()
		.required("Organization name is required")
		.min(3, "Enter at least 3 characters")
		.max(60, "Maximum 60 characters allowed")
		// .matches(/^([^0-9]*)$/, " Name should not contain any number"),
});

const CreateOrganizationModalForm: FC<any> = ({
	user,
	isUserLoading,
	onCancel,
}) => {
	const btnRef = useRef<HTMLButtonElement | null>(null);

	const queryClient = useQueryClient();
	const { setItemIdForUpdate } = useListView();
	const { setToastMessage } = useListView();
	// const animatedComponent=makeAnimated();
	const cancel = (withRefresh?: boolean) => {
		setItemIdForUpdate(undefined);
	};

	const { state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
	const [type, settype] = useState("password");
	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);

	const { refetch } = useOrganizationQueryResponse();

	const HandleSubmit = (values: any) => {
		btnRef.current?.setAttribute("data-kt-indicator", "on");
		const createOrganizationData: any = {
			name: values.name,
		};
		const response = createOrganization(createOrganizationData)
			.then((res) => {
				btnRef.current?.removeAttribute("data-kt-indicator");
				onCancel();
				refetch();
				setToastMessage("Added Successfully!");
				setTimeout(() => {
					setToastMessage(undefined);
				}, 2000);
			})
			.catch((err) => {
				setToastMessage("Error in fetching data!");
				setTimeout(() => {
					setToastMessage(undefined);
				}, 2000);
			});
	};
	const formik = useFormik({
		initialValues: {
			// name: "",
			name: user?.orgName ? `${user.orgName}` : "",
		},
		validationSchema: editUserSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			let temp = {}
			if (user) {
				const temp1: any = { orgName: values.name };
				temp = temp1;
			} else {
				const temp1: any = { name: values.name };
				temp = temp1;
			}
			try {
				if (user) {
					await editOrganization(temp, user?.orgId);
					setToastMessage("Organization Updated Successfully");
					cancel(true);
					setTimeout(() => {
						setToastMessage(undefined);
					}, 2000);
					queryClient.invalidateQueries([`GET_ORGANIZATIONS_LIST-${query}`]);
				} else {
					await createOrganization(temp);
					setToastMessage("Organization Created Successfully");
					cancel(true);
					setTimeout(() => {
						setToastMessage(undefined);
					}, 2000);
					queryClient.invalidateQueries([`GET_ORGANIZATIONS_LIST-${query}`]);
				}
			} catch (ex) {
				console.error(ex);
			} finally {
				setSubmitting(true);
				// cancel(true);
			}
		},
	});

	return (
		<>
			<form
				id="kt_modal_add_user_form"
				className="form"
				onSubmit={formik.handleSubmit}
				noValidate
			>
				<div
					className="d-flex flex-column scroll-y me-n7 pe-7"
					id="kt_modal_add_user_scroll"
					data-kt-scroll="true"
					data-kt-scroll-activate="{default: false, lg: true}"
					data-kt-scroll-max-height="auto"
					data-kt-scroll-dependencies="#kt_modal_add_user_header"
					data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
					data-kt-scroll-offset="300px"
				>
					<div className="fv-row mb-7">
						<label className="required fw-bold fs-6 mb-2">
							Organization Name
						</label>
						<input
							placeholder="Organization Name"
							{...formik.getFieldProps("name")}
							type="text"
							name="name"
							// value={undefined}
							//onChange={(e)=>formik.setFieldVAlue("name",e.target.name)}
							className={clsx(
								"form-control form-control-light mb-3 mb-lg-0",
								{ "is-invalid": formik.touched.name && formik.errors.name },
								{
									"is-valid": formik.touched.name && !formik.errors.name,
								}
							)}
							autoComplete="off"
						/>
						{formik.touched.name && formik.errors.name && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.name}</span>
								</div>
							</div>
						)}
					</div>

					<div className="d-flex justify-content-center">
						<button
							title="Discard"
							type="reset"
							className="btn btn-light mx-10"
							data-kt-users-modal-action="cancel"
							onClick={onCancel}
						>
							Discard
						</button>

						<button
							ref={btnRef}
							type="submit"
							className="btn me-10 text-white btn-custom"
							id="kt_button_1"
							data-kt-users-modal-action="submit"
							title={user?`Update`:`Create`}
						// style={{ backgroundColor: "#1e1e2d" }}
						>
							<span className="indicator-label ">{user?`Update`:`Create`}</span>
							<span className="indicator-progress  ">
								Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

export { CreateOrganizationModalForm };
