import React, { useState } from "react";
import { AiOutlineCaretDown, AiOutlineProject } from "react-icons/ai";
import { primaryColors } from "../../../../theme/assets/ts/_utils/AppColors";

const GeneralDropdown = ({
  selectedValue,
  dropdownItems,
  onDropdownItemClick,
  title
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasSelect = new RegExp('select', 'i').test(title);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="d-flex align-items-center px-4">
      <div
        className="dropdown  px-1 custom-dropdown "
        style={{
          width: "100%",
          height: "50px",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <style className="">
          {`
             .custom-dropdown .dropdown-toggle::after {
              content: none; /* Hide the default Bootstrap dropdown icon */
            }
          `}
        </style>
        <div
          data-kt-daterangepicker="true"
          data-kt-daterangepicker-opens="left"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          className="d-flex align-items-center dropdown-toggle fw-bold"
          style={{
            border: `2px solid ${primaryColors.red}`,
            height: "40px",
            borderRadius: "10px",
            width: "100%",
            color: primaryColors.red,
            justifyContent: title === 'Select'?"":"center",
            fontSize: '15px',
            paddingLeft: title === 'Select'?"15px":""
          }}
          onClick={toggleDropdown}
        >
          <div className="pe-3 fw-bold" style={{ color: title === 'Select' ? '#797575' : primaryColors.red, }}> {selectedValue || title}</div>
          {/* <div className="pe-3">{changedTitle.length>0?changedTitle:selectedValue||selectedValue || title}</div> */}
          <div className="d-flex align-items-center px-5"
            style={{
              position: "absolute",
              right: "10px", // Adjust the right value based on your design
              transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease-in-out",
            }}
          >
            <AiOutlineCaretDown size={20} />
          </div>
        </div>
        <ul
          className={`dropdown-menu ${isOpen ? "show" : ""}`}
          aria-labelledby="dropdownMenuButton1"
          style={{ fontSize: '14px', width: '98%' }}
        >
          {dropdownItems.map((data, index) => (
            <li className="fw-semibold"
              key={data?.productId || index}
              onClick={() => onDropdownItemClick(data)}
              style={{ cursor: "pointer", width: "100%", fontSize: '14px' }}
            >
              <span className="dropdown-item text-hover-primary fw-semibold">{data}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default GeneralDropdown;
