import React, { useState } from "react";
import "./Custominput.css";
const CustomInput = ({
  style = {},
  className = "",
  label,
  value,
  onChange,
  type = "text",
  id,
  disabled = false,
  onCancel = () => {},
  pointer = "auto",
}) => {
  const [onFocus, setOnFocus] = useState(false);
  return (
    <>
      <div className={`w-100 ${className}`} style={style}>
        <div className="d-flex align-items-center position-relative my-1 w-100">
          {/* <div className={`form-group ${className}`} style={style}> */}
          <input
            id={id}
            disabled={disabled}
            className={`form-control pe-${pointer}`}
            style={{
              paddingLeft:
                typeof label === "string" && label.includes("Search")
                  ? "15px"
                  : "10px",
              paddingRight:
                typeof label === "string" && label.includes("Search")
                  ? "35px"
                  : "10px",
              fontWeight: "normal",
              backgroundColor: pointer === "none" ? "#EFF2F5" : "#fff",
            }}
            title=""
            type={type}
            required
            value={value}
            onChange={onChange}
            onFocus={() => setOnFocus(true)}
            onBlur={() => setOnFocus(false)}
          />
          <label
            id="label"
            htmlFor={pointer === "none" ? "" : id}
            style={{
              fontWeight: "normal",
              color: "#808080",
              paddingLeft: `${
                typeof label === "string" && label.includes("Search")
                  ? "5px"
                  : "0px"
              }`,
              backgroundColor: pointer === "none" ? "transparent" : "",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "auto",
            }}
          >
            {label}
          </label>
        
      </div>
      </div>
    </>
  );
};

export default CustomInput;
