import { Fragment, useEffect, useState, useRef } from "react";
import { BsExclamationTriangle, BsThreeDotsVertical } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import CampaignType from "./CampaignType";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

import { AiOutlineInfoCircle, AiOutlineWarning } from "react-icons/ai";
import outline from "../../../../assets/outline-preview.png";
import warning from "../../../../assets/baseline-warning.png";

import call from "../../../../assets/call.png";

import React from "react";
import { GrCircleInformation } from "react-icons/gr";
import { RiDeleteBin6Line } from "react-icons/ri";
// import { BsThreeDotsVertical } from 'react-icons/bs';
// import { AiOutlineWarning } from 'react-icons/ai';

const cardStyles = {
  border: "1px solid lightgray",
  display: "flex",
  borderRadius: "0",

};

const imageStyles = {
  maxWidth: "20px",
  maxHeight: "20px",
  marginRight: "10px",
};

const goalNameStyles = {
  fontSize: "14px",
  fontWeight: "bold",
  color: "#333",
};

const accountDefaultStyles = {
  fontSize: "13px",
  color: "#333",
};

const sourceStyles = {
  fontSize: "14px",

  color: "#333",
};

const warningIconStyles = {
  marginRight: "5px",
};

const actionTextStyles = {
  fontSize: "13px",
  fontWeight: "thin",
  color: "#333",
  borderBottom: "1.8px dashed",
};

const moreOptionsStyles = {
  fontSize: "16px",
  fontWeight: "bold",
  color: "#333",
  cursor: "pointer",
  marginRight: "5px",
};

// const Header = () => (
//   <>
//     <div className="fs-7 text-gray-500 mb-2">
//       <div className="row gx-xl-12 gy-5 d-flex px-5">
//         <div className="col-lg-4">Conversion Goals</div>
//         <div className="col-lg-2">Conversion Source</div>
//         <div className="col-lg-3 d-flex align-items-center justify-content-center">
//           Conversion Actions
//         </div>
//         <div className="col-lg-2"></div>
//         <div className="col-lg-1 "></div>
//       </div>
//     </div>
//   </>
// );

const GoalCard = ({ goals, handleMouseEnter, handleMouseLeave, isaDeleted, setIsaDeleted, }) => {
  const [isaPopupVisible, setIsaPopupVisible] = useState(false);
  const [hover, setHover] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showaPopup, setShowaPopup] = useState(false);
  // const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const [popupContent, setPopupContent] = useState<JSX.Element | null>(null);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [blockHover, setblockHover] = useState(false);
  const [dotHover, setdotHover] = useState(false);



  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowaPopup(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // const handleDeleteGoal = () => {
  //   setShowConfirmationPopup(true);
  //   setShowPopup(false);
  // };

  const handleRestoreGoal = () => {
    setIsDeleted(false);
  };

  const handleConfirmDelete = () => {
    setIsDeleted(true);
    setIsaDeleted(true);
    setShowConfirmationPopup(false);
  };


  const handleaPopupClick = (campaign) => {
    // setTimeout(() => {
    //   window.scrollTo({ top: document.documentElement.scrollWidth, behavior: "smooth" });
    // }, 0);
    console.log("first", goals.name)
    setShowaPopup(true);

    // setShowConfirmationPopup(true);
    // setShowPopup(false);
  };

  // const modalaOpen = () => {
  //   setShowConfirmationPopup(true);
  //   setShowaPopup(false);
  // };
  const handleMouseEnter1 = () => {
    setblockHover(true);
    setIsaPopupVisible((prevIsPopUpVisible) => !prevIsPopUpVisible);
  };
  const handleMouseLeave1 = () => {
    setIsaPopupVisible(false);
    setblockHover(false);
  };

  const handleToggleContent = () => {
    setIsExpanded(!isExpanded);
    setIsaPopupVisible(true);
  };

  const handleMouseEnter2 = () => {
    setHover(true);
  };
  const handleMouseEnter3 = () => {
    setHover2(true);
  };
  const handleMouseLeave3 = () => {
    setHover2(false);
  };

  const handleDotEnter = () => {
    setdotHover(true);
  };
  const handleDotLeave = () => {
    setdotHover(false);
  };


  const handleaDeleteGoal = () => {
    setShowConfirmationPopup(true);
    setShowaPopup(false);
  };


  return (
    <>
      {!isDeleted && (
        <div>
          <div className="fs-7 text-gray-500 mb-2" key={goals.id}>
            {/* {goals.id === 1 && <Header />} */}
            <div className="card mb-5" style={cardStyles}>
              <div className="row gx-xl-12 gy-5 d-flex">
                <div className="col-lg-4 py-5 px-7">
                  <div className="d-flex align-items-center">
                    <span className="d-block justify-content-start flex-column flex-row pr-5">
                      <img src={goals.image} alt={goals.name} style={imageStyles} />
                      <span
                        className="text-gray-800 fw-bold"
                        style={goalNameStyles}
                      >
                        {goals.name}
                      </span>
                      <span
                        className="text-gray-800 mx-2"
                        style={accountDefaultStyles}
                      >
                        (account default)
                      </span>
                    </span>
                  </div>
                </div>
                <div className="col-lg-2 py-5"
                  style={{ backgroundColor: blockHover ? '#F1F3F4' : 'white' }}
                  onMouseOver={handleMouseEnter1}
                  onMouseOut={handleMouseLeave1}
                >
                  <div style={sourceStyles}>{goals.source}</div>
                </div>
                <div className="col-lg-3 d-flex align-items-center justify-content-center"
                  style={{ backgroundColor: blockHover ? '#F1F3F4' : 'white' }}
                  onMouseOver={handleMouseEnter1}
                  onMouseOut={handleMouseLeave1}
                >
                  <div
                    className="text-gray-800 fw-bold d-block fs-7 align-items-center">
                    <img src={warning} style={{ marginRight: '10px', marginTop: '5px' }} />
                    <span
                      className="action-text"
                      style={actionTextStyles}
                    // onMouseEnter={handleMouseEnter1}
                    // onMouseLeave={handleMouseLeave}
                    >
                      {goals.actions} action
                    </span>
                    {isaPopupVisible && (
                      <div
                        style={{
                          // flexWrap: 'wrap',
                          position: "absolute",
                          top: "75%",
                          left: "0",
                          backgroundColor: "#fff",
                          padding: "5px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                          zIndex: "2",
                          width: "380px",
                          // height: "205px",
                          fontSize: "13px",
                          marginLeft: "500px",
                          color: "#333",
                          alignContent: "center",
                          // justifyContent:'center',
                          alignItems: "center",
                          transition: "height 5.0s ease",
                        }}
                      >
                        <div
                          className="d-flex"
                          style={{
                            backgroundColor: "#FDF7E0",
                            width: "340px",
                            padding: "20px",
                            margin: "13px",
                            justifyContent: "space-between",
                            // height: "100px",
                            flex: 'wrap'
                          }}
                        >
                          <BsExclamationTriangle
                            style={{
                              color: '#A0522D',
                              height: "30%",
                              width: "12%",
                              marginRight: "10px",
                            }}
                          />
                          <div>
                            1 inactive or unverified conversion action from{" "}
                            <strong>{goals.source}</strong> for the{" "}
                            <strong>{goals.name}</strong> goal
                          </div>
                        </div>
                        <div className="ml-2 py-3 px-3"
                          style={{
                            // width: "365px",
                            backgroundColor: hover2 ? '#E5E5E5' : 'white',
                            marginTop: "10px",
                            justifyContent: "center",
                            cursor: 'pointer'
                          }}
                          onClick={handleToggleContent}
                          onMouseEnter={() => handleMouseEnter2()}
                        // height: "200px",

                        >

                          {isExpanded ? (

                            <BsChevronUp className="arrow-icon" size={20} style={{ color: hover2 ? 'black' : '#5F6368', }} />
                          ) : (
                            <BsChevronDown
                              className="arrow-icon"
                              size={20}
                              style={{ color: hover2 ? 'black' : '#5F6368', }}
                            />
                          )}
                          <span className="fw-bold ml-3" onMouseEnter={() => handleMouseEnter3()} onMouseLeave={() => handleMouseLeave3()}>
                            1 inactive conversion action
                          </span>
                        </div>

                        {isExpanded && (
                          <div className="d-flex align-items-center px-10" style={{ fontSize: '10px' }} >{goals.source}</div>
                        )}
                        <br />
                        <div>
                          <p className="px-5" style={{ fontSize: '10px', color: "#686C71" }}>
                            You can
                            <a href="#" style={{ color: '#009EF7' }}> edit this goal </a>
                            on your Conversions page
                          </p>
                        </div>
                      </div>

                    )}
                  </div>
                </div>
                <div className="col-lg-2 py-5" style={{ backgroundColor: blockHover ? '#F1F3F4' : 'white' }}
                  onMouseOver={handleMouseEnter1}
                  onMouseOut={handleMouseLeave1}></div>
                <div className="col-lg-1 d-flex align-items-center justify-content-center">
                  <div className="col-lg-3 d-flex pr-5 justify-content-end">

                    <span
                      className="text-gray-800 fw-bold d-block fs-6 p-4"
                      style={{ borderRadius: "50px", backgroundColor: dotHover ? '#F1F3F4' : 'transparent' }}
                      onClick={() => handleaPopupClick(goals.name)}
                      // onMouseEnter={(e) => {
                      //   (e.target as HTMLButtonElement).style.backgroundColor = "#F5F5F5";
                      //   (e.target as HTMLButtonElement).style.borderRadius = "50px"; // Change the background color on hover
                      //   // (e.target as HTMLButtonElement).style.cursor= inputValue2 === '' && cards.length === 0 ? "not-allowed" : "pointer";
                      // }}
                      // onMouseLeave={(e) => {
                      //   (e.target as HTMLButtonElement).style.backgroundColor = "white"; // Restore the initial background color
                      //   // (e.target as HTMLButtonElement).style.color = "#1a73e8"; // Change the background color on hover
                      // }}
                      onMouseEnter={handleDotEnter}
                      onMouseLeave={handleDotLeave}

                    >
                      <BsThreeDotsVertical className="" size={23} style={{ backgroundColor: dotHover ? '#F1F3F4' : 'transparent' }}
                        //  onMouseEnter={(e) => {
                        //   (e.target as HTMLButtonElement).style.backgroundColor = "#F5F5F5";
                        //   (e.target as HTMLButtonElement).style.borderRadius = "50px"; // Change the background color on hover
                        //   // (e.target as HTMLButtonElement).style.cursor= inputValue2 === '' && cards.length === 0 ? "not-allowed" : "pointer";
                        // }}
                        // onMouseLeave={(e) => {
                        //   (e.target as HTMLButtonElement).style.backgroundColor = "white"; // Restore the initial background color
                        //   // (e.target as HTMLButtonElement).style.color = "#1a73e8"; // Change the background color on hover
                        // }}
                        onMouseEnter={handleDotEnter}
                        onMouseLeave={handleDotLeave}
                      />
                    </span>
                    {showaPopup && (
                      <div
                        ref={popupRef}
                        style={{
                          position: "absolute",
                          bottom: "-15px",  //decreasing will shift it lower
                          // right: -150, //decreasing will shift it more to the right
                          right: -80, //decreasing will shift it more to the right
                          backgroundColor: "#fff",
                          padding: "10px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                          zIndex: "2",
                          // width: "270px",
                          height: '70px',
                          fontSize: "13px",
                          color: "#333",
                          flex: 'wrap',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "15px",
                            cursor: "pointer",
                          }}
                          onMouseEnter={(e) => {
                            (e.target as HTMLButtonElement).style.backgroundColor = "#F5F5F5";
                            // (e.target as HTMLButtonElement).style.color = "#1153aa"; // Change the background color on hover
                            // (e.target as HTMLButtonElement).style.cursor= inputValue2 === '' && cards.length === 0 ? "not-allowed" : "pointer";
                          }}
                          onMouseLeave={(e) => {
                            (e.target as HTMLButtonElement).style.backgroundColor = "white"; // Restore the initial background color
                            // (e.target as HTMLButtonElement).style.color = "#1a73e8"; // Change the background color on hover
                          }}
                          onClick={handleaDeleteGoal}
                        >
                          <RiDeleteBin6Line size={20} style={{ marginRight: "10px" }} />
                          Remove "{goals.name}" goals
                        </div>
                      </div>)}

                    {!showaPopup && showConfirmationPopup && (
                      <div
                        className="modal fade show p-7"
                        id="confirmationModal"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="confirmationModalLabel"
                        style={{
                          // display: "block",
                          position: "fixed",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          zIndex: 9999,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-body">
                              <h5 style={{ fontSize: "18px", lineHeight: '24px', marginBottom: '10px' }}>Remove this goal?</h5>
                              <p style={{ fontSize: "13px", color: "black" }}>
                                This campaign will no longer optimize for "Phone call
                                leads". Removing this goal won't affect your
                                account-level goals.
                              </p>
                            </div>
                            <div className="d-flex flex-end mb-3 px-3">
                              <button
                                type="button"
                                style={{ fontSize: '14px' }}
                                className="nav-link btn btn-sm btn-color-primary btn-active btn-active-light fw-bold px-4 me-1"
                                onMouseEnter={(e) => {
                                  (e.target as HTMLButtonElement).style.backgroundColor = "rgb(230,236,250)";
                                  // (e.target as HTMLButtonElement).style.color = "#1153aa"; // Change the background color on hover
                                  // (e.target as HTMLButtonElement).style.cursor= inputValue2 === '' && cards.length === 0 ? "not-allowed" : "pointer";
                                }}
                                onMouseLeave={(e) => {
                                  (e.target as HTMLButtonElement).style.backgroundColor = "white"; // Restore the initial background color
                                  // (e.target as HTMLButtonElement).style.color = "#1a73e8"; // Change the background color on hover
                                }}
                                onClick={() => setShowConfirmationPopup(false)}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                style={{ fontSize: '14px' }}
                                className="nav-link btn btn-sm btn-color-primary btn-active btn-active-light fw-bold px-4 me-1"
                                onMouseEnter={(e) => {
                                  (e.target as HTMLButtonElement).style.backgroundColor = "rgb(230,236,250)";
                                  // (e.target as HTMLButtonElement).style.color = "#1153aa"; // Change the background color on hover
                                  // (e.target as HTMLButtonElement).style.cursor= inputValue2 === '' && cards.length === 0 ? "not-allowed" : "pointer";
                                }}
                                onMouseLeave={(e) => {
                                  (e.target as HTMLButtonElement).style.backgroundColor = "rgb(230,236,250)"; // Restore the initial background color
                                  // (e.target as HTMLButtonElement).style.color = "#1a73e8"; // Change the background color on hover
                                }}
                                // onClick={handleDeleteGoal}
                                onClick={handleConfirmDelete}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      )}
      {/* Restore Goal Button */}
      {/* {isDeleted && (
        <div className="mb-5 py-2 px-5 d-flex" style={{ borderRadius: '7px', backgroundColor: '#E8F0FE', alignItems: 'center' }}>
          <div className="text-primary"><AiOutlineInfoCircle size={20} style={{ color: '#1153aa' }} /></div>
          <p style={{ fontSize: '14px', marginBottom: '-2px', marginLeft: '30px' }}>This change overrides your account goals setup. Your campaign will now optimize for the goals shown below.</p>
          <div className="btn btn-sm fw-bold btn-active-color-primary text-primary d-flex flex-end" style={{ marginLeft: '200px' }} onClick={handleRestoreGoal}>Restore Account Goals</div>
        </div>
      )} */}

    </>
  );
};

export default GoalCard;