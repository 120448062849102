import React, { useEffect, useRef, useState } from "react";
import { FormatUtil } from "../../../../assets/ts/_utils/FormatUtil";
import {
  URLS,
  getDataFromBackend,
  getMethodDataFromBackend,
} from "../../../../../app/modules/dashboards/DashboardRequests";
import CustomLoader from "../../../../../app/pages/dashboard/marketting_component/CustomLoader";
import { IoTriangleSharp } from "react-icons/io5";
import CustomBaselineTooltip from "../../../../../app/pages/dashboard/marketting_component/CustomBaselineTooltip";
import { appColor, primaryColors } from "../../../../assets/ts/_utils/AppColors";

type Statistic = {
  title: string;
  value: number;
  baseLine: number;
};

type Props = {
  productId: string;
  platformId: string;
  channelType: string;
  startDate: string;
  endDate: string;
  baseLine: string;
  statsSubUrl: string;
  productType: string;
  forecastedValue?: any
};

const ProductStatsCard: React.FC<Props> = ({
  productId,
  platformId,
  channelType,
  startDate,
  endDate,
  baseLine,
  statsSubUrl,
  productType,
  forecastedValue
}: Props) => {
  const [statsData, setStatsData] = useState<Statistic[]>([
    {
      title: "Total Spends",
      value: 0,
      baseLine: 19665704.64,
    },
    {
      title: "CRIF EL",
      value: 0,
      baseLine: 0,
    },
    {
      title: "Apps",
      value: 0,
      baseLine: 0,
    },
    {
      title: "Cards",
      value: 0,
      baseLine: 0,
    },
    {
      title: "App Ratio",
      value: 0,
      baseLine: 0,
    },
    {
      title: "CPCL",
      value: 0,
      baseLine: 0,
    },
    {
      title: "CPA",
      value: 0,
      baseLine: 0,
    },
    {
      title: "CPC",
      value: 0,
      baseLine: 0,
    },
    {
      title: "Card Ratio",
      value: 0,
      baseLine: 0,
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      let requestData = {
        productId,
        platformId,
        startDate,
        endDate,
        baseLine,
        productType,
      };
      let response = await getDataFromBackend(
        requestData,
        URLS.BASE_URL + channelType + statsSubUrl
      );
      console.log("Responses of stats api call : ", response?.data);
      let data = response?.data;
      setIsLoading(false);
      if (response?.data) {
        setStatsData(response?.data);
      } else {
        setStatsData([]);
      }
    };

    getData();

    return () => {
      // this now gets called when the component unmounts
    };
  }, [startDate, endDate, productId, platformId, productType, baseLine]);
  const popupRef = useRef<HTMLDivElement>(null);

  const [showGloss, setShowGloss] = useState(false);
  const [activeBaseline, setActiveBaseline] = useState(null);

  const handleMouseEnter1 = (baseLine) => {
    setShowGloss(true);
    setActiveBaseline(baseLine);
  };

  const handleMouseLeave1 = () => {
    setShowGloss(false);
    setActiveBaseline(null);
  };

  const calculateGrowth = (value: any, metricName: any, baseLine: any) => {
    if (metricName === "cpc" || metricName === "cpel" || metricName === "cpa") {
      return parseFloat(value) <= parseFloat(baseLine);
    } else {
      return parseFloat(value) >= parseFloat(baseLine);
    }
  };

  const calculateBaseComparison = (
    value: number,
    metricName: string,
    baseLine: number
  ) => {
    const tempChange = ((value - baseLine) / baseLine) * 100;
    const percentageChange = Math.abs(Number(tempChange.toFixed(2)));
    return percentageChange;
  };

  const renderBaseLineComparison = (
    value: any,
    metricName: any,
    baseLine: any
  ) => {
    const lossCss = {
      // color: "#97291E",
      transform: "rotate(180deg)",
      marginRight: "2px",
    };
    const profitCss = { marginRight: "2px" };
    const compareValue =
      ((Number(value) - Number(baseLine)) / Number(baseLine)) * 100;

    const isTotalSpend =
      metricName === "Total Spends" ||
      metricName === "CPCL" ||
      metricName === "CPA" ||
      metricName === "CPC";

    const colorValue =
      Number(compareValue) > 0
        ? isTotalSpend
          ? "#97291E"
          : "#068F2A"
        : "#97291E";

    return (
      <div
        className="px-3"
        style={{
          fontSize: metricName === "Total Spends" ? "18px" : "15px",
          // color: Number(compareValue) > 0 ? (isTotalSpend ? "#97291E" : "#068F2A") : "#97291E",
          // backgroundColor: Number(compareValue) > 0 ? (isTotalSpend ? "#dba19c" : "#bdf2cb") : "#dba19c",
          color:
            Number(compareValue) > 0
              ? isTotalSpend
                ? "#97291E"
                : "#068F2A"
              : isTotalSpend
                ? "#068F2A"
                : "#97291E",

          // backgroundColor:
          //   Number(compareValue) > 0
          //     ? isTotalSpend
          //       ? "#dba19c"
          //       : "#bdf2cb"
          //     : isTotalSpend
          //     ? "#bdf2cb"
          //     : "#dba19c",

          alignItems: "center",
          display: "flex",
        }}
      >
        <div>
          <IoTriangleSharp
            size={10}
            style={Number(compareValue) > 0 ? profitCss : lossCss}
          />
        </div>
        <div>
          {baseLine > 0
            ? `(${calculateBaseComparison(value, metricName, baseLine)}%)`
            : "(N/A)"}
        </div>
      </div>
    );
  };

  const renderItem = (statistic: Statistic) => {
    const { title, value, baseLine } = statistic;
    return (
      <div
        key={`stat${title}`}
        className=" py-6 px-1 card card-flush w-lg-10"
        style={{
          borderRadius: "5px",
          width: title === "Total Spends" ? "280px" : "180px",
          height: title === "Total Spends" ? "160px" : "110px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "2px solid #A0A0A0",
          background: `linear-gradient(90deg,${title == "App Ratio" || title == "Card Ratio"
            ? "#08112D"
            : "#E84A42"
            }  2%, rgba(0,0,0,0)0%)`,
        }}
      >
        {!isLoading ? (
          <div className="justify-content-center align-items-center">
            <div
              className="fw-semibold d-flex justify-content-center"
              style={{
                fontSize: title === "Total Spends" ? "22px" : "18px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
                color: "black",
                fontWeight: "800",
              }}
            >
              {title}
            </div>
            <div
              className="d-flex justify-content-center"
              style={{
                fontWeight: "bold",
                color: title === "Total Spends" ? "black" : "grey",
                fontSize: title === "Total Spends" ? "32px" : "25px",
              }}
            >
              {title === "CPC" ||
                title === "CPA" ||
                title === "CPCL" ||
                title === "Total Spends"
                ? ` ₹${Math.round(Number(value)).toLocaleString("en-IN")}`
                : title === "App Ratio" || title === "Card Ratio"
                  ? `${Math.round(Number(value)).toLocaleString("en-IN")}%`
                  : Math.round(Number(value)).toLocaleString("en-IN") || ""}
            </div>


            <div className="fw-bold" style={{color: primaryColors.red}}>
              {title === "CPC" && forecastedValue ?
                `Forecasted ${title} : ${forecastedValue?.predicted_avg_cpc.toFixed(2)}`
                : ''}
            </div>



            <div
              className="d-flex justify-content-center"
              onMouseEnter={() => handleMouseEnter1(baseLine)}
              onMouseLeave={handleMouseLeave1}
              style={{ cursor: "pointer" }}
              title={
                title === "CPC" ||
                  title === "CPA" ||
                  title === "CPCL" ||
                  title === "Total Spends"
                  ? `Baseline : ₹ ${Math.round(baseLine).toLocaleString(
                    "en-IN"
                  )}`
                  : title === "App Ratio" || title === "Card Ratio"
                    ? `Baseline : ${Math.round(baseLine)}%`
                    : `Baseline : ${Math.round(baseLine).toLocaleString("en-IN")}`
              }
            >
              {renderBaseLineComparison(value, title, baseLine)}
              {/* {showGloss && activeBaseline === baseLine && (
                <CustomBaselineTooltip
                  popupRef={popupRef}
                  baseLine={baseLine}
                  title={
                    title === "CPC" ||
                    title === "CPA" ||
                    title === "CPCL" ||
                    title === "Total Spends"
                      ? `₹ ${Math.round(baseLine).toLocaleString("en-IN")}`
                      : title === "App Ratio" || title === "Card Ratio"
                      ? `${Math.round(baseLine)}%`
                      : Math.round(baseLine).toLocaleString("en-IN")
                  }
                />
              )} */}
            </div>
            {/* </div> */}
          </div>
        ) : (
          <div className="py-5" id={title} style={{ alignSelf: "center" }}>
            <CustomLoader />
          </div>
        )}
      </div>
    );
  };

  // const renderMainItem = (statistic: Statistic) => {
  //   const { title, value, baseLine } = statistic;
  //   // const lossCss = {
  //   //   // color: "#97291E",
  //   //   transform: "rotate(180deg)",
  //   //   marginLeft: "2px",
  //   // };
  //   // const profitCss = { marginLeft: "2px" };

  //   return (
  //     <div
  //       key={`stat${title}`}
  //       className=" py-6 px-1 card card-flush w-lg-10"
  //       style={{
  //         borderRadius: "5px",
  //         width: "180px",
  //         height: "110px",
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //         border: "2px solid #A0A0A0",
  //         background: `linear-gradient(90deg,${
  //           title == "App Ratio" || title == "Card Ratio"
  //             ? "#97291E"
  //             : "#E84A42"
  //         }  5%, rgba(0,0,0,0)0%)`,
  //       }}
  //     >
  //       {statsData?.length > 0 ? (
  //         <div className="justify-content-center align-items-center">
  //           <div
  //             className="fw-semibold d-flex justify-content-center"
  //             style={{
  //               fontSize: "18px",
  //               display: "flex",
  //               alignItems: "center",
  //               justifyContent: "center",
  //               borderRadius: "10px",
  //               color: "black",
  //               fontWeight: "800",
  //             }}
  //           >
  //             {title}
  //           </div>
  //           <div
  //             className="d-flex justify-content-center"
  //             style={{
  //               fontWeight: "bold",
  //               color: "grey",
  //               fontSize: "25px",
  //             }}
  //           >
  //             {title === "cpc" || title === "cpa" || title === "cpcl"
  //               ? `INR ${Math.round(Number(value))}`
  //               : title === "appRatio" || title === "cardRatio"
  //               ? `${Math.round(Number(value))}%`
  //               : Math.round(Number(value)) || ""}
  //           </div>
  //           <div className="d-flex justify-content-center">
  //             {renderBaseLineComparison(value, title, baseLine)}
  //           </div>
  //           {/* </div> */}
  //         </div>
  //       ) : (
  //         <div className="py-5" id={title} style={{ alignSelf: "center" }}>
  //           <CustomLoader />
  //         </div>
  //       )}
  //     </div>
  //   );
  // };

  // const containerRef = useRef<HTMLDivElement | null>(null);
  // const [isSmallScreen, setIsSmallScreen] = useState(false);

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (containerRef.current) {
  //       setIsSmallScreen(containerRef.current.offsetWidth < 908);
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);
  //   handleResize();

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  return (
    <div
      className="card h-lg-100 p-5 my-10"
      style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
    >
      <div className="row gx-xl-10 p-5 mt-5 mb-5 px-12 ">
        <div className="col-xl-4 d-flex align-items-center">
          {statsData
            .filter((statistic) => statistic.title == "Total Spends")
            .map((statistic) => (
              <div className="" key={statistic.title}>
                {renderItem(statistic)}
              </div>
            ))}
        </div>
        <div className="col-xl-8 d-flex flex-wrap align-items-center">
          <div className="row gx-xl-10 gy-10">
            {statsData
              .filter((statistic) => statistic.title !== "Total Spends")
              .map((statistic) => (
                <div className="col-xl-3" key={statistic.title}>
                  {renderItem(statistic)}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductStatsCard;
