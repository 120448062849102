/** @format */

import React, { useEffect, useState } from "react";

import * as am5 from "@amcharts/amcharts5";
//chart type
import * as am5xy from "@amcharts/amcharts5/xy";
import { ChartTheme } from "../../../../theme/assets/ts/_utils/ChartTheme";
import CustomLoader from "./CustomLoader";
import Modal from "./Modal";

type Props = {
  chartID: string;
  data: any;
  isZoomModalOpen: any;
  setIsZoomModalOpen: any;
  title: string;
};

const TrendsGraphV2: React.FC<Props> = ({
  chartID,
  data,
  isZoomModalOpen,
  setIsZoomModalOpen,
  title,
}) => {
  //   const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);

  const myChartId = isZoomModalOpen ? `${chartID}-modal` : chartID;

  useEffect(() => {
    let root = am5.Root.new(myChartId);
    root._logo?.dispose();
    root.setThemes([ChartTheme.getGradientTheme(root)]);
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: false,
        panX: false,
        panY: false,
        pinchZoomX: false,
      })
    );

    // Define data
    root.dateFormatter.setAll({
      dateFormat: "yyyy",
      dateFields: ["valueX"],
    });

    let yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 30,
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: yRenderer,
      })
    );

    yRenderer.grid.template.setAll({
      location: 1,
    });

    yAxis.get("renderer").grid.template.setAll({ forceHidden: true });
    // yRenderer.grid.template.setAll({
    //     location: 1,
    //     strokeWidth: 1,

    // })

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 1,
        groupData: false,
        baseInterval: {
          timeUnit: "day",
          count: isZoomModalOpen ? 1 : 2,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          forceHidden: true,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xAxis.get("renderer").grid.template.setAll({ forceHidden: true });

    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", true);

    // add scrollbar
    //   chart.set("scrollbarX", am5.Scrollbar.new(root, {
    //     orientation: "horizontal"
    //   }));

    createSeries(
      root,
      chart,
      xAxis,
      yAxis,
      "Series with breaks",
      "value",
      "openValue"
    );
    return () => {
      root.dispose();
    };
  }, [myChartId, data]);

  const createSeries = (root, chart, xAxis, yAxis, name, field, openField) => {
    let series = chart.series.push(
      am5xy.LineSeries.new(root, {
        minBulletDistance: 10,
        connect: false,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "{valueY}",
        }),
      })
    );
    // series.fill = am5.color("blue");
    series.set("fill", am5.color(0xe8e8e8));
    series.set("stroke", am5.color(0xb1b1b1));
    series.strokes.template.setAll({
      strokeWidth: 1,
    });
    series.fills.template.setAll({
      // color:'yellow',
      fillOpacity: 0.5,
      visible: true,
    });
    series.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "yyyy-MM-dd",
      dateFields: ["date"],
    });

    series.data.setAll(data);
  };

  return (
    <div className="d-flex justify-content-between align-items-start flex-column">
      {data?.length <= 0 ? (
        <div
          id={myChartId}
          style={{ alignSelf: "center", marginBottom: "30px" }}
        >
          <CustomLoader />
        </div>
      ) : !isZoomModalOpen ? (
        <div id={myChartId} style={{ height: "200px", width: "100%" }}></div>
      ) : (
        <Modal
          isOpen={isZoomModalOpen}
          onClose={() => setIsZoomModalOpen(!isZoomModalOpen)}
          title={title}
        >
          <div id={myChartId} style={{ height: "400px", width: "100%" }}></div>
        </Modal>
      )}
    </div>
  );
};
export default TrendsGraphV2;
