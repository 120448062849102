import { FC, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isNotEmpty, toAbsoluteUrl } from "../../../../../../theme/helpers";
import clsx from "clsx";
import { createUser, updateUser } from "../../../../application/_requests";

const editUserSchema = Yup.object().shape({
  participants: Yup.string()
    .label("")
    .min(3, "too short")
    .max(50, "too long")
    .required("Inavlid Participants"),
  organisation: Yup.string()
    .label("")
    .min(3, "too short")
    .max(50, "too long")
    .required("Organization is required"),
});

const AddSubmissionModalForm: FC<any> = ({ user, isUserLoading, onCancel }) => {
  const formik = useFormik({
    initialValues: {
      participants: "",
      organisation: "",
      fileName:"",
    },
    validationSchema: editUserSchema,
    onSubmit: (values) => {
    },
  });

  return (
    <>
      <form
        id="kt_modal_add_user_form"
        className="form"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          <div className="fv-row mb-7">
            <label className="required fw-bold fs-6 mb-2">Participants</label>
            <input
              placeholder="Participants"
              {...formik.getFieldProps("participants")}
              type="text"
              name="participants"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0",
                { "is-invalid": formik.touched.participants && formik.errors.participants },
                {
                  "is-valid": formik.touched.participants && !formik.errors.participants,
                }
              )}
              autoComplete="off"
            />
            {formik.touched.participants && formik.errors.participants && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.participants}</span>
                </div>
              </div>
            )}
          </div>
          <div className="fv-row mb-7">
            <label className="required fw-bold fs-6 mb-2">Organization</label>
            <input
              placeholder="Organization"
              {...formik.getFieldProps("organization")}
              type="text"
              name="organization"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0",
                { "is-invalid": formik.touched.organisation && formik.errors.organisation },
                {
                  "is-valid": formik.touched.organisation && !formik.errors.organisation,
                }
              )}
              autoComplete="off"
            />
          </div>
          <div className="fv-row mb-7">
            <label className="required fw-bold fs-6 mb-2">File</label>
            <input
              {...formik.getFieldProps("fileName")}
              type="file"
              name="fileName"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0",
                { "is-invalid": formik.touched.fileName && formik.errors.fileName },
                {
                  "is-valid": formik.touched.fileName && !formik.errors.fileName,
                }
              )}
              autoComplete="off"
            />
          </div>
        </div>

        <div className="text-center pt-15">
          <button
            type="reset"
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
            onClick={onCancel}
          >
            Discard
          </button>

          <button
            type="submit"
            className="btn btn-primary btn-custom"
            data-kt-users-modal-action="submit"
          >
            <span className="indicator-label">Submit</span>
          </button>
        </div>
      </form>
    </>
  );
};

export { AddSubmissionModalForm };
