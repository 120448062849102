/** @format */

import axios, { AxiosResponse } from "axios";
// import  * as cheerio  from "cheerio";
import { ID, Response } from "../../../theme/helpers";
// import { useStorage } from "../../../../../hooks/useStorage";
import {
	Doc,
	ResponseQuery,
	User,
	UsersQueryResponse,
	AddAnnotation,
	RULE,
} from "../apps/user-management/users-list/core/_models";
import * as authHelper from "../auth/core/AuthHelpers";
import { setupInterceptorsTo } from "../interceptors";

const API_URL = process.env.REACT_APP_THEME_API_URL;
const API_BASE_URL = process.env.REACT_APP_API_URL;

const CAMPAIGN_API_URL = process.env.REACT_APP_API_URL;

const USER_URL = `${API_URL}/user`;
const GET_USERS_URL = `${API_URL}/users/query`;
const GET_USERS_VIEW = `${API_BASE_URL}/users/view`;
const REGISTER_USERS_VIEW = `${API_BASE_URL}/users/register`;
const DELETE_USERS_VIEW = `${API_BASE_URL}/users/delete`;
const EDIT_USERS_VIEW = `${API_BASE_URL}/users/edit`;
const GET_MASTER_ROLE = `${API_BASE_URL}/fetchMasterRoles`;
const ADD_RESPONSE = `${API_BASE_URL}/document/upload`;
const GET_ORGANIZATIONS = `${API_BASE_URL}/fetchOrganisation`;
const GET_CATEGORY = `${API_BASE_URL}/fetchCategory`;
const ADD_SOURCE = `${API_BASE_URL}/document/upload`;
const ADD_ANNOTATION = `${API_BASE_URL}/annotation/add`;
const GET_SOURCE_LIST = `${API_BASE_URL}/document/sourceList`;
const GET_RESPONSES = `${API_BASE_URL}/document/responseList`;
const FETCH_ANNOTATIONS = `${API_BASE_URL}/annotation/list`;
const DELETE_ANNOTATION = `${API_BASE_URL}/annotation/delete`;
const FETCH_ANNOTATION_SCREEN_DETAILS = `${API_BASE_URL}/annotation/annotationlist`;
const DELETE_SOURCE = `${API_BASE_URL}/document/deleteSource`;
const DELETE_RESPONSE = `${API_BASE_URL}/document/deleteResponse`;
const PARTICIPANT_LIST = `${API_BASE_URL}/participant/list`;
const GET_ORGANIZATIONS_LIST = `${API_BASE_URL}/organisation/list`;
const ADD_ORGANIZATION = `${API_BASE_URL}/organisation/create`;
const ADD_PARTICIPANT = `${API_BASE_URL}/participant/create`;
const DELETE_PARTICIPANT = `${API_BASE_URL}/participant/delete`;
const DELETE_ORGANIZATION = `${API_BASE_URL}/organisation/delete`;
const EDIT_ORGANIZATION = `${API_BASE_URL}/organisation/edit`;
const EDIT_PARTICIPANT = `${API_BASE_URL}/participant/edit`;

const ADD_DOMAIN = `${API_BASE_URL}/master-management/domain-create`;
const EDIT_DOMAIN = `${API_BASE_URL}/master-management/domain-edit`;
const DELETE_DOMAIN = `${API_BASE_URL}/master-management/domain-delete`;
const LIST_DOMAIN = `${API_BASE_URL}/master-management/domain-list`;
const EDIT_SOURCE = `${API_BASE_URL}/document/source/edit`;
const EDIT_RESPONSE = `${API_BASE_URL}/document/response/edit`;
const ORGANIZATION_PARTICIPANT = `${API_BASE_URL}/organisation/list-under-participant`; //not implemented
const ADD_ORGANIZATION_PARTICIPANT = `${API_BASE_URL}/organisation/add`;
const DELETE_ORGANIZATION_PARTICIPANT = `${API_BASE_URL}/organisation/remove`;
const USER_LIST = `${API_BASE_URL}/users/list`; //needs to be changed
const USER_EMAIL_LIST = `${API_BASE_URL}/users/existingEmails`; //needs to be changed

const GET_ALL_CAMPAIGN = `${CAMPAIGN_API_URL}/home/campaigns/`;
const GET_ALL_LIST = `${CAMPAIGN_API_URL}/home/getCampaigns`;

const GET_ALL_SOCIAL_LIST = `${CAMPAIGN_API_URL}/home/channels`;
const GET_DETAIL_SOCIAL_LIST = `${CAMPAIGN_API_URL}/detail/channels`;

const GET_NEXT_SOCIAL_LIST = `${CAMPAIGN_API_URL}/home/nextchannel`;
const GET_CAMPAIGN_DETAILS = `${CAMPAIGN_API_URL}/dashboard`;

const CAMPAIGN_TOTAL_LEADS = `${CAMPAIGN_API_URL}/report/leads`;

const CAMPAIGN_ORGANICVPAID_PIE = `${CAMPAIGN_API_URL}/report/organicVsPaid/`;
const CAMPAIGN_MALEFEMALE_PIE = `${CAMPAIGN_API_URL}/report/MaleVsFemail`;
const CAMPAIGN_AGE_COLUMNGRAPH = `${CAMPAIGN_API_URL}/report/columnGraphData/iuy7788890`;
const CAMPAIGN_AUDIENCEGROWTH_STACKEDGRAPH = `${CAMPAIGN_API_URL}/report/stackData/iuy7788890`;
const GET_CAMPAIGN_HIGHLIGHTS = `${CAMPAIGN_API_URL}/dashboard/highlights`;
const GET_CAMPAIGN_EXTERNAL = `${CAMPAIGN_API_URL}/dashboard/externallinks`;
const GET_CAMPAIGN_FEATURED = `${CAMPAIGN_API_URL}/dashboard/featuredcampaigns`;
const GET_CAMPAIGN_FEATUREDNAME = `${CAMPAIGN_API_URL}/dashboard/featuredcampaigns`;

const GET_TABLES = `${CAMPAIGN_API_URL}/report/popularInterest/iuy7788890`;
const GET_BAR = `${CAMPAIGN_API_URL}/report/columnGraphData/iuy7788890`;
const GET_BUBBLE_CHART = `${CAMPAIGN_API_URL}/dashboard/chartdata`;
const GET_BUBBLE_DETAIL = `${CAMPAIGN_API_URL}/detail/chartdata`;

const GET_TOP_LIST = `${CAMPAIGN_API_URL}/report/popularInterest`;
const GET_COLUMN_DATA = `${CAMPAIGN_API_URL}/report/age`;
const GET_MALE_DONUT_DATA = `${CAMPAIGN_API_URL}/report/gender`;
const GET_ORGANIC_DONUT_DATA = `${CAMPAIGN_API_URL}/report/organicVsPaid`;
const GET_AUDIENCE_GROWTH_DATA = `${CAMPAIGN_API_URL}/report/audiencegrowth`;

const GET_SOCIAL_LIST = `${CAMPAIGN_API_URL}/dashboard`;
const GET_ALL_SOCIAL_DETAIL_LIST = `${CAMPAIGN_API_URL}/dashboard`;
// url above to be edited ^^

const GET_CAMPAIGN_FEATUREDMEDIA = `${CAMPAIGN_API_URL}/detail/featuredmedias`;
const GET_DETAIL_HIGHLIGHTS = `${CAMPAIGN_API_URL}/detail/highlights`;
const GET_Master_RULE_LIST = `${CAMPAIGN_API_URL}/masterrule/all`;
const DELETE_Master_RULE = `${CAMPAIGN_API_URL}/masterrule/delete`;
const ADD_Master_RULE = `${CAMPAIGN_API_URL}/masterrule/saveOrUpdate`;
const ADD_CAMPAIGN = `${CAMPAIGN_API_URL}/home/createCampaign/page/1.1`;
const ADD_CAMPAIGNTYPE = `${CAMPAIGN_API_URL}/home/createCampaign/page/1.2`;

const ADD_BIDDING = `${CAMPAIGN_API_URL}/home/createCampaign/page/2`;
const ADD_SETTINGS = `${CAMPAIGN_API_URL}/home/createCampaign/page/3.1`;
const MORE_SETTINGS = `${CAMPAIGN_API_URL}/home/createCampaign/page/3.2`;

const ADD_KEYWORDS = `${CAMPAIGN_API_URL}/home/createCampaign/page/4`;
const ADD_BUDGET = `${CAMPAIGN_API_URL}/home/createCampaign/page/5`;
const EDIT_CAMPAIGN = `${API_BASE_URL}/home/updateCampaign`;


// const auth = useStorage.get("kt-auth-react-v");

// https://apps.trisysit.com/nasbackendapi/document/viewDocument/_1673371782803prakash.pdf

const GET_DOC = `${API_BASE_URL}/document/viewDocument`;

const auth = authHelper.getAuth();

setupInterceptorsTo(axios);

const getUsers = (query: string): Promise<UsersQueryResponse> => {
	return axios
		.get(`${GET_USERS_URL}?${query}`)
		.then((d: AxiosResponse<UsersQueryResponse>) => d.data);
};

const getUsersView = (query: string): any => {
	return axios
		.get(`${GET_USERS_VIEW}?${query}`, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};
const getDoc = (query: string): any => {
	return fetch(
		new Request(`${GET_DOC}?documentId=${query}`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
			mode: "cors",
			cache: "default",
		})
	)
		.then((response) => {
			return response.blob();
		})
		.then((blob) => {
			const file = window.URL.createObjectURL(blob);
			const iframe = document.querySelector("iframe");
			if (iframe?.src) iframe.src = file;
		})
		.catch((err) => {
			console.log("FETCH ERROR...", err);
		});
};

const getAllUsersEmail = (): any => {
	return axios
		.get(`${USER_EMAIL_LIST}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

// CAMPAIGN API CALL

//get all campaign
const getAllCampaigns = (): any => {
	return axios
		.get(`${GET_ALL_CAMPAIGN}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

const getAllListing = (): any => {
	return axios
		.get(`${GET_ALL_LIST}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};
//get all campaign
const getBubbleData = (): any => {
	return axios
		.get(`${GET_BUBBLE_CHART}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};
const getBubbleDetail = (query: string): any => {
	return axios
		.get(`${GET_BUBBLE_DETAIL}/${query}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

//get all social list
const getAllSocialList = (): any => {
	return axios
		.get(`${GET_ALL_SOCIAL_LIST}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

const getDetailSocialList = (query: string): any => {
	return axios
		.get(`${GET_DETAIL_SOCIAL_LIST}/${query}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

//get social graph data
const getAllSocialData = (query: string): any => {
	return axios
		.get(`${GET_ALL_SOCIAL_LIST}/${query}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

const getAllSocialDetailData = (query: string): any => {
	return axios
		.get(`${GET_ALL_SOCIAL_DETAIL_LIST}/${query}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

//get next social graph data
const getNextSocialList = (query: string): any => {
	console.log(query, "uuuuuipp[xxx");
	return axios
		.get(`${GET_NEXT_SOCIAL_LIST}/${query}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

const getSocialList = (query: string): any => {
	return axios
		.get(`${GET_SOCIAL_LIST}/${query}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

//get social graph data
const gatCampaignDataById = (query: string): any => {
	return axios
		.get(`${GET_CAMPAIGN_DETAILS}/${query}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};
// const getCampaignHighlights = (query: string): any => {
// 	return axios
// 		.get(`${GET_CAMPAIGN_HIGHLIGHTS}/${query}`, {
// 			headers: {
// 				Authorization: `Bearer ${auth?.token}`,
// 			},
// 		})
// 		.then((d) => d.data)
// 		.catch((e) => {
// 			console.log(e, "api error");
// 		});
// };
const getCampaignHighlights = (): any => {
	return axios
		.get(`${GET_CAMPAIGN_HIGHLIGHTS}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

const getMasterRules = (): any => {
	return axios
		.get(`${GET_Master_RULE_LIST}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

const getDetailHighlights = (query: string): any => {
	return axios
		.get(`${GET_DETAIL_HIGHLIGHTS}/${query}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};
const getExternal = (): any => {
	return axios
		.get(`${GET_CAMPAIGN_EXTERNAL}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};
const getFeatured = (): any => {
	return axios
		.get(`${GET_CAMPAIGN_FEATURED}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

const getFeaturedName = (query: string): any => {
	return axios
		.get(`${GET_CAMPAIGN_FEATUREDNAME}/${query}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

const getTopListing = (query: string | undefined): any => {
	return axios
		.get(`${GET_TOP_LIST}/${query}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

const getColumnData = (query: string | undefined): any => {
	return axios
		.get(`${GET_COLUMN_DATA}/${query}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

const getMaleDonutData = (query: string | undefined): any => {
	return axios
		.get(`${GET_MALE_DONUT_DATA}/${query}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

const getOrganicDonutData = (query: string | undefined): any => {
	return axios
		.get(`${GET_ORGANIC_DONUT_DATA}/${query}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

const getAudienceGrowthData = (query: string | undefined): any => {
	return axios
		.get(`${GET_AUDIENCE_GROWTH_DATA}/${query}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};
const getFeaturedMedia = (query: string): any => {
	return axios
		.get(`${GET_CAMPAIGN_FEATUREDMEDIA}/${query}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

const tableList = (): any => {
	return axios
		.get(`${GET_TABLES}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

const barGraph = (): any => {
	return axios
		.get(`${GET_BAR}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

const uploadUserData = (data: any): any => {
	// return axios
	// .post("https://apps.trisysit.com/nasbackendapi/register", {headers:{Authorization: `Bearer ${auth?.token}`}}, {data:{data}})
	// .then((d) => console.log(d))
};

const getUserById = (id: ID): Promise<User | undefined> => {
	return axios
		.get(`${USER_URL}/${id}`)
		.then((response: AxiosResponse<Response<User>>) => response.data)
		.then((response: Response<User>) => response.data);
	// .catch((e)=>console.log(e))
};

const createUser = (user: User): Promise<User | undefined> => {
	return axios
		.post(REGISTER_USERS_VIEW, user, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((response: any) => response.data.message)
		.catch((e: any) => console.log(e, "api error"));
};

const createParticipant = (user: User): Promise<User | undefined> => {
	return axios
		.post(ADD_PARTICIPANT, user, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((response: any) => response.data.message)
		.catch((e: any) => console.log(e, "api error"));
};



const updateUser = (user: User, id?: string): Promise<User | undefined> => {
	return axios
		.post(`${EDIT_USERS_VIEW}/${id}`, user, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((response: any) => response.data)
		.catch((e: any) => console.log(e, "api error"));
};

const deleteUser = (userId: ID): Promise<void> => {
	return axios
		.get(`${DELETE_USERS_VIEW}/${userId}`, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then(() => {})
		.catch((e: any) => console.log(e, "api error"));
};

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
	const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`));
	return axios
		.all(requests)
		.then(() => {})
		.catch((e: any) => console.log(e, "api error"));
};

const getUserRoles = (): any => {
	return axios
		.get(GET_MASTER_ROLE, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((res: any) => res.data)
		.catch((e: any) => console.log(e, "api error"));
};

const AddResponse = async (docRequest: Doc | any, file: any) => {
	const formData = new FormData();
	let l = file.length;
	for (var x = 0; x < l; x++) {
		formData.append("file", file[x]);
	}
	formData.append("docRequest", JSON.stringify(docRequest));
	return axios
		.post(ADD_RESPONSE, formData, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: any) => response)
		.catch((e: any) => console.log(e, "api error"));
};

const AddSource = async (docRequest: Doc | any, file: any) => {
	const formData = new FormData();
	formData.append("file", file);
	formData.append("docRequest", JSON.stringify(docRequest));
	return axios
		.post(ADD_SOURCE, formData, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: any) => response)
		.catch((e: any) => console.log(e, "api error"));
};

const deleteSource = (draftId: ID): Promise<void> => {
	return axios
		.delete(`${DELETE_SOURCE}?id=${draftId}`, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then(() => {})
		.catch((e: any) => console.log(e, "api error"));
};

const deleteResponse = (responseId: ID): Promise<void> => {
	return axios
		.delete(`${DELETE_RESPONSE}?id=${responseId}`, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then(() => {})
		.catch((e: any) => console.log(e, "api error"));
};

const getCategory = (): any => {
	return axios
		.get(GET_CATEGORY, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((res: any) => res.data)
		.catch((e: any) => console.log(e, "api error"));
};

const getSourceList = (query: string): any => {
	return axios
		.get(`${GET_SOURCE_LIST}?${query}`, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

const getParticipantList = (query: string, id: string | undefined): any => {
	return axios
		.get(`${PARTICIPANT_LIST}/${id}?${query}`, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

const deleteParticipant = (participantId: ID): Promise<void> => {
	return axios
		.delete(`${DELETE_PARTICIPANT}?participantId=${participantId}`, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then(() => {})
		.catch((e: any) => console.log(e, "api error"));
};

const editParticipant = (user: User, id?: any): Promise<User | undefined> => {
	return axios
		.post(`${EDIT_PARTICIPANT}/${id}`, user, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((response: any) => response.data)
		.catch((e: any) => console.log(e, "api error"));
};

const getOrganizations = (): any => {
	return axios
		.get(GET_ORGANIZATIONS, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((res: any) => res.data)
		.catch((e: any) => console.log(e, "api error"));
};

const addAnnotation = async (annotationData: AddAnnotation) => {
	return axios
		.post(ADD_ANNOTATION, annotationData, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: any) => response)
		.catch((e: any) => console.log(e, "api error"));
};

const getResponse = async (
	id: string | undefined,
	query: string
): Promise<ResponseQuery> => {
	return axios
		.get(`${GET_RESPONSES}/${id}?${query}`, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((res: any) => res.data)
		.catch((e: any) => console.log(e, "api error"));
};

const fetchAnnotationsList = async () => {
	return axios
		.get(FETCH_ANNOTATIONS, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: any) => response)
		.catch((e: any) => console.log(e, "api error"));
};

const deleteAnnotation = async (annotationId: any) => {
	return axios
		.get(`${DELETE_ANNOTATION}/${annotationId}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: any) => response)
		.catch((e: any) => console.log(e, "api error"));
};

const fetchAnnotationScreenDetails = async (id: any) => {
	return axios
		.get(`${FETCH_ANNOTATION_SCREEN_DETAILS}/${id}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: any) => response.data)
		.catch((e: any) => console.log(e, "api error"));
};

const getOrganizationsList = (query: string): any => {
	return axios
		.get(`${GET_ORGANIZATIONS_LIST}?${query}`, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((res: any) => res.data)
		.catch((e: any) => console.log(e, "api error"));
};

const createOrganization = async (organizationData: any) => {
	return axios
		.post(ADD_ORGANIZATION, organizationData, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: any) => response.data)
		.catch((e: any) => console.log(e, "api error"));
};
const createCampaign = async (campaignData: any) => {
	return axios
		.post(ADD_CAMPAIGN, campaignData, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: any) => response.data)
		.catch((e: any) => console.log(e, "api error"));
};
const createCampaignType = async (campaignData: any) => {
	return axios
		.post(ADD_CAMPAIGNTYPE, campaignData, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: any) => response.data)
		.catch((e: any) => console.log(e, "api error"));
};


const editOrganization = (user: User, id?: any): Promise<User | undefined> => {
	return axios
		.post(`${EDIT_ORGANIZATION}/${id}`, user, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((response: any) => response.data)
		.catch((e: any) => console.log(e, "api error"));
};

const deleteOrganizaton = async (orgId: any) => {
	return axios
		.delete(`${DELETE_ORGANIZATION}?orgId=${orgId}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: any) => response)
		.catch((e: any) => console.log(e, "api error"));
};

const addDomain = async (domainData: any) => {
	return axios
		.post(ADD_DOMAIN, domainData, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: any) => response)
		.catch((e: any) => console.log(e, "api error"));
};

const listDomain = (query: string): any => {
	return axios
		.get(`${LIST_DOMAIN}?${query}`, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((res: any) => res.data)
		.catch((e: any) => console.log(e, "api error"));
};

const editDomain = (domainData: User, id?: any): Promise<User | undefined> => {
	return axios
		.post(`${EDIT_DOMAIN}/${id}`, domainData, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((response: any) => response.data)
		.catch((e: any) => console.log(e, "api error"));
};

const deleteDomain = async (domainId: any) => {
	return axios
		.delete(`${DELETE_DOMAIN}?domainId=${domainId}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: any) => response)
		.catch((e: any) => console.log(e, "api error"));
};

const editSource = async (temp: any, id: any) => {
	return axios
		.post(`${EDIT_SOURCE}/${id}`, temp, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: any) => response)
		.catch((e: any) => console.log(e, "api error"));
};

const editCampaign =(temp: any ) => {
	console.log("qqqqqqqqqqqqaaaaaaa")
	return axios
		.post(EDIT_CAMPAIGN, temp, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: any) => {
			console.log("ZZZZZZZZZZZZZZ",response)
			return response
		})
		.catch((e: any) => console.log(e, "api error"));
};



const editResponse = async (temp: any, id: any) => {
	return axios
		.post(`${EDIT_RESPONSE}/${id}`, temp, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: any) => response)
		.catch((e: any) => console.log(e, "api error"));
};

const getParticipantOrganizationList = (
	query: string,
	id: string | undefined
): any => {
	return axios
		.get(`${ORGANIZATION_PARTICIPANT}/${id}?${query}`, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((d: any) => d.data)
		.catch((e: any) => {
			console.log(e, "api error");
		});
};
const addParticapintOrganization = (temp: any) => {
	return axios
		.post(`${ADD_ORGANIZATION_PARTICIPANT}`, temp, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: any) => response)
		.catch((e: any) => console.log(e, "api error"));
};
const deleteParticapintOrganization = async (
	participantOrganizationId: any
) => {
	return axios
		.delete(
			`${DELETE_ORGANIZATION_PARTICIPANT}?participantOrganisationId=${participantOrganizationId}`,
			{
				headers: {
					Authorization: `Bearer ${auth?.token}`,
				},
			}
		)
		.then((response: any) => response)
		.catch((e: any) => console.log(e, "api error"));
};

//get total leads number
const ReportTotalLead = (query: string | undefined): any => {
	return axios
		.get(`${CAMPAIGN_TOTAL_LEADS}/${query}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

//get organic vs paid pie data

const OrganicPaid = (query: string | undefined): any => {
	return axios
		.get(`${CAMPAIGN_ORGANICVPAID_PIE}/${query}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

//get male female pie data
const MaleFemale = (): any => {
	return axios
		.get(`${CAMPAIGN_MALEFEMALE_PIE}}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

//get age column graph data
const AgeColumnGraph = (): any => {
	return axios
		.get(`${CAMPAIGN_AGE_COLUMNGRAPH}}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

//get audience growth stacked graph
const AudienceGrowthStack = (): any => {
	return axios
		.get(`${CAMPAIGN_AUDIENCEGROWTH_STACKEDGRAPH}}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

//delete master rule
const deleteMasterRole = async (masterruleId: any) => {
	return axios
		.delete(`${DELETE_Master_RULE}/${masterruleId}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: any) => response)
		.catch((e: any) => console.log(e, "api error"));
};

// Add Master Rule
const AddRule = async (docRequest: RULE | any) => {
	return axios
		.post(ADD_Master_RULE, docRequest, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: any) => response)
		.catch((e: any) => console.log(e, "api error"));
};
// const createBidding = (temp: any) => {
// 	return axios
// 		.post(ADD_BIDDING, temp, {
// 			headers: { Authorization: `Bearer ${auth?.token}` },
// 		})
// 		.then((response: any) => response.data.message)
// 		.catch((e: any) => console.log(e, "api error"));
// };
const createBidding = async (biddingData: any) => {
	return axios
		.post(ADD_BIDDING, biddingData, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: any) => response.data)
		.catch((e: any) => console.log(e, "api error"));
};

const campaignSettings = (temp: any) => {
	return axios
		.post(ADD_SETTINGS, temp, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((response: any) => response.data)
		.catch((e: any) => console.log(e, "api error"));
};
const moreSettings = (temp: any) => {
	return axios
		.post(MORE_SETTINGS, temp, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((response: any) => response.data)
		.catch((e: any) => console.log(e, "api error"));
};
const campaignKeywords = (temp: any) => {
	return axios
		.post(ADD_KEYWORDS, temp, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((response: any) => response.data)
		.catch((e: any) => console.log(e, "api error"));
};

const campaignBudget = (temp: any) => {
	return axios
		.post(ADD_BUDGET, temp, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((response: any) => response.data)
		.catch((e: any) => console.log(e, "api error"));
};

export {
	getUsers,
	deleteUser,
	deleteSelectedUsers,
	getUserById,
	createUser,
	updateUser,
	getUsersView,
	getUserRoles,
	AddResponse,
	getCategory,
	getOrganizations,
	AddSource,
	getSourceList,
	getResponse,
	addAnnotation,
	fetchAnnotationsList,
	deleteAnnotation,
	fetchAnnotationScreenDetails,
	deleteSource,
	deleteResponse,
	createOrganization,
	createCampaign,
	createCampaignType,
	getParticipantList,
	getOrganizationsList,
	createParticipant,
	deleteParticipant,
	deleteOrganizaton,
	editOrganization,
	editParticipant,
	addDomain,
	listDomain,
	editDomain,
	deleteDomain,
	editSource,
	editCampaign,
	editResponse,
	getParticipantOrganizationList,
	deleteParticapintOrganization,
	addParticapintOrganization,
	getAllUsersEmail,
	getDoc,
	getAllCampaigns,
	getAllListing,
	getAllSocialList,
	getDetailSocialList,
	getAllSocialData,
	gatCampaignDataById,
	ReportTotalLead,
	OrganicPaid,
	MaleFemale,
	AgeColumnGraph,
	AudienceGrowthStack,
	getCampaignHighlights,
	getExternal,
	getFeatured,
	getFeaturedName,
	tableList,
	barGraph,
	getNextSocialList,
	getBubbleData,
	getBubbleDetail,
	getTopListing,
	getColumnData,
	getSocialList,
	getFeaturedMedia,
	getMaleDonutData,
	getOrganicDonutData,
	getDetailHighlights,
	getAudienceGrowthData,
	getMasterRules,
	getAllSocialDetailData,
	deleteMasterRole,
	AddRule,
	createBidding,
	campaignSettings,
	moreSettings,
	campaignBudget,
	campaignKeywords
	
};
