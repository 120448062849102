/**
 * /* eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

import { Fragment, useEffect, useState } from "react";
import { KTCard, KTSVG } from "../../../../theme/helpers";
import { getCampaignHighlights } from "../../../modules/application/_requests";
import { useQuery } from "react-query";
import { useParams, useLocation } from "react-router-dom";
import { IoSparkles } from "react-icons/io5";
import { BsTag } from "react-icons/bs";
import CampaignType from "./CampaignType";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { IoMdLink } from "react-icons/io";
import CheckBox from "./CheckBox";

type Props = {
  className: string;
};

const Drive = ({ className }: Props) => {
  return (
    <>
      {/* <div className=""> */}
     
        <div
          className="container p-7 mt-10"
          style={{ backgroundColor: "white", boxShadow: '0 2px 8px rgba(0, 0, 0, 0.5)', borderRadius: "10px", height:'144px' }}
        >
          <div className="row gy-5 gx-xl-10 mb-5">
            <span
              className="text fs-6 pb-4"
              style={{ color: "#3c4043" }}
            >
              Drive conversions
            </span>

            <span className="text fs-6" style={{ color: "#5f6368",marginTop:"-2px" }}>
              Get more conversion with video ads designed to encourage valuable{" "}
              <br /> interations with your buisness.
              <a href="#"> Learn More. </a>
            </span>
          </div>
        </div>
     
    </>
  );
};
export default Drive;
