import React, { useEffect, useState } from "react";
import {
    AiOutlineFieldTime,
    AiOutlineProject,
    AiTwotoneCalendar,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'; // Assuming you use React Router
import { IoClose, IoLocationOutline, IoPeople } from "react-icons/io5";
import { FaBusinessTime, FaCalendarAlt, FaEdit, FaEye, FaRegUser } from "react-icons/fa";
import { BsCalendarPlus } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { RiArrowDropLeftLine, RiArrowDropRightLine, RiInformationLine } from "react-icons/ri";
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
import { IoMdArrowDropdown, IoMdDownload } from "react-icons/io";
import ModalDownload from "../../../assets/ModalDownload.png";
import { primaryColors } from "../../../../theme/assets/ts/_utils/AppColors";



function RuleDetails() {
    // const location = useLocation();
    // const data = location.state?.item;

    // console.log('params in incident detail', location.state);
    // console.log('item: ', data);

    const params = useLocation();
    const navigate = useNavigate();
    console.log("params in incident detail", params.state);
    const data = params?.state?.item
    console.log('item: ', data);

    interface Rule {
        "id": string;
        "name": string;
        "priority": string;
        "initiationType": string;
        "parameter": string;
        "metric": string;
        "conditions": string;
        "action": string;
        "triggerTime": string;
        "validFrom": string;
        "validTo": string;
        "createdBy": string;
        "createdDate": string;
        "updatedBy": string;
        "updatedDate": string;
        "status": string;
        "linkedCampaigns"?: string;
        "valueFrom": string;
        "valueTo": string;
        "email": string
    }


    const demoIncidentDetailFormData = {
        "selectedEquipment": "",
        "equipmentTag": "",
        "howIncidentOccured": "",
        "hazardIdentified": "",
        "selectedIncidentCause": "",
        "causeDescription": "",
        "selectedIncidentType": "",
        "preventativeAction": "",
        "peopleInvolved": [{
            "peopleName": "",
            "selectedGender": "",
            "selectedJobType": "",
            "employeeNumber": "",
            "activityAtIncidentTime": "",
            "incidentTestimony": "",
            "selectedInjuryType": "",
            "personalDamageDescription": ""
        }
        ]
    }

    const item = data;
    //   const [baseLineDropdown, setBaseLineDropdown] = useState(["custom"]);

    const demoRuleDetailData = {
        "id": "088d1ad5-9d2c-415d-b270-f84e709f055c",
        "name": "test",
        "priority": "high",
        "initiationType": "pooling",
        "parameter": "cpc",
        "metric": " ",
        "conditions": "<",
        "action": "no",
        "triggerTime": "2024-01-08T10:41:05.000+00:00",
        "validFrom": "2024-01-08T00:00:00.000+00:00",
        "validTo": "2024-01-08T00:00:00.000+00:00",
        "createdBy": "me",
        "createdDate": "2024-01-08T12:04:03.000+00:00",
        "updatedBy": "me",
        "updatedDate": "2024-01-08T12:04:03.000+00:00",
        "status": "ACTIVE",
        "linkedCampaigns": " ",
        "valueFrom": " ",
        "valueTo": " ",
        "email": " "
    }

    const usableData: any = [
        { 'campaignName': 'Camp1', 'startDate': '23-12-23', 'status': 'paused' },
        { 'campaignName': 'Camp2', 'startDate': '23-12-23', 'status': 'paused' },
        { 'campaignName': 'Camp3', 'startDate': '23-12-23', 'status': 'paused' },
        { 'campaignName': 'Camp4', 'startDate': '23-12-23', 'status': 'paused' },
        { 'campaignName': 'Camp5', 'startDate': '23-12-23', 'status': 'paused' },
        { 'campaignName': 'Camp1', 'startDate': '23-12-23', 'status': 'paused' },
        { 'campaignName': 'Camp1', 'startDate': '23-12-23', 'status': 'paused' },
    ];

    const tableHeaders = ['Sl No.', 'Campaign Name', 'Start Date', 'Status'];

    const [incidentDetailData, setIncidentDetailData] = useState<Rule>(demoRuleDetailData);
    const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const handleRowsPerPageChange = (value: number) => {
        setRowsPerPage(value);
        setCurrentPage(1);
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const formatDate = (dateString: string | undefined | null) => {
        try {
            if (!dateString) {
                return null;
            }

            const dateObject = new Date(dateString);

            if (isNaN(dateObject.getTime())) {
                throw new Error("Invalid date");
            }

            // Format date as yyyy-mm-dd
            const formattedDate = dateObject.toISOString().split('T')[0];
            console.log("Formatted Date:", formattedDate);
            return formattedDate;
        } catch (error) {
            console.error("Error formatting date:", (error as Error).message);
            return null; // Return null for invalid dates
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://apps.trisysit.com/rulesengine/rules/getrule/${data?.id}`);
                // const response = await fetch("http://10.10.1.45:8087/trisys/demo/api/v0/listincident");

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const allData = await response.json();
                // console.log('newdata:', allData);

                // Filtering data based on incident_id
                // const filteredData = allData.filter((item) => item?.id === data?.id);

                // Checking if there is at least one item in the filteredData array
                if (allData) {
                    // const firstItem = filteredData[0];
                    // const parsedIncidentDetailData = JSON.parse(firstItem?.incident_data);

                    setIncidentDetailData(allData);
                    console.log('newdata:', allData);

                } else {
                    // Handling the case where no matching incident is found
                    console.warn('No matching incident found');
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [data?.id]);

    console.log(incidentDetailData, 'ddata')


    const millisecondsToDate = (milliseconds: number): string => {
        const date = new Date(milliseconds);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const millisecondsToTime = (milliseconds: number): string => {
        const date = new Date(milliseconds);
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        return `${formattedHours}:${minutes} ${ampm}`;
    };

    const [refresh, setRefresh] = useState(false);

    const handleChildStateChange = (childState) => {
        // Handle the child state change and trigger a refresh if needed
        console.log('Child state changed:', childState);

        // If the child state is true, trigger a refresh
        if (childState) {
            // window.location.reload();
            setRefresh(true);
            // Scroll down after a delay (adjust the delay according to your needs)
            setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight);
            }, 1000); // 1000 milliseconds (1 second) delay
        }
    };

    const totalPages = Math.ceil(usableData.length / rowsPerPage);
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, usableData.length);
    const currentPageData = usableData.slice(startIndex, endIndex);

    const goToPreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const goToNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    { console.log(currentPageData[0]?.campaignName, 'useee') }

    const handleEditRule = (item) => {
        navigate(`/rule-create`, {
            state: { item },
        })
    }
    const padValue = (value) => {
        const maxLength = 30; // Adjust as needed
        const paddedValue = value?.toString()?.padEnd(maxLength);
        return paddedValue;
    }

    return (
        <>
            {console.log(currentPageData[0]?.campaignName, 'testuse')}

            <div className=''>

                {/* header row */}
                <div className="d-flex align-items-center justify-content-between mb-7" style={{ marginTop: '-27px', }}>
                    <div className="" style={{ color: primaryColors.black, fontWeight: 'bold', fontSize: '25px' }}>Rule Detail</div>
                    <div className="d-flex  justify-content-end ps-5">
                        <div className="d-flex align-items-center justify-content-center"
                            style={{
                                width: '100px',
                                height: '40px',
                                padding: '8px',
                                border: `2px solid ${primaryColors.red}`,
                                borderRadius: '10px',
                                fontSize: '18px',
                                cursor: 'pointer',
                                outline: 'none',
                                fontWeight: 'bold',
                                color: 'white',
                                backgroundColor: primaryColors.red,
                            }}
                            onClick={() => handleEditRule(incidentDetailData)}>
                            EDIT
                        </div>
                    </div>

                </div>

                {/* rule box */}

                <div
                    className='mb-10 p-3'
                    style={{
                        backgroundColor: '#fff',
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
                        // border: `2px solid ${primaryColors.white}`,
                        // overflow: 'hidden',
                        borderRadius: 5,
                        // padding: 10,
                    }}
                >
                    <div className="py-3 px-5">
                        <div className='mb-4' style={{ fontSize: 22, fontWeight: 'bold', color: 'black' }}>
                            {incidentDetailData?.name}
                        </div>

                        <div className="Rule" style={{ fontSize: '16px', }}>
                            <div className="Rule" style={{ fontSize: '16px', fontFamily: '' }}>
                                <div className="mb-4">
                                    <div className="row gx-4 align-items-center gap-15">
                                        <div className="col-md-2 mb-3" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div className="d-flex align-items-center">
                                                <div style={{ fontWeight: 'bold' }}>Status</div>
                                                <div className="mx-15">:</div>
                                                <div>{padValue(incidentDetailData?.status)}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 mb-3" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div className="d-flex align-items-center">
                                                <div style={{ fontWeight: 'bold' }}>Valid From</div>
                                                <div className="mx-15">:</div>
                                                <div className="ms-3">{padValue(formatDate(incidentDetailData?.validFrom))}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 mb-3" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div className="d-flex align-items-center">
                                                <div style={{ fontWeight: 'bold' }}>Valid To</div>
                                                <div className="mx-15">:</div>
                                                <div>{padValue(formatDate(incidentDetailData?.validTo))}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 mb-3" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div className="d-flex align-items-center">
                                                <div style={{ fontWeight: 'bold' }}>Priority</div>
                                                <div className="mx-16">:</div>
                                                <div>{padValue(incidentDetailData?.priority)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr className="horizontal-divider" style={{ border: 'none', borderTop: '1px solid #ccc', width: '100%' }} />

                                <div className="mb-4">
                                    <div className="row gx-4 align-items-center gap-15">
                                        <div className="col-md-2 mb-3" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div className="d-flex align-items-center">
                                                <div style={{ fontWeight: 'bold' }}>Metrics</div>
                                                <div className="mx-13">:</div>
                                                <div className="">{padValue(incidentDetailData?.metric)}</div>
                                            </div>


                                        </div>
                                        <div className="col-md-3 mb-3" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <div className="d-flex align-items-center">
                                            <div style={{ fontWeight: 'bold' }}>Operation</div>
                                            <div className="mx-16">:</div>
                                            <div>{padValue(incidentDetailData?.conditions === '>' ? 'Greater than' : incidentDetailData?.conditions === '<' ? 'Less than' : 'Between')}</div>
                                        </div>
                                        </div>
                                        <div className="col-md-3 mb-3" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <div className="d-flex align-items-center">
                                            <div style={{ fontWeight: 'bold' }}>Value</div>
                                            <div className="mx-20">:</div>
                                            <div>{padValue(`${incidentDetailData?.valueFrom} - ${incidentDetailData?.valueTo}`)}</div>
                                        </div>
                                        </div>
                                        <div className="col-md-2 mb-3"></div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>





                </div>

                {/* action box */}

                <div
                    className='mb-10 p-3'
                    style={{
                        backgroundColor: '#fff',
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
                        // border: `2px solid ${primaryColors.white}`,
                        // overflow: 'hidden',
                        borderRadius: 5,
                        // padding: 10,
                    }}
                >
                    <div className="py-3 px-5">

                        <div className='mb-7' style={{
                            fontSize: 22,
                            fontWeight: 'bold',
                            color: 'black',
                        }} >
                            {/* {incidentDetailData?.incidentName} */}
                            Action
                        </div>


                        <div className="Rule" style={{ fontSize: '16px' }}>
                            <div className="row gx-4 align-items-center gap-5 ">
                                <div className="col-md-3 mb-1" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div className="d-flex align-items-center">
                                    <div style={{ fontWeight: 'bold' }}>Action Type</div>
                                    <div className="mx-20">:</div>
                                    <div>{padValue(incidentDetailData?.action)}</div>
                                </div>
                                </div>
                                <div className="col-md-3 mb-1" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div className="d-flex align-items-center">
                                    <div style={{ fontWeight: 'bold' }}>Email</div>
                                    <div className="mx-18">:</div>
                                    <div>{padValue(incidentDetailData?.email)}</div>
                                    </div>
                                </div>
                                <div className="col-md-3 mb-1" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    {/* <div style={{ fontWeight: 'bold' }}>Valid To</div>:
                                            <div>{padValue(formatDate(incidentDetailData?.validTo))}</div> */}
                                </div>
                                <div className="col-md-3 mb-1" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    {/* <div style={{ fontWeight: 'bold' }}>Valid To</div>:
                                            <div>{padValue(formatDate(incidentDetailData?.validTo))}</div> */}
                                </div>

                            </div>
                            <hr className="horizontal-divider" style={{ border: 'none', borderTop: '1px solid #ccc', width: '100%' }} />

                            <div className="mb-2">
                                <div className="row gx-4 align-items-center gap-5">
                                    <div className="col-md-4 " style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <div className="d-flex align-items-center">
                                        <div style={{ fontWeight: 'bold' }}>Email Structure</div>
                                        <div className="mx-12">:</div>
                                        <div className="">{(incidentDetailData?.email)}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-3" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                    </div>
                                    <div className="col-md-3 " style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                    </div>
                                    <div className="col-md-3" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                    </div>

                                </div>
                            </div>


                        </div>



                    </div>



                </div>

                {/* campaigns box */}

                <div
                    className='mb-10'
                    style={{
                        marginTop: 10,
                        backgroundColor: '#fff',
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
                        // overflow: 'hidden',
                        borderRadius: 5,
                        // padding: 10,
                    }}>
                    <div className="p-5 mb-5 pb-5 pt-5 px-5" style={{ borderRadius: 15 }}>

                        <div className='mb-7' style={{
                            fontSize: 22,
                            fontWeight: 'bold',
                            color: 'black',
                        }} >
                            {/* {incidentDetailData?.incidentName} */}
                            Campaigns
                        </div>
                        <table style={{
                            width: '100%', borderCollapse: 'collapse', borderRadius: 15,
                        }}>
                            <thead style={{
                                border: `1.5px solid ${primaryColors.red}`,
                            }}>
                                <tr style={{
                                    border: `1.5px solid ${primaryColors.red}`, height: '40px',
                                }}>
                                    {tableHeaders?.map((header, index) => (
                                        <th key={index} style={{
                                            border: '1.5px solid lightgrey', height: '40px', textAlign: 'center',
                                        }}>
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {/* {console.log(currentPageData[0]?.campaignName, 'useee')} */}
                                {currentPageData?.map((item, index) => (
                                    <tr key={index} style={{ border: '1.5px solid lightgrey', height: '40px' }}>
                                        <td style={{ border: '1.5px solid lightgrey', textAlign: 'center', maxWidth: '10px' }}>{index + 1}</td>
                                        <td style={{ border: '1.5px solid lightgrey', textAlign: 'center', maxWidth: '100px' }}>{item?.campaignName}</td>
                                        <td style={{ border: '1.5px solid lightgrey', textAlign: 'center', maxWidth: '50px' }}>{item?.startDate}</td>
                                        <td style={{ border: '1.5px solid lightgrey', textAlign: 'center', maxWidth: '50px' }}>{item?.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* table footer */}

                        <div className="d-flex flex-end align-items-center mt-5">

                            {/* Rows per page dropdown */}
                            <div className="me-10 d-flex align-items-center" style={{ position: 'relative' }}>
                                <select
                                    className="fw-bold"
                                    value={rowsPerPage}
                                    onChange={(e) => handleRowsPerPageChange(parseInt(e.target.value))}
                                    style={{ border: `0px solid ${primaryColors.red}`, color: 'grey', outline: 'none', paddingRight: '30px' }}>
                                    <option value={5}>Show 5</option>
                                    <option value={6}>Show 6</option>
                                    <option value={7}>Show 7</option>
                                </select>
                                <div style={{ position: 'absolute', top: '50%', right: '5px', transform: 'translateY(-50%)' }}>
                                    <IoMdArrowDropdown size={25} style={{ color: 'grey' }} />
                                </div>
                            </div>

                            {/* Pagination buttons */}
                            <div>
                                <button className="fw-bold" onClick={goToPreviousPage} disabled={currentPage === 1}
                                    style={{
                                        backgroundColor: primaryColors.white,
                                        border: `1px solid ${currentPage !== 1 ? primaryColors.red : primaryColors.grey}`,
                                        color: currentPage !== 1 ? primaryColors.red : primaryColors.grey,
                                        width: '80px',
                                        height: '30px',
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5
                                    }}>
                                    Previous
                                </button>
                                {pageNumbers.map((page) => (
                                    <button className="fw-bold" key={page} onClick={() => handlePageChange(page)}
                                        style={{
                                            border: `1px solid ${currentPage === page ? primaryColors.red : primaryColors.grey}`,
                                            backgroundColor: primaryColors.white,
                                            color: currentPage === page ? primaryColors.red : primaryColors.grey,
                                            height: '30px',
                                            width: '30px',
                                        }}>
                                        {page}
                                    </button>
                                ))}
                                <button className="fw-bold" onClick={goToNextPage} disabled={currentPage === totalPages}
                                    style={{
                                        backgroundColor: primaryColors.white,
                                        border: `1px solid ${currentPage !== totalPages ? primaryColors.red : primaryColors.grey}`,
                                        color: currentPage !== totalPages ? primaryColors.red : primaryColors.grey,
                                        width: '80px',
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5,
                                        height: '30px',
                                    }}>
                                    Next
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

            </div >
        </>
    );
};


export default RuleDetails;
