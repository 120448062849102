import React, { useState } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

const CustomQuestion = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [iconColor, setIconColor] = useState('#5f6368');
  const [modelHover, setModelHover] = useState(false);
  const [iconHover, seticonHover] = useState(false);



  const handleModalHover = () => {
    setIconColor('#0d6efd');
    setModalOpen(true);
    seticonHover(true);
  };
  const handleModalLeave = () => {
    setIconColor('#5f6368');
    setModalOpen(false);
    seticonHover(true);
  };


  const handleIconHover = () => {
    setIconColor('#0d6efd');
    setModalOpen(true);
  };

  const handleIconLeave = () => {
    setIconColor('#5f6368');
    // if (modelHover ==false && modalOpen==false )
    // setTimeout(() => {
    setModalOpen(false);
    //   }, 1000);

  };

  const iconContainerStyles: React.CSSProperties = {

    position: 'sticky',
    display: 'inline-block',
    fontSize: '13px',
    lineHeight: '20px',
    margin: '0, 20px',
    zIndex: 9,

  };

  const iconStyles: React.CSSProperties = {
    color: iconColor,
    fontSize: '24px',
    cursor: 'pointer',
    transition: 'color 0.3s ease',
  };

  const modalStyles: React.CSSProperties = {
    position: 'absolute',
    top: '20px', // Adjust the vertical position as needed
    left: '0',
    minWidth: '350px',
    height: 'auto',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    display: modalOpen ? 'block' : 'none',
    textAlign: 'left', // Center the text
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    color: 'black',
    // transform: 'translate(-50%, -50%)',
    zIndex: 999999999,
    transition: 'opacity 0.5s ease', // Increased transition time to 0.5s
    opacity: modalOpen ? 1 : 0,

  };

  return (
    <div style={iconContainerStyles}>
      <AiOutlineQuestionCircle
        style={iconStyles}
        onMouseEnter={handleIconHover}
        onMouseLeave={handleIconLeave}

        size={18}
      />

      {modalOpen && (
        <div style={modalStyles}
          onMouseEnter={handleModalHover}
          onMouseLeave={handleModalLeave}>
          {children}
        </div>
      )}
    </div>
  );
};

export default CustomQuestion;
