import { useEffect, useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { KTCard } from "../../../../theme/helpers";
import { AiOutlineQuestionCircle, AiOutlineSearch } from "react-icons/ai";
import { BsCurrencyRupee } from "react-icons/bs";
import { BsExclamationCircle } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import search from "../../../../assets/search.png";
import display from "../../../../assets/display.png";

import { FaTimes } from "react-icons/fa";
import { Badge } from "react-bootstrap";
import AudienceSegment from "./AudienceSegment";
import MoreSettings from "./MoreSettings";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import CustomQuestion from "./customQuestion";
import { campaignSettings } from "../../../modules/application/_requests";
import Location from "./Location";

const Languages = ({selectedLanguage, setSelectedLanguage,languages,selectedLanguages,setSelectedLanguages}) => {
  const [isExpanded, setIsExpanded] = useState(false);
 
  const [isChecked2, setIsChecked2] = useState(true);

  const [showInputBox, setShowInputBox] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
//   const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
//   const [selectedLanguage, setSelectedLanguage] = useState("");
  const [hoveredLanguage, setHoveredLanguage] = useState("");
  
  const [headerColor4, setHeaderColor4] = useState("");
 
   
  const navigate = useNavigate();
  const location = useLocation();
  const idd: any = location.state;

  console.log(idd, "iddddddddddd");
  

  

  ;
  const handleCheckboxChange2 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setSelectedLanguage(event.target.value);
    if (value === "All Languages") {
      if (event.target.checked) {
        // Select all languages
        const allLanguages = languages.map((language) => language.name);
        setSelectedLanguages(allLanguages);
      } else {
        // Deselect all languages
        setSelectedLanguages([]);
      }
    } else {
      if (event.target.checked) {
        setSelectedLanguages((prevSelectedLanguages) => [
          ...prevSelectedLanguages,
          value,
        ]);
      } else {
        setSelectedLanguages((prevSelectedLanguages) =>
          prevSelectedLanguages.filter((language) => language !== value)
        );
      }
    }
  };

  // const handleClick = () => {
  //   // setShowCampaignType(true);
  //   navigate("/keywords");
  // };
  const handleTogglePopup = () => {
    setShowPopup(!showPopup);
  };

  
 
  const handleMouseEnter = (language) => {
    setHoveredLanguage(language);
  };

  const handleMouseLeave = () => {
    setHoveredLanguage("");
  };

  const handleToggleContent = () => {
    setIsExpanded(!isExpanded);
  };

 
  const handleCheckbox2 = () => {
    setIsChecked2(!isChecked2);
  };

  const [selectedNames, setSelectedNames] = useState<string[]>([]);



  const handleRemoveLanguage = (language: string) => {
    setSelectedLanguages((prevSelectedLanguages) =>
      prevSelectedLanguages.filter((item) => item !== language)
    );
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredLanguages, setFilteredLanguages] = useState(languages);


  const handleInputChange = (e) => {
    const query = e?.target?.value?.toLowerCase();
    setSearchQuery(query);

    // Filter languages based on the search query
    const filtered = languages.filter((language) =>
      language?.name?.toLowerCase().includes(query)
    );
    setFilteredLanguages(filtered);
  };

 
  return (
    <>   
            <div
            className="card"
            style={{
              marginTop: isExpanded ? "20px" : "0px",
              marginBottom: isExpanded? "20px" : "0px",
              backgroundColor: isExpanded ? "" : "#E1E2E3",
              boxShadow: isExpanded ? "0 2px 8px rgba(0, 0, 0, 0.5)" : "",
            }}
          >
            <div
              className="card-header d-flex align-items-center"
              onClick={handleToggleContent}
              onMouseOver={() => setHeaderColor4("#E1E2E3")}
              onMouseOut={() => setHeaderColor4("")}
              style={{
                backgroundColor: headerColor4 || "#FFFFFF", // Set default color to white
                cursor: "pointer",
                border: "1px solid #ced4da",
                transition: "background-color 0.3s ease",
                minHeight: "48px",
                borderRadius: "5px",
              }}
            >
              <div
            className="row gx-xl-12 gy-5 d-flex align-items-center"
            style={{ width: "100%" }}
          >
            <div className="col-xl-3">
              <h5 className="card-title">Languages</h5>
              </div>
              <div className="col-xl-8">
              {!isExpanded ? (
                <h5
                  className="card-title"
                  style={{ color: "rgba(0,0,0,.54)"}}
                >
                  {selectedLanguages.length > 0 ? (
                    <>
                      {selectedLanguages.slice(0, 2).map((selectedLanguage) => (
                        <span
                          className="selected-language"
                          key={selectedLanguage}
                          style={{
                            marginRight: "5px",
                            fontSize: "14px",
                          }}
                        >
                          {selectedLanguage},
                        </span>
                      ))}
                      {selectedLanguages.length > 2 && (
                        <span className="selected-language">
                          and {selectedLanguages.length - 2} more
                        </span>
                      )}
                    </>
                  ) : (
                    "All languages"
                  )}
                </h5>
              ) : null}
              </div>
              <div className="col-xl-1 d-flex align-items-center justify-content-end">
              {isExpanded ? (
                <BsChevronUp className="arrow-icon" />
              ) : (
                <BsChevronDown className="arrow-icon" />
              )}
              </div>
              </div>
             
            </div>
            {isExpanded && (
              <div className="card-body">
                <div className="row px-20">
                  <div className="col xl-8">
                    <div className="">
                      <KTCard className="card h-lg-100 col-xxl-12">
                        <div
                          className="container p-1"
                          style={{
                            backgroundColor: "white",
                            borderRadius: "10px",
                          }}
                        >
                          <div
                            className="row gy-5 gx-xl-10"
                            style={{ marginLeft: "-86px" }}
                          >
                            <div
                              className=" d-flex text-dark"
                              style={{ marginLeft: "0px", fontSize: "14px" }}
                            >
                              <p style={{ marginRight: "5px" }}>
                                {" "}
                                Select the languages your customers speak{" "}
                              </p>

                              <CustomQuestion>
                                <p>
                                  Language targeting allows you to restrict
                                  where your ads can appear based on the user's
                                  language settings and the language of the
                                  site. Ads can be shown to people who
                                  understand any, but not necessarily all of the
                                  languages you select.
                                </p>
                                <u style={{ color: "#009EF7" }}>
                                  <a href="#"> Learn more</a>
                                </u>
                              </CustomQuestion>
                            </div>
                            <br />
                            <div style={{ position: "relative" }}>
                              <input
                                type="text"
                                placeholder="Start typing or select a language"
                                onChange={handleInputChange}
                                style={{
                                  padding: "10px",
                                  paddingLeft: "40px",
                                  width: "400px",
                                  height: "50px",
                                  borderRadius: "5px",
                                  border: "1px solid #ced4da", // Set initial border to transparent
                                  borderColor: "#000", // Set initial border color
                                  borderWidth: "1px", // Set initial border width
                                  color: "#80868b",
                                  outline: "none", // Remove default outline style
                                  transition: "border-color 0.3s ease-in-out", // Add transition effect
                                }}
                                onClick={handleTogglePopup}
                                onFocus={(e) => {
                                  e.target.style.borderColor = "#0d6efd"; // Change border color on focus
                                  e.target.style.borderWidth = "2px"; // Change border width on focus
                                }}
                                onBlur={(e) => {
                                  e.target.style.borderColor = "#ced4da"; // Reset border color on blur
                                  e.target.style.borderWidth = "1px"; // Reset border width on blur
                                }}
                              />
                              <div
                                className="px-4"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "10px",
                                  transform: "translateY(-50%)",
                                  color: "#888888",
                                  pointerEvents: "none", // Ignore pointer events to allow clicking the input
                                }}
                              >
                                <AiOutlineSearch size={25} />
                              </div>
                            </div>

                            {showPopup && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "77px",
                                  backgroundColor: "white",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  border: "1px solid #ced4da",
                                  width: "400px",
                                  height: "300px", // Adjust the height as needed
                                  overflow: "auto", // Add scrollbar when content overflows
                                  marginLeft: "15px",
                                  zIndex: 2,
                                }}
                              >
                                {/* {languages.map((language) => (
                                  <div
                                    key={language.id}
                                    style={{
                                      padding: "20px",
                                      fontSize: "14px",
                                      backgroundColor:
                                        language.name === selectedLanguage
                                          ? "#E8E9E9"
                                          : language.name === hoveredLanguage
                                          ? "#F7F8F8"
                                          : "transparent",
                                    }}
                                    onMouseEnter={() =>
                                      handleMouseEnter(language.name)
                                    }
                                    onMouseLeave={handleMouseLeave}
                                  >
                                    <span style={{ display: "flex" }}>
                                      <input
                                        type="checkbox"
                                        value={language.name}
                                        onChange={handleCheckboxChange2}
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                          borderWidth: "3px",
                                          marginRight: "10px",
                                        }}
                                        checked={selectedLanguages.includes(
                                          language.name
                                        )}
                                      />
                                      {language.name}
                                    </span>
                                  </div>
                                ))} */}
                                {filteredLanguages.length > 0 ? (
                              filteredLanguages.map((language) => (
                                <div
                                  key={language.id}
                                  style={{
                                    padding: "20px",
                                    fontSize: "14px",
                                    backgroundColor:
                                      language.name === selectedLanguage
                                        ? "#E8E9E9"
                                        : language.name === hoveredLanguage
                                        ? "#F7F8F8"
                                        : "transparent",
                                  }}
                                  onMouseEnter={() =>
                                    handleMouseEnter(language.name)
                                  }
                                  onMouseLeave={handleMouseLeave}
                                >
                                  <span style={{ display: "flex" }}>
                                    <input
                                      type="checkbox"
                                      value={language.name}
                                      onChange={handleCheckboxChange2}
                                      style={{
                                        width: "10px",
                                        height: "10px",
                                        borderWidth: "3px",
                                        marginRight: "10px",
                                        transform: "scale(2)",
                                      }}
                                      checked={selectedLanguages.includes(
                                        language.name
                                      )}
                                    />
                                    {language.name}
                                  </span>
                                </div>
                              ))
                            ) : (
                              <div>No matching languages found.</div>
                            )}
                              </div>
                            )}
                            {selectedLanguages.length > 0 ? (
                              <div
                                style={{ marginTop: "10px", fontSize: "14px" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    fontSize: "14px",
                                  }}
                                >
                                  {selectedLanguages.length ===
                                  languages.length ? (
                                    <div
                                      className="badge badge-light"
                                      // key="All Languages"
                                      style={{
                                        fontSize: "14px",
                                        display: "flex",
                                        alignItems: "center",
                                        paddingRight: "5px",
                                        backgroundColor: "white",
                                        margin: "12px",
                                        padding: "10px 10px",
                                        borderRadius: "30px",
                                        borderWidth: "1px",
                                        borderStyle: "solid",
                                        borderColor: "#E4E3E3",
                                        maxWidth: "500px",
                                      }}
                                      onMouseEnter={(e) => {
                                        (
                                          e.target as HTMLButtonElement
                                        ).style.backgroundColor = "#F5F5F5";
                                      }}
                                      onMouseLeave={(e) => {
                                        (
                                          e.target as HTMLButtonElement
                                        ).style.backgroundColor = "white"; // Restore the initial background color
                                      }}
                                    >
                                      All Languages
                                      <FaTimes
                                        style={{
                                          marginLeft: "5px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleRemoveLanguage("All Languages")
                                        }
                                      />
                                    </div>
                                  ) : (
                                    selectedLanguages.map(
                                      (selectedLanguage) => (
                                        <span
                                          className="badge badge-light"
                                          key={selectedLanguage}
                                          style={{
                                            // marginRight: "5px",
                                            // marginBottom: "5px",
                                            fontSize: "14px",
                                            display: "flex",
                                            alignItems: "center",

                                            backgroundColor: "white",
                                            margin: "12px",
                                            padding: "10px 10px",
                                            borderRadius: "30px",
                                            borderWidth: "1px",
                                            borderStyle: "solid",
                                            borderColor: "#E4E3E3",
                                            maxWidth: "500px",
                                          }}
                                          onMouseEnter={(e) => {
                                            (
                                              e.target as HTMLButtonElement
                                            ).style.backgroundColor = "#F5F5F5";
                                          }}
                                          onMouseLeave={(e) => {
                                            (
                                              e.target as HTMLButtonElement
                                            ).style.backgroundColor = "white"; // Restore the initial background color
                                          }}
                                        >
                                          {selectedLanguage}
                                          <FaTimes
                                            style={{
                                              marginLeft: "5px",
                                              marginRight: "5px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleRemoveLanguage(
                                                selectedLanguage
                                              )
                                            }
                                          />
                                        </span>
                                      )
                                    )
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div
                                className="badge badge-light"
                                key="All Languages"
                                style={{
                                  // marginRight: "5px",
                                  // marginBottom: "5px",
                                  fontSize: "14px",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  alignItems: "center",
                                  width: "120px",

                                  backgroundColor: "white",
                                  margin: "12px",
                                  padding: "10px 10px",
                                  borderRadius: "30px",
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#E4E3E3",
                                  maxWidth: "500px",
                                }}
                                onMouseEnter={(e) => {
                                  (
                                    e.target as HTMLButtonElement
                                  ).style.backgroundColor = "#F5F5F5";
                                }}
                                onMouseLeave={(e) => {
                                  (
                                    e.target as HTMLButtonElement
                                  ).style.backgroundColor = "white"; // Restore the initial background color
                                }}
                              >
                                All Languages
                              </div>
                            )}
                          </div>
                        </div>
                      </KTCard>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

           </>
  );
};

export default Languages;
