import { useState } from "react";
import { BsChevronDown, BsChevronRight, BsChevronUp } from "react-icons/bs";
import { KTCard } from "../../../../theme/helpers";
import { AiOutlineQuestionCircle, AiOutlineSearch } from "react-icons/ai";
import { BsClock } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FiArrowLeft } from "react-icons/fi";

import { useNavigate } from "react-router-dom";
import CustomQuestion from "./customQuestion";

const Location = ({
  selectedOption,
  selectedTarget,
  setSelectedOption,
  setSelectedTarget,
  territory,
  target,
  inputValue,
  setInputValue,
}) => {
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(false);

  const [headerColor2, setHeaderColor2] = useState("");
  const [showInputBox, setShowInputBox] = useState(false);
  // const [inputValue, setInputValue] = useState("");

  const navigate = useNavigate();
  console.log(inputValue, "inputttttttttt");

  const handleToggleContent2 = () => {
    setIsExpanded2(!isExpanded2);
  };
  const handleToggleContent3 = () => {
    setIsExpanded3(!isExpanded3);
  };
  const handleTargetChange = (event) => {
    setSelectedTarget(event.target.value);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "Enter another location") {
      setShowInputBox(true);
    } else {
      setShowInputBox(false);
    }
  };
  console.log(selectedOption, "bbbbbbbbbbbbjjjjjjjjj");

  return (
    <>
      <div
        className="card"
        style={{
          marginTop: isExpanded2 ? "20px" : "0px",
          marginBottom: isExpanded2 ? "20px" : "0px",
          backgroundColor: isExpanded2 ? "" : "#E1E2E3",
          boxShadow: isExpanded2 ? "0 2px 8px rgba(0, 0, 0, 0.5)" : "",
        }}
      >
        <div
          className="card-header d-flex align-items-center"
          onClick={handleToggleContent2}
          onMouseOver={() => setHeaderColor2("#E1E2E3")}
          onMouseOut={() => setHeaderColor2("")}
          style={{
            backgroundColor: headerColor2 || "#FFFFFF", // Set default color to white
            cursor: "pointer",
            border: "1px solid #ced4da",
            transition: "background-color 0.3s ease",
            minHeight: "48px",
            borderRadius: "5px",
          }}
        >
          <div
            className="row gx-xl-12 gy-5 d-flex align-items-center"
            style={{ width: "100%" }}
          >
            <div className="col-xl-3">
              <h5 className="card-title">Location</h5>
            </div>
            {/* {selectedOption || "Location"} */}
            <div className="col-xl-8">
              {!isExpanded2 ? (
                <h5 className="card-title" style={{ color: "rgba(0,0,0,.54)" }}>
                  {/* {selectedOption} */}
                  {selectedOption === "India"
                    ? "India (Country)"
                    : selectedOption === "Enter another location"
                    ? inputValue
                    : selectedOption || ""}
                </h5>
              ) : null}
            </div>
            <div className="col-xl-1 d-flex align-items-center justify-content-end">
              {isExpanded2 ? (
                <BsChevronUp className="arrow-icon" />
              ) : (
                <BsChevronDown className="arrow-icon" />
              )}
            </div>
          </div>
        </div>
        {isExpanded2 && (
          <div className="card-body">
            <div className="row px-20">
              <div className="col xl-8">
                <div className="">
                  <KTCard className="card h-lg-100 col-xxl-12">
                    <div
                      className="container p-1"
                      style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        className="row gy-5 gx-xl-10"
                        style={{ marginLeft: "-86px" }}
                      >
                        <div className="d-flex align-items-center">
                          <span
                            className="fw text-dark fs-6"
                            style={{ marginLeft: "0px" }}
                          >
                            Select location to Target{" "}
                            <CustomQuestion>
                              Locations help you target your ads to people
                              located in, regularly in, or who’ve shown interest
                              in, a geographic location. For example, a Parisian
                              bakery owner can target people located or
                              regularly in Paris, or people who have expressed
                              interest in Paris (now or in the past). Or, an
                              online business owner who ships to Canada and the
                              U.S. can target both countries. To show ads to
                              users who meet location settings, a variety of
                              signals is used. Variation in these signals means
                              100% accuracy is not always guaranteed. To ensure
                              optimal performance, check your metrics and adjust
                              settings to meet your advertising goals.
                              <p>
                                <u style={{ color: "#009EF7" }}>
                                  <a href="#"> Learn more</a>
                                </u>
                              </p>
                            </CustomQuestion>
                          </span>
                        </div>
                        {/* <div style={{ marginLeft: "30px", marginTop: "20px" }}> */}
                        {territory?.map((item) => (
                          <div className="d-flex align-items-center">
                            <label
                              key={item.id}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "10px",
                              }}
                            >
                              <input
                                type="radio"
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  fontWeight: "bold",
                                  marginRight: "5px",
                                }}
                                name="d"
                                value={item.tname}
                                checked={selectedOption === item.tname}
                                onClick={handleOptionChange}
                              />
                              <span style={{ marginLeft: "5px" }}>
                                {item.tname}
                              </span>
                            </label>
                          </div>
                        ))}

                        <div>
                          {showInputBox && (
                            <div>
                              <div className="d-flex">
                                <div
                                  style={{
                                    position: "relative",
                                    marginRight: "30px",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <input
                                    placeholder="Enter a location to target or exclude"
                                    style={{
                                      padding: "10px",
                                      paddingLeft: "55px",
                                      width: "410px",
                                      height: "50px",

                                      borderRadius: "5px",
                                      border: "1px solid #ced4da", // Set initial border to transparent
                                      borderColor: "#ced4da", // Set initial border color
                                      borderWidth: "1px", // Set initial border width
                                      color: "#80868b",
                                      outline: "none", // Remove default outline style
                                      transition:
                                        "border-color 0.3s ease-in-out", // Add transition effect
                                    }}
                                    onChange={(e) =>
                                      setInputValue(e.target.value)
                                    }
                                    onFocus={(e) => {
                                      e.target.style.borderColor = "#0d6efd"; // Change border color on focus
                                      e.target.style.borderWidth = "2px"; // Change border width on focus
                                    }}
                                    onBlur={(e) => {
                                      e.target.style.borderColor = "#ced4da"; // Reset border color on blur
                                      e.target.style.borderWidth = "1px"; // Reset border width on blur
                                    }}
                                  />

                                  <div
                                    className="px-4"
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "10px",
                                      transform: "translateY(-50%)",
                                      color: "#888888",
                                      pointerEvents: "none", // Ignore pointer events to allow clicking the input
                                    }}
                                  >
                                    <AiOutlineSearch size={25} />
                                  </div>
                                </div>
                                <div className="ml-20">
                                  <button className="btn btn-light text-primary">
                                    Advanced Search
                                  </button>
                                </div>
                              </div>
                              <div
                                className="mt-2 text-grey"
                                style={{ marginLeft: "45px" }}
                              >
                                For example, a country, city, region or postal
                                code
                              </div>
                            </div>
                          )}
                        </div>

                        <button
                          className="btn btn text-primary"
                          style={{ display: "flex", alignItems: "center" }}
                          onClick={handleToggleContent3}
                        >
                          {isExpanded3 ? (
                            <BsChevronUp className="arrow-icon" style={{}} />
                          ) : (
                            <BsChevronDown
                              className="arrow-icon"
                              style={{ fontSize: "20px" }}
                            />
                          )}
                          <span style={{ marginLeft: "5px" }}>
                            Location Options
                          </span>
                        </button>

                        {isExpanded3 && (
                          <div
                            className="row gy-5 gx-xl-10"
                            style={{ marginLeft: "-10px" }}
                          >
                            <div className="d-flex align-items-center">
                              <span
                                className="fw text-dark fs-"
                                style={{ marginLeft: "0px" }}
                              >
                                Target{" "}
                                <CustomQuestion>
                                  By default, your ads can show to people in,
                                  regularly in, or who've shown interest in your
                                  targeted locations. For example, if you own a
                                  bakery in Paris and choose Paris as a targeted
                                  location, your ads can show to people located
                                  or regularly in Paris, or to people who have
                                  expressed interest in Paris bakeries (now or
                                  in the past). Alternatively, you can use other
                                  location options to limit the location types
                                  you target.
                                  <p>
                                    <u style={{ color: "#009EF7" }}>
                                      <a href="#"> Learn more</a>
                                    </u>
                                  </p>
                                </CustomQuestion>
                              </span>
                            </div>
                            {target?.map((item) => (
                              <label
                                key={item.id}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "10px",
                                }}
                              >
                                <input
                                  type="radio"
                                  name="target"
                                  value={item.tarname}
                                  checked={selectedTarget === item.tarname}
                                  onChange={handleTargetChange}
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    fontWeight: "bold",
                                    marginRight: "5px",
                                  }}
                                />
                                <span style={{ marginLeft: "5px" }}>
                                  {item.tarname}
                                </span>
                              </label>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </KTCard>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Location;
