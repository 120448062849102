/**
 * /* eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

import { FC, useContext, useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import {
	createResponseContext,
	initialQueryResponse,
	initialQueryState,
	PaginationState,
	QUERIES,
	stringifyRequestQuery,
	WithChildren,
} from "../../../../../../theme/helpers";
import {
	getResponse,
	getUserById,
	getUsers,
	getUsersView,
	getSourceList,
	getOrganizations,
	getParticipantList,
	getOrganizationsList,

	addDomain,
	listDomain,
	editDomain,
	deleteDomain,
	editSource,
	getParticipantOrganizationList,

} from "../../../../application/_requests";
import { Responses, User, Source } from "./_models";
import { useQueryRequest } from "./QueryRequestProvider";
import { userView } from "../../../../auth/core/_requests";
import { useStorage } from "../../../../../hooks/useStorage";
import { useParams } from "react-router-dom";

//Initializing reponse-list query
const QueryResponseContext =
	createResponseContext<Responses>(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
	const { state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
	const { id } = useParams();

	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);

	const {
		isLoading,
		isFetching,
		refetch,
		data: response,
	} = useQuery(
		[`response-list-${query}`],
		() => {
			return getResponse(id, query);
		}
		// { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: true }
	);

	return (
		<QueryResponseContext.Provider
			value={{ isLoading, isFetching, refetch, response, query }}
		>
			{children}
		</QueryResponseContext.Provider>
	);
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
	const { response } = useQueryResponse();
	if (!response) {
		return [];
	}
	return response.data || [];
};

const useQueryResponsePagination = () => {
	const defaultPaginationState: PaginationState = {
		links: [],
		...initialQueryState,
	};

	const { response } = useQueryResponse();

	if (!response || !response.payload || !response.payload.pagination) {
		return defaultPaginationState;
	}
	return response.payload.pagination;
};

const useQueryResponseLoading = (): boolean => {
	const { isLoading } = useQueryResponse();
	return isLoading;
};

//Initializing User's Table query
const UserQueryResponseContext =
	createResponseContext<User>(initialQueryResponse);
const UserQueryResponseProvider: FC<WithChildren> = ({ children }) => {
	const { state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);

	const {
		isLoading,
		isFetching,
		refetch,
		data: response,
	} = useQuery(
		[`USER_LIST_VIEW-${query}`],
		() => {
			return getUsersView(query);
		},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
	);
	return (
		<UserQueryResponseContext.Provider
			value={{ isLoading, isFetching, refetch, response, query }}
		>
			{children}
		</UserQueryResponseContext.Provider>
	);
};

const useUserQueryResponse = () => useContext(UserQueryResponseContext);

const useQueryViewResponseData = () => {
	const { response } = useUserQueryResponse();
	if (!response) {
		return [];
	}
	return response.data || [];
};

const useQueryUserResponsePagination = () => {
	const defaultPaginationState: PaginationState = {
		links: [],
		...initialQueryState,
	};

	const { response } = useUserQueryResponse();

	if (!response || !response.payload || !response.payload.pagination) {
		return defaultPaginationState;
	}
	return response.payload.pagination;
};

const useQueryUserResponseLoading = (): boolean => {
	const { isLoading } = useUserQueryResponse();
	return isLoading;
};

//Initializing source query
const SourceQueryResponseContext =
	createResponseContext<Source>(initialQueryResponse);
const SourceQueryResponseProvider: FC<WithChildren> = ({ children }) => {
	const { state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);

	const {
		isLoading,
		isFetching,
		refetch,
		data: response,
	} = useQuery([`GET_SOURCE_LIST-${query}`], () => {
		return getSourceList(query);
	},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }

	);

	return (
		<SourceQueryResponseContext.Provider
			value={{ isLoading: isLoading, isFetching, refetch, response, query }}
		>
			{children}
		</SourceQueryResponseContext.Provider>
	);
};
const useSourceQueryResponse = () => useContext(SourceQueryResponseContext);
const useQuerySourceData = () => {
	const { response } = useSourceQueryResponse();
	if (!response) {
		return [];
	}

	return response.data || [];
};
const useSourceResponsePagination = () => {
	const defaultPaginationState: PaginationState = {
		links: [],
		...initialQueryState,
	};

	const { response } = useSourceQueryResponse();

	if (!response) {
		return defaultPaginationState;
	}
	return response.payload.pagination;
};
const useQuerySourceResponseLoading = (): boolean => {
	const { isLoading } = useSourceQueryResponse();
	return isLoading;
};

//Initializing organization query
const OrganizationQueryResponseContext = createResponseContext<Source>(initialQueryResponse);

const OrganizationQueryResponseProvider: FC<WithChildren> = ({ children }) => {
	const { state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);

	const {
		isLoading,
		isFetching,
		refetch,
		data: response,
	} = useQuery([`GET_ORGANIZATIONS_LIST-${query}`], () => {
		return getOrganizationsList(query);
	},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
	);
	// console.log('organizations',organizations);

	// const isLoading = false
	// const refetch = (): void => { }
	// const response = [
	// 	{ "id": 1, "organization_name": "New Organization 1", "created_date": "2023/02/01" },
	// 	{ "id": 2, "organization_name": "New Organization 2", "created_date": "2023/02/02" },
	// 	{ "id": 3, "organization_name": "New Organization 3", "created_date": "2023/02/02" },
	// 	{ "id": 4, "organization_name": "New Organization 4", "created_date": "2023/02/03" },
	// 	{ "id": 5, "organization_name": "New Organization 5", "created_date": "2023/02/03" },
	// 	{ "id": 6, "organization_name": "New Organization 6", "created_date": "2023/02/04" },
	// 	{ "id": 7, "organization_name": "New Organization 7", "created_date": "2023/02/04" },
	// 	{ "id": 8, "organization_name": "New Organization 8", "created_date": "2023/02/04" },
	// ]


	return (
		<OrganizationQueryResponseContext.Provider
			value={{ isLoading: isLoading, isFetching, refetch, response, query }}
		>
			{children}
		</OrganizationQueryResponseContext.Provider>
	);
};

const useOrganizationQueryResponse = () => useContext(OrganizationQueryResponseContext);

const useOrganizationQueryData = () => {
	const { response } = useOrganizationQueryResponse();

	if (!response) {
		return [];
	}

	return response.data || [];
}

const useOrganizationResponsePagination = () => {
	const defaultPaginationState: PaginationState = {
		links: [],
		...initialQueryState,
	};

	const { response } = useOrganizationQueryResponse();

	if (!response) {
		return defaultPaginationState;
	}
	return response.payload.pagination;
}

const useOrganizationResponseLoading = (): boolean => {
	const { isLoading } = useOrganizationQueryResponse();
	return isLoading;
};

//Initializing participant query
const ParticipantQueryResponseContext = createResponseContext<Source>(initialQueryResponse);

const ParticipantQueryResponseProvider: FC<WithChildren> = ({ children }) => {
	const { state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
	const { id } = useParams();

	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);
	const {
		isLoading,
		isFetching,
		refetch,
		data: response,
	} = useQuery([`PARTICIPANT_LIST-${query}`], () => {
		return getParticipantList(query, id);
	},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
	);

	return (
		<ParticipantQueryResponseContext.Provider
			value={{ isLoading: isLoading, isFetching, refetch, response, query }}
		>
			{children}
		</ParticipantQueryResponseContext.Provider>
	);
};

const useParticipantQueryResponse = () => useContext(ParticipantQueryResponseContext);

const useParticipantQueryData = () => {
	const { response } = useParticipantQueryResponse();

	if (!response) {
		return [];
	}

	return response.data || [];
}


const useParticipantResponsePagination = () => {
	const defaultPaginationState: PaginationState = {
		links: [],
		...initialQueryState,
	};

	const { response } = useParticipantQueryResponse();

	if (!response) {
		return defaultPaginationState;
	}
	return response.payload.pagination;
}

const useParticipantResponseLoading = (): boolean => {
	const { isLoading } = useParticipantQueryResponse();
	return isLoading;
};

//Initializing domain query
const DomainQueryResponseContext = createResponseContext<Source>(initialQueryResponse);

const DomainQueryResponseProvider: FC<WithChildren> = ({ children }) => {
	const { state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);

	const {
		isLoading,
		isFetching,
		refetch,
		data: response,
	} = useQuery([`LIST_DOMAIN-${query}`], () => {
		return listDomain(query);
	},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
	);

	return (
		<DomainQueryResponseContext.Provider
			value={{ isLoading: isLoading, isFetching, refetch, response, query }}
		>
			{children}
		</DomainQueryResponseContext.Provider>
	);
};

const useDomainQueryResponse = () => useContext(DomainQueryResponseContext);

const useDomainQueryData = () => {
	const { response } = useDomainQueryResponse();
	if (!response) {
		return [];
	}

	return response.data || [];
}

const useDomainResponsePagination = () => {
	const defaultPaginationState: PaginationState = {
		links: [],
		...initialQueryState,
	};

	const { response } = useDomainQueryResponse();

	if (!response) {
		return defaultPaginationState;
	}
	return response.payload.pagination;
}

const useDomainResponseLoading = (): boolean => {
	const { isLoading } = useDomainQueryResponse();
	return isLoading;
};

//Initializing organization under participant query
const DetailQueryResponseContext = createResponseContext<Source>(initialQueryResponse);

const DetailQueryResponseProvider: FC<WithChildren> = ({ children }) => {
	const { state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
	const { id } = useParams();

	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);

	const {
		isLoading,
		isFetching,
		refetch,
		data: response,
	} = useQuery([`ORGANIZATION_PARTICIPANT-${query}`], () => {
		return getParticipantOrganizationList(query, id);
	},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
	);

	// const resp = {
	// 	"data": [
	// 		{ "id": 1, "organization_name": "New Organization 1", "created_date": "2023/02/01" },
	// 		{ "id": 2, "organization_name": "New Organization 2", "created_date": "2023/02/02" },
	// 		{ "id": 3, "organization_name": "New Organization 3", "created_date": "2023/02/02" },
	// 		{ "id": 4, "organization_name": "New Organization 4", "created_date": "2023/02/03" },
	// 		{ "id": 5, "organization_name": "New Organization 5", "created_date": "2023/02/03" },
	// 		{ "id": 6, "organization_name": "New Organization 6", "created_date": "2023/02/04" },
	// 		{ "id": 7, "organization_name": "New Organization 7", "created_date": "2023/02/04" },
	// 		{ "id": 8, "organization_name": "New Organization 8", "created_date": "2023/02/04" },
	// 	],
	// 	"payload": {
	// 		"pagination": {
	// 			"page": 1,
	// 			"from": 1,
	// 			"to": 8,
	// 			"total": 8,
	// 			"links": [
	// 				{
	// 					"url": "/?page=1",
	// 					"label": "1",
	// 					"active": true,
	// 					"page": 1
	// 				},
	// 				{
	// 					"url": null,
	// 					"label": "&laquo; Previous",
	// 					"active": false,
	// 					"page": null
	// 				},
	// 				{
	// 					"url": null,
	// 					"label": "Next &raquo;",
	// 					"active": false,
	// 					"page": null
	// 				}
	// 			],
	// 			"last_page": 1,
	// 			"first_page_url": "/?page=1",
	// 			"next_page_url": null,
	// 			"prev_page_url": null,
	// 			"items_per_page": "10"
	// 		}
	// 	}
	// }


	return (
		<DetailQueryResponseContext.Provider
			value={{ isLoading: isLoading, isFetching, refetch, response, query }}
		>
			{children}
		</DetailQueryResponseContext.Provider>
	);
};

const useDetailQueryResponse = () => useContext(DetailQueryResponseContext);

const useDetailQueryData = () => {
	const { response } = useDetailQueryResponse();

	if (!response) {
		return [];
	}

	return response.data || [];
}

const useDetailResponsePagination = () => {
	const defaultPaginationState: PaginationState = {
		links: [],
		...initialQueryState,
	};

	const { response } = useDetailQueryResponse();

	if (!response) {
		return defaultPaginationState;
	}
	return response.payload.pagination;
}

const useDetailResponseLoading = (): boolean => {
	const { isLoading } = useDetailQueryResponse();
	return isLoading;
};



export {
	QueryResponseProvider,
	useQueryResponse,
	useQueryResponseData,
	useQueryResponsePagination,
	useQueryResponseLoading,
	useQueryViewResponseData,
	useQueryUserResponsePagination,
	UserQueryResponseProvider,
	useQueryUserResponseLoading,
	useUserQueryResponse,
	SourceQueryResponseProvider,
	useQuerySourceData,
	useSourceResponsePagination,
	useSourceQueryResponse,
	useQuerySourceResponseLoading,
	OrganizationQueryResponseProvider,
	useOrganizationQueryData,
	useOrganizationResponsePagination,
	useOrganizationQueryResponse,
	useOrganizationResponseLoading,
	ParticipantQueryResponseProvider,
	useParticipantQueryData,
	useParticipantResponsePagination,
	useParticipantQueryResponse,
	useParticipantResponseLoading,
	DomainQueryResponseProvider,
	useDomainQueryData,
	useDomainResponsePagination,
	useDomainQueryResponse,
	useDomainResponseLoading,
	DetailQueryResponseProvider,
	useDetailQueryData,
	useDetailResponsePagination,
	useDetailQueryResponse,
	useDetailResponseLoading,
};
