import React, { useEffect, useState } from "react";
import {
  AiOutlineFieldTime,
  AiOutlineProject,
  AiTwotoneCalendar,
} from "react-icons/ai";
import MarketingBarGraph from "./marketting_component/MarketingBarGraph";
import { DateRangePicker } from "materialui-daterange-picker";
import BarGraphwithLine from "./capaign_components/BarGraphwithLine";
import CampaignStats from "../../../theme/layout/components/header/header-menus/CampaignStats";
import SideGNW from "./SideGNW";
import MarketingProductTypes from "./MarketingProductTypes";
import AmChartStickBarGrouped from "./AmChartStickBarGrouped";
import GroupedVbarMarketing from "./components/GroupedVbarMarketing";
import ProductStatsCard from "../../../theme/layout/components/header/header-menus/ProductStatsCard";
import HeaderDropdown from "./Custom_Components/HeaderDropdown";
import CustomDateRangePicker from "./Custom_Components/CustomDateRangePicker";
import ChannelTable from "./components/ChannelTable";
import ChannelTableMarketing from "./components/ChannelTableMarketing";
import AmPieChart from "../../../theme/partials/widgets/charts/AmPieChart";
import CenteredLine from "./CenterLine";
import SpendsByChannnelStats from "../../../theme/partials/widgets/charts/SpendsByChannnelStats";
import {
  URLS,
  getMethodDataFromBackend,
} from "../../modules/dashboards/DashboardRequests";

function MarketingDashboard() {
  const [startDate, setStartDate] = useState("2023-06-01");
  const [endDate, setEndDate] = useState("2023-06-30");
  const [baseLineDropdown, setBaseLineDropdown] = useState(["custom"]);

  const [selectedBaselineValue, setSelectedBaselineValue] =
    useState("Baseline");

  const handleDropdownItemClick = (data) => {
    setSelectedBaselineValue(data);
  };

  const [productId, setProductId] = useState("all");
  const [platformId, setPlatformId] = useState<string>("1");

  const setDateFilter = async () => {
    try {
      const response = await getMethodDataFromBackend(URLS.BASE_URL + "marketing/getdates");
      console.log("setDateFilter response:-", response)
      if(response?.data?.dashboardLevel === "Marketing") {
        setStartDate(formatDate(response.data.startDate));
        setEndDate(formatDate(response.data.endDate));
      }
    } catch(err) {

    }
  }

   // Function to format date
const formatDate = (inputDate) => {
  // Parse input date string
  const date = new Date(inputDate);
  
  // Extract year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because month index starts from 0
  const day = String(date.getDate()).padStart(2, '0');
  
  // Return formatted date string
  return `${year}-${month}-${day}`;
}


  const getDate = (currentDate) => {
    const offset = currentDate.getTimezoneOffset();
    let yourDateObj = new Date(currentDate.getTime() - offset * 60 * 1000);
    return yourDateObj.toISOString().split("T")[0];
  };

  const handleDateRangeChange = (dateRange) => {
    setStartDate(getDate(dateRange?.startDate));
    setEndDate(getDate(dateRange?.endDate));
  };

  const getData = async () => {
    try {
      let responseBaseline = await getMethodDataFromBackend(
        URLS.BASE_URL + "baseline/options"
      );

      let data = responseBaseline?.data;

      if (data) {
        console.log("responseBaseline", data);
        setBaseLineDropdown(data);
      } else {
        console.log("No data received from the backend");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
    setDateFilter();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const element = document.getElementById("testId1");
      if (element) {
        element.style.position = scrollPosition > 10 ? "fixed" : "absolute";
        element.style.right = "30px";
        // element.style.zIndex = 1;
        element.style.width = "100%";
        element.style.top = scrollPosition > 10 ? "70px" : "85px";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        id="testId1"
        className={`d-flex flex-end`}
        style={{
          position: "absolute",
          right: "30px",
          zIndex: 1,
          width: "100%",
          top:'85px'
        }}
      >
        <HeaderDropdown
          selectedValue={selectedBaselineValue}
          dropdownItems={baseLineDropdown}
          onDropdownItemClick={handleDropdownItemClick}
        />

        <CustomDateRangePicker
          startDate={startDate}
          endDate={endDate}
          onDateRangeChange={handleDateRangeChange}
        />
      </div>

      {/* <div
        className="row gx-xl-10 d-flex align-items-center justify-content-end gy-0"
        style={{ marginTop: "-30px" }}
      >
        <div className="col-6 d-flex align-items-center">
          <SideGNW
            startDate={startDate}
            endDate={endDate}
            platformId={platformId}
            productId={productId}
            channelType={"marketing"}
            statsSubUrl={"/stats"}
            baseLineType={selectedBaselineValue}
            productType={""}
          />
        </div>
      </div> */}

      {/* <CampaignStats
        platformId={platformId}
        productId={productId}
        startDate={startDate}
        endDate={endDate}
        channelType={"marketing"}
        baseLineType={selectedBaselineValue}
        statsSubUrl={"/stats"}
        productType={""}
      /> */}

      {/* <ProductStatsCard
          platformId={platformId}
          productId={productId}
          startDate={startDate}
          endDate={endDate}
          channelType={"marketing"}
          baseLineType={`${selectedBaselineValue}`}
          productType={"All"}
          statsSubUrl={"/overallstats"}
        />

        <AmChartStickBarGrouped
          platformId={platformId}
          productId={productId}
          startDate={startDate}
          endDate={endDate}
          channelType={"marketing"}
          productType={""}
        /> */}

      {/* <div className="row gx-xl-10 gy-10 mb-10">
        <div className="col-xl-6">
          <GroupedVbarMarketing
            label={"Google"}
            startDate={startDate}
            endDate={endDate}
            channelType={"marketing"}
            productId={productId}
            platformId={platformId}
            subTypeUrl={"/google"}
          />
        </div>
        <div className="col-xl-6">
          <GroupedVbarMarketing
            label={"Facebook"}
            startDate={startDate}
            endDate={endDate}
            channelType={"marketing"}
            productId={productId}
            platformId={platformId}
            subTypeUrl={"/facebook"}
          />
        </div>
      </div> */}

      <div className="row gy-5 gx-xl-10 mb-10">
        <div className="col-xl-4">
          <AmPieChart
            title={"Spends By Product"}
            chartID="pie-graph-1"
            platformId={platformId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            channelType={"marketing"}
            campaignId={""}
            subUrl={"/spends"}
          />
        </div>
        <div className="col-xl-4">
          <AmPieChart
            title={"Spends By Channel"}
            chartID="pie-graph-2"
            platformId={platformId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            channelType={"marketing"}
            campaignId={""}
            subUrl={"/spendsbychannels"}
          />
        </div>
        <div className="col-xl-4">
          {/* <ProductStatsCard
          platformId={platformId}
          productId={productId}
          startDate={startDate}
          endDate={endDate}
          channelType={"newproduct"}
          baseLineType={`${selectedBaselineValue}`}
          productType={"All"}
          statsSubUrl={"/overallstats"}
        /> */}
          <SpendsByChannnelStats
            title={"Top Metrics"}
            chartID="pie-graph-3"
            platformId={platformId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            channelType={"marketing"}
            campaignId={""}
            subUrl={"/spendsbychannelsmetrics"}
            baseLine={selectedBaselineValue}
          />
        </div>
      </div>
      <div className="row gx-xl-10 gy-10 mb-10">
        <div className="col-xl-12">
          <ChannelTableMarketing
            platformId={platformId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            campaignId={"channelId"}
            title={"Product Types"}
            channelType={"marketing"}
            subUrl={"/productypedata"}
            baseLine={selectedBaselineValue}
          />
        </div>
      </div>
      <div className="row gx-xl-10 gy-10">
        <div className="col-xl-12">
          <ChannelTableMarketing
            platformId={platformId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            campaignId={"channelId"}
            title={"Platform Types"}
            channelType={"marketing"}
            subUrl={"/platformtypedata"}
            baseLine={selectedBaselineValue}
          />
        </div>
      </div>
      {/* <div className="col-xl-12">
        <MarketingBarGraph
          startDate={startDate}
          endDate={endDate}
          productId={productId}
        />
      </div> */}
      {/* <div className="col-xl-12">
        <BarGraphwithLine
          title={"Performance Google"}
          chartID="agecolumngraph1"
          productId={productId}
          startDate={startDate}
          endDate={endDate}
          performanceType={"/performancegoogle"}
        />
      </div> */}
      {/* <div className="col-xl-12">
        <BarGraphwithLine
          title={"Performance Facebook"}
          chartID="agecolumngraph2"
          productId={productId}
          startDate={startDate}
          endDate={endDate}
          performanceType={"/performancefacebook"}
        />
      </div> */}
    </>
  );
}

export default MarketingDashboard;
