export const appColor = "#E84A42";

export const primaryColors = {
  orange: "#E84A42",
  maroon: "#97291e",
  blue: "#053c6d",
  grey: "#a1a1a1",
  black: "#08112D",
  red: "#E84A42",
  white: "#FFFFFF",
  pink: "#ffe2dd"
};

export const secondryColors = {
  orange: "#fdb92a",
  pink: "#f4858e",
  skyBlue: "#00c0f3",
  purple: "#917bb9",
};
