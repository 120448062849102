import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { KTCard } from "../../../../theme/helpers";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsCurrencyRupee } from "react-icons/bs";
import { BsExclamationCircle } from "react-icons/bs";
import { MdSettings } from "react-icons/md";

import { useLocation, useNavigate } from "react-router-dom";
import search from "../../../../assets/search.png";
import display from "../../../../assets/display.png";

import { FaTimes } from "react-icons/fa";
import { Badge } from "react-bootstrap";
import AdRotation from "./AdRotation";
import AdSchedule from "./AdSchedule";
import Dates from "./Dates";
import CampaignURL from "./CampaignURL";
import { moreSettings } from "../../../modules/application/_requests";

const MoreSettings = ({campaignId}) => {
  const rotation = [
    {
      id: "1",
      tname: "Optimize: Prefer best performing ads",
    },
    {
      id: "2",
      tname: "Do not optimize: Rotate ads indefinitely",
    },
    {
      id: "3",
      tname: "Optimize for conversions (Not supported)",
    },
    {
      id: "4",
      tname: "Rotate evenly (Not supported)",
    },
  ];
  const [selectedRotation, setSelectedRotation] = useState(
    rotation[0]?.tname || ""
  );
  console.log(selectedRotation, "rotationnnnnnn");

  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  console.log(selectedStartDate, selectedEndDate, "dates");

  const [inputValues, setInputValues] = useState([{ name: "", value: "" }]);
  console.log(inputValues, "namevalue");
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  console.log(inputValue, inputValue2, "templateUrl");

  const [schedules, setSchedules] = useState([
    { selectedWeekday: "", startTime: "", endTime: "" },
  ]);

  console.log(schedules, "schedules");
  const [isExpanded, setIsExpanded] = useState(false);
  const [headerColor, setHeaderColor] = useState("");
  const navigate = useNavigate();
 
  console.log(campaignId, "iddsidhsjidhsjkfhdjkfh");


  const handleClick = (values: any) => {
    const saveddata: any = {
      campaign_id:campaignId,
      AdRotation: selectedRotation,
      AdSchedule: {
        days:schedules,
        start: schedules,
        end: schedules,
      },
      Start_Date: {
        start: selectedStartDate,
        end: selectedEndDate,
      },
      Url: {
        template: inputValue,
        suffix: inputValue2,
        name: inputValues,
        value:inputValues,
      },
    };

    // const file = values.fileName[0];
    console.log(saveddata, "moreeeeeeeeeeee");
    const response = moreSettings(saveddata)
      .then((response) => {
        console.log("More Settings Added Successfully!");
        console.log(response, "more settings");
        setTimeout(() => {
          console.log(undefined);
        }, 2000);
        // const campaignId = response.campaign_id;
        navigate("/keywords", { state: { campaignId } });
      })
      .catch((err) => {
        alert("Request Failed!");
      });
    
  
  };

  const handleToggleContent5 = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div
        className="card"
        style={{
          marginTop: "15px",
          backgroundColor: isExpanded ? "" : "#E1E2E3",
          // boxShadow: isExpanded ? "0 2px 8px rgba(0, 0, 0, 0.5)" : "",
        }}
      >
        <div
          className="card-header"
          onClick={handleToggleContent5}
          onMouseOver={() => setHeaderColor("#E1E2E3")}
          onMouseOut={() => setHeaderColor("")}
          style={{
            backgroundColor: headerColor || "#FFFFFF", // Set default color to white
            cursor: "pointer",
            border: "1px solid #ced4da",
            transition: "background-color 0.3s ease",
            minHeight: "48px",
            borderRadius: "2px",
          }}
        >
          <h5 className="card-title text-primary">
            {" "}
            <MdSettings className="" /> More settings
          </h5>
        </div>
      </div>
      {isExpanded && (
        <>
          <AdRotation
            selectedRotation={selectedRotation}
            setSelectedRotation={setSelectedRotation}
            rotation={rotation}
          />
          <AdSchedule schedules={schedules} setSchedules={setSchedules} />
          <Dates
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            setSelectedStartDate={setSelectedStartDate}
            setSelectedEndDate={setSelectedEndDate}
          />
          <CampaignURL
            inputValues={inputValues}
            setInputValues={setInputValues}
            inputValue={inputValue}
            setInputValue={setInputValue}
            setInputValue2={setInputValue2}
            inputValue2={inputValue2}
          />
          <div className="">
            <div
              className="gap-2 my-5 d-flex"
              style={{ justifyContent: "flex-end" }}
            >
              <a
                href="#"
                className="btn btn-sm fw-bold btn-primary"
                onClick={handleClick}
              >
                Next
              </a>
              {/* <Biding/> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MoreSettings;
