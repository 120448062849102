import React, { useState } from "react";
// import Data from "./Data.json";
import { RiCheckLine } from "react-icons/ri";
import salesp from "../../../../assets/outline-local-offer-_2_.png";
import leadsp from "../../../../assets/outline-people-_1_.png";
import websitetraffic from "../../../../assets/website_traffic.png";
import productbr from "../../../../assets/auto-awesome.png";
import brandawareness from "../../../../assets/brand_awareness.png";
import AppPromo from "../../../../assets/app_promo.png";
import outlineroom from "../../../../assets/outline-room.png";
import settingslight from "../../../../assets/settings-light.png";
import { KTCard } from "../../../../theme/helpers";
import search from "../../../../assets/search.png";
import performance from "../../../../assets/PerformanceMatrix.png";
import display from "../../../../assets/display.png";
import shopping from "../../../../assets/shopping.png";
import video from "../../../../assets/video.png";
import discovery from "../../../../assets/Discovery.png";


type TypeCardData = {
  id: number;
  title: string;
  image: string;
  content: React.ReactNode;
};


type Props = {
    id: number;
    selectedCard: number;
    onSelect: (id: number,
       title:string
       ) => void;
  };
  
  
  const CampTypeCard = ({ id, selectedCard, onSelect }: Props) => {
    const [isHovered, setIsHovered] = useState(false);
  
    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
  
   
  
    const Data= 
    [
      {
      "id": 11,
      "title": "Search",
      image: search,
      content:( <p>Get in front of high-intent customers at the right time on Google search</p>)
    },
    {
      "id": 12,
      "title": "Performance Max",
      image: performance,
      content: (<div className="d-flex"><p>Reach audiences accross all of Google with a single campaign.<a href="#"> See how it works.</a></p></div>)
      
    },
    {
      "id": 13,
      "title": "Display",
      image: display,
      content:( <p>Reach customers accross 3 millions sites and apps with engaging creative</p>)
    },
   
    {
      "id": 14,
      "title": "Shopping",
      image: shopping,
      content:( <p>Showcase your products to shoppers as they explore what to buy"</p>)
    },
    {
      "id": 15,
      "title": "Video",
      image: video,
      content:( <p>Reach viewers on YouTube and get conversions</p>)
    },
    {
      "id": 16,
      "title": "Discovery",
      image: discovery,
      content:( <p>Reach viewers on YouTube and get conversions</p>)
    }
    // {
    //   "id": 7,
    //   "title": "Local store visits and promotion",
    //   image: "outline",
    //   "description": "Drive foot traffic and sales to your local stores"
    // },
    // {
    //   "id": 8,
    //   "title": "Create a campaign without a typeData's guidance",
    //   image: "outline",
    //   "description": "Set specific goals to measure the success of your campaigns"
    // }
  ];



  const handleClick = () => {
    if (selectedCard === id) {
      onSelect(-1,'' ); // Pass a different value to indicate deselection, e.g., -1
    } else {
      // onSelect(id, typeData.title);
      onSelect(id, typeCardData.title);
    }
  };

  
 const typeCardData= Data.find((card: TypeCardData) => card.id === id) as TypeCardData;

  if (!typeCardData) {
    return null;
  }

  const { title, image, content } = typeCardData;
  // const { title, image, content } = typeData;

  return (
    <KTCard>
      <div
        className=""
        style={{
          position: "relative",
          height: "194.4px",
          // width: '261.36px',
          overflow: "",
          color: selectedCard === id ? "#0d6efd" : "#3c4043",
          cursor: "pointer",
          border:
            selectedCard === id
              ? "2px solid #0d6efd"
              : isHovered
              ? "2px solid #0d6efd"
              : "2px solid #CCCCCC",
          borderRadius: "0px",
          transition: "all 0.3s",
          transform:
            selectedCard === id ? "scale(1)" : isHovered ? "scale(1.1)" : "scale(1)",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {selectedCard === id && (
          <div
            className="overflow-element border-solid-primary"
            style={{
              position: "absolute",
              top: "-10px",
              overflow: "",
              right: "-10px",
              width: "28px",
              height: "28px",
              background: "white",
              border: "2px solid #0d6efd",
              borderWidth: "2px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#0d6efd",
              zIndex: 1,
            }}
          >
            <RiCheckLine size={20} />
          </div>
        )}

        <div className={`card h-lg-100 ${isHovered ? "btn-active-color-primary" : ""}`} style={{ overflow: "" }}>
          <div className={`p-5 ${isHovered ? "clicked" : ""}`}>
            <img src={image} alt="image" style={{ maxWidth: "90px", maxHeight: "75px",
             border: '1px solid #ced4da', borderRadius:'4px' }} />
            <br />
            <div className="card-title align-items-start flex-column mt-2" style={{ overflow: "auto" }}>
              <div className="card-label mt-2 mb-2" style={{ font: "Roboto", 
              fontSize: "14px", overflow: "hidden", textOverflow: "ellipsis", fontWeight: "540" }}>
                {title}
              </div>
              <div className="fw-semibold" style={{ font: "Google-sans",
               fontSize: "13px", fontSmooth: "antialiased", fontWeight: "0.2px", 
               lineHeight:'15px', letterSpacing: "0.5", overflow: "hidden", textOverflow: "ellipsis" }}>
                {content}
              </div>
            </div>
          </div>
        </div>
      </div>
    </KTCard>
  );
};

export default CampTypeCard;