import React, { useState, useEffect, useRef } from "react";
import { FiGlobe, FiPhoneCall, FiMapPin, FiLink2 } from "react-icons/fi";
import { RiCheckLine } from "react-icons/ri";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { KTCard } from "../../../../theme/helpers";
import { IoMdArrowDropdown, IoMdLink } from "react-icons/io";
import { ImBlocked } from "react-icons/im";
import { FiSearch } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";
import CustomQuestion from "./customQuestion";

interface Option {
  value: string;
  label: string;
  countrycode: string;
}

interface CheckboxProps {
  label: string;
  showInput: boolean;
  setShowInput: (show: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  showInput,
  setShowInput,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option>({
    value: "",
    label: "",
    countrycode: "",
  });
  const [showOptions, setShowOptions] = useState(false);
  const [dropSelect, setdropSelect] = useState(false);
  const [dropHover, setdropHover] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [dropbg, setdropbg] = useState(false);
  const [selectedaOption, setSelectedaOption] = useState(null);
  const [hoveredOptionIndex, setHoveredOptionIndex] = useState(null);
  const [selectedOptionIndex, setselectedOptionIndex] = useState(null);
  const [inputvalid, setInputvalid] = useState(true);
  const [isCountryClicked, setIsCountryClicked] = useState(false);

  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setIsCountryClicked(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleCountryClick = () => {
    setIsCountryClicked(true);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  // const countryRef = useRef<HTMLDivElement>(null);
  const divStyles = {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    width: "150px",
    height: "50px",
    borderRadius: "5px",
    border: isCountryClicked ? "3px solid #0d6efd" : "1px solid #80868B",
    cursor: "pointer",
    color: "#3c4043",
    outline: "none",
    transition: "border-color 0.5s ease-in-out",
    // borderColor: showOptions ? "blue" : "#80868B",
    // borderWidth: showOptions ? "2px" : "1px",
  };

  const options: Option[] = [
    {
      value: "Afaghanistan",
      label: "Afaghanistan",
      countrycode: "023 456 7890",
    },
    {
      value: "Aland Island",
      label: "Aland Island",
      countrycode: "018 1234567",
    },
    { value: "Albania", label: "Albania", countrycode: "022 345 678" },
    { value: "Algeria", label: "Algeria", countrycode: "012 34 56 78" },
    {
      value: "Americal Samoa",
      label: "Americal Samoa",
      countrycode: "(684) 622-1234",
    },
    { value: "Andorra", label: "Andorra", countrycode: "712 345" },
    { value: "Angola", label: "Angola", countrycode: "222 123 456" },
    { value: "Anguilla", label: "Anguilla", countrycode: "(264) 461-2345" },
    { value: "Bahamas", label: "Bahamas", countrycode: "(242) 345-6789" },
    { value: "Bahrain", label: "Bahrain", countrycode: "1700 1234" },
    { value: "Bangladesh", label: "Bangladesh", countrycode: "02-7111234" },
    { value: "Barbados", label: "Barbados", countrycode: "(246) 412-3456" },
    { value: "Belgium", label: "Belgium", countrycode: "012 34 56 78" },
    { value: "India", label: "India", countrycode: "074104 10123" },

    { value: "Indonesia", label: "Indonesia", countrycode: "(021) 8350123" },
    { value: "Iraq", label: "Iraq", countrycode: "01 234 5678" },
    { value: "Ireland", label: "Ireland", countrycode: "(022) 12345" },
    {
      value: "Isle of Man",
      label: "Isle of Man",
      countrycode: "01624 756789",
    },
    { value: "Israel", label: "Israel", countrycode: "02-123-4567" },
    { value: "Italy", label: "Italy", countrycode: "02 1234 5678" },
    { value: "Jamaica", label: "Jamaica", countrycode: "(876) 523-0123" },
    { value: "Japan", label: "Japan", countrycode: "03-1234-5678" },
    { value: "Jersey", label: "Jersey", countrycode: "01534 456789" },
    { value: "Jordan", label: "Jordan", countrycode: "(06) 200 1234" },
    {
      value: "Kazakhstan",
      label: "Kazakhstan",
      countrycode: "8 (71234) 5 67 89",
    },
    { value: "Kenya", label: "Kenya", countrycode: "020 2012345" },
    {
      value: "Kiribati",
      label: "Kiribati",
      countrycode: "31234",
    },
    { value: "Kuwait", label: "Kuwait", countrycode: "2234 5678" },
    {
      value: "Kyrgyzstan",
      label: "Kyrgyzstan",
      countrycode: "0312 123 456",
    },
    {
      value: "Lao People's Democratic Republic",
      label: "Lao People's Democratic Republic",
      countrycode: "021 212 862",
    },
    { value: "Latvia", label: "Latvia", countrycode: "63 123 456" },
    { value: "Lebanon", label: "Lebanon", countrycode: " 01 123 456" },
    { value: "Lesotho", label: "Lesotho", countrycode: "2212 3456" },
    { value: "Liberia", label: "Liberia", countrycode: "021 234 567" },
    { value: "Libya", label: "Libya", countrycode: "021-2345678" },
    {
      value: "Liechtenstein",
      label: "Liechtenstein",
      countrycode: "234 56 78",
    },
    {
      value: "Lithuania",
      label: "Lithuania",
      countrycode: "(8-312) 34567",
    },
    {
      value: "Luxembourg",
      label: "Luxembourg",
      countrycode: "27 12 34 56",
    },
    { value: "Macau", label: "Macau", countrycode: "2821 2345" },
    {
      value: "Macedonia",
      label: "Macedonia",
      countrycode: "02 201 2345",
    },
    {
      value: "Madagascar",
      label: "Madagascar",
      countrycode: "020 21 234 56",
    },
    { value: "Malawi", label: "Malawi", countrycode: "01 234 567" },
    {
      value: "Malaysia",
      label: "Malaysia",
      countrycode: "03-2385 6789",
    },
    {
      value: "Maldives",
      label: "Maldives",
      countrycode: "670-1234",
    },
    { value: "Mali", label: "Mali", countrycode: "20 21 23 45" },
    { value: "Malta", label: "Malta", countrycode: "2100 1234" },
    {
      value: "Marshall Islands",
      label: "Marshall Islands",
      countrycode: "247-1234",
    },
    {
      value: "Martinique",
      label: "Martinique",
      countrycode: "0596 30 12 34",
    },
    {
      value: "Mauritania",
      label: "Mauritania",
      countrycode: "35 12 34 56",
    },
    {
      value: "Mauritius",
      label: "Mauritius",
      countrycode: "5448 0123",
    },
    { value: "Mayotte", label: "Mayotte", countrycode: "0269 60 12 34" },
    { value: "Mexico", label: "Mexico", countrycode: "200 123 4567" },
    {
      value: "Micronesia",
      label: "Micronesia",
      countrycode: "320 1234",
    },
    { value: "Moldova", label: "Moldova", countrycode: "022 212 345" },
    { value: "Monaco", label: "Monaco", countrycode: "99 12 34 56" },
    {
      value: "Mongolia",
      label: "Mongolia",
      countrycode: "5312 3456",
    },
    {
      value: "Montenegro",
      label: "Montenegro",
      countrycode: "030 234 567",
    },
    {
      value: "Montserrat",
      label: "Montserrat",
      countrycode: "(664) 491-2345",
    },
    { value: "Morocco", label: "Morocco", countrycode: "0520-123456" },
    {
      value: "Mozambique",
      label: "Mozambique",
      countrycode: "21 123 456",
    },
    { value: "Myanmar", label: "Myanmar", countrycode: "01 234 567" },
    { value: "Namibia", label: "Namibia", countrycode: "061 221 234" },
    { value: "Nauru", label: "Nauru", countrycode: "444 1234" },
    { value: "Nepal", label: "Nepal", countrycode: " 01-4567890" },
    {
      value: "Netherlands",
      label: "Netherlands",
      countrycode: " 010 123 4567",
    },
    {
      value: "New Caledonia",
      label: "New Caledonia",
      countrycode: "20.12.34",
    },
    {
      value: "New Zealand",
      label: "New Zealand",
      countrycode: "03 234 5678",
    },
    {
      value: "Nicaragua",
      label: "Nicaragua",
      countrycode: "2123 4567",
    },
    { value: "Niger", label: "Niger", countrycode: "20 20 12 34" },
    { value: "Nigeria", label: "Nigeria", countrycode: "01 804 0123" },
    { value: "Niue", label: "Niue", countrycode: "7012" },
    {
      value: "Norfolk Island",
      label: "Norfolk Island",
      countrycode: "10 6609",
    },
    {
      value: "Northern Mariana Islands",
      label: "Northern Mariana Islands",
      countrycode: "(670) 234-5678",
    },
    { value: "Norway", label: "Norway", countrycode: "21 23 45 67" },
    { value: "Oman", label: "Oman", countrycode: "(021) 23456789" },
    {
      value: "Pakistan",
      label: "Pakistan",
      countrycode: "(021) 23456789",
    },
    { value: "Palau", label: "Palau", countrycode: "277 1234" },
    {
      value: "Palestinian Territory",
      label: "Palestinian Territory",
      countrycode: "02 223 4567",
    },
    { value: "Panama", label: "Panama", countrycode: "200-1234" },
    {
      value: "Papua New Guinea",
      label: "Papua New Guinea",
      countrycode: "312 3456",
    },
    {
      value: "Paraguay",
      label: "Paraguay",
      countrycode: "(021) 234 5678",
    },
    { value: "Peru", label: "Peru", countrycode: "(01) 1234567" },
    {
      value: "Philippines",
      label: "Philippines",
      countrycode: " (02) 3234 5678",
    },
    { value: "Poland", label: "Poland", countrycode: "12 345 67 89" },
    {
      value: "Portugal",
      label: "Portugal",
      countrycode: "21 234 5678",
    },
    {
      value: "Puerto Rico",
      label: "Puerto Rico",
      countrycode: "(787) 234-5678",
    },
    { value: "Qatar", label: "Qatar", countrycode: "4412 3456" },
    {
      value: "Republic of Kosovo",
      label: "Republic of Kosovo",
      countrycode: "028 012 345",
    },
    { value: "Reunion", label: "Reunion", countrycode: "0262 16 12 34" },
    { value: "Romania", label: "Romania", countrycode: "021 123 4567" },
    {
      value: "Russian Federation",
      label: "Russian Federation",
      countrycode: "8 (301) 123-45-67",
    },
    { value: "Rwanda", label: "Rwanda", countrycode: "250 123 456" },
    {
      value: "Saint Kitts and Nevis",
      label: "Saint Kitts and Nevis",
      countrycode: "(869) 236-1234",
    },
    {
      value: "Saint Lucia",
      label: "Saint Lucia",
      countrycode: "(758) 430-5678",
    },
    {
      value: "Saint Vincent and the Grenadines",
      label: "Saint Vincent and the Grenadines",
      countrycode: "(784) 266-1234",
    },
    { value: "Samoa", label: "Samoa", countrycode: "22123" },
    {
      value: "San Marino",
      label: "San Marino",
      countrycode: "0549 886377",
    },
    {
      value: "Sao Tome and Principe",
      label: "Sao Tome and Principe",
      countrycode: "222 1234",
    },
    {
      value: "Saudi Arabia",
      label: "Saudi Arabia",
      countrycode: "011 234 5678",
    },
    { value: "Senegal", label: "Senegal", countrycode: "30 101 23 45" },
    { value: "Serbia", label: "Serbia", countrycode: "010 234567" },
    {
      value: "Seychelles",
      label: "Seychelles",
      countrycode: "4 217 123",
    },
    {
      value: "Sierra Leone",
      label: "Sierra Leone",
      countrycode: "(022) 221234",
    },
    {
      value: "Singapore",
      label: "Singapore",
      countrycode: "6123 4567",
    },
    {
      value: "Sint Maarten",
      label: "Sint Maarten",
      countrycode: "(721) 542-5678",
    },
    {
      value: "Slovakia",
      label: "Slovakia",
      countrycode: "02/212 345 67",
    },
    {
      value: "Slovenia",
      label: "Slovenia",
      countrycode: "(01) 234 56 78",
    },
    {
      value: "Solomon Islands",
      label: "Solomon Islands",
      countrycode: "40123",
    },
    { value: "Somalia", label: "Somalia", countrycode: "4 012345" },
    {
      value: "South Africa",
      label: "South Africa",
      countrycode: "010 123 4567",
    },
    {
      value: "South Korea",
      label: "South Korea",
      countrycode: "02-212-3456",
    },
    { value: "Spain", label: "Spain", countrycode: "810 12 34 56" },
    {
      value: "Sri Lanka",
      label: "Sri Lanka",
      countrycode: "0112 345 678",
    },
    {
      value: " St. Helena",
      label: "St. Helena",
      countrycode: "22158",
    },
    {
      value: "St. Pierre and Miquelon",
      label: "St. Pierre and Miquelon",
      countrycode: "043 01 23",
    },
    {
      value: "Suriname",
      label: "Suriname",
      countrycode: " 211-234",
    },
    {
      value: "Svalbard and Jan Mayen Islands",
      label: "Svalbard and Jan Mayen Islands",
      countrycode: "79 12 34 56",
    },
    {
      value: "Swaziland",
      label: "Swaziland",
      countrycode: "2217 1234",
    },
    { value: "Sweden", label: "Sweden", countrycode: "08-12 34 56" },
    {
      value: "Switzerland",
      label: "Switzerland",
      countrycode: "021 234 56 78",
    },
    { value: "Taiwan", label: "Taiwan", countrycode: "02 2123 4567" },
    {
      value: "Tajikistan",
      label: "Tajikistan",
      countrycode: "372 12 3456",
    },
    {
      value: "Tanzania",
      label: "Tanzania",
      countrycode: "022 234 5678",
    },
    {
      value: "Thailand",
      label: "Thailand",
      countrycode: "02 123 4567",
    },
    { value: "Togo", label: "Togo", countrycode: "22 21 23 45" },
    { value: "Tokelau", label: "Tokelau", countrycode: "3101" },
    { value: "Tonga", label: "Tonga", countrycode: "20-123" },
    {
      value: "Trinidad and Tobago",
      label: "Trinidad and Tobago",
      countrycode: "(868) 221-1234",
    },
    { value: "Tunisia", label: "Tunisia", countrycode: "(0212) 345 67 89" },
    { value: "Turkey", label: "Turkey", countrycode: "(0212) 345 67 89" },
    {
      value: "Turkmenistan",
      label: "Turkmenistan",
      countrycode: "(8 12) 34-56-78",
    },
    {
      value: "Turks and Caicos Islands",
      label: "Turks and Caicos Islands",
      countrycode: "(649) 712-1234",
    },
    { value: "Tuvalu", label: "Tuvalu", countrycode: "20 123" },
    { value: "Uganda", label: "Uganda", countrycode: "031 2345678" },
    { value: "Ukraine", label: "Ukraine", countrycode: "03112 34567" },
    {
      value: "United Arab Emirates",
      label: "United Arab Emirates",
      countrycode: "02 234 5678",
    },
    {
      value: "United Kingdom",
      label: "United Kingdom",
      countrycode: "0121 234 5678",
    },
    {
      value: "United States",
      label: "United States",
      countrycode: "(201) 555-0123",
    },
    { value: "Uruguay", label: "Uruguay", countrycode: "2123 1234" },
    {
      value: "Uzbekistan",
      label: "Uzbekistan",
      countrycode: "8 66 905 01 23‬",
    },
    { value: "Vanuatu", label: "Vanuatu", countrycode: "22123" },
    {
      value: "Venezuela",
      label: "Venezuela",
      countrycode: "0212-1234567",
    },
    {
      value: "Viet Nam",
      label: "Viet Nam",
      countrycode: "0210 1234 567",
    },
    {
      value: "Virgin Islands (British)",
      label: "Virgin Islands (British)",
      countrycode: "(284) 229-1234",
    },
    {
      value: "Virgin Islands (U.S.)",
      label: "Virgin Islands (U.S.)",
      countrycode: "(340) 642-1234",
    },
    {
      value: "Wallis and Futuna Islands",
      label: "Wallis and Futuna Islands",
      countrycode: "72 12 34",
    },
    { value: "Yemen", label: "Yemen", countrycode: "01 234 567" },
    { value: "Zambia", label: "Zambia", countrycode: "0211 234 567" },
    {
      value: "Zimbabwe",
      label: "Zimbabwe",
      countrycode: "013 12345",
    },
    {
      value: "International Freephone",
      label: "International Freephone",
      countrycode: "+800 1234 5678",
    },
  ];

  // input[type="checkbox"]:checked::before {
  //   content: "";
  //   position: absolute;
  //   top: 4px;
  //   left: 7px;
  //   width: 5px;
  //   height: 10px;
  //   border: solid #007bff;
  //   border-width: 0 2px 2px 0;
  //   transform: rotate(45deg);
  // }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionChange = (option: Option, index) => {
    setSelectedOption(option);
    // setSelectedaOption(option);
    setShowOptions(false);
    setdropbg(true);
    setselectedOptionIndex(index);
    setSelectedCountry(option.value);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setShowInput(!showInput);
  };

  const handleMouseEnter = (index) => {
    setdropHover(true);
    setHoveredOptionIndex(index);
  };

  const handleMouseLeave = () => {
    setdropHover(false);
    setHoveredOptionIndex(null);
  };
  const [selectedCountry, setSelectedCountry] = useState("");

  const getExampleCountryCode = () => {
    const selectedOption = options.find(
      (option) => option.value === selectedCountry
    );
    return selectedOption ? selectedOption.countrycode : "";
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <input
          className="mx-2"
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          style={{
            width: "10px",
            height: "10px",
            border: "2px solid #80868B",
            borderRadius: "3px",
            padding: "0",
            margin: "0",
            transform: "scale(2)",
            // appearance: "none",
            MozAppearance: "none",
            // WebkitAppearance: "none"
          }}
        />

        {/* <input
  type="checkbox"
  checked={isChecked}
  onChange={handleCheckboxChange}
  style={{
    width: "20px",
    height: "20px",
    border: "2px solid #80868B",
    borderRadius: "3px",
    padding: "0",
    margin: "0",
    appearance: "none",
    MozAppearance: "none",
    WebkitAppearance: "none",
  }}
/> */}

        <span style={{ marginLeft: "15px" }}>{label}</span>
      </div>
      {isChecked && showInput && (
        <>
          {label === "Phone calls" && (
            <div>
              <div
                className="d-flex"
                style={{ marginLeft: "30px", position: "relative" }}
                ref={dropdownRef}
              >
                <div
                  ref={divRef}
                  className="py-5 px-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "150px",
                    height: "50px",
                    borderRadius: "5px",
                    border: isCountryClicked ? "3px solid #0d6efd" : "1px solid #80868B",
                    cursor: "pointer",
                    color: "#3c4043",
                    outline: "none",
                    transition: "border-color 2.0 ease-in-out",
                    // borderColor: showOptions ? "blue" : "#80868B",
                    // borderWidth: showOptions ? "2px" : "1px",
                  }}
                  onClick={handleCountryClick}

                >
                  <div className="d-flex align-items-center"
                    style={{
                      flex: 1,
                      padding: "0 5px",
                      color: "#5f6368",
                      fontSize:'12px',
                    }}
                    onClick={() => setShowOptions(!showOptions)}

                  >
                    {selectedOption?.label?.length > 15
                                  ? `${selectedOption?.label?.slice(0, 15)}...`
                                  : selectedOption?.label || "United States"}
                  </div>
                  <IoMdArrowDropdown size={22}
                    style={{
                      marginLeft: "5px",
                      color: "#5f6368",
                    }}
                  />
                </div>
                {showOptions && (
                  <ul
                    style={{
                      position: "absolute",
                      // top: '-155%',
                      bottom: "-60%",
                      left: "-1%",
                      width: "30%",
                      padding: "10px",
                      margin: "10px",
                      height: "200px",
                      listStyle: "none",
                      background: "#fff",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      borderRadius: "5px",
                      zIndex: 9,
                      overflow: "auto",
                    }}
                  >
                    {options.map((option, index) => (
                      <li
                        key={option.value}
                        style={{
                          padding: "10px",
                          cursor: "pointer",
                          borderBottom: "0px solid #ced4da",
                          backgroundColor:
                            selectedOptionIndex === index
                              ? "#E8F0FE"
                              : hoveredOptionIndex === index
                                ? "#EFEFEF"
                                : "white",

                          // backgroundColor:'white',
                        }}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleOptionChange(option, index)}
                      // onChange={(selectedOption) => setSelectedCountry(selectedOption)}
                      >
                        {option.label}
                      </li>
                    ))}
                  </ul>
                )}
                <div>
                  {showInput && (
                    <div style={{ position: "relative", marginLeft: "10px" }}>
                      <input
                        placeholder="Phone Number"
                        type="number"
                        style={{
                          padding: "10px",
                          paddingRight: "55px",
                          width: "200px",
                          height: "50px",
                          borderRadius: "5px",
                          fontSize: "13px",
                          border: "1px solid #80868B", // Set initial border to transparent
                          borderColor: "#80868B", // Set initial border color
                          borderWidth: "1px", // Set initial border width

                          outline: "none", // Remove default outline style
                          transition: "border-color 0.3s ease-in-out", // Add transition effect
                        }}
                        onChange={(e) => setInputValue(e.target.value)}
                        onFocus={(e) => {
                          e.target.style.borderColor = "#0d6efd"; // Change border color on focus
                          e.target.style.borderWidth = "3px"; // Change border width on focus
                        }}
                        onBlur={(e) => {
                          e.target.style.borderColor = "#80868B"; // Reset border color on blur
                          e.target.style.borderWidth = "1px"; // Reset border width on blur
                        }}
                      />

                      <div
                        className="px-4"
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",

                          //  zIndex: '9999',
                        }}
                      >
                        <CustomQuestion>
                          Enter the phone number you'd like to show in your ad.
                        </CustomQuestion>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className=""
                style={{
                  marginLeft: "195px",
                  color: "#5f6368",
                  fontSize: "13px",
                  marginTop: "2px",
                }}
              >
                Example:{" "}
                {selectedCountry ? getExampleCountryCode() : " (201) 555-0123"}
              </div>
            </div>
          )}

          {label === "Website visits" && (
            <div style={{ position: "relative", marginLeft: "30px" }}>
              <input
                placeholder="Your business's website"
                style={{
                  padding: "10px",
                  paddingLeft: "55px",
                  width: "360px",
                  height: "50px",
                  borderRadius: "5px",
                  fontSize: "13px",
                  border: "1px solid #80868B", // Set initial border to transparent
                  borderColor: "#80868B", // Set initial border color
                  borderWidth: "1px", // Set initial border width
                  color: "black",
                  outline: "none", // Remove default outline style
                  transition: "border-color 0.3s ease-in-out", // Add transition effect
                }}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const dotIndex = inputValue.indexOf(".");

                  if (
                    inputValue === "" ||
                    (dotIndex !== -1 &&
                      dotIndex !== 0 &&
                      dotIndex !== inputValue.length - 1)
                  ) {
                    setInputvalid(true);
                    e.target.style.borderColor = "#0d6efd";
                    e.target.style.borderWidth = "2px"; // Set border color to initial color if input is empty or dot is present with text before and after
                  } else {
                    setInputvalid(false);
                    e.target.style.borderColor = "80868B";
                    e.target.style.borderWidth = "1px";
                    // Set border color to red if input is not empty and dot is not present or not in the correct position
                  }

                  setInputValue(inputValue);
                }}
                onFocus={(e) => {
                  const inputValue = e.target.value;
                  const dotIndex = inputValue.indexOf(".");

                  if (
                    inputValue === "" ||
                    (dotIndex !== -1 &&
                      dotIndex !== 0 &&
                      dotIndex !== inputValue.length - 1)
                  ) {
                    e.target.style.borderColor = "#0d6efd"; // Set border color to initial color if input is empty or dot is present with text before and after
                  } else {
                    e.target.style.borderColor = "red"; // Set border color to red if input is not empty and dot is not present or not in the correct position
                  }

                  e.target.style.borderWidth = "3px"; // Change border width on focus
                }}
                onBlur={(e) => {
                  const inputValue = e.target.value;
                  const dotIndex = inputValue.indexOf(".");

                  if (
                    inputValue === "" ||
                    (dotIndex !== -1 &&
                      dotIndex !== 0 &&
                      dotIndex !== inputValue.length - 1)
                  ) {
                    e.target.style.borderColor = "#3c4043"; // Reset border color on blur
                  } else {
                    e.target.style.borderColor = "red";
                  }
                  e.target.style.borderWidth = "1px"; // Reset border width on blur
                }}
              />

              <div
                className="px-4"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "10px",
                  transform: "translateY(-50%)",
                  color: "#888888",
                  pointerEvents: "none", // Ignore pointer events to allow clicking the input
                }}
              >
                <IoMdLink size={25} />
              </div>
            </div>
          )}
          {!inputvalid && (
            <div
              className="px-10"
              style={{
                color: "red",
                fontSize: "12px",
                marginTop: "5px",
              }}
            >
              Enter a valid URL (ex. www.example.com)
            </div>
          )}
        </>
      )}
      {isChecked && label === "Store visits" && (
        <p style={{ marginLeft: "38px", fontSize: "13px" }}>
          Enter location on the next step
        </p>
      )}
    </div>
  );
};

const CheckBox: React.FC = () => {
  const [showInput1, setShowInput1] = useState(false);
  const [showInput2, setShowInput2] = useState(false);
  const [showRadioButtons, setShowRadioButtons] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isInputEnabled, setIsInputEnabled] = useState(false);
  const [isSearchIconVisible, setIsSearchIconVisible] = useState(true);
  const handleAllDownloadsCheckboxChange = () => {
    setShowRadioButtons(!showRadioButtons);
    setIsInputEnabled(false);
  };
  const handleRadioChange = (e) => {
    const { value } = e.target;
    setIsInputEnabled(value !== "");
  };
  const handleInputMouseEnter = () => {
    setIsSearchIconVisible(false);
  };

  const handleInputMouseLeave = () => {
    setIsSearchIconVisible(true);
  };
  return (
    <>
      <div
        className="container px-10 py-7 mt-10"
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="row gy-5 gx-xl-10">
          <div className="px-5 pb-4 d-flex align-items-center">
            <div
              className="me-3"
              style={{ color: "#5f6368", fontSize: "14px" }}
            >
              Select the ways you would like to reach your goal{" "}
            </div>
            <CustomQuestion>
              This step helps customize your campaign setup to focus on settings
              and features that can help you get the customer actions that
              matter most to your business.
            </CustomQuestion>
          </div>
          <div className="text-dark-400 mt-1 fw-semibold fs-6">
            <div className="mx-4">
              <Checkbox
                label="Website visits"
                showInput={showInput1}
                setShowInput={setShowInput1}
              />
              <Checkbox
                label="Phone calls"
                showInput={showInput2}
                setShowInput={setShowInput2}
              />
              <Checkbox
                label="Store visits"
                showInput={false}
                setShowInput={() => { }}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // cursor: showRadioButtons ? "pointer" : "default",
                    // cursor: showRadioButtons ? "pointer" : "default",
                    // pointerEvents: showRadioButtons ? "auto" : "none",
                  }}
                >
                  <input
                    // className='form-check-input'
                    type="checkbox"
                    // id='kt_checkbox_3'
                    checked={showRadioButtons}
                    onChange={handleAllDownloadsCheckboxChange}
                    onClick={(e) => e.stopPropagation()} // Prevent click event propagation
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "0",
                      marginLeft: "6px",
                      // marginRight: "100px",
                      transform: "scale(2)",
                    }}
                  />

                  {/* <input
                    className="mx-2"
                    type="checkbox"
                  
     
                    style={{
                      width: "10px",
                      height: "10px",
                      borderWidth: "5px",
                      transform: "scale(2)",
                      // pointerEvents: "none",
                      // cursor:'pointer'
                    }}
                  /> */}
                  <div style={{ marginLeft: "22px", color: "#3c4043" }}>
                    App downloads
                  </div>
                </div>
                {showRadioButtons && (
                  <div className="" style={{ marginLeft: "38px" }}>
                    <div
                      className="fs-6 fw-semibold my-5"
                      style={{ color: "#5f6368", fontWeight: "200px" }}
                    >
                      Select your mobile app's platform
                    </div>
                    <div className="mb-5">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "10px",
                        }}
                      >
                        <input
                          type="radio"
                          name="download-option"
                          value="option1"
                          style={{
                            width: "11px",
                            height: "11px",
                            fontWeight: "bold",
                            marginRight: "15px",
                            marginLeft: "15px",
                            transform: "scale(2)",
                          }}
                          onChange={handleRadioChange}
                        />
                        <span>iOS</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "10px",
                          marginTop: "15px",
                        }}
                      >
                        <input
                          type="radio"
                          name="download-option"
                          value="option2"
                          style={{
                            width: "11px",
                            height: "11px",
                            fontWeight: "bold",
                            marginRight: "15px",
                            marginLeft: "15px",
                            transform: "scale(2)",
                          }}
                          onChange={handleRadioChange}
                        />
                        <span>Android</span>
                      </div>

                      <div
                        className="my-5"
                        style={{ color: "#5f6368", fontWeight: "200px" }}
                      >
                        Look up your app
                      </div>
                      <div className="mb-7" style={{ position: "relative" }}>
                        <input
                          placeholder="Enter the app name, package name or publisher"
                          style={{
                            padding: "10px",
                            paddingLeft: "45px",
                            width: "360px",
                            height: "50px",
                            borderRadius: "5px",
                            border: isInputEnabled
                              ? "1px solid black"
                              : "1px solid #ced4da",
                            color: isInputEnabled ? "black" : "#E1DDDD",
                            outline: "none",
                            cursor: isInputEnabled ? "auto" : "not-allowed",
                            fontSize: "13px",
                          }}
                          onFocus={(e) => {
                            e.target.style.borderColor = isInputEnabled
                              ? "#0d6efd"
                              : "lightgrey"; // Change border color on focus
                            e.target.style.borderWidth = isInputEnabled
                              ? "3px"
                              : "1px"; // Change border width on focus
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = isInputEnabled
                              ? "#80868B"
                              : ""; // Reset border color on blur
                            e.target.style.borderWidth = "1px"; // Reset border width on blur
                          }}
                          onChange={(e) => setInputValue(e.target.value)}
                          readOnly={!isInputEnabled}
                          onMouseEnter={handleInputMouseEnter}
                          onMouseLeave={handleInputMouseLeave}
                        />
                        {/* {inputValue === "" && ( */}
                        <div
                          className="px-4"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "5px",
                            transform: "translateY(-50%)",
                            color: "#888888",
                            pointerEvents: "none", // Ignore pointer events to allow clicking the input
                          }}
                        >
                          <AiOutlineSearch size={22} />
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckBox;
