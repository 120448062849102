function Modal({ isOpen, onClose, title, children, width="100%" }) {
  return (
    isOpen && (
      <div
        className="modal fade show"
        id="confirmationModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="confirmationModalLabel"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          right: 0,
          width: width,
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.9)",
          zIndex: 9999,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="modal-dialog" role="document">
          <div
            className="modal-content"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxHeight: "700px",
              backgroundColor: "#FFFFFF",
              zIndex: 9999,
            }}
          >
            <div className="modal-header mb-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  {title}
                </span>
              </h3>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={onClose}
              ></button>
            </div>
            <div className="tab-content" style={{ overflowY: "auto" }}>
              {children}
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default Modal;
