import { useState, useRef, useEffect } from "react";
import { BsBox2, BsChevronDown, BsChevronUp, BsX } from "react-icons/bs";
import { KTCard } from "../../../../theme/helpers";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsCurrencyRupee } from "react-icons/bs";
import { BsExclamationCircle } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IoMdLink } from "react-icons/io";
import { MdCurrencyRupee, MdOutlineCurrencyRupee } from "react-icons/md";
// import { useFormik } from "formik";
import * as Yup from "yup";
import CustomQuestion from "./customQuestion";
import { campaignKeywords } from "../../../modules/application/_requests";

const Keywords = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);

  const [type, setType] = useState<string>("Conversions");
  const [status, setStatus] = useState<string>("Conversions");
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [inputvalid, setInputvalid] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [searchInputValue, setsearchInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [headerColor, setHeaderColor] = useState("");
  const [headerColor2, setHeaderColor2] = useState("");
  const [iconColor, setIconColor] = useState("black");
  const [popupVisible, setPopupVisible] = useState(false);
  const [popup, setPopup] = useState(false);
  const [popupHead, setPopupHead] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [empty, setempty] = useState(true);
  const [selectedPopUpName, setSelectedPopUpName] = useState("");
  // const [inputValue, setInputValue] = useState('');
  const [cards, setCards] = useState<string[]>([]);

  const handleMouseEnter = () => {
    // setIsPopupVisible(true);
    setIsPopupVisible((prevIsPopUpVisible) => !prevIsPopUpVisible);
    setIconColor("#0096FF");
    setPopupVisible(true);
  };

  const handleMouseLeave = () => {
    setIsPopupVisible(false);
    setIconColor("");
    setPopupVisible(false);
  };
  const navigate = useNavigate();

  const handleMouseEnter1 = (name) => {
    setSelectedPopUpName(name);
    setPopup(true);
    setPopupHead(false);
    setIsPopupVisible(false);
  };
  const handleMouseEnter2 = () => {
    setPopup(false);
    setPopupHead(true);
    setIsPopupVisible(false);
  };
  const [showPopup, setShowPopup] = useState(false);

  const handleMouseEnter3 = () => {
    setShowPopup(true);
  };

  const handleMouseLeave3 = () => {
    setShowPopup(false);
  };

  const { id } = useParams();
  console.log(id, "params data");
  const location = useLocation();
  const idd: any = location.state;

  console.log(idd, "keyword btao");
  console.log(typeof idd, "typeof");
 
  const handleClick = (values: any) => {
	
		const savedkeyworddata: any = 
   {
      "campaign_id": idd?.campaignId,
      "ScanURL":inputValue,
      "ProductsServices":cards,
      "Search":searchInputValue
      }
      
     
		// const file = values.fileName[0];
    console.log(savedkeyworddata,"keyyyyyy")
		const response = campaignKeywords(savedkeyworddata)
    
			.then((data) => {
				
				console.log("Keyword Added Successfully!");
        console.log(data,"keyword")
        const cid= idd?.campaignId;
        console.log("fhdjkfhdkfhdkfhsdkfhsdkjfsdf",cid)
       
        navigate("/budget", { state: { cid } });
				setTimeout(() => {
					console.log(undefined);
				}, 2000);
       
        // navigate("/budget");
			})
			.catch((err) => {
				alert("Request Failed!");

			});
      // navigate("/budget");
	};



  const handleToggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  const handleToggleContent2 = () => {
    setIsExpanded2(!isExpanded2);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleCheckbox2 = () => {
    setIsChecked2(!isChecked2);
  };



  // const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (e.key === 'Enter' && inputValue.trim() !== '') {
  //     setCards([...cards, inputValue.trim()]);
  //     setInputValue('');
  //   }
  // };

  // const removeCard = (index: number) => {
  //   const updatedCards = [...cards];
  //   updatedCards.splice(index, 1);
  //   setCards(updatedCards);
  // };

  // const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
  //   const parentElement = e.target.parentNode as HTMLElement;
  //   parentElement.style.borderColor = '#0d6efd';
  //   parentElement.style.borderWidth = '2px';
  // };

  // const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
  //   const parentElement = e.target.parentNode as HTMLElement;
  //   parentElement.style.borderColor = '#3c4043';
  //   parentElement.style.borderWidth = '1px';
  // };




  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleInputFocus = () => {
    const containerElement = containerRef.current;
    if (containerElement) {
      containerElement.style.borderColor = '#0d6efd';
      containerElement.style.borderWidth = '2px';
    }
  };

  const handleInputBlur = () => {
    const containerElement = containerRef.current;
    if (containerElement) {
      containerElement.style.borderColor = '#3c4043';
      containerElement.style.borderWidth = '1px';
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.style.height = '50px';
      e.preventDefault();
      const value = e.currentTarget.value.trim();
      if (value !== '') {
        setCards((prevCards) => [...prevCards, value]);
        setInputValue2(value);
        setempty(false);
        e.currentTarget.style.height = '50px';
      }
    }
  };
console.log(cards,"caaaaaaaaaaaarrrrrrrrrrr")
  const removeCard = (index: number) => {
    setCards((prevCards) => prevCards.filter((_, i) => i !== index));
  };


  return (
    <>
      <div className="container px-10">
        <div className="px-10"></div>
        <div
          className="card "
          style={{
            marginBottom: isExpanded ? "15px" : "0px",
            backgroundColor: isExpanded ? "" : "#E1E2E3",
            boxShadow: isExpanded ? '0 2px 8px rgba(0, 0, 0, 0.5)' : '',
          }}
        >
          <div
            className="card-header"
            onClick={handleToggleContent}
            onMouseOver={() => setHeaderColor("#E1E2E3")}
            onMouseOut={() => setHeaderColor("")}
            style={{
              backgroundColor: headerColor || "#FFFFFF", // Set default color to white
              cursor: "pointer",
              border: '1px solid #ced4da',
              transition: "background-color 0.3s ease",
              minHeight: "48px",
              borderRadius: '5px',
            }}
          >
            <h5 className="card-title">Keywords</h5>

            {isExpanded ? (
              <BsChevronUp
                className="arrow-icon"
                style={{ marginTop: "20px" }}
              />
            ) : (
              <BsChevronDown
                className="arrow-icon"
                style={{ marginTop: "20px" }}
              />
            )}
          </div>
          {isExpanded && (
            <div className="body px-7 py-7">
              <div className="">

                <div
                  className="container"
                  style={{ backgroundColor: "white", borderRadius: "10px" }}
                >

                  <div
                    className="row"
                  // style={{ marginLeft: "-86px" }}
                  >
                    <span
                      className="text-dark fw-semibold"
                      style={{ fontSize: "14px", color: "#3C4043", lineHeight: '7px' }}
                    >
                      <p>Get keyword suggestions (optional)
                      </p>


                    </span>
                    <span
                      className=""
                      style={{ fontSize: "14px", color: "#5f6368", fontFamily: 'sans-serif', lineHeight: '14px' }}
                    >
                      <p>Google Ads can find keywords for you by scanning a web page or
                        seeing what's working for similar products or services </p>
                    </span>


                    {/* first input */}

                    <div style={{ position: "relative", marginTop: '7px' }}>
                      <input
                        placeholder="Enter a URL to scan for keywords"
                        style={{
                          padding: "10px",
                          paddingLeft: "55px",
                          width: "52%",
                          height: "50px",
                          borderRadius: "5px",
                          fontSize: "13px",
                          border: "1px solid #3c4043", // Set initial border color
                          borderColor: "#3c4043", // Set initial border color
                          borderWidth: "1px", // Set initial border width
                          color: "black",
                          outline: "none", // Remove default outline style
                          transition: "border-color 0.3s ease-in-out", // Add transition effect
                        }}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const dotIndex = inputValue.indexOf(".");

                          if (inputValue === "" || (dotIndex !== -1 && dotIndex !== 0 && dotIndex !== inputValue.length - 1)) {
                            setInputvalid(true);
                            e.target.style.borderColor = "#0d6efd"; // Set border color to initial color if input is empty or dot is present with text before and after
                          } else {
                            setInputvalid(false);
                            e.target.style.borderColor = "red"; // Set border color to red if input is not empty and dot is not present or not in the correct position
                          }

                          setInputValue(inputValue);
                        }}
                        onFocus={(e) => {
                          const inputValue = e.target.value;
                          const dotIndex = inputValue.indexOf(".");

                          if (inputValue === "" || (dotIndex !== -1 && dotIndex !== 0 && dotIndex !== inputValue.length - 1)) {

                            e.target.style.borderColor = "#0d6efd"; // Set border color to initial color if input is empty or dot is present with text before and after
                          } else {
                            e.target.style.borderColor = "red"; // Set border color to red if input is not empty and dot is not present or not in the correct position

                          }


                          e.target.style.borderWidth = "2px"; // Change border width on focus
                        }}
                        onBlur={(e) => {

                          const inputValue = e.target.value;
                          const dotIndex = inputValue.indexOf(".");

                          if (inputValue === "" || (dotIndex !== -1 && dotIndex !== 0 && dotIndex !== inputValue.length - 1)) {
                            e.target.style.borderColor = "#3c4043"; // Reset border color on blur

                          }


                          else {
                            e.target.style.borderColor = "red";
                          }
                          e.target.style.borderWidth = "1px"; // Reset border width on blur
                        }}
                      />

                      <div
                        className="px-4"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "17px",
                          transform: "translateY(-50%)",
                          color: "#5F6368",
                          pointerEvents: "none", // Ignore pointer events to allow clicking the input
                        }}
                      >
                        <IoMdLink size={25} />
                      </div>
                    </div>
                    {!inputvalid && (
                      <div className="px-7"
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "5px",
                        }}
                      >
                        Enter a valid URL (ex. www.example.com)
                      </div>
                    )}



                    {/* demo second input */}
                    {/* <div style={{ position: 'relative', marginTop: '25px', width:'53%' }}>
                      <div
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '35px',
                          transform: 'translateY(-50%)',
                          color: '#5F6368',
                          pointerEvents: 'none',
                        }}
                      >
                        <BsBox2 size={20} />
                      </div>

                      <div
                        ref={containerRef}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          border: '1px solid #3c4043',
                          borderRadius: '5px',
                          paddingLeft: '55px',
                          height:'auto',
                          transition: 'border-color 0.3s ease-in-out',
                          overflow:'auto'
                        }}
                      >
                        {cards.map((card, index) => (
                          <div className="d-flex"
                            key={index}
                            style={{
                              margin: '5px',
                              padding: '5px 10px',
                              borderRadius: '30px',
                              borderWidth: '1px',
                              borderStyle: 'solid',
                              borderColor: '#3c4043',
                            }}
                          >
                            {card}
                            <BsX
                              size={20}
                              style={{
                                marginLeft: '2px',
                                cursor: 'pointer',
                                color: '#5F6368',
                              }}
                              onClick={() => removeCard(index)}
                            />
                          </div>
                        ))}

                        <textarea
                          ref={textareaRef}
                          placeholder="Enter products or services to advertise"
                          style={{
                            // padding: '10px',
                            width: '100%',
                            minHeight: '50px',
                            borderRadius: '5px',
                            fontSize: '13px',
                            border: 'none',
                            color: 'black',
                            outline: 'none',
                          }}
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                          onKeyPress={handleKeyPress}
                          onFocus={handleInputFocus}
                          onBlur={handleInputBlur}
                        />
                      </div>
                    </div> */}

                    {/* second input */}

                    <div style={{ position: 'relative', marginTop: '25px', width: '53%' }}>
                      <div
                        style={{
                          position: 'absolute',
                          left: '30px',
                          top: '15px',
                          marginTop: '15px',
                          color: '#5F6368',
                          pointerEvents: 'none',
                        }}
                      >
                        <BsBox2 size={20} />
                      </div>

                      <div
                        className="d-flex flex-grow align-items-start"
                        ref={containerRef}
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          border: '1px solid #3c4043',
                          borderRadius: '5px',
                          paddingLeft: '40px',
                          minHeight: '50px',
                          marginTop: '15px',
                          width: '100%',
                          overflowY: 'auto', // Enable vertical scrolling
                          flexWrap:'wrap'
                        }}
                      >

                        {/* <div
    className="d-flex align-items-center"
    ref={containerRef}
    style={{
      border: '1px solid #3c4043',
      borderRadius: '5px',
      paddingLeft: '40px',
      minHeight: '50px',
      marginTop: '15px',
    }}
  /> */}
                        {/* <div
    className="d-flex align-items-center"
    ref={containerRef}
    style={{
      border: '1px solid #3c4043',
      borderRadius: '5px',
      paddingLeft: '40px',
      flexWrap: 'wrap',
      minHeight: '50px',
      marginTop: '15px',
    }}
  > */}
                        {/* {inputValue2 != ""?
                       (     <>                */}
                        {!(inputValue2 === '' && cards.length === 0) && (
                          cards.map((card, index) => (
                            <div
                              className="d-flex"
                              key={index}
                              style={{
                                margin: '12px',
                                padding: '5px 10px',
                                borderRadius: '30px',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                borderColor: '#3c4043',
                                maxWidth: '100%',
                                backgroundColor: 'white',
                                color: '#5f6368'
                              }}
                              onMouseEnter={(e) => {
                                (e.target as HTMLButtonElement).style.backgroundColor = "#F5F5F5";
                                (e.target as HTMLButtonElement).style.color = "#010000";
                              }}
                              onMouseLeave={(e) => {
                                (e.target as HTMLButtonElement).style.backgroundColor = "white";
                                (e.target as HTMLButtonElement).style.color = "#5f6368";
                              }}
                            >
                              {card.length > 30 ? `${card.slice(0, 30)}...` : card}
                              <BsX
                                size={20}
                                style={{
                                  marginLeft: '2px',
                                  cursor: 'pointer',
                                }}
                                onClick={() => removeCard(index)}
                              />
                            </div>
                          ))
                        )}

                        {/* </> ):(null) */}
                        {/* } */}




                        <textarea
                          className="p-5 d-flex"
                          ref={textareaRef}
                          placeholder="Enter products or services to advertise"
                          style={{
                            padding: '10px',
                            width: '100%',
                            borderRadius: '5px',
                            fontSize: '13px',
                            border: 'none',
                            color: 'black',
                            outline: 'none',
                            transition: 'border-color 0.3s ease-in-out',
                            resize: 'none',
                            whiteSpace: 'nowrap', // Prevent text from wrapping
                          }}
                          // style={{
                          //   padding: '10px',
                          //   width: 'calc(100% - 55px)',
                          //   borderRadius: '5px',
                          //   fontSize: '13px',
                          //   border: 'none',
                          //   color: 'black',
                          //   outline: 'none',
                          //   transition: 'border-color 0.3s ease-in-out',
                          //   resize: 'none',
                          //   overflow: 'auto',
                          // }}
                          value={inputValue2}
                          onChange={(e) => setInputValue2(e.target.value)}
                          onKeyDown={handleKeyDown}
                          onFocus={handleInputFocus}
                          onBlur={handleInputBlur}
                        />
                      </div>
                    </div>




                    {/* new 2nd input demo */}

                    {/* <div style={{ position: 'relative', marginTop: '25px', width:'53%', height: 'auto',
                             }}>
                      <div
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '30px',
                          transform: 'translateY(-50%)',
                          color: '#5F6368',
                          pointerEvents: 'none',
                        }}
                      >
                        <BsBox2 size={20} />
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          border: '1px solid #3c4043',
                          borderRadius: '5px',
                          paddingLeft: '55px',
                        }}
                      >
                        {cards.map((card, index) => (
                          <div className="d-flex"
                            key={index}
                            style={{
                              margin: '5px',
                              padding: '5px 10px',
                              borderRadius: '30px',
                              borderWidth: '1px',
                              borderStyle: 'solid',
                              borderColor: '#3c4043',
                            }}
                          >
                            {card}
                            <BsX size={20}
                              style={{
                                marginLeft: '5px',
                                cursor: 'pointer',
                                color: '#5F6368',
                              }}
                              onClick={() => removeCard(index)}
                            />
                          </div>
                        ))}

                        <input
                          placeholder="Enter products or services to advertise"
                          style={{
                            // padding: '10px, 0',
                            width: '100%',
                            height:'100%',
                            borderRadius: '5px',
                            fontSize: '13px',
                            border: 'none',
                            color: 'black',
                            outline: 'none',
                            transition: 'border-color 0.3s ease-in-out',
                          }}
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                          onKeyPress={handleKeyPress}
                          onFocus={handleInputFocus}
                          onBlur={handleInputBlur}
                        />
                      </div>
                    </div> */}

                    {/* {inputValue2 ? ( */}


                    {/* button */}


                    <div className='d-flex'
                      style={{
                        marginLeft: '-10px',
                        // width: "217.69px",
                        // height: "35.78px", 
                        // cursor: inputValue2 === '' && cards.length === 0 ? "not-allowed" : "pointer",
                      }}>
                      <div className="button nav-link btn btn-sm btn-color-primary btn-active btn-active-light fw-bold mx-3 my-7"
                        style={{
                          width: "217.69px",
                          height: "35.78px",
                          // width: "100%",
                          // height: "100%",
                          borderRadius: "5px",
                          border: "1px solid #dadce0",
                          cursor: inputValue2 === '' && cards.length === 0 ? "not-allowed" : "pointer",
                          color: inputValue2 === '' && cards.length === 0?  '#dadce0':"#1a73e8",
                          transition: "background-color 0.3s ease",
                          backgroundColor: "white", // Set the initial background color
                        }}
                        onMouseEnter={(e) => {
                          (e.target as HTMLButtonElement).style.backgroundColor =inputValue2 === '' && cards.length === 0?'white': "#F1F3F4";
                          (e.target as HTMLButtonElement).style.color =inputValue2 === '' && cards.length === 0?  '#dadce0':"#1153aa"; // Change the background color on hover
                          // (e.target as HTMLButtonElement).style.cursor= inputValue2 === '' && cards.length === 0 ? "not-allowed" : "pointer";
                        }}
                        onMouseLeave={(e) => {
                          (e.target as HTMLButtonElement).style.backgroundColor = "white"; // Restore the initial background color
                          (e.target as HTMLButtonElement).style.color = inputValue2 === '' && cards.length === 0?'#dadce0':"#1a73e8"; // Change the background color on hover
                        }}
                        // readOnly={inputValue2 === '' && cards.length === 0}
                      >
                        Get Keyword Suggestions
                      </div>

                    </div>
                    {/* // ) : null} */}

                    <span
                      className="text-dark fw-semibold mt-3"
                      style={{ fontSize: "14px", color: "#3C4043", lineHeight: '7px' }}
                    >
                      <p>Enter Keywords
                      </p>


                    </span>

                    <span
                      className="d-flex"
                      style={{ fontSize: "14px", color: "#5f6368", fontFamily: 'sans-serif', lineHeight: '10px' }}
                    >
                      <p style={{ marginRight: '10px' }}>Keywords are words or phrases that are used to match your ads with the terms people are searching for</p>
                      <div style={{ marginTop: '-3px' }}>
                        <CustomQuestion>
                          <p>Use keyword match types to help control which searches can trigger ads</p>

                          <p> keyword = Broad match</p>

                          <p> "keyword" = Phrase match</p>

                          <p>[keyword] = Exact match
                          </p>
                          <br />
                          <u style={{ color: '#009EF7' }}><a href="#"> Learn more</a></u>
                        </CustomQuestion>
                      </div>

                    </span>

                    {/* third input */}

                    <div style={{ position: "relative", marginTop: '5px' }}>
                      <textarea className="px-7 py-5"
                        placeholder="Enter or paste keywords. You can separate each keyword by commas or enter one per line."
                        style={{

                          width: "100%",
                          height: "255.78px",
                          borderRadius: "5px",
                          fontSize: "13px",
                          border: "1px solid #3c4043", // Set initial border to transparent
                          borderColor: "#3c4043", // Set initial border color
                          borderWidth: "1px", // Set initial border width
                          color: "#80868b",
                          outline: "none", // Remove default outline style
                          transition: "border-color 0.3s ease-in-out", // Add transition effect
                          overflow: 'auto'
                        }}
                        onChange={(e) => setsearchInputValue(e.target.value)}
                        onFocus={(e) => {
                          e.target.style.borderColor = "#0d6efd"; // Change border color on focus
                          e.target.style.borderWidth = "2px"; // Change border width on focus
                        }}
                        onBlur={(e) => {
                          e.target.style.borderColor = "#3c4043"; // Reset border color on blur
                          e.target.style.borderWidth = "1px"; // Reset border width on blur
                        }}
                      />
                    </div>



                  </div>
                </div>

              </div>
            </div>
          )}
        </div>


        <div className="">
          <div
            className="gap-2 my-5 d-flex"
            style={{ justifyContent: "flex-end" }}
          >
            <a
              href="#"
              className="btn btn-sm fw-bold btn-primary"
              onClick={handleClick}
              style={{ fontSize: "14px" }}
            >
              Next
            </a>
            {/* <Biding/> */}
          </div>
        </div>
      </div >
    </>
  );
};

export default Keywords;
