/** @format */

import React, { useEffect, useState } from "react";
import BarGraph from "../../../../../app/pages/dashboard/components/BarGraph";
import { useLocation, useParams } from "react-router-dom";
import GenderDonutChart from "../../../../partials/widgets/charts/GenderDonutChart";
import TopListing from "../../../../../app/pages/dashboard/capaign_components/TopListing";
import IndiaMapChart from "../../../../../app/pages/dashboard/capaign_components/IndiaMapChart";
import { TopKeyProgress } from "../../../../../app/pages/dashboard/capaign_components/TopKeyProgress";
import AmChartHeatMapChart from "../../../../../app/pages/dashboard/components/AmchartHeatMapChart";
import AmBubbleChart from "../../../../../app/pages/dashboard/components/AmBubbleChart";
import { DateRangePicker } from "materialui-daterange-picker";
import AmAreaGraph from "../../../../../app/pages/dashboard/components/AmAreaGraph";
import TrendsComponent from "../../../../../app/pages/dashboard/marketting_component/TrendsComponent";
import AmFunnelChart from "../../../../../app/pages/dashboard/marketting_component/AmFunnelChart";
import { AiOutlineFieldTime } from "react-icons/ai";
import CustomDateRangePicker from "../../../../../app/pages/dashboard/Custom_Components/CustomDateRangePicker";
import HeaderDropdown from "../../../../../app/pages/dashboard/Custom_Components/HeaderDropdown";
import {
  URLS,
  getMethodDataFromBackend,
} from "../../../../../app/modules/dashboards/DashboardRequests";

export default function CampaignDashboard() {
  const params = useLocation();
  console.log("params in campaign", params.state);
  const channelId = params?.state?.item?.campaign
    ? params?.state?.item?.campaign
    : params?.state?.item?.campaignName;
  const [platformId, setPlatformId] = useState<string>(
    params?.state?.platformId
  );
  const [productId, setProductId] = useState<string>(params?.state?.productId);
  const [campaignId, setCampaignId] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("2023-06-01");
  const [endDate, setEndDate] = useState<string>("2023-06-30");
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const [bidPop, setBidPop] = useState(false);
  const [prodHover, setProdHover] = useState(false);
  const [baseLineDropdown, setBaseLineDropdown] = useState(["custom"]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [selectedBaselineValue, setSelectedBaselineValue] = useState("Custom");
  const [selectedItem, setSelectedItem] = useState("Custom");

  const handleDropdownItemClick = (data) => {
    console.log(data);
    setSelectedItem(data);
  };

  useEffect(() => {
    document.title = "Camp AI Gain - Report";
    setDateFilter();
  }, []);

  const setDateFilter = async () => {
    try {
      const response = await getMethodDataFromBackend(URLS.BASE_URL + "campaign/getdates");
      console.log("setDateFilter response:-", response)
      if(response?.data?.dashboardLevel === "Campaign") {
        setStartDate(formatDate(response.data.startDate));
        setEndDate(formatDate(response.data.endDate));
      }
    } catch(err) {

    }
  }

  const formatDate = (inputDate) => {
    // Parse input date string
    const date = new Date(inputDate);
    
    // Extract year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because month index starts from 0
    const day = String(date.getDate()).padStart(2, '0');
    
    // Return formatted date string
    return `${year}-${month}-${day}`;
  }

  const getDate = (currentDate) => {
    const offset = currentDate.getTimezoneOffset();
    let yourDateObj = new Date(currentDate.getTime() - offset * 60 * 1000);
    return yourDateObj.toISOString().split("T")[0];
  };

  const handleDateRangeChange = (dateRange) => {
    setStartDate(getDate(dateRange?.startDate));
    setEndDate(getDate(dateRange?.endDate));
  };

  const getData = async () => {
    try {
      let responseBaseline = await getMethodDataFromBackend(
        URLS.BASE_URL + "baseline/options"
      );

      let data = responseBaseline?.data;

      if (data) {
        console.log("responseBaseline", data);
        setBaseLineDropdown(data);
      } else {
        console.log("No data received from the backend");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const element = document.getElementById("testId4");
      if (element) {
        element.style.position = scrollPosition > 10 ? "fixed" : "absolute";
        element.style.right = "30px";
        // element.style.zIndex = 1;
        element.style.width = "100%";
        element.style.top = scrollPosition > 10 ? "70px" : "85px";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        id="testId4"
        className={`d-flex flex-end`}
        style={{
          position: "absolute",
          right: "30px",
          zIndex: 1,
          width: "100%",
          top:'85px'
        }}
      >
        {/* <HeaderDropdown
          selectedValue={selectedBaselineValue}
          dropdownItems={baseLineDropdown}
          onDropdownItemClick={handleDropdownItemClick}
        /> */}

        <CustomDateRangePicker
          startDate={startDate}
          endDate={endDate}
          onDateRangeChange={handleDateRangeChange}
        />
      </div>

      <TrendsComponent
        platformId={platformId}
        productId={productId}
        startDate={startDate}
        endDate={endDate}
        channelType={"campaign"}
        campaignId={channelId}
        productType={""}
      />

      <div className="row gy-5 g-xl-10">
        <div className="col-xl-6 mb-10">
          <AmFunnelChart
            title={"Reach, Engage and Convert"}
            chartID="funnelChart8"
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            channelType={"campaign"}
            campaignId={channelId}
            subUrl={"/reachengageconvert"}
            platformId={platformId}
            productType={""}
          />
        </div>
        <div className="col-xl-6 mb-10">
          <AmAreaGraph
            chartID={"areaChart"}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            platformId={platformId}
            channelType={"campaign"}
            campaignId={channelId}
          />
        </div>
      </div>

      {/* <div className="row gy-5 g-xl-10">
        <div className="col-xl-4 mb-10">
          <AmChartHeatMapChart
            chartID="heatMap"
            platformId={platformId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            channelType={"campaign"}
            campaignId={channelId}
          />
        </div>
        <div className="col-xl-4 mb-10">
          <AmBubbleChart
            chartID="bubbleChart"
            platformId={platformId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            channelType={"campaign"}
            campaignId={channelId}
          />
        </div>

        <div className="col-xl-4 mb-10">
          <TopKeyProgress
            productId={productId}
            platformId={platformId}
            channelType={"campaign"}
            campaignId={channelId}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </div> */}

      {/* <div className="row gy-5 gx-xl-10 mb-10">
        <div className="col-xl-8">
          <BarGraph
            title={"Age"}
            chartID="agecolumngraph"
            platformId={platformId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            channelType={"campaign"}
            campaignId={channelId}
          />
        </div>
        <div className="col-xl-4">
          <GenderDonutChart
            title={"Gender"}
            chartID="pie-graph"
            platformId={platformId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            channelType={"campaign"}
            campaignId={channelId}
          />
        </div>
      </div> */}

      {/* <div
        className="row gy-5 gx-xl-10 d-flex mb-10"
        style={{ justifyContent: "space-between" }}
      >
        <div className="col-xl-4">
          <TopListing
            platformId={platformId}
            channelType={"campaign"}
            campaignId={channelId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            title={"Top Cities"}
            subTypeUrl={"topcities"}
          />
        </div>
        <div className="col-xl-4">
          <TopListing
            platformId={platformId}
            channelType={"campaign"}
            campaignId={channelId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            title={"Top States"}
            subTypeUrl={"topstates"}
          />
        </div>
        <div className="col-xl-4 pt-0">
          <IndiaMapChart
            chartID={"campaignIndiaMap"}
            height={"400px"}
            marginTop={"0px"}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            platformId={platformId}
            campaignId={channelId}
            productType={""}
          />
        </div>
      </div> */}
    </>
  );
}
