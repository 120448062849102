import { useState } from "react";
import { BsChevronDown, BsChevronRight, BsChevronUp } from "react-icons/bs";
import { KTCard } from "../../../../theme/helpers";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsClock } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FiArrowLeft } from "react-icons/fi";

import { useNavigate } from "react-router-dom";

const AudienceSegment = ({ style, setStyle, styles }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [headerColor, setHeaderColor] = useState("");

  const [select, setSelect] = useState("");
  // const [style, setStyle] = useState<string[]>([]);

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isPopupVisible2, setIsPopupVisible2] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const Stages = ["Search", "Browse"];

  // const styles = [
  //   {
  //     id: "1",
  //     name: "Fashionistas",
  //   },
  //   {
  //     id: "2",
  //     name: "Formal Wears",
  //   },
  //   {
  //     id: "3",
  //     name: "Shoppers",
  //   },
  //   {
  //     id: "4",
  //     name: "Children Aparel's",
  //   },
  //   {
  //     id: "5",
  //     name: "Concert and Music Festivals Tickets",
  //   },
  //   {
  //     id: "6",
  //     name: "Performing Arts Tickets",
  //   },

  //   {
  //     id: "7",
  //     name: "Sports Tickets",
  //   },
  //   {
  //     id: "8",
  //     name: "Financial Services",
  //   },
  // ];

  const browse = [
    {
      id: "1",
      bname: "Who they are",
      captions: "Detailed Dempgraphics",
    },
    {
      id: "2",
      bname: "What there interests and habbits are",
      captions: "Affinity",
    },
    {
      id: "3",
      bname: "What they are actively researching or planning",
      captions: "In-market",
    },
    {
      id: "4",
      bname: "How they have interacted with your buisness",
      captions: "Your data segments",
    },
    {
      id: "5",
      bname: "You combined audience segements",
      captions: "Combined segments",
    },
  ];

  const cards = [
    {
      id: "1",
      cname: "Parental Status",
      captions: "Detailed Dempgraphics",
    },
    {
      id: "2",
      bname: "Marital Status",
      captions: "Affinity",
    },
    {
      id: "3",
      bname: "Education",
      captions: "In-market",
    },
    {
      id: "4",
      bname: "Homeownership Status",
      captions: "Your data segments",
    },
    {
      id: "5",
      bname: "Employment",
      captions: "Combined segments",
    },
  ];
  const target = [
    {
      id: "1",
      targetName: "Targetting",
      sub: " Narrow the reach of your campaign to the selected segments, with the option to adjust the bids",
    },
    {
      id: "2",
      targetName: "Observation (recommended)",
      sub: "Don't narrow the reach of your campaign, with the option to adjust the bids on the selected segments",
    },
  ];

  const [filteredStyles, setFilteredStyles] = useState(styles);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isSearchClicked, setIsSearchClicked] = useState(true);
  const [isBrowseClicked, setIsBrowseClicked] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [selectedBname, setSelectedBname] = useState("");
  const [selectedTab, setSelectedTab] = useState<string>("Search");

  const handleSearchChange = (event) => {
    const searchInput = event.target.value.toLowerCase();
    setSearchValue(searchInput);

    const filteredStyles = styles.filter((styles) =>
      styles.name.toLowerCase().includes(searchInput)
    );
    setFilteredStyles(filteredStyles);
  };

  const selectedStyleCount = style.length;

  const handleSearchClick = () => {
    setIsSearchClicked(true);
    setIsBrowseClicked(false);
    setIsClicked(false);
  };
  const handleBrowseClick = () => {
    setIsBrowseClicked(true);
    setIsSearchClicked(false);
    setIsClicked(false);
  };

  const handleClick = (bname) => {
    setIsClicked(true);
    setIsSearchClicked(false);
    setIsBrowseClicked(false);
    setSelectedBname(bname);
  };

  const handleMouseEnter = (event) => {
    const target = event.target.getAttribute("data-target");

    if (target === "popup1") {
      setIsPopupVisible(true);
    } else if (target === "popup2") {
      setIsPopupVisible2(true);
    }
  };

  const handleOption = (event) => {
    setSelect(event.target.value);
  };

  const handleRemoveLanguage = (languageToRemove) => {
    setStyle((prevLanguages) =>
      prevLanguages.filter((language) => language !== languageToRemove)
    );
  };

  const handleMouseLeave = () => {
    setIsPopupVisible(false);
    setIsPopupVisible2(false);
  };

  const handleCheckboxChange2 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (event.target.checked) {
      setStyle((prevSelectedLanguages) => [...prevSelectedLanguages, value]);
    } else {
      setStyle((prevSelectedLanguages) =>
        prevSelectedLanguages.filter((language) => language !== value)
      );
    }
  };

  const handleSelectAll = () => {
    if (selectAllChecked) {
      setStyle([]);
    } else {
      const allLanguages = filteredStyles.map((language) => language.name);
      setStyle(allLanguages);
    }
    setSelectAllChecked(!selectAllChecked);
  };

  const handleClearAll = () => {
    setStyle([]);
  };

  const handleToggleContent = () => {
    setIsExpanded(!isExpanded);
  };
  const handleToggleContent1 = () => {
    setIsExpanded1(!isExpanded1);
  };

  const handleToggleContent2 = () => {
    setIsExpanded2(!isExpanded2);
  };

  return (
    <>
      <div
        className="card"
        style={{
          marginTop: isExpanded ? "20px" : "0px",
          marginBottom: isExpanded ? "20px" : "0px",
          backgroundColor: isExpanded ? "" : "#E1E2E3",
          boxShadow: isExpanded ? "0 2px 8px rgba(0, 0, 0, 0.5)" : "",
        }}
      >
        <div
          className="card-header d-flex align-items-center"
          onClick={handleToggleContent}
          onMouseOver={() => setHeaderColor("#E1E2E3")}
          onMouseOut={() => setHeaderColor("")}
          style={{
            backgroundColor: headerColor || "#FFFFFF", // Set default color to white
            cursor: "pointer",
            border: "1px solid #ced4da",
            transition: "background-color 0.3s ease",
            minHeight: "48px",
            borderRadius: "5px",
          }}
        >
          <div
            className="row gx-xl-12 gy-5 d-flex align-items-center"
            style={{ width: "100%" }}
          >
            <div className="col-xl-3">
              <h5 className="card-title">Audience segments</h5>
            </div>
            <div className="col-xl-8">
              {!isExpanded ? (
                <h5 className="card-title" style={{ color: "rgba(0,0,0,.54)" }}>
                  {style.length > 0 ? (
                    <>
                      {style.slice(-1).map((style) => (
                        <span
                          className="selected-language"
                          key={style}
                          style={{
                            marginRight: "5px",
                            fontSize: "14px",
                          }}
                        >
                          {style}
                        </span>
                      ))}
                      {style.length > 1 && (
                        <span className="selected-language">
                          + {style.length - 1} more
                        </span>
                      )}
                    </>
                  ) : (
                    "Select audience segements to add to your campaign"
                  )}
                </h5>
              ) : null}
            </div>
            <div className="col-xl-1 d-flex align-items-center justify-content-end">
              {isExpanded ? (
                <BsChevronUp className="arrow-icon" />
              ) : (
                <BsChevronDown className="arrow-icon" />
              )}
            </div>
          </div>
        </div>
        {isExpanded && (
          <div className="card-body">
            <div className="row px-20">
              <div className="col-xl-12">
                <div className="">
                  <KTCard className="card h-lg-100 col-xxl-12">
                    <div
                      className="container p-1"
                      style={{ backgroundColor: "white", borderRadius: "10px" }}
                    >
                      <div
                        className="row gy-5 gx-xl-10"
                        style={{ marginLeft: "-86px" }}
                      >
                        <span
                          className="fw text-grey fs-5"
                          style={{ marginLeft: "0px" }}
                        >
                          Select audience segments to add to your campaign.You
                          can create new segments in{" "}
                          <a href="#">Audience Manager</a>.
                          <AiOutlineQuestionCircle
                            size={15}
                            data-target="popup1"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                          />
                        </span>
                        {isPopupVisible && (
                          <div
                            style={{
                              position: "absolute",
                              top: "2%",
                              left: "41%",
                              // right:"100%",
                              backgroundColor: "#fff",
                              padding: "20px",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                              zIndex: "2",
                              width: "500px",
                              height: "180px",
                              fontSize: "15px",
                              marginLeft: "260px",
                            }}
                          >
                            <div>
                              Audiences are groups of people with specific
                              interests, intents, and demographics, as estimated
                              by Google.
                              <br />
                              <br /> They’re people you can show your ads to.
                              Data from your audience sources may be used to
                              improve the bidding and targeting of your audience
                              campaigns.
                            </div>
                          </div>
                        )}
                        <br />
                        <div style={{ width: "100%", height: "100%" }}>
                          <table
                            className="table gs-0 gy-4 my-0"
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid black",
                              justifyContent: "center",
                              // position: "fixed",
                            }}
                          >
                            <colgroup>
                              <col style={{ width: "50%" }} />
                              <col style={{ width: "50%" }} />
                            </colgroup>

                            <thead
                              style={{
                                border: "1px solid black",
                                // padding: "8px",
                              }}
                            >
                              <tr>
                                <th
                                  style={{
                                    // border: "1px solid black",
                                    padding: "8px",
                                  }}
                                >
                                  <div>
                                    <ul className="nav nav-tabs nav-line-tabs card-title">
                                      {Stages?.map((item) => {
                                        const isSearch =
                                          item.toLowerCase() === "search";
                                        const isBrowse =
                                          item.toLowerCase() === "browse";

                                        return (
                                          <li
                                            className="nav-item fs-6 fw"
                                            onClick={() => setSelectedTab(item)}
                                            key={item}
                                          >
                                            <a
                                              className={`nav-link ${
                                                selectedTab.toLowerCase() ===
                                                item.toLowerCase()
                                                  ? "active"
                                                  : ""
                                              }`}
                                              data-bs-toggle="tab"
                                              href=""
                                              onClick={() => {
                                                if (isSearch) {
                                                  handleSearchClick();
                                                } else if (isBrowse) {
                                                  handleBrowseClick();
                                                }
                                              }}
                                              style={{
                                                color:
                                                  isSearch || isBrowse
                                                    ? "black"
                                                    : "", // Set the color to black for search and browse items
                                              }}
                                            >
                                              {item}
                                              {isSearch ? isSearchClicked : ""}
                                              {isBrowse ? isBrowseClicked : ""}
                                            </a>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </th>

                                <th
                                  style={{
                                    padding: "8px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    border: "0px solid black",
                                  }}
                                >
                                  {selectedStyleCount} selected
                                  <button
                                    className="flex-end nav-link btn btn-sm btn-color-primary btn-active btn-active-light fw-semibold px-4 me-1"
                                    style={{ height: "100%" }}
                                    onClick={handleClearAll}
                                  >
                                    Clear all
                                  </button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {isSearchClicked && (
                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid black",
                                      padding: "8px",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      value={searchValue}
                                      onChange={handleSearchChange}
                                      style={{
                                        width: "100%",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {/* <FiSearch/> */}
                                  </td>
                                </tr>
                              )}
                              {isClicked && (
                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid black",
                                      padding: "8px",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <FiArrowLeft
                                        size={25}
                                        onClick={handleBrowseClick}
                                        style={{ color: "gray" }}
                                      />
                                      <input
                                        type="text"
                                        value={selectedBname}
                                        placeholder={selectedBname}
                                        style={{
                                          width: "100%",
                                          border: "none",
                                          outline: "none",
                                          marginLeft: "5px", // Add margin to create space between the icon and text
                                        }}
                                      />
                                    </div>
                                    {/* <FiSearch/> */}
                                  </td>
                                </tr>
                              )}

                              <tr>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    padding: "8px",
                                    height: "20px",
                                    overflow: "auto",
                                    marginLeft: "15px",
                                    zIndex: 2,
                                  }}
                                >
                                  <div
                                    className="mt-5"
                                    style={{
                                      height: "150px",
                                      overflow: "auto",
                                    }}
                                  >
                                    {isSearchClicked && (
                                      <div>
                                        <input
                                          type="checkbox"
                                          checked={selectAllChecked}
                                          onChange={handleSelectAll}
                                          style={{
                                            width: "10px",
                                            height: "10px",
                                            borderWidth: "5px",
                                            marginRight: "20px",
                                            marginLeft: "25px",
                                            transform: "scale(2)",
                                          }}
                                        />
                                        <span
                                          className="select-all-text"
                                          style={{
                                            marginBottom: "5px",
                                            fontSize: "15px",
                                          }}
                                        >
                                          Select all ({filteredStyles.length})
                                        </span>
                                      </div>
                                    )}

                                    {isSearchClicked &&
                                      filteredStyles.map((language) => (
                                        <div key={language.id}>
                                          <label
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginBottom: "5px",
                                              marginLeft: "25px",
                                              fontSize: "15px",
                                            }}
                                          >
                                            <input
                                              type="checkbox"
                                              value={language.name}
                                              onChange={handleCheckboxChange2}
                                              checked={style.includes(
                                                language.name
                                              )}
                                              style={{
                                                width: "10px",
                                                height: "10px",
                                                borderWidth: "5px",
                                                marginRight: "20px",
                                                transform: "scale(2)",
                                              }}
                                            />
                                            <span
                                              style={{
                                                borderBottom: "1px dashed",
                                                marginRight: "5px",
                                              }}
                                            >
                                              {language.name}{" "}
                                            </span>

                                            <BsClock
                                              size={15}
                                              data-target="popup2"
                                              onMouseEnter={handleMouseEnter}
                                              onMouseLeave={handleMouseLeave}
                                            />
                                          </label>
                                          {isPopupVisible2 && (
                                            <div
                                              style={{
                                                position: "absolute",
                                                top: "35%",
                                                left: "0",
                                                backgroundColor: "#fff",
                                                padding: "20px",
                                                border: "1px solid #ccc",
                                                borderRadius: "5px",
                                                boxShadow:
                                                  "0 2px 5px rgba(0, 0, 0, 0.3)",
                                                zIndex: "2",
                                                width: "230px",
                                                height: "60px",
                                                fontSize: "12px",
                                                marginLeft: "150px",
                                              }}
                                            >
                                              <div>
                                                Recently selected segment
                                              </div>
                                            </div>
                                          )}
                                          <br />
                                        </div>
                                      ))}

                                    {isBrowseClicked && (
                                      <div style={{ cursor: "pointer" }}>
                                        {browse.map((browse) => (
                                          <div
                                            className="mb-4 px-3"
                                            key={browse.id}
                                          >
                                            <div
                                              className="fw fs-6 mb-1 text-dark text-hover-dark cursor-pointer"
                                              style={{ fontSize: "15px" }}
                                              onClick={() =>
                                                handleClick(browse.bname)
                                              }
                                            >
                                              {browse.bname}
                                              <BsChevronRight
                                                className="arrow-icon"
                                                style={{
                                                  marginLeft: "5px",
                                                  float: "right",
                                                }}
                                              />
                                            </div>
                                            <div className="text-muted fw-bold d-block fs-7">
                                              ({browse.captions})
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                    {isClicked && (
                                      <div
                                        className="card "
                                        style={{ marginTop: "-25px" }}
                                      >
                                        <div
                                          className="card-header"
                                          onClick={handleToggleContent1}
                                        >
                                          <h2 className="card-title">
                                            Parental Status
                                          </h2>
                                          {isExpanded1 ? (
                                            <BsChevronUp
                                              className="arrow-icon"
                                              style={{ marginTop: "30px" }}
                                            />
                                          ) : (
                                            <BsChevronDown
                                              className="arrow-icon"
                                              style={{ marginTop: "30px" }}
                                            />
                                          )}
                                        </div>
                                        {isExpanded1 && (
                                          <div
                                            className="card "
                                            style={{
                                              marginTop: "-25px",
                                              marginLeft: "30px",
                                            }}
                                          >
                                            <div
                                              className="card-header"
                                              onClick={handleToggleContent2}
                                            >
                                              <h2 className="card-title">
                                                Parents
                                              </h2>
                                              {isExpanded2 ? (
                                                <BsChevronUp
                                                  className="arrow-icon"
                                                  style={{ marginTop: "30px" }}
                                                />
                                              ) : (
                                                <BsChevronDown
                                                  className="arrow-icon"
                                                  style={{ marginTop: "30px" }}
                                                />
                                              )}
                                              {isExpanded2 && (
                                                <div className="card-body">
                                                  <div className="row gx-xl-12 gy-10 px-20">
                                                    <div className="col-xl-8">
                                                      <div className="">
                                                        <KTCard className="card h-lg-100 col-xxl-12">
                                                          <div
                                                            className="container p-1"
                                                            style={{
                                                              backgroundColor:
                                                                "white",
                                                              borderRadius:
                                                                "10px",
                                                            }}
                                                          >
                                                            <div
                                                              className="row gy-5 gx-xl-10"
                                                              style={{
                                                                marginLeft:
                                                                  "-86px",
                                                              }}
                                                            >
                                                              <span className="fw-bold text-muted fs-7">
                                                                This option
                                                                limits your ads
                                                                to only new
                                                                customers,
                                                                regardless of
                                                                your bid
                                                                strategy
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </KTCard>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </td>

                                <td
                                  style={{
                                    padding: "8px",
                                    fontSize: "15px",
                                    overflow: "auto",
                                  }}
                                >
                                  {selectedStyleCount === 0 && (
                                    <div style={{ display: "flex" }}>
                                      Select one or more segments to observe.
                                    </div>
                                  )}
                                  <div style={{ maxHeight: "200px" }}>
                                    {style.map((language) => (
                                      <div
                                        key={language}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <div>{language}</div>
                                        <AiOutlineCloseCircle
                                          size={25}
                                          onClick={() =>
                                            handleRemoveLanguage(language)
                                          }
                                        />
                                      </div>
                                    ))}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="d-flex align-items-center">
                          <span
                            className="fw text-muted fs-4"
                            style={{ marginLeft: "0px" }}
                          >
                            Targeting setting for this campaign
                            <AiOutlineQuestionCircle size={15} />
                          </span>
                        </div>

                        {target.map((item) => (
                          <label key={item.id}>
                            <input
                              type="radio"
                              name="segment"
                              value={item.targetName}
                              checked={select === item.targetName}
                              onChange={handleOption}
                            />
                            <span
                              className="fw text-grey fs-5"
                              style={{ marginLeft: "10px" }}
                            >
                              {item.targetName}
                            </span>
                            <br />
                            <span
                              className="fw text-grey fs-7"
                              style={{ marginLeft: "25px" }}
                            >
                              {item.sub}
                            </span>
                          </label>
                        ))}

                        <br />

                        <br />
                      </div>
                    </div>
                  </KTCard>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AudienceSegment;
