import { useState, useRef } from "react";
import { BsArrowDownUp, BsChevronDown, BsChevronUp } from "react-icons/bs";
import { KTCard } from "../../../../theme/helpers";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsCurrencyRupee } from "react-icons/bs";
import { BsExclamationCircle } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IoMdArrowDropdown, IoMdLink } from "react-icons/io";
import { MdCurrencyRupee, MdOutlineCurrencyRupee } from "react-icons/md";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomQuestion from "./customQuestion";
import { createBidding } from "../../../modules/application/_requests";
import { State } from "@amcharts/amcharts5/.internal/core/util/States";
import { useEffect } from "react";

const Bidding = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isExpanded2, setIsExpanded2] = useState(true);

  const [type, setType] = useState<string>("Conversions");
  const [status, setStatus] = useState<string>("Conversions");
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [headerColor, setHeaderColor] = useState("");
  const [headerColor2, setHeaderColor2] = useState("");
  const [iconColor, setIconColor] = useState("black");
  const [popupVisible, setPopupVisible] = useState(false);
  const [popup, setPopup] = useState(false);
  const [popupHead, setPopupHead] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [bidPop, setBidPop] = useState(false);
  const [impressionPop, setImpressionPop] = useState(false);

  const dropdownRefsFilter = useRef<HTMLDivElement>(null);
  const dropdownRefsImpression = useRef<HTMLDivElement>(null);

  const [selectedPopUpName, setSelectedPopUpName] = useState("");
  const { id } = useParams();
  console.log(id, "params data");
  const location = useLocation();
  const idd: any = location.state;

  console.log(idd, "id btao");
  console.log(typeof idd, "typeof");

  const handleMouseEnter = () => {
    // setIsPopupVisible(true);
    setIsPopupVisible((prevIsPopUpVisible) => !prevIsPopUpVisible);
    setIconColor("#0096FF");
    setPopupVisible(true);
  };

  const handleMouseLeave = () => {
    setIsPopupVisible(false);
    setIconColor("");
    setPopupVisible(false);
  };
  const navigate = useNavigate();

  const handleMouseEnter1 = (name) => {
    setSelectedPopUpName(name);
    setPopup(true);
    setPopupHead(false);
    setIsPopupVisible(false);
  };
  const handleMouseEnter2 = () => {
    setPopup(false);
    setPopupHead(true);
    setIsPopupVisible(false);
  };
  const handleMouseEnter4 = (name) => {
    // setSelected(name)
    setHoveredItem(name);
    setImpressionPop(true);
    setPopup(false);
    setPopupHead(false);
    setIsPopupVisible(false);
  };
  const handleMouseLeave4 = () => {
    setHoveredItem(null);
  };

  const [showPopup, setShowPopup] = useState(false);

  const handleMouseEnter3 = () => {
    setShowPopup(true);
  };

  const handleMouseLeave3 = () => {
    setShowPopup(false);
  };

  const validationSchema = Yup.object().shape({
    inputValue: Yup.number()
      .typeError("Enter an amount")
      .required("Enter an amount"),
  });

  const formik = useFormik({
    initialValues: {
      inputValue: "",
    },
    validationSchema,
    onSubmit: () => {},
  });

  // const handleInputChange = (e) => {
  //   const { value } = e.target;
  //   formik.setFieldValue("inputValue", value);
  // };
  const handleInputChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue("inputValue", value);
    setInputValue(event.target.value);
  };

  const status_data = [
    {
      name: "Status",
      heading: "Recommended",
      pophead:
        "Recommended because you track conversions, and these options will help you get the most conversions for your budget",
      dropdownData: [
        {
          name: "Conversions",
          check: "Set a target cost per action",
          pop: "Conversions are actions on your website, app, or store that you define.",
          cpa: "Target CPA",
          icon: MdCurrencyRupee,
        },
        {
          name: "Conversion values",
          check: "Set a target return on ad spend",
          pop: "Conversion value is value that you associate with actions taken on your webpage, app, or store.",
          cpa: "Target ROAS",
          icon: MdCurrencyRupee,
        },
        {
          name: "Clicks",
          check: "Set a maximum cost per click bid limit",
          pop: "Clicks are when someone clicks on your ad.",
          cpa: "Maximum CPC bid limit",
          icon: MdCurrencyRupee,
        },
        {
          name: "Impression share",
          check: "a",
          pop: "Impression share is the percentage of impressions that your ads receive compared to the total number of impressions that your ads could get",
          cpa: "Maximum CPC bid limit",
          icon: MdCurrencyRupee,
          share: "Percent (%) impression share to target",
          ads: "Where do you want your ads to appear ",
        },
      ],
      impressionDrop: [
        {
          name: "Anywhere on results page",
        },
        {
          name: "Top of results page",
        },
        {
          name: "Absolute top of results page",
        },
      ],
      drop: "ad location",
    },
  ];
  const handleSelect = (e, item, data) => {
    console.log("handle...", item, data);
    if (item?.name?.toLowerCase() === "type") {
      console.log("TYPE..");
      setType(data.name);
    } else if (item?.name?.toLowerCase() === "status") {
      console.log("STATUS....");
      setStatus(data.name);
    } else if (item?.name === "Status" && data?.name === "Clicks") {
      setSelected("Clicks"); // Select "Clicks" in the first dropdown
    }
    setBidPop(false);
    // setImpressionPop(false);
  };

  const handleApply = () => {
    setStatus("Clicks"); // Select "Clicks" in the first dropdown
  };

  // ...

  <div className="col-xl-1 d-flex align-items-center justify-content-center">
    <button
      className="btn btn-light d-flex align-items-center justify-content-center"
      style={{
        backgroundColor: "transparent",
        color: "#007bff",
        border: "none",
        height: "40px",
        width: "70px",
        transition: "background-color 0.3s",
        fontSize: "15px",
      }}
      onClick={handleApply}
    >
      Apply
    </button>
  </div>;

  // const handleSelect = (e, item, data) => {
  //   console.log("handle...", item, data);
  //   if (item.name.toLowerCase() === "type") {
  //     console.log("TYPE..");
  //     setType(data.name);
  //   } else if (item.name.toLowerCase() === "status") {
  //     console.log("STATUS....");
  //     setStatus(data.name);
  //   } else {
  //     // Handle other cases if needed
  //   }

  //   }

  const [selected, setSelected] = useState(
    status_data[0].impressionDrop[0].name
  ); // Initialize with the first value
  const [hoveredItem, setHoveredItem] = useState(null);
  const handleDrop2 = (index, selectedValue) => {
    console.log(`Selected value: ${selectedValue}`);
    setSelected(selectedValue);

    setImpressionPop(false);
  };
  console.log(selected, "selecetessssssssss");
  const handleClick = (values: any) => {
    const biddingData: any = {
      campaign_id: idd?.campaignId,
      Bidding: {
        focuson: selectedPopUpName,
        value: inputValue,
      },
      Customer_Acquisition: {
        is_bid: isChecked2,
      },
    };

    // const file = values.fileName[0];
    console.log(biddingData, "nnnnnnmmmmmmmmm");
    const data = createBidding(biddingData)
      .then((data) => {
        console.log("Bidding and Customer Added Successfully!");
        console.log(data, "biding response");

        const campaignId = data.campaign_id;
        navigate("/campaignSettings", { state: { campaignId } });
        setTimeout(() => {
          console.log(undefined);
        }, 2000);
      })
      .catch((err) => {
        alert("Request Failed!");
      });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRefsFilter.current &&
        !dropdownRefsFilter.current.contains(event.target as Node)
      ) {
        // setIsPopupOpen(false);
        setBidPop(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRefsImpression.current &&
        !dropdownRefsImpression.current.contains(event.target as Node)
      ) {
        // setIsPopupOpen(false);
        setImpressionPop(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handlePop = () => {
    // setShowCampaignType(true);
    setShowPopup(false);
    setIsClicked(true);
  };

  const handleToggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  const handleToggleContent2 = () => {
    setIsExpanded2(!isExpanded2);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleCheckbox2 = () => {
    setIsChecked2(!isChecked2);
  };
  // const handleStatusSelection = (selectedStatus) => {
  //   setStatus(selectedStatus);
  // };
  const handleStatusSelection = (item, data) => {
    console.log("handle...", item, data);
    if (item.name.toLowerCase() === "drop2") {
      console.log("drop2..");
      setType(data.name);
    } else if (item.name.toLowerCase() === "status") {
      console.log("STATUS....");
      setStatus(data.name);
    } else {
      // Handle other cases if needed
    }
  };

  const handleBidding = () => {
    setBidPop(true);
    setImpressionPop(false);
  };

  const handleImpression = () => {
    setImpressionPop(true);
    setBidPop(false);
  };

  return (
    <>
      <div className="container px-10">
        <div className="px-10"></div>
        <div
          className="card "
          style={{
            marginBottom: isExpanded ? "15px" : "0px",
            backgroundColor: isExpanded ? "" : "#E1E2E3",
            boxShadow: isExpanded ? "0 2px 8px rgba(0, 0, 0, 0.5)" : "",
          }}
        >
          <div
            className="card-header"
            onClick={handleToggleContent}
            onMouseOver={() => setHeaderColor("#E1E2E3")}
            onMouseOut={() => setHeaderColor("")}
            style={{
              backgroundColor: headerColor || "#FFFFFF", // Set default color to white
              cursor: "pointer",
              border: "1px solid #ced4da",
              transition: "background-color 0.3s ease",
              minHeight: "48px",
              borderRadius: "5px",
            }}
          >
            <div
              className="row gx-xl-12 gy-5 d-flex align-items-center"
              style={{ width: "100%" }}
            >
              <div className="col-xl-3">
                <h5 className="card-title">Bidding</h5>
              </div>
              <div className="col-xl-8">
                {!isExpanded ? (
                  <h5
                    className="card-title"
                    style={{ color: "rgba(0,0,0,.54)" }}
                  >
                    Maximize conversions
                  </h5>
                ) : null}
              </div>
              <div className="col-xl-1 d-flex align-items-center justify-content-end">
                {isExpanded ? (
                  <BsChevronUp
                    className="arrow-icon"
                    size={24}
                    style={{ marginTop: "10px", color: "#5F6368" }}
                  />
                ) : (
                  <BsChevronDown
                    className="arrow-icon"
                    size={24}
                    style={{ marginTop: "10px" }}
                  />
                )}
              </div>
            </div>
          </div>
          {isExpanded && (
            <div className="card-body">
              <div className="">
                <div className=" col-xxl-12 px-20">
                  <div
                    className="container p-1"
                    style={{ backgroundColor: "white", borderRadius: "10px" }}
                  >
                    <div
                      className="row gy-5 gx-xl-10"
                      style={{ marginLeft: "-86px" }}
                    >
                      <span
                        className="fw text-dark"
                        style={{ fontSize: "14px", color: "#3C4043" }}
                      >
                        What do you want to focus on?{" "}
                        <CustomQuestion>
                          <p>
                            Choose the metric you'd like to focus on for your
                            campaign, and see bidding options to help you
                            optimize for it.
                          </p>
                          <u style={{ color: "#009EF7" }}>
                            <a href="#"> Learn more</a>
                          </u>
                        </CustomQuestion>
                      </span>
                      {isPopupVisible && (
                        <div
                          style={{
                            position: "absolute",
                            top: "35%",
                            left: "0",
                            backgroundColor: "#fff",
                            padding: "20px",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                            zIndex: "2",
                            width: "500px",
                            height: "120px",
                            fontSize: "15px",
                            marginLeft: "250px",
                          }}
                        >
                          <div></div>
                        </div>
                      )}
                      <span className="text-dark-400 mt-1 fw-semibold fs-5">
                        {status_data?.map((item) => {
                          return (
                            <div className="d-flex align-items-center fw-bold">
                              {/* <span className="text-muted fs-7">{item?.name}</span> */}

                              <div
                                className="dropdown d-flex align-items-center flex-wrap px-3"
                                id={`drop`}
                                style={{
                                  // width: "140px",
                                  textAlign: "left",
                                  fontSize: "13px",
                                  border: "2px solid grey",
                                  borderRadius: "5px",
                                  // marginLeft: "2px",

                                  height: "50px",
                                }}
                              >
                                <div
                                  className="text-dark fw-bold"
                                  style={{ fontSize: "14px" }}
                                  onClick={handleBidding}
                                >
                                  {item?.name === "Type" ? type : status}
                                </div>
                                {/* <div
                                  className="px-2"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  style={{
                                    // width: "100%",
                                    // textAlign: "left",
                                    fontSize: "13px",
                                    // display: "flex",
                                    // alignItems: "center",
                                    padding: "0px 8px 0px 16px",
                                    position: "relative",
                                  }}
                                > */}
                                <div className="flex-end">
                                  <IoMdArrowDropdown size={25}
                                    className=""
                                    style={{
                                      // position: "absolute", // Added position: absolute
                                      right: "5px", // Adjust the right value as needed
                                      color: "#5f6368",
                                    }}
                                  />
                                </div>
                                {/* </div> */}

                                {/* <div
                                  className="dropdown-menu"
                                  aria-labelledby={`end`}
                                  style={{
                                    // width: "145%",
                                    minWidth: "unset",
                                    
                                  }}
                                > */}
                                {bidPop && (
                                  <div
                                    className="fw-semibold d-flex align-items-center flex-wrap"
                                    ref={dropdownRefsFilter}
                                    style={{
                                      position: "absolute",
                                      // top: "2%",
                                      // left: "7%",
                                      backgroundColor: "#fff",
                                      padding: "10px",
                                      border: "1px solid white",
                                      borderRadius: "6px",
                                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                                      zIndex: 999999,
                                      width: "230px",
                                      height: "300px",
                                      // maxHeight: "300px",
                                      // overflowY: "auto",
                                      fontSize: "15px",
                                      transition: "height 10.0s ease",
                                      cursor: "pointer",
                                      top: "-10%",
                                      marginLeft: "-12px",
                                    }}
                                  >
                                    {status_data[0]?.dropdownData?.map(
                                      (data, index) => {
                                        const isFirstItemAfterHeading =
                                          index === 0 && item?.heading;
                                        return (
                                          <div key={index}>
                                            {isFirstItemAfterHeading && (
                                              <div className="dropdown-header px-2 mb-2">
                                                <div
                                                  className="text-primary"
                                                  style={{
                                                    borderBottom: "2px dashed",
                                                    fontSize: "16px",
                                                    width:"114px",
                                                    
                                                  }}
                                                  onMouseEnter={() =>
                                                    handleMouseEnter2()
                                                  }
                                                >
                                                  {item.heading}
                                                </div>
                                              </div>
                                            )}
                                            {popupHead &&
                                              isFirstItemAfterHeading && (
                                                <div
                                                  style={{
                                                    position: "absolute",
                                                    backgroundColor: "#fff",
                                                    padding: "15px",
                                                    borderRadius: "5px",
                                                    zIndex: "1",
                                                    width: "400px",
                                                    height: "90px",
                                                    fontSize: "14px",
                                                    marginLeft: "250px",
                                                    marginTop: "-5px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "13px",
                                                      color: "#3C4340",
                                                    }}
                                                  >
                                                    {" "}
                                                    {item.pophead}
                                                  </span>
                                                </div>
                                              )}
                                            {data.name === "Clicks" && (
                                              <div className="dropdown-header px-2">
                                                <div
                                                  className="text-dark"
                                                  style={{ fontSize: "16px" }}
                                                >
                                                  Other optimization options
                                                </div>
                                              </div>
                                            )}
                                            <div
                                              // className={`dropdown-item text-hover-dark${
                                              //   data.name === status
                                              //     ? "text-primary bg-light-secondary"
                                              //     : ""
                                              // }`}
                                              className="p-5 d-flex align-items-center"
                                              onClick={(e) =>
                                                handleSelect(e, item, data)
                                              }
                                              // onMouseEnter={() =>
                                              //   handleMouseEnter1(data.name)

                                              // }
                                              // onMouseLeave={() => setPopup(false)}
                                              onMouseEnter={() =>
                                                handleMouseEnter1(data.name)
                                              }
                                              onMouseLeave={() =>
                                                setPopup(false)
                                              }
                                              style={{
                                                 width:"228px",
                                                 height:"50px",
                                                backgroundColor:
                                                  //   data.name === status
                                                  //     ? "#E8F0FE"
                                                  //     : "transparent", // Change the background color on hover
                                                  // data.name === status ? "#E8F0FE" : "#F5F5F5",
                                                  data.name === status
                                                    ? "#E8F0FE"
                                                    : selectedPopUpName ===
                                                      data.name
                                                    ? "#EFEFEF"
                                                    : "white",
                                                cursor: "pointer",
                                                marginLeft:"-10px"
                                               
                                              }}
                                            >
                                             {/* <div className="d-flex" style={{marginLeft:"-10px"}}> */}
                                               {data.name}
                                               {/* </div> */}
                                            </div>
                                            {popup &&
                                              selectedPopUpName ===
                                                data.name && (
                                                <div
                                                  style={{
                                                    position: "absolute",
                                                    backgroundColor: "#fff",
                                                    padding: "15px",
                                                    borderRadius: "5px",
                                                    zIndex: "1",
                                                    width: "400px",
                                                    height: "90px",
                                                    fontSize: "14px",
                                                    marginLeft: "250px",
                                                    marginTop: "-10px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "13px",
                                                      color: "#3C4340",
                                                    }}
                                                  >
                                                    {data.pop}
                                                  </span>
                                                </div>
                                              )}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </span>

                      <div className="flex-wrap align-items-center">
                        {status_data[0]?.dropdownData?.map((data, index) => {
                          if (data.name === status) {
                            console.log(
                              status,
                              data.name,
                              "statusssssssssssssssss"
                            );
                            return (
                              <div
                                className="d-flex align-items-center"
                                key={index}
                              >
                                {data.name !== "Impression share" && (
                                  <>
                                    <input
                                      type="checkbox"
                                      checked={isChecked}
                                      onChange={handleCheckboxChange}
                                      style={{
                                        width: "10px",
                                        height: "10px",
                                        borderWidth: "5px",
                                        marginRight: "15px",
                                        fontWeight: "bold",
                                        transform: "scale(2)",
                                      }}
                                    />

                                    <span
                                      className="fw text-dark"
                                      style={{
                                        marginLeft: "5px",
                                        fontSize: "14px",
                                        color: "#3C4043",
                                      }}
                                    >
                                      {data.check} (optional)
                                    </span>
                                  </>
                                )}
                                {data.name === "Impression share" && (
                                  <div>
                                    <div
                                      className="fw text-dark d-flex align-items-center "
                                      style={{
                                        // marginLeft: "1px",
                                        fontSize: "14px",
                                        color: "#3C4043",
                                        // marginBottom: "10px", // Add margin bottom to create space between the text and input box
                                      }}
                                    >
                                      {data.ads}
                                    </div>
                                    <span className="text-dark-400 fw-semibold fs-5">
                                      {status_data?.map((item) => {
                                        console.log(
                                          status_data,
                                          "ddddddddddddffffff"
                                        );
                                        return (
                                          <div className="d-flex align-items-center fw-bold mt-1">
                                            {/* <span className="text-muted fs-7">{item?.name}</span> */}

                                            <div className="dropdown">
                                              {/* Your div code */}
                                              <div
                                                className="dropdown d-flex align-items-center flex-wrap px-3"
                                                id={`drop`}
                                                style={{
                                                  // width: "160px",
                                                  textAlign: "left",
                                                  fontSize: "13px",
                                                  border: "2px solid grey",
                                                  borderRadius: "5px",
                                                  // marginLeft: "2px",

                                                  height: "50px",
                                                }}
                                              >
                                                {/* <div
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                  style={{
                                                    width: "100%",
                                                    textAlign: "left",
                                                    fontSize: "13px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "5px",
                                                  }}
                                                > */}
                                                <div
                                                  className="flex-end"
                                                  onClick={handleImpression}
                                                >
                                                  {/* {startTime} */}
                                                  {selected}
                                                  <IoMdArrowDropdown size={25}
                                                    style={{
                                                      marginLeft: "5px",
                                                      color: "#5f6368",
                                                    }}
                                                  />
                                                </div>
                                                {/* </div> */}
                                                {/* <ul
                                                  className="dropdown-menu"
                                                  aria-labelledby={`start`}
                                                  style={{
                                                    width: "100%",
                                                    minWidth: "unset",
                                                  }}
                                                > */}
                                                {impressionPop && (
                                                  <div
                                                    className="fw-semibold d-flex align-items-center flex-wrap"
                                                    ref={dropdownRefsImpression}
                                                    style={{
                                                      position: "absolute",
                                                      // top: "2%",
                                                      // left: "7%",
                                                      backgroundColor: "#fff",
                                                      
                                                      border: "1px solid white",
                                                      borderRadius: "6px",
                                                      boxShadow:
                                                        "0 2px 5px rgba(0, 0, 0, 0.3)",
                                                      zIndex: 999999,
                                                      width: "250px",
                                                      height: "170px",
                                                      // maxHeight: "300px",
                                                      // overflowY: "auto",
                                                      fontSize: "15px",
                                                      transition:
                                                        "height 10.0s ease",
                                                      cursor: "pointer",
                                                      top: "-6%",
                                                     
                                                      marginLeft: "-13px",
                                                    }}
                                                    // onClick={handleImpression}
                                                  >
                                                    {status_data[0]?.impressionDrop?.map(
                                                      (item, itemIndex) => (
                                                        <div 
                                                        key={itemIndex}
                                                          className="py-4"
                                                          onClick={() =>
                                                            handleDrop2(
                                                              index,
                                                              item.name
                                                            )
                                                          }
                                                         
                                                          onMouseEnter={() =>
                                                            handleMouseEnter4(item.name)
                                                          }
                                                          // onMouseLeave={() =>
                                                          //   setImpressionPop(false)
                                                          // }
                                                          onMouseLeave={handleMouseLeave}
                                                          style={{
                                                            width:'100%',
                                                            // height:'20%',
                                                              backgroundColor:
                                                              item.name ===
                                                              selected
                                                                ? "#E8F0FE"
                                                                : item.name===hoveredItem                                                               
                                                                ? "#EFEFEF"
                                                                : "white",
                                                                

                                                          }}
                                                        >
                                                          <div className="d-flex align-items-center px-5" style={{height:'100%'}}>{item.name}</div>
                                                        </div>
                                                       
                                                      )
                                                    )}
                                                    {/* </ul> */}
                                                  </div>
                                                )}
                                              </div>
                                              {/* Render other dropdown divs similarly */}
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </span>
                                    <br />
                                    <div
                                      className="fw text-dark"
                                      style={{
                                        marginLeft: "5px",
                                        fontSize: "14px",
                                        color: "#3C4043",
                                        marginBottom: "10px", // Add margin bottom to create space between the text and input box
                                      }}
                                    >
                                      {data.share}
                                    </div>

                                    <div>
                                      <input
                                        type="text"
                                        // value={formik.values.inputValue}
                                        // onChange={handleInputChange}
                                        // onMouseEnter={handleMouseEnter3}
                                        // onMouseLeave={handleMouseLeave3}
                                        // onClick={handlePop}
                                        // className={isClicked ? "blue" : ""}
                                        style={{
                                          padding: "10px",
                                          paddingLeft: "55px",
                                          width: "180px",
                                          height: "50px",
                                          borderRadius: "5px",
                                          border: "1px solid #ced4da", // Set initial border to transparent
                                          borderColor: `${
                                            formik.errors.inputValue
                                              ? "red"
                                              : isClicked
                                              ? "#0d6efd"
                                              : "#ced4da"
                                          }`,
                                          borderWidth: "2px", // Set initial border width
                                          color: "black",
                                          outline: "none", // Remove default outline style
                                          transition:
                                            "border-color 0.3s ease-in-out", // Add transition effect
                                        }}
                                      />
                                    </div>
                                    <div
                                      className="fw text-dark"
                                      style={{
                                        marginLeft: "5px",
                                        fontSize: "14px",
                                        color: "#3C4043",
                                        marginBottom: "10px", // Add margin bottom to create space between the text and input box
                                      }}
                                    >
                                      {data.cpa}
                                    </div>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="text"
                                        value={formik.values.inputValue}
                                        onChange={handleInputChange}
                                        onMouseEnter={handleMouseEnter3}
                                        onMouseLeave={handleMouseLeave3}
                                        onClick={handlePop}
                                        // className={isClicked ? "blue" : ""}
                                        style={{
                                          padding: "10px",
                                          paddingLeft: "55px",
                                          width: "180px",
                                          height: "50px",
                                          borderRadius: "5px",
                                          border: "1px solid #ced4da", // Set initial border to transparent
                                          borderColor: `${
                                            formik.errors.inputValue
                                              ? "red"
                                              : isClicked
                                              ? "#0d6efd"
                                              : "#ced4da"
                                          }`,
                                          borderWidth: "2px", // Set initial border width
                                          color: "black",
                                          outline: "none", // Remove default outline style
                                          transition:
                                            "border-color 0.3s ease-in-out", // Add transition effect
                                        }}
                                      />
                                      <div
                                        className="px-4"
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "10px",
                                          transform: "translateY(-50%)",
                                          color: "black",
                                          pointerEvents: "none", // Ignore pointer events to allow clicking the input
                                        }}
                                      >
                                        <MdCurrencyRupee size={20} />
                                      </div>
                                      {showPopup && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "60%",
                                            right: "80%",
                                            backgroundColor: "#fff",
                                            padding: "5px",
                                            border: "1px solid #ccc",
                                            borderRadius: "5px",
                                            boxShadow:
                                              "0 2px 5px rgba(0, 0, 0, 0)",
                                            zIndex: "2",
                                            width: "110px",
                                            height: "30px",
                                            fontSize: "10px",
                                            marginLeft: "500px",
                                            color: "#333",
                                          }}
                                        >
                                          <div className="d-flex align-items-center ">
                                            Please fill this field
                                          </div>
                                        </div>
                                      )}
                                      {formik.touched.inputValue &&
                                        formik.errors.inputValue && (
                                          <div
                                            className="px-4"
                                            style={{
                                              position: "absolute",
                                              top: "50%",
                                              left: "10px",
                                              transform: "translateY(-50%)",
                                              color: "#888888",
                                              pointerEvents: "none", // Ignore pointer events to allow clicking the input
                                            }}
                                          >
                                            {formik.errors.inputValue}
                                            <MdOutlineCurrencyRupee size={15} />
                                          </div>
                                        )}
                                      {formik.errors.inputValue && (
                                        <div
                                          className="px-4"
                                          style={{
                                            color: "red",
                                            borderColor: "red",

                                            marginTop: "5px",
                                          }}
                                        >
                                          {formik.errors.inputValue}
                                        </div>
                                      )}
                                    </div>

                                    <div
                                      className="card mt-20 px-5"
                                      style={{
                                        border: "1px solid gray",
                                        width: "100%",
                                      }}
                                    >
                                      <div className="row gy-5 gx-xl-12 d-flex">
                                        <div className="col-xl-11 p-3 d-flex align-items-center ">
                                          <div className="">
                                            <BsArrowDownUp
                                              size={30}
                                              style={{
                                                padding: "7px",
                                                color: "#188038",
                                                borderRadius: "50px",
                                                marginRight: "10px",
                                                backgroundColor: "#E6F4EA",
                                              }}
                                            />
                                          </div>
                                          <div
                                            className="text-dark pl-5 align-items-center justify-content-center"
                                            style={{
                                              fontSize: "14px",
                                              textAlign: "left",
                                            }}
                                          >
                                            <b>
                                              Bid more efficiently with
                                              Maximized clicks:{" "}
                                            </b>
                                            Get more clicks with a fully
                                            automated bid strategy
                                            <CustomQuestion>
                                              <p>
                                                Maximize clicks automatically
                                                sets your bids to help you get
                                                the most clicks within your
                                                budget.
                                              </p>
                                              <p>
                                                You’re seeing this
                                                recommendation because some of
                                                your Search Network or Display
                                                Network campaigns have enough
                                                clicks to benefit from Maximize
                                                clicks bidding.
                                              </p>
                                              <p></p>
                                              <p>
                                                <u style={{ color: "#009EF7" }}>
                                                  <a href="#"> Learn more</a>
                                                </u>
                                              </p>
                                            </CustomQuestion>
                                          </div>
                                        </div>
                                        <div className="col-xl-1 d-flex align-items-center justify-content-center">
                                          <button
                                            className="btn btn-light d-flex align-items-center justify-content-center"
                                            style={{
                                              backgroundColor: "transparent",
                                              color: "#007bff",
                                              border: "none",
                                              height: "40px",
                                              width: "70px",
                                              transition:
                                                "background-color 0.3s",
                                              fontSize: "15px",
                                            }}
                                            onClick={handleApply}
                                          >
                                            Apply
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          }
                          return null;
                        })}

                        {isChecked && (
                          <div className=" flex-wrap align-items-center">
                            <div className="d-flex flex-wrap align-items-center">
                              <span
                                className="fw text-dark"
                                style={{ fontSize: "14px", color: "#3C4043" }}
                              >
                                {status !== "Impression share" && (
                                  <>
                                    {
                                      status_data[0]?.dropdownData?.find(
                                        (data) => data.name === status
                                      )?.cpa
                                    }{" "}
                                    <CustomQuestion>
                                      <p>
                                        {
                                          status_data[0]?.dropdownData?.find(
                                            (data) => data.name === status
                                          )?.cpa
                                        }{" "}
                                        sets bids to help get as many
                                        conversions as possible at or below the
                                        target cost-per-action (CPA) you set.
                                        Some conversions may cost more or less
                                        than your target
                                      </p>
                                      <u style={{ color: "#009EF7" }}>
                                        <a href="#"> Learn more</a>
                                      </u>
                                    </CustomQuestion>
                                  </>
                                )}
                              </span>
                              <br />
                              <br />
                            </div>
                            {status !== "Impression share" && (
                              <div style={{ position: "relative" }}>
                                <input
                                  type="text"
                                  // value={formik.values.inputValue}
                                  value={inputValue}
                                  onChange={handleInputChange}
                                  onMouseEnter={handleMouseEnter3}
                                  onMouseLeave={handleMouseLeave3}
                                  onClick={handlePop}
                                  // className={isClicked ? "blue" : ""}
                                  style={{
                                    padding: "10px",
                                    paddingLeft: "55px",
                                    width: "180px",
                                    height: "50px",
                                    borderRadius: "5px",
                                    border: "1px solid #ced4da", // Set initial border to transparent
                                    borderColor: `${
                                      formik.errors.inputValue
                                        ? "red"
                                        : isClicked
                                        ? "#0d6efd"
                                        : "#ced4da"
                                    }`,
                                    borderWidth: "2px", // Set initial border width
                                    color: "black",
                                    outline: "none", // Remove default outline style
                                    transition: "border-color 0.3s ease-in-out", // Add transition effect
                                  }}
                                />
                                <div
                                  className="px-4"
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "10px",
                                    transform: "translateY(-50%)",
                                    color: "black",
                                    pointerEvents: "none", // Ignore pointer events to allow clicking the input
                                  }}
                                >
                                  <MdCurrencyRupee size={20} />
                                </div>
                                {showPopup && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "60%",
                                      right: "80%",
                                      backgroundColor: "#fff",
                                      padding: "5px",
                                      border: "1px solid #ccc",
                                      borderRadius: "5px",
                                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0)",
                                      zIndex: "2",
                                      width: "110px",
                                      height: "30px",
                                      fontSize: "10px",
                                      marginLeft: "500px",
                                      color: "#333",
                                    }}
                                  >
                                    <div className="d-flex align-items-center ">
                                      Please fill this field
                                    </div>
                                  </div>
                                )}
                                {formik.touched.inputValue &&
                                  formik.errors.inputValue && (
                                    <div
                                      className="px-4"
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "10px",
                                        transform: "translateY(-50%)",
                                        color: "#888888",
                                        pointerEvents: "none", // Ignore pointer events to allow clicking the input
                                      }}
                                    >
                                      {formik.errors.inputValue}
                                      <MdOutlineCurrencyRupee size={15} />
                                    </div>
                                  )}
                                {formik.errors.inputValue && (
                                  <div
                                    className="px-4"
                                    style={{
                                      color: "red",
                                      borderColor: "red",

                                      marginTop: "5px",
                                    }}
                                  >
                                    {formik.errors.inputValue}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <span
                        className="fw-bold mt-13"
                        style={{ color: "#5F6368", fontSize: "14px",fontWeight:"400px" }}
                      >
                        Alternative bid strategies like portfolios are available
                        in settings after you create your campaign
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          className="card"
          style={{
            marginTop: isExpanded2 ? "10px" : "0px",
            backgroundColor: isExpanded2 ? "" : "#E1E2E3",
            boxShadow: isExpanded2 ? "0 2px 8px rgba(0, 0, 0, 0.5)" : "",
          }}
        >
          <div
            className="card-header"
            onClick={handleToggleContent2}
            onMouseOver={() => setHeaderColor2("#E1E2E3")}
            onMouseOut={() => setHeaderColor2("")}
            style={{
              backgroundColor: headerColor2 || "#FFFFFF", // Set default color to white
              cursor: "pointer",
              border: "1px solid #ced4da",
              transition: "background-color 0.3s ease",
              minHeight: "48px",
              borderRadius: "5px",
            }}
          >
            <div
              className="row gx-xl-12 gy-5 d-flex align-items-center"
              style={{ width: "100%" }}
            >
              <div className="col-xl-3">
                <h5 className="card-title">Customer Acquisition</h5>
              </div>
              <div className="col-xl-8">
                {!isExpanded2 ? (
                  <h5
                    className="card-title"
                    style={{ color: "rgba(0,0,0,.54)" }}
                  >
                    Bid equally for new and existing customers
                  </h5>
                ) : null}
              </div>
              <div className="col-xl-1 d-flex align-items-center justify-content-end">
                {isExpanded2 ? (
                  <BsChevronUp
                    className="arrow-icon"
                    size={24}
                    style={{ marginTop: "10px", color: "#5F6368" }}
                  />
                ) : (
                  <BsChevronDown
                    className="arrow-icon"
                    size={24}
                    style={{ marginTop: "10px" }}
                  />
                )}
              </div>
            </div>
          </div>
          {isExpanded2 && (
            <div className="card-body">
              <div className="row gx-xl-12 gy-10">
                <div className="col-xl-7">
                  {/* <KTCard className="card h-lg-100 col-xxl-12"> */}
                  <div
                    className="container p-1"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                    }}
                  >
                    <div className="row gy-5 gx-xl-10">
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          checked={isChecked2}
                          onChange={handleCheckbox2}
                          style={{
                            width: "10px",
                            height: "10px",
                            color: "#9e9e9e",
                            border: "2px solid #9e9e9e",
                            transform: "scale(2)",
                          }}
                        />
                        <span
                          className="fw-bold text-dark"
                          style={{ marginLeft: "10px", fontSize: "16px" }}
                        >
                          Only bid for new customers
                        </span>
                      </div>
                      <div
                        className=" text-muted mb-7"
                        style={{ marginLeft: "10px", fontSize: "14px" }}
                      >
                        This option limits your ads to only new customers,
                        regardless of your bid strategy
                      </div>
                    </div>
                  </div>
                  {/* </KTCard> */}
                  {isChecked2 && (
                    <div
                      className="container py-5 px-10 d-flex"
                      style={{
                        backgroundColor: "#FBE7E6",
                        borderRadius: "9px",
                      }}
                    >
                      <BsExclamationCircle
                        size={40}
                        style={{
                          marginRight: "10px",
                          marginTop: "-10px",
                          color: "red",
                        }}
                      />
                      <div
                        className=""
                        style={{ fontSize: "14px", color: "3C4043" }}
                      >
                        This campaign will not run. <br />
                        <br />
                        To fix this campaign, you can either include an audience
                        segment with at least 1,000 active members in at least
                        one network, or turn off this setting until you have
                        added an eligible audience segment.
                        {/* <button
                          type="button"
                          className="nav-link btn btn-sm btn-color-danger btn-active btn-active-light
             fw-bold mt-5"
                          style={{ marginLeft: "-20px" }}
                          
                        >
                          Define existing customer list
                        </button> */}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-xl-1 d-flex align-items-center justify-content-center">
                  <div
                    className="vertical-line"
                    style={{
                      width: "0.7px",
                      height: "100%",
                      backgroundColor: "#ccc",
                    }}
                  />
                </div>

                <div className="col-xl-4 pl-0">
                  {!isChecked2 ? (
                    <span
                      className=""
                      style={{
                        fontSize: "14px",
                        color: "#5F6368",
                        fontFamily: "Arial",
                      }}
                    >
                      By default, your campaign bids equally for new and
                      existing customers. However, you can configure your
                      customer acquisition settings to optimize for acquiring
                      new customers. <a href="#"> Learn more</a>
                    </span>
                  ) : (
                    <span
                      className=""
                      style={{
                        fontSize: "14px",
                        color: "#5F6368",
                        fontFamily: "Arial",
                      }}
                    >
                      Smart bidding will be optimized for delivering your ads
                      only to new customers.
                      <br />
                      <br />
                      Note: Your ads may sometimes be shown to existing
                      customers due to technology limitations and privacy
                      measures.
                      <a href="#"> Learn more</a>
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="next">
          <div
            className="gap-2 my-5 d-flex"
            style={{ justifyContent: "flex-end" }}
          >
            <a
              href="#"
              className="btn btn-sm fw-bold btn-primary"
              onClick={handleClick}
              style={{ fontSize: "14px" }}
            >
              Next
            </a>
            {/* <Biding/> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Bidding;
