import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { KTCard } from "../../../../theme/helpers";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsCurrencyRupee } from "react-icons/bs";
import { BsExclamationCircle } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdLink } from "react-icons/io";
import { MdCurrencyRupee, MdOutlineCurrencyRupee } from "react-icons/md";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomQuestion from "./customQuestion";
import warning from "../../../../assets/baseline-warning.png";
import { campaignBudget } from "../../../modules/application/_requests";

const Budget = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isExpanded2, setIsExpanded2] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [headerColor2, setHeaderColor2] = useState("");
  const [iconColor, setIconColor] = useState("black");
  const [popupVisible, setPopupVisible] = useState(false);
  const [popup, setPopup] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [budgetValue, setBudgetValue] = useState("");
  const [inputValueWhenClosed, setInputValueWhenClosed] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const idd: any = location.state;

  console.log(idd, "id budget");

  const [showPopup, setShowPopup] = useState(false);

  const handleClick = (values: any) => {
    const saveddata: any = {
      campaign_id: idd?.cid,
      budgetValue: budgetValue,
    };

    // const file = values.fileName[0];
    console.log(saveddata, "budget api");
    const response = campaignBudget(saveddata)
      .then((response) => {
        console.log("Budget Added Successfully!");
        console.log(response, "Budget");

        setTimeout(() => {
          console.log(undefined);
        }, 2000);
      })
      .catch((err) => {
        alert("Request Failed!");
      });

    navigate("/listing");
  };

  const handleMouseEnter3 = () => {
    setShowPopup(true);
  };

  const handleMouseLeave3 = () => {
    setShowPopup(false);
  };

  const validationSchema = Yup.object().shape({
    inputValue: Yup.number()
      .typeError("Enter a monetary value")
      .required("Enter a monetary value"),
  });

  const formik = useFormik({
    initialValues: {
      inputValue: "",
    },
    validationSchema,
    onSubmit: () => {},
  });

  const handleInputChange = (e) => {
    const { value } = e.target;
    setBudgetValue(value);
    // setInputValueWhenClosed(value);
    formik.setFieldValue("inputValue", value);
  };

  const handleClickOutside = () => {
    if (formik.values.inputValue && !formik.values.inputValue.includes(".")) {
      formik.setFieldValue("inputValue", formik.values.inputValue + ".00");
    } else if (!formik.values.inputValue) {
      formik.setFieldValue("inputValue", "0.00");
    }
  };

  // const handleClick = () => {
  //   // setShowCampaignType(true);
  //   navigate("/campaignSettings");
  // };
  const handlePop = () => {
    // setShowCampaignType(true);
    setShowPopup(false);
    setIsClicked(true);
  };

  const handleToggleContent2 = () => {
    setIsExpanded2(!isExpanded2);
  };

  return (
    <>
      {/* <div className="container px-10"> */}
      <div className="px-10"></div>
      <div
        className="card"
        style={{
          marginTop: isExpanded2 ? "10px" : "0px",
          backgroundColor: isExpanded2 ? "" : "#E1E2E3",
          boxShadow: isExpanded2 ? "0 2px 8px rgba(0, 0, 0, 0.5)" : "",
        }}
      >
        {" "}
        {isExpanded2 ? null : (
          <div
            className="card-header"
            onClick={handleToggleContent2}
            onMouseOver={() => setHeaderColor2("#E1E2E3")}
            onMouseOut={() => setHeaderColor2("")}
            style={{
              backgroundColor: headerColor2 || "#FFFFFF", // Set default color to white
              cursor: "pointer",
              border: "1px solid #ced4da",
              transition: "background-color 0.3s ease",
              minHeight: "48px",
              // borderBottom: "none",
            }}
          >
            <div
              className="row gx-xl-12 gy-5 d-flex align-items-center"
              style={{ width: "100%" }}
            >
              <div className="col-xl-3">
                <h5 className="card-title">Budget</h5>
              </div>
              <div className="col-xl-8">
                {!isExpanded2 ? (
                  <h5
                    className="card-title"
                    style={{ color: "rgba(0,0,0,.54)" }}
                  >
                    <MdCurrencyRupee size={18} />{" "}
                    {budgetValue ? `${budgetValue}.00/day` : "0.00/day"}
                  </h5>
                ) : null}
              </div>
              <div className="col-xl-1 d-flex align-items-center justify-content-end">
                {isExpanded2 ? (
                  <BsChevronUp
                    className="arrow-icon"
                    style={{ marginTop: "20px" }}
                  />
                ) : (
                  <BsChevronDown
                    className="arrow-icon"
                    style={{ marginTop: "20px" }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {isExpanded2 && (
          <div className="card-body">
            <div className="row gx-xl-12 gy-10">
              <div className="col-xl-7">
                {/* <KTCard className="card h-lg-100 col-xxl-12"> */}
                <div
                  className="container p-1"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "10px",
                  }}
                >
                  <div className="row gy-5 gx-xl-10">
                    <div className="d-flex align-items-center">
                      <span
                        className="fw text-dark"
                        style={{ fontSize: "14px", color: "#3C4043" }}
                      >
                        Set your average daily budget for this campaign
                      </span>
                    </div>
                    <div className="flex-wrap align-items-center">
                      <div className=" flex-wrap align-items-center">
                        <div style={{ position: "relative" }}>
                          <input
                            type="text"
                            value={formik.values.inputValue}
                            onChange={handleInputChange}
                            onMouseEnter={handleMouseEnter3}
                            onMouseLeave={handleMouseLeave3}
                            onClick={handlePop}
                            onBlur={handleClickOutside}
                            // className={isClicked ? "blue" : ""}
                            style={{
                              padding: "10px",
                              paddingLeft: "55px",
                              width: "180px",
                              height: "50px",
                              borderRadius: "5px",
                              border: "1px solid #ced4da", // Set initial border to transparent
                              borderColor: `${
                                formik.errors.inputValue
                                  ? "red"
                                  : isClicked
                                  ? "#0d6efd"
                                  : "#ced4da"
                              }`,
                              borderWidth: "2px", // Set initial border width
                              color: "black",
                              outline: "none", // Remove default outline style
                              transition: "border-color 0.3s ease-in-out", // Add transition effect
                            }}
                          />
                          <div
                            className="px-4"
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "3px",
                              transform: "translateY(-50%)",
                              color: "gray",
                              pointerEvents: "none", // Ignore pointer events to allow clicking the input
                            }}
                          >
                            <MdCurrencyRupee size={15} />
                          </div>
                          {/* {showPopup && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "60%",
                                    right: "80%",
                                    backgroundColor: "#fff",
                                    padding: "5px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0)",
                                    zIndex: "2",
                                    width: "110px",
                                    height: "30px",
                                    fontSize: "10px",
                                    marginLeft: "500px",
                                    color: "#333",
                                  }}
                                >
                                  <div className="d-flex align-items-center ">
                                    Please fill this field
                                  </div>
                                </div>
                              )} */}
                        </div>
                        <div
                          className="mt-3 ml-5 px-5"
                          style={{ color: "#d93025" }}
                        >
                          {formik.errors.inputValue}
                        </div>
                        {budgetValue && parseInt(budgetValue) < 500 && (
                          <div
                            className="d-flex"
                            style={{
                              backgroundColor: "#FDF7E0",
                              width: "105%",
                              padding: "20px",
                              margin: "13px",
                              justifyContent: "space-between",
                              height: "80px",
                              fontSize: "14px",
                              color: "3C4043",
                            }}
                          >
                            <img
                              src={warning}
                              style={{
                                height: "38%",
                                width: "5%",
                                marginRight: "10px",
                              }}
                            />
                            Advertisers in your country (India) commonly use a
                            budget of at least ₹500. Consider increasing your
                            budget to improve your campaign's potential reach.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* </KTCard> */}
              </div>
              <div className="col-xl-1 d-flex align-items-center justify-content-center">
                <div
                  className="vertical-line"
                  style={{
                    width: "0.7px",
                    height: "100%",
                    backgroundColor: "#ccc",
                    marginLeft: "75px",
                  }}
                />
              </div>

              <div className="col-xl-3 pl-0 d-block">
                <span
                  className=""
                  style={{ fontSize: "12px", color: "3C4043" }}
                >
                  For the month, you won't pay more than your daily budget times
                  the average number of days in a month. Some days you might
                  spend less than your daily budget, and on others you might
                  spend up to twice as much. <a href="#"> Learn more</a>
                </span>
              </div>
              {isExpanded2 && (
                <div className="col-xl-1">
                  <BsChevronUp
                    className="arrow-icon"
                    onClick={handleToggleContent2}
                    style={{ marginTop: "5px" }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="">
        <div
          className="gap-2 my-5 d-flex"
          style={{ justifyContent: "flex-end" }}
        >
          <a
            href="#"
            className="btn btn-sm fw-bold btn-primary"
            onClick={handleClick}
            style={{ fontSize: "14px" }}
          >
            Next
          </a>
          {/* <Biding/> */}
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Budget;
