import React from "react";
import TrendSubComponent from "./TrendSubComponent";

type Props = {
  productId: string;
  platformId: string;
  startDate: string;
  endDate: string;
  channelType: string;
  campaignId: any;
  productType: any;
};

const TrendsComponent: React.FC<Props> = ({
  productId,
  platformId,
  startDate,
  endDate,
  channelType,
  campaignId,
  productType,
}: Props) => {
  const subUrl = `channel/${channelType}`;
  return (
    <div className="row gy-10 gx-xl-10 d-flex mb-10">
      <TrendSubComponent
        productType={productType}
        productId={productId}
        platformId={platformId}
        startDate={startDate}
        endDate={endDate}
        isGrowth={true}
        showCurrency={false}
        subUrl={`${channelType}/impressions`}
        title="Impressions"
        campaignId={campaignId}
      />
      <TrendSubComponent
        productType={productType}
        productId={productId}
        platformId={platformId}
        startDate={startDate}
        endDate={endDate}
        isGrowth={true}
        showCurrency={false}
        subUrl={`${channelType}/clicks`}
        title="Clicks"
        campaignId={campaignId}
      />
      <TrendSubComponent
        productType={productType}
        productId={productId}
        platformId={platformId}
        startDate={startDate}
        endDate={endDate}
        isGrowth={true}
        showCurrency={false}
        subUrl={`${channelType}/lead`}
        title="Lead"
        campaignId={campaignId}
      />
      <TrendSubComponent
        productType={productType}
        productId={productId}
        platformId={platformId}
        startDate={startDate}
        endDate={endDate}
        isGrowth={true}
        showCurrency={true}
        subUrl={`${channelType}/costs`}
        title="Costs"
        campaignId={campaignId}
      />
      <TrendSubComponent
        productType={productType}
        productId={productId}
        platformId={platformId}
        startDate={startDate}
        endDate={endDate}
        isGrowth={true}
        showCurrency={true}
        subUrl={`${channelType}/cpc`}
        title="CPC"
        campaignId={campaignId}
      />
      <TrendSubComponent
        productType={productType}
        productId={productId}
        platformId={platformId}
        startDate={startDate}
        endDate={endDate}
        isGrowth={true}
        showCurrency={true}
        subUrl={`${channelType}/cpm`}
        title="CPM"
        campaignId={campaignId}
      />
      <TrendSubComponent
        productType={productType}
        productId={productId}
        platformId={platformId}
        startDate={startDate}
        endDate={endDate}
        isGrowth={true}
        showCurrency={true}
        subUrl={`${channelType}/cpl`}
        title="CPL"
        campaignId={campaignId}
      />
      <TrendSubComponent
        productType={productType}
        productId={productId}
        platformId={platformId}
        startDate={startDate}
        endDate={endDate}
        isGrowth={true}
        showCurrency={false}
        subUrl={`${channelType}/finalconversions`}
        title="Final Conversions"
        campaignId={campaignId}
      />
    </div>
  );
};

export default TrendsComponent;
