import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { KTCard } from "../../../../theme/helpers";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsCurrencyRupee } from "react-icons/bs";
import { BsExclamationCircle } from "react-icons/bs";
import { MdSettings } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import search from "../../../../assets/search.png";
import display from "../../../../assets/display.png";
import { FaTimes } from "react-icons/fa";
import { Badge } from "react-bootstrap";
import { IoMdArrowDropdown } from "react-icons/io";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Dates = ({selectedStartDate,selectedEndDate,setSelectedStartDate,setSelectedEndDate}) => {
  
  const [isExpanded, setIsExpanded] = useState(false);
  const [headerColor, setHeaderColor] = useState("");

  const navigate = useNavigate();
  const [showCalendar, setShowCalendar] = useState(false);

  const handleDropdownClick = () => {
    setShowCalendar(!showCalendar);
  };

  const handleClick = () => {
    navigate("/campaignSettings");
  };

  const handleToggleContent = () => {
    setIsExpanded(!isExpanded);
  };
  // const [selectedStartDate, setSelectedStartDate] = useState(null);
  // const [selectedEndDate, setSelectedEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  return (
    <>
      <div
        className="card"
        style={{
          marginTop: isExpanded ? "10px" : "0px",
          backgroundColor: isExpanded ? "" : "#E1E2E3",
          boxShadow: isExpanded ? "0 2px 8px rgba(0, 0, 0, 0.5)" : "",
        }}
      >
        <div
          className="card-header"
          onClick={handleToggleContent}
          onMouseOver={() => setHeaderColor("#E1E2E3")}
          onMouseOut={() => setHeaderColor("")}
          style={{
            backgroundColor: headerColor || "#FFFFFF",
            cursor: "pointer",
            border: "1px solid #ced4da",
            transition: "background-color 0.3s ease",
            minHeight: "48px",
          }}
        >
          <div
            className="row gx-xl-12 gy-5 d-flex align-items-center"
            style={{ width: "100%" }}
          >
            <div className="col-xl-3">
          <h5 className="card-title">Start and end dates</h5>
          </div>
          <div className="col-xl-8"></div>
          <div className="col-xl-1 d-flex align-items-center justify-content-end">

          {isExpanded ? (
            <BsChevronUp className="arrow-icon" style={{ marginTop: "20px" }} />
          ) : (
            <BsChevronDown
              className="arrow-icon"
              style={{ marginTop: "20px" }}
            />
          )}
          </div>
          </div>
        </div>
        {isExpanded && (
          <div className="card-body">
            <div className="row gx-xl-12 gy-10">
              <div className="col-xl-8">
                <div
                  className="container p-1"
                  style={{ backgroundColor: "white", borderRadius: "10px" }}
                >
                  <div className="form-group">
                  <label htmlFor="dateInput">Start Date:</label>
                  <div className="input-group" style={{width:"20%"}}>
                    <DatePicker
                      id="dateInput"
                      selected={selectedStartDate}
                      onChange={handleStartDateChange}
                      className="form-control"
                      defaultValue={selectedStartDate}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="dateInput">End Date:</label>
                  <div className="input-group" style={{width:"20%"}}>
                    <DatePicker
                      id="dateInput"
                      selected={selectedEndDate}
                      onChange={handleEndDateChange}
                      className="form-control"
                      minDate={selectedStartDate}
                      defaultValue={selectedEndDate}
                    />
                  </div>
                </div>
                  {/* <div className="dropdown" id={`start`} style={{ width: "100px", textAlign: "left", fontSize: "13px", border: "1px solid black", borderRadius: "5px", marginLeft: "5px", height: "50px", position: "relative" }}> */}
                 
               
                  {/* </div> */}
                </div>
              </div>
              <div className="col-xl-4 d-flex">
                <div
                  className="vertical-line"
                  style={{
                    width: "0.7px",
                    height: "100%",
                    backgroundColor: "#ccc",
                    marginLeft: "20px",
                  }}
                />
                <div
                  className=""
                  style={{
                    fontSize: "13px",
                    color: "3C4043",
                    marginLeft: "60px",
                  }}
                >
                  Your ads will continue to run unless you specify an end date.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Dates;
