import React, { useEffect, useState } from "react";
import { AiOutlineCaretDown, AiOutlineProject } from "react-icons/ai";

const DoubleDropdown = ({
    selectedValue,
    dropdownItems,
    onDropdownItemClick,
    title,
    cancelClicked,
    responseValue,
    isForecastable
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [disabledItems, setDisabledItems] = useState<any>([]);
    const [selectedValues, setSelectedValues] = useState({});

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (cancelClicked) {
          setDisabledItems([]);
        }
      }, [cancelClicked]);

    const handleItemClick = (data) => {
        onDropdownItemClick(data);    
          console.log(selectedValue, 'jsonbnao');
        // setDisabledItems([...disabledItems, data]);
        toggleDropdown();
    };

    return (
        <div className="d-flex align-items-center px-5">
            <div
                className="dropdown px-1 custom-dropdown"
                style={{
                    width: "100%",
                    height: "50px",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                }}
            >
                <div
                    data-kt-daterangepicker="true"
                    data-kt-daterangepicker-opens="left"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className="d-flex align-items-center dropdown-toggle fw-bold"
                    style={{
                        border: "2px solid #E84A42",
                        height: "40px",
                        borderRadius: "10px",
                        width: "100%",
                        color: "#E84A42",
                        justifyContent: "center",
                        fontSize: '15px'
                    }}
                    onClick={toggleDropdown}
                >
                    <div className="pe-3" style={{ fontWeight: '100px' }}>{isForecastable? title: selectedValue || title}</div>
                    <div className="d-flex align-items-center px-5"
                        style={{
                            position: "absolute",
                            right: "10px", // Adjust the right value based on your design
                            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                            transition: "transform 0.3s ease-in-out",
                        }}
                    >
                        <AiOutlineCaretDown size={20} />
                    </div>
                </div>
                <ul
                    className={`dropdown-menu ${isOpen ? "show" : ""}`}
                    aria-labelledby="dropdownMenuButton1"
                    style={{ fontSize: '14px', width: '98%' }}
                >
                    {dropdownItems.map((data, index) => (
                        <li className=""
                            key={data?.productId || index}
                            onClick={() => !data.includes(':') &&  handleItemClick(data)}
                            style={{
                                cursor: data.includes(':') ? "not-allowed" : "pointer",
                                width: "100%",
                                fontSize: '14px',
                                opacity: data.includes(':') ? 0.6 : 1,
                            }}
                        >
                            <div className="dropdown-item text-hover-primary d-flex fw-semibold">
                                {data}
                                {/* {responseValue && data === selectedValue && (
                                    <div className="fw-bold ms-5 d-flex"> : <div className="ps-5">{responseValue}</div></div>
                                )} */}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default DoubleDropdown;
