/** @format */

import { FC, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Field, useFormik } from "formik";
import clsx from "clsx";

import { getOrganizations, getCategory, editResponse } from "../../../../application/_requests";
import { useMutation, useQuery } from "react-query";
import { Doc } from "../core/_models";
import { useParams } from "react-router-dom";
import { useQueryResponse } from "../core/QueryResponseProvider";
import { useListView } from "../core/ListViewProvider";

const editUserSchema = Yup.object().shape({
    category: Yup.string().label("").required("Please select participant"),
    organization: Yup.string().label("").required("Please select organization"),
    // fileName: Yup.mixed()
    //     .required("File is required")
    //     .test(
    //         "fileType",
    //         "Please upload a file type: pdf",
    //         (value) => value && value[0].type === "application/pdf"
    //     )
    //     .test(
    //         "fileSize",
    //         // "File size must not exceed 50 MB.",
    //         "File size is greater than 50 MB.",
    //         (value) => value && value[0].size <= 50 * 1024 * 1024
    //     ),
});

const EditResponseModalForm: FC<any> = ({ user, isUserLoading, onCancel }) => {
    const btnRef = useRef<HTMLButtonElement | null>(null);
    const { setToastMessage } = useListView();
    const { id } = useParams();
    const { refetch } = useQueryResponse();

    const {
        isFetching,
        refetch: refetchCat,
        data: category,
    } = useQuery(
        [`CATEGORY`],
        () => {
            return getCategory();
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
    );

    const {
        isFetching: isFetchingOrg,
        refetch: refetchOrg,
        data: organizations,
    } = useQuery(
        [`ORGANIZATION`],
        () => {
            return getOrganizations();
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
    );

    // const { mutate, isLoading } = useMutation('');

    const HandleSubmit = (values: any) => {
        btnRef.current?.setAttribute("data-kt-indicator", "on");
        const docRequest: Doc = {
            documentType: "SUBMISSION",
            draftId: id || "",
            subject: null,
            documentName: null,
            participantId: values.category,
            organisationId: values.organization,
        };
        // console.log(values)
        // console.log(docRequest)
        // const file = values.fileName[0];
        //	btnRef.current?.setAttribute('data-kt-indicator', 'on');

        const response = editResponse(docRequest, user.responseId)
            .then((res) => {
                btnRef.current?.removeAttribute("data-kt-indicator");
                onCancel();
                refetch();
                setToastMessage("Response Updated Successfully");
                setTimeout(() => {
                    setToastMessage(undefined);
                }, 2000);
            })
            .catch((err) => {
                alert("Request Failed!");
            });
    };
    const formik = useFormik({
        initialValues: {
            category: `${user.participantId}`,
            organization: `${user.organisationId}`,
        },
        validationSchema: editUserSchema,
        // validateOnBlur: false,
        onSubmit: (values) => {
            HandleSubmit(values);
        },
    });
    // console.log(organizations)
    return (
        <>
            <form
                id="kt_modal_add_user_form"
                className="form"
                onSubmit={formik.handleSubmit}
                noValidate
            >
                <div
                    className="d-flex flex-column scroll-y me-n7 pe-7"
                    id="kt_modal_add_user_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_user_header"
                    data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                    data-kt-scroll-offset="300px"
                >
                    <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">Participant</label>
                        <select
                            className={clsx(
                                "form-select form-select-light mb-3 mb-lg-0",
                                {
                                    "is-invalid":
                                        formik.touched.category && formik.errors.category,
                                },
                                {
                                    "is-valid":
                                        formik.touched.category && !formik.errors.category,
                                }
                            )}
                            aria-label="Select example"
                            {...formik.getFieldProps("category")}
                        >
                            <option selected={true} hidden={true} value="">
                                {`${user.participantName}`}
                            </option>
                            {category?.map((item: any, index: any) => (
                                <option key={index} value={item.participantId}>
                                    {item.participantName}
                                </option>
                            ))}
                        </select>
                        {formik.touched.category && formik.errors.category && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">{formik.errors.category}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">Organization</label>
                        <select
                            // className="form-select form-select-light"

                            className={clsx(
                                "form-select form-select-light mb-3 mb-lg-0",
                                {
                                    "is-invalid":
                                        formik.touched.organization && formik.errors.organization,
                                },
                                {
                                    "is-valid":
                                        formik.touched.organization && !formik.errors.organization,
                                }
                            )}
                            aria-label="Select example"
                            {...formik.getFieldProps("organization")}
                        >
                            <option selected={true} hidden={true} value="">
                                {`${user.organisationName}`}
                            </option>
                            {organizations?.map((item: any, index: any) => (
                                <option key={index} value={item.organisationId}>
                                    {item.organisationName}
                                </option>
                            ))}
                        </select>
                        {formik.touched.organization && formik.errors.organization && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">{formik.errors.organization}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">File</label>
                        <label className="form-control custom-file-upload p-0" title={user?.fileName}>
                            <button className="form-control disabled chooseFileBtn" type="button">Choose File</button>
                            <span className="fileName">{user?.fileName}</span>
                        </label>
                    </div>

                    <div className="d-flex justify-content-center">
                        <button
                            title="Discard"
                            type="reset"
                            className="btn btn-light mx-10"
                            data-kt-users-modal-action="cancel"
                            onClick={onCancel}
                        >
                            Discard
                        </button>

                        <button
                            title="Update"
                            ref={btnRef}
                            type="submit"
                            className="btn me-10 text-white btn-custom"
                            id="kt_button_1"
                            data-kt-users-modal-action="submit"
                        >
                            <span className="indicator-label">Update</span>
                            <span className="indicator-progress">
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};

export { EditResponseModalForm };
