import React, { useState } from "react";
import { AiOutlineCaretDown, AiOutlineProject } from "react-icons/ai";
import { IoMdArrowDropdown } from "react-icons/io";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { primaryColors } from "../../../../theme/assets/ts/_utils/AppColors";

const GeneralInputBox = ({
    selectedValue,
    onChange,
    title
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const hasSelect = new RegExp('select', 'i').test(title);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="d-flex align-items-center px-5" style={{height:'50px'}}>
            <input
            type="text"
            className="d-flex align-items-center"
            value={selectedValue}
            onChange={onChange}
            placeholder={title}
            style={{
                border: `2px solid ${primaryColors.red}`,
                cursor: 'pointer',
                height: "40px",
                borderRadius: "10px",
                width: "100%",
                color: 'grey',
                justifyContent: "center",
                fontSize: '15px',
                outline: 'none',
                fontWeight: 'bold',
            }}
        />
        </div>
    );
};

export default GeneralInputBox;
