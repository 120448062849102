/** @format */

import React from "react";
import { ConfigurationListWrapper } from "../../../modules/apps/user-management/users-list/ConfigurationList";

const Configuration = () => {
	return (
		<div>
			<ConfigurationListWrapper />
		</div>
	);
};

export default Configuration;
