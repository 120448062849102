import React, { useState } from "react";
// import Data from "./Data.json";
import { RiCheckLine } from "react-icons/ri";
import salesp from "../../../../assets/outline-local-offer-_2_.png";
import leadsp from "../../../../assets/outline-people-_1_.png";
import websitetraffic from "../../../../assets/website_traffic.png";
import productbr from "../../../../assets/auto-awesome.png";
import brandawareness from "../../../../assets/brand_awareness.png";
import AppPromo from "../../../../assets/app_promo.png";
import outlineroom from "../../../../assets/outline-room.png";
import settingslight from "../../../../assets/settings-light.png";
import { KTCard } from "../../../../theme/helpers";

type CardData = {
  id: number;
  title: string;
  description: string;
};


type Props = {
  id: number;
  "data-id": string;
  selectedCard: number;
  onSelect: (id: number, title:string) => void;
};

const CampCard = ({ id, selectedCard, onSelect }: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  

  const Data =
    [
      {
        "id": 1,
        "title": "Sales",
        "icon": "outline",
        "description": "Drive sales online, in-app, by phone, or in-store"
      },
      {
        "id": 2,
        "title": "Leads",
        "icon": "outline",
        "description": "Capture potential customer information for follow-up"
      },
      {
        "id": 3,
        "title": "Website traffic",
        "icon": "outline",
        "description": "Drive traffic to your website and increase conversions"
      },

      {
        "id": 4,
        "title": "Product and brand Consideration",
        "icon": "outline",
        "description": "Increase awareness and consideration for your brand"
      },
      {
        "id": 5,
        "title": "Brand awareness and reach",
        "icon": "outline",
        "description": "Create awareness and recognition for your brand"
      },
      {
        "id": 6,
        "title": "App Promotion",
        "icon": "outline",
        "description": "Promote your mobile app to drive installations and engagement"
      },
      {
        "id": 7,
        "title": "Local store visits and promotion",
        "icon": "outline",
        "description": "Drive foot traffic and sales to your local stores"
      },
      {
        "id": 8,
        "title": "Create a campaign without a goal's guidance",
        "icon": "outline",
        "description": "Set specific goals to measure the success of your campaigns"
      }
    ]



  let cardImage: JSX.Element | null = null;

  if (id === 1) {
    cardImage = <img src={salesp} alt="Sales" style={{ maxWidth: '30px', maxHeight: '30px' }} />;
  } else if (id === 2) {
    cardImage = <img src={leadsp} alt="Leads" style={{ maxWidth: '30px', maxHeight: '30px' }} />;
  } else if (id === 3) {
    cardImage = <img src={websitetraffic} alt="App Promotion" style={{ maxWidth: '30px', maxHeight: '30px' }} />;
  } else if (id === 4) {
    cardImage = <img src={productbr} alt="Product" style={{ maxWidth: '30px', maxHeight: '30px' }} />;
  } else if (id === 5) {
    cardImage = <img src={brandawareness} alt="Product" style={{ maxWidth: '30px', maxHeight: '30px' }} />;
  } else if (id === 6) {
    cardImage = <img src={AppPromo} alt="Product" style={{ maxWidth: '30px', maxHeight: '30px' }} />;
  } else if (id === 7) {
    cardImage = <img src={outlineroom} alt="Product" style={{ maxWidth: '30px', maxHeight: '30px' }} />;
  } else if (id === 8) {
    cardImage = <img src={settingslight} alt="Product" style={{ maxWidth: '30px', maxHeight: '30px' }} />;
  }



  const handleClick = () => {
    if (selectedCard === id) {
      onSelect(-1, ''); // Pass a different value to indicate deselection, e.g., -1
    } else {
      onSelect(id, cardData.title);
    }
  };

  const cardData = Data.find((card: CardData) => card.id === id) as CardData;

  if (!cardData) {
    return null;
  }

  const {title, description } = cardData;

  return (
    <KTCard>
      <div
        className=''
        style={{
          position: "relative",
          height: '194.4px',
          // width: '290px',
          overflow: '',
          // flexBasis: '100%', /* Set initial width to take up full row */
          flexGrow: '1', /* Allow components to grow and shrink equally */
          maxWidth: '100%', /* Prevent components from exceeding parent container width */
          // maxHeight: '100%',
          color: selectedCard === id ? "#0d6efd" : "#3c4043",
          cursor: "pointer",
          border: selectedCard === id ? "2px solid #0d6efd" : isHovered
            ? "2px solid #0d6efd"
            : "2px solid #CCCCCC",
          borderRadius: "0px",
          transition: "all 0.3s",
          transform: selectedCard === id ? "scale(1)" : isHovered ? "scale(1.1)" : "scale(1)",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {selectedCard === id && (
          <div
            className="overflow-element border-solid-primary"
            style={{
              position: "absolute",
              top: "-10px",
              overflow: '',
              right: "-10px",
              width: "28px",
              height: "28px",
              background: "white",
              border: "2px solid #0d6efd",
              borderWidth: "2px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#0d6efd",
              zIndex: 1,
            }}
          >
            <RiCheckLine size={20} />
          </div>
        )}
        <div className={`card h-lg-100 ${isHovered ? "btn-active-color-primary" : ""}`} style={{ overflow: 'auto' }}>
          <div className={`p-5 ${isHovered ? "clicked" : ""}`}>
            {cardImage}
            <br />
            <div className="card-title align-items-start flex-column mt-2" style={{ overflow: 'auto' }}>
              <div className="card-label mt-2 mb-3" style={{ font: "Roboto", fontSize: '14px', overflow: 'hidden',
               textOverflow: 'ellipsis', fontWeight: '540', lineHeight: '20px' }}>{title}</div>
              <div className="fw-semibold" style={{ font: "Google-sans", fontSize: '13px', fontSmooth: 'antialiased',
               fontWeight: '0.2px', letterSpacing: '0.5', overflow: 'hidden', textOverflow: 'ellipsis', 
               lineHeight: '15px' }}>{description}</div>
            </div>
          </div>
        </div>
      </div>
    </KTCard>
  );
};

export default CampCard;
