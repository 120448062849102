import React, { useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { primaryColors } from "../../../../theme/assets/ts/_utils/AppColors";
import { FiCalendar } from "react-icons/fi";

const GeneralDatePicker = ({
    selectedValue,
    onDropdownItemClick,
    title
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const hasSelect = new RegExp('select', 'i').test(title);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const formatDate = (dateString: string | undefined | null) => {
        try {
            if (!dateString) {
                return null;
            }

            const dateObject = new Date(dateString);

            if (isNaN(dateObject.getTime())) {
                throw new Error("Invalid date");
            }

            // Format date as yyyy-mm-dd
            const formattedDate = dateObject.toISOString().split('T')[0];
            console.log("Formatted Date:", formattedDate);
            return formattedDate;
        } catch (error: any) {
            console.error("Error formatting date:", error.message);
            return null; // Return null for invalid dates
        }
    };

    const formattedSelectedValue = selectedValue ? formatDate(selectedValue) : '';

    // Ensure formattedSelectedValue is always a string
    const placeholderValue = formattedSelectedValue ?? '';

    return (
        <div className="d-flex align-items-center px-5">
            <div
                className="dropdown px-1 custom-dropdown"
                style={{
                    width: "100%",
                    height: "50px",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                }}
            >
                <style>
                    {`
               .custom-dropdown .dropdown-toggle::after {
                content: none; /* Hide the default Bootstrap dropdown icon */
              }
            `}
                </style>
                <div
                    className="d-flex align-items-center dropdown-toggle fw-bold"
                    style={{
                        height: "40px",
                        borderRadius: "10px",
                        width: "100%",
                        color: primaryColors.red,
                        fontSize: '15px',
                        border: `2px solid ${primaryColors.red}`
                    }}>
                    <DatePicker
                        selected={selectedValue}
                        onChange={(date: Date) => onDropdownItemClick(date)}
                        onClick={toggleDropdown}
                        dateFormat="yyyy-MM-dd"
                        wrapperClassName="custom-datepicker-wrapper"
                        customInput={
                            <div className="d-flex" 
                            style={{ position: 'relative', width: '100%', fontWeight: 'bold', color: primaryColors.red }}
                                onClick={toggleDropdown}
                            >
                                <input
                                    placeholder={placeholderValue? placeholderValue: 'Select'}
                                    className="d-flex align-items-center"
                                    style={{
                                        width: '100% !important', 
                                        height: '32px',
                                        // paddingRight: selectedValue ? '120px' : '140px',
                                        border: '2px solid white',
                                        borderRadius: '10px',
                                        cursor: 'pointer',
                                        outline: 'none',
                                        fontWeight: 'bold',
                                        color: primaryColors.red,
                                        justifyContent: title === 'Select'?"":"center",
                                        fontSize: '15px',
                                    }}
                                />
                            </div>
                        }
                    />
                    <div className="d-flex align-items-center px-5"
                        style={{
                            position: "absolute",
                            right: "10px",
                            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                            transition: "transform 0.3s ease-in-out",
                        }}
                    >
                        <FiCalendar size={20} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GeneralDatePicker;

