import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { AiOutlineZoomIn } from "react-icons/ai";
import { IoTriangleSharp } from "react-icons/io5";
import { FormatUtil } from "../../../../theme/assets/ts/_utils/FormatUtil";
import {
  getDataFromBackend,
  URLS,
} from "../../../modules/dashboards/DashboardRequests";
import CustomLoader from "../marketting_component/CustomLoader";
import CustomBaselineTooltip from "../marketting_component/CustomBaselineTooltip";

type Props = {
  productId: string;
  platformId: string;
  campaignId: string;
  startDate: string;
  endDate: string;
  title: string;
  channelType: string;
  subUrl: string;
  baseLine: string;
};

interface ChannelDatum {
  title: string;
  value: number;
  baseLine: number;
}

interface ChannelData {
  product: string;
  data: ChannelDatum[];
}

const ChannelTableMarketing: React.FC<Props> = ({
  productId,
  startDate,
  endDate,
  title,
  channelType,
  subUrl,
  platformId,
  baseLine,
}) => {
  const [channelData, setChannelData] = useState<ChannelData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const requestData = {
          productId,
          startDate,
          endDate,
          platformId,
          baseLine,
        };
        const response = await getDataFromBackend(
          requestData,
          URLS.BASE_URL + channelType + subUrl
        );
        const data = response?.data || {};
        console.log(
          "reponse of channel table",
          data,
          URLS.BASE_URL + channelType + subUrl
        );
        setChannelData(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    getData();
  }, [productId, startDate, endDate, channelType, subUrl, baseLine]);

  const renderTableCell = (value, compare, isPercentage = false, title) => {
    const isTotalSpend =
      title === "Total Spends" ||
      title === "CPCL" ||
      title === "CPA" ||
      title === "CPC";
    const isRatio = title === "App Ratio" || title === "Conversion Ratio";
    const lossCss = {
      transform: "rotate(180deg)",
      marginRight: "2px",
    };
    const profitCss = { marginRight: "2px" };
    const backgroundColor = isPercentage ? "#f9d2b6" : "#b7ccdf";
    const color = isPercentage ? "black" : "#053C6D";
    const formattedValue = Number.isNaN(Number(value))
      ? "N/A"
      : Math.round(Number(value));

    const compareValue =
      ((Number(value) - Number(compare)) / Number(compare)) * 100;

    const formattedCompare =
      !isFinite(compareValue) || Number.isNaN(Number(compareValue))
        ? "N/A"
        : `${Math.abs(Number(compareValue.toFixed(2)))}%`;

    return (
      <td
        style={{
          borderTop: "1px solid black",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {isPercentage ? (
            <div
              style={{
                borderRadius: "10px",
                width: "100px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "5px",
                color,
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              {title === "Cost Parameter" || isTotalSpend
                ? `₹${formattedValue.toLocaleString("en-IN")}`
                : isRatio
                ? `${formattedValue.toLocaleString("en-IN")}%`
                : formattedValue.toLocaleString("en-IN")}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "10px",
                  width: "25px",
                  height: "20px",
                  backgroundColor: "#BFBFBF",
                  justifyContent: "center",
                  marginRight: "5px",
                }}
              >
                {value}
              </div>
              <div
                style={{
                  borderRadius: "10px",
                  width: "100%",
                  height: "20px",
                  marginBottom: "5px",
                  color,
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                {title}
              </div>
            </div>
          )}
          {isPercentage && (
            <div
              style={{
                position: "relative",
                width: "75px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                color: isTotalSpend
                  ? Number(compareValue) > 0
                    ? "#97291E"
                    : "green"
                  : Number(compareValue) > 0
                  ? "green"
                  : "#97291E",
              }}
              title={`Baseline: ${title === "Cost Parameter" || isTotalSpend
              ? `₹${compare.toLocaleString("en-IN")}`
              : isRatio
              ? `${compare.toLocaleString("en-IN")}%`
              : compare.toLocaleString("en-IN")}`}
            >
              <IoTriangleSharp
                size={10}
                style={Number(compareValue) > 0 ? profitCss : lossCss}
              />
              {formattedCompare}
            </div>
          )}
        </div>
      </td>
    );
  };

  const uniqueFields: string[] = [];

  // Loop through channelData to collect unique titles
  if(channelData && channelData.length>0){
    for (const loan of channelData) {
      for (const item of loan.data) {
        const title = item.title;
        if (!uniqueFields.includes(title)) {
          uniqueFields.push(title);
        }
      }
    }
  }
  

  // Add 'Type' as the first element in the array
  uniqueFields.unshift("Type");

  return (
    <div
      className="card h-lg-100 p-5"
      style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="fw-bold fs-4">{title}</div>
      </div>
      <div style={{ overflow: "auto" }}>
        <table
          id="kt_widget_table_3"
          className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3"
          data-kt-table-widget-3="all"
        >
          <thead>
            <tr>
              {uniqueFields.map((columnName, index) => (
                <th
                  style={{ textAlign: index == 0 ? "left" : "center" }}
                  key={index}
                  className="fw-bold"
                >
                  {columnName}
                </th>
              ))}
            </tr>
          </thead>
          {isLoading ? (
            <tbody
              className="py-5 d-flex align-self-center justify-content-center min-vh-50"
              id={title}
            >
              <tr className="" style={{ paddingLeft: "200px" }}>
                <CustomLoader />
              </tr>
            </tbody>
          ) : (
            <tbody>
              {channelData && channelData.length>0 && channelData?.map((data: any, index) => (
                <tr
                  key={index}
                  style={{
                    borderTop: "1px solid black",
                    // backgroundColor: "cyan",
                  }}
                >
                  {/* <td
                    style={{
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "10px",
                        width: "20px",
                        height: "20px",
                        backgroundColor: "#BFBFBF",
                        justifyContent: "center",
                        marginRight: "5px",
                      }}
                    >
                      {index + 1}
                    </div>
                    {data?.product || data?.platform}
                  </td> */}

                  {renderTableCell(
                    index + 1,
                    index + 1,
                    false,
                    data?.product || data?.platform
                  )}

                  {data.data.map((item, itemIndex) => {
                    return renderTableCell(
                      item?.value,
                      item?.baseLine,
                      true,
                      item?.title
                    );
                  })}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default ChannelTableMarketing;
