/** @format */

import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import * as am5xy from "@amcharts/amcharts5/xy";
import { getDataFromBackend, URLS } from "../../../modules/dashboards/DashboardRequests";
import CustomLoader from "../marketting_component/CustomLoader";
import { ChartTheme } from "../../../../theme/assets/ts/_utils/ChartTheme";

function AmAreaGraph({ chartID, productId, startDate, endDate, platformId, channelType, campaignId }) {

    type GraphData = {
        date: number;
        value: number;
    };

    interface DataEntry {
        data: { date: string; value: string }[];
        dataFor: "ctr" | "cost";
    }


    const [areGraphData, setAreGraphData] = useState<DataEntry[]>([]);
    const [transformedCTR, setTransformedCTR] = useState<{ date: number; value: number }[]>([]);
    const [transformedCost, setTransformedCost] = useState<{ date: number; value: number }[]>([]);

    useEffect(() => {
        const getData = async () => {
            let requestData = {
                productId,
                platformId,
                startDate,
                endDate,
                campaignId
            }
            let response = await getDataFromBackend(requestData, URLS.GET_CAMPAIGN_CTRVSCOST);
            console.log("Response of areagraph: ", response?.data);
            let data = response?.data;
            // setAreGraphData(data);
            if (response?.data) {
                setAreGraphData(data);
            } else {
                return
            }
        };

        getData(); // run it, run it

        return () => {
            // this now gets called when the component unmounts
        };
    }, [productId, platformId, campaignId, startDate, endDate]);


    useEffect(() => {
        if (areGraphData?.length > 0) {
            const newTransformedCTR: { date: number; value: number }[] = [];
            const newTransformedCost: { date: number; value: number }[] = [];

            for (const entry of areGraphData) {
                const dataType = entry.dataFor;
                const dataArray = entry.data;

                for (const data of dataArray) {
                    const date = new Date(data.date);
                    const value = parseFloat(data.value);

                    if (!isNaN(value)) {
                        let transformedValue: number;

                        if (dataType === "ctr") {
                            transformedValue = value;
                            newTransformedCTR.push({
                                date: date.getTime(),
                                value: transformedValue
                            });
                        } else if (dataType === "cost") {
                            transformedValue = Math.round(value);
                            newTransformedCost.push({
                                date: date.getTime(),
                                value: transformedValue
                            });
                        }
                    }
                }
            }

            setTransformedCTR(newTransformedCTR);
            setTransformedCost(newTransformedCost);
        }
    }, [areGraphData]);

    useLayoutEffect(() => {
        let root = am5.Root.new(chartID);
        root._logo?.dispose();
        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([ChartTheme.getGradientTheme(root)]);


        root.dateFormatter.setAll({
            dateFormat: "yyyy",
            dateFields: ["valueX"]
        });

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            focusable: true,
            panX: true,
            panY: true,
            wheelX: "none",
            wheelY: "none",
            pinchZoomX: true
        }));

        // Generate random data
        let date = new Date();
        date.setHours(0, 0, 0, 0);

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "zoomY"
        }));
        cursor.lineX.set("visible", false);

        function generateData(initialDate: Date, initialValue: number, daysToAdd: number): GraphData {
            const date = new Date(initialDate);
            date.setDate(date.getDate() + daysToAdd);

            const minValue = Math.max(0, initialValue); // Ensure the minimum value is 0 or greater
            const maxValue = initialValue + 5;
            const value = minValue + Math.round(Math.random() * (maxValue - minValue));

            return {
                date: date.getTime(),
                value: value
            };
        }

        function generateDatas(initialDate: Date, initialValue: number, count: number): GraphData[] {
            const data: GraphData[] = [];

            for (let i = 0; i < count; ++i) {
                const daysToAdd = i; // Increase the daysToAdd incrementally
                data.push(generateData(initialDate, initialValue, daysToAdd));
            }

            return data;
        }

        const initialDate = new Date();
        const initialValue = 0;
        const dataCount = 30;

        let xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                baseInterval: { timeUnit: "day", count: 1 },
                renderer: am5xy.AxisRendererX.new(root, {}),
                tooltip: am5.Tooltip.new(root, {})
            })
        );

        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );

        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        let series1 = chart.series.push(
            am5xy.LineSeries.new(root, {
                name: "Series",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                valueXField: "date",
                tooltip: am5.Tooltip.new(root, {
                    labelText: "Amount Spent : ₹ {valueY}"
                })
            })
        );

        series1.fills.template.setAll({ fillOpacity: 0.3, visible: true });

        // Add scrollbar
        // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/

        let series = chart.series.push(am5xy.LineSeries.new(root, {
            name: "Series",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
                labelText: "CTR : {valueY}%"
            })
        }));

        // Set data
        let data = generateDatas(initialDate, initialValue, dataCount);
        let data2 = generateDatas(initialDate, initialValue, dataCount);
        // series.data.setAll(data);
        // series1.data.setAll(data2);
        series.data.setAll(transformedCTR);
        series1.data.setAll(transformedCost);
        series1.appear(1000);
        chart.appear(1000, 100);
        console.log("transformedCTR", transformedCTR, transformedCost, data, data2)
        return () => {
            root.dispose();
        };

    }, [chartID, areGraphData, transformedCTR, transformedCost]);

    const [selectedItem, setSelectedItem] = useState(null);

    const handleDropdownItemClick = (data) => {
        console.log(data);
        setSelectedItem(data);
    };


    return (
        <div className="card card-flush h-lg-100 mb-10" style={{ boxShadow: '0 2px 8px rgba(0, 0, 0, 0.5)' }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div className="card-header">
                    <div className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-dark fs-6">CTR vs Amount Spent Per Day</span>
                    </div>
                </div>
            </div>
            {/* <div id={chartID} style={{ height: "380px", width: "100%" }}></div> */}
            {areGraphData?.length <= 0 ?
                <div id={chartID} style={{ alignSelf: 'center', marginTop: '25px' }}>
                    <CustomLoader />
                </div>
                :
                <div id={chartID} style={{ height: "380px", width: "100%" }}></div>
            }
        </div>
    );
}

export default AmAreaGraph;
