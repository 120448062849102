import React, { useState } from "react";
import MarketingStickGraph from "./marketting_component/MarketingStickGraph";
import AmChartStickGraph from "./marketting_component/AmChartStickGraph";

type Props = {
  productId: string;
  platformId: string;
  // campaignId: string;
  startDate: string;
  endDate: string;
  channelType: string;
  productType: string;
  baseLine: string;
};

const AmChartStickBarGrouped: React.FC<Props> = ({
  productId,
  platformId,
  startDate,
  endDate,
  channelType,
  productType,
  baseLine,
}: Props) => {
  return (
    <>
      <div className="row gy-5 gx-xl-10 mb-10">
        <div className="col-xl-4 ">
          <AmChartStickGraph
            platformId={platformId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            title={channelType === "platform" ? "CTR" : "CPCL"}
            chartID={"stick01"}
            channelType={channelType}
            subUrl={
              channelType === "platform"
                ? "ctrperformancebytype"
                : channelType === "marketing"
                ? "avgcpcl"
                : "avgctr"
            }
            productType={productType}
            baseLine={baseLine}
          />
        </div>
        <div className="col-xl-4 ">
          <AmChartStickGraph
            platformId={platformId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            title={channelType === "platform" ? "CPEL" : "CPEL"}
            chartID={"stick02"}
            channelType={channelType}
            subUrl={
              channelType === "platform" ? "cpelperformancebytype" : "avgcpel"
            }
            productType={productType}
            baseLine={baseLine}
          />
        </div>
        <div className="col-xl-4 ">
          <AmChartStickGraph
            platformId={platformId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            title={channelType === "platform" ? " CPA" : "CPA"}
            chartID={"stick03"}
            channelType={channelType}
            subUrl={
              channelType === "platform" ? "cpaperformancebytype" : "avgcpa"
            }
            baseLine={baseLine}
            productType={productType}
          />
        </div>
      </div>
    </>
  );
};

export default AmChartStickBarGrouped;
