import React, { useEffect, useLayoutEffect, useState } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_india from "@amcharts/amcharts5-geodata/india2020High";
import { ChartTheme } from "../../../../theme/assets/ts/_utils/ChartTheme";
import {
  URLS,
  getDataFromBackend,
} from "../../../modules/dashboards/DashboardRequests";
import CustomDropdown from "../marketting_component/CustomDropdown";
import CustomLoader from "../marketting_component/CustomLoader";

// will generate random heatmap data on every call
// const getHeatMapData = () => {
//   return [
//     { id: 'IN-AP', name: 'Andhra Pradesh', clicks: 19, info: { clicks: 19 } },
//     { id: 'IN-AR', name: 'Arunachal Pradesh', clicks: 52, info: { clicks: 19 } },
//     { id: 'IN-AS', name: 'Assam', clicks: 35, info: { clicks: 19 } },
//     { id: 'IN-BR', name: 'Bihar', clicks: 40, info: { clicks: 19 } },
//     { id: 'IN-CT', name: 'Chhattisgarh', clicks: 36, info: { clicks: 19 } },
//     { id: 'IN-GA', name: 'Goa', clicks: 21, info: { clicks: 19 } },
//     { id: 'IN-GJ', name: 'Gujarat', clicks: 22, info: { clicks: 19 } },
//     { id: 'IN-HR', name: 'Haryana', clicks: 32, info: { clicks: 19 } },
//     { id: 'IN-HP', name: 'Himachal Pradesh', clicks: 24, info: { clicks: 19 } },
//     { id: 'IN-JH', name: 'Jharkhand', clicks: 26, info: { clicks: 19 } },
//     { id: 'IN-KA', name: 'Karnataka', clicks: 27, info: { clicks: 19 } },
//     { id: 'IN-KL', name: 'Kerala', clicks: 60, info: { clicks: 19 } },
//     { id: 'IN-MP', name: 'Madhya Pradesh', clicks: 29, info: { clicks: 19 } },
//     { id: 'IN-MH', name: 'Maharashtra', clicks: 28, info: { clicks: 19 } },
//     { id: 'IN-MN', name: 'Manipur', clicks: 58, info: { clicks: 19 } },
//     { id: 'IN-ML', name: 'Meghalaya', clicks: 59, info: { clicks: 19 } },
//     { id: 'IN-MZ', name: 'Mizoram', clicks: 32, info: { clicks: 19 } },
//     { id: 'IN-NL', name: 'Nagaland', clicks: 59, info: { clicks: 19 } },
//     { id: 'IN-OR', name: 'Odisha', clicks: 59, info: { clicks: 19 } },
//     { id: 'IN-PB', name: 'Punjab', clicks: 21, info: { clicks: 19 } },
//     { id: 'IN-RJ', name: 'Rajasthan', clicks: 34, info: { clicks: 19 } },
//     { id: 'IN-SK', name: 'Sikkim', clicks: 49, info: { clicks: 19 } },
//     { id: 'IN-TN', name: 'Tamil Nadu', clicks: 39, info: { clicks: 19 } },
//     { id: 'IN-TG', name: 'Telangana', clicks: 45, info: { clicks: 19 } },
//     { id: 'IN-TR', name: 'Tripura', clicks: 14, info: { clicks: 19 } },
//     { id: 'IN-UT', name: 'Uttarakhand', clicks: 61, info: { clicks: 19 } },
//     { id: 'IN-UP', name: 'Uttar Pradesh', clicks: 15, info: { clicks: 19 } },
//     { id: 'IN-WB', name: 'West Bengal', clicks: 17, info: { clicks: 19 } },
//     { id: 'IN-AN', name: 'Andaman and Nicobar Islands', clicks: 32, info: { clicks: 19 } },
//     { id: 'IN-CH', name: 'Chandigarh', clicks: 61, info: { clicks: 19 } },
//     { id: 'IN-DN', name: 'Dadra and Nagar Haveli', clicks: 19, info: { clicks: 19 } },
//     { id: 'IN-DD', name: 'Daman and Diu', clicks: 20, info: { clicks: 19 } },
//     { id: 'IN-DL', name: 'Delhi', clicks: 59, info: { clicks: 19 } },
//     { id: 'IN-JK', name: 'Jammu and Kashmir', clicks: 25, info: { clicks: 19 } },
//     { id: 'IN-LK', name: 'Ladakh', clicks: 43, info: { clicks: 19 } },
//     { id: 'IN-LD', name: 'Lakshadweep', clicks: 37, info: { clicks: 19 } },
//     { id: 'IN-PY', name: 'Puducherry', clicks: 44, info: { clicks: 19 } }
//   ];
// };

type Props = {
  chartID: string;
  productId: string;
  startDate: string;
  endDate: string;
  height: string;
  marginTop: string;
  platformId: any;
  campaignId: any;
  productType: any;
};

const IndiaMapChart: React.FC<Props> = ({
  chartID,
  productId,
  startDate,
  endDate,
  height,
  marginTop,
  platformId,
  campaignId,
  productType,
}) => {
  let root;
  const [selectedItem, setSelectedItem] = useState<string>("Clicks");
  const [graphData, setGraphData] = useState<any>([]);

  const handleDropdownItemClick = (data: string) => {
    setSelectedItem(data);
  };

  useEffect(() => {
    root = am5.Root.new(chartID);
    root._logo?.dispose();
    root.setThemes([ChartTheme.getGradientTheme(root)]);

    // if (graphData?.length > 1) {
      const getData = async () => {
        let requestData = {
          productId,
          startDate,
          endDate,
          platformId,
          campaignId,
          productType,
        };
        let response = await getDataFromBackend(
          requestData,
          URLS.GET_LOCATION_STATE_MAP
        );
        console.log("Response in map : ", response?.data);
        let data = response?.data;
        setGraphData(data);
        showGraph(data, selectedItem.toLowerCase());
      };

      getData();
    // }
    //  else {
    //   showGraph(graphData, selectedItem?.toLowerCase());
    // }

    return () => {
      root?.dispose();
    };
  }, [
    chartID,
    productId,
    startDate,
    endDate,
    selectedItem,
    // graphData,
    platformId,
    campaignId,
    productType,
  ]);

  const showGraph = (data, valueField) => {
    if (data && data.length > 0) {
      let chart = root.container.children.push(
        am5map.MapChart.new(root, {
          pinchZoom: false,
          interactive: true,
        })
      );

      let polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodata_india,
          valueField: valueField,
          calculateAggregates: true,
          themes: [ChartTheme.getTheme(root)],
        })
      );

      polygonSeries.data.setAll(data);

      polygonSeries.mapPolygons.template.setAll({
        stroke: am5.color("#ad1e23"),
        strokeWidth: 1,
        fillOpacity: 1,
      });

      // polygonSeries.mapPolygons.template.states.create("hover", {
      //   fill: am5.color(0xFF99001F)
      // });

      polygonSeries.set("heatRules", [
        {
          target: polygonSeries.mapPolygons.template,
          dataField: "value",
          min: am5.color("#fde5d2"),
          max: am5.color("#E84A42"),
          key: "fill",
        },
      ]);

      // let tooltip = polygonSeries.set("tooltip", am5.Tooltip.new(root, {}));
      // tooltip.label.set("text", "{value} clicks in {name}");
      polygonSeries.mapPolygons.template.setAll({
        tooltipText: selectedItem==="Cost"||selectedItem==="CPC"? " ₹{value} " + valueField + " in {name}":selectedItem==="CTR"?" {value}% " + valueField + " in {name}": " {value} " + valueField + " in {name}",
        interactive: true,
      });

      let heatLegend = chart.children.push(
        am5.HeatLegend.new(root, {
          orientation: "horizontal",
          startColor: am5.color("#fde5d2"),
          endColor: am5.color("#E84A42"),
          startText: "Lowest",
          endText: "Highest",
          stepCount: 29,
          // centerX:am5.p0,
          // centerY:am5.p100
          dy: 370,
          interactive: true,
        })
      );

      // polygonSeries.mapPolygons.template.events.on("pointerover", (ev) => {
      //   console.log("EV : ", ev);
      //   // heatLegend.showValue(231);
      //   if (ev?.target?.dataItem) {
      //     // console.log("data Item : ", ev?.target?.dataItem);
      //     // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      //     // heatLegend.showValue(ev.target.dataItem?.get(selectedItem?.toLowerCase()));
      //   }
      // });

      heatLegend.startLabel.setAll({
        fontSize: 12,
        draggable: false,
        showTooltipOn: "hover",
        fill: heatLegend.get("startColor"),
      });

      heatLegend.endLabel.setAll({
        fontSize: 12,
        draggable: false,
        showTooltipOn: "hover",
        fill: heatLegend.get("endColor"),
      });

      // change this to template when possible
      polygonSeries.events.on("datavalidated", function () {
        heatLegend.set("startValue", polygonSeries.getPrivate("valueLow"));
        heatLegend.set("endValue", polygonSeries.getPrivate("valueHigh"));
      });
    }
  };

  const dropdownData = ["Clicks", "Impression", "Cost", "CTR", "CPC"];

  return (
    <div
      className="card card-flush h-lg-100 p-5"
      style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
    >
      {/* need to change it as forn and ui is diff make it same in future just for sake of simlicity doing this  */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-dark fs-6">
            {selectedItem} by Location
          </span>
        </div>
        <CustomDropdown
          dropdownData={dropdownData}
          selectedItem={selectedItem}
          onItemClick={handleDropdownItemClick}
        />
      </div>
      {graphData?.length < 1 && (
        <div style={{ alignSelf: "center" }}>
          <CustomLoader />
        </div>
      )}
      <div style={{ marginTop: marginTop }}>
        <div id={chartID} style={{ height: height, width: "100%" }}></div>
      </div>
    </div>
  );
};

export default IndiaMapChart;
