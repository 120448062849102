import React, { useEffect, useLayoutEffect, useState } from "react";
import { AiOutlineZoomIn } from "react-icons/ai";
import { IoTriangleSharp } from "react-icons/io5";
import { FormatUtil } from "../../../../theme/assets/ts/_utils/FormatUtil";
import {
  getDataFromBackend,
  URLS,
} from "../../../modules/dashboards/DashboardRequests";
import CustomLoader from "../marketting_component/CustomLoader";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { ChartTheme } from "../../../../theme/assets/ts/_utils/ChartTheme";

type Props = {
  chartId: string;
  barData: any;
  xValue: string;
  title: string;
};

const ChannelTableBarGraph: React.FC<Props> = ({
  chartId,
  barData,
  xValue,
  title
}) => {
  console.log("barData ===>", barData, xValue);
  console.log(title,'tix')

  useLayoutEffect(() => {
    let root = am5.Root.new(chartId);
    root._logo?.dispose();
    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([ChartTheme.getTheme(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
      })
    );

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
    });

    xRenderer.grid.template.setAll({
      location: 1,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: "campaign",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
        }),
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: xValue,
        sequencedInterpolation: true,
        categoryXField: "campaign",
        // tooltip: am5.Tooltip.new(root, {
        //   labelText: "{valueY}",
        // }
        // ),
      })
    );

    series.columns.template.setAll({
      height: am5.percent(70),
      strokeOpacity: 1,
      tooltipText:title==='Cost Parameter'?
      "{categoryX} : ₹{valueY}":" {categoryX} : {valueY}",
      tooltipY: am5.percent(10),
    });

    series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      strokeOpacity: 0,
    });

    let data = barData.map((item) => ({
      ...item,
      [xValue]: Math.ceil(parseFloat(item[xValue])),
    }));

    console.log("dataaaaaaaaaa", [xValue], data);

    xAxis.data.setAll(data);
    series.data.setAll(data);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);
    chart.appear(1000, 100);
  }, [title]);

  return (
    <>
      <div
        id={chartId}
        className="ms-n5 min-h-auto"
        style={{
          height: "280px",
          width: "50%",
        }}
      >
        <div className="d-flex justify-content-center fs-2 fw-semibold">
          {xValue === 'cpc' || xValue === 'cpel'|| xValue === 'ctr'|| xValue === 'cpa' ? 
          xValue.toUpperCase() : xValue.charAt(0).toUpperCase() + xValue.slice(1).toLowerCase()}
          </div>
      </div>
    </>
  );
};

export default ChannelTableBarGraph;
