/** @format */

import { Dispatch, SetStateAction } from "react";

export type ID = undefined | null | number | boolean | any;

export type PaginationState = {
	total: number;
	from: number;
	to: number;
	page: number;
	items_per_page: 10 | 30 | 50 | 100;
	links?: Array<{
		label: string;
		active: boolean;
		url: string | null;
		page: number | null;
	}>;
};

export type SortState = {
	sort?: string;
	order?: "asc" | "desc";
};

export type FilterState = {
	filter?: unknown;
};

export type SearchState = {
	search?: string;
};

export type Response<T> = {
	total: boolean;
	limit?: any;
	skip: any;
	users?: any;
	data?: T;
	payload?: {
		message?: string;
		errors?: {
			[key: string]: Array<string>;
		};
		pagination?: PaginationState;
	};
};

export type QueryState = PaginationState &
	SortState &
	FilterState &
	SearchState;

export type QueryRequestContextProps = {
	state: QueryState;
	updateState: (updates: Partial<QueryState>) => void;
};

export const initialQueryState: QueryState = {
	page: 1,
	items_per_page: 10,
	total: 0,
	from: 0,
	to: 0
};

export const initialQueryRequest: QueryRequestContextProps = {
	state: initialQueryState,
	updateState: () => {},
};

export type QueryResponseContextProps<T> = {
	response?: Response<Array<T>> | undefined | any;
	refetch: () => void;
	isLoading: boolean;
	isFetching: boolean;
	query: string;
};

export const initialQueryResponse = {
	refetch: () => {},
	isLoading: false,
	isFetching: false,
	query: "",
};

export type ListViewContextProps = {
	selected: Array<ID>;
	onSelect: (selectedId: ID) => void;
	onSelectAll: () => void;
	clearSelected: () => void;
	// NULL => (CREATION MODE) | MODAL IS OPENED
	// NUMBER => (EDIT MODE) | MODAL IS OPENED
	// UNDEFINED => MODAL IS CLOSED
	show?: boolean;
	setShow?: Dispatch<SetStateAction<boolean | undefined>>;
	itemIdForUpdate?: ID;
	itemIdForDelete?: ID;
	toastMessage?: ID;
	setToastMessage: Dispatch<SetStateAction<ID>>;
	setItemIdForUpdate: Dispatch<SetStateAction<ID>>;
	setItemIdForDelete: Dispatch<SetStateAction<ID>>;
	setItemIdForAddSubmission?: Dispatch<SetStateAction<ID>>;
	isAllSelected: boolean;
	disabled: boolean;
};

export const initialListView: ListViewContextProps = {
	selected: [],
	onSelect: () => {},
	onSelectAll: () => {},
	clearSelected: () => {},
	setItemIdForUpdate: () => {},
	setToastMessage: () => {},
	setShow: () => {},
	setItemIdForDelete: () => {},
	setItemIdForAddSubmission: () => {},
	isAllSelected: false,
	disabled: false,
};
