import React, { useState, useEffect } from "react";
import { AiOutlineCaretDown, AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { primaryColors } from "../../../../theme/assets/ts/_utils/AppColors";
import { MdOutlineCancel } from "react-icons/md";

const GeneralMultiSelectDropdown = ({
    selectedValue,
    dropdownItems,
    onDropdownItemClick,
    title,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredItems, setFilteredItems] = useState<any>([]);
    const [selectedOptions, setSelectedOptions] = useState<any>([]);
    console.log("selectedOptions",selectedOptions);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        setFilteredItems(dropdownItems);
    }, [dropdownItems]);

    const handleSearch = (value) => {
        setSearchTerm(value);
        if (value.trim() === "") {
            setFilteredItems(dropdownItems);
        } else {
            const filtered = dropdownItems.filter((item) =>
                item.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredItems(filtered);
        }
    };

    const handleItemClick = (item) => {
        const index = selectedOptions.indexOf(item);
        if (index === -1) {
            setSelectedOptions([...selectedOptions, item]);
            onDropdownItemClick([...selectedOptions, item]);
        } else {
            const updatedSelection = [...selectedOptions];
            updatedSelection.splice(index, 1);
            setSelectedOptions(updatedSelection);
            onDropdownItemClick(updatedSelection);
        }
    };

    const handleRemoveOption = (item) => {
        const updatedSelection = selectedOptions.filter(
            (option) => option !== item
        );
        setSelectedOptions(updatedSelection);
        onDropdownItemClick(updatedSelection);

    };

    return (
        <div className="d-flex align-items-center px-5">
            <div
                className="dropdown px-1 custom-dropdown"
                style={{
                    width: "100%",
                    height: "50px",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    
                }}
            >
                <style>
                    {`
             .custom-dropdown .dropdown-toggle::after {
              content: none; /* Hide the default Bootstrap dropdown icon */
            }
          `}
                </style>
                <div
                    data-kt-daterangepicker="true"
                    data-kt-daterangepicker-opens="left"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className="d-flex align-items-center dropdown-toggle fw-bold"
                    style={{
                        border: `2px solid ${primaryColors.red}`,
                        height: "40px",
                        borderRadius: "10px",
                        width: "100%",
                        color: primaryColors.red,
                        justifyContent: selectedOptions?"":"center",
                        fontSize: "15px",
                        position: "relative",
                    }}
                    onClick={toggleDropdown}
                >
                    <div
                        className="pe-3 fw-bold d-flex align-items-center"
                        style={{
                            color: title === "Select" ? "#797575" : primaryColors.red,
                            width:'80%',
                            overflowX: 'hidden',
                            
                            // justifyContent: selectedOptions.length > 0?"":"",
                        }}
                    >

                        {selectedOptions && selectedOptions.length > 0 ? (
                            <div className="ps-3">
                                {selectedOptions.reduce((acc, option, index) => {
                                    console.log("Inhere",isOpen)
                                    const displayOption = option.length > 10 ? option.substring(0, 10) + '...' : option;
                                    if (index === selectedOptions.length - 1) {
                                        return acc + displayOption;
                                    } else {
                                        return acc + displayOption + ', ';
                                    }
                                }, '')}
                            </div>
                        ): <div className="ps-3">Select</div>}


                    </div>
                    {/* {selectedOptions?.length>25? '...':''} */}
                    <div
                        className="d-flex align-items-center px-5"
                        style={{
                            position: "absolute",
                            right: "5px", // Adjust the right value based on your design
                            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                            transition: "transform 0.3s ease-in-out",
                            
                        }}
                    >
                        <AiOutlineCaretDown size={20} />
                    </div>
                </div>
                <ul
                    className={`dropdown-menu ${isOpen ? "show" : ""}`}
                    aria-labelledby="dropdownMenuButton1"
                    style={{ fontSize: "14px", width: "98%" , border:`2px solid ${primaryColors.red}`}}
                >
                    <li className='d-flex align-items-center pb-1 px-3'
                        style={{
                            borderBottom: `1px solid ${primaryColors.grey}`, width: '100%',
                            overflowX: 'auto', color: primaryColors.black, height: '60px', paddingTop: '-5px'
                        }}>

                        {selectedOptions?.map((option, index) => (
                            <div key={index} className="selected-option px-4 py-2 d-flex align-items-center mx-2"
                                style={{
                                    backgroundColor: primaryColors.pink,
                                    border: `0px solid ${primaryColors.pink}`,
                                    borderRadius: '50px',
                                    fontSize: '13px',
                                    height: '30px'
                                }}>
                                <div className="me-2 d-flex inline-block" style={{ width: '80px' }}>
                                    {option.length > 7 ? option.substring(0, 7) + "..." : option}
                                </div>
                                <MdOutlineCancel
                                    className="close-icon"
                                    size={17}
                                    style={{ color: primaryColors.black }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveOption(option);
                                    }}
                                />
                            </div>
                        ))}

                        {selectedOptions.length > 0 ? '' : <div style={{ color: primaryColors.grey }}>No options selected yet</div>}
                    </li>

                    <li className="search-row" style={{ borderBottom: `1px solid ${primaryColors.grey}`, }}>
                        <div style={{ position: "relative" }}>
                            <AiOutlineSearch
                                size={20}
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "10px",
                                    transform: "translateY(-50%)",
                                    color: primaryColors.pink,
                                }}
                            />
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={(e) => handleSearch(e.target.value)}
                                placeholder="Search"
                                style={{
                                    width: "calc(100% - 30px)", // Adjust the width to accommodate the icon
                                    height: "40px",
                                    padding: "8px 8px 8px 30px", // Adjust the padding to give space for the icon
                                    border: `0px solid ${primaryColors.white}`,
                                    borderRadius: "10px",
                                }}
                            />
                        </div>
                    </li>

                    <li className="py-3" style={{ height: '200px', overflowY: 'auto' }}>
                        {filteredItems?.map((data, index) => (
                            <div
                                key={data?.productId}
                                className="d-flex align-items-center px-5 py-1"
                                onClick={() => handleItemClick(data)}
                                style={{
                                    cursor: "pointer",
                                    width: "100%",
                                    fontSize: "14px",
                                    marginBottom: "5px",
                                }}
                            >
                                <div className="pe-5">
                                    <input
                                        type="checkbox"
                                        checked={selectedOptions.includes(data)}
                                        style={{ height: '15px', width: '15px', borderRadius: '-30px', color: primaryColors.red }}
                                        readOnly
                                    />
                                </div>
                                <div>{data}</div>
                            </div>
                        ))}
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default GeneralMultiSelectDropdown;
