import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import { ChartTheme } from "../../../assets/ts/_utils/ChartTheme";
import { data } from "jquery";
import {
  getDataFromBackend,
  URLS,
} from "../../../../app/modules/dashboards/DashboardRequests";
import CustomLoader from "../../../../app/pages/dashboard/marketting_component/CustomLoader";

interface GenderDataItem {
  gender: string;
  value: number;
  percentage: string;
}

function GenderDonutChart({
  chartID,
  platformId,
  productId,
  startDate,
  endDate,
  channelType,
  campaignId,
  title,
}) {
  const [genderData, setGenderData] = useState<
    Array<{
      data: Array<{
        value: number;
        gender: string;
      }>;
      dataFor: string;
    }>
  >([]);

  const [formattedGenderData, setFormattedGenderData] = useState<
    GenderDataItem[]
  >([]);
  const [selectedItem, setSelectedItem] = useState("clicks");
  const [datas, setDatas] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<
    Array<{
      value: number;
      gender: string;
    }>
  >([]);
  const [dropdownData, setDropdownData] = useState<string[]>([]);

  // const dropdownData = [
  // 	"Impression",
  // 	"Clicks",
  // 	"CTR",
  // 	"Cost",
  // ];

  useEffect(() => {
    const getData = async () => {
      let requestData = {
        productId,
        platformId,
        startDate,
        endDate,
        campaignId,
      };
      let response = await getDataFromBackend(
        requestData,
        URLS.GET_CAMPAIGN_GENDERSTATS
      );
      console.log("Response of GET_CAMPAIGN_GENDERSTATS : ", response?.data);
      // setGenderData(response.data);
      if (response?.data) {
        setGenderData(response?.data);
      } else {
        return;
      }
    };

    getData();

    return () => {};
  }, [productId, platformId, campaignId, startDate, endDate]);
  useEffect(() => {
    if (genderData?.length > 0) {
      const mockDropdown = Array.from(
        new Set(genderData?.map((item) => item.dataFor))
      );
      setDropdownData(mockDropdown);
      const selectedData = genderData?.filter(
        (item) => item.dataFor === selectedItem
      );
      const newData = selectedData[0]?.data.map((item) => ({
        ...item,
      }));
      setFilteredData(newData);
      console.log(newData, "selected data");
    }
  }, [genderData, selectedItem]);

  useEffect(() => {
    let totalValue = filteredData.reduce((sum, item) => sum + item.value, 0);
    console.log(totalValue, "tots");

    const formattedData = filteredData.map((item) => ({
      ...item,
      percentage: ((item.value / totalValue) * 100).toFixed(1),
    }));

    setFormattedGenderData(formattedData);
  }, [filteredData]);

  useLayoutEffect(() => {
    var root = am5.Root.new(chartID);
    root._logo?.dispose();

    root.setThemes([ChartTheme.getTheme(root)]);

    var chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        radius: 78,
        innerRadius: 38,
      })
    );
    let data = formattedGenderData;

    var series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: "Series",
        valueField: "percentage",
        categoryField: "gender",
        alignLabels: true,
      })
    );

    let tooltip: any = am5.Tooltip.new(root, {
      getFillFromSprite: true,
      text: am5.color(0x000000),
    });

    tooltip.get("background").setAll({
      fill: am5.color(0x000000),
      fillOpacity: 0.8,
    });

    series.set("tooltip", tooltip);

    series.data.setAll(data);
    series.labels.template.setAll({
      text: "[fontSize: 13px]{gender}: {percentage}%",
      textType: "circular",
      inside: false,
      radius: -20,
    });

    series.ticks.template.set("forceHidden", true);

    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartID, filteredData, formattedGenderData]);

  console.log("formattedGenderData", formattedGenderData);

  return (
    <div
      className="card h-lg-100 py-5 px-7"
      style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          className="text-start fw-bold text-gray-800 fs-4"
          style={{ paddingTop: "7px", paddingBottom: "9px" }}
        >
          <span className="card-label fw-bold text-dark fs-6">{title}</span>
        </div>
        <div className="d-flex align-items-center fw-bold flex-end">
          <div className="dropdown">
            <div
              data-kt-daterangepicker="true"
              data-kt-daterangepicker-opens="left"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary d-flex align-items-center px-0 dropdown-toggle"
            >
              {selectedItem || "clicks"}
            </div>
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="dropdownMenuButton1"
            >
              {dropdownData.map((data) => (
                <li
                  key={data}
                  onClick={() => {
                    setSelectedItem(data);
                    console.log(data, "dddhh");
                  }}
                >
                  <span className="dropdown-item text-hover-primary">
                    {data}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="card-body d-flex justify-content-between align-items-start flex-column">
        {/* <div id={chartID} style={{ height: "200px", width: "100%" }}></div> */}
        {filteredData?.length > 0 ? (
          <div id={chartID} style={{ height: "200px", width: "100%" }}></div>
        ) : (
          <div
            id={chartID}
            style={{ alignSelf: "center", marginBottom: "30px" }}
          >
            <CustomLoader />
          </div>
        )}
      </div>
    </div>
  );
}
export default GenderDonutChart;
