import { useState, useRef, useEffect } from "react";
import { BsBox2, BsChevronDown, BsChevronUp, BsX } from "react-icons/bs";
import { KTCard } from "../../../../theme/helpers";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsCurrencyRupee } from "react-icons/bs";
import { BsExclamationCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { IoMdLink } from "react-icons/io";
import { MdCurrencyRupee, MdOutlineCurrencyRupee } from "react-icons/md";
// import { useFormik } from "formik";
import * as Yup from "yup";
import CustomQuestion from "./customQuestion";

const CancelContinue = ({onClick}) => {

	const navigate = useNavigate();

  return (
    <>
      <div className="gap-2 mt-5 d-flex" style={{ justifyContent: "flex-end" }}>
          <a
            href="#"
            onClick={() => navigate(-1)}
            className="btn btn-sm btn-light fw-bold btn-active-color-primary text-primary"
            style={{ fontSize: '14px' }}
          >
            Cancel
          </a>
          <a
            href="#"
            className="btn btn-sm fw-bold btn-primary"
            onClick={onClick}
            style={{ fontSize: '14px' }}
          >
            Continue
          </a>
        </div>
    </>
  );
};

export default CancelContinue;
