import {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";

import * as authHelper from "./auth/core/AuthHelpers";

const auth = authHelper.getAuth();

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    //TODO need to comment out the request and auth before deployment to PROD
    console.info(`[request] [${JSON.stringify(config)}]`);
    console.info(`[auth] [${JSON.stringify(auth)}]`);
    return config;
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    console.error(`[request error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
    //TODO need to comment out the response before deployment to PROD
    console.info(`[response] [${JSON.stringify(response)}]`);
    return response;
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    console.error(`[response error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
}