/**
 * /* eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
// import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { login } from "../core/_requests";
import { useAuth } from "../core/Auth";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { appColor, primaryColors } from "../../../../theme/assets/ts/_utils/AppColors";

const loginSchema = Yup.object().shape({
	email: Yup.string()
		.label("")
		.email("Invalid Email")
		.min(3, "Minimum 3 symbols")
		.max(50, "Maximum 50 symbols")
		.required("Email is required"),
	password: Yup.string()
		//.min(3, 'Minimum 3 symbols')
		.max(50, "Maximum 50 symbols")
		.required("Password is required"),
});

const initialValues = {
	email: "",
	password: "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
	const [loading, setLoading] = useState(false);
	const { saveAuth, setCurrentUser, logout } = useAuth();
	const [type, settype] = useState("password");

	const formik = useFormik({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			setLoading(true);
			try {
				console.log("hiiiiii")
				// let logoutTime: string | any = process.env.REACT_APP_LOGOUT_TIME;
				const res = await login(values.email, values.password);

				let auth = res?.data.data;
				// let auth = {
				// 	"token": "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzdW1pdGRleUB0cmlzeXNpdC5jb20iLCJyb2xlcyI6IkFETUlOIiwiaWF0IjoxNjk0MDg1NDI1LCJleHAiOjk0NzAwODU0MjV9.1ZoG_Hp_Ke9MCUZwfq_qnQqveVxa-vs1QWcg6oV3rA0",
				// 	"username": "sumit@trisysit.com",
				// 	"mobile": null,
				// 	"email": "sumit@trisysit.com",
				// 	"roles": ["ADMIN"],
				// 	"sourceId": "59bd77db-dee9-40bf-bda4-b44460229fd3"
				// };
				saveAuth(auth);
				// const {data: user} = await getUserByToken(auth.api_token)
				setCurrentUser(auth);
				// setTimeout(() => {
				// 	logout();
				// }, parseInt(logoutTime));
			} catch (error: any) {
				saveAuth(undefined);
				let statusFunc = () => {
					if (error.response.status === 403) {
						return `${error.response.data.data}` || "Invalid Credentials";
					} else if (error.response.status === 404) {
						return `${error.response.data.data}` || "User does not exists !";
					}
				};
				setStatus(statusFunc());
				setSubmitting(false);
				setLoading(false);
			}
		},
	});

	return (
		<div className="card p-20 " style={{ width: "400px" }}>
			<form
				className="form w-100"
				onSubmit={formik.handleSubmit}
				noValidate
				id="kt_login_signin_form"
			>
				{/* begin::Heading */}
				<div className="text-center mb-11">
					<h1 className="text-dark fw-bolder mb-1">Sign In</h1>
					{/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
				</div>

				{formik.status ? (
					<div className="mb-lg-11 alert alert-danger text-center">
						<div className="alert-text font-weight-bold">{formik.status}</div>
					</div>
				) : null}

				{/* begin::Form group */}
				<div className="fv-row mb-8">
					<label className="form-label fs-6 fw-bolder text-dark">Email</label>
					<input
						placeholder="Email"
						{...formik.getFieldProps("email")}
						className={clsx(
							"form-control form-control-light mb-3 mb-lg-0",
							{
								"is-invalid": formik.touched.email && formik.errors.email,
							},
							{
								"is-valid": formik.touched.email && !formik.errors.email,
							}
						)}
						type="email"
						name="email"
						autoComplete="off"
					/>
					{formik.touched.email && formik.errors.email && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{formik.errors.email}</span>
							</div>
						</div>
					)}
				</div>
				{/* end::Form group */}

				{/* begin::Form group */}
				<div className="fv-row mb-3" style={{ position: "relative" }}>
					<label className="form-label fw-bolder text-dark fs-6 mb-2">
						Password
					</label>
					<input
						placeholder="Password"
						type={type}
						autoComplete="off"
						{...formik.getFieldProps("password")}
						className={clsx(
							"form-control form-control-light mb-3 mb-lg-0",
							{
								"is-invalid": formik.touched.password && formik.errors.password,
							},
							{
								"is-valid": formik.touched.password && !formik.errors.password,
							}
						)}
					/>
					<div
						style={{
							position: "absolute",
							right: 10,
							marginTop: 0,
							top: 39,
							cursor: "pointer",
						}}
						onClick={() =>
							type == "text" ? settype("password") : settype("text")
						}
					>
						{type == "password" ? (
							<FaEyeSlash size={20} title="Show" />
						) : (
							<FaEye size={20} title="Hide" />
						)}
					</div>
					{formik.touched.password && formik.errors.password && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{formik.errors.password}</span>
							</div>
						</div>
					)}
				</div>
				{/* end::Form group */}

				{/* begin::Wrapper */}
				<div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">

					{/* begin::Link */}
					{/* <Link
						to="/auth/forgot-password"
						className="link-dark link-hover"
						title="Forgot Password?"
					>
						Forgot Password?
					</Link> */}
					{/* end::Link */}
				</div>
				{/* end::Wrapper */}

				{/* begin::Action */}
				<div className="d-grid mb-2">
					<button
						title="Sign In"
						type="submit"
						id="kt_sign_in_submit"
						className="btn text-white"
						style={{ backgroundColor: primaryColors?.orange }}
						disabled={formik.isSubmitting || !formik.isValid}
					>
						{!loading && <span className="indicator-label">Sign In</span>}
						{loading && (
							<span className="indicator-progress" style={{ display: "block" }}>
								Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2 "></span>
							</span>
						)}
					</button>
				</div>
			</form>
		</div>
	);
}
