/** @format */

import axios from "axios";

import * as authHelper from "../auth/core/AuthHelpers";
import { setupInterceptorsTo } from "../interceptors";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const GET_MARKETING_STATS = `${API_BASE_URL}/marketing/stats`;
const GET_MARKETING_LEAD_STATS = `${API_BASE_URL}/marketing/leadstats`;
const GET_MARKETING_PRODUCTSTATS = `${API_BASE_URL}/marketing/productstats`;
const GET_MARKETING_PRODUCT = `${API_BASE_URL}/marketing/product`;
const GET_MARKETING_SPENDS = `${API_BASE_URL}/marketing/spends`;
const GET_MARKETING_APPS = `${API_BASE_URL}/marketing/apps`;
const GET_MARKETING_CARDS = `${API_BASE_URL}/marketing/cards`;

const GET_PRODUCT_STATS = `${API_BASE_URL}/product/stats`;
const GET_NEW_PRODUCT_STATS = `${API_BASE_URL}/newproduct/stats`;
const GET_NEW_PRODUCT_PLATFORM_STATS = `${API_BASE_URL}/newproduct/platformstats`;

const GET_FEATURED_CAMPAIGNS = `${API_BASE_URL}/product/campaign`;

const GET_GENDER_CLICKS_PRODUCT = `${API_BASE_URL}/product/gender/clicks`;
const GET_GENDER_CLICKS_PLATFORM = `${API_BASE_URL}/platform/gender`;

const GET_DEVICE_CLICKS = `${API_BASE_URL}/product/device/clicks`;

const GET_DEVICE_CTR = `${API_BASE_URL}/product/device/ctr`;

//intentionally has a / at the end.

const GET_DEVICE = `${API_BASE_URL}/product/device/`;

const GET_BUDGET_PRODUCT = `${API_BASE_URL}/newproduct/budget`;
const GET_BUDGET_PLATFORM = `${API_BASE_URL}/platform/budgetdata`;
const GET_PRODUCT_TOTAL_LEADS = `${API_BASE_URL}/product/totallead`;
const GET_PLATFORM_PERFORMANCEBYCREDIT = `${API_BASE_URL}/platform/performancebycredit`;
const GET_PLATFORM_PERFORMANCEBYNETWORK = `${API_BASE_URL}/platform/performancebynetwork`;
const GET_PLATFORM_DEVICESTATS = `${API_BASE_URL}/platform/devicestats`;
const GET_PLATFORM_DEVICETYPE = `${API_BASE_URL}/platform/devicetype`;
const GET_PLATFORM_PERFORMANCEBYCAMPAIGN = `${API_BASE_URL}/platform/performancebycampaign`;

const GET_LOCATION_STATE = `${API_BASE_URL}/product/location/state`;
const GET_LOCATION_STATE_MAP= `${API_BASE_URL}/campaign/location`;
const GET_PLATFORM = `${API_BASE_URL}/platform/`;
const GET_PLATFORM_IMPRESSIONS = `${API_BASE_URL}/platform/impressions`;
const GET_PLATFORM_CLICKS = `${API_BASE_URL}/platform/clicks`;
const GET_PLATFORM_COSTS = `${API_BASE_URL}/platform/costs`;
const GET_PLATFORM_CPM = `${API_BASE_URL}/platform/cpm`;
const GET_PLATFORM_CPC = `${API_BASE_URL}/platform/cpc`;
const GET_PLATFORM_FINAL_CONVERSATIONS = `${API_BASE_URL}/platform/finalconversions`;
const GET_PLATFORM_GENDER_PRODUCT_STATS = `${API_BASE_URL}/platform/genderproductstats`;
const GET_PLATFORM_CAMPAIGNTYPES = `${API_BASE_URL}/platform/campaigntypes`;
const GET_PLATFORM_CAMPAIGNBYCREDIT = `${API_BASE_URL}/platform/campaignbycredit`;
const GET_CAMPAIGN_CTRVSCOST = `${API_BASE_URL}/campaign/ctrvscost`;
const BASE_URL = `${API_BASE_URL}/`;

const GET_CAMPAIGN_PERFORMANCEBYDAY = `${API_BASE_URL}/campaign/heatMap`;
const GET_CAMPAIGN_TOPSTATES = `${API_BASE_URL}/campaign/topstates`;
const GET_CAMPAIGN_DEVICETYPE = `${API_BASE_URL}/campaign/devicetype`;
const GET_CAMPAIGN_AGESTATS = `${API_BASE_URL}/campaign/agestats`;
const GET_CAMPAIGN_GENDERSTATS = `${API_BASE_URL}/campaign/genderstats`;
const GET_CAMPAIGN_TOPKEY = `${API_BASE_URL}/campaign/topkeywords`;
const GET_CAMPAIGN_REACHENGAGECONVERT = `${API_BASE_URL}/campaign/reachengageconvert`;
// const GET_CAMPAIGN_LEAD = `${API_BASE_URL}/campaign/lead`;
const GET_CAMPAIGN_BASEURL = `${API_BASE_URL}/campaign/`;
const GET_BASELINESTATS = `${API_BASE_URL}/baseline/stats/`;
const GET_PRODUCTTYPES = `${API_BASE_URL}/newproduct/producttypes`;
const GET_PLATFORMTYPES = `${API_BASE_URL}/platform/producttypes`;
const GET_PRODUCT_CATEGORY = `${API_BASE_URL}/product/platforms/`;
const GET_PRODUCT_SPENDLINE = `${API_BASE_URL}/newproduct/spends`;
const GET_CAMPAIGN_LIST=`${API_BASE_URL}/campaign/getcampaigns`;

const URLS = {
  GET_MARKETING_STATS,
  GET_PRODUCT_STATS,
  GET_NEW_PRODUCT_STATS,
  GET_NEW_PRODUCT_PLATFORM_STATS,
  GET_FEATURED_CAMPAIGNS,
  GET_GENDER_CLICKS_PRODUCT,
  GET_GENDER_CLICKS_PLATFORM,
  GET_DEVICE_CLICKS,
  GET_DEVICE_CTR,
  GET_DEVICE,
  GET_BUDGET_PRODUCT,
  GET_BUDGET_PLATFORM,
  GET_PRODUCT_TOTAL_LEADS,
  GET_LOCATION_STATE,
  GET_LOCATION_STATE_MAP,
  GET_PLATFORM,
  GET_PLATFORM_IMPRESSIONS,
  GET_PLATFORM_CLICKS,
  GET_PLATFORM_COSTS,
  GET_PLATFORM_CPM,
  GET_PLATFORM_CPC,
  GET_PLATFORM_FINAL_CONVERSATIONS,
  GET_PLATFORM_PERFORMANCEBYCREDIT,
  GET_PLATFORM_PERFORMANCEBYNETWORK,
  GET_PLATFORM_DEVICESTATS,
  GET_PLATFORM_GENDER_PRODUCT_STATS,
  GET_PLATFORM_CAMPAIGNTYPES,
  GET_PLATFORM_CAMPAIGNBYCREDIT,
  GET_CAMPAIGN_CTRVSCOST,
  BASE_URL,
  GET_CAMPAIGN_PERFORMANCEBYDAY,
  GET_CAMPAIGN_TOPSTATES,
  GET_CAMPAIGN_DEVICETYPE,
  GET_CAMPAIGN_AGESTATS,
  GET_CAMPAIGN_GENDERSTATS,
  GET_CAMPAIGN_TOPKEY,
  GET_CAMPAIGN_BASEURL,
  GET_CAMPAIGN_REACHENGAGECONVERT,
  GET_MARKETING_PRODUCTSTATS,
  GET_MARKETING_PRODUCT,
  GET_MARKETING_LEAD_STATS,
  GET_BASELINESTATS,
  GET_PRODUCTTYPES,
  GET_PLATFORM_PERFORMANCEBYCAMPAIGN,
  GET_PRODUCT_CATEGORY,
  GET_PRODUCT_SPENDLINE,
  GET_MARKETING_SPENDS,
  GET_MARKETING_APPS,
  GET_MARKETING_CARDS,
  GET_PLATFORMTYPES
};

const auth = authHelper.getAuth();

setupInterceptorsTo(axios);

// const getMarketingStats = (requestData: any,  url: string) => {
// 	return axios
// 		.post(url, requestData, {
// 			headers: {
// 				Authorization: `Bearer ${auth?.token}`,
// 			},
// 		})
// 		.then((response: any) => response)
// 		.catch((e) => {
// 			console.log(e, "api error");
// 		});
// };
const getProductStats = (requestData: any) => {
  return axios
    .post(`${GET_PRODUCT_STATS}`, requestData, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response)
    .catch((e) => {
      console.log(e, "api error");
    });
};

const getDataFromBackend = (requestData: any, url: string) => {
  return axios
    .post(url, requestData, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response)
    .catch((e) => {
      console.log(e, "api error");
    });
};

const getMethodDataFromBackend = (url: string) => {
  return axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response) => response)
    .catch((error) => {
      console.log(error, "api error");
    });
};

const getCampaignList = () => {
    return axios
      .get(`${GET_CAMPAIGN_LIST}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response: any) => response)
      .catch((e) => {
        console.debug(e, "api error");
      });
}; 

export { getDataFromBackend, getMethodDataFromBackend, URLS, getCampaignList };
