import { Fragment, useEffect, useState, useRef } from "react";
import { KTCard, KTSVG } from "../../../../theme/helpers";
import { getCampaignHighlights } from "../../../modules/application/_requests";
import { useQuery } from "react-query";
import { useParams, useLocation } from "react-router-dom";
import { IoSparkles } from "react-icons/io5";
import { BsTag } from "react-icons/bs";
import { GrView } from "react-icons/gr";

import { MdDelete } from "react-icons/md";
import CampaignType from "./CampaignType";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineInfoCircle, AiOutlineWarning } from "react-icons/ai";
import outline from "../../../../assets/outline-preview.png";
import warning from "../../../../assets/baseline-warning.png";
import call from "../../../../assets/call.png";



import { Search } from "../../../../theme/partials";
import Video from "./Video";
import GoalCard from "./GoalCard";

type Props = {
  className: string;
};

type Goal = {
  id: number;
  name: string;
  source: string;
  actions: number;
};

const ImproveCard = (props, {handleRestoreGoal}) => {
  // const containerRef = useRef<HTMLDivElement>(null);
  let title = props.title
  //   let TitleData=[
  //     {
  // title:'Sales'
  //   },
  //   {
  //     title:'Leads'
  //       },
  //       {
  //         title:'Website'
  //           }
  // ]
  const location = useLocation();
  const locationURL = window.location.pathname.split("/").pop();

  const id = (location.state as { id: string })?.id || "";

  console.log(id, "fdkfhdk");
  //   const { id } = useParams();
  console.log(id, "params data");
  const [showCampaignType, setShowCampaignType] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isaDeleted, setIsaDeleted] = useState(false);
  type Goal = {
    id: number;
    objective: string;
    name: string;
    source: string;
    actions: number;
    image: string; // Image URL or imported image
  };

  const [goals, setGoals] = useState<Goal[]>([
    {
      id: 1,
      objective: "Website",
      name: "Page views",
      source: title,
      actions: 1,
      image: outline, // Imported image
    },
    {
      id: 2,
      objective: "Website",
      name: "Phone Call Lead",
      source: "Call from Ads",
      actions: 1,
      image: call, // Imported image

    },
    // Add more goals as needed
  ]);

  // const [isChecked2, setIsChecked2] = useState(false);


  const handleClick = () => {
    setShowCampaignType(true);
    setTimeout(() => {
      window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
    }, 0);
    // if (containerRef.current) {
    //   containerRef.current.scrollIntoView({ behavior: "smooth" });
    // }
  };

  // const handleRestoreGoal = () => {
  //   setIsaDeleted(false);
  // };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const removeGoal = (id: number) => {
    setGoals(goals.filter((goal) => goal.id !== id));
  };

  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState<JSX.Element | null>(null);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const handlePopupClick = (content: string) => {
    setPopupContent(
      <div className="d-flex align-items-center">
        <MdDelete size={20} className="mr-2" onClick={modalOpen} />
        {content}
      </div>
    );
    setShowPopup(true);
    setShowConfirmationPopup(true);
    // setShowPopup(false);
  };

  const modalOpen = () => {
    setShowConfirmationPopup(true);
    setShowPopup(false)
  };

  const handleDeleteGoal = (event: React.MouseEvent<HTMLButtonElement>) => {
    const id = event.currentTarget.dataset.id;
    if (id) {
      removeGoal(Number(id)); // Remove the goal from the state
      setShowConfirmationPopup(false); // Hide the delete confirmation popup
    }
  };


  const closePopup = () => {
    setShowPopup(false);
    setShowConfirmationPopup(false);
  };

  // const handleConfirmDelete = () => {
  //   if (goalToDelete) {
  //     removeGoal(goalToDelete.id);
  //     setShowConfirmationModal(false);
  //   }
  // };



  return (
    <>
      {/* {TitleData.map((item, index) => ( */}
      <div className="pb-7 pt-5" style={{
        backgroundColor: "white", boxShadow: '0 2px 8px rgba(0, 0, 0, 0.5)',
        borderRadius: "10px"
      }}>

        {/* <KTCard className="card h-lg-100"> */}
        <span className="fw-bold text-dark px-5 pb-7 pt-5" style={{ fontSize: '16px' }}>
          Use these conversion goals to improve {title}
        </span>
        <hr
          style={{
            // marginTop: "-10px",
            border: "1px solid #CCCCCC",
            width: "102.8%",
            marginLeft: '-17px'
          }}
        />
        {isaDeleted ?
          <div className="mb-10 py-2 px-1 d-flex justify-content-between mx-3" style={{ borderRadius: '7px', backgroundColor: '#E8F0FE', alignItems: 'center' }}>
          
          <p style={{ fontSize: '14px', marginBottom: '-2px', marginLeft: '30px' }}>
          <AiOutlineInfoCircle className="me-5" size={20} style={{ color: '#1153aa' }} />This change overrides your account goals setup.
            Your campaign will now optimize for the goals shown below.
          </p>
          <div className="btn btn-sm fw-bold btn-active-color-primary text-primary d-flex flex-end" 
          onClick={()=>setIsaDeleted(false)}>
            Restore Account Goals
          </div>
        </div>
        
          :


          <div className="align-items-start flex-column fs-6 px-5 mb-5" style={{ color: "#5f6368", fontSize: '14px' }}>
            Conversion goals labeled as account default will use data from all of
            your campaigns to prove your bid strategy and campaign performance,
            even if they don't seem directly related to  {title}
            {/* {item.title} */}

          </div>

        }
        <div className="">

          <div className="fs-7 mb-2" style={{ color: '#5F6368' }}>
            <div className="row gx-xl-12 gy-5 d-flex px-5">
              <div className="col-lg-4">Conversion Goals</div>
              <div className="col-lg-2">Conversion Source</div>
              <div className="col-lg-3 d-flex align-items-center justify-content-center">
                Conversion Actions
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-1 "></div>
            </div>
          </div>

          {goals.map((goals) => (
            <div className="container">
              {/* // goalcard custom component */}
              <div>
                <GoalCard
                  goals={goals}
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  isaDeleted={isaDeleted}
                  setIsaDeleted={setIsaDeleted}
                  // onClick={handleRestoreGoal}
                // handlePopupClick={handlePopupClick}
                />
              </div>
            </div>
          )
          )}

          {/* </tbody> */}
          {/* </table> */}


        </div>
        {/* </KTCard> */}
      </div>
      {/* ))} */}

    </>
  );
};
export default ImproveCard;
