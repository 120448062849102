/** @format */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import facebook from "../../../../assets/facebook.png";
import youtube from "../../../../assets/youtube.png";
import instagram from "../../../../assets/instagram.png";
import google from "../../../../assets/google.png";
import linkedin from "../../../../assets/linkedin.png";
import twitter from "../../../../assets/twitter.png";
import { getAllSocialList } from "../../../modules/application/_requests";
import { useQuery } from "react-query";
import { useAuth } from "../../../modules/auth";
import {
  URLS,
  getDataFromBackend,
} from "../../../modules/dashboards/DashboardRequests";
import CustomLoader from "../marketting_component/CustomLoader";

type Props = {
  startDate: string;
  endDate: string;
  productId: string;
  platformId: string;
  channelType: string;
  campaignId: string;
};

const fdata = [
  {
    channelName: "agency analytics",
    totalNewLeads: 5641,
    totalLeadGoal: 10000,
    percentage: 56.41,
  },
  {
    channelName: "seo report",
    totalNewLeads: 6667,
    totalLeadGoal: 0,
    percentage: 66.67,
  },
  {
    channelName: "social media reporting",
    totalNewLeads: 4335,
    totalLeadGoal: 0,
    percentage: 43.35,
  },
  {
    channelName: "agency analytics",
    totalNewLeads: 2345,
    totalLeadGoal: 0,
    percentage: 23.45,
  },
];

const TopKeyProgress: React.FC<Props> = ({
  productId,
  platformId,
  channelType,
  campaignId,
  startDate,
  endDate,
}) => {
  const [graph, setGraph] = useState();
  const [indexs, setIndexs] = useState();
  const [selectedItem, setSelectedItem] = useState("Clicks");
  const [filteredData, setFilteredData] = useState<any>([]);

  let [data, setData] = useState<any>([]);
  const dropdownData = ["Impressions", "Clicks", "Conversions", "Cost"];
  console.log(campaignId, "camm");
  const abc = campaignId;

  const handleDropdownItemClick = (data) => {
    console.log(data);
    setSelectedItem(data);
  };

  // const { setIndexes } = useAuth();
  useEffect(() => {
    const getData = async () => {
      console.log("get");
      let requestData = {
        productId,
        platformId,
        startDate,
        endDate,
        campaignId,
      };
      console.log("top keyword request", requestData);
      let response = await getDataFromBackend(
        requestData,
        URLS.GET_CAMPAIGN_TOPKEY
      );
      console.log("top keyword response", response?.data, requestData);
      setData(response?.data);
    };
    console.log(data, "ttrt");
    getData(); // run it, run it

    return () => {
      // this now gets called when the component unmounts
    };
  }, [productId, platformId, campaignId, startDate, endDate]);

  // const handleClick = (e, item, index) => {
  // 	setGraph(item);
  // 	setIndexs(index);
  // 	setIndexes(index);
  // };

  useEffect(() => {
    if (data?.length > 0) {
      const selectedData = data?.filter(
        (item) => item.dataFor === selectedItem
      );

      const dataWithPercentage = selectedData.map((item) => {
        const totalCount = item.data.reduce(
          (sum, keyword) =>
            sum +
            keyword[
              selectedItem == "Conversions"
                ? "conversion"
                : selectedItem?.toLowerCase()
            ],
          0
        );
        const newData = item.data.map((keyword) => ({
          ...keyword,
          percentage: (
            (keyword[
              selectedItem == "Conversions"
                ? "conversion"
                : selectedItem?.toLowerCase()
            ] /
              totalCount) *
            100
          ).toFixed(2),
        }));
        return {
          ...item,
          data: newData,
        };
      });
      setFilteredData(dataWithPercentage);

      console.log("selectedData", dataWithPercentage);
    }
  }, [data, selectedItem]);

  return (
    <div
      className="card card-flush h-lg-100 mb-10"
      style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
    >
      <div className="card-header">
        <div className="card-title align-items-start flex-column">
          <span className="card-label fw-semibold text-dark fs-6">
            Top Keywords
          </span>
        </div>
        <div className="d-flex align-items-center fw-bold flex-end">
          <div className="dropdown">
            <div
              data-kt-daterangepicker="true"
              data-kt-daterangepicker-opens="left"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary d-flex align-items-center px-4 dropdown-toggle"
            >
              {selectedItem || "Clicks"}
            </div>
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="dropdownMenuButton1"
            >
              {dropdownData.map((data) => (
                <li key={data} onClick={() => handleDropdownItemClick(data)}>
                  <span className="dropdown-item text-hover-primary">
                    {data}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {data?.length > 0 ? (
        <div className="card-body">
          {filteredData?.map((item, index) => {
            console.log(filteredData, "fdata");
            return (
              <>
                {item?.data.map((item, index) => {
                  return (
                    <div key={`sociallist${item?.Keyword}`}>
                      <div className="">
                        <div className="d-flex align-items-center me-3 mb-3">
                          <div className="d-flex justify-content-between align-items-center fs-7 flex-grow-1">
                            <div className="text-hover-primary fw-semibold lh-0 cursor-pointer">
                              {item?.Keyword}
                            </div>

                            <div className="fw-semibold">
                              {selectedItem !== "Conversions"
                                ? selectedItem === "Cost"
                                  ? "₹ " + item?.[selectedItem?.toLowerCase()]
                                  : item?.[selectedItem?.toLowerCase()]
                                : item?.conversion}
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center ">
                          <div
                            className={`progress h-6px w-100 me-2 bg-light-light`}
                          >
                            <div
                              className={`progress-bar`}
                              role="progressbar"
                              style={{
                                width: `${item?.percentage}%`,
                                height: "15px",
                                backgroundColor: "orange",
                              }}
                              // aria-valuenow={21}
                              aria-valuemin={0}
                              // aria-valuemax={highestValue}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="my-10"></div>
                    </div>
                  );
                })}
              </>
            );
          })}
        </div>
      ) : (
        <div style={{ alignSelf: "center", marginBottom: "30px" }}>
          <CustomLoader />
        </div>
      )}
    </div>
  );
};

export { TopKeyProgress };
