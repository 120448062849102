/**
 * /* eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

import { Fragment, useEffect, useRef, useState } from "react";
import { KTCard, KTSVG } from "../../../../theme/helpers";
import { getCampaignHighlights } from "../../../modules/application/_requests";
import { useQuery } from "react-query";
import { useParams, useLocation } from "react-router-dom";
import { IoSparkles } from "react-icons/io5";
import { BsTag } from "react-icons/bs";
import CampaignType from "./CampaignType";
import { AiOutlineQuestionCircle, AiOutlineSearch } from "react-icons/ai";
import { IoMdLink } from "react-icons/io";
import CheckBox from "./CheckBox";
import Bidding from "./Bidding";
import { useNavigate } from "react-router-dom";
import { BiCircleThreeQuarter } from "react-icons/bi";
import { MdIncompleteCircle } from "react-icons/md";
import search from "../../../../assets/searchhh.png";




const StartNewModal = ({ campaignId , onBackClick}) => {
  const location = useLocation();
  const locationURL = window.location.pathname.split("/").pop();

  console.log(locationURL,"location URl")
  const id = (location.state as { id: string })?.id || "";
  const navigate = useNavigate();

  console.log(campaignId, "modal pe id");
  //   const { id } = useParams();
  console.log(id, "params data");
  const [showCampaignType, setShowCampaignType] = useState(false);
  // const [showModal, setShowModal] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);
 
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowConfirmationPopup(false);
        // setShowPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const modalOpen = () => {
    setShowConfirmationPopup(true);

  };
  // const handleClick = () => {
  //   setShowCampaignType(true);
  //   setShowModal(true);
  //   // setShowConfirmationPopup(false)
  //   // navigate('/biding');
  // };
  const handleBiding = () => {
    setShowCampaignType(true);

    navigate("/Biding", { state: { campaignId } })
  };



  const TrackData = [
    {
      "track": "Sales-Search-31"
    },
    {
      "track": "Sales-Search-31"
    },
    {
      "track": "Sales-Search-31"
    },
    {
      "track": "Sales-Search-31"
    },
    {
      "track": "Sales-Search-31"
    },
    {
      "track": "Sales-Search-31"
    },
    {
      "track": "Sales-Search-31"
    },
  ]

  // const { "track" } = TrackData;

  return (
    <>


      <div
        className="modal fade show"
        id="confirmationModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="confirmationModalLabel"

        style={{
          // display: "block",
          position: "fixed",
          // position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          right: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9999,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        
          // style={{
          //   position: "fixed",
          //   top: "50%",
          //   left: "50%",
          //   transform: "translate(-50%, -50%)",
          //   width: "600px",
          //   height: "fit-content",
          //   backgroundColor: "rgba(0, 0, 0, 0.5)",
          //   zIndex: 9999,
          //   display: "flex",
          //   alignItems: "center",
          //   justifyContent: "center",
          // }}

        }}
      >
        <div className="modal-dialog" role="document" >
          <div className="modal-content" style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "600px",
            height: '500px',
            backgroundColor: "#FFFFFF",
            zIndex: 9999,
          }}>
            <div className="modal-body">
              <div className="" style={{ fontSize: '18px' }}>	{locationURL === "newCampaign" ? "Create a new campaign or finish a saved draft?" : "Select a campaign draft to finish"}</div>
            </div>
            <hr style={{ marginTop: "0.5px" }}></hr>
            <div className="" >
              <div className="row px-7 pb-5" style={{ fontSize: '13px', color:'#3C4043' }}>
                <div className="col-6">
                  <span>Campaign draft name</span>
                  {/* Add your content for creating a new campaign */}
                </div>
                <div className="col-6">
                  <span>Campaign objectives</span>
                  {/* Add your content for finishing a saved draft */}
                </div>
              </div>
              <hr style={{ marginTop: "0.5px" }}></hr>
            </div>
            <div className='px-2' style={{ overflowY: 'auto', overflowX:'hidden' }}>
              {TrackData.map((tracker, index) => (
                <div className="row px-7 py-6" style={{ fontSize: '13px', color:'#3C3043' }}>
                  <div className="col-6 d-flex align-items-center">
                    <MdIncompleteCircle size={14.16} style={{ fill: 'orange', color: 'orange', marginRight: '20px' }} />
                   <img src={search} alt="search" style={{height:'30px', width:'30px'}}/>
                    <span className="px-3">{tracker.track}</span>
                    {/* Add your content for creating a new campaign */}
                  </div>
                  <div className="col-6 d-flex">
                    <span className="mx-1">Sales</span>
                    {/* Add your content for finishing a saved draft */}
                  </div>
                </div>))}
            </div>
            {locationURL === "newCampaign" &&
            <div className="px-3 d-flex flex-end mb-1" style={{ width: '100%', backgroundColor: 'white' }}>
              <button
                type="button"
                className="nav-link btn btn-sm btn-color-primary btn-active btn-active-light fw-bold px-4 d-flex flex-wrap"
                style={{ fontSize: '14px' }}
                onMouseEnter={(e) => { (e.target as HTMLButtonElement).style.backgroundColor = "#F5F5F5" }}
                onMouseLeave={(e) => { (e.target as HTMLButtonElement).style.backgroundColor = "#FFFFFF" }}
                // onClick={() => navigate(0)}
              // onClick={() => setShowModal(false)}
              onClick={onBackClick}

              >
                Go back
              </button>
              <button
                type="button"
                className="nav-link btn btn-sm btn-color-primary btn-active btn-active-light fw-bold px-2 d-flex flex-wrap"
                style={{ fontSize: '14px' }}
                onClick={handleBiding}
                // onClick={() => navigate(-1)}
                onMouseEnter={(e) => { (e.target as HTMLButtonElement).style.backgroundColor = "#F5F5F5" }}
                onMouseLeave={(e) => { (e.target as HTMLButtonElement).style.backgroundColor = "#FFFFFF" }}
              >
                Start new
              </button>
            </div>
}
          </div>
        </div>
      </div>




      {/* </div> */}
    </>
  );
};
export default StartNewModal;
