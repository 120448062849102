/** @format */

import { FC, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import { getOrganizations, getCategory, AddResponse, addParticapintOrganization } from "../../../../application/_requests";
import { useMutation, useQuery } from "react-query";
import { Doc } from "../core/_models";
import { useParams } from "react-router-dom";
import {
	useDetailQueryResponse
} from "../core/QueryResponseProvider";
import { useListView } from "../core/ListViewProvider";
import clsx from "clsx";

const editUserSchema = Yup.object().shape({
	organization: Yup.string().label("").required("Organization is required"),
});

const AddOrganizationModalForm: FC<any> = ({
	user,
	isUserLoading,
	onCancel,

}) => {
	const btnRef = useRef<HTMLButtonElement | null>(null);
	const { setToastMessage } = useListView();
	const { id } = useParams();
	const { refetch } = useDetailQueryResponse();



	const {
		isFetching: isFetchingOrg,
		refetch: refetchOrg,
		data: organizations,
	} = useQuery(
		[`ADD_ORGANIZATION_PARTICIPANT`],
		() => {
			return getOrganizations();
		},
		// { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
	);


	const HandleSubmit = (values: any) => {
		btnRef.current?.setAttribute("data-kt-indicator", "on");
		const docRequest: any = {
			participantId: id,
			organisationId: values.organization,
		};
		// const file = values.fileName[0];

		const response = addParticapintOrganization(docRequest)
			.then((res) => {
				btnRef.current?.removeAttribute("data-kt-indicator");
				onCancel();
				refetch();
				setToastMessage("Added Successfully!");
				setTimeout(() => {
					setToastMessage(undefined);
				}, 2000);
			})
			.catch((err) => {
				alert("Request Failed!");

			});


	};
	const formik = useFormik({
		initialValues: {
			participantId: "",
			organizationId: "",

		},
		validationSchema: editUserSchema,
		onSubmit: (values) => {
			HandleSubmit(values);
		},
	});
	return (
		<>
			<form
				id="kt_modal_add_user_form"
				className="form"
				onSubmit={formik.handleSubmit}
				noValidate
			>
				<div
					className="d-flex flex-column scroll-y me-n7 pe-7"
					id="kt_modal_add_user_scroll"
					data-kt-scroll="true"
					data-kt-scroll-activate="{default: false, lg: true}"
					data-kt-scroll-max-height="auto"
					data-kt-scroll-dependencies="#kt_modal_add_user_header"
					data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
					data-kt-scroll-offset="300px"
				>
					<div className="fv-row mb-7">
						<label className="required fw-bold fs-6 mb-2">
							{" "}
							Add Organization
						</label>
						<select
							className={clsx(
								"form-select form-select-light mb-3 mb-lg-0",
								{
									"is-invalid":
										formik.touched.organizationId && formik.errors.organizationId,
								},
								{
									"is-valid":
										formik.touched.organizationId && formik.errors.organizationId,
								}
							)}
							aria-label="Select example"
							{...formik.getFieldProps("organization")}
						>
							<option hidden={true} value="">
								{user?.organization_name ? user.organization_name : `Select Organization`}
							</option>
							{organizations?.map((item: any, index: number) => (
								<option key={index} value={item.organisationId}>
									{item.organisationName}
								</option>
							))}
						</select>
						{formik.touched.organizationId && formik.errors.organizationId && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.organizationId}</span>
								</div>
							</div>
						)}
					</div>

					<div className="d-flex justify-content-center">
						<button
							title="Discard"
							type="reset"
							className="btn btn-light mx-10"
							data-kt-users-modal-action="cancel"
							onClick={onCancel}
						>
							Discard
						</button>

						<button
							title={user ? `Update` : `Submit`}
							ref={btnRef}
							type="submit"
							className="btn me-10 text-white btn-custom"
							id="kt_button_1"
							data-kt-users-modal-action="submit"
							style={{ backgroundColor: "#333E58" }}
						>
							<span className="indicator-label">{user ? `Update` : `Submit`}</span>
							<span className="indicator-progress">
								Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

export { AddOrganizationModalForm };
