/** @format */

import React, {createContext, useEffect, useState,  } from "react";

import { createCampaignType, gatCampaignDataById } from "../../../modules/application/_requests";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { KTCard, KTSVG } from "../../../../theme/helpers";

import { Link, Routes, Route, useNavigate } from "react-router-dom";
import Sales from "./CampCard";
import Leads from "./Leads";
import Website from "./Website";
import BrandAwareness from "./BrandAwareness";
import AppPromotion from "./AppPromotion";
import Product from "./Product";
import CampaignName from "./CampaignName";
import { FcDocument } from "react-icons/fc";
import Search from "./Search";
import PerformanceMax from "./PerformanceMax";
import Display from "./Display";
import Shopping from "./Shopping";
import Video from "./Video";
import Discovery from "./Discovery";
import CheckBox from "./CheckBox";
import BuisnessWesite from "./BusinessWebsite";
import Drive from "./Drive";
import search from "../../../../assets/search.png";
import shopping from "../../../../assets/shopping.png";
import discover from "../../../../assets/discover.png";
import performance from "../../../../assets/PerformanceMatrix.png";
import video from "../../../../assets/video.png";
import display from "../../../../assets/display.png";
import CampTypeCard from "./CampTypeCard";
import MerchantLink from "./Merchantlink";
import NewCampaign from "./NewCampaign";
import SelectedTypeContext from './SelectedTypeContext';
import CancelContinue from "./CancelContinue";
import StartNewModal from "./StartNewModal";

// import {IoChevronBackCircleSharp} from 'react-icons/io';
// export const SelectedTypeCardTitleContext = createContext('');
// export const SelectedTypeCardTitleProvider

const CampaignType = ({campaignId,selectedCardTitle}) => {
  const [selectedTypeId, setSelectedTypeId] = useState(-1);
  const [selectedTypeCardTitle, setSelectedTypeCardTitle] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  // const campaignId = location.state?.campaignId;
  console.log(campaignId,'idddd')
  console.log(selectedCardTitle,"selectedCardTitle")
  const [inputBusinessValue, setInputBusinessValue] = useState('');

// api int
const handleTypeSave=(values:any)=> {
  // if()
  // setTimeout(() => {
  //   window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
  // }, 0);

  const savedtypedata =
  {
    "campaign_id": campaignId,
    "CampaignType":selectedTypeCardTitle,
    "additionalInfo":[
    {
    "reachGoal":{
    "name":"Website visits",
    "value": inputBusinessValue
    },
    "campaignName":inputValue
    }
    ]
    }
    
console.log(savedtypedata,"savedddddddddd")
const response = createCampaignType(savedtypedata)
  // fetch('https://apps.trisysit.com/campback/home/createCampaign/page/1', {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify(saveddata),
  // })
  .then((response) => {
      
    console.log("Added Successfully!");
    console.log(response,"heyhfdkfdf")

    setTimeout(() => {
      console.log(undefined);
    }, 2000);
  })
  .catch((err) => {
    alert("Request Failed!");

  });

    console.log(savedtypedata, 'aaaaa');
    setShowModal(true);
    // navigate("/Biding", { state: { campaignId: savedtypedata.campaign_id } });
    // navigate("/Biding", { state: { campaignId } })
   
}


  // const [showCampaignType, setShowCampaignType] = useState(false);

  const handleTypeCardSelect = (id: number, title: string) => {
    setSelectedTypeId(id);
    setSelectedTypeCardTitle(title);
  };
  console.log(selectedTypeCardTitle, "bbbbbbbbbbbb")
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Camp AI Gain - Detail";
  }, []);
  const { id } = useParams();
  console.log(id, "params data");

  // const location = useLocation();
  // const state: any = location.state;

  // console.log(state, "hhhhhhh");
  const [isCardClicked, setIsCardClicked] = useState(false);
  const [performaceVisible, setPerformaceVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [display, setDisplay] = useState(false);
  const [discovery, setDiscovery] = useState(false);
  const [shopping, setShopping] = useState(false);
  const [video, setVideo] = useState(false);










  const handleCardClick = () => {
    setTimeout(() => {
      window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
    }, 0);
    setIsCardClicked((prevIsCardClicked) => !prevIsCardClicked);
    setSearchVisible(!searchVisible);
    setPerformaceVisible(false);
    setDisplay(false);
    setDiscovery(false)
    setVideo(false)
    setShopping(false)
  };
  const handleCardClickPerformace = () => {
    setTimeout(() => {
      window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
    }, 0);
    setIsCardClicked((prevIsCardClicked) => !prevIsCardClicked);
    setPerformaceVisible(!performaceVisible);
    setSearchVisible(false);
    setDisplay(false);
    setDiscovery(false)
    setVideo(false)
    setShopping(false)
  };

  const handleCardClickDisplay = () => {
    setTimeout(() => {
      window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
    }, 0);
    setIsCardClicked((prevIsCardClicked) => !prevIsCardClicked);
    setDisplay(!display);
    setSearchVisible(false);
    setPerformaceVisible(false);
    setDiscovery(false)
    setVideo(false)
    setShopping(false)

  };

  const handleCardClickShopping = () => {
    setTimeout(() => {
      window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
    }, 0);
    setIsCardClicked((prevIsCardClicked) => !prevIsCardClicked);
    setShopping(!shopping);
    setDisplay(false);
    setSearchVisible(false);
    setPerformaceVisible(false);
    setDiscovery(false)
    setVideo(false)

  };

  const handleCardClickDiscovery = () => {
    setTimeout(() => {
      window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
    }, 0);
    setIsCardClicked((prevIsCardClicked) => !prevIsCardClicked);
    setDiscovery(!discovery);
    setSearchVisible(false);
    setPerformaceVisible(false);
    setDisplay(false)
    setVideo(false)
    setShopping(false)

  };
  const handleCardClickVideo = () => {
    setTimeout(() => {
      window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
    }, 0);
    setIsCardClicked((prevIsCardClicked) => !prevIsCardClicked);
    setVideo(!video);
    setSearchVisible(false);
    setPerformaceVisible(false);
    setDisplay(false)
    setShopping(false)

  };


  
  return (
    <>
      {/* trial */}
      <div className="pb-3 pt-5 mt-7 px-5" style={{
        backgroundColor: "white",
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.5)', borderRadius: "10px"
      }}>
        <span className="fw-bold text-dark mx-5" style={{ fontSize: '16px' }}>
          Select a campaign type
        </span>
        <hr
          style={{
            // marginTop: "-10px",
            border: "1px solid #CCCCCC",
            width: "102.8%",
            marginLeft: '-17px'
          }}
        />

        <div className="px-3">
          <div className="row gy-5 gx-xl-12 mb-6 mt-2 d-flex">
            <div className='col-md-3' onClick={handleCardClick}>
              <CampTypeCard id={11} data-id="1" selectedCard={selectedTypeId} onSelect={handleTypeCardSelect} />

            </div>
            <div className="col-md-3 " onClick={handleCardClickPerformace}>
              <CampTypeCard id={12} data-id="2" selectedCard={selectedTypeId} onSelect={handleTypeCardSelect} />
            </div>
            <div className="col-md-3 " onClick={handleCardClickDisplay}>
              <CampTypeCard id={13} data-id="3" selectedCard={selectedTypeId} onSelect={handleTypeCardSelect} />
            </div>
            <div className="col-md-3" onClick={handleCardClickShopping}>
              <CampTypeCard id={14} data-id="4" selectedCard={selectedTypeId} onSelect={handleTypeCardSelect} />
            </div>
          </div>

          <div className="row row gy-5 gx-md-12 mb-7">
            <div className="col-md-3" onClick={handleCardClickVideo}>
              <CampTypeCard id={15} data-id="5" selectedCard={selectedTypeId} onSelect={handleTypeCardSelect} />
            </div>
            <div className="col-md-3 " onClick={handleCardClickDiscovery}>
              <CampTypeCard id={16} data-id="6" selectedCard={selectedTypeId} onSelect={handleTypeCardSelect} />
            </div>
            {/* <NewCampaign selectedTypeCardTitle={selectedTypeCardTitle} /> */}

          </div>
        </div>
      </div>

      {searchVisible && <CheckBox />}
      {searchVisible && <CampaignName inputValue={inputValue} setInputValue={setInputValue} selectedCardTitle={selectedCardTitle} selectedTypeCardTitle={selectedTypeCardTitle}/>}
      {performaceVisible && <CampaignName inputValue={inputValue} setInputValue={setInputValue} selectedCardTitle={selectedCardTitle} selectedTypeCardTitle={selectedTypeCardTitle}/>}
      {display && <BuisnessWesite  setInputBusinessValue={setInputBusinessValue} inputBusinessValue={inputBusinessValue} />}
      {display && <CampaignName inputValue={inputValue} setInputValue={setInputValue} selectedCardTitle={selectedCardTitle} selectedTypeCardTitle={selectedTypeCardTitle} />}
      {discovery && <BuisnessWesite setInputBusinessValue={setInputBusinessValue} inputBusinessValue={inputBusinessValue} />}
      {discovery && <CampaignName inputValue={inputValue} setInputValue={setInputValue} selectedCardTitle={selectedCardTitle} selectedTypeCardTitle={selectedTypeCardTitle}/>}
      {video && <Drive className="" />}
      {shopping && <MerchantLink className="" />}


     <CancelContinue onClick={handleTypeSave} />
     {showModal && <StartNewModal campaignId={campaignId} onBackClick={(e)=>setShowModal(false)}/> }

 

    </>
  );
};

export default CampaignType;
