import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import { ChartTheme } from "../../../assets/ts/_utils/ChartTheme";
import { data } from "jquery";
import {
  getDataFromBackend,
  URLS,
} from "../../../../app/modules/dashboards/DashboardRequests";
import CustomLoader from "../../../../app/pages/dashboard/marketting_component/CustomLoader";
import { AiOutlineZoomIn } from "react-icons/ai";
import Modal from "../../../../app/pages/dashboard/marketting_component/Modal";

interface MarketingDataItem {
  productName: string;
  value: number;
  percentage: any;
}

function AmPieChart({
  chartID,
  platformId,
  productId,
  startDate,
  endDate,
  channelType,
  campaignId,
  title,
  subUrl,
}) {
  const [marketingPieData, setMarketingPieData] = useState<
    Array<{
      total: any;
      value: Array<{
        value: number;
        productName: string;
      }>;
    }>
  >([]);

  const [formattedMarketingData, setFormattedMarketingData] = useState<
    MarketingDataItem[]
  >([]);
  const [selectedItem, setSelectedItem] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [datas, setDatas] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<
    Array<{
      value: number;
      productName: string;
    }>
  >([]);
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      let requestData = {
        productId,
        platformId,
        startDate,
        endDate,
        campaignId,
      };
      let response = await getDataFromBackend(
        requestData,
        URLS.BASE_URL + channelType + subUrl
      );
      console.log(
        "Response of tripie: ",
        response?.data,
        URLS.BASE_URL + channelType + subUrl
      );
      console.log("reso", response?.data);
      if (response?.data) {
        const filteredData = response.data.value.filter(
          (item) =>
            item.hasOwnProperty("value") && item.hasOwnProperty("productName")
        );
        setMarketingPieData([
          {
            total: response.data.total,
            value: filteredData,
          },
        ]);
        setFilteredData(filteredData);
        setIsLoading(false);
        console.log("nf", filteredData);
      } else {
        return;
      }
    };

    getData();

    return () => {};
  }, [productId, platformId, campaignId, startDate, endDate]);

  useEffect(() => {
    let totalValue = filteredData.reduce((sum, item) => sum + item.value, 0);
    console.log(totalValue, "tots");

    const formattedData = filteredData.map((item) => {
      const percentage = Math.round((item.value / totalValue) * 100);
      return {
        ...item,
        percentage: percentage,
      };
    });
    setFormattedMarketingData(formattedData);
  }, [filteredData, marketingPieData]);

  useLayoutEffect(() => {
    var root = am5.Root.new(chartID);
    root._logo?.dispose();

    // root.setThemes([ChartTheme.getTheme(root)]);
    root.setThemes([ChartTheme.getBarGradientTheme(root)]);

    var chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        radius: 78,
        innerRadius: 38,
      })
    );
    let data = formattedMarketingData;

    var series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: "Series",
        valueField: "percentage",
        categoryField: "productName",
        alignLabels: true,
      })
    );

    let tooltip: any = am5.Tooltip.new(root, {
      getFillFromSprite: true,
      text: am5.color(0xffffff),
      autoTextColor: false,
    });

    tooltip.get("background").setAll({
      fill: am5.color(0xffffff),
      fillOpacity: 0.8,
    });

    series.set("tooltip", tooltip);
    tooltip.label.set("fill", am5.color(0xffffff));
    series.data.setAll(data);
    series.labels.template.setAll({
      text: "[fontSize: 10px]{productName}: {percentage}%",
      textType: "adjusted",
      inside: false,
      radius: 0,
      oversizedBehavior: "wrap",
    });

    series.ticks.template.set("forceHidden", true);

    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartID, filteredData, formattedMarketingData, isZoomModalOpen]);

  return (
    <div
      className="card h-lg-100 py-4 px-5"
      style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
    >
      <div className="card-label fw-semibold text-dark fs-6">{title}</div>
      {isLoading ? (
        <div
          className="py-5 d-flex align-self-center justify-content-center min-vh-50"
          id={chartID}
          style={{ height: "100%", alignItems: "center" }}
        >
          <CustomLoader />
        </div>
      ) : (
        <>
          <div
            className="fw-bold"
            style={{
              fontSize: "12px",
              textAlign: "end",
              cursor: "pointer",
              position: "absolute",
              right: 10,
            }}
            onClick={() => setIsZoomModalOpen(true)}
          >
            <AiOutlineZoomIn size={25} />
          </div>
          {marketingPieData?.map((item, index) => (
            <div className="card-label fw-bold text-dark fs-2 mb-5" key={index}>
              ₹{" "}
              {item.total.toLocaleString("en-IN", { maximumFractionDigits: 0 })}
            </div>
          ))}
          {!isZoomModalOpen ? (
            <div id={chartID} style={{ height: "200px", width: "100%" }}></div>
          ) : (
            <Modal
              isOpen={isZoomModalOpen}
              onClose={() => setIsZoomModalOpen(false)}
              title={title}
            >
              <div className="card-label fw-bold text-dark fs-2 mt-0 mx-7">
                {marketingPieData?.map((item, index) => (
                  <div
                    className="card-label fw-bold text-dark fs-2 mb-5"
                    key={index}
                  >
                    ₹ {item.total.toLocaleString("en-IN", { maximumFractionDigits: 0 })}
           
                  </div>
                ))}
              </div>
              <div
                id={chartID}
                style={{ height: "350px", width: "100%" }}
              ></div>
            </Modal>
          )}
        </>
      )}
    </div>
  );
}
export default AmPieChart;
