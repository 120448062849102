import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import * as am5xy from "@amcharts/amcharts5/xy";
import { ChartTheme } from "../../../../theme/assets/ts/_utils/ChartTheme";
import {
  getDataFromBackend,
  URLS,
} from "../../../modules/dashboards/DashboardRequests";
import CustomLoader from "./CustomLoader";
import CustomDropdown from "./CustomDropdown";

type Props = {
  channelType: string;
  title: any;
  productId: string;
  apiTitle: string;
  startDate: string;
  endDate: string;
  platformId: string;
  chartID: string;
  productType: string;
};

type DataEntry = {
  children: {
    children: {
      name: any;
      value: any;
    }[];
  }[];
};

const tempData: DataEntry = {
  children: [
    {
      children: [
        {
          name: "Desktop",
          value: 162896,
        },
      ],
    },
  ],
};

const TreeMap: React.FC<Props> = ({
  channelType,
  title,
  chartID,
  productId,
  startDate,
  apiTitle,
  endDate,
  productType,
  platformId,
}) => {
  const myChartID = chartID;

  const [treeMapData, setTreeMapData] = useState<any[]>([]);
  const [data, setData] = useState<DataEntry>(tempData);
  const [selectedItem, setSelectedItem] = useState<string>("All");
  const [dropdownData, setDropdownData] = useState<string[]>(["All"]);

  useEffect(() => {
    const getData = async () => {
      let requestData = {
        productId,
        startDate,
        endDate,
        platformId,
        productType,
      };
      let response = await getDataFromBackend(
        requestData,
        URLS.BASE_URL + `${channelType}/${apiTitle}`
      );
      let data = response?.data;
      // setTreeMapData(data);
      console.log(
        "Device Type (Clicks)",
        data,
        URLS.BASE_URL + `${channelType}/${apiTitle}`
      );
      if (response?.data) {
        setTreeMapData(data);
      } else {
        return;
      }
    };

    getData();
  }, [productId, startDate, endDate, productType]);

  useEffect(() => {
    if (treeMapData?.length > 0) {
      const mockDropdown = Array.from(
        new Set(treeMapData?.map((item) => item.dataFor))
      );
      setDropdownData(mockDropdown);

      const selectedData = treeMapData?.filter(
        (item) => item.dataFor === selectedItem
      );

      const newData = selectedData[0]?.data.map((item) => ({
        ...item,
      }));
      const tempData = {
        children: newData.map((item) => {
          let clicksData = item.data.find(
            (dataItem) => dataItem.name === "Clicks"
          );
          return {
            children: [
              {
                name: item.deviceName,
                value: clicksData ? clicksData.value : 0,
              },
            ],
          };
        }),
      };
      setData(tempData);
    }
  }, [treeMapData, selectedItem]);

  useLayoutEffect(() => {
    let root = am5.Root.new(myChartID);

    root._logo?.dispose();

    root.setThemes([ChartTheme.getBarGradientTheme(root)]);

    let container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
      })
    );

    let series = container.children.push(
      am5hierarchy.Treemap.new(root, {
        singleBranchOnly: false,
        downDepth: 1,
        upDepth: -1,
        initialDepth: 2,
        valueField: "value",
        categoryField: "name",
        childDataField: "children",
        nodePaddingOuter: 0,
        nodePaddingInner: 0,
      })
    );

    series.rectangles.template.setAll({
      strokeWidth: 2,
    });

    let maxLevels = 2;
    let maxNodes = 10;
    let maxValue = 100;

    series.data.setAll([data]);
    series.set("selectedDataItem", series.dataItems[0]);

    let tooltip = am5.Tooltip.new(root, {
      autoTextColor: true,
      labelText: "{name} \nClicks : {value}",
    });

    tooltip.label.setAll({
      fill: am5.color(0xff5566),
    });

    series.set("tooltip", tooltip);

    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [myChartID, data]);

  const handleDropdownItemClick = (data: string) => {
    setSelectedItem(data);
  };

  return (
    <div
      className="card card-flush h-lg-100 p-5"
      style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
    >
      <div className="card-title d-flex justify-content-between align-items-center">
        <div className="card-label fw-bold text-dark fs-4">{title}</div>
        <div>
          <CustomDropdown
            dropdownData={dropdownData}
            selectedItem={selectedItem}
            onItemClick={handleDropdownItemClick}
          />
        </div>
      </div>
      {treeMapData?.length <= 0 ? (
        <div className="py-5" id={chartID} style={{ alignSelf: "center" }}>
          <CustomLoader />
        </div>
      ) : (
        <div
          id={chartID}
          style={{ height: "370px", width: "100%", paddingLeft: "0px" }}
        ></div>
      )}
    </div>
  );
};

export default TreeMap;
