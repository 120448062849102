import React from 'react';
// import { Oval } from 'react-loader-spinner';

const CustomLoader = () => {


  return (
    // <div style={{ alignSelf: 'center' }}>
      // <Oval
      //   height={40}
      //   width={40}
      //   color="#E84A42"
      //   // color="grey"
      //   // ariaLabel="tail-spin-loading"
      //   // radius={0.05}
      //   wrapperStyle={{}}
      //   wrapperClass=""
      //   visible={true}
      //   ariaLabel='oval-loading'
      //   secondaryColor="#ffc8a1"
      //   strokeWidth={4}
      //   strokeWidthSecondary={4}
      // />
    // </div>
    <></>
  );
};

export default CustomLoader;