import React from "react";

type CustomDropdownProps = {
  dropdownData: string[];
  selectedItem: string | null;
  onItemClick: (data: string) => void;
};

const CustomDropdown: React.FC<CustomDropdownProps> = ({ dropdownData, selectedItem, onItemClick }) => {
  return (
    <div className="d-flex align-items-center fw-bold flex-end">
      <div className="dropdown">
        <div
          data-kt-daterangepicker="true"
          data-kt-daterangepicker-opens="left"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary d-flex align-items-center px-4 dropdown-toggle"
        >
          {selectedItem || "Clicks"}
        </div>
        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
          {dropdownData.map((data) => (
            <li key={data} onClick={() => onItemClick(data)}
            style={{ cursor: "pointer",  }}
            >
              <span className="dropdown-item text-hover-primary">{data}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CustomDropdown;
