import React from 'react';

const CenteredLine: React.FC<{ title: string }> = ({ title }) => {
  return (
    <div style={{ width: '80%', margin: '0 auto' }} className='mb-10'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 1, borderBottom: '2px solid #E84A42' }} />

        <div style={{ padding: '0 20px' }}>
          <p style={{ margin: '0', textAlign: 'center', fontSize: '20px', fontWeight: '600', color: '#E84A42' }}>{title}</p>
        </div>

        <div style={{ flex: 1, borderBottom: '2px solid #E84A42' }} />
      </div>
    </div>
  );
};

export default CenteredLine;