/** @format */

import { useEffect } from "react";
import { UserEditModalHeader } from "./UserEditModalHeader";
import { UserEditModalFormWrapper } from "./UserEditModalFormWrapper";
import { AddSubmissionModalForm } from "./AddSubmissionModalForm";
import { AddUserModalForm } from "./AddUserModalForm";
import { AddParticipantModalForm } from "./AddParticipantModalForm";
import { AddResponseModalForm } from "./AddResponseModalForm";
import { ResponseEditModalFormWrapper } from "./ResponseEditModalFormWrapper";
import { AddSourceModalForm } from "./AddSourceModelForm";
import { CreateOrganizationModalForm } from "./CreateOrganizationModalForm";
import { CreateDomainModalForm } from "./CreateDomainModalForm";
import { AddOrganizationModalForm } from "./AddOrganisationModalForm";
import { EditSourceModalForm } from "./EditSourceModalForm";
import { EditResponseModalForm } from "./EditResponseModalForm";

const UserEditModal = (props: any) => {
	useEffect(() => {
		document.body.classList.add("modal-open");
		return () => {
			document.body.classList.remove("modal-open");
		};
	}, []);

	return (
		<>
			<div
				className="modal fade show d-block"
				id="kt_modal_add_user"
				role="dialog"
				tabIndex={-1}
				aria-modal="true"
			>
				{/* begin::Modal dialog */}
				<div className="modal-dialog modal-dialog-centered mw-650px">
					{/* begin::Modal content */}
					<div className="modal-content">
						<UserEditModalHeader
							titleText={props.modalText}
							onCloseModal={props.onCloseEditModal}
						/>
						{/* begin::Modal body */}
						<div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
							{props.content === "add_user" ? (
								<AddUserModalForm
									onCancel={props.onCloseEditModal}
									user={props.user}
								/>
							) : props.content === "add_participant" ? (
								<AddParticipantModalForm
									onCancel={props.onCloseEditModal}
									user={props.user}
								/>
							) : props.content === "add_submission" ? (
								<AddSubmissionModalForm onCancel={props.onCloseEditModal} />
							) : props.content === "add_response" ? (
								<AddResponseModalForm
									onCancel={props.onCloseEditModal} user={props.user}
								/>
							) : props.content === "edit_response" ? (
								<EditResponseModalForm
									onCancel={props.onCloseEditModal} user={props.user}
								/>
							) : props.content === "add_source" ? (
								<AddSourceModalForm
									onCancel={props.onCloseEditModal} user={props.user}
								/>
							) : props.content === "edit_source" ? (
								<EditSourceModalForm
									onCancel={props.onCloseEditModal} user={props.user}
								/>
							) : props.content === "create_domain" ? (
								<CreateDomainModalForm
									onCancel={props.onCloseEditModal}
									user={props.user}
								/>
							) : props.content === "create_organization" ? (
								<CreateOrganizationModalForm
									onCancel={props.onCloseEditModal}
									user={props.user}
								/>
							) : props.content === "add_organization" ? (
								<AddOrganizationModalForm
									onCancel={props.onCloseEditModal}
									user={props.user}
								/>
							) : (
								// <ResponseEditModalFormWrapper />
								<UserEditModalFormWrapper />
							)}
						</div>
						{/* end::Modal body */}
					</div>
					{/* end::Modal content */}
				</div>
				{/* end::Modal dialog */}
			</div>
			{/* begin::Modal Backdrop */}
			<div className="modal-backdrop fade show"></div>
			{/* end::Modal Backdrop */}
		</>
	);
};

export { UserEditModal };
