import React, { useEffect, useState } from "react";
import {
  URLS,
  getDataFromBackend,
} from "../../../modules/dashboards/DashboardRequests";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { ChartTheme } from "../../../../theme/assets/ts/_utils/ChartTheme";
import CustomLoader from "./CustomLoader";
import CustomDropdown from "./CustomDropdown";

type DataEntry = {
  mvalue: number;
  fvalue: number;
  age: string;
};

type DataEntryDataFor = {
  data: {
    mvalue: number;
    fvalue: number;
    age: string;
  }[];
  dataFor: string;
};

type Props = {
  chartID: string;
  productId: string;
  startDate: string;
  endDate: string;
  platformId: string;
  productType: string;
  channelType: string;
};

const ProductGenderCard: React.FC<Props> = ({
  chartID,
  productId,
  startDate,
  endDate,
  platformId,
  productType,
  channelType,
}: Props) => {
  const [genderData, setGenderData] = useState<DataEntry[]>([]);
  const [mockData, setMockData] = useState<DataEntryDataFor[]>([]);
  const [selectedItem, setSelectedItem] = useState<string>("All");
  const [dropdownData, setDropdownData] = useState<string[]>(["All"]);

  let root;

  // Data
  const showGraph = (graphData, maleValue, femaleValue) => {
    console.log("Show Graph execution start");
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        // wheelX: "panX",
        // wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );
    // Use only absolute numbers
    chart.getNumberFormatter().set("numberFormat", "#.#s");
    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );
    legend.data.setAll(chart.series.values);
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "age",
        renderer: am5xy.AxisRendererY.new(root, {
          inversed: true,
          cellStartLocation: 0.1,
          cellEndLocation: 0.9,
          minGridDistance: 10,
        }),
      })
    );
    if (graphData && graphData.length > 0) {
      let sortedData = graphData.sort((a, b) => (a["age"] > b["age"] ? 1 : -1));
      console.log("Sortedl Data :", sortedData);
      yAxis.data.setAll(sortedData);
    } else {
      yAxis.data.setAll(graphData);
    }

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 0.1,
          // minGridDistance:10
        }),
      })
    );
    createSeries(
      root,
      chart,
      xAxis,
      yAxis,
      maleValue,
      am5.p100,
      "right",
      1,
      graphData
    );
    createSeries(
      root,
      chart,
      xAxis,
      yAxis,
      femaleValue,
      0,
      "left",
      1,
      graphData
    );
    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
      })
    );
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);
    return () => {
      root?.dispose();
      // this now gets called when the component unmounts
    };
  };

  useEffect(() => {
    const getData = async () => {
      let requestData = {
        productId,
        startDate,
        endDate,
        platformId,
        productType,
      };

      let myUrl =
        channelType == "product"
          ? URLS.GET_GENDER_CLICKS_PRODUCT
          : URLS.GET_GENDER_CLICKS_PLATFORM;

      let response = await getDataFromBackend(requestData, myUrl);
      console.log(
        "Response of Overall Age Group Division by Gender (Clicks): ",
        response?.data,
        myUrl
      );

      let data = response?.data;
      if (data && channelType !== "platform") {
        if (data) {
          data.map((item) => {
            item?.products?.sort((a, b) => b.value - a.value);
          });
        }
        setGenderData(data);
      } else if (data && channelType == "platform") {
        setMockData(data);
      }
    };
    getData();
  }, [chartID, productId, startDate, endDate, productType]);

  useEffect(() => {
    root = am5.Root.new(chartID);
    root._logo?.dispose();
    root.setThemes([ChartTheme.getTheme(root)]);
    if (channelType !== "platform" && genderData?.length > 0) {
      showGraph(genderData, "mValue", "fValue");
    } else {
      const mockDropdown = Array.from(
        new Set(mockData?.map((item) => item.dataFor))
      );
      setDropdownData(mockDropdown);

      const selectedData = mockData?.filter(
        (item) => item.dataFor === selectedItem
      );

      const newData = selectedData[0]?.data.map((item) => ({
        ...item,
      }));
      if (newData && newData.length > 0) {
        showGraph(newData, "mValue", "fValue");
      }
    }
    return () => {
      root?.dispose();
    };
  }, [selectedItem, mockData, genderData]);

  const createSeries = (
    root,
    chart,
    xAxis,
    yAxis,
    field,
    labelCenterX,
    pointerOrientation,
    rangeValue,
    graphData
  ) => {
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: field,
        categoryYField: "age",
        sequencedInterpolation: true,
        clustered: false,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: pointerOrientation,
          labelText:
            field === "mValue"
              ? "Male " + ": {valueX}"
              : "Female " + ": {valueX}",
        }),
      })
    );

    series.columns.template.setAll({
      height: am5.p100,
      strokeOpacity: 1,
      fillOpacity: 1,
    });

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationX: 1,
        locationY: 0.5,
        sprite: am5.Label.new(root, {
          centerY: am5.p50,
          text: "{valueX}",
          populateText: true,
          centerX: labelCenterX,
        }),
      });
    });
    if (graphData && graphData.length > 0) {
      let sortedData = graphData.sort((a, b) => (a["age"] > b["age"] ? 1 : -1));
      series.data.setAll(sortedData);
    } else {
      series.data.setAll(graphData);
    }

    series.appear();

    let rangeDataItem = xAxis.makeDataItem({
      value: rangeValue,
    });
    xAxis.createAxisRange(rangeDataItem);

    let label = rangeDataItem.get("label");
    let displayValue = field === "mValue" ? "Male" : "Female";
    label.setAll({
      text: displayValue,
      fontSize: "1.1em",
      fill: series.get("stroke"),
      paddingTop: 10,
      isMeasured: true,
      centerX: labelCenterX,
    });

    return series;
  };

  const handleDropdownItemClick = (data: string) => {
    setSelectedItem(data);
  };

  return (
    <div
      className="card card-flush h-lg-100 p-5"
      style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
    >
      <div className="card-title d-flex justify-content-between align-items-center">
        <div className="card-label fw-bold text-dark fs-4">
          Overall Age Group Division by Gender (Clicks)
        </div>
        {channelType == "platform" && (
          <div>
            <CustomDropdown
              dropdownData={dropdownData}
              selectedItem={selectedItem}
              onItemClick={handleDropdownItemClick}
            />
          </div>
        )}
      </div>
      <div className="align-items-center">
        {genderData?.length > 0 || mockData?.length > 0 ? (
          <div id={chartID} style={{ height: "327px", width: "100%" }}></div>
        ) : (
          <div
            id={chartID}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "285px",
            }}
          >
            <CustomLoader />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductGenderCard;
