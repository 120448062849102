/** @format */

import axios from "axios";
import { AuthModel, UserModel } from "./_models";
import { loginData } from "./config/loginData";
// import { verifyData } from "./config/verifyData";
import { useStorage } from "../../../hooks/useStorage";
import { useAuth } from "./Auth";
import { setupInterceptorsTo } from "../../interceptors";
const API_URL = process.env.REACT_APP_API_URL;
const authToken = useStorage.get("kt-auth-react-v");


const auth:UserModel = useStorage.get("kt-auth-react-v");
const verifyData={
	data:auth
}

setupInterceptorsTo(axios);

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;
export const LOGIN_URL = `${API_URL}/login`;
export const REGISTER_URL = `${API_URL}/register`;
export const EDIT_URL = `${API_URL}/edit/`;
export const VIEW_URL = `${API_URL}/view`;
export const DELETE_URL = `${API_URL}/delete/`;
export const VIEW_EMAIL_URL = `${API_URL}/view/`;

// Server should return AuthModel
export function login(username: string, password: string) {
	var params = new URLSearchParams();
	params.append("username", username);
	params.append("password", password);
	return axios({
		method: "post",
		url: LOGIN_URL,
		data: params,
	});
	// return loginData;
}


export function userRegister(
	username: string,
	password: string,
	mobile: string,
	email: string,
	roles: string[]
) {
	// return axios.post<AuthModel>(REGISTER_URL, {
	// username,
	// password,
	// mobile,
	// email,
	// roles
	// });
	return loginData;
}


export function userView() {
	return axios.get<AuthModel>(VIEW_URL, {
		headers: { Authorization: `Bearer ${authToken.token}` },
		data: {},
	});
	// return loginData;
}

export function userEdit(
	username: string,
	password: string,
	mobile: string,
	email: string,
	roles: string[]
) {
	// return axios.post<AuthModel>(REGISTER_URL, {
	// username,
	// password,
	// mobile,
	// email,
	// roles
	// });
	return loginData;
}

export function userViewByEmail() {
	// return axios.post<AuthModel>(REGISTER_URL, {
	// username,
	// password,
	// mobile,
	// email,
	// roles
	// });
	return loginData;
}

export function userDelete() {
	// return axios.post<AuthModel>(REGISTER_URL, {
	// username,
	// password,
	// mobile,
	// email,
	// roles
	// });
	return loginData;
}
// Server should return AuthModel
export function register(
	email: string,
	firstname: string,
	lastname: string,
	password: string,
	password_confirmation: string
) {
	return axios.post(REGISTER_URL, {
		email,
		first_name: firstname,
		last_name: lastname,
		password,
		password_confirmation,
	});
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
	return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
		email,
	});
}
// const { currentUser } = useAuth();

export function getUserByToken(token: string) {
	// return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
	//   api_token: token,
	// });

	// console.log(currentUser, "pppll");
	return verifyData;
}


export function productData(productId : string, startDate: string, endDate: string) {
	var params = new URLSearchParams();
	params.append("productId", productId);
	params.append("startDate", startDate);
	params.append("endDate", endDate);
	return axios({
		method: "post",
		url: LOGIN_URL,
		data: params,
	});
	// return loginData;
}