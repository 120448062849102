import React, { useEffect, useRef, useState } from "react";
import { FormatUtil } from "../../../../assets/ts/_utils/FormatUtil";
import {
    URLS,
    getDataFromBackend,
    getMethodDataFromBackend,
} from "../../../../../app/modules/dashboards/DashboardRequests";
import CustomLoader from "../../../../../app/pages/dashboard/marketting_component/CustomLoader";
import { IoTriangleSharp } from "react-icons/io5";
import CustomBaselineTooltip from "../../../../../app/pages/dashboard/marketting_component/CustomBaselineTooltip";
import HeaderDropdown from "../../../../../app/pages/dashboard/Custom_Components/HeaderDropdown";
import GeneralDropdown from "../../../../../app/pages/dashboard/Custom_Components/GeneralDropdown";
import DoubleDropdown from "../../../../../app/pages/dashboard/Custom_Components/DoubleDropdown";

type Statistic = {
    title: string;
    value: number;
    baseLine: number;
};

type Props = {
    productId: string;
    platformId: string;
    channelType: string;
    startDate: string;
    endDate: string;
    baseLine: string;
    statsSubUrl: string;
    productType: string;
    setSelectedProductType: any;
    setForecastedValue: any;

};

const ForecastCard: React.FC<Props> = ({
    productId,
    platformId,
    channelType,
    startDate,
    endDate,
    baseLine,
    statsSubUrl,
    productType,
    setSelectedProductType,
    setForecastedValue
}: Props) => {

    const metricData = ['All', 'CPC', 'CPL', 'CPEL'];
    const parameterData = ['Age', 'Gender', 'Devices', 'Product Type'];
    const age = ["18 - 24", "25 - 34", "35 - 44", "45 - 54", "55 - 64", "65+", "Unknown"];
    const gender = ["Male", "Female", "Unknown"];
    const devices = ["Mobile phones", "Computers", "Tablets", "TV screens"];
    const productTypeData = ["Premium", "Regular", "hpcl-super-saver", "Freedom"];
    const [parameters, setParameters] = useState<any>(['Age', 'Gender', 'Devices', 'Product Type']);
    const [selectedMetric, setSelectedMetric] = useState("");
    const [selectedParameter, setSelectedParameter] = useState("");
    const [selectedValue, setSelectedValue] = useState("");
    const [requestData, setRequestData] = useState<any>();
    console.log("data", requestData)
    const [cancelClicked, setCancelClicked] = useState(false);
    const [openSubParameter, setOpenSubParameter] = useState(false);
    const [loader, setLoader] = useState(false);
    const [successData, setSuccessData] = useState<any>();

   

    const handleMetricDropdownClick = (data) => {
        setSelectedMetric(data);
    };

    const handleParameterClick = (data) => {
        setSelectedParameter(data);
        setOpenSubParameter(true);

    };

    const handleDropdownItemClick = (data) => {
        setSelectedValue(data);
        setOpenSubParameter(false);
        setParameters(
            parameters.filter(
                (param) => param.split(":")[0] !== selectedParameter).concat([`${selectedParameter} : ${data}`])
        );
    };

    const isForecastable = parameters?.filter((param) => param.includes(":")).length === 4;

    useEffect(() => {
        if (parameters?.filter((param) => param.includes(":")).length === 4) {
            const keyMapping = {
                "Age": "age",
                "Devices": "device",
                "Product Type": "product_type",
                "Gender": "gender"
            };

            const modifiedData = parameters.map((item) => item.split(" : "));
            const jsonData = Object.fromEntries(modifiedData.map(([key, value]) => [keyMapping[key], value]));
            setRequestData(jsonData);
            console.log(jsonData, 'jsssson');
        }
    }, [parameters]);

    { console.log(parameters, 'parm') }
    { console.log(requestData, 'reqqq') }

    const forecastClick = async () => {
        const API_BASE_URL = process.env.REACT_APP_API_URL;
        setLoader(true);
        if (requestData) {
            const requestDataModified = {
                ...requestData,
                product_type: requestData.product_type.charAt(0).toLowerCase() + requestData.product_type.slice(1)
            };
            fetch(`${API_BASE_URL}/forecast/sales`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestDataModified),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log("POST request successful", data);
                    setSuccessData(data);
                    setSelectedMetric("");
                    setLoader(false);
                    setForecastedValue(data);
                })
                .catch((error) => {
                    console.error("Error making POST request", error);
                });
        }
    };


    const cancelClick = () => {
        setSelectedMetric("");
        setSelectedParameter("");
        setSelectedValue("");
        setCancelClicked(true);
        setParameters(['Age', 'Gender', 'Devices', 'Product Type']);
        setRequestData("");
        setLoader(false);
        setSuccessData("");

    }
    { console.log(selectedParameter, selectedValue, 'respneed') }


    return (
        <div
            className="h-lg-75s w-100 px-20  "
            // style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)", }}
        >
            <div className="header fw-bold fs-3 " style={{marginTop:"-30px"}}>Forecast</div>
            <div className="row  gx-xl-10 ">
                <div className="col-xl-3">
                    <GeneralDropdown
                        selectedValue={selectedMetric}
                        dropdownItems={metricData}
                        onDropdownItemClick={handleMetricDropdownClick}
                        title={'Metric'}
                    />
                    {/* hello */}
                </div>
                <div className="col-xl-3">
                    <DoubleDropdown
                        selectedValue={selectedParameter}
                        dropdownItems={parameters}
                        onDropdownItemClick={handleParameterClick}
                        title={'Parameter'}
                        cancelClicked={cancelClicked}
                        responseValue={selectedValue}
                        isForecastable={isForecastable}
                    />
                </div>
                <div className="col-xl-3">


                    {selectedParameter && openSubParameter && (
                        <GeneralDropdown
                            selectedValue={''}
                            dropdownItems={selectedParameter === "Age"
                                ? age
                                : selectedParameter === "Gender"
                                    ? gender
                                    : selectedParameter === "Devices"
                                        ? devices
                                        : productTypeData
                            }
                            onDropdownItemClick={handleDropdownItemClick}
                            title={`Select value`}

                        />
                    )}
                </div>

                <div className="d-flex align-item-start">
                    <table style={{ margin: '20px', width: '70%', textAlign: 'center', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th style={{ border: '2px solid red', backgroundColor: '#f2f2f2', padding: '8px', color: "red" }}>Parameter</th>
                                <th style={{ border: '2px solid red', backgroundColor: '#f2f2f2', padding: '8px', color: "red" }}>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {parameters.map((parameter, index) => {
                                const [param, value] = parameter.split(" : ");
                                return (
                                    <tr key={index}>
                                        <td style={{ border: '2px solid red', padding: '8px' }}>{param}</td>
                                        <td style={{ border: '2px solid red', padding: '8px' }}>{value}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="col-xl-3 mt-20">

                        <div
                            className="dropdown px-1"
                            style={{
                                width: "100%",
                                height: "50px",
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "space-between",
                                cursor: "pointer",
                            }}
                        >
                            <div
                                className="d-flex align-items-center fw-bold h-35px button"
                                style={{
                                    border: "1px solid rgb(228,230,239)",
                                    height: "40px",
                                    borderRadius: "10px",
                                    width: "100%",
                                    backgroundColor: "#E84A42",
                                    color: "white",
                                    justifyContent: "center",
                                    fontSize: '12px',
                                    cursor: !isForecastable || loader ? "not-allowed" : "pointer",
                                    opacity: !isForecastable || loader ? 0.6 : 1,
                                }}
                                // aria-disabled='true'
                                onClick={forecastClick}
                                aria-disabled={!isForecastable}
                            >
                                {loader && isForecastable ? "FORECASTING..." : "FORECAST"}
                            </div>


                            <div
                                className="d-flex align-items-center fw-bold ms-10  button h-35px"
                                style={{
                                    border: "2px solid #E84A42",
                                    height: "40px",
                                    borderRadius: "10px",
                                    width: "100%",
                                    backgroundColor: "white",
                                    color: "#E84A42",
                                    justifyContent: "center",
                                    fontSize: '12px'
                                }}
                                onClick={cancelClick}
                            >
                                CANCEL
                            </div>
                        </div>
                        {successData && (
                            <div className="my-3 fw-bold fs-5 d-flex justify-content-center mt-10" style={{ color: 'green' }}>
                                Forecasted CPC :{(successData?.predicted_avg_cpc !== undefined && successData?.predicted_avg_cpc !== null && successData?.predicted_avg_cpc.toFixed(2))}
                            </div>
                        )}

                    </div>

                </div>

                <div>


                </div>


            </div>


        </div>

    );
};

export default ForecastCard;
