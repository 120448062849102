import React, { useEffect, useState } from "react";
import {
  URLS,
  getDataFromBackend,
} from "../../../modules/dashboards/DashboardRequests";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { ChartTheme } from "../../../../theme/assets/ts/_utils/ChartTheme";
import GenderCardTypes from "./GenderCardTypes";
import CustomLoader from "./CustomLoader";
import { AiOutlineZoomIn } from "react-icons/ai";
import Modal from "./Modal";

type card = {
  cardName: any;
  mValue: number;
  fValue: number;
  unknown: any;
};

type DataEntry = {
  age: string;
  cardData: card[];
};

type Props = {
  productId: string;
  startDate: string;
  endDate: string;
  platformId: string;
  chartID: string;
  productType: string;
};

const GenderCard: React.FC<Props> = ({
  productId,
  startDate,
  endDate,
  chartID,
  platformId,
  productType
}: Props) => {
  let [genderData, setGenderData] = useState<DataEntry[]>([]);
  let [convertedGenderData, setConvertedGenderData] = useState<DataEntry[]>([]);
  let root;
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);

  useEffect(() => {
    const getData = async () => {
      let requestData = {
        productId,
        startDate,
        endDate,
        platformId,
        productType
      };
      let response = await getDataFromBackend(
        requestData,
        URLS.GET_PLATFORM_GENDER_PRODUCT_STATS
      );
      console.log("Response of gender cards : ", response?.data);

      let data = response?.data;
      if (data) {
        data.map((item) => {
          item?.products?.sort((a, b) => b.value - a.value);
        });
      }
      if (response?.data) {
        setGenderData(data);
        console.log("Akash setGenderData:-", data)
      } else {
        return;
      }
      // setGenderData(data);
      if (data && data.length > 0) {
        console.log("Data : ", data);
      }
    };

    getData();
  }, [chartID, productId, startDate, endDate, productType]);

  useEffect(() => {
    const convertedData = genderData
      .map((entry) => {
        const modifiedCardData = entry.cardData.map((card) => ({
          ...card,
          mValue: -card.mValue,
        }));

        return {
          age: entry.age === "Unknown" ? "Unknown" : `Age for ${entry.age}`,
          cardData: modifiedCardData,
        };
      })
      .sort((a, b) => {
        const getAgeNumber = (ageStr) => {
          const ageMatch = ageStr.match(/\d+/g);
          return ageMatch ? parseInt(ageMatch[0]) : 0;
        };

        if (a.age === "Unknown") return 1;
        if (b.age === "Unknown") return -1;

        const ageA = getAgeNumber(a.age);
        const ageB = getAgeNumber(b.age);
        return ageA - ageB;
      });

    console.log("Akash convertedDataconvertedData", convertedData);

    setConvertedGenderData(convertedData);
  }, [genderData]);

  return (
    <div>
      <div
        className="card card-flush h-lg-100 p-5 pb-20"
        style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)", }}
      >
        <div className="card-title d-flex justify-content-between align-items-center">
          <div className="card-label fw-bold text-dark fs-4">
            Age Group Division by Credit Card Types (Clicks)
          </div>
          <div
            className="fw-bold"
            style={{
              fontSize: "12px",
              textAlign: "end",
              cursor: "pointer",
            }}
            onClick={() => setIsZoomModalOpen(true)}
          >
            <AiOutlineZoomIn size={25} />
          </div>
        </div>
        {convertedGenderData?.length > 0 ? (
          <div style={{paddingBottom:'10px', marginTop:'10px'}}>
            {convertedGenderData.map((entry) => {
              return (
                <div key={entry.age}>
                  <GenderCardTypes
                    data={entry.cardData}
                    productId={productId}
                    startDate={startDate}
                    endDate={endDate}
                    platformId={platformId}
                    chartID={entry.age}
                    title={entry.age}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div style={{ alignSelf: "center", marginBottom: "20px" }}>
            <CustomLoader />
          </div>
        )}
      </div>

      {isZoomModalOpen && (
        <Modal
          isOpen={isZoomModalOpen}
          onClose={() => setIsZoomModalOpen(false)}
          title={"Age Group Division by Credit Card Types (Clicks)"}
        >
          {convertedGenderData.map((entry) => (
            <GenderCardTypes
              key={entry.age}
              data={entry.cardData}
              productId={productId}
              startDate={startDate}
              endDate={endDate}
              platformId={platformId}
              chartID={`${entry.age}-modal`}
              title={entry.age}
            />
          ))}
        </Modal>
      )}
    </div>
  );
};

export default GenderCard;
