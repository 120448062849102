/** @format */

import { ListViewProvider, useListView } from "./core/ListViewProvider";
import {
	QueryRequestProvider,
	useQueryRequest,
} from "./core/QueryRequestProvider";
import {
	useQuerySourceData,
	SourceQueryResponseProvider,
	useSourceResponsePagination,
	useQuerySourceResponseLoading,
	useSourceQueryResponse,
} from "./core/QueryResponseProvider";
import { QueryClient, useQuery, useQueryClient } from "react-query";

import { UsersListHeader } from "./components/header/UsersListHeader";
import { UsersTable } from "./table/UsersTable";
import { UserEditModal } from "./user-edit-modal/UserEditModal";
import { KTCard } from "../../../../../theme/helpers";
import { DeleteModal } from "../../../widgets/components/DeleteModal";
import { usersColumns } from "./table/columns/_columns";
import { useMemo, useEffect, useState } from "react";
import { stringifyRequestQuery } from "../../../../../theme/helpers";
import {
	deleteMasterRole,
	deleteSource,
	getMasterRules,
} from "../../../application/_requests";
import { Toast } from "./Toast";
// import { IsExpired, useAuth } from "../../../auth";

const ConfigurationList = () => {
	const queryClient = useQueryClient();
	const {
		setItemIdForDelete,
		itemIdForUpdate,
		setItemIdForUpdate,
		itemIdForDelete,
		toastMessage,
		setToastMessage,
	} = useListView();
	const columns = useMemo(() => usersColumns(), []);
	const source = useQuerySourceData();
	// const isLoading = useQuerySourceResponseLoading();
	// const { isFetching } = useSourceQueryResponse();
	const data = useMemo(() => source, [source]);
	const pagination = useSourceResponsePagination();
	const { updateState, state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

	const {
		isLoading,
		isFetching,
		refetch,
		data: response,
	} = useQuery(
		[`ALL_MASTER_RULE_LISTING`],
		() => {
			return getMasterRules();
		},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
	);

	console.log(response, "responseMmmmmmmmmm");

	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery, query]); //query added additionally

	const updatePage = (page: number | null) => {
		queryClient.invalidateQueries([`ALL_MASTER_RULE_LISTING`]);
		if (!page || isLoading || pagination.page === page) {
			return;
		}
		updateState({ page, items_per_page: pagination.items_per_page || 10 });
		queryClient.invalidateQueries([`ALL_MASTER_RULE_LISTING`]);
	};

	const handleDelete = async () => {
		await deleteMasterRole(itemIdForDelete?.masterRuleId);
		setItemIdForDelete(undefined);
		setToastMessage("Rule Deleted Successfully");
		setTimeout(() => {
			setToastMessage(undefined);
		}, 2000);
		queryClient.invalidateQueries([`ALL_MASTER_RULE_LISTING`]);
	};
	return (
		<>
			<KTCard>
				<UsersListHeader title="Add Rule" placeholder="Search by Name" />

				{response && (
					<UsersTable
						columns={columns}
						users={response}
						isLoading={isLoading}
						isFetching={isFetching}
						onNextPage={updatePage}
						pagination={pagination}
					/>
				)}
			</KTCard>
			{itemIdForUpdate !== undefined && (
				<UserEditModal
					content={itemIdForUpdate?.masterRuleId ? "add_source" : "add_source"}
					modalText={itemIdForUpdate?.masterRuleId ? "Edit Rule" : "Add Rule"}
					onCloseEditModal={() => setItemIdForUpdate(undefined)}
					user={itemIdForUpdate}
				/>
			)}
			{itemIdForDelete !== undefined && <DeleteModal onDelete={handleDelete} />}
			{toastMessage !== undefined && <Toast title={toastMessage} />}
		</>
	);
};

const ConfigurationListWrapper = () => (
	<QueryRequestProvider>
		<SourceQueryResponseProvider>
			<ListViewProvider>
				<ConfigurationList />
			</ListViewProvider>
		</SourceQueryResponseProvider>
	</QueryRequestProvider>
);

export { ConfigurationListWrapper };
