/** @format */

import React, { useEffect, useState } from "react";
import { KTCard } from "../../../../theme/helpers";
import { useQuery } from "react-query";
import { getTopListing } from "../../../modules/application/_requests";
import { useParams } from "react-router-dom";
import CustomDropdown from "../marketting_component/CustomDropdown";
import {
  URLS,
  getDataFromBackend,
} from "../../../modules/dashboards/DashboardRequests";
import CustomLoader from "../marketting_component/CustomLoader";

// type DataEntry = {
//     data: string[];
//     dataFor: string;
//     // age: string;
// };

type Props = {
  // chartID:string;
  productId: string;
  platformId: string;
  campaignId: string;
  startDate: string;
  endDate: string;
  title: string;
  channelType: string;
  subTypeUrl: string;
};

const TopListing: React.FC<Props> = ({
  campaignId,
  productId,
  startDate,
  endDate,
  title,
  platformId,
  channelType,
  subTypeUrl,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const [selectedItem, setSelectedItem] = useState("Clicks");
  const [datas, setDatas] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);

  const dropdownData = ["Impression", "Clicks", "CTR", "Avg.CPC"];

  useEffect(() => {
    const getData = async () => {
      let requestData = {
        startDate,
        endDate,
        productId,
        platformId,
        campaignId,
      };
      let response = await getDataFromBackend(
        requestData,
        URLS.GET_CAMPAIGN_BASEURL + subTypeUrl
      );
      console.log("Responses of state: ", response?.data);

      let data = response?.data;
      setDatas(data);
    };

    getData(); // run it, run it

    return () => {
      // this now gets called when the component unmounts
    };
  }, [startDate, endDate, productId, platformId, campaignId]);

  // const datas = [
  // 	{
  // 		"data": [
  // 			{
  // 				"city": "Uttar Pradesh",
  // 				"value": 40608
  // 			}
  // 		],
  // 		"dataFor": "Clicks"
  // 	},
  // 	{
  // 		"data": [
  // 			{
  // 				"city": "Uttar Pradesh",
  // 				"value": 4027483
  // 			}
  // 		],
  // 		"dataFor": "Impression"
  // 	},
  // 	{
  // 		"data": [
  // 			{
  // 				"city": "Uttar Pradesh",
  // 				"value": 65403
  // 			}
  // 		],
  // 		"dataFor": "CTR"
  // 	},
  // 	{
  // 		"data": [
  // 			{
  // 				"city": "Uttar Pradesh",
  // 				"value": 92041
  // 			}
  // 		],
  // 		"dataFor": "Avg.CPC"
  // 	}
  // ]

  useEffect(() => {
    if (datas?.length > 0) {
      const selectedData = datas?.filter(
        (item) => item.dataFor === selectedItem
      );
      setFilteredData(selectedData);
    }
  }, [datas, selectedItem]);

  return (
    <div
      className="card card-flush h-lg-100 py-5 px-8"
      style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          className="text-start fw-bold text-gray-800 fs-4"
          style={{ paddingTop: "7px", paddingBottom: "9px" }}
        >
          <span className="card-label fw-bold text-dark fs-6">{title}</span>
        </div>
        <div className="d-flex align-items-center fw-bold flex-end">
          <div className="dropdown">
            <div
              data-kt-daterangepicker="true"
              data-kt-daterangepicker-opens="left"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary d-flex align-items-center px-0 dropdown-toggle"
            >
              {selectedItem || "Clicks"}
            </div>
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="dropdownMenuButton1"
            >
              {dropdownData.map((data) => (
                <li
                  key={data}
                  onClick={() => {
                    setSelectedItem(data);
                    console.log(data, "dddhh");
                  }}
                >
                  <span className="dropdown-item text-hover-primary">
                    {data}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {datas?.length > 0 ? (
        <div className="table-responsive mb-5" style={{ width: "100%" }}>
          {filteredData?.length > 0 ? (
            <table
              id="kt_table_users"
              className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer"
            >
              {filteredData.map((item, key) => (
                <React.Fragment key={key}>
                  {item?.data
                    ?.slice()
                    .sort((a, b) => b.value - a.value)
                    .map((state, stateKey) => (
                      <tr
                        className="text-gray-800 fw-bold text-hover-primary fs-6"
                        style={{ fontSize: "14px" }}
                        key={stateKey}
                      >
                        <React.Fragment>
                          <td>{state?.city}</td>
                          {selectedItem === "Avg.CPC" ? (
                            <td
                              className="d-flex flex-end"
                              style={{ textAlign: "right" }}
                            >
                              ₹ {state?.value?.toFixed(2)}
                            </td>
                          ) : selectedItem === "CTR" ? (
                            <td
                              className="d-flex flex-end"
                              style={{ textAlign: "right" }}
                            >
                              {state?.value?.toFixed(2)}%
                            </td>
                          ) : (
                            <td
                              className="d-flex flex-end"
                              style={{ textAlign: "right" }}
                            >
                              {state?.value?.toFixed(2)}
                            </td>
                          )}
                        </React.Fragment>
                      </tr>
                    ))}
                </React.Fragment>
              ))}
            </table>
          ) : (
            <div
              className="text-center"
              style={{ fontSize: "16px", color: "#999", marginTop: "10px" }}
            >
              No data found
            </div>
          )}
        </div>
      ) : (
        <div className="py-5" style={{ alignSelf: "center" }}>
          <CustomLoader />
        </div>
      )}
    </div>
  );
};

export default TopListing;
