import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../modules/auth";
import {
  getDataFromBackend,
  getMethodDataFromBackend,
  URLS,
} from "../../modules/dashboards/DashboardRequests";
import { primaryColors } from "../../../theme/assets/ts/_utils/AppColors";

type Product = {
  productName: string;
  productId: string;
  icon?: string;
};

type Platform = {
  platformName: string;
  platformId: string;
};

function MarketingProductTypes() {
  const { setBread } = useAuth();
  const navigate = useNavigate();

  const location = useLocation();

  const [productData, setProductData] = useState<Product[]>([]);
  const [platformData, setPlatformData] = useState<Platform[]>([]);

  const [selectedProduct, setSelectedProduct] = useState({
    productName: "",
    productId: "",
  });

  const [selectedPlatform, setSelectedPlatform] = useState({
    platformName: "",
    platformId: "",
  });

  const [selectedTabs, setSelectedTabs] = useState<number[]>([0]);

  const navbarData = [
    {
      productName: "Product",
      productId: "7cba513f-317c-4407-8726-9f8082b854f2",
    },
    {
      productName: "Platform",
      productId: "7cba513f-317c-4407-8726-9f8082b854f2",
    },
    {
      productName: "Campaign",
      productId: "7cba513f-317c-4407-8726-9f8082b854f2",
    },
    {
      productName: "Consumer",
      productId: "7cba513f-317c-4407-8726-9f8082b854f2",
    },
    {
      productName: "Rule",
      productId: "7c468513f-330c-4207-8336-9f8762b854f2",
    },
  ];

  const handleNavigation = (data, index) => {
    const pathname = location?.pathname;
    const lastPathSegment = pathname.split("/").pop();

    const item = {
      campaignName: lastPathSegment,
    };

    const productId = selectedProduct?.productId;
    const platformId = selectedPlatform?.platformId;

    if (index == 3 && selectedTabs.includes(2)) {
      setSelectedTabs([0, 1, 2, 3]);
      console.log("condition satisfied");
      setBread(`${item?.campaignName} Reports`);
      console.log("first", item, productId, platformId);
      navigate(`dashboard/1/consumer/${item?.campaignName}`, {
        state: { item, productId, platformId },
      });
    } else if (index == 2 && selectedTabs.includes(3)) {
      navigate(-1);
    } 
    else if (index == 4) {
      navigate(`rule-listing`)}
  };

  const getData = async () => {
    let responseProduct = await getMethodDataFromBackend(
      URLS.GET_MARKETING_PRODUCT
    );
    let data = responseProduct?.data;
    setProductData(data);
    let requestData = {
      productId: 0,
      platformId: 1,
      startDate: "2023-01-01",
      endDate: "2024-01-01",
    };

    let responsePlatform = await getDataFromBackend(
      requestData,
      URLS.GET_PRODUCT_CATEGORY
    );

    console.log("eeeeeeeee", data, responsePlatform);

    const customSort = (a, b) => {
      if (a.platformName === "Google") {
        return -1;
      } else if (b.platformName === "Google") {
        return 1;
      } else {
        return a.platformName.localeCompare(b.platformName);
      }
    };

    const sortedPlatforms = responsePlatform?.data?.sort(customSort);
    setPlatformData(sortedPlatforms);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (location.pathname === "/marketing-dashboard") {
      setSelectedTabs([0]);
      setSelectedProduct({
        productName: "",
        productId: "",
      });
      setSelectedPlatform({
        platformName: "",
        platformId: "",
      });
    } else if (location.pathname.startsWith("/dashboard/1/consumer")) {
      setSelectedTabs([0, 1, 2, 3]);
    } else if (location.pathname.startsWith("/dashboard/1/report")) {
      setSelectedTabs([0, 1, 2]);
    } else if (location.pathname.startsWith("/dashboard/1")) {
      setBread(
        `${selectedPlatform?.platformName} Performance Marketing Dashboard`
      );
      setSelectedTabs([0, 1]);
    } else if (location.pathname.startsWith("/product-dashboard")) {
      setBread(`${selectedProduct?.productName} Marketing Dashboard`);
      setSelectedTabs([0, 1]);
      setSelectedPlatform({
        platformName: "",
        platformId: "",
      });
    }
  }, [productData, location]);

  const onSelectDropdown = (data, index) => {
    console.log("onSelectDropdown", data, index);
    if (index === 0) {
      setSelectedTabs([...selectedTabs, index + 1]);
      setSelectedProduct(data);
      setBread(`${data.productName} Marketing Dashboard`);
      navigate("/product-dashboard", { state: data });
    } else if (index === 1) {
      setSelectedPlatform(data);
      const routeData = {
        platformName: data?.platformName,
        platformId: data?.platformId,
        productId: selectedProduct?.productId,
      };
      console.log("2nd index coming", routeData);
      setBread(`${data?.platformName} Performace Marketing Dashboard`);
      navigate(`/dashboard/1`, {
        state: routeData,
      });
    } else if (index === 2) {
      console.log("3rd index coming", data);
    } else {
      console.log("4th index coming", data);
    }
  };

  return (
    <div className="">
      <div className="d-flex align-items-center justify-content-between">
        {navbarData.map((data, index, type: any) => {
          const myCondition = selectedTabs.includes(index);
          const myDropdownMap = index === 0 ? productData : platformData;
          const selectedDropdown =
            index === 0
              ? selectedProduct?.productName
              : index === 1
              ? selectedPlatform?.platformName
              : "";

          const indexCondition = index == 0 || index == 1;

          return (
            <div
              className={"dropdown mx-3"}
              style={{
                zIndex: 2,
              }}
              key={`productName${data.productName}`}
              onClick={() => {
                handleNavigation(data, index);
              }}
            >
              <div
                data-kt-daterangepicker="true"
                data-kt-daterangepicker-opens="left"
                id={`dropdownMenuButton3-${index}`}
                data-bs-toggle={myCondition && indexCondition ? "dropdown" : ""}
                aria-expanded="false"
                className={
                  myCondition && indexCondition
                    ? "dropdown-toggle  px-15 mx-3"
                    : " px-15 mx-3"
                }
                style={{
                  backgroundColor: myCondition ? "#08112D" : "transparent",
                  borderRadius: myCondition ? "10px" : "0",
                  fontSize: "14px",
                  fontWeight: "600",
                  whiteSpace: "nowrap",
                  textAlign: "center",
                  color: myCondition
                    ? primaryColors.white
                    : selectedTabs.includes(2)
                    ? primaryColors.black
                    : primaryColors.grey,
                  padding: "5px",
                  cursor:
                    selectedTabs.includes(2) || myCondition ? "pointer" : "",
                  width: "100%",
                }}
              >
                {selectedDropdown || data?.productName}
              </div>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby={`dropdownMenuButton3-${index}`}
                style={{width: "100%"}}
              >
                {myDropdownMap.map((data, place) => (
                  <li
                    key={data?.productId || place}
                    onClick={() => onSelectDropdown(data, index)}
                    style={{ cursor: "pointer", width: "100%" }}
                  >
                    <span className="dropdown-item text-hover-primary">
                      {data?.productName || data?.platformName}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MarketingProductTypes;
