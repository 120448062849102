/** @format */

import { lazy, FC, Suspense, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../theme/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { MenuTestPage } from "../pages/MenuTestPage";
import { getCSSVariableValue } from "../../theme/assets/ts/_utils";
import { WithChildren } from "../../theme/helpers";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";
import { AnnotationScreens } from "../modules/apps/user-management/AnnotationScreen";
import SourceBoard from "../modules/apps/user-management/SourceBoard";
import OrganizationBoard from "../modules/apps/user-management/OrganizationBoard";
import ParticipantBoard from "../modules/apps/user-management/ParticipantBoard";
import { ProductDashboard } from "../pages/dashboard/ProductDashboard";
import { useAuth } from "../modules/auth";
import DashboardElement from "../pages/dashboard/DashboardElement";
import DrilledSentiments from "../pages/dashboard/DrilledSentiments";
import Marketting from "../pages/dashboard/marketting_component/Marketting";
import { PageLink, PageTitle } from "../../theme/layout/core";
import { Breadcrumb } from "react-bootstrap";
import Configuration from "../pages/dashboard/capaign_components/Configuration";
import NewCampaign from "../pages/dashboard/capaign_components/NewCampaign";
import Biding from "../pages/dashboard/capaign_components/Bidding";
import CampaignSettings from "../pages/dashboard/capaign_components/CampaignSettings";
import Budget from "../pages/dashboard/capaign_components/Budget";
import Keywords from "../pages/dashboard/capaign_components/Keywords";
import { MdOutlineEdit } from "react-icons/md";
import Listing from "../pages/dashboard/capaign_components/Listing";
import StartNewModal from "../pages/dashboard/capaign_components/StartNewModal";
import PlatformDashboard from "../pages/dashboard/marketting_component/PlatformDashboard";
import CampaignDashboard from "../../theme/layout/components/header/header-menus/CampaignDashboard";
import MarketingDashboard from "../pages/dashboard/MarketingDashboard";
import ConsumerDashboard from "../../theme/layout/components/header/header-menus/ConsumerDashboard";
import RuleListing from "../pages/dashboard/Custom_Components/RuleListing"
import RuleDetails from "../pages/dashboard/marketting_component/RuleDetails";
import RuleCreate from "../pages/dashboard/Custom_Components/RuleCreate";
import CreatePlatformRule from "../pages/dashboard/Custom_Components/CreatePlatformRule";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
  const UsersPage = lazy(
    () => import("../modules/apps/user-management/UsersPage")
  );
  const ProfileDetails = lazy(
    () => import("../modules/profile/ProfileDetails")
  );

  const { currentUser, breadCrumb } = useAuth();
  const { currentUsers, breadCrumbs } = useAuth();
  const { current, bread } = useAuth();

  const [isEditMode, setIsEditMode] = useState(false);
  const [editedText, setEditedText] = useState("Ad group 1");
  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleInputChange = (e) => {
    setEditedText(e.target.value);
  };

  const handleSaveClick = () => {
    setIsEditMode(false);
    // Perform any necessary actions with the edited text
    console.log("Edited text:", editedText);
  };

  const ROLE: any | undefined = currentUser?.roles;
  const ROLES: any | undefined = currentUsers?.roles;
  const ROL: any | undefined = current?.roles;

  const reportBred: Array<PageLink> = [
    {
      title: "Home",
      path: "/marketing-dashboard",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "Product",
      path: "/product-dashboard",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "Platform",
      path: "detail",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "Campaign",
      path: "",
      isSeparator: false,
      isActive: true,
    },
  ];
  const dashboardBred: Array<PageLink> = [
    {
      title: "Home",
      path: "/marketing-dashboard",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Dashboard",
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];

  const newCampaignBred: Array<PageLink> = [
    {
      title: "Home",
      path: "/marketing-dashboard",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "Dashboards",
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];
  const configurationBred: Array<PageLink> = [
    {
      title: "Home",
      path: "/marketing-dashboard",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "Configuration",
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];
  const detailBred: Array<PageLink> = [
    {
      title: "Home",
      path: "/marketing-dashboard",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "Campaign",
      path: "",
      isSeparator: false,
      isActive: true,
    },
  ];
  const details: Array<PageLink> = [
    {
      title: "Home",
      path: "/marketing-dashboard",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "Product",
      path: "/product-dashboard",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "Platform",
      path: "",
      isSeparator: false,
      isActive: true,
    },
    // {
    //   title: "Rule Listing",
    //   path: "/rule-listing",
    //   isSeparator: false,
    //   isActive: true,
    // },
  ]

    const rule: Array<PageLink> = [
      {
        title: "Home",
        path: "/marketing-dashboard",
        isSeparator: false,
        isActive: false,
      },
      {
        title: "Rule Listing",
        path: "/rule-listing",
        isSeparator: false,
        isActive: false,
      },
      {
        title: "Rule Details",
        path: "/rule-details",
        isSeparator: false,
        isActive: true,
      },
      {
        title: "Create Rule",
        path: "/rule-create",
        isSeparator: false,
        isActive: true,
      },
  ];

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}

        <Route path="auth/*" element={<Navigate to="/marketing-dashboard" />} />
        {/* Pages */}
        <Route
          path="marketing-dashboard"
          element={
            <>
              <PageTitle breadcrumbs={dashboardBred} showBackButton={false}>
                Marketing Dashboard
              </PageTitle>
              <MarketingDashboard />
            </>
          }
        ></Route>

        <Route
          path="product-dashboard"
          element={
            <>
              {/* <PageTitle breadcrumbs={dashboardBred} showBackButton={false}>
                Credit Card Marketing Dashboard n
              </PageTitle> */}

              <PageTitle breadcrumbs={dashboardBred} showBackButton={true}>
                {bread}
              </PageTitle>

              <ProductDashboard />
            </>
          }
        ></Route>

        <Route
          path="newCampaign"
          element={
            <>
              <PageTitle>New Campaign</PageTitle>
              <NewCampaign />
            </>
          }
        ></Route>
        {/* <Route
          path="startnewModal"
          element={
            <>
			
              <StartNewModal className=""/>
            </>
          }
        ></Route> */}

        <Route
          path="biding"
          element={
            <>
              <PageTitle>Bidding</PageTitle>
              <Biding />
            </>
          }
        ></Route>

        <Route
          path="campaignSettings"
          element={
            <>
              <PageTitle>Campaign Settings</PageTitle>

              <CampaignSettings />
            </>
          }
        ></Route>

        <Route
          path="budget"
          element={
            <>
              <PageTitle>Budget</PageTitle>
              <Budget />
            </>
          }
        ></Route>

        <Route
          path="keywords"
          element={
            <>
              <PageTitle>Keywords</PageTitle>
              <div
                className="mb-7"
                style={{
                  marginTop: "-27px",
                  marginLeft: "30px",
                  fontSize: "14px",
                  color: "#3C4043",
                  fontFamily: "Arial",
                }}
              >
                Ad groups help you organize your ads around a common theme. For
                the best results, focus your ads and keywords on one product or
                service.
              </div>
              <div
                className="mb-7"
                style={{
                  marginTop: "",
                  marginLeft: "30px",
                  fontSize: "22px",
                  color: "#202124",
                  fontFamily: "Arial",
                }}
              >
                {" "}
                {isEditMode ? (
                  <div style={{ position: "relative", marginTop: "7px" }}>
                    <input
                      type="text"
                      style={{
                        padding: "5px",
                        paddingLeft: "10px",
                        width: "13%",
                        height: "50px",
                        borderRadius: "5px",
                        fontSize: "13px",
                        border: "1px solid #3c4043", // Set initial border color
                        borderColor: "#3c4043", // Set initial border color
                        borderWidth: "1px", // Set initial border width
                        color: "black",
                        outline: "none", // Remove default outline style
                        transition: "border-color 0.3s ease-in-out", // Add transition effect
                      }}
                      value={editedText}
                      onChange={handleInputChange}
                      onBlur={handleSaveClick}
                    />
                    <label
                      className="px-3"
                      style={{
                        position: "absolute",
                        left: "15px",
                        top: editedText !== "" ? "-7px" : "15px",
                        backgroundColor: "white",
                        transition: "all 0.3s ease-in-out",
                        pointerEvents: "none",
                        fontSize: editedText !== "" ? "12px" : "13px",
                        color: editedText !== "" ? "#0d6efd" : "#5f6368",
                      }}
                    >
                      Ad group name
                    </label>

                    <div
                      className="px-4"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "17px",
                        transform: "translateY(-50%)",
                        color: "#5F6368",
                        pointerEvents: "none", // Ignore pointer events to allow clicking the input
                      }}
                    >
                      {/* <IoMdLink size={25} /> */}
                    </div>
                  </div>
                ) : (
                  <div>
                    {editedText} <MdOutlineEdit onClick={handleEditClick} />
                  </div>
                )}
              </div>
              <Keywords />
            </>
          }
        ></Route>

        <Route
          path="listing"
          element={
            <>
              {/* <PageTitle>
            Campaign Listing
              </PageTitle> */}
              <Listing />
            </>
          }
        ></Route>

        <Route
          path="listingDemo"
          element={
            <>
              {/* <PageTitle>
            Campaign Listing
              </PageTitle> */}
              {/* <ListingDemo /> */}
            </>
          }
        ></Route>

        <Route
          path="dashboard/:campaignId/report/:channelId"
          element={
            <>
              <PageTitle breadcrumbs={reportBred} showBackButton={true}>
                {breadCrumbs}
              </PageTitle>
              <CampaignDashboard />
            </>
          }
        />

        <Route
          path="dashboard/:campaignId/consumer/:channelId"
          element={
            <>
              <PageTitle breadcrumbs={reportBred} showBackButton={true}>
                {breadCrumbs}
              </PageTitle>
              <ConsumerDashboard />
            </>
          }
        />
        {/* <Route
          path="dashboard/:campaignId"
          element={
            <>
              <PageTitle breadcrumbs={detailBred}>{breadCrumb}</PageTitle>

              <Marketting />
             
            </>
          }
        /> */}

        <Route
          path="dashboard/:campaignId"
          element={
            <>
              <PageTitle breadcrumbs={details} showBackButton={true}>
                {bread}
              </PageTitle>

              {/* <Marketting /> */}
              <PlatformDashboard />
            </>
          }
        />
           <Route
          path="rule-listing"
          element={
            <>
              <PageTitle breadcrumbs={rule} showBackButton={true}>
                {bread}
              </PageTitle>

              {/* <Marketting /> */}
              <RuleListing/>
            </>
          }
        />
        <Route
          path="rule-details"
          element={
            <>
              <PageTitle breadcrumbs={rule} showBackButton={true}>
                {bread}
              </PageTitle>
              <RuleDetails/>
            </>
          }
        />
            <Route
          path="rule-create"
          element={
            <>
              <PageTitle breadcrumbs={rule} showBackButton={true}>
                {bread}
              </PageTitle>
              <RuleCreate/>
            </>
          }
        />
           <Route
          path="rule-creates"
          element={
            <>
              <PageTitle breadcrumbs={rule} showBackButton={true}>
                {bread}
              </PageTitle>
              <CreatePlatformRule/>
            </>
          }
        />
        <Route
          path="configuration"
          element={
            <>
              <PageTitle breadcrumbs={configurationBred}>
                Configuration
              </PageTitle>

              <Configuration />
            </>
          }
        />
        <Route
          path="listing"
          element={
            <>
              <PageTitle>Campaigns</PageTitle>

              <Listing />
            </>
          }
        />
        {/* Lazy Modules */}
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />

        {/* {(ROLE[0] == "ADMIN" || "VIEWER") && ( */}

        {/* )} */}
        {/* <Route
          path='/source-management/*'
          element={
            <SuspensedView>
              <SourcePage/>
            </SuspensedView>
          }
        />   */}

        {/* {ROLE[0] == "ADMIN" && (
					<>
						<Route
							path="/user-management/*"
							element={
								<SuspensedView>
									<UsersPage />
								</SuspensedView>
							}
						/>

						<Route
							path="/organization-management/*"
							element={
								<SuspensedView>
									<OrganizationBoard />
								</SuspensedView>
							}
						/>

						<Route
							path="/source-management/*"
							element={
								<SuspensedView>
									<SourceBoard />
								</SuspensedView>
							}
						/>

						<Route
							path="/master-management/*"
							element={
								<SuspensedView>
									<ParticipantBoard />
									<OrganizationBoard />
								</SuspensedView>
							}
						/>
					</>
				)}
				<Route
					path="/annotation-management/*"
					element={
						<SuspensedView>
							<AnnotationScreens />
						</SuspensedView>
					}
				/> */}

        {/* Page Not Found */}
        {/* <Route path="*" element={<Navigate to="/error/404" />} /> */}
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
