import React, { useState } from 'react';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { DateRangePicker } from "materialui-daterange-picker";

const CustomDateRangePicker = ({ startDate, endDate, onDateRangeChange }) => {
  const [open, setOpen] = useState(false);
  const [initialDates, setInitialDates] = useState({
    startDate: startDate,
    endDate: endDate,
  });

  const toggle = () => {
    setOpen(!open);
  };

  const handleDateChange = (range) => {
    onDateRangeChange(range);
    setOpen(false);
  };

  return (
    <>
      <div
        className={`btn btn-sm d-flex flex-end align-items-center`}
        onClick={toggle}
        style={{
          height: "40px",
          backgroundColor: "white",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <AiTwotoneCalendar
          size={30}
          style={{
            color: "#CED0D6",
            marginRight: "20px",
            marginLeft: "-15px",
          }}
        />
        <div
          className="d-flex align-items-center"
          style={{
            border: "1px solid rgb(228,230,239)",
            height: "30px",
            borderRadius: "10px",
            width: "180px",
            backgroundColor: "#E84A42",
            color: "white",
            justifyContent: "center",
            marginRight: "-15px",
          }}
        >
          {startDate} - {endDate}
        </div>
      </div>
      <div style={{ position: "absolute", right: "0px", top: "40px" }}>
        <DateRangePicker
        open={open}
        toggle={toggle}
        initialDateRange={initialDates}
        onChange={handleDateChange}
      />
       </div>
      
    </>
  );
};

export default CustomDateRangePicker;
