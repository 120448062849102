/** @format */

import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { ChartTheme } from "../../../../theme/assets/ts/_utils/ChartTheme";
import Modal from "../marketting_component/Modal";
import {
  primaryColors,
  secondryColors,
} from "../../../../theme/assets/ts/_utils/AppColors";
import { URLS, getMethodDataFromBackend } from "../../../modules/dashboards/DashboardRequests";

type Props = {
  chartID: string;
  height: string;
  data: any[];
  valueXField: string;
  valueYField: string;
  isZoomModalOpen: any;
  setIsZoomModalOpen: any;
  title: any;
  channelType: any;
};

// type budgetData = {
// 	productName: string;
// 	value: number;
// }

type Color = any;

const HorizontalBarGraph: React.FC<Props> = ({
  chartID,
  height,
  data,
  valueXField,
  valueYField,
  isZoomModalOpen,
  setIsZoomModalOpen,
  title,
  channelType,
}) => {
  const [hardcodedColors, setHardcodedColors] = useState<Color[]>([]);
  const [colorCombination, setColorCombination] = useState<any>([]);

  useEffect(()=> {
    if(!colorCombination || colorCombination.length==0){
      const getColorCombintion = async () => {
        try {
          const response = await getMethodDataFromBackend(URLS.BASE_URL + "marketing/getcolorcodes");
          console.log("getColorCombintion response:-",chartID,data,title,response);
          if(response?.data) {
            setColorCombination(response.data)
          }
        } catch(error) {
          console.log("Some error occured!!!!")
        }
      }
      getColorCombintion();
    }
    
  }, [])

  useEffect(() => {
    if (data) {
      const reorderColors = () => {
        console.log("re renders ====> 1");
        
        const sortedProducts = [...data].sort((a, b) => a.value - b.value);
        const reorderedColors = sortedProducts.map((product) => (am5.color(getBarColor(product.productName || product.name)))
        // { 
        //   switch (product.productName || product.name) {
        //     case 
        //     "Millennia": 
        //       return am5.color(primaryColors.orange);
        //     case "Freedom":
        //       return am5.color(primaryColors.black);
        //     case "Regular":
        //       return am5.color(primaryColors.blue);
        //     case "Multicard":
        //       return am5.color(primaryColors.grey);
        //     case "Premium":
        //       return am5.color(secondryColors.orange);
        //     case "millennia"||"Millennia": 
        //         return am5.color(primaryColors.orange);
        //       case "freedom":
        //         return am5.color(primaryColors.black);
        //       case "regular":
        //         return am5.color(primaryColors.blue);
        //       case "Multicard":
        //         return am5.color(primaryColors.grey);
        //       case "premium":
        //         return am5.color(secondryColors.orange);
        //     case "Card on Card":
        //       return am5.color(secondryColors.purple);
        //     case "Amazon":
        //       return am5.color(primaryColors.orange);
        //     case "Vserv":
        //       return am5.color(primaryColors.black);
        //     case "Payu":
        //       return am5.color(primaryColors.grey);
        //     case "Flipkart":
        //       return am5.color(secondryColors.skyBlue);
        //     case "Google":
        //       return am5.color(secondryColors.orange);
        //     case "Affiliate":
        //       return am5.color(secondryColors.purple);
        //     case "Facebook":
        //       return am5.color(primaryColors.blue);
        //     default:
        //       return am5.color(primaryColors.orange);
        //   }
        // }
      );
        console.log("reorderedColors", reorderedColors);
        setHardcodedColors(reorderedColors);
      };

      reorderColors();
    }
  }, [data, colorCombination]);

  const getBarColor = useCallback((lable) => {
    const obj = colorCombination.find(item => item?.productPlatform === lable && (item.id))
    return obj ? obj.id : primaryColors.red;
  },[colorCombination, setColorCombination])

  

  useLayoutEffect(() => {
    console.log("re renders ====> 2");
    let root = am5.Root.new(chartID);
    root._logo?.dispose();
    // root.setThemes(
    //   channelType !== "platform"
    //     ? [ChartTheme.getBarGradientTheme(root)]
    //     : [ChartTheme.getReverseGradientTheme(root)]
    // );
    // root.setThemes([ChartTheme.getBarGradientTheme(root)]);
    // root.setThemes([ChartTheme.getBarPerformanceThemeOrange(root)]);
    if (data) {
      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          layout: root.verticalLayout,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
        })
      );

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let yRenderer = am5xy.AxisRendererY.new(root, {
        minGridDistance: 10,
        strokeWidth: 0,
      });

      yRenderer.grid.template.setAll({
        location: 1,
        strokeWidth: 1,
        forceHidden: true,
      });

      let yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: valueYField,
          renderer: yRenderer,
          // tooltip: am5.Tooltip.new(root, {
          // 	labelText: "{valueY}"
          // })
        })
      );
      if (data && data.length > 0) {
        let sortedData = data.sort((a, b) => a[valueXField] - b[valueXField]);
        yAxis.data.setAll(sortedData);
      }

      let xRenderer = am5xy.AxisRendererX.new(root, { strokeOpacity: 0.1 });

      let xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          renderer: xRenderer,
        })
      );

      xAxis.get("renderer").labels.template.setAll({
        visible: false, // Hide x-axis labels
      });

      yAxis.get("renderer").labels.template.setAll({ centerX: am5.p0 });
      xAxis
        .get("renderer")
        .grid.template.setAll({ strokeWidth: 1, forceHidden: true });
      yAxis.get("renderer").grid.template.setAll({ forceHidden: true });
      // yAxis.dataItems.sort((x, y) => x[valueXField] - y[valueXField]);
      let series0 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: valueXField,
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: valueXField,
          categoryYField: valueYField,
          // tooltip: am5.Tooltip.new(root, {
          // 	// labelText: "{valueX}"
          // })
        })
      );

      series0.columns.template.setAll({
        height: am5.percent(70),
        strokeOpacity: 1,
        tooltipText:
          title === "App Ratio" || title === "Cards Ratio" || title === "CTR"
            ? "{categoryY} : {valueX}%"
            : title === "CPC" ||
              title === "CPA" ||
              title === "CPCL" ||
              title === "Total Spends"
            ? "{categoryY} : ₹{valueX}"
            : "{categoryY} : {valueX}",
        tooltipY: am5.percent(10),
      });

      let tooltip = am5.Tooltip.new(root, {
        autoTextColor: false,
      });

      // Set the tooltip label's fill color
      tooltip.label.set("fill", am5.color(0xffffff));
      series0.set("tooltip", tooltip);

      // color gradint is chaging from here only 😄😄

      // series0.columns.template.adapters.add("fill", function (fill, target) {
      //   return chart.get("colors")?.getIndex(series0.columns.indexOf(target));
      // });

      // series0.columns.template.adapters.add(
      //   "stroke",
      //   function (stroke, target) {
      //     return chart.get("colors")?.getIndex(series0.columns.indexOf(target));
      //   }
      // );

      const createColorObject = (hexCode) => {
        return am5.color(hexCode);
      };

      // Map hardcoded color codes to am5.Color objects
      const colors = hardcodedColors.map((color) => createColorObject(color));

      // Use the colors directly in your chart
      series0.columns.template.adapters.add("fill", function (fill, target) {
        return colors[series0.columns.indexOf(target) % colors.length];
      });

      // Remove the border color by setting the stroke color to transparent or zero opacity
      series0.columns.template.set("stroke", am5.color(0xffffff));

      if (data && data.length > 0) {
        let sortedData = data.sort((a, b) => a[valueXField] - b[valueXField]);
        series0.data.setAll(sortedData);
      }
      // yAxis.get("renderer").grid.template.set("forceHidden", true);
      // yAxis.get("renderer").labels.template.set('visible', false);

      // let legend = chart.children.push(am5.Legend.new(root, {
      // 	centerX: am5.p0,
      // 	x: am5.p50,

      //   }));

      //   legend.data.setAll(chart.series.values);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      series0.appear();
    }

    return () => {
      root.dispose();
    };
  }, [chartID, isZoomModalOpen, hardcodedColors]);

  return (
    <>
      {!isZoomModalOpen ? (
        <div id={chartID} style={{ height: "200px", width: "100%" }}></div>
      ) : (
        <Modal
          isOpen={isZoomModalOpen}
          onClose={() => setIsZoomModalOpen(!isZoomModalOpen)}
          title={title}
        >
          <div
            id={chartID}
            style={{ height: "400px", width: "100%", margin: "20px" }}
          ></div>
        </Modal>
      )}
      {/* <div
        id={chartID}
        style={{ height: height, width: "100%" }}
        className="mb-0"
      /> */}
    </>
  );
};

export default HorizontalBarGraph;
