/**
 * /* eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

import { Fragment, useEffect, useState } from "react";
import { KTCard, KTSVG } from "../../../../theme/helpers";
import { getCampaignHighlights } from "../../../modules/application/_requests";
import { useQuery } from "react-query";
import { useParams, useLocation } from "react-router-dom";
import { IoSparkles } from "react-icons/io5";
import { BsTag } from "react-icons/bs";
import CampaignType from "./CampaignType";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { IoMdLink } from "react-icons/io";
import CheckBox from "./CheckBox";
import CustomQuestion from "./customQuestion";



const BuisnessWesite = ({ inputBusinessValue, setInputBusinessValue }) => {

  const [showCampaignType, setShowCampaignType] = useState(false);


  const handleClick = () => {
    setShowCampaignType(true);
  };

  return (
    <>




      <div className="container px-10 mt-10 pt-7 pb-10" style={{ backgroundColor: "white", boxShadow: '0 2px 8px rgba(0, 0, 0, 0.5)', borderRadius: '10px' }}>

        {/* <div className="row gy-5 gx-xl-10 mb-10"> */}


        <div className="mb-3 d-flex align-items-center mx-5">
          <div className="me-3"
            style={{ color: '#5f6368', fontSize: '14px', letterSpacing: '0.2px' }}>
            This is the webpage people will go after clicking ads
          </div>
          <CustomQuestion>
            This step helps customize your campaign setup to focus
            on settings and features that can help you get the customer actions
            that matter most to your business.
          </CustomQuestion>


        </div>

        <div className=" px-5" style={{ position: 'relative' }}>
          <input
            placeholder="Your business website"
            style={{
              padding: '10px',
              paddingLeft: '55px',
              width: '360px',
              height: '50px',
              borderRadius: '5px',
              fontSize: '13px',
              border: '1px solid #80868B', // Set initial border to transparent
              borderColor: '#80868B', // Set initial border color
              borderWidth: '1px', // Set initial border width
              color: '#80868b',
              outline: 'none', // Remove default outline style
              transition: 'border-color 0.3s ease-in-out', // Add transition effect
            }}
            onChange={(e) => setInputBusinessValue(e.target.value)}
            onFocus={(e) => {
              e.target.style.borderColor = '#0d6efd'; // Change border color on focus
              e.target.style.borderWidth = '3px'; // Change border width on focus
            }}
            onBlur={(e) => {
              e.target.style.borderColor = '#80868B'; // Reset border color on blur
              e.target.style.borderWidth = '1px'; // Reset border width on blur
            }}
          />

          <div
            className="px-4"
            style={{
              position: 'absolute',
              top: '50%',
              left: '20px',
              transform: 'translateY(-50%)',
              color: '#888888',
              pointerEvents: 'none', // Ignore pointer events to allow clicking the input
            }}
          >
            <IoMdLink size={25} />
          </div>
        </div>


      </div>

      {/* </div> */}


      {/* <div className="">
        {!showCampaignType && (
          <div className="gap-2" style={{ position: "absolute", right: 30 }}>
            <a
              href="#"
              className="btn btn-sm fw-bold btn-color-gray-700 btn-active-color-primary"
            >
              Cancel
            </a>
            <a
              href="#"
              className="btn btn-sm fw-bold btn-primary"
              onClick={handleClick}
            >
              Continue
            </a>
          </div>
        )}
        {showCampaignType && <CampaignType />}
      </div> */}
    </>
  );
};
export default BuisnessWesite;
