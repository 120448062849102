/** @format */

import React, { useEffect, useRef, useState } from "react";
import { useThemeMode } from "../../../../theme/partials";
import { IoMdArrowDropdown } from "react-icons/io";
import {
  getDataFromBackend,
  URLS,
} from "../../../modules/dashboards/DashboardRequests";
import CustomDropdown from "./CustomDropdown";
import { primaryColors } from "../../../../theme/assets/ts/_utils/AppColors";

type Props = {
  productId: string;
  startDate: string;
  endDate: string;
  platformId: number;
  productType: string;
};

const NetworkingGraph: React.FC<Props> = ({
  productId,
  startDate,
  endDate,
  platformId,
  productType
}) => {
  const location = window.location.pathname.split("/").pop();

  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const [responOfNetworkGraph, setResponOfNetworkGraph] = useState<any>([
    {
      name: "Clicks",
      data: [
        {
          networkName: "Google Search",
          value: 20,
        },
        {
          networkName: "Display Network",
          value: 40,
        },
        {
          networkName: "Cross Network",
          value: 15,
        },
        {
          networkName: "Youtube",
          value: 25,
        },
      ],
    },
  ]);
  interface DataEntryMock {
    data: {
      name: any;
      data: Array<{ networkName: string; value: number }>;
    }[];
    dataFor: string;
  }

  const [selectedItem, setSelectedItem] = useState("All");
  const [mockData, setMockData] = useState<DataEntryMock[]>([]);
  const [dropdownData, setDropdownData] = useState<string[]>([]);
  // const [legends, setLegends] = useState<any>([]);
  // const [rep, setRep] = useState<any>([]);
  // const [averages, setAverages] = useState<any>([]);

  const getData = async () => {
    let requestData = {
      productId,
      platformId,
      startDate,
      endDate,
      productType
    };
    let performanceByNetwork = await getDataFromBackend(
      requestData,
      URLS.GET_PLATFORM_PERFORMANCEBYNETWORK
    );
    console.log("network graph response", performanceByNetwork);
    if (performanceByNetwork?.data) {
      setMockData(performanceByNetwork?.data);
    } else {
      return;
    }
  };

  useEffect(() => {
    getData();
  }, [productId, platformId, startDate, endDate, productType]);

  const transformedData = responOfNetworkGraph?.map((category) => ({
    type: category.name,
    data: category?.data?.map((entry) =>
      parseFloat(
        (
          (entry.value / category.data.reduce((sum, e) => sum + e.value, 0)) *
          100
        ).toFixed(2)
      )
    ),
  }));

  const selectedCategories = ["Clicks", "Cost"];

  const rep = transformedData?.filter((category) =>
    selectedCategories.includes(category.type)
  );

  const legends = responOfNetworkGraph[0]?.data?.map(
    (entry) => entry.networkName
  );

  const barColors = ["#E84A42", primaryColors.black, "rgb(7,74,134)", "#ebe7e7"];

  const averages = rep[0].data.map((_, index) => {
    const sum = rep?.reduce((total, item) => total + item.data[index], 0);
    return sum / rep?.length;
  });

  console.log("all dta in mount", transformedData, rep, legends, averages);

  const handleDropdownItemClick = (data: string) => {
    setSelectedItem(data);
  };

  useEffect(() => {
    if (mockData?.length > 0) {
      const mockDropdown = Array.from(
        new Set(mockData?.map((item) => item.dataFor))
      );
      setDropdownData(mockDropdown);

      const selectedData = mockData?.filter(
        (item) => item.dataFor === selectedItem
      );

      const newData = selectedData[0]?.data.map((item) => ({
        ...item,
      }));
      setResponOfNetworkGraph(newData);
    }
  }, [mockData, selectedItem]);

  useEffect(() => {
    if (responOfNetworkGraph?.length > 0) {
      const selectedCategories = ["Clicks", "Cost"];

      const transformedData = responOfNetworkGraph?.map((category) => ({
        type: category.name,
        data: category?.data?.map((entry) =>
          parseFloat(
            (
              (entry.value /
                category.data.reduce((sum, e) => sum + e.value, 0)) *
              100
            ).toFixed(2)
          )
        ),
      }));

      const myRep = transformedData?.filter((category) =>
        selectedCategories.includes(category.type)
      );
      // setRep(myRep);
      const myLegends = responOfNetworkGraph[0]?.data?.map(
        (entry) => entry.networkName
      );
      // setLegends(myLegends);

      const myAverages = rep[0].data.map((_, index) => {
        const sum = rep?.reduce((total, item) => total + item.data[index], 0);
        return sum / rep?.length;
      });
      // setAverages(myAverages);

      console.log(
        "all dta in eceEffect",
        transformedData,
        myRep,
        myLegends,
        myAverages
      );
    }
  }, [responOfNetworkGraph]);

  return (
    <>
      <div
        className="card card-flush h-lg-100 py-5 px-7 pe-5"
        data-bs-theme="light"
        style={{
          backgroundColor: "white",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="card-title d-flex justify-content-between align-items-center">
          <div className="card-label fw-bold text-dark fs-4">
            Summary of how your ads are performing on these networks
          </div>
          <div>
            <CustomDropdown
              dropdownData={dropdownData}
              selectedItem={selectedItem}
              onItemClick={handleDropdownItemClick}
            />
          </div>
        </div>

        <div className="pt-12">
          <tbody>
            <div className="legends mt-4 d-flex justify-content-between">
              {legends?.map((legend, index) => (
                <div
                  key={index}
                  className="legend-item d-flex"
                  style={{
                    // display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <span
                    className="legend-color"
                    style={{
                      minWidth: "20px",
                      height: "20px",
                      marginRight: "5px",
                      backgroundColor: barColors[index % barColors.length],
                    }}
                  ></span>
                  <span
                    className="legend-label"
                    style={{ fontSize: "14px", marginRight: "3px" }}
                  >
                    {legend}
                  </span>
                </div>
              ))}
            </div>

            {rep?.map((item, index) => {
              return (
                <div className="row gx-xl-12" key={index}>
                  <div className="col-xl-10 d-flex align-items-center">
                    <div className="d-flex justify-content-start mt-5 mb-5 align-items-center">
                      {item?.data?.map((value, valueIndex) => (
                        <div
                          key={valueIndex}
                          className="bar-with-tooltip"
                          style={{
                            position: "relative",
                            height: "20px",
                            backgroundColor:
                              barColors[valueIndex % barColors.length],
                            width: value * 3.5, // Adjust the width as needed
                            marginRight: "2px", // Add some spacing between bars
                            cursor: "pointer",
                          }}
                          onMouseEnter={() => {
                            const tooltip = document.getElementById(
                              `tooltip-${index}-${valueIndex}`
                            );
                            if (tooltip) {
                              tooltip.style.visibility = "visible";
                              tooltip.style.opacity = "1";
                            }
                          }}
                          onMouseLeave={() => {
                            const tooltip = document.getElementById(
                              `tooltip-${index}-${valueIndex}`
                            );
                            if (tooltip) {
                              tooltip.style.visibility = "hidden";
                              tooltip.style.opacity = "0";
                            }
                          }}
                        >
                          <span
                            id={`tooltip-${index}-${valueIndex}`}
                            className="tooltip"
                            style={{
                              position: "absolute",
                              bottom: "25px", // Adjust the distance from the bar (changed to 'bottom')
                              left: "50%",
                              transform: "translateX(-50%)",
                              backgroundColor: "rgba(0, 0, 0, 0.7)",
                              color: "white",
                              padding: "4px 8px",
                              fontSize: "12px",
                              borderRadius: "4px",
                              whiteSpace: "nowrap",
                              visibility: "hidden",
                              opacity: 0,
                              transition: "opacity 0.2s ease-in-out",
                            }}
                          >
                            {legends[valueIndex]}: {value?.toFixed(2)}
                          </span>
                        </div>
                      ))}

                      {/* {item?.value} */}
                    </div>
                  </div>
                  <div className="col-xl-2 d-flex align-items-center justify-content-start">
                    <div
                      className="button d-flex align-items-center px-2"
                      style={{ border: "1px solid #CFD0D0" }}
                    >
                      {item?.type}
                      {/* <IoMdArrowDropdown size={15} style={{ marginLeft: '5px' }} /> */}
                    </div>
                  </div>

                  <div className="col-xl-9">
                    <div className="d-flex align-items-center justify-content-between px-10 pe-20">
                      {item?.data?.map((value, valueIndex) => {
                        console.log("eeeee", value);
                        return (
                          <div
                            className="d-flex align-items-center"
                            style={{
                              backgroundColor:
                                barColors[valueIndex % barColors.length],
                              height: "10px",
                              width: "10px",
                            }}
                          >
                            <div className="d-flex px-5 fs-6">
                              {value?.toFixed(2)}%
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="row gx-xl-12 mb-5">
              <div className="col-xl-10 d-flex align-items-center">
                <div className="d-flex justify-content-start mt-5 mb-5 align-items-center">
                  {averages.map((item, valueIndex) => (
                    <div
                      key={valueIndex}
                      className="bar-with-tooltip"
                      style={{
                        position: "relative",
                        height: "20px",
                        backgroundColor:
                          barColors[valueIndex % barColors.length],
                        width: `${item * 3.5}px`, // Adjust the width as needed
                        marginRight: "2px", // Add some spacing between bars
                        cursor: "pointer",
                      }}
                      onMouseEnter={() => {
                        const tooltip = document.getElementById(
                          `tooltip-${item.toFixed(2)}-${valueIndex}`
                        );
                        if (tooltip) {
                          tooltip.style.visibility = "visible";
                          tooltip.style.opacity = "1";
                        }
                      }}
                      onMouseLeave={() => {
                        const tooltip = document.getElementById(
                          `tooltip-${item.toFixed(2)}-${valueIndex}`
                        );
                        if (tooltip) {
                          tooltip.style.visibility = "hidden";
                          tooltip.style.opacity = "0";
                        }
                      }}
                    >
                      <span
                        id={`tooltip-${item.toFixed(2)}-${valueIndex}`}
                        className="tooltip"
                        style={{
                          position: "absolute",
                          bottom: "25px", // Adjust the distance from the bar (changed to 'bottom')
                          left: "50%",
                          transform: "translateX(-50%)",
                          backgroundColor: "rgba(0, 0, 0, 0.7)",
                          color: "white",
                          padding: "4px 8px",
                          fontSize: "12px",
                          borderRadius: "4px",
                          whiteSpace: "nowrap",
                          visibility: "hidden",
                          opacity: 0,
                          transition: "opacity 0.2s ease-in-out",
                        }}
                      >
                        {legends[valueIndex]}: {(item * 10).toFixed(2)}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-xl-2 d-flex align-items-center">
                <div
                  className="button d-flex align-items-center justify-content-between px-2"
                  style={{ border: "1px solid #CFD0D0" }}
                >
                  Avg.CPC
                  {/* <IoMdArrowDropdown size={15} style={{ marginLeft: '5px' }} /> */}
                </div>
              </div>

              <div className="col-xl-9">
                <div className="d-flex align-items-center justify-content-between px-10 pe-20">
                  {averages.map((value, valueIndex) => (
                    <>
                      <div
                        className="d-flex align-items-center"
                        style={{
                          backgroundColor:
                            barColors[valueIndex % barColors.length],
                          height: "10px",
                          width: "10px",
                        }}
                      >
                        <div className="d-flex px-5 fs-6">
                          {value?.toFixed(2)}%
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>

            {/* <div
              className="row gx-xl-12 gy-5 mt-10"
            >
              <div className="col-xl-10 d-flex align-items-center justify-content-between ps-10 pe-20">

                {averages.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center p-1"
                    style={{ backgroundColor: "#E84A42", height: "20px", width: "20px" }}
                  >
                    <div className="d-flex p-5 fs-4 mx-1">
                      <div>{`₹${item.toFixed(2)}`}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-xl-2">

                <div className="button d-flex align-items-center"
                  style={{ border: "1px solid #CFD0D0", }} >Avg CPC<IoMdArrowDropdown size={15} style={{ marginLeft: '5px' }} /></div>
              </div>
            </div> */}
          </tbody>
        </div>
      </div>
    </>
  );
};

export default NetworkingGraph;
