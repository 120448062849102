/** @format */

import { useMemo } from "react";
import { useTable, ColumnInstance, Row } from "react-table";
import { CustomHeaderColumn } from "../table/columns/CustomHeaderColumn";
import { CustomRow } from "../table/columns/CustomRow";
import { User } from "../core/_models";
import { UsersListLoading } from "../components/loading/UsersListLoading";
import { KTCardBody } from "../../../../../../theme/helpers";
import PaginatedItems from "./columns/UserPagination";
import { useListView } from "../core/ListViewProvider";
import Loader from "../../../../../../assets/loading.gif";
type Props = {
	users: any[];
	isLoading: any;
};

const UsersTable = (props: any) => {
	const data = useMemo(() => props.users, [props.users]);
	const columns = useMemo(() => props.columns, [props.columns, props.data]);
	const isLoading = props.isLoading;
	const isFetching = props.isFetching;
	const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
		useTable({
			columns,
			data,
		});

	let { show } = useListView();
	return (
		<KTCardBody className="py-4">
			{/* {isLoading && isFetching ? ('Fetching data') : ( */}
			<div className="table-responsive" style={{ width: "100%" }}>
				<table
					id="kt_table_users"
					className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
					{...getTableProps()}
				>
					<thead>
						<tr
							className="text-start fw-bold gs-0 text-muted text-uppercase"
							style={{ fontSize: "14px" }}
						>
							{headers.map((column: ColumnInstance<User>) => (
								<CustomHeaderColumn key={column.id} column={column} />
							))}
						</tr>
					</thead>
					<tbody
						className="text-black-600"
						style={{ fontSize: "13px" }}
						{...getTableBodyProps()}
					>
						{isFetching ? (
							<tr>
								<td colSpan={7}>
									<div className="d-flex text-center w-100 align-content-center justify-content-center">
										<span className="me-2">Fetching data</span>
										<img src={Loader} height="20px" alt="logo" />
									</div>
								</td>
							</tr>
						) : rows.length > 0 ? (
							rows.map((row: Row<User>, i) => {
								prepareRow(row);
								return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
							})
						) : (
							<tr>
								<td colSpan={7}>
									<div className="d-flex text-center w-100 align-content-center justify-content-center">
										{show == true && "No matching records found"}
										{isFetching == false &&
											isLoading == false &&
											show == false &&
											"No records found"}
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			{/* )} */}

			{/* <div className="d-flex justify-content-end mt-5">
				{rows.length > 0 ? (
					<PaginatedItems
						pagination={props.pagination}
						onNextPage={props.onNextPage}
					/>
				) : null}
			</div> */}
			{/* {props.isLoading && <UsersListLoading />} */}
		</KTCardBody>
	);
};

export { UsersTable };
