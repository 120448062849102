import React, { useEffect, useState } from "react";
import {
  URLS,
  getDataFromBackend,
} from "../../../modules/dashboards/DashboardRequests";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { ChartTheme } from "../../../../theme/assets/ts/_utils/ChartTheme";

type DataEntry = {
  age: string;
  cardData: card[];
};

type card = {
  cardName: any;
  mValue: number;
  fValue: number;
  unknown: any;
};

type Props = {
  data: card[];
  productId: string;
  startDate: string;
  endDate: string;
  platformId: string;
  chartID: string;
  title: string;
};

const GenderCardTypes: React.FC<Props> = ({
  data,
  productId,
  startDate,
  endDate,
  chartID,
  title,
}: Props) => {
  let [genderData, setGenderData] = useState<DataEntry[]>([]);

  let root;
  // Data
  const showGraph = (graphData, maleValue, femaleValue) => {
    console.log("Show Graph execution start");
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        // wheelX: "panX",
        // wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );
    // Use only absolute numbers
    chart.getNumberFormatter().set("numberFormat", "#.#s");
    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );
    legend.data.setAll(chart.series.values);
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "cardName",
        renderer: am5xy.AxisRendererY.new(root, {
          inversed: true,
          cellStartLocation: 0.1,
          cellEndLocation: 0.9,
          minGridDistance: 10,
        }),
      })
    );
    if (graphData && graphData.length > 0) {
      let sortedData = data;
      yAxis.data.setAll(sortedData);
    } else {
      yAxis.data.setAll(graphData);
    }

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 0.1,
        }),
        // min: 0,  // Set minimum value
        // max: 5000, // Set maximum value
      })
    );
    createSeries(
      root,
      chart,
      xAxis,
      yAxis,
      maleValue,
      am5.p100,
      "right",
      1,
      graphData
    );
    createSeries(
      root,
      chart,
      xAxis,
      yAxis,
      femaleValue,
      0,
      "left",
      1,
      graphData
    );
    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
      })
    );
    chart.appear(1000, 100);
    // return () => {
    //     root?.dispose();
    // };
  };

  useEffect(() => {
    root = am5.Root.new(chartID);
    root._logo?.dispose();
    root.setThemes([ChartTheme.getTheme(root)]);
    showGraph(data, "mValue", "fValue");

    return () => {
      root?.dispose();
    };
  }, [chartID, productId, startDate, endDate, data]);

  const createSeries = (
    root,
    chart,
    xAxis,
    yAxis,
    field,
    labelCenterX,
    pointerOrientation,
    rangeValue,
    graphData
  ) => {
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: field,
        categoryYField: "cardName",
        sequencedInterpolation: true,
        clustered: false,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: pointerOrientation,
          labelText:
            field === "mValue"
              ? "Male " + ": {valueX}"
              : "Female " + ": {valueX}",
        }),
      })
    );

    series.columns.template.setAll({
      height: am5.p100,
      strokeOpacity: 30,
      fillOpacity: 30,
    });

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationX: 2,
        locationY: 0.5,
        sprite: am5.Label.new(root, {
          centerY: am5.p50,
          text: "{valueX}",
          populateText: true,
          centerX: labelCenterX,
        }),
      });
    });
    if (graphData && graphData.length > 0) {
      let sortedData = data;
      series.data.setAll(sortedData);
    } else {
      series.data.setAll(graphData);
    }

    series.appear();

    let rangeDataItem = xAxis.makeDataItem({
      value: rangeValue,
    });
    xAxis.createAxisRange(rangeDataItem);

    let label = rangeDataItem.get("label");
    let displayValue = field === "mValue" ? "Male" : "Female";
    label.setAll({
      text: displayValue,
      fontSize: "1.1em",
      fill: series.get("stroke"),
      paddingTop: 10,
      isMeasured: true,
      centerX: labelCenterX,
    });
    return series;
  };

  return (
    <div className="align-items-center">
      <h3 className="d-flex text-dark fs-6 fw-bold justify-content-center mt-0">
        {title}
      </h3>
      <div className="">
        <div
          id={chartID}
          style={{ height: "125.5px", width: "100%", marginTop: "30px" }}
        ></div>
      </div>
    </div>
  );
};

export default GenderCardTypes;
