import React, { useEffect, useState } from "react";
import { KTCard } from "../../../../theme/helpers";
import { IoTriangleSharp } from "react-icons/io5";
import { ImArrowDown, ImArrowUp } from "react-icons/im";
// import TrendsGraph from "../components/TrendsGraph";
import { useQuery } from "react-query";
import { getColumnData } from "../../../modules/application/_requests";
import { AiFillEye, AiFillStar } from "react-icons/ai";
import {
  FaCommentDollar,
  FaFileInvoiceDollar,
  FaHandshake,
  FaSearchDollar,
} from "react-icons/fa";
import HorizontalBarGraph from "../components/HorizontalBarGraph";
import {
  URLS,
  getDataFromBackend,
} from "../../../modules/dashboards/DashboardRequests";
import { FormatUtil } from "../../../../theme/assets/ts/_utils/FormatUtil";
import CustomDropdown from "./CustomDropdown";

type DataItem = {
  name: string;
  value: any;
};
type CardDataItem = {
  name: any;
  value: any;
};

type DataEntry = {
  data: {
    deviceName: string;
    data: DataItem[];
    cardData: CardDataItem[];
  }[];
  dataFor: string;
};

type DataEntryFiltered = {
  deviceName: string;
  data: DataItem[];
  cardData: CardDataItem[];
};

type Props = {
  productId: string;
  platformId: string;
  startDate: string;
  endDate: string;
  channelType: string;
  productType: string;
  subUrl: string;
};

const DeviceCard: React.FC<Props> = ({
  productId,
  platformId,
  startDate,
  endDate,
  channelType,
  productType,
  subUrl
}: Props) => {
  const mockDataBeforeLoading = [
    {
      data: [
        {
          deviceName: "Desktop",
          data: [
            {
              name: "Clicks",
              value: 0,
            },
            {
              name: "Impression",
              value: 0,
            },
            {
              name: "CPM",
              value: 0,
            },
            {
              name: "Cost",
              value: 0.28,
            },
            {
              name: "CTR",
              value: 0.69,
            },
          ],
          cardData: [
            {
              name: "Freedom",
              value: 0,
            },
            {
              name: "Premium",
              value: 0,
            },
            {
              name: "hpcl-super-saver",
              value: 0,
            },
            {
              name: "Regular",
              value: 0,
            },
          ],
        }
      ],
      dataFor: "All",
    },
  ];
  const [actualData, setActualData] = useState<DataEntry[]>(
    mockDataBeforeLoading
  );
  const [filteredData, setFilteredData] = useState<DataEntryFiltered[]>([]);
  const [dropdownData, setDropdownData] = useState<string[]>(["All", "Other"]);
  const [selectedItem, setSelectedItem] = useState<any>({
    "0": "All",
    "1": "All",
    "2": "All",
  });
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleDropdownItemClick = (data, index) => {
    setSelectedIndex(index);
    setSelectedItem((prevSelectedItems) => ({
      ...prevSelectedItems,
      [index]: data,
    }));
  };

  useEffect(() => {
    console.log(
      "selected data",
      selectedItem,
      selectedItem[selectedIndex],
      actualData[selectedIndex]
    );
    if (actualData?.length > 0) {
      const mockDropdown = Array.from(
        new Set(actualData?.map((item) => item.dataFor))
      );
      setDropdownData(mockDropdown);

      const selectedData = actualData?.filter(
        (item) => item.dataFor === selectedItem[selectedIndex]
      );
      if (selectedData[0]?.data[selectedIndex]?.data) {
        const newData = selectedData[0]?.data.map((item, index) => ({
          ...item,
        }));

        setFilteredData(newData);
      }
      console.log("selectedDataselectedData", selectedData, selectedIndex);
    }
  }, [selectedItem, actualData, selectedIndex]);

  useEffect(() => {
    const getData = async () => {
      let requestData = {
        productId,
        platformId,
        startDate,
        endDate,
        productType
      };
      let response = await getDataFromBackend(
        requestData,
        URLS.BASE_URL + subUrl
      );
      console.log("GET_PLATFORM_DEVICESTATS", response);

      if (response?.data) {
        setActualData(response?.data);
      } else {
        return;
      }
    };
    getData();
    return () => { };
  }, [platformId, productId, startDate, endDate, productType]);

  return (
    <div className="row gy-10 gx-xl-10 d-flex">
      {filteredData?.map((entry, index) => {
        return (
          <div key={index} className="col-xl-12">
            <div
              className="card card-flush h-lg-100 p-5"
              style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
            >
              {/* <div className="mb-5">
                                <div className="fw-semibold d-flex align-items-center justify-content-center" style={{ fontSize: '30px' }}>
                                    {entry.deviceName} Statistics
                                </div>
                            </div> */}
              <div className="card-title d-flex justify-content-between align-items-center">
                <div
                  className="fw-semibold d-flex align-self-center"
                  style={{ fontSize: "30px" }}
                >
                  {entry.deviceName} Statistics
                </div>
                <div>
                  <CustomDropdown
                    dropdownData={dropdownData}
                    selectedItem={selectedItem[index] || "All"}
                    onItemClick={(data) => handleDropdownItemClick(data, index)}
                  />
                </div>
              </div>
              <div className="row gy-10 gx-xl-12 d-flex align-items-center justify-content-between mb-10 px-10">
                {entry?.data?.slice(0, 4)?.map((dataItem, index) => {
                  return (
                    <div key={`dataItem${dataItem.name}`} className="col-xl-3">
                      <div className="Boxtext">
                        <div
                          className="fw-semibold d-flex justify-content-center"
                          style={{ color: "#5D5D5D", fontSize: "20px" }}
                        >
                          {dataItem.name}
                        </div>
                        <div
                          className="fw-semibold d-flex justify-content-center"
                          style={{ fontSize: "25px" }}
                        >
                          {" "}
                          {dataItem.name === "Cost" || dataItem.name === "CPM"
                            ? "₹"
                            : ""}
                          {Math.round(Number(dataItem.value)).toLocaleString(
                            "en-IN"
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="row gy-10 gx-xl-12 d-flex align-items-center mb-1">
                <div className="col-xl-6">
                  <div className="BarGraphs pb-4 px-5">
                    <HorizontalBarGraph
                      chartID={`hor${entry?.deviceName}${subUrl}`}
                      height="135px"
                      data={entry.cardData}
                      valueXField="value"
                      valueYField="name"
                      isZoomModalOpen={false}
                      setIsZoomModalOpen={""}
                      title=""
                      channelType={channelType}
                    />
                  </div>
                </div>
                <div
                  className="col-xl-6 d-flex align-items-center justify-content-center"
                  style={{ borderLeft: "1px solid #CFD0D0" }}
                >
                  <div className="Boxtext" style={{ paddingLeft: "20px" }}>
                    <div
                      className="fw-semibold d-flex justify-content-center"
                      style={{ color: "#5D5D5D", fontSize: "20px" }}
                    >
                      {entry.data[entry.data.length - 1].name}
                    </div>
                    <div
                      className="fw-semibold d-flex justify-content-center"
                      style={{ fontSize: "25px" }}
                    >
                      {entry.data[entry.data.length - 1].value} %
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DeviceCard;
