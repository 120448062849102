/** @format */

import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import {
  getDataFromBackend,
  URLS,
} from "../../../modules/dashboards/DashboardRequests";
import CustomLoader from "../marketting_component/CustomLoader";
import { ChartTheme } from "../../../../theme/assets/ts/_utils/ChartTheme";

type BubbleData = {
  deviceName: string;
  data: {
    name: string;
    value: number;
  }[];
};

function AmBubbleChart({
  chartID,
  platformId,
  productId,
  startDate,
  endDate,
  channelType,
  campaignId,
}) {
  const [bubbleData, setBubbleData] = useState<BubbleData[]>([]);
  const [selectedItem, setSelectedItem] = useState("Impression");
  const [mergedData, setMergedData] = useState({});

  const dropdownData = ["Impression", "Clicks", "CPM", "Cost"];

  const handleDropdownItemClick = (data) => {
    console.log(data);
    setSelectedItem(data);
  };

  useEffect(() => {
    const getData = async () => {
      let requestData = {
        productId,
        platformId,
        startDate,
        endDate,
        campaignId,
      };
      let response = await getDataFromBackend(
        requestData,
        URLS.GET_CAMPAIGN_DEVICETYPE
      );
      console.log("Response GET_CAMPAIGN_DEVICETYPE: ", response?.data);
      // setBubbleData(response?.data);
      if (response?.data) {
        setBubbleData(response?.data);
      } else {
        return;
      }
    };
    getData();
    return () => {};
  }, [platformId, productId, campaignId, startDate, endDate]);

  useEffect(() => {
    const filteredData = bubbleData?.map((item) => {
      const { deviceName, data } = item;

      const metricsData = data.filter((metric) =>
        selectedItem.includes(metric.name)
      );
      const metricValues = metricsData.reduce((accumulator, metric) => {
        accumulator[metric.name] = metric.value;
        return accumulator;
      }, {});

      return { deviceName, ...metricValues };
    });

    const categoryValues = filteredData.map((item) => ({
      name: item.deviceName,
      value: item[selectedItem],
    }));

    const formattedData = {
      children: categoryValues,
    };
    console.log("aaaaaaaaa", formattedData);
    setMergedData(formattedData);
  }, [selectedItem, bubbleData]);

  useLayoutEffect(() => {
    let root = am5.Root.new(chartID);
    root._logo?.dispose();

    root.setThemes([ChartTheme.getBubbleTheme(root)]);
    // root.setThemes([am5themes_Animated.new(root)]);

    let data = mergedData;

    let container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
        interactive: true,
      })
    );

    let series = container.children.push(
      am5hierarchy.ForceDirected.new(root, {
        singleBranchOnly: false,
        downDepth: 2,
        topDepth: 1,
        initialDepth: 1,
        maxRadius: 80,
        minRadius: 50,
        valueField: "value",
        categoryField: "name",
        childDataField: "children",
        manyBodyStrength: -13,
        centerStrength: 0.8,
      })
    );
    series.labels.template.setAll({
      fill: am5.color(0x000000),
      oversizedBehavior: "fit"
    });
    series.labels.template.adapters.add("forceHidden", function(forceHidden, target) {
      return false;
    });
    {
      selectedItem === "Cost"
        ? series.labels.template.set("text", "[#ffffff]{name}: [bold]₹ {value}")
        : selectedItem === "CTR"
        ? series.labels.template.set("text", "[#ffffff]{name}: [bold]{value}%")
        : series.labels.template.set("text", "[#ffffff]{name}: [bold]{value}");
    }

    let selectedDataItem;

    series.nodes.template.events.on("click", function (e) {
      if (selectedDataItem) {
        let targetDataItem = e.target.dataItem;
        if (e.target.dataItem == selectedDataItem) {
          selectedDataItem.get("outerCircle").setPrivate("visible", false);
          selectedDataItem = undefined;
        }
      } else {
        selectedDataItem = e.target.dataItem;
        selectedDataItem.get("outerCircle").setPrivate("visible", true);
      }
    });

    series.data.setAll([data]);
    series.set("selectedDataItem", series.dataItems[0]);

    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartID, mergedData]);

  return (
    <div
      className="card card-flush h-lg-100 mb-10"
      style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
    >
      <div className="d-flex justify-content-between pe-3">
        <div className="card-header">
          <div className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-dark fs-6">
              Device Types
            </span>
          </div>
        </div>
        <div className="d-flex align-items-center fw-bold flex-end">
          <div className="dropdown">
            <div
              data-kt-daterangepicker="true"
              data-kt-daterangepicker-opens="left"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary d-flex align-items-center px-4 dropdown-toggle"
            >
              {selectedItem || "Clicks"}
            </div>
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="dropdownMenuButton1"
            >
              {dropdownData.map((data) => (
                <li key={data} onClick={() => handleDropdownItemClick(data)}>
                  <span className="dropdown-item text-hover-primary">
                    {data}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {/* <div id={chartID} style={{ height: "380px", width: "100%" }}></div> */}
      {bubbleData?.length <= 0 ? (
        <div id={chartID} style={{ alignSelf: "center", marginBottom: "30px" }}>
          <CustomLoader />
        </div>
      ) : (
        <div id={chartID} style={{ height: "380px", width: "100%" }}></div>
      )}
    </div>
  );
}

export default AmBubbleChart;
