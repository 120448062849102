import React, { useState } from "react";

interface CommaSeparatedTextProps {
  text: string;
  limit: number;
}

const CommaSeparatedText: React.FC<CommaSeparatedTextProps> = ({ text, limit }) => {
  const [showAll, setShowAll] = useState(false);

  // Split the text into an array using commas
  const values = text.split(",");

  // Toggle between showing all values or a limited subset
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  // Render a limited number of items or the full list based on state
  const displayedValues = showAll ? values : values.slice(0, limit);

  return (
    <div>
      {/* Show the comma-separated values */}
      <p>{displayedValues.join(", ")}</p>

      {/* Show "Show more" or "Show less" button based on state */}
      {values.length > limit && (
        <button className={`btn btn-link btn-sm`} onClick={toggleShowAll}>
          {showAll ? "Show less" : `Show more (${values.length - limit} more)`}
        </button>
      )}
    </div>
  );
};

export default CommaSeparatedText;