import React from "react";
import ProductCard from "./ProductCard";

type Props = {
  productId: string;
  startDate: string;
  endDate: string;
  productType: string;
  channelType: string;
  subType: string;
};

const ProductCardComponent: React.FC<Props> = ({
  productId,
  startDate,
  endDate,
  productType,
  channelType,
  subType
}: Props) => {
    
  const generateProductCards = (num: number) => {
    const productCards: JSX.Element[] = [];
    for (let i = 0; i < num; i++) {
      productCards.push(
        <div key={i} className={`col-xl-4${i > 5 ? '' : ' mb-10'}`}>
          <ProductCard
            productId={productId}
            startDate={startDate}
            productType={productType}
            endDate={endDate}
            channelType={channelType}
            index={i.toString()}
            subType={subType}
          />
        </div>
      );
    }
    return productCards;
  };

  return (
    <div className="row d-flex mb-10">
      {generateProductCards(9)}
    </div>
  );
};

export default ProductCardComponent;
