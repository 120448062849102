/** @format */

/**
 * /* eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

import { FC, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { MenuComponent } from "../../../../../../../theme/assets/ts/components";
import { ID, KTSVG, QUERIES } from "../../../../../../../theme/helpers";
import { useListView } from "../../core/ListViewProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import { deleteUser } from "../../../../../application/_requests";
import { FaEye, FaEdit, FaRegTrashAlt, FaAlignJustify } from "react-icons/fa";

type Props = {
	cases?: string;
	id: ID;
	onDelete?: () => void;
	onEdit?: () => void;
};

const ActionCellUser: FC<Props> = ({ id, onEdit, cases }) => {
	const { setItemIdForUpdate } = useListView();
	const { setItemIdForDelete } = useListView();
	const { query } = useQueryResponse();
	const queryClient = useQueryClient();
	const location = window.location.pathname.split("/").pop();

	const navigate = useNavigate();
	useEffect(() => {
		MenuComponent.reinitialization();
	}, []);

	const openEditModal = () => {
		setItemIdForUpdate(id);
	};

	const onDelete = () => {
		setItemIdForDelete(id);
	};

	const onFormat = () => {
		navigate(`format-response/${id}`);
	};
	const onAssign = () => {
		navigate(`/user-management/users/document/${id.username}/assign`, {
			state: id.username,
		});
	};

	const onView = () => {
		navigate(`/source-management/source/view`, {
			state: {
				data: id.draftId || id.responseId,
			},
			// state: {

			// 	data: id.responseId,
			// },
		});
	};

	const deleteItem = useMutation(() => deleteUser(id), {
		// 💡 response of the mutation is passed to onSuccess
		onSuccess: () => {
			// ✅ update detail view directly
			queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`]);
		},
	});

	return (
		<>
			<div
				className="d-flex align-items-center mx-10 justify-content-center"
				// data-kt-menu-trigger="click"
				// data-kt-menu-placement="bottom-start"
			>
				{(cases === "response" || cases === "source") && (
					<FaEye
						className="svg-icon1"
						size={13}
						style={{ marginRight: 15, cursor: "pointer" }}
						title="View Document"
						onClick={onView}
					/>
				)}

				{cases === `view-detail/${location}` || cases === "response" ? (
					!(
						<FaEdit
							className="svg-icon1"
							size={13}
							style={{ marginRight: 15, cursor: "pointer" }}
							onClick={openEditModal}
							title="Edit"
						/>
					)
				) : (
					<FaEdit
						className="svg-icon1"
						size={13}
						style={{ marginRight: 15, cursor: "pointer" }}
						onClick={openEditModal}
						title="Edit"
					/>
				)}

				<FaRegTrashAlt
					className="svg-icon1"
					size={13}
					style={{ cursor: "pointer" }}
					title="Delete"
					onClick={onDelete}
				/>
			</div>
		</>
	);
};

export { ActionCellUser };

/* eslint-disable jsx-a11y/anchor-is-valid */
// import { FC, useEffect } from "react";
// import { useMutation, useQueryClient } from "react-query";
// import { MenuComponent } from "../../../../../../../theme/assets/ts/components";
// import { ID, KTSVG, QUERIES } from "../../../../../../../theme/helpers";
// import { useListView } from "../../core/ListViewProvider";
// import { useQueryResponse } from "../../core/QueryResponseProvider";
// import { deleteUser } from "../../core/_requests";

// type Props = {
// 	id: ID;
// };

// const ActionCellUser: FC<Props> = ({ id }) => {
// 	const { setItemIdForUpdate } = useListView();
// 	const { query } = useQueryResponse();
// 	const queryClient = useQueryClient();

// 	useEffect(() => {
// 		MenuComponent.reinitialization();
// 	}, []);

// 	const openEditModal = () => {
// 		setItemIdForUpdate(id);
// 	};

// 	const deleteItem = useMutation(() => deleteUser(id), {
// 		// 💡 response of the mutation is passed to onSuccess
// 		onSuccess: () => {
// 			// ✅ update detail view directly
// 			queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`]);
// 		},
// 	});

// 	return (
// 		<>
// 			<a
// 				href="#"
// 				className="btn btn-light btn-active-light-primary btn-sm"
// 				data-kt-menu-trigger="click"
// 				data-kt-menu-placement="bottom-end"
// 			>
// 				Actions
// 				<KTSVG
// 					path="/media/icons/duotune/arrows/arr072.svg"
// 					className="svg-icon-5 m-0"
// 				/>
// 			</a>
// 			{/* begin::Menu */}
// 			<div
// 				className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
// 				data-kt-menu="true"
// 			>
// 				{/* begin::Menu item */}
// 				<div className="menu-item px-3">
// 					<a className="menu-link px-3" onClick={openEditModal}>
// 						Edit
// 					</a>
// 				</div>
// 				{/* end::Menu item */}

// 				{/* begin::Menu item */}
// 				<div className="menu-item px-3">
// 					<a
// 						className="menu-link px-3"
// 						data-kt-users-table-filter="delete_row"
// 						onClick={async () => await deleteItem.mutateAsync()}
// 					>
// 						Delete
// 					</a>
// 				</div>
// 				{/* end::Menu item */}
// 			</div>
// 			{/* end::Menu */}
// 		</>
// 	);
// };

// export { ActionCellUser };
