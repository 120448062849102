import * as am5 from "@amcharts/amcharts5";
import { primaryColors, secondryColors } from "./AppColors";

export class ChartTheme {
  static ChartTheme(root: am5.Root): am5.Theme {
    throw new Error("Method not implemented.");
  }

  public static getTheme(root: any): am5.Theme {
    return this.getMyTheme(root, this.getColors());
  }

  public static getBubbleTheme(root: any): am5.Theme {
    return this.getMyTheme(root, this.getBubbleColors());
  }

  public static getGradientTheme(root: any): am5.Theme {
    return this.getMyTheme(root, this.getGradientColors());
  }

  public static getReverseGradientTheme(root: any): am5.Theme {
    return this.getMyTheme(root, this.getReverseGradientColors());
  }

  public static getBarGradientTheme(root: any): am5.Theme {
    return this.getMyTheme(root, this.getBarGradientColors());
  }

  public static getBarPerformanceTheme1(root: any): am5.Theme {
    return this.getMyTheme(root, this.getPerformanceColors1());
  }
  public static getBarPerformanceTheme2(root: any): am5.Theme {
    return this.getMyTheme(root, this.getPerformanceColors2());
  }
  public static getBarPerformanceTheme3(root: any): am5.Theme {
    return this.getMyTheme(root, this.getPerformanceColors3());
  }
  public static getBarPerformanceTheme4(root: any): am5.Theme {
    return this.getMyTheme(root, this.getPerformanceColors4());
  }
  public static getBarPerformanceTheme5(root: any): am5.Theme {
    return this.getMyTheme(root, this.getPerformanceColors5());
  }
  public static getBarPerformanceThemeOrange(root: any): am5.Theme {
    return this.getMyTheme(root, this.getPerformanceColorsOrange());
  }

  private static getMyTheme(root: any, colors: am5.Color[]): am5.Theme {
    let myTheme;
    if (root) {
      myTheme = am5.Theme.new(root);

      myTheme
        .rule("RoundedRectangle", ["hierarchy", "node", "shape", "depth1"])
        .setAll({
          strokeWidth: 2,
        });

      myTheme
        .rule("RoundedRectangle", ["hierarchy", "node", "shape", "depth2"])
        .setAll({
          fillOpacity: 0,
          strokeWidth: 1,
          strokeOpacity: 0.2,
        });

      myTheme.rule("Label", ["node", "depth1"]).setAll({
        forceHidden: true,
      });

      myTheme.rule("ColorSet").set("colors", colors);
    }
    return myTheme;
  }

  public static getColors(): am5.Color[] {
    let colors = [
      am5.color(primaryColors.red),
      am5.color(primaryColors.black),
      // am5.color(primaryColors.blue),
      // am5.color(primaryColors.grey),
    ];
    return colors;
  }

  public static getBubbleColors(): am5.Color[] {
    let colors = [
      am5.color(primaryColors.black),
      am5.color(primaryColors.blue),
      am5.color(primaryColors.red),
      // am5.color("#ebe7e7"),
    ];
    return colors;
  }

  public static getGradientColors(): am5.Color[] {
    let colors = [
      am5.color("#E84A42"),
      am5.color("#f5984d"),
      am5.color("#f8b279"),
      am5.color("#facba6"),
      am5.color("#fde5d2"),
      am5.color("#fef2e9"),
    ];
    return colors;
  }

  public static getReverseGradientColors(): am5.Color[] {
    let colors = [
      am5.color("#fef2e9"),
      am5.color("#fde5d2"),
      am5.color("#facba6"),
      am5.color("#f8b279"),
      am5.color("#f5984d"),
      am5.color("#E84A42"),
    ];
    return colors;
  }

  public static getBarGradientColors(): am5.Color[] {
    let colors = [
      am5.color(primaryColors.black),
      am5.color(primaryColors.blue),
      am5.color(primaryColors.red),
      am5.color(primaryColors.grey),
      am5.color(secondryColors.orange),
      am5.color(secondryColors.skyBlue),
      am5.color(secondryColors.pink),
      am5.color(secondryColors.purple),
    ];
    return colors;
  }

  public static getPerformanceColors1(): am5.Color[] {
    let colors = [am5.color("#97291e"), am5.color("#E84A42")];
    return colors;
  }

  public static getPerformanceColors2(): am5.Color[] {
    let colors = [am5.color("#a5a5a5"), am5.color("#053c6d")];
    return colors;
  }

  public static getPerformanceColors3(): am5.Color[] {
    let colors = [am5.color("#00c0f3"), am5.color("#f4858e")];
    return colors;
  }

  public static getPerformanceColors4(): am5.Color[] {
    let colors = [am5.color("#d1cfbb"), am5.color("#fdb92a")];
    return colors;
  }

  public static getPerformanceColors5(): am5.Color[] {
    let colors = [
      am5.color("#a9d18e"),
      am5.color("#ffd966"),
      am5.color("#917bb9"),
    ];
    return colors;
  }

  public static getPerformanceColorsOrange(): am5.Color[] {
    let colors = [am5.color("#E84A42")];
    return colors;
  }
}
