import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { ChartTheme } from "../../../../theme/assets/ts/_utils/ChartTheme";
import {
  getDataFromBackend,
  URLS,
} from "../../../modules/dashboards/DashboardRequests";
import CustomDropdown from "../marketting_component/CustomDropdown";
import { AiOutlineZoomIn } from "react-icons/ai";
import Modal from "../marketting_component/Modal";
import CustomLoader from "../marketting_component/CustomLoader";

type DataType = {
  data: { date: any; value: any }[];
  dataFor: string;
};

type DataTypeResponse = {
  date: any;
  value: any;
};

function SpendLineGraph({
  chartID,
  title,
  productId,
  startDate,
  endDate,
  productType,
  subUrl,
  platformId,
  channelType,
}) {
  const myChartID = chartID;

  const [dropdownData, setDropdownData] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<string>("YTD");

  const [spendResponse, setSpendResponse] = useState<DataTypeResponse[]>([]);
  const [mockData, setMockData] = useState<DataType[]>([]);
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  function getDateForMonth(monthName) {
    const monthNames = [
      "JANUARY",
      "FEBRUARY",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUGUST",
      "SEPTEMBER",
      "OCTOBER",
      "NOVEMBER",
      "DECEMBER",
    ];

    const monthIndex = monthNames.indexOf(monthName.toUpperCase());

    if (monthIndex !== -1) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      return new Date(currentYear, monthIndex, 1).getTime();
    }
    return null;
  }

  const getData = async () => {
    setIsLoading(true);
    let requestData = {
      productId,
      startDate,
      endDate,
      productType,
      platformId,
    };

    let responseSpend = await getDataFromBackend(
      requestData,
      URLS.BASE_URL + subUrl
    );
    console.log("responseSpend", responseSpend?.data);
    // setSpendResponse(responseSpend?.data);
    let data = responseSpend?.data;
    data.forEach((item) => {
      item.data.forEach((entry) => {
        if (typeof entry.date === "string") {
          entry.date = getDateForMonth(entry.date);
        }
      });
    });
    setIsLoading(false);
    setMockData(data);
  };

  useEffect(() => {
    getData();
  }, [productId, startDate, endDate, productType, platformId]);

  const handleDropdownItemClick = (data: string) => {
    setSelectedItem(data);
  };

  useEffect(() => {
    if (mockData?.length > 0) {
      const mockDropdown = Array.from(
        new Set(mockData?.map((item) => item.dataFor))
      );
      setDropdownData(mockDropdown);

      const selectedData = mockData?.filter(
        (item: any) => item.dataFor === selectedItem
      );
      console.log(
        "seleceted spend data",
        selectedData[0],
        mockData,
        selectedItem
      );
      setSpendResponse(selectedData[0]?.data);
      // const newData = selectedData[0]?.data.map((item) => ({
      //   ...item,
      //   applicants: parseFloat(item?.applicants),
      // }));
      // setSelectedItem(productType)
    }
  }, [mockData, selectedItem]);
  useLayoutEffect(() => {
    let root = am5.Root.new(myChartID);
    root._logo?.dispose();

    root.setThemes([ChartTheme["getBarPerformanceThemeOrange"](root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
      })
    );

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);

    // Generate random data
    let date = new Date();
    date.setHours(0, 0, 0, 0);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.2,
        baseInterval: {
          timeUnit: selectedItem === "YTD" ? "month" : "day",

          // for remove extra day date
          count:1
            // channelType == "product" && selectedItem == "YTD"
            //   ? 1
            //   : channelType == "product" && selectedItem !== "YTD"
            //   ? 1
            //   : selectedItem == "YTD"
            //   ? 1
            //   : 0.9,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          pan: "zoom",
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 1,
        renderer: am5xy.AxisRendererY.new(root, {
          pan: "zoom",
        }),
      })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        stroke: am5.color("#E84A42"),
        // tooltip: am5.Tooltip.new(root, {
        //   labelText: "₹{valueY}",
        //   pointerOrientation: "right",
        // }),
      })
    );

    series.strokes.template.setAll({
      strokeWidth: 3,
    });

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 0,
        sprite: am5.Circle.new(root, {
          radius: 5,
          stroke: root.interfaceColors.get("background"),
          strokeWidth: 0,
          fill: series.get("fill"),
        }),
      });
    });

    let tooltip = am5.Tooltip.new(root, {
      labelText: "₹{valueY}",
      autoTextColor: false,
    });

    // Set the tooltip label's fill color
    tooltip.label.set("fill", am5.color(0xffffff));
    series.set("tooltip", tooltip);

    xAxis.get("renderer").grid.template.set("forceHidden", true);
    yAxis.get("renderer").grid.template.set("forceHidden", true);

    yAxis.get("renderer").labels.template.setAll({
      visible: false,
    });

    let data = spendResponse;
    series.data.setAll(data);

    let xAxis1 = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.2,
        baseInterval: {
          timeUnit: "day",
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    xAxis1.get("renderer").grid.template.set("forceHidden", true);

    // data for staraight line
    function generateDataStraightLine(
      baseValue: number,
      currentDate: Date
    ): { date: number; value: number } {
      const value = 0;

      const date = new Date(currentDate);
      am5.time.add(date, "day", 1);
      return {
        date: date.getTime(),
        value: value,
      };
    }

    function generateDatasStraightLine(count: number) {
      let data: Array<{ date: number; value: number }> = [];
      let currentDate = new Date();
      let baseValue = 0;
      for (let i = 0; i < count; ++i) {
        const newData = generateDataStraightLine(baseValue, currentDate);
        data.push(newData);
        currentDate = new Date(newData.date);
        baseValue = newData.value;
      }
      return data;
    }

    let series2 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Series",
        xAxis: xAxis1,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        stroke: am5.color("#E84A42"),
      })
    );

    series2.strokes.template.setAll({
      strokeWidth: 5,
    });

    xAxis1.get("renderer").labels.template.setAll({ visible: false });

    let data1 = generateDatasStraightLine(50);
    series2.data.setAll(isLoading ? [] : data1);

    series.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [
    myChartID,
    chartID,
    spendResponse,
    isZoomModalOpen,
    selectedItem,
    isLoading,
  ]);

  return (
    <div
      className="card card-flush h-lg-100"
      style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
    >
      <div className="pt-4 px-4">
        <div className="card-title d-flex justify-content-between align-items-center">
          <div className="card-label fw-bold text-dark fs-4">{title}</div>
          <div className="d-flex align-items-center">
            <div>
              <CustomDropdown
                dropdownData={dropdownData}
                selectedItem={selectedItem}
                onItemClick={handleDropdownItemClick}
              />
            </div>
            {channelType == "product" && (
              <div
                className="fw-bold"
                style={{
                  fontSize: "12px",
                  textAlign: "end",
                  cursor: "pointer",
                }}
                onClick={() => setIsZoomModalOpen(true)}
              >
                <AiOutlineZoomIn size={25} />
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoading ? (
        <div
          className="py-5 d-flex align-self-center justify-content-center min-vh-50"
          id={chartID}
          style={{ height: "100%", alignItems: "center" }}
        >
          <CustomLoader />
        </div>
      ) : !isZoomModalOpen ? (
        <div
          style={{
            width: "98%",
            alignSelf: "center",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            id={chartID}
            style={{
              height: "320px",
              width: "100%",
            }}
          ></div>
        </div>
      ) : (
        <Modal
          isOpen={isZoomModalOpen}
          onClose={() => setIsZoomModalOpen(false)}
          title={title}
        >
          <div
            style={{
              width: "98%",
              alignSelf: "center",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              id={chartID}
              style={{
                height: "420px",
                width: "100%",
              }}
            ></div>
          </div>
        </Modal>
      )}
    </div>
  );
}
export default SpendLineGraph;
