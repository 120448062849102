/** @format */

import { FC, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Field, useFormik } from "formik";
import clsx from "clsx";

import { getOrganizations, getCategory, AddResponse } from "../../../../application/_requests";
import { useMutation, useQuery } from "react-query";
import { Doc } from "../core/_models";
import { useParams } from "react-router-dom";
import { useQueryResponse } from "../core/QueryResponseProvider";
import { useListView } from "../core/ListViewProvider";

const editUserSchema = Yup.object().shape({
	// category: Yup.string().label("").required("Please select participant"),
	// organization: Yup.string().label("").required("Please select organization"),
	fileName: Yup.mixed()
		.required("File is required")
		.test(
			"fileType",
			"Please upload a file type: pdf",
			(value) => value && value[0].type === "application/pdf"
		)
		.test(
			"fileSize",
			// "File size must not exceed 50 MB.",
			"File size is greater than 50 MB.",
			(value) => value && value[0].size <= 50 * 1024 * 1024
		),
});

const AddResponseModalForm: FC<any> = ({ user, isUserLoading, onCancel }) => {
	const btnRef = useRef<HTMLButtonElement | null>(null);
	const { setToastMessage } = useListView();
	const { id } = useParams();
	const { refetch } = useQueryResponse();

	const {
		isFetching,
		refetch: refetchCat,
		data: category,
	} = useQuery(
		[`CATEGORY`],
		() => {
			return getCategory();
		},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
	);

	const {
		isFetching: isFetchingOrg,
		refetch: refetchOrg,
		data: organizations,
	} = useQuery(
		[`ORGANIZATION`],
		() => {
			return getOrganizations();
		},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
	);

	// const { mutate, isLoading } = useMutation('');

	const HandleSubmit = (values: any) => {
		btnRef.current?.setAttribute("data-kt-indicator", "on");
		const docRequest: Doc = {
			documentType: "SUBMISSION",
			draftId: id || "",
			subject: null,
			documentName: null,
			participantId: null,
			organisationId: null,
		};
		const file = values.fileName;
		//	btnRef.current?.setAttribute('data-kt-indicator', 'on');

		const response = AddResponse(docRequest, file)
			.then((res) => {
				btnRef.current?.removeAttribute("data-kt-indicator");
				onCancel();
				refetch();
				setToastMessage("Response Added Successfully");
				setTimeout(() => {
					setToastMessage(undefined);
				}, 2000);
			})
			.catch((err) => {
				alert("Request Failed!");
			});
	};
	const formik = useFormik({
		initialValues: {
			category: "",
			organization: "",
			fileName: undefined,
		},
		validationSchema: editUserSchema,
		// validateOnBlur:false,
		onSubmit: (values) => {
			HandleSubmit(values);
		},
	});

	return (
		<>
			<form
				id="kt_modal_add_user_form"
				className="form"
				onSubmit={formik.handleSubmit}
				noValidate
			>
				<div
					className="d-flex flex-column scroll-y me-n7 pe-7"
					id="kt_modal_add_user_scroll"
					data-kt-scroll="true"
					data-kt-scroll-activate="{default: false, lg: true}"
					data-kt-scroll-max-height="auto"
					data-kt-scroll-dependencies="#kt_modal_add_user_header"
					data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
					data-kt-scroll-offset="300px"
				>
					{/* <div className="fv-row mb-7">
						<label className="required fw-bold fs-6 mb-2">Participant</label>
						<select
							className={clsx(
								"form-select form-select-light mb-3 mb-lg-0",
								{
									"is-invalid":
										formik.touched.category && formik.errors.category,
								},
								{
									"is-valid":
										formik.touched.category && !formik.errors.category,
								}
							)}
							aria-label="Select example"
							{...formik.getFieldProps("category")}
						>
							<option
								// selected={true} 
								hidden={true}
								value=""
							>
								Select Participant
							</option>

							{category?.map((item: any, index: any) => (
								<option key={index} value={item.participantId}>
									{item.participantName}
								</option>
							))}
						</select>
						{formik.touched.category && formik.errors.category && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.category}</span>
								</div>
							</div>
						)}
					</div>

					<div className="fv-row mb-7">
						<label className="required fw-bold fs-6 mb-2">Organization</label>
						<select
							// className="form-select form-select-light"

							className={clsx(
								"form-select form-select-light mb-3 mb-lg-0",
								{
									"is-invalid":
										formik.touched.organization && formik.errors.organization,
								},
								{
									"is-valid":
										formik.touched.organization && !formik.errors.organization,
								}
							)}
							aria-label="Select example"
							{...formik.getFieldProps("organization")}
						>
							<option
								// selected={true} 
								hidden={true}
								value=""
							>
								Select Organization
							</option>
							{organizations?.map((item: any, index: any) => (
								<option key={index} value={item.organisationId}>
									{item.organisationName}
								</option>
							))}
						</select>
						{formik.touched.organization && formik.errors.organization && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.organization}</span>
								</div>
							</div>
						)}
					</div> */}

					<div className="fv-row mb-7">
						<label className="required fw-bold fs-6 mb-2">File</label>
						<input
							{...formik.getFieldProps("fileName")}
							type="file"
							name="fileName"
							value={undefined}
							onChange={(e) => formik.setFieldValue("fileName", e.target.files)}
							className={clsx(
								"form-control form-control-light mb-3 mb-lg-0",
								{
									"is-invalid":
										formik.touched.fileName && formik.errors.fileName,
								},
								{
									"is-valid":
										formik.touched.fileName && !formik.errors.fileName,
								}
							)}
							autoComplete="off"
							multiple
						/>
						{formik.touched.fileName && formik.errors.fileName && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.fileName}</span>
								</div>
							</div>
						)}
					</div>

					<div className="d-flex justify-content-center">
						<button
							title="Discard"
							type="reset"
							className="btn btn-light mx-10"
							data-kt-users-modal-action="cancel"
							onClick={onCancel}
						>
							Discard
						</button>

						<button
							title="Submit"
							ref={btnRef}
							type="submit"
							className="btn me-10 text-white btn-custom"
							id="kt_button_1"
							data-kt-users-modal-action="submit"
						>
							<span className="indicator-label">Submit</span>
							<span className="indicator-progress">
								Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

export { AddResponseModalForm };
