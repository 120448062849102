/** @format */

import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { ChartTheme } from "../../../../theme/assets/ts/_utils/ChartTheme";
import {
  URLS,
  getDataFromBackend,
} from "../../../modules/dashboards/DashboardRequests";
import CustomLoader from "../marketting_component/CustomLoader";
import CustomDropdown from "../marketting_component/CustomDropdown";
import { AiOutlineZoomIn } from "react-icons/ai";
import Modal from "../marketting_component/Modal";

type Props = {
  chartID: string;
  productId: string;
  startDate: string;
  endDate: string;
  platformId: string;
  productType: string;
  channelType: string;
  title: string;
  subUrl: string;
  baseLine: string;
};

type BudgetData = {
  data: {
    productType: string;
    budgetAllocated: number;
    budgetConsumed: number;
    percentage?: number;
  }[];
  dataFor: string;
};
type BudgetDataForGraph = {
  productType: string;
  budgetAllocated: number;
  budgetConsumed: number;
  percentage?: number;
};

const MultiHorizontalBarGraph: React.FC<Props> = ({
  chartID,
  productId,
  startDate,
  endDate,
  productType,
  platformId,
  channelType,
  title,
  subUrl,
  baseLine,
}) => {
  let root;

  const [isLoading, setIsLoading] = useState(false);

  const [graphData, setGraphData] = useState<BudgetDataForGraph[]>([]);
  const [mockData, setMockData] = useState<BudgetData[]>([]);
  const [selectedItem, setSelectedItem] = useState<string>("All");
  const [dropdownData, setDropdownData] = useState<string[]>(["All", "Other"]);
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      setGraphData([]);
      setMockData([]);
      let requestData = {
        productId,
        startDate,
        endDate,
        productType,
        platformId,
        baseLine,
      };
      let myUrl =
        channelType == "newproduct"
          ? URLS.BASE_URL + channelType + subUrl
          : URLS.GET_BUDGET_PLATFORM;
      let response = await getDataFromBackend(requestData, myUrl);

      setIsLoading(false);
      let data = response?.data;
      console.log("Response in Budget: ", data, channelType == "platform");
      if (data && channelType !== "platform") {
        console.log("Response in Budget !platform: ", response?.data);

        let newPercentAdded = response?.data?.map((item) => {
          return {
            ...item,
            budgetall: Math.round(item.budgetAllocated),
            budgetcons: Math.round(item.budgetConsumed),
            percentage: Math.round(
              (item.budgetConsumed / item.budgetAllocated) * 100
            ),
          };
        });
        setGraphData(newPercentAdded);
        console.log("gdata",graphData);

      } else if (channelType == "platform") {
        let newPercentAdded = response?.data?.map((item) => {
          return {
            ...item,
            budgetall: Math.round(item.budgetAllocated),
            budgetcons: Math.round(item.budgetConsumed),
            percentage: Math.round(
              (item.budgetConsumed / item.budgetAllocated) * 100
            ),
          };
        });
        setMockData(newPercentAdded);
        console.log("Response in Budget platform: ", response?.data, newPercentAdded);
        
      }
    };

    getData(); // run it, run it
  }, [chartID, productId, startDate, endDate, productType, baseLine]);

  useEffect(() => {
    if (mockData?.length > 0) {
      const mockDropdown = Array.from(
        new Set(mockData?.map((item) => item.dataFor))
      );
      setDropdownData(mockDropdown);

      const selectedData = mockData?.filter(
        (item) => item.dataFor === selectedItem
      );

      const newData = selectedData[0]?.data.map((item) => ({
        ...item,
        budgetall: Math.round(item.budgetAllocated),
        budgetcons: Math.round(item.budgetConsumed),
        percentage: Math.round(
          (item.budgetConsumed / item.budgetAllocated) * 100
        ),
      }));
      setGraphData(newData);
    }
  }, [mockData, selectedItem]);

  useLayoutEffect(() => {
    root = am5.Root.new(chartID);
    root._logo?.dispose();
    root.setThemes([ChartTheme.getTheme(root)]);
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        // wheelX: "panX",
        // wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 30,
    });

    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: channelType == "newproduct" ? "productType" : "name",
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {
          themeTags: ["axis"],
          animationDuration: 200,
        }),
      })
    );

    yRenderer.grid.template.setAll({
      location: 1,
    });

    yAxis.data.setAll(graphData);

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 0.1,
        }),
      })
    );


    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

    let series0 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Planned",
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: "budgetall",
        categoryYField: channelType == "newproduct" ? "productType" : "name",
        clustered: false,
        sequencedInterpolation: true,
      })
    );

    let tooltip0 = am5.Tooltip.new(root, {
      labelText:
        title === "Spends" || title === "Budget"
          ? "Planned: ₹{valueX}"
          : "Planned: {valueX}",
      autoTextColor: false,
    });

    tooltip0.label.set("fill", am5.color(0xffffff));

    series0.set("tooltip", tooltip0);

    //need to remove as it is hover tooltip
    series0.columns.template.setAll({
      tooltipText: title === "Spends" || title === "Budget"
      ? "Planned: ₹{valueX}"
      : "Planned: {valueX}",
    });

    series0.columns.template.setAll({
      height: am5.percent(60),
      tooltipY: 0,
      strokeOpacity: 0,
    });

    series0.data.setAll(graphData);

    let series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Actual",
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: "budgetcons",
        categoryYField: channelType == "newproduct" ? "productType" : "name",
        clustered: false,
        valueYField: "percentage",
        tooltip: am5.Tooltip.new(root, {
          labelText:
            title === "Spends" || title === "Budget"
              ? "Actual: ₹{valueX} \nPercent: {valueY}%"
              : "Actual: {valueX} \nPercent: {valueY}%",
        }),
      })
    );


    //need to remove as it is hover tooltip
    series1.columns.template.setAll({
      tooltipText:       title === "Spends" || title === "Budget"
      ? "Actual: ₹{valueX} \nPercent: {valueY}%"
      : "Actual: {valueX} \nPercent: {valueY}%",
    });

    let tooltip1 = am5.Tooltip.new(root, {
      labelText:
        title === "Spends" || title === "Budget"
          ? "Actual: ₹{valueX} \nPercent: {valueY}%"
          : "Actual: {valueX} \nPercent: {valueY}%",
      autoTextColor: false,
    });

    // Set the tooltip label's fill color
    tooltip1.label.set("fill", am5.color(0xffffff));

    // Apply the tooltip to the series
    series0.set("tooltip", tooltip0);

    series0.columns.template.setAll({
      height: am5.percent(60),
      tooltipY: 0,
      strokeOpacity: 0,
    });

    series1.columns.template.setAll({
      height: am5.percent(50),
      tooltipY: 0,
      strokeOpacity: 0,
    });

    series1.data.setAll(graphData);

    chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "selectX"
    }));
    // let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));

    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    legend.data.setAll(chart.series.values);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);
    series0.appear();
    // series1.appear();

    return () => {
      root.dispose();
    };
  }, [graphData, isLoading, isZoomModalOpen, title]);

  const handleDropdownItemClick = (data: string) => {
    setSelectedItem(data);
  };

  return (
    <>
      <div
        className="card card-flush h-lg-100 p-5"
        id="test"
        style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
      >
        {isLoading ? (
          <div
            id={chartID}
            className="py-5 d-flex align-self-center justify-content-center min-vh-80"
          >
            <div style={{ alignSelf: "center" }}>
              <CustomLoader />
            </div>
          </div>
        ) : (
          <>
            <div className="card-title d-flex justify-content-between align-items-center">
              <div className="card-label fw-bold text-dark fs-4">{title}</div>
              {channelType == "platform" && (
                <div>
                  <CustomDropdown
                    dropdownData={dropdownData}
                    selectedItem={selectedItem}
                    onItemClick={handleDropdownItemClick}
                  />
                </div>
              )}
              {channelType == "newproduct" && (
                <div
                  className="fw-bold"
                  style={{
                    fontSize: "12px",
                    textAlign: "end",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsZoomModalOpen(true)}
                >
                  <AiOutlineZoomIn size={25} />
                </div>
              )}
            </div>

            {!isZoomModalOpen ? (
              <div
                id={chartID}
                style={{ height: "400px", width: "100%" }}
              ></div>
            ) : (
              <Modal
                isOpen={isZoomModalOpen}
                onClose={() => setIsZoomModalOpen(!isZoomModalOpen)}
                title={title}
              >
                <div
                  id={chartID}
                  style={{ height: "600px", width: "100%" }}
                ></div>
              </Modal>
            )}
          </>
        )}
      </div>
      {/* {isLoading && (

      )} */}
    </>
  );
};

export default MultiHorizontalBarGraph;
