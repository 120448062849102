import { useState, useRef } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { KTCard } from "../../../../theme/helpers";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsCurrencyRupee } from "react-icons/bs";
import { BsExclamationCircle } from "react-icons/bs";
import { MdSettings } from "react-icons/md";

import { useNavigate } from "react-router-dom";
import search from "../../../../assets/search.png";
import display from "../../../../assets/display.png";

import { FaTimes } from "react-icons/fa";
import { Badge } from "react-bootstrap";
import { IoMdArrowDropdown } from "react-icons/io";

const AdSchedule = ({ schedules, setSchedules }) => {
  const territory = [
    {
      id: "1",
      tname: "Optimize: Prefer best performing ads",
    },
    {
      id: "2",
      tname: "Do not optimize: Rotate ads indefinitely",
    },
    {
      id: "3",
      tname: "Optimize for conversions (Not supported)",
    },
    {
      id: "4",
      tname: "Rotate evenly (Not supported)",
    },
  ];
  const [isExpanded, setIsExpanded] = useState(false);
  const [dropdownCount, setDropdownCount] = useState(1);
  const [headerColor, setHeaderColor] = useState("");

  const handleToggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  const [numElements, setNumElements] = useState(1);
  //   const handleAddClick = () => {
  //     setNumElements(numElements + 1);
  //   };

  // const [schedules, setSchedules] = useState([
  //   { selectedWeekday: "", startTime: "", endTime: "" },
  // ]);

  const [errorMessage, setErrorMessage] = useState("");

  const handleWeekdaySelection = (index, weekday) => {
    const updatedSchedules = [...schedules];
    updatedSchedules[index].selectedWeekday = weekday;
    setSchedules(updatedSchedules);
  };

  console.log("first", schedules);
  const handleStartTime = (index, start) => {
    const updatedSchedules = [...schedules];
    updatedSchedules[index].startTime = start;
    setSchedules(updatedSchedules);
    setErrorMessage("");
  };

  // const handleEndTime = (index, end) => {
  //   const updatedSchedules = [...schedules];
  //   updatedSchedules[index].endTime = end;
  //   setSchedules(updatedSchedules);
  // };
  const handleEndTime = (index, end) => {
    const start = schedules[index].startTime;

    if (end <= start) {
      // Invalid end time, set error message
      setErrorMessage("");
      return;
    }
    const updatedSchedules = [...schedules];
    updatedSchedules[index].endTime = end;
    setSchedules(updatedSchedules);

    // Clear error message when end time is valid
    setErrorMessage("End time is earlier than start time");
  };

  const handleAddClick = () => {
    setDropdownCount((prevCount) => prevCount + 1);
    setNumElements(numElements + 1);
    setSchedules([
      ...schedules,
      { selectedWeekday: "", startTime: "", endTime: "" },
    ]);
  };

  const weekdays = [
    "All Days",
    "Monday-Friday",
    "Saturdays-Sundays",
    "Mondays",
    "Tuesdays",
    "Wednesdays",
    "Thursdays",
    "Fridays",
    "Saturdays",
    "Sundays",
  ];
  const start = [
    "00.00",
    "00.15",
    "00.30",
    "00.45",
    "01.00",
    "01.15",
    "01.30",
    "01.45",
    "02.00",
    "02.15",
  ];

  const end = [
    "00.00",
    "00.15",
    "00.30",
    "00.45",
    "01.00",
    "01.15",
    "01.30",
    "01.45",
    "02.00",
    "02.15",
  ];

  return (
    <>
      <div
        className="card"
        style={{
          marginTop: isExpanded ? "10px" : "0px",
          backgroundColor: isExpanded ? "" : "#E1E2E3",
          boxShadow: isExpanded ? "0 2px 8px rgba(0, 0, 0, 0.5)" : "",
        }}
      >
        <div
          className="card-header"
          onClick={handleToggleContent}
          onMouseOver={() => setHeaderColor("#E1E2E3")}
          onMouseOut={() => setHeaderColor("")}
          style={{
            backgroundColor: headerColor || "#FFFFFF", // Set default color to white
            cursor: "pointer",
            border: "1px solid #ced4da",
            transition: "background-color 0.3s ease",
            minHeight: "48px",
          }}
        >
          <div
            className="row gx-xl-12 gy-5 d-flex align-items-center"
            style={{ width: "100%" }}
          >
            <div className="col-xl-3">
              <h5 className="card-title">Ad schedule</h5>
            </div>
            <div className="col-xl-8">
              {!isExpanded ? (
                <h5
                  className="card-title"
                  style={{ color: "rgba(0,0,0,.54)" }}
                >
                  {schedules.some((schedule) => schedule.selectedWeekday) // Check if any schedule has a selectedWeekday
                    ? "Using a custom schedule"
                    : "All Days"}
                </h5>
              ) : null}
            </div>
            <div className="col-xl-1 d-flex align-items-center justify-content-end">
              {isExpanded ? (
                <BsChevronUp
                  className="arrow-icon"
                  style={{ marginTop: "20px" }}
                />
              ) : (
                <BsChevronDown
                  className="arrow-icon"
                  style={{ marginTop: "20px" }}
                />
              )}
            </div>
          </div>
        </div>
        {isExpanded && (
          <div className="card-body">
            <div className="row gx-xl-12 gy-10">
              <div className="col-xl-8">
                <div
                  className="container p-1"
                  style={{ backgroundColor: "white", borderRadius: "10px" }}
                >
                  {Array.from(Array(numElements).keys()).map((index) => (
                    <div
                      key={index}
                      className="row gy-5 gx-xl-10"
                      style={{ marginTop: index === 0 ? "0" : "10px" }}
                    >
                      <div style={{ display: "flex" }}>
                        <div className="dropdown">
                          {/* Your div code */}
                          <div
                            className="dropdown"
                            id={`weekdayDropdown`}
                            style={{
                              width: "152px",
                              textAlign: "left",
                              fontSize: "13px",
                              border: "1px solid black",
                              borderRadius: "5px",
                              marginLeft: "10px",
                              height: "50px",
                            }}
                          >
                            <div
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{
                                width: "100%",
                                textAlign: "left",
                                fontSize: "13px",
                                display: "flex",
                                alignItems: "center",
                                padding: "5px",
                              }}
                            >
                              {/* {schedules[index].selectedWeekday || "Select Weekday"} */}

                              <div style={{ flex: "1" }}>
                                {schedules[index].selectedWeekday || "All Days"}
                                <IoMdArrowDropdown
                                  style={{
                                    marginLeft: "5px",
                                    color: "#5f6368",
                                  }}
                                />
                              </div>
                            </div>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby={`weekdayDropdown`}
                              style={{
                                width: "100%",
                                minWidth: "unset",
                              }}
                            >
                              {weekdays?.map((item) => (
                                <li key={item}>
                                  <button
                                    className="dropdown-item"
                                    onClick={() =>
                                      handleWeekdaySelection(index, item)
                                    }
                                  >
                                    {item}
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </div>
                          {/* Render other dropdown divs similarly */}
                        </div>
                        <div className="dropdown">
                          {/* Your div code */}
                          <div
                            className="dropdown"
                            id={`start`}
                            style={{
                              width: "100px",
                              textAlign: "left",
                              fontSize: "13px",
                              border: "1px solid black",
                              borderRadius: "5px",
                              marginLeft: "5px",
                              height: "50px",
                            }}
                          >
                            <div
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{
                                width: "100%",
                                textAlign: "left",
                                fontSize: "13px",
                                display: "flex",
                                alignItems: "center",
                                padding: "5px",
                              }}
                            >
                              <div style={{ flex: "1" }}>
                                {/* {startTime} */}
                                {schedules[index].startTime || "00.00"}
                                <IoMdArrowDropdown
                                  style={{
                                    marginLeft: "5px",
                                    color: "#5f6368",
                                  }}
                                />
                              </div>
                            </div>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby={`start`}
                              style={{
                                width: "100%",
                                minWidth: "unset",
                              }}
                            >
                              {start?.map((item) => (
                                <li key={item}>
                                  <button
                                    className="dropdown-item"
                                    onClick={() => handleStartTime(index, item)}
                                  >
                                    {item}
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </div>
                          {/* Render other dropdown divs similarly */}
                        </div>
                        {index < dropdownCount - 0 && (
                          <span style={{ margin: "0 10px" }}>to</span>
                        )}
                        <div
                          className="dropdown"
                          id={`end`}
                          style={{
                            width: "100px",
                            textAlign: "left",
                            fontSize: "13px",
                            border: "1px solid black",
                            borderRadius: "5px",
                            marginLeft: "10px",
                            height: "50px",
                          }}
                        >
                          <div
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              width: "100%",
                              textAlign: "left",
                              fontSize: "13px",
                              display: "flex",
                              alignItems: "center",
                              padding: "5px",
                            }}
                          >
                            <div style={{ flex: "1" }}>
                              {/* {endTime || hour[0]} */}
                              {/* {endTime} */}
                              {schedules[index].endTime || "00.00"}
                              <IoMdArrowDropdown
                                style={{
                                  marginLeft: "5px",
                                  color: "#5f6368",
                                }}
                              />
                            </div>
                          </div>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby={`end`}
                            style={{
                              width: "100%",
                              minWidth: "unset",
                            }}
                          >
                            {end?.map((item) => (
                              <li key={item}>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleEndTime(index, item)}
                                >
                                  {item}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </div>
                        {/* Render other dropdown divs similarly */}
                      </div>
                    </div>
                  ))}

                  <div className="col-xl-2 ">
                    <button
                      className="btn btn-light"
                      style={{
                        backgroundColor: "transparent",
                        color: "#007bff",
                        border: "none",
                        transition: "background-color 0.3s",
                        fontSize: "15px",
                      }}
                      onClick={handleAddClick}
                    >
                      Add
                    </button>
                  </div>
                </div>

                <div
                  className="card m-6"
                  style={{ display: "flex", width: "100%" }}
                >
                  <div className="row gy-5 gx-xl-12">
                    <div className="col-xl-10 p-3">
                      <span
                        className="text-dark"
                        style={{
                          fontSize: "13px",
                          textAlign: "left",
                          color: "3c4043",
                        }}
                      >
                        Based on account time zone: (GMT+05:30) India Standard
                        Time Saving this removes the settings you changed and
                        adds new ones, resetting any performance data
                      </span>
                    </div>
                    <div
                      className="text"
                      style={{
                        fontSize: "13px",
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {errorMessage}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-xl-1 d-flex align-items-center justify-content-center"> */}
              <div className="col-xl-4 d-flex">
                <div
                  className="vertical-line"
                  style={{
                    width: "0.7px",
                    height: "100%",
                    backgroundColor: "#ccc",
                    marginLeft: "20px",
                  }}
                />
                {/* </div> */}

                <div
                  className=""
                  style={{
                    fontSize: "13px",
                    color: "3C4043",
                    marginLeft: "60px",
                  }}
                >
                  To limit when your ads can run, set an ad schedule. Keep in
                  mind that your ads will only run during these times.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AdSchedule;
