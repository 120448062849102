/**
 * /* eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

import { Fragment, useEffect, useState } from "react";
import { KTCard, KTSVG } from "../../../../theme/helpers";
import { getCampaignHighlights } from "../../../modules/application/_requests";
import { useQuery } from "react-query";
import { useParams, useLocation } from "react-router-dom";
import { IoSparkles } from "react-icons/io5";
import { BsTag } from "react-icons/bs";
import CampaignType from "./CampaignType";
import { AiOutlineQuestionCircle, AiOutlineSearch } from "react-icons/ai";
import { IoMdLink } from "react-icons/io";
import CheckBox from "./CheckBox";
import Bidding from "./Bidding";
import { useNavigate } from "react-router-dom";
import { BiCircleThreeQuarter } from "react-icons/bi";

type Props = {
  className: string;
};

const CampaignName = ({inputValue,setInputValue,selectedCardTitle,selectedTypeCardTitle}) => {
  const location = useLocation();
  const locationURL = window.location.pathname.split("/").pop();

  const id = (location.state as { id: string })?.id || "";
  const navigate = useNavigate();

  console.log(id, "fdkfhdk");
  console.log(selectedCardTitle,"vdvdvdvdvdvd")
  console.log(selectedTypeCardTitle,"selectedTypeCardTitle")
  //   const { id } = useParams();
  console.log(id, "params data");
  const [showCampaignType, setShowCampaignType] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  // const [inputValue, setInputValue] = useState('');

  const modalOpen = () => {
    setShowConfirmationPopup(true);

  };
  const handleClick = () => {
    setShowCampaignType(true);
    setShowModal(true);
    // setShowConfirmationPopup(false)
    // navigate('/biding');
  };
  const handleBiding = () => {
    setShowCampaignType(true);

    navigate('/biding');
  };



  const TrackData = [
    {
      "track": "Sales-Search-31"
    },
    {
      "track": "Sales-Search-31"
    },
    {
      "track": "Sales-Search-31"
    },
    {
      "track": "Sales-Search-31"
    },
    {
      "track": "Sales-Search-31"
    },
    {
      "track": "Sales-Search-31"
    },
    {
      "track": "Sales-Search-31"
    },
  ]

  // const { "track" } = TrackData;

  return (
    <>

      <div className="pb-3 pt-5 mt-10 px-5"
        style={{ backgroundColor: "white", boxShadow: '0 2px 8px rgba(0, 0, 0, 0.5)', borderRadius: "10px" }}>
        <span className="fw-bold text-dark mx-5" style={{ fontSize: '16px' }}>
          Campaign Name
        </span>
        <hr
          style={{
            // marginTop: "-10px",
            border: "1px solid #CCCCCC",
            width: "102.8%",
            marginLeft: '-17px'
          }} />

        <div className="my-10 mx-5" style={{ position: 'relative' }}>
          <input
            // placeholder="Sales-Search-31"
            defaultValue={(selectedCardTitle ? selectedCardTitle : "") + " - " + (selectedTypeCardTitle ? selectedTypeCardTitle : "")}
            style={{
              padding: '10px',
              paddingLeft: '20px',
              width: '320px',
              height: '50px',
              borderRadius: '5px',
              fontSize: '13px',
              border: '1px solid #80868B', // Set initial border to transparent
              borderColor: '#80868B', // Set initial border color
              borderWidth: '1px', // Set initial border width
              color: '80868B',
              outline: 'none', // Remove default outline style
              transition: 'border-color 0.3s ease-in-out', // Add transition effect
            }}
            onChange={(e) => setInputValue(e.target.value)}
            onFocus={(e) => {
              e.target.style.borderColor = '#0d6efd'; // Change border color on focus
              e.target.style.borderWidth = '3px'; // Change border width on focus
            }}
            onBlur={(e) => {
              e.target.style.borderColor = '#80868B'; // Reset border color on blur
              e.target.style.borderWidth = '1px'; // Reset border width on blur
            }}
          />



        </div>


      </div>






      {/* </div> */}
    </>
  );
};
export default CampaignName;
