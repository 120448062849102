import React, { useEffect, useState } from "react";
import { IoTriangleSharp } from "react-icons/io5";
import TrendsGraphV2 from "./TrendsGraphV2";
import {
  URLS,
  getDataFromBackend,
} from "../../../modules/dashboards/DashboardRequests";
import { FormatUtil } from "../../../../theme/assets/ts/_utils/FormatUtil";
import { AiOutlineZoomIn } from "react-icons/ai";

type DateData = {
  date: string;
  value: number;
};

type DataEntry = {
  title: string;
  value: number;
  data: DateData[];
};

type Props = {
  productId: string;
  platformId: string;
  startDate: string;
  endDate: string;
  subUrl: string;
  title: string;
  isGrowth: boolean;
  showCurrency: boolean;
  campaignId: any;
  productType: any;
};

const TrendSubComponent: React.FC<Props> = ({
  productId,
  platformId,
  startDate,
  endDate,
  subUrl,
  title,
  isGrowth,
  showCurrency,
  campaignId,
  productType
}: Props) => {
  const [data, setData] = useState<DataEntry>({
    title: title,
    value: 0,
    data: [],
  });

  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);

  useEffect(() => {
    const getData = async () => {
      let requestData = {
        productId,
        startDate,
        endDate,
        campaignId,
        platformId,
        productType
      };
      let response = await getDataFromBackend(
        requestData,
        URLS.BASE_URL + subUrl
      );
      console.log("Response of trends component : ", response?.data, subUrl, requestData);
      setData(response?.data);
    };
    getData(); // run it, run it
    return () => {
      // root?.dispose();
      // this now gets called when the component unmounts
    };
  }, [platformId, productId, campaignId, startDate, endDate, subUrl, productType]);

  return (
    <>
      <div className="col-xl-3">
        <div
          className="card card-flush h-lg-100 p-5"
          style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
        >
          <div className=" p-5 d-flex justify-content-between ">
            <h3 className="card-title align-items-start flex-column border-solid-2px">
              <div
                className="card-label fw-semibold fs-6"
                style={{ color: "#8A8A8A" }}
              >
                {title}
              </div>
              {/* {data?.value > 0 && ( */}
              <div
                className="text-400 mt-1 fw-semibold d-flex align-items-center"
                style={{ color: "#3F4254", fontSize: "30px" }}
              >
                {showCurrency ? "₹" : ""}
                {Math.round(data?.value).toLocaleString("en-IN")}
                {/* {isGrowth ? (
                    <IoTriangleSharp
                      size={30}
                      style={{ marginLeft: "10px", color: "#5ACD89" }}
                    />
                  ) : (
                    <IoTriangleSharp
                      size={30}
                      style={{
                        marginLeft: "10px",
                        color: "red",
                        transform: "rotate(180deg)",
                        display: "inline-block",
                      }}
                    />
                  )} */}
              </div>
              {/* )} */}
            </h3>
            <div
              className="fw-bold"
              style={{
                fontSize: "12px",
                textAlign: "end",
                cursor: "pointer",
              }}
              onClick={() => setIsZoomModalOpen(true)}
            >
              <AiOutlineZoomIn size={25} />
            </div>
          </div>
          {data?.data && (
            <TrendsGraphV2
              isZoomModalOpen={isZoomModalOpen}
              setIsZoomModalOpen={setIsZoomModalOpen}
              chartID={`${title}graph`}
              data={data.data}
              title={title}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TrendSubComponent;
