import { languages } from "prismjs";
import React, { useEffect, useState, useRef } from "react";
import { AiFillEye, AiFillPlayCircle, AiOutlineSearch } from "react-icons/ai";
import {
  BsChevronDown,
  BsChevronUp,
  BsFillCheckCircleFill,
  BsFillCircleFill,
} from "react-icons/bs";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FaTimes } from "react-icons/fa";
import { IoIosCloseCircle, IoMdArrowDropdown } from "react-icons/io";
import { KTCard } from "../../../../theme/helpers";
import CampaignName from "./CampaignName";
import CustomQuestion from "./customQuestion";
import Dates from "./Dates";
import Languages from "./Languages";
import Location from "./Location";

import { AiFillPauseCircle } from "react-icons/ai";
import AdSchedule from "./AdSchedule";
import { Navigate, useNavigate } from "react-router-dom";
import { editCampaign } from "../../../modules/application/_requests";
// import Budget from "./Budget";
import warning from "../../../../assets/baseline-warning.png";
import { MdCurrencyRupee } from "react-icons/md";
import { useQueryClient } from "react-query";
import { HiCheckCircle } from "react-icons/hi";

function Edit({ id, camp }) {

  const queryClient = useQueryClient();
  const [isChecked, setIsChecked] = useState(false);
  console.log(id, camp, camp?.targetLocation, camp?.status, "fetched data from listing");
  const [budgetValue, setBudgetValue] = useState("");

  const [selectedLabel, setSelectedLabel] = useState({
    value: "",
    label: "",
  });

  useEffect(() => {
    if (camp && camp?.status) {
      setSelectedLabel({
        value: camp.status,
        label: camp.status,
      });
    }
    if (camp && camp?.targetLocation) {
      setSelectedOption(camp?.targetLocation);
    }
    if (camp && camp?.targetLanguage) {
      setSelectedLanguage(camp?.targetLanguage);
    }
    if (camp && camp?.budget) {
      setBudgetValue(camp?.budget);
    }
  }, [camp]);

  console.log(selectedLabel.value, "selectedLabel");
  const [showOptions, setShowOptions] = useState(false);
  const [dropSelect, setdropSelect] = useState(false);
  const [dropHover, setdropHover] = useState(false);
  const [campaignValue, setCampaignValue] = useState("");
  const [dropbg, setdropbg] = useState(false);

  const [hoveredOptionIndex, setHoveredOptionIndex] = useState(null);
  const [selectedOptionIndex, setselectedOptionIndex] = useState(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const divStyles = {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    width: "150px",
    height: "50px",
    borderRadius: "5px",
    border: "1px solid #80868B",
    cursor: "pointer",
    color: "#3c4043",
    outline: "none",
    transition: "border-color 20s ease-in-out",
    borderColor: showOptions ? "#196DDC" : "#80868B",
    borderWidth: showOptions ? "2px" : "1px",
  };

  const [isClicked, setIsClicked] = useState(false);

  const options = [
    { value: "Live Now", label: "Live Now" },
    { value: "Paused", label: "Paused" },
    { value: "Reviewing", label: "Reviewing" },
    { value: "Completed", label: "Completed" },
  ];

  //  const targetLocation=camp?.targetLocation || ""
  //  console.log(targetLocation,"targetLocation")

  let cardIcon: JSX.Element | null = null;

  // input[type="checkbox"]:checked::before {
  // content: "";
  // position: absolute;
  // top: 4px;
  // left: 7px;
  // width: 5px;
  // height: 10px;setShowOptions
  // border: solid #007bff;
  // border-width: 0 2px 2px 0;
  // transform: rotate(45deg);
  // }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionChange = (option, index) => {
    setSelectedLabel(option);
    // setSelectedaOption(option);
    setShowOptions(false);
    setdropbg(true);
    setselectedOptionIndex(index);
  };

  const handleMouseEnter = (index) => {
    setdropHover(true);
    setHoveredOptionIndex(index);
  };

  const handleMouseLeave = () => {
    setdropHover(false);
    setHoveredOptionIndex(null);
  };
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(false);
  const [isExpanded4, setIsExpanded4] = useState(false);
  const [headerColor, setHeaderColor] = useState("");
  const [headerColor2, setHeaderColor2] = useState("");
  const [headerColor3, setHeaderColor3] = useState("");

  const [headerColor4, setHeaderColor4] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);

  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [schedules, setSchedules] = useState([
    { selectedWeekday: "", startTime: "", endTime: "" },
  ]);

  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  // Initialize with the first value

  const handleToggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  const handleToggleContent2 = () => {
    setIsExpanded2(!isExpanded2);
  };

  const handleToggleContent3 = () => {
    setIsExpanded3(!isExpanded3);
  };
  const handleToggleContent4 = () => {
    setIsExpanded4(!isExpanded4);
  };

  const status_data = [
    {
      name: "Status",
      statusdata: ["Enabled", "Paused", "Removed"],
    },
  ];
  const [selected, setSelected] = useState([]);
  const handleDrop2 = (index, selectedValue) => {
    console.log(`Selected value: ${selectedValue}`);
    setSelected(selectedValue);
  };




  const validationSchema = Yup.object().shape({
    inputValue: Yup.number()
      .typeError("Enter a monetary value")
      .required("Enter a monetary value"),
  });

  const formik = useFormik({
    initialValues: {
      inputValue: "",
    },
    validationSchema,
    onSubmit: () => { },
  });

  const handleInputChange = (e) => {
    const { value } = e.target;
    setBudgetValue(value);
    // setInputValueWhenClosed(value);
    formik.setFieldValue("inputValue", value);
  };

  const handleClickOutside = () => {
    if (formik.values.inputValue && !formik.values.inputValue.includes(".")) {
      formik.setFieldValue("inputValue", formik.values.inputValue + ".00");
    } else if (!formik.values.inputValue) {
      formik.setFieldValue("inputValue", "0.00");
    }
  };


  const territory = [
    {
      id: "1",
      tname: "All countries and territories",
    },
    {
      id: "2",
      tname: "India",
    },
    {
      id: "3",
      tname: "Enter another location",
    },
  ];
  // const [selectedOption, setSelectedOption] = useState(
  //   "All countries and territories"
  // );
  const [selectedTarget, setSelectedTarget] = useState("");
  const target = [
    {
      id: "1",
      tarname:
        "Presence or interest: People in, regularly in, or who've shown interest in your targeted locations (recommended)",
    },
    {
      id: "2",
      tarname: "Presence: People in your excluded locations(recommended)",
    },
  ];
  const languages = [
    {
      id: "1",
      name: "All Languages",
    },
    {
      id: "2",
      name: "Arabic",
    },
    {
      id: "3",
      name: "Bengali",
    },
    {
      id: "4",
      name: "Bulgarian",
    },
    {
      id: "5",
      name: "Catalan",
    },
    {
      id: "6",
      name: "Chinese(simplified)",
    },

    {
      id: "7",
      name: "Chinese(Traditional)",
    },
    {
      id: "8",
      name: "Croatian",
    },
    {
      id: "9",
      name: "Czech",
    },
    {
      id: "10",
      name: "Danish",
    },
    {
      id: "11",
      name: "Dutch",
    },
    {
      id: "12",
      name: "English",
    },
    {
      id: "13",
      name: "Estonian",
    },
    {
      id: "14",
      name: "Filipino",
    },
    {
      id: "15",
      name: "Finnish",
    },
    {
      id: "16",
      name: "French",
    },
    {
      id: "17",
      name: "German",
    },
    {
      id: "18",
      name: "Greek",
    },
    {
      id: "19",
      name: "Gujarati",
    },
    {
      id: "20",
      name: "Hebrew",
    },
    {
      id: "21",
      name: "Hindi",
    },
    {
      id: "22",
      name: "Hungarian",
    },
    // {
    //   id: "23",
    //   name: "Icelandic",
    // },
    // {
    //   id: "24",
    //   name: "Indonesian",
    // },
    // {
    //   id: "25",
    //   name: "Italian",
    // },
    // {
    //   id: "26",
    //   name: "Japanese",
    // },
    // {
    //   id: "27",
    //   name: "Korean",
    // },
    // {
    //   id: "28",
    //   name: "Latvian",
    // },
    // {
    //   id: "29",
    //   name: "Lithuanian",
    // },
    // {
    //   id: "30",
    //   name: "Malay",
    // },
    // {
    //   id: "31",
    //   name: "Malayalam",
    // },
    // {
    //   id: "32",
    //   name: "Marathi",
    // },
    // {
    //   id: "33",
    //   name: "Norwegian",
    // },
    // {
    //   id: "34",
    //   name: "Persian",
    // },
    // {
    //   id: "35",
    //   name: "Polish",
    // },
    // {
    //   id: "36",
    //   name: "Portuguese",
    // },
    // {
    //   id: "37",
    //   name: "Punjabi",
    // },
    // {
    //   id: "38",
    //   name: "Roamania",
    // },
    // {
    //   id: "39",
    //   name: "Russian",
    // },
    // {
    //   id: "40",
    //   name: "Serbian",
    // },
    // {
    //   id: "41",
    //   name: "Slovak",
    // },
    // {
    //   id: "42",
    //   name: "Slovenian",
    // },
    // {
    //   id: "43",
    //   name: "Spanish",
    // },
    // {
    //   id: "44",
    //   name: "Swedish",
    // },
    // {
    //   id: "45",
    //   name: "Tamil",
    // },
    // {
    //   id: "46",
    //   name: "Telugu",
    // },
    // {
    //   id: "47",
    //   name: "Thai",
    // },
    // {
    //   id: "48",
    //   name: "Turkish",
    // },
    // {
    //   id: "49",
    //   name: "Ukrainian",
    // },
    // {
    //   id: "50",
    //   name: "Urdu",
    // },
    // {
    //   id: "51",
    //   name: "Vietnamese",
    // },
  ];

  //   const handleSubmit = () => {

  //     const save={
  //         id: id,
  //         name:inputValue,

  //     };
  //     console.log(save,"saveeeeeeeeeee")
  //       const res = editCampaign(save,id)

  //         .then((res) => {
  //           console.log("Campaign Settings EditSuccessfully!");
  //           console.log(res,"ressssssssssssssssssssssss")

  //             setTimeout(() => {
  //               console.log(undefined);
  //             }, 2000);
  //         })
  //         .catch((err) => {
  //             alert("Request Failed!");
  //         });
  // };

  const [selectedOption, setSelectedOption] = useState("");
  console.log(selectedOption, "location value");
  const navigate = useNavigate();

  const handleSubmit = () => {
    const save = {
      id: id || "",
      name: campaignValue !== "" ? campaignValue : camp?.name,
      status: selectedLabel?.value || camp?.status,
      // targetLocation: selectedOption || camp?.targetLocation,
      targetLocation:
        selectedOption === "Enter another location"
          ? inputValue
          : selectedOption || camp?.name,
      targetLanguage:
        selectedLanguage !== "" ? selectedLanguage : camp?.targetLanguage,
      startdate:selectedStartDate !==""? selectedStartDate : camp?.startdate,
      budget: budgetValue !== "" ? budgetValue : camp?.budget,
      endDate:selectedEndDate !==""? selectedEndDate : camp?.endDate,
      target: camp?.target,
      budgetGoal: camp?.budgetGoal,
      stage: camp?.stage,
      tag: camp?.tag,
      createdBy: null,
      updatedBy: camp?.updatedBy,
      actualCpg: camp?.actualCpg,
      targetCpg: camp?.targetCpg,
      campaignObjective: camp?.campaignObjective,
      campaignGoal: camp?.campaignGoal,
      campaignType: camp?.campaignType,
      reachGoalName: camp?.reachGoalName,
      reachGoalValue: camp?.reachGoalValue,
      campaignBiddingFocusOn: camp?.campaignBiddingFocusOn,
      campaignBiddingFocusOnValue: camp?.campaignBiddingFocusOnValue,
      campaignCustomerAcquisitionValue: camp?.campaignCustomerAcquisitionValue,
      campaignNetwork: camp?.campaignNetwork,
      audienceSegment: camp?.audienceSegment,
      campaignAdsRotation: camp?.campaignAdsRotation,
      adsNoOfDays: camp?.adsNoOfDays,
      adsStartTime: camp?.adsStartTime,
      adsEndTime: camp?.adsEndTime,
      websiteUrl: camp?.websiteUrl,
      websiteUrlSuffix: camp?.websiteUrlSuffix,
      urlName: camp?.urlName,
      urlValue: camp?.urlValue,
      scanUrl: camp?.scanUrl,
      productServices: camp?.productServices,
      searchKeywords: camp?.searchKeywords,
      createdDate: "2023-04-01 19:36:48",
      updatedDate: camp?.updatedDate,
    };

    console.log(save, "edit page");

    const response = editCampaign(save)
      .then((response) => {
        console.log("Response Updated Successfully");
        console.log(response, "edit page api response");
        // window.location.reload();
        queryClient.refetchQueries(["GET_ALL_LIST"]);
      })
      .catch((err) => {
        alert("Request Failed!");
      });
    // navigate(0)
    setIsExpanded(false);
    setIsExpanded2(false);
    setIsExpanded3(false);
    setIsExpanded4(false);

  };
  return (
    <>
      <div
        className="card"
        style={{
          // overflow: "hidden",yyy
          marginBottom: isExpanded ? "20px" : "0px",
          backgroundColor: isExpanded ? "" : "#E1E2E3",
          boxShadow: isExpanded ? "0 2px 8px rgba(0, 0, 0, 0.5)" : "",
        }}
      >
        <div
          className="card-header d-flex align-items-center"
          onClick={handleToggleContent}
          onMouseOver={() => setHeaderColor("#E1E2E3")}
          onMouseOut={() => setHeaderColor("")}
          style={{
            backgroundColor: headerColor || "#FFFFFF", // Set default color to white
            cursor: "pointer",
            border: "1px solid #ced4da",
            transition: "background-color 0.3s ease",
            minHeight: "48px",
            borderRadius: "5px",
          }}
        >
            <div
            className="row gx-xl-12 gy-5 d-flex align-items-center"
            style={{ width: "100%" }}
          >
            <div className="col-xl-3">
          <h5 className="card-title">Campaign Name</h5>
          </div>
          <div className="col-xl-8">

          {!isExpanded ? (
            // <h5 className="card-title" style={{ color: "rgba(0,0,0,.54)",marginLeft: "-45%" }}>
            //  {networks.find((network, index) => isChecked[index])?.nname || "No networks targeted"}
            // </h5>
            <h5
              className="card-title"
              style={{ color: "rgba(0,0,0,.54)" }}
            >
              {/* {campaignValue !== "" ? campaignValue : camp?.name} */}
              {camp?.name}
            </h5>
          ) : null}
          </div>
          <div className="col-xl-1 d-flex align-items-center justify-content-end">

          {isExpanded ? (
            <BsChevronUp className="arrow-icon" />
          ) : (
            <BsChevronDown className="arrow-icon" />
          )}
        </div>
        </div>
        </div>
        {isExpanded && (
          <>
            <div className="card-body">
              <input
                placeholder="Sales-Search-31"
                defaultValue={camp ? camp.name : campaignValue}
                // value={camp ? camp.name : inputValue}
                style={{
                  padding: "10px",
                  paddingLeft: "20px",
                  width: "320px",
                  height: "50px",
                  borderRadius: "5px",
                  fontSize: "13px",
                  border: "1px solid #80868B", // Set initial border to transparent
                  borderColor: "#80868B", // Set initial border color
                  borderWidth: "1px", // Set initial border width
                  color: "80868B",
                  outline: "none", // Remove default outline style
                  transition: "border-color 0.3s ease-in-out", // Add transition effect
                }}
                onChange={(e) => setCampaignValue(e.target.value)}
                onFocus={(e) => {
                  e.target.style.borderColor = "#0d6efd"; // Change border color on focus
                  e.target.style.borderWidth = "2px"; // Change border width on focus
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#80868B"; // Reset border color on blur
                  e.target.style.borderWidth = "1px"; // Reset border width on blur
                }}
              />
            </div>
          </>
        )}
      </div>

      <div
        className="card"
        style={{
          // overflow: "hidden",yyy
          marginBottom: isExpanded2 ? "20px" : "0px",
          backgroundColor: isExpanded2 ? "" : "#E1E2E3",
          boxShadow: isExpanded2 ? "0 2px 8px rgba(0, 0, 0, 0.5)" : "",
          marginTop: isExpanded2 ? "20px" : "0px",
        }}
      >
        <div
          className="card-header d-flex align-items-center"
          onClick={handleToggleContent2}
          onMouseOver={() => setHeaderColor2("#E1E2E3")}
          onMouseOut={() => setHeaderColor2("")}
          style={{
            backgroundColor: headerColor2 || "#FFFFFF", // Set default color to white
            cursor: "pointer",
            border: "1px solid #ced4da",
            transition: "background-color 0.3s ease",
            minHeight: "48px",
            borderRadius: "5px",
          }}
        >
          <div
            className="row gx-xl-12 gy-5 d-flex align-items-center"
            style={{ width: "100%" }}
          >
            <div className="col-xl-3">
          <h5 className="card-title">Campaign Status</h5>
          </div>
          <div className="col-xl-8">

          {!isExpanded2 ? (
            // <h5 className="card-title" style={{ color: "rgba(0,0,0,.54)",marginLeft: "-45%" }}>
            //  {networks.find((network, index) => isChecked[index])?.nname || "No networks targeted"}
            // </h5>
            <h5
              className="card-title"
              style={{ color: "rgba(0,0,0,.54)" }}
            >
              {selectedLabel.value !== "" ? selectedLabel.value : ""}
            </h5>
          ) : null}
          </div>
          <div className="col-xl-1 d-flex align-items-center justify-content-end">

          {isExpanded2 ? (
            <BsChevronUp className="arrow-icon" />
          ) : (
            <BsChevronDown className="arrow-icon" />
          )}
        </div>
        </div>
        </div>
        {isExpanded2 && (
          <>
            {" "}
            <div className="card-body">
              {/* <div className="my-10 mx-5" style={{ position: "relative" }}> */}

              <span className="text-dark-400 mt-1 fw-semibold fs-5">
                {status_data?.map((item, Index) => {
                  return (
                    <div
                      className="d-flex align-items-center fw-bold"
                      key={Index}
                    >
                      <div
                        className="d-flex"
                        style={{ marginLeft: "30px", position: "relative" }}
                        ref={dropdownRef}
                      >
                        <div
                          style={divStyles}
                          onClick={() => setShowOptions(!showOptions)}
                        >
                          <span
                            className="d-flex align-items-center "
                            style={{
                              flex: 1,
                              padding: "0 5px",
                              color: "#5f6368",
                            }}
                          >
                            {selectedLabel.value}
                          </span>
                          <IoMdArrowDropdown
                            size={22}
                            style={{
                              marginLeft: "5px",
                              color: "#5f6368",
                            }}
                          />
                        </div>
                        {showOptions && (
                          <ul
                            style={{
                              position: "absolute",
                              top: "-20%",
                              // bottom: "0%",
                              left: "-7%",
                              width: "300px",
                              padding: "10px",
                              margin: "10px",
                              height: "200px",
                              listStyle: "none",
                              background: "#fff",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                              borderRadius: "5px",
                              zIndex: 1,
                              overflow: "auto",
                            }}
                          >
                            {options.map((item, index) => (
                              <li
                                className="d-flex align-items-center"
                                key={item.value}
                                style={{
                                  padding: "10px",
                                  cursor: "pointer",
                                  borderBottom: "0px solid #ced4da",
                                  backgroundColor:
                                    selectedOptionIndex === index
                                      ? "#E8F0FE"
                                      : hoveredOptionIndex === index
                                        ? "#EFEFEF"
                                        : "white",
                                }}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                onClick={() => handleOptionChange(item, index)}
                              >
                                {item.value === "Reviewing" && (
                                  <AiFillEye
                                    size={15}
                                    style={{
                                      color: "#07A1F7",
                                    }}
                                  />
                                )}
                                {item.value === "Paused" && (
                                  <AiFillPauseCircle
                                    size={15}
                                    style={{
                                      color: "#FFCA0E",
                                    }}
                                  />
                                )}
                                {item.value === "Live Now" && (
                                  <AiFillPlayCircle
                                    size={15}
                                    style={{
                                      color: "#73D9A2",
                                    }}
                                  />
                                )}
                                {item.value === "Completed" && (
                                  <HiCheckCircle
                                    size={15}
                                    style={{
                                      color: "#7A44EB",
                                    }}
                                  />
                                )}
                                <div className="px-5"> </div>
                                {item.label}
                              </li>
                            ))}
                          </ul>
                        )}
                        <div></div>
                      </div>
                    </div>
                  );
                })}
              </span>
              {/* </div> */}
            </div>
            {/* <div
              className="card-footer d-flex flex-end align-items-center"
              style={{ height: "40px" }}
            >
              <button
                type="button"
                className="nav-link btn btn-sm btn-active btn-active-light fw-bold px-4 d-flex flex-wrap"
                style={{ fontSize: "14px", color: "#277BE9" }}
                onMouseEnter={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "#F5F5F5";
                }}
                onMouseLeave={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "#FFFFFF";
                }}
                onClick={() => setIsExpanded2(false)}
                // onClick={() => setShowModal(false)}
                // onClick={onClick}
              >
                Cancel
              </button>
              <button
                type="button"
                className="nav-link btn btn-sm btn-active btn-active-light fw-bold px-5 d-flex flex-wrap"
                style={{ fontSize: "14px", color: "#277BE9" }}
                // onClick={handleBiding}
                // onClick={() => navigate(-1)}
                onMouseEnter={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "#F5F5F5";
                }}
                onMouseLeave={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "#FFFFFF";
                }}
                onClick={handleSubmit}
              >
                Save
              </button>
            </div> */}
          </>
        )}
      </div>

      <Location
        selectedOption={selectedOption}
        selectedTarget={selectedTarget}
        setInputValue={setInputValue}
        inputValue={inputValue}
        setSelectedOption={setSelectedOption}
        setSelectedTarget={setSelectedTarget}
        territory={territory}
        target={target}
      />

      {/* <Location
        selectedOption={camp && camp.targetLocation ? camp.targetLocation : ""}
        selectedTarget={camp && camp.targetLocation ? camp.targetLocation : ""}
        setSelectedOption={setSelectedOption}
        setSelectedTarget={setSelectedTarget}
        territory={territory}
        target={target}
      /> */}

      <Languages
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        languages={languages}
        selectedLanguages={selectedLanguages}
        setSelectedLanguages={setSelectedLanguages}
      />



      {/* budget */}

      <div
        className="card"
        style={{
          marginTop: isExpanded4 ? "10px" : "0px",
          backgroundColor: isExpanded4 ? "" : "#E1E2E3",
          boxShadow: isExpanded4 ? "0 2px 8px rgba(0, 0, 0, 0.5)" : "",
        }}
      >
        {" "}
        {isExpanded4 ? null : (
          <div
            className="card-header"
            onClick={handleToggleContent4}
            onMouseOver={() => setHeaderColor4("#E1E2E3")}
            onMouseOut={() => setHeaderColor4("")}
            style={{
              backgroundColor: headerColor4 || "#FFFFFF", // Set default color to white
              cursor: "pointer",
              border: "1px solid #ced4da",
              transition: "background-color 0.3s ease",
              minHeight: "48px",
              // borderBottom: "none",
            }}
          >
            <h5 className="card-title">Budget</h5>

            {!isExpanded4 ? (
              <h5 className="card-title" style={{ color: "rgba(0,0,0,.54)" }}>
                <MdCurrencyRupee size={18} />{" "}
                {budgetValue ? `${budgetValue}.00/day` : "0.00/day"}
              </h5>
            ) : null}

            {isExpanded4 ? (
              <BsChevronUp
                className="arrow-icon"
                style={{ marginTop: "20px" }}
              />
            ) : (
              <BsChevronDown
                className="arrow-icon"
                style={{ marginTop: "20px" }}
              />
            )}
          </div>
        )}
        {isExpanded4 && (
          <div className="card-body">
            <div className="row gx-xl-12 gy-10">
              <div className="col-xl-7">
                {/* <KTCard className="card h-lg-100 col-xxl-12"> */}
                <div
                  className="container p-1"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "10px",
                  }}
                >
                  <div className="row gy-5 gx-xl-10">
                    <div className="d-flex align-items-center">
                    {/* <span
                        className="fw-bold text-dark"
                        style={{ fontSize: "14px", color: "#3C4043" }}
                      >
                        
                       Budget and dates
                      </span> */}
                      <span
                        className="fw text-dark"
                        style={{ fontSize: "14px", color: "#3C4043",marginLeft:"30px" }}
                      >
                        
                       Enter budget type and amount
                      </span>
                    </div>
                    <div className="flex-wrap align-items-center">
                      <div className=" flex-wrap align-items-center">
                        <div style={{ position: "relative",marginLeft:"30px" }}>
                        <input
                
                defaultValue={camp ? camp.budget : budgetValue}
                type="number"
                // value={camp ? camp.name : inputValue}
                style={{
                  padding: "10px",
                  paddingLeft: "40px",
                  width: "180px",
                  height: "50px",
                  borderRadius: "5px",
                  fontSize: "13px",
                  border: "1px solid #80868B", // Set initial border to transparent
                  borderColor: "#80868B", // Set initial border color
                  borderWidth: "1px", // Set initial border width
                  color: "80868B",
                  outline: "none", // Remove default outline style
                  transition: "border-color 0.3s ease-in-out", // Add transition effect
                }}
                onChange={(e) => setCampaignValue(e.target.value)}
                onFocus={(e) => {
                  e.target.style.borderColor = "#0d6efd"; // Change border color on focus
                  e.target.style.borderWidth = "2px"; // Change border width on focus
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#80868B"; // Reset border color on blur
                  e.target.style.borderWidth = "1px"; // Reset border width on blur
                }}
              />
                          <div
                            className="px-4"
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "3px",
                              transform: "translateY(-50%)",
                              color: "gray",
                              pointerEvents: "none", // Ignore pointer events to allow clicking the input
                            }}
                          >
                            <MdCurrencyRupee size={15} />
                          </div>
                          {/* {showPopup && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "60%",
                                    right: "80%",
                                    backgroundColor: "#fff",
                                    padding: "5px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0)",
                                    zIndex: "2",
                                    width: "110px",
                                    height: "30px",
                                    fontSize: "10px",
                                    marginLeft: "500px",
                                    color: "#333",
                                  }}
                                >
                                  <div className="d-flex align-items-center ">
                                    Please fill this field
                                  </div>
                                </div>
                              )} */}
                        </div>
                        <div
                          className="mt-3 ml-5 px-5"
                          style={{ color: "#d93025" }}
                        >
                          {formik.errors.inputValue}
                        </div>
                        {budgetValue && parseInt(budgetValue) < 500 && (
                          <div
                            className="d-flex"
                            style={{
                              backgroundColor: "#FDF7E0",
                              width: "105%",
                              padding: "20px",
                              margin: "13px",
                              justifyContent: "space-between",
                              height: "80px",
                              fontSize: "14px",
                              color: "3C4043",
                            }}
                          >
                            <img
                              src={warning}
                              style={{
                                height: "38%",
                                width: "5%",
                                marginRight: "10px",
                              }}
                            />
                            Advertisers in your country (India) commonly use a
                            budget of at least ₹500. Consider increasing your
                            budget to improve your campaign's potential reach.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* </KTCard> */}
              </div>
              <div className="col-xl-1 d-flex align-items-center justify-content-center">
                <div
                  className="vertical-line"
                  style={{
                    width: "0.7px",
                    height: "100%",
                    backgroundColor: "#ccc",
                    marginLeft: "85px",
                  }}
                />
              </div>

              <div className="col-xl-3 pl-0 d-block">
                <span
                  className=""
                  style={{ fontSize: "12px", color: "3C4043", }}
                >
                  For the month, you won't pay more than your daily budget
                  times the average number of days in a month. Some days you
                  might spend less than your daily budget, and on others you
                  might spend up to twice as much. <a href="#"> Learn more</a>
                </span>
              </div>
              {isExpanded4 && (
                <div className="col-xl-1">
                  <BsChevronUp
                    className="arrow-icon"
                    onClick={handleToggleContent4}
                    style={{ marginTop: "5px" }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {/* <Languages
 selectedLanguage={camp && camp.targetLanguage ? camp.targetLanguage : ""}
 setSelectedLanguage={setSelectedLanguage}
 languages={languages}
 selectedLanguages={camp && camp.targetLanguage ? camp.targetLanguage : ""}
 setSelectedLanguages={setSelectedLanguages}
 /> */}

      {/* <Dates
 selectedStartDate={startDate}
 selectedEndDate={endDate}
 setSelectedStartDate={setSelectedStartDate}
 setSelectedEndDate={setSelectedEndDate}
 /> */}
      <Dates
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        setSelectedStartDate={setSelectedStartDate}
        setSelectedEndDate={setSelectedEndDate}
      />
      {/* <AdSchedule schedules={schedules} setSchedules={setSchedules} /> */}
      <div
        className="my-10 d-flex flex-end align-items-center"
        style={{ height: "40px" }}
      >
        <button
          type="button"
          className="nav-link btn btn-sm btn-active btn-active-light fw-bold px-4 d-flex flex-wrap"
          style={{ fontSize: "14px", color: "#277BE9" }}
          onMouseEnter={(e) => {
            (e.target as HTMLButtonElement).style.backgroundColor = "#F5F5F5";
          }}
          onMouseLeave={(e) => {
            (e.target as HTMLButtonElement).style.backgroundColor = "#FFFFFF";
          }}
          onClick={() => setIsExpanded(false)}
        // onClick={() => setShowModal(false)}
        // onClick={onClick}
        >
          Cancel
        </button>
        <button
          type="button"
          className="nav-link btn btn-sm btn-active btn-active-light fw-bold px-5 d-flex flex-wrap"
          style={{ fontSize: "14px", color: "#277BE9" }}
          // onClick={handleBiding}
          // onClick={() => navigate(-1)}
          onMouseEnter={(e) => {
            (e.target as HTMLButtonElement).style.backgroundColor = "#F5F5F5";
          }}
          onMouseLeave={(e) => {
            (e.target as HTMLButtonElement).style.backgroundColor = "#FFFFFF";
          }}
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>
    </>
  );
}

export default Edit;
