import React, { useEffect, useLayoutEffect, useState } from "react";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

import { KTCard } from "../../../../theme/helpers";
import { useNavigate } from "react-router-dom";
import { getDataFromBackend, URLS } from "../../../modules/dashboards/DashboardRequests";
import CustomLoader from "../marketting_component/CustomLoader";
import Modal from "../marketting_component/Modal";
import { AiOutlineZoomIn } from "react-icons/ai";

function AmChartHeatMapChart(
    {
        chartID, platformId, productId, startDate, endDate, channelType, campaignId
    }
) {
    //const chart = useRef(null);
    const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);

    // const myChartID = chartID;
    const myChartId = isZoomModalOpen ? `${chartID}-modal` : chartID;

    const title = "Heat Map";
    const navigate = useNavigate();
    const isdash = window.location.href;
    const [activeTab, setActiveTab] = useState('day');
    const status_data = [
        {
            dropdownData: [
                "Impression",
                "Clicks",
                "CTR",
                "Avg.CPC",
                "Cost",
                "Conversions",
                "Conv.value",
                "Cost/conv",
                "Conv.rate",
                "Avg.CPM",
                "Avg.CPV",
            ],
        },
    ];

    const [selectedItem, setSelectedItem] = useState("Impression");

    type HeatMapData = {
        dataFor: string;
        data: {
            hour: string;
            weekday: string;
            value: number;
        }[];
    };

    const [selectedHeatMap, setSelectedHeatMap] = useState<HeatMapData[]>([]);
    const [mergedData, setMergedData] = useState<{ hour: string; weekday: string; value: number; }[]>([]);

    useEffect(() => {
        const getData = async () => {
            let requestData = {
                productId,
                platformId,
                startDate,
                endDate,
                campaignId
            }
            let response = await getDataFromBackend(requestData, URLS.GET_CAMPAIGN_PERFORMANCEBYDAY);
            console.log("Response GET_CAMPAIGN_PERFORMANCEBYDAY: ", response?.data);
            // setSelectedHeatMap(response?.data);
            if (response?.data) {
                setSelectedHeatMap(response?.data);
            } else {
                return
            }

        };
        getData(); // run it, run it
        return () => {
            // root?.dispose();
            // this now gets called when the component unmounts

        };
    }, [platformId, productId, campaignId, startDate, endDate]);

    const handleDropdownItemClick = (item, data) => {
        console.log(item, data);
        setSelectedItem(data);
    };

    useEffect(() => {
        const filteredData = selectedHeatMap.find(item => item.dataFor === selectedItem)?.data || [];
        setMergedData(filteredData)
    }, [selectedItem, selectedHeatMap])


    useLayoutEffect(() => {
        let root = am5.Root.new(myChartId);
        root._logo?.dispose();
        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
            am5themes_Animated.new(root)
        ]);


        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none",
            layout: root.verticalLayout
        }));


        // Create axes and their renderers
        let yRenderer = am5xy.AxisRendererY.new(root, {
            visible: false,
            minGridDistance: 20,
            inversed: true
        });

        yRenderer.grid.template.set("visible", false);

        let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0,
            renderer: yRenderer,
            categoryField: "weekday"
        }));

        let xRenderer = am5xy.AxisRendererX.new(root, {
            visible: false,
            minGridDistance: 30,
            opposite: false
        });

        xRenderer.grid.template.set("visible", false);

        let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            renderer: xRenderer,
            categoryField: "hour"
        }));
        xAxis.get("renderer").labels.template.setAll({ visible: selectedHeatMap?.length > 0 ? true : false });
        yAxis.get("renderer").labels.template.setAll({ visible: selectedHeatMap?.length > 0 ? true : false });



        // Create series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/#Adding_series
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
            calculateAggregates: true,
            stroke: am5.color(0xffffff),
            clustered: false,
            xAxis: xAxis,
            yAxis: yAxis,
            categoryXField: "hour",
            categoryYField: "weekday",
            valueField: "value"
        }));

        series.columns.template.setAll({
            tooltipText: selectedItem==='Cost'|| selectedItem==='Avg.CPC'|| selectedItem==='Avg.CPM'|| selectedItem==='Avg.CPV'?"₹ {value}":selectedItem==='CTR'?"{value}%":"{value}",
            strokeOpacity: 1,
            strokeWidth: 2,
            width: am5.percent(100),
            height: am5.percent(100)
        });

        series.columns.template.events.on("pointerover", function (event) {
            let di = event.target.dataItem;
            if (di) {
                // heatLegend.showValue(di.get("value", 0));
            }
        });

        // series.events.on("datavalidated", function () {
        //     heatLegend.set("startValue", series.getPrivate("valueHigh"));
        //     heatLegend.set("endValue", series.getPrivate("valueLow"));
        // });


        // Set up heat rules
        // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
        series.set("heatRules", [{
            target: series.columns.template,
            min: am5.color("#fde5d2"),
            max: am5.color("#E84A42"),
            dataField: "value",
            key: "fill"
        }]);


        // Add heat legend
        // https://www.amcharts.com/docs/v5/concepts/legend/heat-legend/
        // let heatLegend = chart.bottomAxesContainer.children.push(am5.HeatLegend.new(root, {
        //     orientation: "horizontal",
        //     startColor: am5.color("#fde5d2"),
        //     endColor: am5.color("#E84A42"),
        // }));


        // Set data
        // https://www.amcharts.com/docs/v5/charts/xy-chart/#Setting_data
        let data = mergedData
        series.data.setAll(data);

        yAxis.data.setAll([
            { weekday: "Sunday" },
            { weekday: "Monday" },
            { weekday: "Tuesday" },
            { weekday: "Wednesday" },
            { weekday: "Thursday" },
            { weekday: "Friday" },
            { weekday: "Saturday" }
        ]);

        xAxis.data.setAll([
            { hour: "12pm" },
            { hour: "1am" },
            { hour: "2am" },
            { hour: "3am" },
            { hour: "4am" },
            { hour: "5am" },
            { hour: "6am" },
            { hour: "7am" },
            { hour: "8am" },
            { hour: "9am" },
            { hour: "10am" },
            { hour: "11am" },
            { hour: "12am" },
            { hour: "1pm" },
            { hour: "2pm" },
            { hour: "3pm" },
            { hour: "4pm" },
            { hour: "5pm" },
            { hour: "6pm" },
            { hour: "7pm" },
            { hour: "8pm" },
            { hour: "9pm" },
            { hour: "10pm" },
            { hour: "11pm" }
        ]);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
        chart.appear(1000, 100);

        // end am5.ready()
        return () => {
            root.dispose()
        }
    }, [myChartId, mergedData, selectedHeatMap]);
    console.log("wwwwwwwwwww", selectedHeatMap)


    return (
        <div className="card card-flush h-lg-100 mb-10" style={{ boxShadow: '0 2px 8px rgba(0, 0, 0, 0.5)' }}>
            <div className='pe-5' style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div className="card-header">
                    <div className="card-title d-flex align-items-center justify-content-between">
                        <span className="card-label fw-bold text-dark fs-6">Your Performance by day and time</span>
                    </div>
                </div>
                {status_data?.map((item) => (
                    <div className="d-flex align-items-center fw-bold flex-end px-3" >
                        {/* <span className="text-muted fs-7">{item.name}</span> */}
                        <div className="dropdown">
                            <div
                                data-kt-daterangepicker="true"
                                data-kt-daterangepicker-opens="left"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary d-flex align-items-center px-4 dropdown-toggle"
                            >
                                {selectedItem || "Impression"} {/* Display selected item or "Select" */}
                            </div>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                {item.dropdownData?.map((data) => (
                                    <li key={data} onClick={() => handleDropdownItemClick(item, data)}>
                                        <span className="dropdown-item text-hover-primary">{data}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
                <AiOutlineZoomIn
                            size={40}
                            style={{ borderRadius: "50px", padding: "10px", cursor: "pointer" }}
                            onMouseEnter={(e) => {
                                (e.target as HTMLButtonElement).style.backgroundColor =
                                    "rgb(230,236,250)";
                            }}
                            onMouseLeave={(e) => {
                                (e.target as HTMLButtonElement).style.backgroundColor = "";
                            }}
                            onClick={() => setIsZoomModalOpen(!isZoomModalOpen)}
                        />
            </div>
            {/* {selectedHeatMap?.length > 1 ?
                <>
                    <ul className="nav nav-tabs card-toolbar nav-fill">

                        <li className="nav-item">
                            <a className={`nav-link ${activeTab === 'dayHour' ? 'active' : ''}`}
                                style={{ borderBottom: activeTab === 'dayHour' ? '2px solid #5F63F2' : '2px solid transparent', fontWeight: 'bold', color: activeTab === 'dayHour' ? '#5F63F2' : 'inherit' }}
                                href="#dayHour" data-bs-toggle="tab"
                                onClick={() => setActiveTab('dayHour')}>
                                Day & Hour
                            </a>
                        </li>
                    </ul>
                    <div id={chartID} style={{ height: "380px", width: "100%" }}></div>
                </>
                :
                <>
                    <div id={chartID} style={{ alignSelf: 'center', marginBottom: '30px', }}>
                        <CustomLoader />
                    </div>
                </>
            } */}
            {selectedHeatMap?.length <= 0 ? (
                <div id={myChartId} style={{ alignSelf: 'center', marginBottom: '30px', }}>
                    <CustomLoader />
                </div>
            ) : !isZoomModalOpen ? (
                <>
                    <ul className="nav nav-tabs card-toolbar nav-fill">

                        <li className="nav-item">
                            <a className={`nav-link ${activeTab === 'dayHour' ? 'active' : ''}`}
                                style={{ borderBottom: activeTab === 'dayHour' ? '2px solid #5F63F2' : '2px solid transparent', fontWeight: 'bold', color: activeTab === 'dayHour' ? '#5F63F2' : 'inherit' }}
                                href="#dayHour" data-bs-toggle="tab"
                                onClick={() => setActiveTab('dayHour')}>
                                Day & Hour
                            </a>
                        </li>
                    </ul>
                    <div id={myChartId} style={{ height: "380px", width: "100%" }}></div>
                </>
            ) : (
                <Modal
                    isOpen={isZoomModalOpen}
                    onClose={() => setIsZoomModalOpen(!isZoomModalOpen)}
                    title={title}
                >
                    <>
                        <ul className="nav nav-tabs card-toolbar nav-fill">

                            <li className="nav-item">
                                <a className={`nav-link ${activeTab === 'dayHour' ? 'active' : ''}`}
                                    style={{ borderBottom: activeTab === 'dayHour' ? '2px solid #5F63F2' : '2px solid transparent', fontWeight: 'bold', color: activeTab === 'dayHour' ? '#5F63F2' : 'inherit' }}
                                    href="#dayHour" data-bs-toggle="tab"
                                    onClick={() => setActiveTab('dayHour')}>
                                    Day & Hour
                                </a>
                            </li>
                        </ul>
                        <div id={myChartId} style={{ height: "600px", width: "100%" }}></div>
                    </>
                </Modal>
            )}
        </div>
    );
}
export default AmChartHeatMapChart;
