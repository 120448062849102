import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { KTCard } from "../../../../theme/helpers";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsCurrencyRupee } from "react-icons/bs";
import { BsExclamationCircle } from "react-icons/bs";
import { MdSettings } from "react-icons/md";

import { useNavigate } from "react-router-dom";
import search from "../../../../assets/search.png";
import display from "../../../../assets/display.png";

import { FaTimes } from "react-icons/fa";
import { Badge } from "react-bootstrap";
import CampaignSettings from "./CampaignSettings";

const AdRotation = ({ selectedRotation, setSelectedRotation, rotation }) => {
  // const rotation = [
  //   {
  //     id: "1",
  //     tname: "Optimize: Prefer best performing ads",
  //   },
  //   {
  //     id: "2",
  //     tname: "Do not optimize: Rotate ads indefinitely",
  //   },
  //   {
  //     id: "3",
  //     tname: "Optimize for conversions (Not supported)",
  //   },
  //   {
  //     id: "4",
  //     tname: "Rotate evenly (Not supported)",
  //   },
  // ];
  const [isExpanded, setIsExpanded] = useState(false);

  const [headerColor, setHeaderColor] = useState("");

  const [type, setType] = useState<string>("Conversions");
  const [status, setStatus] = useState<string>("Conversions");
  const [isChecked, setIsChecked] = useState(true);
  const [isChecked2, setIsChecked2] = useState(true);
  // const [selectedRotation, setSelectedRotation] = useState(
  //   rotation[0]?.tname || ""
  // );

  const navigate = useNavigate();

  const handleOptionChange = (event) => {
    const newSelectedRotation = event.target.value;
    setSelectedRotation(newSelectedRotation);
  };

  const handleToggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div
        className="card"
        style={{
          marginTop: isExpanded ? "10px" : "0px",
          backgroundColor: isExpanded ? "" : "#E1E2E3",
          boxShadow: isExpanded ? "0 2px 8px rgba(0, 0, 0, 0.5)" : "",
        }}
      >
        <div
          className="card-header"
          onClick={handleToggleContent}
          onMouseOver={() => setHeaderColor("#E1E2E3")}
          onMouseOut={() => setHeaderColor("")}
          style={{
            backgroundColor: headerColor || "#FFFFFF", // Set default color to white
            cursor: "pointer",
            border: "1px solid #ced4da",
            transition: "background-color 0.3s ease",
            minHeight: "48px",
          }}
        >
          <div
            className="row gx-xl-12 gy-5 d-flex align-items-center"
            style={{ width: "100%" }}
          >
            <div className="col-xl-3">
              <h5 className="card-title">Ad Rotation</h5>
            </div>
            <div className="col-xl-8">
              {!isExpanded ? (
                <h5 className="card-title" style={{ color: "rgba(0,0,0,.54)" }}>
                  {selectedRotation}
                </h5>
              ) : null}
            </div>
            <div className="col-xl-1 d-flex align-items-center justify-content-end">
              {isExpanded ? (
                <BsChevronUp
                  className="arrow-icon"
                  style={{ marginTop: "20px" }}
                />
              ) : (
                <BsChevronDown
                  className="arrow-icon"
                  style={{ marginTop: "20px" }}
                />
              )}
            </div>
          </div>
        </div>
        {isExpanded && (
          <div className="card-body">
            <div className="row gx-xl-12 gy-10">
              <div className="col-xl-8">
                <div
                  className="container p-1"
                  style={{ backgroundColor: "white", borderRadius: "10px" }}
                >
                  <div className="row gy-5 gx-xl-10">
                    {rotation.map((item) => (
                      <div key={item.id}>
                        <label>
                          <input
                            type="radio"
                            name="rotation"
                            value={item.tname}
                            checked={selectedRotation === item.tname}
                            onClick={handleOptionChange}
                            disabled={item.id === "3" || item.id === "4"}
                          />
                          <span
                            style={{
                              marginLeft: "5px",
                              color:
                                item.id === "3" || item.id === "4"
                                  ? "gray"
                                  : "inherit",
                            }}
                          >
                            {item.tname}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                {selectedRotation ===
                  "Do not optimize: Rotate ads indefinitely" && (
                  <div
                    className="card my-9 px-5"
                    style={{
                      border: "1px solid gray",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div className="row gy-5 gx-xl-12">
                      <div className="col-xl-10 p-3">
                        <span
                          className="text-dark"
                          style={{ fontSize: "14px", textAlign: "left" }}
                        >
                          <b>Use optimized ad rotation: </b>
                          Automatically show your best ads at auction time
                          <AiOutlineQuestionCircle size={15} />
                        </span>
                      </div>
                      <div className="col-xl-2 d-flex align-items-center justify-content-center">
                        <button
                          className="btn btn-light"
                          style={{
                            backgroundColor: "transparent",
                            color: "#007bff",
                            border: "none",
                            transition: "background-color 0.3s",
                            fontSize: "15px",
                          }}
                          onClick={() =>
                            setSelectedRotation(
                              "Optimize: Prefer best performing ads"
                            )
                          }
                          // onClick={() => handleApply("search")}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* <div className="col-xl-1 d-flex align-items-center justify-content-center"> */}
              <div className="col-xl-4 d-flex">
                <div
                  className="vertical-line"
                  style={{
                    width: "0.7px",
                    height: "100%",
                    backgroundColor: "#ccc",
                    marginLeft: "20px",
                  }}
                />
                {/* </div> */}

                {selectedRotation ===
                  "Optimize: Prefer best performing ads" && (
                  <div
                    className=""
                    style={{
                      fontSize: "13px",
                      color: "3C4043",
                      marginLeft: "60px",
                    }}
                  >
                    Show ads that are expected to get more clicks or
                    conversions. Recommended for most advertisers.
                  </div>
                )}
                {selectedRotation ===
                  "Do not optimize: Rotate ads indefinitely" && (
                  <div
                    className=""
                    style={{
                      fontSize: "12px",
                      color: "3C4043",
                      marginLeft: "20px",
                    }}
                  >
                    Show ads more evenly for an indefinite amount of time.
                    <br />
                    <br />
                    When using Smart Bidding, Google Ads will optimize your ad
                    rotation to prefer the best performing ads even if it's
                    currently set to "Rotate indefinitely".
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AdRotation;
