/** @format */

import { FC, useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import clsx from "clsx";
import { AddSource, editSource } from "../../../../application/_requests";
import { useListView } from "../core/ListViewProvider";
import { Doc } from "../core/_models";

import { useSourceQueryResponse } from "../core/QueryResponseProvider";
import { useQueryClient } from "react-query";
import { useQueryRequest } from "../core/QueryRequestProvider";
import { stringifyRequestQuery } from "../../../../../../theme/helpers";
import './EditSourceModalCSS.scss';

const validationSchema = Yup.object().shape({
    subject: Yup.string()
        .trim()
        .required("Subject is required")
        .min(3, "Enter at least 3 characters")
        .max(60, "Maximum 60 characters allowed")
        .matches(/^\S+.*\S+$/, "Leading and trailing spaces are not allowed"),

    name: Yup.string()
        .trim()
        .required("Name is required")
        .min(3, "Enter at least 3 characters")
        .max(60, "Maximum 60 characters allowed")
        .matches(/^\S+.*\S+$/, "Leading and trailing spaces are not allowed"),
})

const EditSourceModalForm: FC<any> = ({ user, isUserLoading, onCancel }) => {
    // console.log('user in source', user)
    const btnRef = useRef<HTMLButtonElement | null>(null);
    const queryClient = useQueryClient();
    const { setItemIdForUpdate } = useListView();
    const { setToastMessage } = useListView();
    const cancel = (withRefresh?: boolean) => {
        setItemIdForUpdate(undefined);
    };

    const { state } = useQueryRequest();
    const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
    const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
    const [type, settype] = useState("password");
    useEffect(() => {
        if (query !== updatedQuery) {
            setQuery(updatedQuery);
        }
    }, [updatedQuery]);

    const { refetch } = useSourceQueryResponse();

    const HandleSubmit = async (values: any, { setSubmitting }) => {
        btnRef.current?.setAttribute("data-kt-indicator", "on");
        // console.log('values in source', values)

        const docRequest: Doc = {
            documentType: "DRAFT",
            // draftId: "",
            subject: values.subject.trim(),
            documentName: values.name.trim(),
            // category: null,
            // organization: null,
        };

        setSubmitting(true)
        try {
            await editSource(docRequest, user?.draftId)
            setToastMessage("Source Updated Successfully")
            cancel(true)
            setTimeout(() => {
                setToastMessage(undefined)
            }, 2000)
            queryClient.invalidateQueries([`GET_SOURCE_LIST-${query}`]);
        } catch (error) {
            console.log(error)
        }
        finally {
            setSubmitting(true)
        }
    };

    const formik = useFormik({
        initialValues: {
            name: `${user.docName}`,
            subject: `${user.draftSubject}`,
            fileName: undefined,
        },

        validationSchema: validationSchema,
        // validateOnBlur: false,
        onSubmit: async (values, { setSubmitting }) => {
            // console.log('source form data', values)
            HandleSubmit(values, { setSubmitting });
        },
    });

    return (
        <>
            <form
                id="kt_modal_add_user_form"
                className="form"
                onSubmit={formik.handleSubmit}
                noValidate
            >
                <div
                    className="d-flex flex-column scroll-y me-n7 pe-7"
                    id="kt_modal_add_user_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_user_header"
                    data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                    data-kt-scroll-offset="300px"
                >
                    <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2"> Subject</label>
                        <input
                            placeholder="Subject"
                            //className="form-select form-select-solid"
                            //aria-label="Select example"
                            {...formik.getFieldProps("subject")}
                            type="text"
                            name="subject"
                            // value={undefined}
                            className={clsx(
                                "form-control form-control-light mb-3 mb-lg-0",
                                {
                                    "is-invalid": formik.touched.subject && formik.errors.subject,
                                },
                                {
                                    "is-valid": formik.touched.subject && !formik.errors.subject,
                                }
                            )}
                            autoComplete="off"
                        ></input>
                        {formik.touched.subject && formik.errors.subject && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">{formik.errors.subject}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">Name</label>
                        <input
                            placeholder="Name"
                            {...formik.getFieldProps("name")}
                            type="text"
                            name="name"
                            // value={undefined}
                            className={clsx(
                                "form-control form-control-light mb-3 mb-lg-0",
                                { "is-invalid": formik.touched.name && formik.errors.name },
                                {
                                    "is-valid": formik.touched.name && !formik.errors.name,
                                }
                            )}
                            autoComplete="off"
                        />
                        {formik.touched.name && formik.errors.name && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">{formik.errors.name}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">File</label>
                        <label className="form-control custom-file-upload p-0" title={user?.fileName}>
                            <button className="form-control disabled chooseFileBtn" type="button">Choose File</button>
                            <span className="fileName">{user?.fileName}</span>
                        </label>
                    </div>

                    <div className="d-flex justify-content-center">
                        <button
                            type="reset"
                            className="btn btn-light mx-10"
                            data-kt-users-modal-action="cancel"
                            onClick={onCancel}
                            title="Discard"
                        >
                            Discard
                        </button>

                        <button
                            ref={btnRef}
                            type="submit"
                            className="btn  me-10 text-white btn-custom"
                            id="kt_button_1"
                            data-kt-users-modal-action="submit"
                            title="Update"
                        >
                            <span className="indicator-label ">Update</span>
                            <span className="indicator-progress  ">
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </div>

            </form>
        </>
    );
};

export { EditSourceModalForm };
