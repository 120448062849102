import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { KTCard } from "../../../../theme/helpers";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsCurrencyRupee } from "react-icons/bs";
import { BsExclamationCircle } from "react-icons/bs";
import { MdSettings } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useNavigate } from "react-router-dom";
import search from "../../../../assets/search.png";
import display from "../../../../assets/display.png";

import { FaPlus, FaTimes } from "react-icons/fa";
import { Badge } from "react-bootstrap";

const CampaignURL = ({inputValues,setInputValues,inputValue2,setInputValue2,inputValue,setInputValue}) => {
 
  console.log(inputValues,"inputValue")
  const [isExpanded, setIsExpanded] = useState(false);

  const [headerColor, setHeaderColor] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [inputvalid, setInputvalid] = useState(true);

  // const [inputValue, setInputValue] = useState("");
  // const [inputValue2, setInputValue2] = useState("");

  const [inputValue3, setInputValue3] = useState("");

  const [isFocused, setIsFocused] = useState(false);

  const navigate = useNavigate();
  const formattedInputValue = `{_${inputValue3}}`;
  const handleToggleContent = () => {
    setIsExpanded(!isExpanded);
  };
  //   const handleInputChange = (e) => {
  //     const inputValue3 = e.target.value;
  //     setInputValue3(inputValue3);
  //     e.target.style.borderColor = "#0d6efd";
  //   };


  // const [inputValues, setInputValues] = useState([{ name: "", value: "" }]);
  // console.log(inputValues,"namevalue")

  const handleInputChange = (index, field, value) => {
    const updatedValues = [...inputValues];
    updatedValues[index][field] = value;
    setInputValues(updatedValues);
  };

  const handleAddInput = () => {
    setInputValues([...inputValues, { name: "", value: "" }]);
  };

  return (
    <>
      <div
        className="card"
        style={{
          marginTop: isExpanded ? "10px" : "0px",
          backgroundColor: isExpanded ? "" : "#E1E2E3",
          boxShadow: isExpanded ? "0 2px 8px rgba(0, 0, 0, 0.5)" : "",
        }}
      >
        <div
          className="card-header"
          onClick={handleToggleContent}
          onMouseOver={() => setHeaderColor("#E1E2E3")}
          onMouseOut={() => setHeaderColor("")}
          style={{
            backgroundColor: headerColor || "#FFFFFF", // Set default color to white
            cursor: "pointer",
            border: "1px solid #ced4da",
            transition: "background-color 0.3s ease",
            minHeight: "48px",
          }}
        >
          <div
            className="row gx-xl-12 gy-5 d-flex align-items-center"
            style={{ width: "100%" }}
          >
            <div className="col-xl-3">
          <h5 className="card-title">Campaign URL options</h5>
          </div>
          <div className="col-xl-8">

          {!isExpanded ? (
            <h5
              className="card-title"
              style={{ color: "rgba(0,0,0,.54)" }}
            >
              {inputValue ||
              inputValue2 ||
              inputValues.some((input) => input.name || input.value)
                ? "Using URL tracking options"
                : "No options set"}
            </h5>
          ) : null}
          </div>
          <div className="col-xl-1 d-flex align-items-center justify-content-end">

          {isExpanded ? (
            <BsChevronUp className="arrow-icon" style={{ marginTop: "20px" }} />
          ) : (
            <BsChevronDown
              className="arrow-icon"
              style={{ marginTop: "20px" }}
            />
          )}
          </div>
          </div>
        </div>
        {isExpanded && (
          <div className="card-body">
            <div className="row gx-xl-12 gy-10">
              <div className="col-xl-8">
                <div
                  className="container p-1"
                  style={{ backgroundColor: "white", borderRadius: "10px" }}
                >
                  <div className="row gy-5 gx-xl-10">
                    <div style={{ position: "relative", marginTop: "7px" }}>
                      <input
                        type="text"
                        style={{
                          padding: "5px",
                          paddingLeft: "10px",
                          width: "52%",
                          height: "50px",
                          borderRadius: "5px",
                          fontSize: "13px",
                          border: "1px solid #3c4043", // Set initial border color
                          borderColor: "#3c4043", // Set initial border color
                          borderWidth: "1px", // Set initial border width
                          color: "black",
                          outline: "none", // Remove default outline style
                          transition: "border-color 0.3s ease-in-out", // Add transition effect
                        }}
                        value={inputValue}
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          e.target.style.borderColor = "#0d6efd"; // Set border color to initial color if input is empty or dot is present with text before and after

                          setInputValue(inputValue);
                        }}
                        onFocus={(e) => {
                          const inputValue = e.target.value;
                          const dotIndex = inputValue.indexOf(".");

                          e.target.style.borderColor = "#0d6efd"; // Set border color to initial color if input is empty or dot is present with text before and after

                          e.target.style.borderWidth = "2px"; // Change border width on focus
                        }}
                        onBlur={(e) => {
                          const inputValue = e.target.value;

                          e.target.style.borderColor = "#3c4043"; // Reset border color on blur

                          e.target.style.borderWidth = "1px"; // Reset border width on blur
                        }}
                      />
                      <label
                        className="px-3"
                        style={{
                          position: "absolute",
                          left: "30px",
                          top: inputValue !== "" ? "-7px" : "15px",
                          backgroundColor: "white",
                          transition: "all 0.3s ease-in-out",
                          pointerEvents: "none",
                          fontSize: inputValue !== "" ? "12px" : "13px",
                          color: inputValue !== "" ? "#0d6efd" : "#5f6368",
                        }}
                      >
                        Tracking Template
                      </label>

                      <div
                        className="px-4"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "17px",
                          transform: "translateY(-50%)",
                          color: "#5F6368",
                          pointerEvents: "none", // Ignore pointer events to allow clicking the input
                        }}
                      >
                        {/* <IoMdLink size={25} /> */}
                      </div>
                    </div>

                    <div
                      className="px-7"
                      style={{
                        color: "#3c4043",
                        fontSize: "13px",
                        marginTop: "5px",
                      }}
                    >
                      Example: https://www.trackingtemplate.foo/?url=lurl&id=5
                    </div>
                  </div>

                  {/* <div className="row gy-5 gx-xl-10"> */}
                  <div style={{ position: "relative", marginTop: "7px" }}>
                    <input
                      type="text"
                      style={{
                        padding: "5px",
                        paddingLeft: "10px",
                        width: "52%",
                        height: "50px",
                        borderRadius: "5px",
                        fontSize: "13px",
                        border: "1px solid #3c4043", // Set initial border color
                        borderColor: "#3c4043", // Set initial border color
                        borderWidth: "1px", // Set initial border width
                        color: "black",
                        outline: "none", // Remove default outline style
                        transition: "border-color 0.3s ease-in-out", // Add transition effect
                      }}
                      value={inputValue2}
                      onChange={(e) => {
                        const inputValue2 = e.target.value;

                        e.target.style.borderColor = "#0d6efd"; // Set border color to initial color if input is empty or dot is present with text before and after

                        setInputValue2(inputValue2);
                      }}
                      onFocus={(e) => {
                        const inputValue2 = e.target.value;
                        e.target.style.borderColor = "#0d6efd"; // Set border color to initial color if input is empty or dot is present with text before and after

                        e.target.style.borderWidth = "2px"; // Change border width on focus
                      }}
                      onBlur={(e) => {
                        const inputValue2 = e.target.value;

                        {
                          e.target.style.borderColor = "#3c4043"; // Reset border color on blur
                        }

                        e.target.style.borderWidth = "1px"; // Reset border width on blur
                      }}
                    />
                    <label
                      className=""
                      style={{
                        position: "absolute",
                        left: "23px",
                        top: inputValue2 !== "" ? "-7px" : "15px",
                        backgroundColor: "white",
                        transition: "all 0.3s ease-in-out",
                        pointerEvents: "none",
                        fontSize: inputValue2 !== "" ? "12px" : "13px",
                        color: inputValue2 !== "" ? "#0d6efd" : "#5f6368",
                      }}
                    >
                      Final URL suffix
                    </label>

                    <div
                      className="px-4"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "17px",
                        transform: "translateY(-50%)",
                        color: "#5F6368",
                        pointerEvents: "none", // Ignore pointer events to allow clicking the input
                      }}
                    >
                      {/* <IoMdLink size={25} /> */}
                    </div>
                  </div>

                  <div
                    className="px-2"
                    style={{
                      color: "#3c4043",
                      fontSize: "13px",
                      marginTop: "5px",
                    }}
                  >
                    Example: param1=value1&param2=value2
                  </div>

                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        position: "relative",
                        marginTop: "40px",
                        marginRight: "20px",
                      }}
                    >
                      <div
                        className="px-2"
                        style={{ fontSize: "14px", marginTop: "5px" }}
                      >
                        Custom parameters
                      </div>
                      <div className="d-flex">
                        <div style={{ marginRight: "10px" }}>
                          {inputValues.map((input, index) => (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                width: "60%",
                                marginTop: index === 0 ? "0" : "20px",
                              }}
                            >
                              <input
                                type="text"
                                placeholder="Name"
                                style={{
                                  padding: "5px",
                                  paddingLeft: "25px",
                                  width: "100%",
                                  height: "50px",
                                  borderRadius: "5px",
                                  fontSize: "13px",
                                  border: "1px solid #3c4043",
                                  borderColor: "#3c4043",
                                  borderWidth: "1px",
                                  color: "black",
                                  outline: "none",
                                  transition: "border-color 0.3s ease-in-out",
                                }}
                                value={input.name}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "name",
                                    e.target.value
                                  )
                                }
                                onFocus={(e) => {
                                  const inputValue3 = e.target.value;
                                  e.target.style.borderColor = "#0d6efd";
                                  e.target.style.borderWidth = "2px";
                                }}
                                onBlur={(e) => {
                                  const inputValue3 = e.target.value;
                                  e.target.style.borderColor = "#3c4043";
                                  e.target.style.borderWidth = "1px";
                                }}
                              />
                              <div
                                className="px-4"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "0px",
                                  transform: "translateY(-50%)",
                                  pointerEvents: "none",
                                }}
                              >
                                {"{_"}
                              </div>
                              <div
                                className=""
                                style={{
                                  paddingRight: "20px",
                                  position: "absolute",
                                  top: "50%",
                                  left: "90px",
                                  transform: "translateY(-50%)",
                                  pointerEvents: "none",
                                }}
                              >
                                {"}"}
                              </div>
                              {index !== inputValues.length - 0 && (
                                <div
                                  className="px-2"
                                  style={{
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                    position: "absolute",
                                    top: "50%",
                                    left: "175px",
                                    transform: "translateY(-50%)",
                                    pointerEvents: "none",
                                  }}
                                >
                                  =
                                </div>
                              )}
                            </div>
                          ))}
                         
                        </div>

                        <div>
                          {inputValues.map((input, index) => (
                            <div
                              key={index}
                              style={{
                                marginTop: index === 0 ? "0" : "20px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="text"
                                  placeholder="Value"
                                  style={{
                                    padding: "5px",
                                    paddingLeft: "25px",
                                    width: "80%",
                                    height: "50px",
                                    borderRadius: "5px",
                                    fontSize: "13px",
                                    border: "1px solid #3c4043",
                                    borderColor: "#3c4043",
                                    borderWidth: "1px",
                                    color: "black",
                                    outline: "none",
                                    transition: "border-color 0.3s ease-in-out",
                                    // marginTop: "20px",
                                  }}
                                  value={input.value}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                  onFocus={(e) => {
                                    const inputValue3 = e.target.value;
                                    e.target.style.borderColor = "#0d6efd";
                                    e.target.style.borderWidth = "2px";
                                  }}
                                  onBlur={(e) => {
                                    const inputValue3 = e.target.value;
                                    e.target.style.borderColor = "#3c4043";
                                    e.target.style.borderWidth = "1px";
                                  }}
                                />
                                {index === inputValues.length - 1 && (
                                  <div
                                    className=""
                                    style={{
                                      marginLeft: "10px",
                                      background: "#0d6efd",
                                      borderRadius: "50%",
                                      padding: "5px",
                                      width: "27px",
                                      height: "30px",
                                      color: "white",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleAddInput}
                                  >
                                    <FaPlus size={16} />
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* </div> */}
              </div>

              {/* <div className="col-xl-1 d-flex align-items-center justify-content-center"> */}
              <div className="col-xl-4 d-flex">
                <div
                  className="vertical-line"
                  style={{
                    width: "0.7px",
                    height: "100%",
                    backgroundColor: "#ccc",
                    marginLeft: "20px",
                  }}
                />
                {/* </div> */}

                <div
                  className=""
                  style={{
                    fontSize: "13px",
                    color: "3C4043",
                    marginLeft: "60px",
                  }}
                >
                  Tracking template is the URL you want the ad click to go to
                  for tracking.<a href="#">Learn more</a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CampaignURL;
