import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { ChartTheme } from "../../../../theme/assets/ts/_utils/ChartTheme";
import {
  getDataFromBackend,
  getMethodDataFromBackend,
  URLS,
} from "../../../modules/dashboards/DashboardRequests";
import { FormatUtil } from "../../../../theme/assets/ts/_utils/FormatUtil";
import { IoTriangleSharp } from "react-icons/io5";
import { AiOutlineZoomIn } from "react-icons/ai";
import Modal from "../marketting_component/Modal";
import CustomLoader from "../marketting_component/CustomLoader";

type Props = {
  // detail: string;
  startDate: string;
  endDate: string;
  xAxisConfig: { visible: boolean };
  title: string;
  detailhead: string;
  shortdetail: string;
  longdetail: string;
  graphdata: any[];
  channelType: string;
  subTypeUrl: string;
};

const MarketingVBar: React.FC<Props> = ({
  startDate,
  endDate,
  xAxisConfig,
  title,
  detailhead,
  longdetail,
  shortdetail,
  graphdata,
  channelType,
  subTypeUrl,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);
  const sortedGraphData = [...graphdata].sort((a, b) => b.value - a.value);
  // console.log("sortedGraphData", sortedGraphData);
  console.log("sortedGraphData", sortedGraphData);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [baseLineData, setBaseLineData] = useState<any>({});

  useEffect(() => {
    const getData = async () => {
      let responseBaseline = await getMethodDataFromBackend(
        URLS.GET_BASELINESTATS
      );
      let dataBaseline = responseBaseline?.data;
      setBaseLineData(dataBaseline);
      console.log("baseee", responseBaseline?.data);
    };
    getData();
    return () => {};
  }, [startDate, endDate]);

  const baselineValue =
    title?.toLowerCase() == "cpc"
      ? Number(baseLineData?.cpc)
      : title?.toLowerCase() == "cpel"
      ? Number(baseLineData?.cpel)
      : title?.toLowerCase() == "cpa"
      ? Number(baseLineData?.cpa)
      : title?.toLowerCase() == "clicks"
      ? Number(baseLineData?.totalClicks)
      : title?.toLowerCase() == "eligible leads"
      ? Number(baseLineData?.totalEligibleLeads)
      : title?.toLowerCase() == "apps"
      ? Number(baseLineData?.totalApps)
      : 15;

  function generateRandomString(length) {
    const characters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters?.charAt(randomIndex);
    }

    return result;
  }

  const myChartID = isZoomModalOpen
    ? `${generateRandomString(10)}-modal`
    : generateRandomString(10);

  let wholeValue;
  useLayoutEffect(() => {
    let root = am5.Root.new(myChartID);
    root._logo?.dispose();

    root.setThemes([ChartTheme.getTheme(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        // wheelX: "panX",
        // wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );

    // graphdata.forEach((item) => {
    //     if (item.field && item.field.length > 60) {
    //         item.field = item.field.slice(0, 57) + '...';
    //     }
    // });

    // let xRenderer = am5xy.AxisRendererX.new(root, {});
    let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
      maxHeight: 150,
      oversizedBehavior: "truncate",
    });

    xRenderer.grid.template.setAll({
      location: 1,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "campaignName",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
        focusableGroup: "campaignName",
      })
    );

    xAxis
      .get("renderer")
      .labels.template.setAll(
        isZoomModalOpen ? { visible: true } : xAxisConfig
      );

    xAxis.data.setAll(graphdata);

    // Create y-Axis
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        max: wholeValue,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
        }),
      })
    );
    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        // name: name,
        stacked: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "campaignName",
        categoryYField: "profitOrLoss",
        valueXField: "percentageChangeBaseLine",
        tooltip: am5.Tooltip.new(root, {
          labelText:
            subTypeUrl === "/costmetrics" ||
            subTypeUrl === "/google" ||
            subTypeUrl === "/facebook"
              ? `${title}: ₹ {valueY}  \nBaseline : {valueX}%`
              : `${title}: {valueY}  \nBaseline : {valueX}%`,
          pointerOrientation: "left",
        }),
      })
    );

    // series.columns.template.setAll({
    //   tooltipText: `${title}: {valueY}`
    //   // tooltipY: am5.percent(10),
    // });

    // for remove horizontal line
    xAxis.get("renderer").grid.template.set("forceHidden", true);
    // yAxis.get("renderer").grid.template.set("forceHidden", true);
    // yAxis2.get("renderer").grid.template.set("forceHidden", true);

    series.data.setAll(graphdata);
    series.appear();

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          // text: "{valueY}",
          fill: root.interfaceColors.get("alternativeText"),
          centerY: am5.p50,
          centerX: am5.p50,
          populateText: true,
        }),
      });
    });

    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis2 = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.2,
        baseInterval: {
          timeUnit: "day",
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    let yAxis2 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series2 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Series",
        xAxis: xAxis2,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}",
          pointerOrientation: "right",
        }),
      })
    );

    xAxis2.get("renderer").grid.template.set("forceHidden", true);
    xAxis2.get("renderer").labels.template.setAll({ visible: false });
    yAxis2.get("renderer").labels.template.setAll({ visible: false });
    xAxis2.get("renderer").grid.template.set("forceHidden", true);
    yAxis2.get("renderer").grid.template.set("forceHidden", true);

    // Set data
    // let data2 = generateDatas(1200);
    // series2.data.setAll(data2);

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [myChartID]);

  useLayoutEffect(() => {}, []);

  let calculateBaseComparision = (value, baselineValue) => {
    const tempChange = ((value - baselineValue) / baselineValue) * 100;
    if (isNaN(tempChange) || !isFinite(tempChange)) {
      return "N/A";
    }
    const roundedChange = Math.abs(Number(tempChange.toFixed(2)));
    return roundedChange;
  };

  let calculateGrowth = (value, title, baselineValue) => {
    console.log("value, title, baseLine", value, title, baselineValue);
    if (
      title?.toLowerCase() == "cpc" ||
      title?.toLowerCase() == "cpel" ||
      title?.toLowerCase() == "cpa"
    ) {
      if (parseFloat(value) < parseFloat(baselineValue)) {
        return true;
      } else {
        return false;
      }
    } else {
      if (parseFloat(value) >= parseFloat(baselineValue)) {
        return true;
      } else {
        return false;
      }
    }
  };

  let arrowTwist = (value, title, baselineValue) => {
    console.log("value, title, baseLine", value, title, baselineValue);

    if (parseFloat(value) >= parseFloat(baselineValue)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="row gy-0 gx-xl-10" style={{ marginBottom: "0px" }}>
      <div className="col-xl-12">
        <div className="fw-bold">Observation in {title}: </div>
        <div>
          {shortdetail
            ?.split(" higher than baseLine.")
            .map((part, index, array) => {
              const isLastPart = index === array.length - 1;
              const textColor =
                subTypeUrl === "/costmetrics"
                  ? isLastPart
                    ? "green"
                    : "red"
                  : isLastPart
                  ? "red"
                  : "green";

              return (
                <React.Fragment key={index}>
                  <span style={{ color: textColor }}>
                    {!isLastPart ? (
                      <React.Fragment>
                        {part}
                        <span>{" higher than baseLine.\n"}</span>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <br />
                        <span>{part}</span>
                      </React.Fragment>
                    )}
                  </span>
                </React.Fragment>
              );
            })}
        </div>

        <div
          className="py-2 fw-bold"
          style={{
            fontSize: "12px",
            textAlign: "end",
          }}
          onClick={handleOpenModal}
        >
          {graphdata && graphdata?.length > 0 && <a href="#">View Table</a>}
        </div>
        <div className="d-flex flex-end">
          <AiOutlineZoomIn
            size={40}
            style={{ borderRadius: "50px", padding: "10px", cursor: "pointer" }}
            onMouseEnter={(e) => {
              (e.target as HTMLButtonElement).style.backgroundColor =
                "rgb(230,236,250)";
            }}
            onMouseLeave={(e) => {
              (e.target as HTMLButtonElement).style.backgroundColor = "";
            }}
            onClick={() => setIsZoomModalOpen(!isZoomModalOpen)}
          />
        </div>
        <div className="d-flex align-items-center">
          <div
            className="vertical-text fw-bold text-dark fs-6"
            style={{
              writingMode: "vertical-lr",
              transform: "rotate(-180deg)",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              height: "100px",
              marginTop: !xAxisConfig.visible ? "-100px" : "-200px",
            }}
          >
            {title}
          </div>
          {graphdata?.length <= 0 ? (
            <div
              id={myChartID}
              style={{
                height: xAxisConfig.visible
                  ? channelType === "marketing"
                    ? "220px"
                    : "350px"
                  : "200px",
                width: "100%",
              }}
            >
              {/* No data */}
            </div>
          ) : !isZoomModalOpen ? (
            <div
              id={myChartID}
              style={{
                height: xAxisConfig.visible
                  ? channelType === "marketing"
                    ? "300px"
                    : "350px"
                  : "200px",
                width: "100%",
              }}
            ></div>
          ) : (
            <Modal
              isOpen={isZoomModalOpen}
              onClose={() => setIsZoomModalOpen(!isZoomModalOpen)}
              title={title}
            >
              <div className="p-5 px-8 py-8">
                <div className="fw-bold">Observation in {title}: </div>
                <div className="mb-5">
                  {shortdetail
                    ?.split(" higher than baseLine.")
                    .map((part, index, array) => {
                      const isLastPart = index === array.length - 1;
                      const textColor =
                        subTypeUrl === "/costmetrics"
                          ? isLastPart
                            ? "green"
                            : "red"
                          : isLastPart
                          ? "red"
                          : "green";
                      return (
                        <span key={index} style={{ color: textColor }}>
                          {part}
                          {!isLastPart && (
                            <span style={{ color: textColor }}>
                              {" "}
                              higher than baseLine.{" "}
                            </span>
                          )}
                        </span>
                      );
                    })}
                </div>
                <div
                  id={myChartID}
                  style={{ width: "100%", height: "600px" }}
                />
              </div>
            </Modal>
          )}
        </div>
      </div>
      <div className="col-xl-4 pe-10">
        {/* <div className="fw-bold">Observation in {title}: </div>
        <div>
          {shortdetail
            ?.split(" higher than baseLine.")
            .map((part, index, array) => {
              const isLastPart = index === array.length - 1;
              const textColor =
                subTypeUrl === "/costmetrics"
                  ? isLastPart
                    ? "green"
                    : "red"
                  : isLastPart
                  ? "red"
                  : "green";
              return (
                <span key={index} style={{ color: textColor }}>
                  {part}
                  {!isLastPart && (
                    <span style={{ color: textColor }}>
                      {" "}
                      higher than baseLine.{" "}
                    </span>
                  )}
                </span>
              );
            })}
        </div>

        <div
          className="py-2 fw-bold"
          style={{
            fontSize: "12px",
            textAlign: "end",
          }}
          onClick={handleOpenModal}
        >
          {graphdata && graphdata?.length > 0 && <a href="#">View More</a>}
        </div> */}
        {isModalOpen && (
          <div
            className="modal fade show"
            id="confirmationModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="confirmationModalLabel"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              right: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="modal-dialog" role="document">
              <div
                className="modal-content"
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "80%",
                  maxHeight: "500px",
                  backgroundColor: "#FFFFFF",
                  zIndex: 9999,
                }}
              >
                <div className="header d-flex align-items-center justify-content-between px-7 py-3">
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center fw-bold text-gray-800 fs-3">
                      Observation in {title} graph:
                    </div>
                  </div>
                  {/* <div
                    className="d-flex justify-content-end fw-bold fs-6 d-flex py-3 px-4"
                    style={{
                      backgroundColor: "#ecc1bd",
                      borderRadius: "30px",
                      color: "#08112D",
                      cursor: "pointer",
                      marginLeft: "750px",
                    }}
                  >
                    <div className="me-2">Baseline: </div>
                    {baselineValue}
                  </div> */}
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={handleCloseModal}
                  ></button>
                </div>
                <div className="p-7" style={{ overflow: "auto" }}>
                  <div className="row gx-xl-10 gy-10 mb-7 fs-4">
                    {channelType === "marketing" ? (
                      <div className="col-xl-6 d-flex justify-content-center fw-bold">
                        Product Name
                      </div>
                    ) : (
                      <div className="col-xl-6 d-flex justify-content-center fw-bold">
                        Campaign Name
                      </div>
                    )}
                    <div className="col-xl-3 d-flex justify-content-center fw-bold">
                      Value
                    </div>
                    <div className="col-xl-3 d-flex justify-content-center fw-bold">
                      Percentage
                    </div>
                  </div>
                  {sortedGraphData?.map((item, index) => (
                    <div className="row gx-xl-10 gy-10 mb-3">
                      <div className="col-xl-6 d-flex flex-start fw-semibold">
                        <div
                          className="ms-2 me-5 p-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "10px",
                            width: "20px",
                            height: "20px",
                            backgroundColor: "#BFBFBF",
                          }}
                        >
                          {index + 1}
                        </div>
                        {item?.campaignName}
                      </div>
                      <div className="col-xl-3 d-flex justify-content-center fw-bold">
                        <div className="px-4 py-2">
                          {title?.toLowerCase() === "cost" ||
                          title?.toLowerCase() === "cpc" ||
                          title?.toLowerCase() === "cpa" ||
                          title?.toLowerCase() === "cpel"
                            ? `₹${Math.round(Number(item?.value))}`
                            : title?.toLowerCase() === "ctr"
                            ? `${Math.round(Number(item?.value))}%`
                            : Math.round(Number(item?.value)) || ""}
                        </div>
                      </div>
                      <div className="col-xl-3 d-flex align-items-center d-flex flex-center"
                      
                      style={{cursor:'pointer'}}
                      title={title?.toLowerCase() === "cost" ||
                      title?.toLowerCase() === "cpc" ||
                      title?.toLowerCase() === "cpa" ||
                      title?.toLowerCase() === "cpel"
                         ?`Baseline: ₹${Math.abs(
                        Math.round( item?.baseLine)
                      ).toLocaleString("en-IN")}`:`Baseline: ${Math.abs(
                        Math.round( item?.baseLine)
                      ).toLocaleString("en-IN")}`}
                         
                      >
                        {arrowTwist(item?.value, title, item?.baseLine) ? (
                          <IoTriangleSharp
                            size={14}                       
                            style={{
                              color: calculateGrowth(
                                item?.value,
                                title,
                                item?.baseLine
                              )
                                ? "green"
                                : "red ",
                            }}
                          />
                        ) : (
                          <IoTriangleSharp
                            size={14}
                            style={{
                              color: calculateGrowth(
                                item?.value,
                                title,
                                item?.baseLine
                              )
                                ? "green"
                                : "red ",
                              transform: "rotate(180deg)",
                            }}
                          />
                        )}
                        <div
                          style={{
                            fontSize: "14px",
                            color: calculateGrowth(
                              item?.value,
                              title,
                              item?.baseLine
                            )
                              ? "green"
                              : "red ",
                          }}
                          title={title?.toLowerCase() === "cost" ||
                          title?.toLowerCase() === "cpc" ||
                          title?.toLowerCase() === "cpa" ||
                          title?.toLowerCase() === "cpel"
                             ?`Baseline: ₹${Math.abs(
                            Math.round( item?.baseLine)
                          ).toLocaleString("en-IN")}`:`Baseline: ${Math.abs(
                            Math.round( item?.baseLine)
                          ).toLocaleString("en-IN")}`}
                        >
                          {`(${calculateBaseComparision(item?.value, item?.baseLine
                        )}%)`}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default MarketingVBar;
