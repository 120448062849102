import React, { useEffect, useState } from "react";
import { AiFillEye, AiFillStar, AiOutlineZoomIn } from "react-icons/ai";
import moneybag from "../../../../assets/money.png";
import handcoin from "../../../../assets/finance.png";
import handshake from "../../../../assets/handshake.png";
import CTR from "../../../../assets/CTR (2).png";
import CPC from "../../../../assets/CPC.png";
import CPEL from "../../../../assets/CPEL.png";
import CPA from "../../../../assets/CPA (2).png";
import Clicks from "../../../../assets/clicks (2).png";
import Leads from "../../../../assets/leads.png";
import Conversions from "../../../../assets/conversions (2).png";

import { FaFileInvoiceDollar } from "react-icons/fa";
import {
  URLS,
  getDataFromBackend,
} from "../../../modules/dashboards/DashboardRequests";
import HorizontalBarGraph from "../components/HorizontalBarGraph";
import { FormatUtil } from "../../../../theme/assets/ts/_utils/FormatUtil";

type card = {
  productName: string;
  value: number;
};

type DataEntry = {
  title: string;
  value: number;
  products: card[];
};

type Props = {
  productId: string;
  startDate: string;
  endDate: string;
  productType: string;
  channelType: string;
  index: string;
  subType: string;
};

const ProductCard: React.FC<Props> = ({
  productId,
  startDate,
  endDate,
  productType,
  channelType,
  index,
  subType
}: Props) => {
  let [productData, setProductData] = useState<DataEntry[]>([]);
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);

  const productDataMock = [
    {
      title: "CTR",
      value: 0,
      products: [
        {
          productName: "Regular",
          value: 0,
        },
        {
          productName: "hpcl super saver",
          value: 0,
        },
        {
          productName: "Freedom",
          value: 0,
        },
        {
          productName: "Premium",
          value: 0,
        },
      ],
    },
    {
      title: "CPC",
      value: 0,
      products: [
        {
          productName: "Regular",
          value: 0,
        },
        {
          productName: "hpcl super saver",
          value: 0,
        },
        {
          productName: "Freedom",
          value: 0,
        },
        {
          productName: "Premium",
          value: 0,
        },
      ],
    },
    {
      title: "CPEL",
      value: 0,
      products: [
        {
          productName: "Regular",
          value: 0,
        },
        {
          productName: "hpcl super saver",
          value: 0,
        },
        {
          productName: "Freedom",
          value: 0,
        },
        {
          productName: "Premium",
          value: 0,
        },
      ],
    },
    {
      title: "CPA",
      value: 0,
      products: [
        {
          productName: "Regular",
          value: 0,
        },
        {
          productName: "hpcl super saver",
          value: 0,
        },
        {
          productName: "Freedom",
          value: 0,
        },
        {
          productName: "Premium",
          value: 0,
        },
      ],
    },
    {
      title: "Impression",
      value: 0,
      products: [
        {
          productName: "Regular",
          value: 0,
        },
        {
          productName: "hpcl super saver",
          value: 0,
        },
        {
          productName: "Freedom",
          value: 0,
        },
        {
          productName: "Premium",
          value: 0,
        },
      ],
    },
    {
      title: "Clicks",
      value: 0,
      products: [
        {
          productName: "Regular",
          value: 0,
        },
        {
          productName: "hpcl super saver",
          value: 0,
        },
        {
          productName: "Freedom",
          value: 0,
        },
        {
          productName: "Premium",
          value: 0,
        },
      ],
    },
    {
      title: "Leads",
      value: 0,
      products: [
        {
          productName: "Regular",
          value: 0,
        },
        {
          productName: "hpcl super saver",
          value: 0,
        },
        {
          productName: "Freedom",
          value: 0,
        },
        {
          productName: "Premium",
          value: 0,
        },
      ],
    },
    {
      title: "Conversion",
      value: 0,
      products: [
        {
          productName: "Regular",
          value: 0,
        },
        {
          productName: "hpcl super saver",
          value: 0,
        },
        {
          productName: "Freedom",
          value: 0,
        },
        {
          productName: "Premium",
          value: 0,
        },
      ],
    },
    {
      title: "Conversions",
      value: 0,
      products: [
        {
          productName: "Regular",
          value: 0,
        },
        {
          productName: "hpcl super saver",
          value: 0,
        },
        {
          productName: "Freedom",
          value: 0,
        },
        {
          productName: "Premium",
          value: 0,
        },
      ],
    },
  ];

  useEffect(() => {
    const getData = async () => {
      let requestData = {
        productId,
        startDate,
        endDate,
        productType,
      };
      let myUrl = subType == "product" ? URLS.GET_NEW_PRODUCT_STATS: URLS.GET_NEW_PRODUCT_PLATFORM_STATS
      let response = await getDataFromBackend(
        requestData,
        myUrl
      );
      console.log(
        "Response of productCard : ",
        response?.data,
        URLS.GET_NEW_PRODUCT_STATS
      );

      let data = response?.data;

      const roundedData = data && data.length>0 && data.map(item => ({
        ...item,
        value: Math.round(item.value),
        products: item.products.map(product => ({
          ...product,
          value: Math.round(product.value)
        }))
      }));

      if (data?.length) {
        setProductData(roundedData);
      } else {
        setProductData(productDataMock);
      }
    };

    getData(); // run it, run it

    return () => {
      // this now gets called when the component unmounts
    };
  }, [productId, startDate, endDate, productType]);


  const iconMap = {
    impressions: <AiFillEye size={25} style={{ color: "#08112D" }} />,
    clicks: (
      <img
        src={Clicks}
        alt="Product"
        style={{ maxWidth: "25px", maxHeight: "25px" }}
      />
    ),
    conversions: (
      <img
        src={Conversions}
        alt="Product"
        style={{ maxWidth: "25px", maxHeight: "25px" }}
      />
    ),
    cpa: (
      <img
        src={CPA}
        alt="Product"
        style={{ maxWidth: "25px", maxHeight: "25px" }}
      />
    ),
    cpcl: (
      <img
        src={CPEL}
        alt="Product"
        style={{ maxWidth: "25px", maxHeight: "25px" }}
      />
    ),
    cpc: (
      <img
        src={CPC}
        alt="Product"
        style={{ maxWidth: "25px", maxHeight: "25px" }}
      />
    ),
    ["app ratio"]: (
      <img
        src={CTR}
        alt="Product"
        style={{ maxWidth: "25px", maxHeight: "25px" }}
      />
    ),
    ["total apps"]: (
      <img
        src={Leads}
        alt="Product"
        style={{ maxWidth: "25px", maxHeight: "25px" }}
      />
    ),
    ctc: <AiFillStar size={25} style={{ color: "#08112D" }} />,
    "total cost": (
      <FaFileInvoiceDollar size={25} style={{ color: "#08112D" }} />
    ),

    default: <AiFillEye size={25} style={{ color: "#08112D" }} />,
  };

  const formatValue = (title, value) => {
    switch (title?.toLowerCase()) {
      case "cpm":
      case "ctc":
      case "cpa":
      case "cpc":
      case "cpcl":
      case "total spends":
        return `₹ ${Math.round(value).toLocaleString("en-IN")}`;
      case "app ratio":
        return `${Math.round(value).toLocaleString("en-IN")}%`;
      case "cards ratio":
        return `${Math.round(value).toLocaleString("en-IN")}%`;
      default:
        return Math.round(value).toLocaleString("en-IN") || "";
    }
  };



  function ProductCard({ data }) {
    const icon = iconMap[data?.title?.toLowerCase()] || iconMap?.default;
    const roundedValue = String(Math.round(Number(data?.value)));

    const formattedValue = formatValue(data?.title, roundedValue);
    return (
      <div className="">
        <div className="card card-flush h-lg-100 p-5" style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}>
          <h3 className="card-title align-items-start flex-column border-solid-2px">
            <div className="card-label fw-semibold fs-4 d-flex align-items-center justify-content-between">
              <div>{data?.title}</div>
              <div style={{ display: 'flex' }}>
                {/* <div>{icon}</div> */}
                <div
                  className="fw-bold"
                  style={{
                    fontSize: "12px",
                    textAlign: "end",
                    marginLeft: '5px',
                    cursor: 'pointer'
                  }}
                  onClick={() => setIsZoomModalOpen(true)}
                >
                  <AiOutlineZoomIn size={25} />
                </div>
              </div>
            </div>
            <div className="text-400 fw-semibold d-flex align-items-center" style={{ color: "#3F4254", fontSize: "30px" }}>
              {formattedValue.toLocaleString()}
            </div>
          </h3>
          <div className="">
            <HorizontalBarGraph
              chartID={`hor${data?.title}${index}${subType}`}
              height="180px"
              data={data?.products}
              valueXField="value"
              valueYField="productName"
              isZoomModalOpen={isZoomModalOpen}
              setIsZoomModalOpen={setIsZoomModalOpen}
              title={data?.title}
              channelType={channelType}
            />
          </div>
        </div>
      </div>
    );
  }


  return (
    <div className="row gy-10 gx-xl-10 d-flex">
      <ProductCard data={productData[index]} />
    </div>
  );
};

export default ProductCard;
