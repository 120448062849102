import React, { useState } from "react";
import { AiOutlineProject } from "react-icons/ai";

const HeaderDropdown = ({
  selectedValue,
  dropdownItems,
  onDropdownItemClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="d-flex align-items-center px-5">
      <div
        className="dropdown px-1"
        style={{
          width: "200px",
          height: "40px",
          backgroundColor: "white",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <AiOutlineProject
          size={30}
          style={{
            color: isOpen ? "#18A6F8" : "#CED0D6",
          }}
        />
        <div
          data-kt-daterangepicker="true"
          data-kt-daterangepicker-opens="left"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          className="d-flex align-items-center dropdown-toggle"
          style={{
            border: "1px solid rgb(228,230,239)",
            height: "30px",
            borderRadius: "10px",
            width: "140px",
            backgroundColor: "#E84A42",
            color: "white",
            justifyContent: "center",
          }}
          onClick={toggleDropdown}
        >
          {selectedValue || "Baseline"}
        </div>
        <ul
          className={`dropdown-menu ${isOpen ? "show" : ""}`}
          aria-labelledby="dropdownMenuButton1"
        >
          {dropdownItems.map((data, index) => (
            <li
              key={data?.productId || index}
              onClick={() => onDropdownItemClick(data)}
              style={{ cursor: "pointer", width: "180px" }}
            >
              <span className="dropdown-item text-hover-primary">{data}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default HeaderDropdown;
