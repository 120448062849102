import { Fragment, useEffect, useState, useRef } from "react";
import { KTCard, KTSVG } from "../../../../theme/helpers";
import { getCampaignHighlights } from "../../../modules/application/_requests";
import { useQuery } from "react-query";
import { useParams, useLocation } from "react-router-dom";
import { IoSparkles } from "react-icons/io5";
import { BsChevronDown, BsChevronUp, BsExclamationTriangle, BsTag } from "react-icons/bs";
import { GrView } from "react-icons/gr";

import { MdDelete } from "react-icons/md";
import CampaignType from "./CampaignType";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineInfoCircle, AiOutlineWarning } from "react-icons/ai";
import outline from "../../../../assets/outline-preview.png";
import warning from "../../../../assets/baseline-warning.png";
import call from "../../../../assets/call.png";



import { Search } from "../../../../theme/partials";
import Video from "./Video";
import GoalCard from "./GoalCard";
import { RiDeleteBin6Line } from "react-icons/ri";

type Props = {
    className: string;
};

type Goal = {
    id: number;
    name: string;
    source: string;
    actions: number;
};

const cardStyles = {
    border: "1px solid lightgray",
    display: "flex",
    borderRadius: "0",

};

const imageStyles = {
    maxWidth: "20px",
    maxHeight: "20px",
    marginRight: "10px",
};

const goalNameStyles = {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#333",
};

const accountDefaultStyles = {
    fontSize: "13px",
    color: "#333",
};

const sourceStyles = {
    fontSize: "14px",

    color: "#333",
};

const warningIconStyles = {
    marginRight: "5px",
};

const actionTextStyles = {
    fontSize: "13px",
    fontWeight: "thin",
    color: "#333",
    borderBottom: "1.8px dashed",
};

const moreOptionsStyles = {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#333",
    cursor: "pointer",
    marginRight: "5px",
};


const DemoImprove = (props) => {
    // const containerRef = useRef<HTMLDivElement>(null);
    let title = props.title
    //   let TitleData=[
    //     {
    // title:'Sales'
    //   },
    //   {
    //     title:'Leads'
    //       },
    //       {
    //         title:'Website'
    //           }
    // ]
    const location = useLocation();
    const locationURL = window.location.pathname.split("/").pop();

    const id = (location.state as { id: string })?.id || "";

    console.log(id, "fdkfhdk");
    //   const { id } = useParams();
    console.log(id, "params data");
    const [showCampaignType, setShowCampaignType] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isaDeleted, setIsaDeleted] = useState(false);

    const [goalPopupVisibility, setGoalPopupVisibility] = useState<{ [key: number]: boolean }>({});

    const handleMouseEnter1 = (goalId: number) => {
        setGoalPopupVisibility((prevVisibility) => ({ ...prevVisibility, [goalId]: true }));
    };

    const handleMouseLeave1 = (goalId: number) => {
        setGoalPopupVisibility((prevVisibility) => ({ ...prevVisibility, [goalId]: false }));
    };

    type Goal = {
        id: number;
        objective: string;
        name: string;
        source: string;
        actions: number;
        image: string; // Image URL or imported image
    };

    const [goals, setGoals] = useState<Goal[]>([
        {
            id: 1,
            objective: "Website",
            name: "Page views",
            source: title,
            actions: 1,
            image: outline, // Imported image
        },
        {
            id: 2,
            objective: "Website",
            name: "Phone Call Lead",
            source: "Call from Ads",
            actions: 1,
            image: call, // Imported image

        },
        // Add more goals as needed
    ]);

    const[modalGoal,setModalGoal]= useState<number | null>(null)
    // const [isChecked2, setIsChecked2] = useState(false);
    const [isaPopupVisible, setIsaPopupVisible] = useState(false);
    const [hover, setHover] = useState(false);
    const [hover2, setHover2] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [removeId, setRemoveId] = useState<number | null>(null);
    const [restoreId, setRestoreId] = useState<number | null>(null);


    const [isExpanded, setIsExpanded] = useState(false);
    const [showaPopup, setShowaPopup] = useState(false);
    // const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const popupRef = useRef<HTMLDivElement>(null);
    const [popupContent, setPopupContent] = useState<JSX.Element | null>(null);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [blockHover, setblockHover] = useState(false);
    const [goalToDeleteId, setGoalToDeleteId] = useState<number | null>(null);
    type GoalHoverState = {
        [key: number]: boolean;
    };



    // new shit
    const [goalsState, setGoalsState] = useState(
        goals.map((goal) => ({
            id: goal.id,
            showPopup: false,
            showConfirmationPopup: false,
        }))
    );



    
    // new shit ends


    const [hoveredGoalId, setHoveredGoalId] = useState<{ [key: number]: boolean }>({});

    const handleDotEnter = (goalId: number) => {
        setHoveredGoalId((prevGoalID) => ({ ...prevGoalID, [goalId]: true }));
    };

    const handleDotLeave = (goalId: number) => {
        setHoveredGoalId((prevGoalID) => ({ ...prevGoalID, [goalId]: false }));
    };


    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowaPopup(false);
            }
        };
        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const handleConfirmDelete = (id) => {
        setRemoveId(id)
        setIsDeleted(true);
        // setIsaDeleted(true);
        setShowConfirmationPopup(false);
    };
console.log(removeId,"remove ID")
const handleRestore = (id) => {
    setRestoreId(id);
    setShowConfirmationPopup(false);
};
console.log(restoreId,"restore ID")

    const handleaPopupClick = (name, id) => {
        setShowaPopup(true);
        setGoalToDeleteId(id);
        // setShowaPopup(true);
        // console.log('deleid', goalToDeleteId);
    };
    console.log(goalToDeleteId,"goalToDeleteId")

    const handleToggleContent = () => {
        setIsExpanded(!isExpanded);
        setIsaPopupVisible(true);
    };

    const handleMouseEnter2 = () => {
        setHover(true);
    };
    const handleMouseEnter3 = () => {
        setHover2(true);
    };
    const handleMouseLeave3 = () => {
        setHover2(false);
    };




    const handleaDeleteGoal = (name,id) => {
        // setGoals(id);
        setModalGoal(id)
        setShowConfirmationPopup(true);
        setShowaPopup(false);

    };

    console.log(modalGoal,"hhhhhhhhhhhhh")

    const handleClick = () => {
        setShowCampaignType(true);
        setTimeout(() => {
            window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
        }, 0);
        // if (containerRef.current) {
        //   containerRef.current.scrollIntoView({ behavior: "smooth" });
        // }
    };

    // const handleRestoreGoal = () => {
    //   setIsaDeleted(false);
    // };

    //   const handleMouseEnter = () => {
    //     setIsHovered(true);
    //   };

    //   const handleMouseLeave = () => {
    //     setIsHovered(false);
    //   };

    const removeGoal = (id: number) => {
        setGoals(goals.filter((goal) => goal.id !== id));
    };

    const [showPopup, setShowPopup] = useState(false);
    //   const [popupContent, setPopupContent] = useState<JSX.Element | null>(null);
    //   const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const handlePopupClick = (content: string) => {
        setPopupContent(
            <div className="d-flex align-items-center">
                <MdDelete size={20} className="mr-2" onClick={modalOpen} />
                {content}
            </div>
        );
        setShowPopup(true);
        setShowConfirmationPopup(true);
        // setShowPopup(false);
    };

    const modalOpen = () => {
        setShowConfirmationPopup(true);
        setShowPopup(false)
    };

    const handleDeleteGoal = (event: React.MouseEvent<HTMLButtonElement>) => {
        const id = event.currentTarget.dataset.id;
        if (id) {
            removeGoal(Number(id)); // Remove the goal from the state
            setShowConfirmationPopup(false); // Hide the delete confirmation popup
        }
    };


    const closePopup = () => {
        setShowPopup(false);
        setShowConfirmationPopup(false);
    };

    // const handleConfirmDelete = () => {
    //   if (goalToDelete) {
    //     removeGoal(goalToDelete.id);
    //     setShowConfirmationModal(false);
    //   }
    // };



    return (
        <>
            {/* {TitleData.map((item, index) => ( */}
            <div className="pb-7 pt-5" style={{
                backgroundColor: "white", boxShadow: '0 2px 8px rgba(0, 0, 0, 0.5)',
                borderRadius: "10px"
            }}>

                {/* <KTCard className="card h-lg-100"> */}
                <span className="fw-bold text-dark px-5 pb-7 pt-5" style={{ fontSize: '16px' }}>
                    Use these conversion goals to improve {title}
                </span>
                <hr
                    style={{
                        // marginTop: "-10px",
                        border: "1px solid #CCCCCC",
                        width: "102.8%",
                        marginLeft: '-17px'
                    }}
                />
                {isDeleted ?
                    <div className="mb-10 py-2 px-1 d-flex justify-content-between mx-3" style={{ borderRadius: '7px', backgroundColor: '#E8F0FE', alignItems: 'center' }}>

                        <p style={{ fontSize: '14px', marginBottom: '-2px', marginLeft: '30px' }}>
                            <AiOutlineInfoCircle className="me-5" size={20} style={{ color: '#1153aa' }} />This change overrides your account goals setup.
                            Your campaign will now optimize for the goals shown below.
                        </p>
                        <div className="btn btn-sm fw-bold btn-active-color-primary text-primary d-flex flex-end"
                            // onClick={() => setIsDeleted(false)}
                            onClick={() => handleRestore(removeId)}

                            >
                            Restore Account Goals
                        </div>
                    </div>

                    :


                    <div className="align-items-start flex-column fs-6 px-5 mb-5" style={{ color: "#5f6368", fontSize: '14px' }}>
                        Conversion goals labeled as account default will use data from all of
                        your campaigns to prove your bid strategy and campaign performance,
                        even if they don't seem directly related to {title}.
                        {/* {item.title} */}

                    </div>

                }
                <div className="">

                    <div className="fs-7 mb-2" style={{ color: '#5F6368' }}>
                        <div className="row gx-xl-12 gy-5 d-flex px-5">
                            <div className="col-lg-4">Conversion Goals</div>
                            <div className="col-lg-2">Conversion Source</div>
                            <div className="col-lg-3 d-flex align-items-center justify-content-center">
                                Conversion Actions
                            </div>
                            <div className="col-lg-2"></div>
                            <div className="col-lg-1 "></div>
                        </div>
                    </div>

                    {goals.map((goals, index) =>
                     (
                        <div className="container" key={goals.id}>
                            
                            {/* // goalcard custom component */}
                            <>
                            {console.log(goals,"gggggggggggg")}
                                {removeId!=goals.id && (
                                //  {removeId!=goals.id && restoreId===removeId && (
                                    <div>
                                          
                                        <div className="fs-7 text-gray-500 mb-2" key={goals.id}>
                                            {/* {goals.id === 1 && <Header />} */}
                                            <div className="card mb-5" style={cardStyles}>
                                                <div className="row gx-xl-12 gy-5 d-flex">
                                                    <div className="col-lg-4 py-5 px-7">
                                                        <div className="d-flex align-items-center">
                                                            <span className="d-block justify-content-start flex-column flex-row pr-5">
                                                                <img src={goals.image} alt={goals.name} style={imageStyles} />
                                                                <span
                                                                    className="text-gray-800 fw-bold"
                                                                    style={goalNameStyles}
                                                                >
                                                                    {goals.name}
                                                                </span>
                                                                <span
                                                                    className="text-gray-800 mx-2"
                                                                    style={accountDefaultStyles}
                                                                >
                                                                    (account default)
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 py-5"
                                                        style={{ backgroundColor: goalPopupVisibility[goals.id] ? '#F1F3F4' : 'white' }}
                                                        onMouseOver={() => handleMouseEnter1(goals.id)}
                                                        onMouseOut={() => handleMouseLeave1(goals.id)}
                                                    >
                                                        <div style={sourceStyles}>{goals.source}</div>
                                                    </div>
                                                    <div className="col-lg-3 d-flex align-items-center justify-content-center"
                                                        style={{ backgroundColor: goalPopupVisibility[goals.id] ? '#F1F3F4' : 'white' }}
                                                        onMouseOver={() => handleMouseEnter1(goals.id)}
                                                        onMouseOut={() => handleMouseLeave1(goals.id)}
                                                    >
                                                        <div
                                                            className="text-gray-800 fw-bold d-block fs-7 align-items-center">
                                                            <img src={warning} style={{ marginRight: '10px', marginTop: '5px' }} />
                                                            <span
                                                                className="action-text"
                                                                style={actionTextStyles}
                                                            // onMouseEnter={handleMouseEnter1}
                                                            // onMouseLeave={handleMouseLeave}
                                                            >
                                                                {goals.actions} action
                                                            </span>
                                                            {goalPopupVisibility[goals.id] && (
                                                                <div
                                                                    style={{
                                                                        // flexWrap: 'wrap',
                                                                        position: "absolute",
                                                                        top: "75%",
                                                                        left: "80px",
                                                                        backgroundColor: "#fff",
                                                                        padding: "5px",
                                                                        border: "1px solid #ccc",
                                                                        borderRadius: "5px",
                                                                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                                                                        zIndex: "2",
                                                                        width: "380px",
                                                                        // height: "205px",
                                                                        fontSize: "13px",
                                                                        marginLeft: "500px",
                                                                        color: "#333",
                                                                        alignContent: "center",
                                                                        // justifyContent:'center',
                                                                        alignItems: "center",
                                                                        transition: "height 5.0s ease",
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="d-flex"
                                                                        style={{
                                                                            backgroundColor: "#FDF7E0",
                                                                            width: "340px",
                                                                            padding: "20px",
                                                                            margin: "13px",
                                                                            justifyContent: "space-between",
                                                                            // height: "100px",
                                                                            flex: 'wrap'
                                                                        }}
                                                                    >
                                                                        <BsExclamationTriangle
                                                                            style={{
                                                                                color: '#A0522D',
                                                                                height: "30%",
                                                                                width: "12%",
                                                                                marginRight: "10px",
                                                                            }}
                                                                        />
                                                                        <div>
                                                                            1 inactive or unverified conversion action from{" "}
                                                                            <strong>{goals.source}</strong> for the{" "}
                                                                            <strong>{goals.name}</strong> goal
                                                                        </div>
                                                                    </div>
                                                                    <div className="ml-2 py-3 px-3"
                                                                        style={{
                                                                            // width: "365px",
                                                                            backgroundColor: hover2 ? '#E5E5E5' : 'white',
                                                                            marginTop: "10px",
                                                                            justifyContent: "center",
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        onClick={handleToggleContent}
                                                                        onMouseEnter={() => handleMouseEnter2()}
                                                                    // height: "200px",

                                                                    >

                                                                        {isExpanded ? (

                                                                            <BsChevronUp className="arrow-icon" size={20} style={{ color: hover2 ? 'black' : '#5F6368', }} />
                                                                        ) : (
                                                                            <BsChevronDown
                                                                                className="arrow-icon"
                                                                                size={20}
                                                                                style={{ color: hover2 ? 'black' : '#5F6368', }}
                                                                            />
                                                                        )}
                                                                        <span className="fw-bold ml-3" onMouseEnter={() => handleMouseEnter3()} onMouseLeave={() => handleMouseLeave3()}>
                                                                            1 inactive conversion action
                                                                        </span>
                                                                    </div>

                                                                    {isExpanded && (
                                                                        <div className="d-flex align-items-center px-10" style={{ fontSize: '10px' }} >{goals.source}</div>
                                                                    )}
                                                                    <br />
                                                                    <div>
                                                                        <p className="px-5" style={{ fontSize: '10px', color: "#686C71" }}>
                                                                            You can
                                                                            <a href="#" style={{ color: '#009EF7' }}> edit this goal </a>
                                                                            on your Conversions page
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 py-5" style={{ backgroundColor: goalPopupVisibility[goals.id] ? '#F1F3F4' : 'white' }}
                                                        onMouseOver={() => handleMouseEnter1(goals.id)}
                                                        onMouseOut={() => handleMouseLeave1(goals.id)}
                                                    >
                                                    </div>
                                                    <div className="col-lg-1 d-flex align-items-center justify-content-center">
                                                        <div className="col-lg-3 d-flex pr-5 justify-content-end">

                                                            <span
                                                                className="text-gray-800 fw-bold d-block fs-6 p-4"
                                                                style={{ borderRadius: "50px", backgroundColor: hoveredGoalId[goals.id] ? '#F1F3F4' : 'transparent' }}
                                                                onMouseEnter={() => handleDotEnter(goals.id)}
                                                                onMouseLeave={() => handleDotLeave(goals.id)}


                                                            >
                                                                <BsThreeDotsVertical
                                                                    className=""
                                                                    size={23}
                                                                    style={{
                                                                        backgroundColor: hoveredGoalId[goals.id] ? '#F1F3F4' : 'transparent',
                                                                    }}
                                                                onClick={() => handleaPopupClick(goals?.name, goals?.id)}

                                                                    onMouseEnter={() => handleDotEnter(goals.id)}
                                                                    onMouseLeave={() => handleDotLeave(goals.id)}
                                                                />


                                                            </span>
                                                            {showaPopup && goalToDeleteId === goals.id &&(
                                                                <div
                                                                    ref={popupRef}
                                                                    style={{
                                                                        position: "absolute",
                                                                        bottom: "-15px",  //decreasing will shift it lower
                                                                        // right: -150, //decreasing will shift it more to the right
                                                                        right: -80, //decreasing will shift it more to the right
                                                                        backgroundColor: "#fff",
                                                                        padding: "10px",
                                                                        border: "1px solid #ccc",
                                                                        borderRadius: "5px",
                                                                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                                                                        zIndex: "2",
                                                                        // width: "270px",
                                                                        height: '70px',
                                                                        fontSize: "13px",
                                                                        color: "#333",
                                                                        flex: 'wrap',
                                                                        justifyContent: 'center'
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            padding: "15px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onMouseEnter={(e) => {
                                                                            (e.target as HTMLButtonElement).style.backgroundColor = "#F5F5F5";
                                                                            // (e.target as HTMLButtonElement).style.color = "#1153aa"; // Change the background color on hover
                                                                            // (e.target as HTMLButtonElement).style.cursor= inputValue2 === '' && cards.length === 0 ? "not-allowed" : "pointer";
                                                                        }}
                                                                        onMouseLeave={(e) => {
                                                                            (e.target as HTMLButtonElement).style.backgroundColor = "white"; // Restore the initial background color
                                                                            // (e.target as HTMLButtonElement).style.color = "#1a73e8"; // Change the background color on hover
                                                                        }}
                                                                        // onClick={handleaDeleteGoal}
                                                                onClick={() => handleaDeleteGoal(goals?.name, goals?.id)}

                                                                    >
                                                                        <RiDeleteBin6Line size={20} style={{ marginRight: "10px" }} />
                                                                        Remove "{goals?.name}" goals
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {!showaPopup && showConfirmationPopup  && modalGoal === goals.id && (
                                                                <div
                                                                
                                                                    className="modal fade show p-7"
                                                                    id="confirmationModal"
                                                                    tabIndex={-1}
                                                                    role="dialog"
                                                                    aria-labelledby="confirmationModalLabel"
                                                                    style={{
                                                                        // display: "block",
                                                                        position: "fixed",
                                                                        top: 0,
                                                                        left: 0,
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                                                                        boxShadow: "0 2px 8px rgba(0, 0, 0, 2)",
                                                                        zIndex: 9999,
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <div className="modal-dialog" role="document">
                                                                        <div className="modal-content">
                                                                            <div className="modal-body">
                                                                                <h5 style={{ fontSize: "18px", lineHeight: '24px', marginBottom: '10px' }}>Remove this goal?</h5>
                                                                                <p style={{ fontSize: "13px", color: "black" }}>
                                                                                    This campaign will no longer optimize for  "{goals?.name}"
                                                                                    . Removing this goal won't affect your
                                                                                    account-level goals.
                                                                                </p>
                                                                            </div>
                                                                            <div className="d-flex flex-end mb-3 px-3">
                                                                                <button
                                                                                    type="button"
                                                                                    style={{ fontSize: '14px', color:'#5680DD' }}
                                                                                    className="nav-link btn btn-sm btn-active btn-active-light fw-bold px-4 me-1"
                                                                                    onMouseEnter={(e) => {
                                                                                        (e.target as HTMLButtonElement).style.backgroundColor = "rgb(230,236,250)";
                                                                                        // (e.target as HTMLButtonElement).style.color = "#1153aa"; // Change the background color on hover
                                                                                        // (e.target as HTMLButtonElement).style.cursor= inputValue2 === '' && cards.length === 0 ? "not-allowed" : "pointer";
                                                                                    }}
                                                                                    onMouseLeave={(e) => {
                                                                                        (e.target as HTMLButtonElement).style.backgroundColor = "white"; // Restore the initial background color
                                                                                        // (e.target as HTMLButtonElement).style.color = "#1a73e8"; // Change the background color on hover
                                                                                    }}
                                                                                    onClick={() => setShowConfirmationPopup(false)}
                                                                                >
                                                                                    Cancel
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    style={{ fontSize: '14px', color:'#5680DD', backgroundColor:"rgb(230,236,250)" }}
                                                                                    className="nav-link btn btn-sm  btn-active fw-bold px-4 me-1"
                                                                                    // onMouseEnter={(e) => {
                                                                                    //     (e.target as HTMLButtonElement).style.backgroundColor = "rgb(230,236,250)";
                                                                                    //     // (e.target as HTMLButtonElement).style.color = "#1153aa"; // Change the background color on hover
                                                                                    //     // (e.target as HTMLButtonElement).style.cursor= inputValue2 === '' && cards.length === 0 ? "not-allowed" : "pointer";
                                                                                    // }}
                                                                                    // onMouseLeave={(e) => {
                                                                                    //     (e.target as HTMLButtonElement).style.backgroundColor = "white"; // Restore the initial background color
                                                                                    //     // (e.target as HTMLButtonElement).style.color = "#1a73e8"; // Change the background color on hover
                                                                                    // }}
                                                                                    // onClick={handleDeleteGoal}
                                                                                    // onClick={handleConfirmDelete}
                                                                onClick={() => handleConfirmDelete(goals?.id)}

                                                                                >
                                                                                    Remove
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* Restore Goal Button */}
                                {/* {isDeleted && (
        <div className="mb-5 py-2 px-5 d-flex" style={{ borderRadius: '7px', backgroundColor: '#E8F0FE', alignItems: 'center' }}>
          <div className="text-primary"><AiOutlineInfoCircle size={20} style={{ color: '#1153aa' }} /></div>
          <p style={{ fontSize: '14px', marginBottom: '-2px', marginLeft: '30px' }}>This change overrides your account goals setup. Your campaign will now optimize for the goals shown below.</p>
          <div className="btn btn-sm fw-bold btn-active-color-primary text-primary d-flex flex-end" style={{ marginLeft: '200px' }} onClick={handleRestoreGoal}>Restore Account Goals</div>
        </div>
      )} */}

                            </>
                        </div>
                    )
                    )}

                    {/* </tbody> */}
                    {/* </table> */}


                </div>
                {isDeleted && (
                    <div className="nav-link btn btn-sm btn-color-primary btn-active btn-active-light fw-bold px-4 mt-5 d-flex flex-start"
                        onClick={() => setIsDeleted(false)}>
                        Add goal
                    </div>)}

            </div>
            {/* ))} */}

        </>
    );
};
export default DemoImprove;
