import React, { FC, useEffect, useMemo, useState } from "react";
import youtube from "../../../../assets/Displayyy.png";
import home from "../../../../assets/home.png";
import mmt from "../../../../assets/premium.png";
import icici from "../../../../assets/regular.png";
import hpcl from "../../../../assets/hpcl.png";
import facebook from "../../../../assets/searchhh.png";
import instagram from "../../../../assets/demand.png";
import google from "../../../../assets/vedio.png";
import linkedin from "../../../../assets/linkedin.png";
import twitter from "../../../../assets/performanceee.png";
import brand from "../../../../assets/shop.png";
import competitor from "../../../../assets/competitor.png";
import generic from "../../../../assets/marketing.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../modules/auth";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  getDataFromBackend,
  getMethodDataFromBackend,
  URLS,
} from "../../../modules/dashboards/DashboardRequests";
import CustomLoader from "./CustomLoader";
import { FormatUtil } from "../../../../theme/assets/ts/_utils/FormatUtil";
import { IoTriangleSharp } from "react-icons/io5";
import ChangeDateFormat from "../Custom_Functions/ChangeDateFormat";
import Modal from "./Modal";
import CustomInput from "./CustomInput";

type Props = {
  productId: string;
  startDate: string;
  endDate: string;
  platformId: string;
  channelType: string;
  subUrl: string;
  title?: string;
  productType?: string;
};

type campaignData = {
  data: {
    baseLineClicks: string;
    baseLineCost: string;
    baseLineCpc: string;
    baseLineConversion: string;
    clicks: string;
    cpc: string;
    cost: string;
    conversion: string;
    campaign?: string;
    productType: string;
    state: string;
    campaignName?: string;
    status?: string;
    createdDate?: any;
  }[];
  name: string;
};

const PlatformCard: React.FC<Props> = ({
  productId,
  startDate,
  endDate,
  platformId,
  channelType,
  subUrl,
  title,
  productType,
}) => {
  const firstColumn = "CAMPAIGNS";
  const { setBreadCrumbs, breadCrumb } = useAuth();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [respon, setRespon] = useState<campaignData[]>([]);
  const [data, setData] = useState<Array<{ name: string; id: number }>>([]);
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState<string>("All");
  const [type, setType] = useState<string>("Show All");
  const [status, setStatus] = useState<string>("Show All");
  const [searchTerm, setSearchTerm] = useState("");

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const getStatusStyle = (status) => {
    switch (status) {
      case 'Enabled':
      case 'ACTIVE':
          return { color: '#009ef7' };
      case 'Paused':
      case 'INACTIVE':
        return { color: 'green' };
      default:
        return {};
    }
  };

  const getSuggestionStyle = (status,suggestion) => {
    switch (status) {
      case 'Enabled':
      case 'ACTIVE':
        if(suggestion && suggestion.includes("Stop")){
          return { color: 'red' };
        }else{
          return { color: '#009ef7' };
        }
        
      case 'Paused':
      case 'INACTIVE':
        return { color: 'green' };
      default:
        return {};
    }
  };
  
  console.log("responrespon", respon)
  useEffect(() => {
    const getData = async () => {
      let requestData = {
        productId,
        startDate,
        endDate,
        platformId,
        productType,
      };
      let response = await getDataFromBackend(
        requestData,
        URLS.BASE_URL + channelType + "/" + subUrl
      );

      console.log(response,"response")

      let data = response?.data;
      console.log(
        "Response of platformacard : ",
        channelType + "/" + subUrl,
        data
      );
      if (response) {
        setRespon(data);
      } else {
        setRespon([
          {
            data: [
              {
                clicks: "7915.0",
                cpc: "23.121182564750505",
                cost: "183004.16000000024",
                conversion: "527.0",
                campaign:
                  "icicibank-a4-search-credit-card-core-cc-platinum-generic-payu-segment-2-phrase-apr",
                productType: "premium",
                state: "Paused",
                baseLineClicks: "string",
                baseLineCost: "string",
                baseLineCpc: "string",
                baseLineConversion: "string",
              },
            ],
            name: "All",
          },
        ]);
      }

      const convertedData = data.map((item, index) => ({
        name: item.name,
        id: index + 1,
      }));
      setData(convertedData);
    };

    getData();

    return () => { };
  }, [productId, startDate, endDate, platformId, productType]);

  const handleSelect = (e, item, data) => {
    console.log("handle...", item, e.target.value);
    if (item?.name?.toLowerCase() === "type") {
      console.log("TYPE..");
      return setType(data);
    } else if (item?.name?.toLowerCase() === "status") {
      console.log("STATUS....");
      return setStatus(data);
    } else {
    }
  };

  const status_data = [];

  const handleClick = (item) => {
    const campaignName = item.campaign || item.campaignName;
    setBreadCrumbs(`${campaignName} Reports`);
    console.log("first", item);
    navigate(`report/${campaignName}`, {
      state: { item, productId, platformId },
    });
  };

  let calculateBaseComparision = (value, baselineValue) => {
    const tempChange = ((value - baselineValue) / baselineValue) * 100;

    if (!Number.isFinite(tempChange)) {
      return ""
      // return "N/A";
    }

    const percentageChange = Math.abs(Number(tempChange.toFixed(2)));
    return `${percentageChange}%`;
  };

  let calculateGrowth = (value, baselineValue) => {
    if (parseFloat(value) > parseFloat(baselineValue)) {
      return true;
    } else {
      return false;
    }
  };

  const socialImageMap = {
    all: home,
    premium: mmt,
    freedom: icici,
    regular: youtube,
    millennia: hpcl,
    demand: instagram,
    search: facebook,
    display: youtube,
    linkedin: linkedin,
    "performance max": twitter,
    video: google,
    brand: brand,
    competitor: competitor,
    generic: generic,
  };

  const getStatusBadge = (item) => {
    let badgeClass = "";
    let badgeText = item?.state || item?.status;

    switch (badgeText?.toLowerCase()) {
      case "reviewing":
        badgeClass = "badge-light-primary";
        break;
      case "enabled":
        badgeClass = "badge-light-success";
        break;
      case "paused":
        badgeClass = "badge-light-warning";
        break;
      case "completed":
        badgeClass = "badge-light-info";
        break;
      default:
        badgeClass = "badge-light";
    }

    return (
      <span className={`badge ${badgeClass} fs-8 fw-bold text-capitalize`}>
        {badgeText === 'platinum' ? 'Premium' :
          (badgeText === 'cc pa ntp' ? 'Regular' :
            (badgeText === 'mmt' ? 'Freedom' :
              (badgeText === 'hpcl-super-saver' ? 'Millennia' : badgeText)))}
      </span>

    );
  };


  const renderMetric = (value, baseKey) => {
    const growth = calculateGrowth(value, baseKey);
    // const color = growth ? "green" : "#08112D";
    // const color =(baseKey === "cost" || baseKey === "cpc")? growth ?  "#08112D":"green" : growth ?"green" : "#08112D"
    // const color = growth ? "green":"#08112D";
    let color = growth ? "green" : "#08112D";

    if (baseKey === "cost" || baseKey === "cpc") {
      color = growth ? "#08112D" : "green";
    }

    const iconStyle = growth ? {} : { transform: "rotate(180deg)" };

    let formattedValue: number | string =
      value == "Infinity" ? 0 : Math.round(Math.abs(value));

    if (baseKey === "totalSpend" || baseKey === "cpc") {
      formattedValue = `₹${formattedValue}`;
    }

    return (
      <div className="fs-7 text-muted fw-bold d-flex align-items-center">
        <div>{formattedValue?formattedValue.toLocaleString("en-IN"):""}</div>
        <div
          className="mx-2 fw-bold"
          style={{ color, cursor: "pointer" }}
          title={
            baseKey === "totalSpend" || baseKey === "cpc"
              ? `Baseline: ₹${Math.abs(Math.round(baseKey)).toLocaleString(
                "en-IN"
              )}`
              : `Baseline: ${Math.abs(Math.round(baseKey)).toLocaleString(
                "en-IN"
              )}`
          }
        >
          {calculateBaseComparision(value,baseKey) !=="" && 
          <>
          {(calculateBaseComparision(value,baseKey) !=="" 
          || calculateBaseComparision(value,baseKey) !=="0" 
          || calculateBaseComparision(value,baseKey) !=="₹0") && 
            <IoTriangleSharp
            size={10}
            style={{ color, ...iconStyle, marginRight: "2px" }}
          />
          }
            
          {/* {value},{baseKey} */}
          {`${calculateBaseComparision(value, baseKey)}`}
          </>
          }
          
        </div>
      </div>
    );
  };

  const renderCostMetric = (value, baseKey) => {
    console.log("eeeeeeeeeeeessss", value);

    const growth = calculateGrowth(value, baseKey);
    let color = growth ? "#08112D" : "green";

    console.log("basek:", value);

    const iconStyle = growth ? {} : { transform: "rotate(180deg)" };

    let formattedValue: number | string =
      value == "Infinity" ? "N/A" : Math.round(Math.abs(value));

    if (baseKey === "totalSpend" || baseKey === "cpc") {
      formattedValue = `₹${formattedValue}`;
    }

    return (
      <div className="fs-7 text-muted fw-bold d-flex align-items-center">
        <div>₹{formattedValue.toLocaleString("en-IN")}</div>
        <div
          className="mx-2 fw-bold"
          style={{ color, cursor: "pointer" }}
          title={`Baseline: ₹${Math.abs(Math.round(baseKey)).toLocaleString(
            "en-IN"
          )}`}
        >
          
          
          

          {calculateBaseComparision(value,baseKey) !=="" && 
          <>
          {(calculateBaseComparision(value,baseKey) !=="" 
          || calculateBaseComparision(value,baseKey) !=="0" 
          || calculateBaseComparision(value,baseKey) !=="₹0") && 
          <IoTriangleSharp
          size={10}
          style={{ color, ...iconStyle, marginRight: "5px" }}
        />
          }
            
          {/* {value},{baseKey} */}
          {`${calculateBaseComparision(value, baseKey)}`}
          </>
          }
        </div>
      </div>
    );
  };

  const SocialMediaLink = ({
    social,
    selectedTab,
    setSelectedTab,
    socialImageMap,
  }) => {
    const selected = selectedTab?.toLowerCase() === social?.name.toLowerCase();
    const imageName = social?.name.toLowerCase();
    const imgSrc = socialImageMap[imageName];

    if (!imgSrc) return null;

    return (
      <li
        key={social.name}
        className={`nav-item mb-3 me-3 me-lg-6`}
        style={{ position: "relative" }}
      >
        <a
          className={`nav-link btn btn-outline btn-flex btn-color-muted 
              btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2
              ${selected ? "active" : ""}`}
          id={`kt_stats_widget_16_tab_link_${social.name}`}
          data-bs-toggle="pill"
          href={`#kt_stats_widget_16_tab_${social.name}`}
          onClick={() => setSelectedTab(social.name)}
        >
          <img
            src={imgSrc}
            alt={social.name}
            style={{
              height: "30px",
              width: "30px",
              marginTop: "4px",
            }}
          />
          <span
            className={`nav-text text-gray-800 fw-bold fs-8 lh-1 mt-2 ${selected ? "text-primary" : ""
              }`}
            onClick={() => setSelectedTab(social.name)}
          >
            {social.name}
          </span>
          {selected && (
            <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
          )}
        </a>
      </li>
    );
  };

  const StatusDropdown = ({ item, status, type, handleSelect }) => {
    return (
      <div className="d-flex align-items-center fw-bold flex-end">
        <span className="text-muted fs-7">{item?.name}</span>

        <div className="dropdown">
          <div
            data-kt-daterangepicker="true"
            data-kt-daterangepicker-opens="left"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary d-flex align-items-center px-4 dropdown-toggle"
          >
            <div className="text-dark fw-bold">
              {item?.name === "Type" ? type : status}
            </div>
            <div className="dropdown-custom"></div>
          </div>
          <ul
            className="dropdown-menu dropdown-menu-end"
            aria-labelledby="dropdownMenuButton1"
          >
            {item?.dropdownData?.map((data) => (
              <li onClick={(e) => handleSelect(e, item, data)}>
                <span
                  className={`dropdown-item text-hover-primary ${data === status
                    ? "text-primary bg-light-secondary tick-icon"
                    : ""
                    }`}
                >
                  {data}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const TableComponent = ({
    selectedTab,
    respon,
    handleClick,
    renderMetric,
    getStatusBadge,
    viewAll,
  }) => {
    const dataToRender = viewAll
      ? respon.find((group) => group.name === selectedTab)?.data
      : respon.find((group) => group.name === selectedTab)?.data?.slice(0, 5);

    const filteredDataToRender = useMemo(() => {
      if (!searchTerm) {
        return dataToRender;
      }

      const filteredData =
        subUrl == "campaigntypes"
          ? dataToRender?.filter((group) =>
            group?.campaignName?.toLowerCase().includes(searchTerm)
          )
          : dataToRender?.filter((group) =>
            group?.campaign?.toLowerCase().includes(searchTerm)
          );

      return viewAll ? filteredData : filteredData?.slice(0, 5);
    }, [searchTerm, dataToRender, viewAll]);
console.log("filteredDataToRender",filteredDataToRender)
    return (
      <div className="table-responsive px-7">
        {dataToRender && dataToRender?.length > 0 ? (
          <table
            id="kt_widget_table_3"
            className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3"
            data-kt-table-widget-3="all"
          >
            <thead className="fs-7 fw-bold text-gray-500">
              <tr>
                <th>CAMPAIGNS</th>
                <th>STATUS</th>
                <th>CLICKS</th>
                <th>CPC</th>
                <th>COST</th>
                <th>CONVERSIONS</th>
                <th>SUGGESTIONS</th>
              </tr>
            </thead>
            <tbody>
              {selectedTab &&
                filteredDataToRender?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="w-300px">
                        <div className="position-relative ps-6 pe-3 py-2">
                          {!viewAll && (
                            <div
                              className={`position-absolute start-0 top-0 w-4px h-100 rounded-2`}
                              style={{
                                backgroundColor:
                                  respon[0]?.name === "All"
                                    ? "#E84A42"
                                    : respon[0]?.name === "Search"
                                      ? "#E84A42"
                                      : "#ade095",
                              }}
                            ></div>
                          )}
                          <span
                            className=" fw-bold fs-6 mb-1 text-dark text-hover-primary cursor-pointer"
                            onClick={() => handleClick(item)}
                          >
                            {item?.campaign || item?.campaignName}
                          </span>
                          {/* {!viewAll &&
                            <span className="text-muted fw-bold d-block fs-7">
                              {item?.createdDate ? "Created on " + ChangeDateFormat(item?.createdDate) : ""}
                            </span>
                          } */}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center" style={getStatusStyle(item?.status)}>
                          {item?.status}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          {renderMetric(item?.clicks, item?.baseLineClicks)}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          {renderCostMetric(item?.cpc, item?.baseLineCpc)}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          {renderCostMetric(item?.cost, item?.baseLineCost)}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center"></div>
                        {renderMetric(item?.conversion,item?.baseLineConversion)}
                      </td>
                    
                      <td>
                        <div className="d-flex align-items-center" style={getSuggestionStyle(item?.status,item?.suggestion)}>
                          {item?.suggestion}
                        </div>
                      </td>

                      {/* <td>{getStatusBadge(item)}</td> */}
                      {/* {!viewAll && ( */}
                      <td className="text-end">
                        <button
                          type="button"
                          className="btn btn-icon btn-sm btn-light btn-active-primary w-25px h-25px"
                          onClick={() => handleClick(item)}
                        >
                          <i className="bi bi-arrow-right-short fs-2 text-muted"></i>
                        </button>
                      </td>
                      {/* )} */}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <div className="text-center mt-5">
            <span className="text-gray-400 fw-bold fs-6">
              No Campaigns found
            </span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="card card-flush h-xl-100 "
      style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
    >
      <div
        className="card card-flush h-xl-100 flex-grow-1 flex-shrink-1"
        style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
      >
        <div className="card-header pt-5" style={{ position: "relative" }}>
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-800">{title}</span>

            <span className="text-gray-400 pt-2 fw-semibold fs-6">
              {" "}
              75% activity growth
            </span>
          </h3>

          <div className="col-md-4">
            <CustomInput
              label={"Search"}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              onCancel={() => setSearchTerm("")}
              id={"Search"}
            />
          </div>
          {/* <div className="card-toolbar">
            <button
              className="btn btn-icon btn-color-gray-400 btn-active-color-primary 
							justify-content-end"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-overflow="true"
            >
              <i className="ki-duotone ki-dots-square fs-1">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
              </i>
            </button>
          </div> */}
        </div>

        <div
          className=" py-3 px-7"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ul className="nav">
            {data?.map((social) => (
              <SocialMediaLink
                key={social.name}
                social={social}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                socialImageMap={socialImageMap}
              />
            ))}
          </ul>
        </div>

        {respon?.length > 0 ? (
          <div className="card card-flush h-lg-100 p-5">
            <TableComponent
              selectedTab={selectedTab}
              respon={respon}
              handleClick={handleClick}
              renderMetric={renderMetric}
              getStatusBadge={getStatusBadge}
              viewAll={false}
            />
          </div>
        ) : (
          <div style={{ alignSelf: "center", marginBottom: "50px" }}>
            <CustomLoader />
          </div>
        )}

        {respon.find((e) => e.name == selectedTab)?.data?.slice(5, 6).length !==
          0 && (
            <button
              type="button"
              className="nav-link btn btn-sm btn-color-primary btn-active btn-active-light fw-bold px-2 d-flex flex-wrap"
              style={{
                fontSize: "14px",
                position: "absolute",
                right: "0",
                marginRight: "10px",
                bottom: "5px",
              }}
              onClick={() => setIsModalOpen(!isModalOpen)}
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor = "#F5F5F5";
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor = "#FFFFFF";
              }}
            >
              <a href="#">View More</a>
            </button>
          )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title={title}
        width={"110%"}
      >
        <div className="card card-flush h-xl-100">
          <TableComponent
            selectedTab={selectedTab}
            respon={respon}
            handleClick={handleClick}
            renderMetric={renderMetric}
            getStatusBadge={getStatusBadge}
            viewAll={true}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PlatformCard;
