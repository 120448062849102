import { useEffect, useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { KTCard } from "../../../../theme/helpers";
import { AiOutlineQuestionCircle, AiOutlineSearch } from "react-icons/ai";
import { BsCurrencyRupee } from "react-icons/bs";
import { BsExclamationCircle } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import search from "../../../../assets/newsearch (1).png";
import display from "../../../../assets/newdisplay (1).png";

import { FaTimes } from "react-icons/fa";
import { Badge } from "react-bootstrap";
import AudienceSegment from "./AudienceSegment";
import MoreSettings from "./MoreSettings";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import CustomQuestion from "./customQuestion";
import { campaignSettings } from "../../../modules/application/_requests";
import Location from "./Location";
import Languages from "./Languages";

const CampaignSettings = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isExpanded2, setIsExpanded2] = useState(true);
  const [isExpanded3, setIsExpanded3] = useState(true);
  const [isExpanded4, setIsExpanded4] = useState(false);
  const [isExpanded5, setIsExpanded5] = useState(false);

  const [type, setType] = useState<string>("Conversions");
  const [status, setStatus] = useState<string>("Conversions");

  const [isChecked2, setIsChecked2] = useState(true);

  const [showInputBox, setShowInputBox] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [hoveredLanguage, setHoveredLanguage] = useState("");
  const [headerColor, setHeaderColor] = useState("");
  const [headerColor2, setHeaderColor2] = useState("");
  const [headerColor4, setHeaderColor4] = useState("");
  const [campaignId, setCampaignId] = useState("");

  const [style, setStyle] = useState<string[]>([]);
  const styles = [
    {
      id: "1",
      name: "Fashionistas",
    },
    {
      id: "2",
      name: "Formal Wears",
    },
    {
      id: "3",
      name: "Shoppers",
    },
    {
      id: "4",
      name: "Children Aparel's",
    },
    {
      id: "5",
      name: "Concert and Music Festivals Tickets",
    },
    {
      id: "6",
      name: "Performing Arts Tickets",
    },

    {
      id: "7",
      name: "Sports Tickets",
    },
    {
      id: "8",
      name: "Financial Services",
    },
  ];

  // const [inputValue, setInputValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  console.log(inputValue,"innnnnnnnnnnnnn")

  const navigate = useNavigate();
  const location = useLocation();
  const idd: any = location.state;
  console.log(idd, "iddddddddddd");
  const handleClick = () => {
    const saveddata = {
      campaign_id: idd?.campaignId,
      Network: selectedNames,
      Languages: selectedLanguages,
      Target_Loaction: {
        // location: selectedOption,
        location: selectedOption === "Enter another location" ? inputValue : selectedOption,
        target: selectedTarget,
      },
      Audience_Segment: style,
    };

    // const file = values.fileName[0];
    console.log(saveddata, "nnnnnnmmmmmmmmm");
    const response = campaignSettings(saveddata)
      .then((response) => {
        console.log("Campaign Settings Added Successfully!");
        console.log(response, "Campaign");

        setTimeout(() => {
          console.log(undefined);
        }, 2000);
        // const campaignId = response.campaign_id;
        setCampaignId(response.campaign_id);
      })
      .catch((err) => {
        alert("Request Failed!");
      });

    // navigate("/keywords");
  };

  const languages = [
    {
      id: "1",
      name: "All Languages",
    },
    {
      id: "2",
      name: "Arabic",
    },
    {
      id: "3",
      name: "Bengali",
    },
    {
      id: "4",
      name: "Bulgarian",
    },
    {
      id: "5",
      name: "Catalan",
    },
    {
      id: "6",
      name: "Chinese(simplified)",
    },

    {
      id: "7",
      name: "Chinese(Traditional)",
    },
    {
      id: "8",
      name: "Croatian",
    },
    {
      id: "9",
      name: "Czech",
    },
    {
      id: "10",
      name: "Danish",
    },
    {
      id: "11",
      name: "Dutch",
    },
    {
      id: "12",
      name: "English",
    },
    {
      id: "13",
      name: "Estonian",
    },
    {
      id: "14",
      name: "Filipino",
    },
    {
      id: "15",
      name: "Finnish",
    },
    {
      id: "16",
      name: "French",
    },
    {
      id: "17",
      name: "German",
    },
    {
      id: "18",
      name: "Greek",
    },
    {
      id: "19",
      name: "Gujarati",
    },
    {
      id: "20",
      name: "Hebrew",
    },
    {
      id: "21",
      name: "Hindi",
    },
    {
      id: "22",
      name: "Hungarian",
    },
    {
      id: "23",
      name: "Icelandic",
    },
    {
      id: "24",
      name: "Indonesian",
    },
    {
      id: "25",
      name: "Italian",
    },
    // {
    //   id: "26",
    //   name: "Japanese",
    // },
    // {
    //   id: "27",
    //   name: "Korean",
    // },
    // {
    //   id: "28",
    //   name: "Latvian",
    // },
    // {
    //   id: "29",
    //   name: "Lithuanian",
    // },
    // {
    //   id: "30",
    //   name: "Malay",
    // },
    // {
    //   id: "31",
    //   name: "Malayalam",
    // },
    // {
    //   id: "32",
    //   name: "Marathi",
    // },
    // {
    //   id: "33",
    //   name: "Norwegian",
    // },
    // {
    //   id: "34",
    //   name: "Persian",
    // },
    // {
    //   id: "35",
    //   name: "Polish",
    // },
    // {
    //   id: "36",
    //   name: "Portuguese",
    // },
    // {
    //   id: "37",
    //   name: "Punjabi",
    // },
    // {
    //   id: "38",
    //   name: "Roamania",
    // },
    // {
    //   id: "39",
    //   name: "Russian",
    // },
    // {
    //   id: "40",
    //   name: "Serbian",
    // },
    // {
    //   id: "41",
    //   name: "Slovak",
    // },
    // {
    //   id: "42",
    //   name: "Slovenian",
    // },
    // {
    //   id: "43",
    //   name: "Spanish",
    // },
    // {
    //   id: "44",
    //   name: "Swedish",
    // },
    // {
    //   id: "45",
    //   name: "Tamil",
    // },
    // {
    //   id: "46",
    //   name: "Telugu",
    // },
    // {
    //   id: "47",
    //   name: "Thai",
    // },
    // {
    //   id: "48",
    //   name: "Turkish",
    // },
    // {
    //   id: "49",
    //   name: "Ukrainian",
    // },
    // {
    //   id: "50",
    //   name: "Urdu",
    // },
    // {
    //   id: "51",
    //   name: "Vietnamese",
    // },
  ];

  const territory = [
    {
      id: "1",
      tname: "All countries and territories",
    },
    {
      id: "2",
      tname: "India",
    },
    {
      id: "3",
      tname: "Enter another location",
    },
  ];
  const [selectedOption, setSelectedOption] = useState(
    "All countries and territories"
  );
 


  const [selectedTarget, setSelectedTarget] = useState("");
  const target = [
    {
      id: "1",
      tarname:
        "Presence or interest: People in, regularly in, or who've shown interest in your targeted locations (recommended)",
    },
    {
      id: "2",
      tarname: "Presence: People in your excluded locations(recommended)",
    },
  ];

  const networks = [
    {
      id: "1",
      image: search,
      nname: "Search Networks",
      description:
        "Ads can appear near Google Search results and other Google sites when people search for terms that are relevant to your keywords",
      check: "Include Google search networks",
      applybold: "Expand your reach with Google search partners: ",
      applyunbold: "Reach additional customers on partner sites ",
      qdetail:
        "To help you get a similar cost per conversion as you get on the Search network, some clicks on the Search partner network may cost more or less than your Google Search Max. CPC bid. Search partner performance won't impact your Search Quality Score.",
      applyqdetail:
        "Showing your ads on Google search partners websites can help increase your ads’ reach and traffic. Clickthrough rates on search partners don't affect Quality Scores on Google Search. You're seeing this recommendation because some of your Search Network or Shopping campaigns can drive additional traffic by expanding to Google search partners. Note that if you’re limited by budget, you may need to increase your budget to fully benefit from this recommendation.",
    },
    {
      id: "2",
      image: display,
      nname: "Display Networks",
      description:
        "Easy way to get additional conversions at similar or lower costs than Search with unused Search budget.",
      check: "Include Google Display networks",
      applybold: "Use Display Expansion: ",
      applyunbold:
        "Show your ads in more places and get more conversions at a similar cost per action (CPA) ",
      qdetail:
        "What to know about expanding your Search campaign to Google Display:You'll get incremental conversions on Display at similar or lower costs than on Search Your ads will appear only when you're not using all your budget on Search or when Display conversions are cheaper and your other Display campaigns are not eligible to serve Your Display performance won’t impact your Search Quality Score No additional setup is required for one-click opt-in",
      applyqdetail:
        "You’re seeing this recommendation because some of your Search campaigns can get more conversions by using unspent budget on the Google Ads Display Network.",
    },
  ];
  const [isChecked, setIsChecked] = useState(networks.map(() => false));
  const handleCheckboxChange2 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setSelectedLanguage(event.target.value);
    if (value === "All Languages") {
      if (event.target.checked) {
        // Select all languages
        const allLanguages = languages.map((language) => language.name);
        setSelectedLanguages(allLanguages);
      } else {
        // Deselect all languages
        setSelectedLanguages([]);
      }
    } else {
      if (event.target.checked) {
        setSelectedLanguages((prevSelectedLanguages) => [
          ...prevSelectedLanguages,
          value,
        ]);
      } else {
        setSelectedLanguages((prevSelectedLanguages) =>
          prevSelectedLanguages.filter((language) => language !== value)
        );
      }
    }
  };

  // const handleClick = () => {
  //   // setShowCampaignType(true);
  //   navigate("/keywords");
  // };
  const handleTogglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "Enter another location") {
      setShowInputBox(true);
    } else {
      setShowInputBox(false);
    }
  };
  console.log(selectedOption, "bbbbbbbbbbbbjjjjjjjjj");

  const handleTargetChange = (event) => {
    setSelectedTarget(event.target.value);
  };
  const handleMouseEnter = (language) => {
    setHoveredLanguage(language);
  };

  const handleMouseLeave = () => {
    setHoveredLanguage("");
  };

  const handleToggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  const handleToggleContent2 = () => {
    setIsExpanded2(!isExpanded2);
  };

  const handleToggleContent3 = () => {
    setIsExpanded3(!isExpanded3);
  };
  const handleToggleContent4 = () => {
    setIsExpanded4(!isExpanded4);
  };

  const handleCheckbox2 = () => {
    setIsChecked2(!isChecked2);
  };

  const [selectedNames, setSelectedNames] = useState<string[]>([]);

  const handleCheckbox = (index: number) => {
    const updatedChecked = [...isChecked];
    updatedChecked[index] = !updatedChecked[index];
    setIsChecked(updatedChecked);

    setSelectedNames((prevNames) => {
      if (updatedChecked[index]) {
        return [...prevNames, networks[index].nname];
      } else {
        return prevNames.filter((name) => name !== networks[index].nname);
      }
    });
  };

  console.log(selectedNames, "names");

  // const handleInputChange = (e) => {
  //   setInputValue(e.target.value);
  // };
  const handleRemoveLanguage = (language: string) => {
    setSelectedLanguages((prevSelectedLanguages) =>
      prevSelectedLanguages.filter((item) => item !== language)
    );
  };
 
  const handleApply = (network) => {
    const updatedChecked = [...isChecked];
    const networkIndex = networks.findIndex((item) => item.nname === network);

    if (networkIndex !== -1) {
      updatedChecked[networkIndex] = true;
      setIsChecked(updatedChecked);
    }
  };
  console.log(networks, "ischeckhed");

  useEffect(() => {
    handleClick(); // Trigger the handleClick function whenever the relevant data changes
  }, [selectedNames, selectedLanguages, selectedOption, selectedTarget, style]);

  return (
    <>
      <div className="container px-10 mb-10">
        <div className="px-10">
          {/* <span className="fw-bold text-dark fs-6" style={{marginBottom:"20px"}}>
              To reach the right people, start by defining key settings for your campaign{" "}
                    
                    </span> */}
          <div
            className="card"
            style={{
              // overflow: "hidden",yyy
              marginBottom: isExpanded ? "20px" : "0px",
              backgroundColor: isExpanded ? "" : "#E1E2E3",
              boxShadow: isExpanded ? "0 2px 8px rgba(0, 0, 0, 0.5)" : "",
            }}
          >
            <div
              className="card-header d-flex align-items-center"
              onClick={handleToggleContent}
              onMouseOver={() => setHeaderColor("#E1E2E3")}
              onMouseOut={() => setHeaderColor("")}
              style={{
                backgroundColor: headerColor || "#FFFFFF", // Set default color to white
                cursor: "pointer",
                border: "1px solid #ced4da",
                transition: "background-color 0.3s ease",
                minHeight: "48px",
                borderRadius: "5px",
              }}
            >
              <div
            className="row gx-xl-12 gy-5 d-flex align-items-center"
            style={{ width: "100%" }}
          >
            <div className="col-xl-3">
              <h5 className="card-title">Networks</h5>
            </div>
            <div className="col-xl-8">
              {!isExpanded ? (
                // <h5 className="card-title" style={{ color: "rgba(0,0,0,.54)",marginLeft: "-45%" }}>
                //  {networks.find((network, index) => isChecked[index])?.nname || "No networks targeted"}
                // </h5>
                <h5
                  className="card-title"
                  style={{ color: "rgba(0,0,0,.54)" }}
                >
                  {isChecked.every((checked) => checked)
                    ? networks.map((network) => network.nname).join(", ")
                    : networks
                        .filter((network, index) => isChecked[index])
                        .map((network) => network.nname)
                        .join(", ") || "No networks targeted"}
                </h5>
              ) : null}
              </div>
              <div className="col-xl-1 d-flex align-items-center justify-content-end">
              {isExpanded ? (
                <BsChevronUp className="arrow-icon" />
              ) : (
                <BsChevronDown className="arrow-icon" />
              )}
              </div>
              </div>
            </div>
            {isExpanded && (
              <div className="card-body">
                <div
                  className="container"
                  style={{ backgroundColor: "white", borderRadius: "10px" }}
                >
                  {/* <KTCard> */}

                  {networks.map((network, index) => (
                    <div className="row gy-5 gx-xl-12 mb-5 d-flex">
                      <div className="col-lg-2">
                        <img
                          src={network.image}
                          alt="image"
                          style={{ maxWidth: "130px", maxHeight: "150px" }}
                        />
                      </div>
                      <div className="col-xl-10 px-5">
                        {/* {networks.map((network, index) => ( */}
                        <div key={network.id}>
                          <div
                            className="fw-bold text-dark mb-3"
                            style={{
                              fontFamily: "Google Sans,Roboto,Arial,sans-serif",
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#202124",
                              alignItems: "center",
                            }}
                          >
                            {network.nname}
                          </div>
                          <span
                            className=""
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#3c4043",
                            }}
                          >
                            {network.description}
                          </span>
                          <br />
                          <div className="d-flex align-items-center my-3">
                            <input
                              type="checkbox"
                              checked={isChecked[index]}
                              onChange={() => handleCheckbox(index)}
                              style={{
                                width: "10px",
                                height: "10px",
                                borderWidth: "3px",
                                marginRight: "10px",
                                transform: "scale(2)",
                              }}
                            />
                            <span
                              className=""
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#3c4043",
                              }}
                            >
                              {network.check}{" "}
                              <CustomQuestion>
                                {network.qdetail}{" "}
                                <p>
                                  <u style={{ color: "#009EF7" }}>
                                    <a href="#"> Learn more</a>
                                  </u>
                                </p>
                              </CustomQuestion>
                            </span>
                          </div>

                          {!isChecked[index] && (
                            <div
                              className="card my-7 px-5"
                              style={{
                                border: "1px solid gray",
                                display: "flex",
                              }}
                            >
                              <div className="row gy-5 gx-xl-12">
                                <div className="col-xl-11 p-3 d-flex  align-items-center ">
                                  <div className="">
                                    <AiOutlineSearch
                                      size={30}
                                      style={{
                                        padding: "5px",
                                        color: "#FF8C00",
                                        borderRadius: "50px",
                                        marginRight: "10px",
                                        backgroundColor: "#FEEFE3",
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="text-dark pl-5 align-items-center justify-content-center"
                                    style={{
                                      fontSize: "14px",
                                      textAlign: "left",
                                    }}
                                  >
                                    <b>{network.applybold}</b>
                                    {network.applyunbold}{" "}
                                    <CustomQuestion>
                                      {network.applyqdetail}
                                      <p>
                                        <u style={{ color: "#009EF7" }}>
                                          <a href="#"> Learn more</a>
                                        </u>
                                      </p>
                                    </CustomQuestion>
                                  </div>
                                </div>
                                <div className="col-xl-1 d-flex align-items-center justify-content-center">
                                  <button
                                    className="btn btn-light d-flex align-items-center justify-content-center"
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#007bff",
                                      border: "none",
                                      height: "40px",
                                      width: "70px",
                                      transition: "background-color 0.3s",
                                      fontSize: "15px",
                                    }}
                                    onClick={() => handleApply(network.nname)}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <Location
            selectedOption={selectedOption}
            selectedTarget={selectedTarget}
            setInputValue={setInputValue}
            inputValue={inputValue}
            setSelectedOption={setSelectedOption}
            setSelectedTarget={setSelectedTarget}
            territory={territory}
            target={target}
          />

          <Languages
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
            selectedLanguages={selectedLanguages}
            setSelectedLanguages={setSelectedLanguages}
            languages={languages}
          />

          <AudienceSegment style={style} setStyle={setStyle} styles={styles} />
          {/* <div className="" onClick={handleClick}>  */}
          <MoreSettings campaignId={campaignId} />
          {/* </div>  */}

          {/* <div className="">
            <div
              className="gap-2 my-5 d-flex"
              style={{ justifyContent: "flex-end" }}
            >
              <a
                href="#"
                className="btn btn-sm fw-bold btn-primary"
                onClick={handleClick}
              >
                Next
              </a>
            
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CampaignSettings;
