/**
 * /* eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

import { FC, useEffect, useRef, useState } from "react";
import "./Dropdown.scss";
import FeaturedCamp from "./marketting_component/FeaturedCamp";
import ProductCard from "./marketting_component/ProductCard";
import CreditGraph from "./marketting_component/CreditGraph";
import SmallDeviceCard from "./marketting_component/SmallDeviceCard";
import IndiaMapChart from "./capaign_components/IndiaMapChart";
import ProductGenderCard from "./marketting_component/ProductGenderCard";
import MultiHorizontalBarGraph from "./components/MultiHorizontalBarGraph";
import { ProductLeadsListing } from "./capaign_components/ProductLeadsListing";
import { DateRangePicker } from "materialui-daterange-picker";
import AmChartStickBarGrouped from "./AmChartStickBarGrouped";
import SideGNW from "./SideGNW";
import CampaignStats from "../../../theme/layout/components/header/header-menus/CampaignStats";
import {
  AiOutlineFieldTime,
  AiTwotoneCalendar,
  AiOutlineProject,
} from "react-icons/ai";
import PlatformNav from "./marketting_component/PlatformNav";
import AmFunnelChart from "./marketting_component/AmFunnelChart";
import { useLocation } from "react-router-dom";
import {
  URLS,
  getMethodDataFromBackend,
} from "../../modules/dashboards/DashboardRequests";
import ProductStatsCard from "../../../theme/layout/components/header/header-menus/ProductStatsCard";
import SpendLineGraph from "./capaign_components/SpendLineGraph";
import HeaderDropdown from "./Custom_Components/HeaderDropdown";
import CustomDateRangePicker from "./Custom_Components/CustomDateRangePicker";
import ProductCardComponent from "./marketting_component/ProductCardComponent";
import CenteredLine from "./CenterLine";
import ForecastCard from "../../../theme/layout/components/header/header-menus/ForecastCard";
import colapse from "../../../../src/assets/cllaspe.png"
import { BsThreeDotsVertical } from "react-icons/bs";

const ProductDashboard: FC = () => {
  const location = useLocation();
  const state: any = location.state;
  const product = !!location.pathname.includes('/product-dashboard');


  console.log("params in product dashboard",location, state);
  const [platformId, setPlatformId] = useState<any>("1");
  const [productId, setProductId] = useState(state?.productId);
  const [startDate, setStartDate] = useState("2023-06-01");
  const [endDate, setEndDate] = useState("2023-06-30");
  const [productTypeDropdown, setProductTypeDropdown] = useState([]);
  const [baseLineDropdown, setBaseLineDropdown] = useState(["custom"]);
  const [selectedBaselineValue, setSelectedBaselineValue] =
    useState("Baseline");
  const [selectedProductType, setSelectedProductType] = useState("All");
  const [forecastedValue, setForecastedValue] = useState<any>();

  console.log(forecastedValue, 'forecastglb')

  const handleDropdownItemClick = (data) => {
    setSelectedBaselineValue(data);
  };

  const handleProductTypeDropdownItemClick = (data) => {
    if (data == "all") {
      setSelectedProductType("All");
    } else {
      setSelectedProductType(data);
    }
  };

  useEffect(() => {
    const getData = async () => {
      let responseProductDrop = await getMethodDataFromBackend(
        URLS.GET_PRODUCTTYPES
      );
      let productDropData = responseProductDrop?.data;
      setProductTypeDropdown(productDropData);
      console.log("prodtypedrop", responseProductDrop?.data);
    };
    getData();
    return () => { };
  }, [startDate, endDate]);

  useEffect(() => {
    setProductId(state?.productId);
  }, [state]);

  useEffect(() => {
    document.title = "Product Dashboard - Camp AI Gain";
    setDateFilter();
  }, []);

  const setDateFilter = async () => {
    try {
      const response = await getMethodDataFromBackend(URLS.BASE_URL + "newproduct/getdates");
      console.log("setDateFilter response:-", response)
      if(response?.data?.dashboardLevel === "Product") {
        setStartDate(formatDate(response.data.startDate));
        setEndDate(formatDate(response.data.endDate));
      }
    } catch(err) {

    }
  }

  const formatDate = (inputDate) => {
    // Parse input date string
    const date = new Date(inputDate);
    
    // Extract year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because month index starts from 0
    const day = String(date.getDate()).padStart(2, '0');
    
    // Return formatted date string
    return `${year}-${month}-${day}`;
  }

  const getDate = (currentDate) => {
    const offset = currentDate.getTimezoneOffset();
    let yourDateObj = new Date(currentDate.getTime() - offset * 60 * 1000);
    return yourDateObj.toISOString().split("T")[0];
  };

  const handleDateRangeChange = (dateRange) => {
    setStartDate(getDate(dateRange?.startDate));
    setEndDate(getDate(dateRange?.endDate));
  };
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const handleDropdownClick = () => {
    setIsCollapsed(false);
  };


  const getData = async () => {
    try {
      let responseBaseline = await getMethodDataFromBackend(
        URLS.BASE_URL + "baseline/options"
      );

      let data = responseBaseline?.data;

      if (data) {
        console.log("responseBaseline", data);
        setBaseLineDropdown(data);
      } else {
        console.log("No data received from the backend");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const element = document.getElementById("testId2");
      if (element) {
        element.style.position = scrollPosition > 10 ? "fixed" : "absolute";
        element.style.right = "30px";
        // element.style.zIndex = 1;
        element.style.width = "100%";
        element.style.top = scrollPosition > 10 ? "70px" : "85px";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        id="testId2"
        className={`d-flex flex-end`}
        style={{
          position: "absolute",
          right: "30px",
          zIndex: 1,
          width: "100%",
          top: '85px'
        }}
      >
        <HeaderDropdown
          selectedValue={selectedProductType}
          dropdownItems={productTypeDropdown}
          onDropdownItemClick={handleProductTypeDropdownItemClick}
        />

        <HeaderDropdown
          selectedValue={selectedBaselineValue}
          dropdownItems={baseLineDropdown}
          onDropdownItemClick={handleDropdownItemClick}
        />

        <CustomDateRangePicker
          startDate={startDate}
          endDate={endDate}
          onDateRangeChange={handleDateRangeChange}
        />
      </div>

      {/* <div className="d-flex align-items-center justify-content-end px-0 py-5">
        <PlatformNav
          startDate={startDate}
          endDate={endDate}
          productId={productId}
          productType={selectedProductType}
        />

        <SideGNW
          startDate={startDate}
          endDate={endDate}
          platformId={platformId}
          productId={productId}
          channelType={"product"}
          statsSubUrl={"/overallstats"}
          productType={selectedProductType}
          baseLineType={"Baseline"}
        />
      </div> */}
      {isCollapsed && product &&
      <div className="card-header h-30px d-flex justify-content-end align-items-end" onClick={toggleCollapse} style={{ cursor: 'pointer',marginBottom:"-37px" }}>
        <div className="card h-70px "style={{ transform: 'rotate(-90deg)',borderRadius: '30px 30px 0 0',boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)' }}>
        <div className="dropdown dropdown-toggle px-3" style={{ color: 'red', transform: 'rotate(-180deg)', fontSize: '25px' }}>
        </div>
        <img src={isCollapsed ? colapse : colapse} height={'30px'} width={'30px'} alt={isCollapsed ? 'Expand' : 'Collapse'} style={{transform: 'rotate(90deg)'}} />
        </div>
        
      </div>
      }
      <div className="card w-100" style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)", }}>

        { <div className={`card-body  w-100 ${isCollapsed ? 'collapse' : 'show'}`} >
          <div className="d-flex h-35px  " style={{ boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',width:"5%",borderRadius: '0 20px 20px 0',marginLeft:"-27px",marginTop:"-24px"}} onClick={toggleCollapse}>
            <img
            height={'35px'}
            width={'35px'}
            src={isCollapsed ? colapse : colapse}
            alt={isCollapsed ? 'Expand' : 'Collapse'} />
            <div className="dropdown dropdown-toggle px-3" style={{ color: 'red', transform: 'rotate(-90deg)', fontSize: '25px' }}></div></div>
          
          {/* <BsThreeDotsVertical onClick={handleDropdownClick} style={{ cursor: 'pointer' }} /> */}
          <ForecastCard
            platformId={platformId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            channelType={"newproduct"}
            baseLine={selectedBaselineValue}
            productType={selectedProductType}
            statsSubUrl={"/overallstats"}
            setSelectedProductType={setSelectedProductType}
            setForecastedValue={setForecastedValue}
          />
        </div>}
      </div>
      <ProductStatsCard
        platformId={platformId}
        productId={productId}
        startDate={startDate}
        endDate={endDate}
        channelType={"newproduct"}
        baseLine={selectedBaselineValue}
        productType={selectedProductType}
        statsSubUrl={"/overallstats"}
        forecastedValue={forecastedValue}
      />

      <div className="row gy-5 gx-xl-10 mb-10">
        <div className="col-xl-8 ">
          {/* <FeaturedCamp
            title="Featured Campaigns"
            firstColumn="CAMPAIGN TITLE"
            productId={productId}
            startDate={startDate}
            productType={selectedProductType}
            endDate={endDate}
          /> */}
          <SpendLineGraph
            chartID={`spendlinegraph`}
            title={"Spends in INR"}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            productType={selectedProductType}
            subUrl={"newproduct/spends"}
            platformId={platformId}
            channelType="product"
          />
        </div>
        <div className="col-xl-4">
          <AmFunnelChart
            title={"Reach, Engage and Convert"}
            chartID="funnelChart81"
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            channelType={"newproduct"}
            campaignId={"0"}
            subUrl={"/overallconversionfunnel"}
            productType={selectedProductType}
            platformId={platformId}
          />
        </div>
      </div>
      <CenteredLine title={"Product Type Metrics"} />
      {/* <div
        className="row gy-5 gx-xl-10 mb-10"
        style={{ display: "flex", alignItems: "stretch" }}
      >
        <div className="col-xl-12"> */}
      <ProductCardComponent
        productId={productId}
        startDate={startDate}
        productType={selectedProductType}
        endDate={endDate}
        channelType={"newproduct"}
        subType={"product"}
      />
      {/* </div>
      </div> */}
      {selectedProductType == "All" && (
        <>
          <CenteredLine title={"Platform Type Metrics"} />
          <ProductCardComponent
            productId={productId}
            startDate={startDate}
            productType={selectedProductType}
            endDate={endDate}
            channelType={"newproduct"}
            subType={"platform"}
          />
        </>
      )}

      <div className="row gy-5 gx-xl-12 mb-10">
        <div className="col-xxl-12 ">
          <MultiHorizontalBarGraph
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            chartID="productBudgetGraph"
            productType={selectedProductType}
            platformId={platformId}
            channelType={"newproduct"}
            title={"Apps & Eligible Leads"}
            subUrl="/budget/appsleads"
            baseLine={selectedBaselineValue}
          />
        </div>
        {/* <div className="col-xxl-4 ">
          <IndiaMapChart
            chartID={"indiaMapClicks"}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            height={"400px"}
            marginTop={"0px"}
            platformId={platformId}
            productType={selectedProductType}
            campaignId={""}
          />
        </div> */}
      </div>

      <div className="row gy-5 gx-xl-12 mb-10">
        <div className="col-xxl-12 ">
          <MultiHorizontalBarGraph
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            chartID="productBudgetGraph-1"
            productType={selectedProductType}
            platformId={platformId}
            channelType={"newproduct"}
            title={"Spends"}
            subUrl="/budget/spends"
            baseLine={selectedBaselineValue}
          />
        </div>
      </div>

      {/* <div className="row gy-5 gx-xl-12">
        <div className="col-xxl-12 ">
          <AmChartStickBarGrouped
            platformId={platformId}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            productType={selectedProductType}
            channelType={"product"}
          />
        </div>
      </div> */}

      {/* <div className="row gy-5 gx-xl-10 mb-10">
        <div className="col-xl-4">
          <ProductLeadsListing
            productId={productId}
            startDate={startDate}
            productType={selectedProductType}
            endDate={endDate}
          />
        </div>
        <div className="col-xxl-4">
          <ProductGenderCard
            chartID={"genderChart"}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            productType={selectedProductType}
            platformId={platformId}
            channelType={"product"}
          />
        </div>
        <div className="col-xxl-4">
          <SmallDeviceCard
            productId={productId}
            startDate={startDate}
            productType={selectedProductType}
            endDate={endDate}
          />
        </div>
      </div> */}

      {/* <div className="row gy-5 gx-xl-10 mb-10">
        <div className="col-xxl-6 ">
          <CreditGraph
            chartID={"creditGraph"}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            productType={selectedProductType}
            title={"Clicks"}
          />
        </div>
        <div className="col-xxl-6 ">
          <CreditGraph
            chartID={"creditGraph1"}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            productType={selectedProductType}
            title={"CTR"}
          />
        </div>
      </div> */}

      {/* <div className="row gy-5 gx-xl-10 mb-10">
        <div className="col-xxl-8 ">
          <FeaturedCamp
            title="Featured Campaigns"
            firstColumn="CAMPAIGN TITLE"
            productId={productId}
            startDate={startDate}
            productType={selectedProductType}
            endDate={endDate}
          />
        </div>
        <div className="col-xxl-4">
          <IndiaMapChart
            chartID={"indiaMapClicks"}
            productId={productId}
            startDate={startDate}
            endDate={endDate}
            height={"400px"}
            marginTop={"60px"}
            platformId={platformId}
            productType={selectedProductType}
            campaignId={""}
          />
        </div>
      </div> */}
    </>
  );
};

export { ProductDashboard };
