import React, { useEffect, useLayoutEffect, useState } from "react";
import { AiOutlineZoomIn } from "react-icons/ai";
import { IoTriangleSharp } from "react-icons/io5";
import { FormatUtil } from "../../../../theme/assets/ts/_utils/FormatUtil";
import {
  getDataFromBackend,
  URLS,
} from "../../../modules/dashboards/DashboardRequests";
import CustomLoader from "../marketting_component/CustomLoader";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import ChannelTableBarGraph from "./ChannelTableBarGraph";
import { FaChartBar } from "react-icons/fa";
import { AiOutlineSplitCells } from "react-icons/ai";
import { TbTableAlias, TbLayoutColumns } from "react-icons/tb";

type Props = {
  productId: string;
  platformId: string;
  campaignId: string;
  startDate: string;
  endDate: string;
  tableTitle: string;
  channelType: string;
  subUrl: string;
  baseLine: string;
  productType: string;
};
interface ChannelDataItem {
  campaign: string;
  ctr?: string;
  ctrCompare?: string;
  cpc?: string;
  cpcCompare?: string;
  cpel?: string;
  cpelCompare?: string;
  cpa?: string;
  cpaCompare?: string;
  clicks?: string;
  clicksCompare?: string;
  leads?: string;
  leadsCompare?: string;
  apps?: string;
  appsCompare?: string;
  cards?: string;
  cardsCompare?: string;
  clicksInt: number;
}

interface ChannelData {
  chartName: string;
  weekly: ChannelDataItem[];
  fourteenDays: ChannelDataItem[];
  monthly: ChannelDataItem[];
  ninetyDays: ChannelDataItem[];
  monthsData: {
    [month: string]: ChannelDataItem[];
  };
}

const ChannelTable: React.FC<Props> = ({
  productId,
  startDate,
  endDate,
  tableTitle,
  channelType,
  subUrl,
  platformId,
  baseLine,
  productType,
}) => {
  const [selectedButton, setSelectedButton] = useState("This week");
  const [selectedButtonsForComparison, setSelectedButtonsForComparison] =
    useState<string[]>([]);
  const [showBarGraph, setShowBarGraph] = useState(false);
  const [showCompareGraph, setShowCompareGraph] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [channelData, setChannelData] = useState<ChannelData>();
  const [filteredData, setFilteredData] = useState<ChannelDataItem[]>([]);
  const vbardropdata = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [selectedName, setSelectedName] = useState<string>("Month");
  const chartId = "myIddd" + subUrl;

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const requestData = {
          productId,
          startDate,
          endDate,
          platformId,
          baseLine,
          productType,
        };
        const response = await getDataFromBackend(
          requestData,
          URLS.BASE_URL + channelType + subUrl
        );
        const data = response?.data || {};
        console.log(
          "reponse of channel table platform",
          data,
          URLS.BASE_URL + channelType + subUrl
        );
        setIsLoading(false);
        setChannelData(data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    getData();
  }, [
    productId,
    startDate,
    endDate,
    channelType,
    subUrl,
    baseLine,
    productType,
  ]);

  useEffect(() => {
    if (channelData) {
      if (selectedButton === "This week") {
        setFilteredData(channelData.weekly);
      } else if (selectedButton === "This month") {
        setFilteredData(channelData.monthly);
      } else if (selectedButton === "Last 14 days") {
        setFilteredData(channelData.fourteenDays);
      } else if (selectedButton === "Last 90 days") {
        setFilteredData(channelData.ninetyDays);
      } else if (selectedButton === "Months") {
        const uppercaseSelectedName = selectedName.toUpperCase();
        const selectedMonthData =
          channelData.monthsData[uppercaseSelectedName] || [];
        setFilteredData(selectedMonthData);
      }
    }
    console.log("filtered data in change", filteredData);
  }, [selectedButton, selectedName, channelData]);

  const handleButtonClick = (buttonText) => {
    setSelectedButton(buttonText);
  };

  const handleButtonClickForComparison = (buttonText) => {
    if (selectedButtonsForComparison.includes(buttonText)) {
      setSelectedButtonsForComparison((prevSelected) =>
        prevSelected.filter((item) => item !== buttonText)
      );
    } else {
      setSelectedButtonsForComparison((prevSelected) => [
        ...prevSelected,
        buttonText,
      ]);
    }
  };

  const renderTableCell = (
    value,
    compare,
    isPercentage = false,
    title = ""
  ) => {
    const lossCss = {
      // color: "#97291E",
      transform: "rotate(180deg)",
      marginRight: "2px",
    };
    const profitCss = { marginRight: "2px" };
    const backgroundColor = isPercentage ? "#f9d2b6" : "#b7ccdf";
    const color = isPercentage ? "black" : "#053C6D";
    const formattedValue = isNaN(Math.abs(Number(value)))
      ? "N/A"
      : Math.round(Math.abs(Number(value)));

    const formattedCompare = isNaN(Math.abs(Number(compare)))
      ? "N/A"
      : `${Math.abs(Number(compare)).toFixed(2)}%`;

    const percentageChange = isPercentage ? compare / 100 : compare;
    const baseline = Math.round(value / (1 + percentageChange));

    return (
      <td
        style={{
          borderTop: "1.5px solid black",
        }}
      >
        <div className="d-flex justify-content-center align-items-center">
          {isPercentage ? (
            <div
              style={{
                borderRadius: "10px",
                width: "105px",
                height: "20px",
                // backgroundColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "5px",
                color,
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              {tableTitle === "Cost Parameter"
                ? `₹${formattedValue.toLocaleString("en-IN")}`
                : formattedValue.toLocaleString("en-IN")}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "10px",
                  width: "25px",
                  height: "20px",
                  backgroundColor: "#BFBFBF",
                  justifyContent: "center",
                  marginRight: "5px",
                }}
              >
                {value}
              </div>
              <div
                style={{
                  borderRadius: "10px",
                  width: "100%",
                  height: "20px",
                  marginBottom: "5px",
                  color,
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                {title}
              </div>
            </div>
          )}
          {isPercentage && (
            <div
              style={{
                width: "85px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  tableTitle === "Cost Parameter"
                    ? Number(compare) > 0
                      ? "#97291E"
                      : "green"
                    : Number(compare) > 0
                    ? "green"
                    : "#97291E",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              title={
                tableTitle === "Cost Parameter"
                  ? `Baseline: ₹${Math.abs(Math.round(baseline)).toLocaleString(
                      "en-IN"
                    )}`
                  : `Baseline: ${Math.abs(Math.round(baseline)).toLocaleString(
                      "en-IN"
                    )}`
              }
            >
              <IoTriangleSharp
                size={10}
                style={Number(compare) > 0 ? profitCss : lossCss}
              />
              {formattedCompare}
            </div>
          )}
        </div>
      </td>
    );
  };

  const columns =
    subUrl === "/costcomparison"
      ? ["Campaign Types", "Cost", "CPEL", "CPA", "CPC"]
      : ["Campaign Types", "CTR", "Clicks", "Leads", "Apps", "Cards"];

  const barGraphColumn =
    subUrl === "/costcomparison"
      ? ["CPC", "CPEL", "CPA", "Cost"]
      : ["CTR", "Clicks", "Leads", "Apps", "Cards"];

  const selectMonth = (selectedMonth: string) => {
    setSelectedButton("Months");
    setSelectedName(selectedMonth);
  };

  const toggleChartComparision = showBarGraph || showCompareGraph;

  const tableData = {};
  barGraphColumn.forEach((type) => {
    tableData[type] = {};
    filteredData?.forEach((item) => {
      if (
        selectedButtonsForComparison.length === 0 ||
        selectedButtonsForComparison.some(
          (campaign) => campaign === item.campaign
        )
      ) {
        tableData[type][item.campaign] = item[`${type.toLowerCase()}`];
      }
    });
  });

  const tableHeaders = ["Type", ...Object.keys(tableData[barGraphColumn[0]])];

  const tableRows = barGraphColumn.map((type) => {
    return (
      <tr key={type}>
        <td>{type}</td>
        {Object.values(tableData[type]).map((value: any, index) => {
          const formattedValue = Number.isNaN(Number(value))
            ? "N/A"
            : Math.round(Number(value)).toLocaleString("en-IN");
          return (
            <td key={index}>
              {" "}
              {tableTitle === "Cost Parameter"
                ? `₹${formattedValue}`
                : formattedValue}
            </td>
          );
        })}
      </tr>
    );
  });

  const buttonStyle = {
    cursor: "pointer",
    marginRight: "10px",
    padding: "5px",
    borderRadius: "10px",
  };

  const selectedButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#f9d2b6",
  };

  return (
    <div
      className="card h-lg-100 p-5"
      style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)", zIndex: 0 }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="fw-bold fs-4">{tableTitle}</div>
        <div className="d-flex align-items-center">
          {["This week", "Last 14 days", "Last 90 days"].map((buttonText) => (
            <div
              key={buttonText}
              className={`button p-3 me-5 fw-semibold ${
                selectedButton === buttonText ? "selected" : ""
              }`}
              onClick={() => handleButtonClick(buttonText)}
              style={{
                backgroundColor:
                  selectedButton === buttonText ? "#08112D" : "#ecc1bd",
                borderRadius: "30px",
                color: selectedButton === buttonText ? "white" : "#08112D",
                cursor: "pointer",
              }}
            >
              {buttonText}
            </div>
          ))}
          <div className="mr-3 w-40">
            <div className="dropdown" style={{ zIndex: 2 }}>
              <div
                data-kt-daterangepicker="true"
                data-kt-daterangepicker-opens="left"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                className="btn btn-sm fw-semibold d-flex align-items-center p-3 dropdown-toggle fs-7"
                style={{
                  position: "relative",
                  zIndex: 1,
                  backgroundColor:
                    selectedButton === "Months" ? "#08112D" : "#ecc1bd",
                  borderRadius: "30px",
                  color: selectedButton === "Months" ? "white" : "#08112D",
                  cursor: "pointer",
                }}
              >
                {selectedName || "Month"}
              </div>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownMenuButton1"
              >
                {vbardropdata.map((data) => (
                  <li key={data} onClick={() => selectMonth(data)}>
                    <span className="dropdown-item text-hover-primary">
                      {data}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <div
            onClick={() => {
              setShowCompareGraph(false);
              setShowBarGraph(false);
            }}
            style={
              showCompareGraph || showBarGraph
                ? buttonStyle
                : selectedButtonStyle
            }
          >
            <TbTableAlias size={25} />
          </div>
          <div
            onClick={() => {
              setShowCompareGraph(!showCompareGraph);
              setShowBarGraph(false);
            }}
            style={showCompareGraph ? selectedButtonStyle : buttonStyle}
          >
            <TbLayoutColumns size={27} />
          </div>
          <div
            onClick={() => {
              setShowBarGraph(!showBarGraph);
              setShowCompareGraph(false);
            }}
            style={showBarGraph ? selectedButtonStyle : buttonStyle}
          >
            <FaChartBar size={25} />
          </div>
        </div>
      </div>
      {!toggleChartComparision && (
        <div style={{ overflow: "auto" }}>
          <table
            id="kt_widget_table_3"
            className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3"
            data-kt-table-widget-3="all"
          >
            <thead>
              <tr>
                {columns.map((columnName, index) => (
                  <th
                    style={{ textAlign: index == 0 ? "left" : "center" }}
                    key={index}
                    className="fw-bold"
                  >
                    {columnName}
                  </th>
                ))}
              </tr>
            </thead>
            {isLoading ? (
              <tbody
                className="py-5 d-flex align-items-center justify-content-center"
                style={{ width: "100%" }}
                id={tableTitle}
              >
                <tr className="" style={{ paddingLeft: "280px" }}>
                  <CustomLoader />
                </tr>
              </tbody>
            ) : (
              <tbody>
                {filteredData.map((data: any, index) => (
                  <tr key={index}>
                    {/* <td
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "10px",
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#BFBFBF",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "5px",
                        }}
                      >
                        {index + 1}
                      </div>
                      {data.campaign}
                    </td> */}
                    {subUrl === "/costcomparison" ? (
                      <>
                        {renderTableCell(
                          index + 1,
                          index + 1,
                          false,
                          data.campaign
                        )}
                        {renderTableCell(data.cost, data.costCompare, true)}
                        {renderTableCell(data.cpel, data.cpelCompare, true)}
                        {renderTableCell(data.cpa, data.cpaCompare, true)}
                        {renderTableCell(data.cpc, data.cpcCompare, true)}
                      </>
                    ) : (
                      <>
                        {renderTableCell(
                          index + 1,
                          index + 1,
                          false,
                          data.campaign
                        )}
                        {renderTableCell(data.ctr, data.ctrCompare, true)}
                        {renderTableCell(data.clicks, data.clicksCompare, true)}
                        {renderTableCell(data.leads, data.leadsCompare, true)}
                        {renderTableCell(data.apps, data.appsCompare, true)}
                        {renderTableCell(data.cards, data.cardsCompare, true)}
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      )}

      <div>
        {showBarGraph && (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {barGraphColumn?.map((column, index) => (
              <ChannelTableBarGraph
                key={index}
                chartId={`${chartId}-${column}`}
                barData={filteredData}
                xValue={column.toLowerCase()}
                title={tableTitle}
              />
            ))}
          </div>
        )}
      </div>

      {showCompareGraph && (
        <div style={{ overflow: "auto" }}>
          <div
            className="my-6"
            style={{
              overflow: "auto",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {filteredData?.map((data, index) => (
              <div
                key={index}
                className={`button p-3 me-5 fw-semibold ${
                  selectedButtonsForComparison.includes(data?.campaign)
                    ? "selected"
                    : ""
                }`}
                onClick={() => handleButtonClickForComparison(data?.campaign)}
                style={{
                  backgroundColor: selectedButtonsForComparison.includes(
                    data?.campaign
                  )
                    ? "#08112D"
                    : "#ecc1bd",
                  borderRadius: "10px",
                  color: selectedButtonsForComparison.includes(data?.campaign)
                    ? "white"
                    : "#08112D",
                  cursor: "pointer",
                }}
              >
                {data?.campaign}
              </div>
            ))}
          </div>
          <table
            id="kt_widget_table_3"
            className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3"
            data-kt-table-widget-3="all"
          >
            <thead>
              <tr>
                {tableHeaders.map((header) => (
                  <th
                    style={{ textAlign: "left" }}
                    className="fw-bold"
                    key={header}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ChannelTable;
