import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import search from "../../../../assets/searchhh.png";
import display from "../../../../assets/Displayyy.png";
import demand from "../../../../assets/demand.png";
import video from "../../../../assets/vedio.png";
import brand from "../../../../assets/shop.png";
import competitor from "../../../../assets/competitor.png";
import generic from "../../../../assets/marketing.png";
import perfmax from "../../../../assets/performanceee.png";
import google from "../../../../assets/google.png";
import facebook from "../../../../assets/facebook.png";
import amazon from "../../../../assets/amazon-removebg-preview.png";
import payu from "../../../../assets/payu.png";
import flipkart from "../../../../assets/flipkart.png";
import vserv from "../../../../assets/vserv.png";
import affiliate from "../../../../assets/affiliate.png";
import {
  getDataFromBackend,
  getMethodDataFromBackend,
  URLS,
} from "../../../modules/dashboards/DashboardRequests";
import CustomLoader from "./CustomLoader";
import { ChartTheme } from "../../../../theme/assets/ts/_utils/ChartTheme";
import { AiOutlineZoomIn } from "react-icons/ai";
import Modal from "./Modal";

type Props = {
  productId: string;
  platformId: string;
  startDate: string;
  endDate: string;
  title: string;
  chartID: string;
  channelType: string;
  subUrl: string;
};

interface ChartDataItem {
  platformName?: string;
  value: number;
  campaignType?: string;
  // Add other properties if needed
}

interface BulletSettings {
  src: string;
}

interface AmChartDataItem {
  productId?: string;
  startDate?: string;
  endDate?: string;
  platformName?: string;
  campaignType?: string;
  value?: any;
  data?: ChartDataItem[];
  displayText: string;
  baseLine?: any;
  percentageChangeBaseLine?: any;
  profitOrLoss?: any;
}

interface MergedChartDataItem {
  name: string;
  value: number;
  bulletSettings: BulletSettings;
  valueNext: number;
  displayText?: string;
  baseLine?: any;
  profitOrLoss?: any;
  percentageChangeBaseLine?: any;
}

const AmChartStickGraph = ({
  productId,
  platformId,
  chartID,
  startDate,
  endDate,
  title,
  channelType,
  subUrl,
  productType,
  baseLine,
}) => {
  const [amChartData, setAmChartData] = useState<AmChartDataItem[]>([]);
  const [mergedAmChartData, setMergedAmChartData] = useState<
    MergedChartDataItem[]
  >([]);
  const [baseLineData, setBaseLineData] = useState<any>({});
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const jsonKey = channelType == "platform" ? "campaignType" : "platformName";

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      setAmChartData([]);
      setMergedAmChartData([]);
      let requestData = {
        productId,
        startDate,
        endDate,
        platformId,
        productType,
        baseLine,
      };
      let response = await getDataFromBackend(
        requestData,
        URLS.BASE_URL + channelType + "/" + subUrl
      );
      let responseBaseline = await getMethodDataFromBackend(
        URLS.GET_BASELINESTATS
      );
      let dataBaseline = responseBaseline?.data;
      setBaseLineData(dataBaseline);
      console.log(
        "Response in AmChartStickGraph and baseline respons : ",
        response.data,
        responseBaseline
      );
      setIsLoading(false);
      let data = response?.data;

      const modifiedData = data.map((obj) => {
        const value = obj.value;
        const baseLine = obj.baseLine;
        if (baseLine !== 0) {
          const percentageChange = ((value - baseLine) / baseLine) * 100;
          const formattedPercentage = isFinite(percentageChange)
            ? percentageChange >= 0
              ? `+${percentageChange.toFixed(2)}`
              : percentageChange.toFixed(2)
            : "NA";

          obj.percentageChangeBaseLine = formattedPercentage;
          obj.profitOrLoss = percentageChange < 0 ? "Loss" : "Profit";
        } else {
          obj.percentageChangeBaseLine = baseLine === 0 ? "Infinity" : "NaN";
          obj.profitOrLoss = "N/A"; // Set as N/A for cases where baseLine is zero
        }
        return obj;
      });

      // Replace "Infinity" with "NA" in the modified data
      const dataWithNAs = modifiedData.map((obj) => {
        if (obj.percentageChangeBaseLine === "Infinity") {
          obj.percentageChangeBaseLine = "NA";
        }
        return obj;
      });

      if (data) {
        setAmChartData(dataWithNAs);
      } else {
        setMergedAmChartData([
          {
            name: "Search",
            value: 15,
            bulletSettings: { src: search },
            valueNext: 10,
          },
        ]);
      }
    };

    getData();

    return () => {};
  }, [productId, startDate, endDate, platformId, productType, baseLine]);

  useEffect(() => {
    console.log("baselinedata", baseLineData);
    if (amChartData?.length > 0) {
      const outputArray = amChartData?.map((item) => {
        let name = "";
        let bulletSettings: { src: string } = { src: "" };
        let valueNext = 10;

        switch (item[jsonKey]) {
          case "Facebook":
            name = "Facebook";
            bulletSettings.src = facebook;
            break;
          case "Google":
            name = "Google";
            bulletSettings.src = google;
            break;
          case "Amazon":
            name = "Amazon";
            bulletSettings.src = amazon;
            break;
          case "Affiliate":
            name = "Affiliate";
            bulletSettings.src = affiliate;
            break;
          case "Flipkart":
            name = "Flipkart";
            bulletSettings.src = flipkart;
            break;
          case "Payu":
            name = "Payu";
            bulletSettings.src = payu;
            break;
          case "Vserv":
            name = "Vserv";
            bulletSettings.src = vserv;
            break;
          case "Search":
            name = "Search";
            bulletSettings.src = search;
            break;
          case "Brand":
            name = "Brand";
            bulletSettings.src = brand;
            break;
          case "Competitor":
            name = "Competitor";
            bulletSettings.src = competitor;
            break;
          case "Generic":
            name = "Generic";
            bulletSettings.src = generic;
            break;
          case "Discovery":
            name = "Discovery";
            bulletSettings.src = demand;
            break;
          case "Performance Max":
            name = "Performance Max";
            bulletSettings.src = perfmax;
            break;
          case "Display":
            name = "Display";
            bulletSettings.src = display;
            break;
          case "Demand Gen":
            name = "Demand Gen";
            bulletSettings.src = demand;
            break;
          case "Video":
            name = "Video";
            bulletSettings.src = video;
            break;
          case "Affiliate":
            name = "Affiliate";
            bulletSettings.src = payu;
            break;
          case "Vserv":
            name = "Vserv";
            bulletSettings.src = vserv;
            break;
          default:
            break;
        }

        return {
          name,
          value: Math.round(Number(item?.value)),
          baseLine: Number(item?.baseLine).toFixed(2),
          percentageChangeBaseLine: item?.percentageChangeBaseLine,
          profitOrLoss: item?.profitOrLoss,
          bulletSettings,
          valueNext,
        };
      });
      setMergedAmChartData(outputArray);
      console.log("amChartData", outputArray);
    }
  }, [amChartData]);

  // const chartID = "stickGraph";

  useLayoutEffect(() => {
    let root = am5.Root.new(chartID);

    // Set themes
    // // https://www.amcharts.com/docs/v5/concepts/themes/
    // root.setThemes([
    //     am5themes_Animated.new(root)
    // ]);

    // root.setThemes([ChartTheme["getBarPerformanceTheme1"](root)]);
    root._logo?.dispose();

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
      })
    );

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });

    // if (channelType === "platform") {
    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
    });
    // }

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "name",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "left",
        }),
      })
    );

    xRenderer.grid.template.set("visible", false);

    let yRenderer = am5xy.AxisRendererY.new(root, {});
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        extraMax: 0.2,
        renderer: yRenderer,
      })
    );

    yRenderer.grid.template.setAll({
      strokeDasharray: [2, 2],
    });

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "percentageChangeBaseLine",
        sequencedInterpolation: true,
        categoryXField: "name",
        categoryYField: "profitOrLoss",
        // tooltip: am5.Tooltip.new(root, { dy: -20, labelText: title + " : {valueY}" + {title==='CTR'&& '%'} })
        tooltip: am5.Tooltip.new(root, {
          dy: -20,
          labelText:
            title === "CTR"
              ? `CTR : {valueY}% \nBaseline Diff : {valueX}% \nBaseline: {baseLine}%`
              : title +
                " : ₹{valueY} \nBaseline Diff : {valueX}% \nBaseline: ₹{baseLine}",
          pointerOrientation: "left",
        }),
      })
    );

    // let tooltip = am5.Tooltip.new(root, {
    //   autoTextColor: false,
    //   labelText:
    //     title === "CTR"
    //       ? `CTR : {valueY}% \nBaseline : {valueX}% ({categoryY})`
    //       : title + " : ₹{valueY} \nBaseline : {valueX}% ({categoryY})",
    // });

    // tooltip.label.setAll({
    //   fill: am5.color(parseFloat("valueX") > 0 ? 0xff5566 : 0x55ff5b),
    // });

    // series.set("tooltip", tooltip);

    series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      strokeOpacity: 0,
      width: 5,
      fill: am5.color("#000000"),
    });

    //   series.columns.template.adapters.add("fill", function(fill: any, target: any) {
    //     let dataItem = target?.dataItem;

    //     if (dataItem && dataItem.get("valueY") !== undefined) {
    //         if (dataItem.get("valueY") > 0) {
    //             // target.set("tooltipY", am5.p100);
    //             return am5.color(0x008000);
    //         }else{
    //           // target.set("tooltipY", am5.p100);
    //           return am5.color(0x800000);
    //         }
    //     }

    //     return fill;
    // });

    let data = mergedAmChartData;

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 1,
        sprite: am5.Picture.new(root, {
          templateField: "bulletSettings",
          width: 30,
          height: 30,
          centerX: am5.p50,
          centerY: am5.p100,
          // shadowColor: am5.color(0x000000),
          // shadowBlur: 4,
          // shadowOffsetX: 4,
          // shadowOffsetY: 4,
          // shadowOpacity: 0.6,
        }),
      });
    });

    xAxis.data.setAll(data);
    series.data.setAll(data);

    function generateData(
      baseValue: number,
      currentDate: Date
    ): { date: number; value: number } {
      const value =
        chartID == "stick01"
          ? Number(baseLineData?.ctr)
          : chartID == "stick02"
          ? Number(baseLineData?.cpel)
          : chartID == "stick03"
          ? Number(baseLineData?.cpa)
          : 5;

      const date = new Date(currentDate);
      am5.time.add(date, "day", 1);
      return {
        date: date.getTime(),
        value: value,
      };
    }

    function generateDatas(count: number) {
      let data: Array<{ date: number; value: number }> = [];
      let currentDate = new Date();
      let baseValue = 0;
      for (let i = 0; i < count; ++i) {
        const newData = generateData(baseValue, currentDate);
        data.push(newData);
        currentDate = new Date(newData.date);
        baseValue = newData.value;
      }
      return data;
    }

    let xAxis1 = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.2,
        baseInterval: {
          timeUnit: "day",
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    xAxis1.get("renderer").grid.template.set("forceHidden", true);

    let series2 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Series",
        xAxis: xAxis1,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        stroke: am5.color("#97291E"),
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}",
          pointerOrientation: "right",
        }),
      })
    );

    series2.strokes.template.setAll({
      strokeWidth: 0.5,
    });

    xAxis1.get("renderer").labels.template.setAll({ visible: false });

    // let data1 = generateDatas(200);
    // series2.data.setAll(data1);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear();
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartID, mergedAmChartData, baseLineData, isZoomModalOpen]);

  return (
    <div
      className="card card-flush"
      data-bs-theme="light"
      style={{
        backgroundColor: "white ",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
      }}
    >
      <div className="card-title d-flex justify-content-between align-items-center mt-6 mx-5">
        <div className="text-dark fs-6 fw-bold">
          {channelType === "platform"
            ? "Which Campaign types performing best in terms of "
            : "Average"}{" "}
          {title}
        </div>
        {channelType === "platform" && (
          <div
            className="fw-bold"
            style={{
              fontSize: "12px",
              textAlign: "end",
              cursor: "pointer",
            }}
            onClick={() => setIsZoomModalOpen(true)}
          >
            <AiOutlineZoomIn size={25} />
          </div>
        )}
      </div>
      <div className="card-body d-flex justify-content-between flex-column pt-1 px-5 pb-0">
        {!isLoading && !isZoomModalOpen ? (
          <>
            <div className="">
              <div
                id={chartID}
                className="ms-n5 min-h-auto"
                style={{
                  height: "350px",
                  // height: channelType === "platform" ? "350px" : "250px",
                  width: "100%",
                }}
              />
            </div>
          </>
        ) : isZoomModalOpen ? (
          <Modal
            isOpen={isZoomModalOpen}
            onClose={() => setIsZoomModalOpen(!isZoomModalOpen)}
            title={title}
          >
            <div id={chartID} style={{ height: "600px", width: "100%" }}></div>
          </Modal>
        ) : (
          <>
            <div
              className="py-5 d-flex align-self-center justify-content-center min-vh-50"
              id={chartID}
            >
              <CustomLoader />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AmChartStickGraph;
