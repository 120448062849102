/** @format */

import { FC, useState, useRef, useMemo, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import clsx from "clsx";
import {
	createParticipant,
	editParticipant,
} from "../../../../application/_requests";
import { useListView } from "../core/ListViewProvider";
import { useQueryClient } from "react-query";
import { useQueryRequest } from "../core/QueryRequestProvider";
import { stringifyRequestQuery } from "../../../../../../theme/helpers";
import { useParams } from "react-router-dom";

const editUserSchema = Yup.object().shape({
	name: Yup.string()
		.trim()
		.required("Participant name is required")
		.min(3, "Enter at least 3 characters")
		.max(60, "Maximum 60 characters allowed"),
	code: Yup.string()
		.label("")
		.length(1, "Code should be exactly one character between A to Z")
		.matches(/^[A-Za-z]+$/, "Code should be one character between A to Z")
		.required("Participant code is required"),
});

const AddParticipantModalForm: FC<any> = ({
	user,
	isUserLoading,
	onCancel,
}) => {
	const queryClient = useQueryClient();
	const { setItemIdForUpdate } = useListView();
	const { setToastMessage } = useListView();
	// const animatedComponent=makeAnimated();
	const cancel = (withRefresh?: boolean) => {
		setItemIdForUpdate(undefined);
	};

	const { state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);

	const { id } = useParams();

	const formik = useFormik({
		initialValues: {
			name: user?.participantName ? `${user.participantName}` : "",
			code: user?.participantCode ? `${user.participantCode}` : "",
		},
		validationSchema: editUserSchema,
		// validateOnBlur: false,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			const temp: any = {
				domainId: `${id}`,
				participantName: values.name,
				participantCode: values.code,
			};

			try {
				if (user) {
					await editParticipant(temp, user?.participantId);
					setToastMessage("Participant Updated Successfully");
					cancel(true);
					setTimeout(() => {
						setToastMessage(undefined);
					}, 2000);
					queryClient.invalidateQueries([`PARTICIPANT_LIST-${query}`]);
				} else {
					await createParticipant(temp);
					setToastMessage("Participant Created Successfully");
					cancel(true);
					setTimeout(() => {
						setToastMessage(undefined);
					}, 2000);
					queryClient.invalidateQueries([`PARTICIPANT_LIST-${query}`]);
				}
			} catch (ex) {
				console.error(ex);
			} finally {
				setSubmitting(true);
				// cancel(true);
			}
		},
	});

	return (
		<>
			<form
				id="kt_modal_add_user_form"
				className="form"
				onSubmit={formik.handleSubmit}
				noValidate
			>
				<div
					className="d-flex flex-column scroll-y me-n7 pe-7"
					id="kt_modal_add_user_scroll"
					data-kt-scroll="true"
					data-kt-scroll-activate="{default: false, lg: true}"
					data-kt-scroll-max-height="auto"
					data-kt-scroll-dependencies="#kt_modal_add_user_header"
					data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
					data-kt-scroll-offset="300px"
				>
					<div className="fv-row mb-7">
						<label className="required fw-bold fs-6 mb-2">
							Participant Name
						</label>
						<input
							placeholder="Participant Name"
							{...formik.getFieldProps("name")}
							type="text"
							name="name"
							className={clsx(
								"form-control form-control-light mb-3 mb-lg-0",
								{ "is-invalid": formik.touched.name && formik.errors.name },
								{
									"is-valid": formik.touched.name && !formik.errors.name,
								}
							)}
							autoComplete="off"
						/>
						{formik.touched.name && formik.errors.name && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.name}</span>
								</div>
							</div>
						)}
					</div>

					<div className="fv-row mb-7">
						<label className="required fw-bold fs-6 mb-2"> Code</label>
						<input
							placeholder="Code"
							//className="form-select form-select-solid"
							//aria-label="Select example"
							{...formik.getFieldProps("code")}
							type="text"
							name="code"
							// value={undefined}
							className={clsx(
								"form-control form-control-light mb-3 mb-lg-0",
								{
									"is-invalid": formik.touched.code && formik.errors.code,
								},
								{
									"is-valid": formik.touched.code && !formik.errors.code,
								}
							)}
							autoComplete="off"
						></input>
						{formik.touched.code && formik.errors.code && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.code}</span>
								</div>
							</div>
						)}
					</div>

					<div className="d-flex justify-content-center">
						<button
							title="Discard"
							type="reset"
							className="btn btn-light mx-10"
							data-kt-users-modal-action="cancel"
							onClick={onCancel}
						>
							Discard
						</button>

						<button
							title={user ? `Update` : `Create`}
							type="submit"
							className="btn text-white mx-10 btn-custom"
							data-kt-users-modal-action="submit"
							style={{ backgroundColor: "#333E58" }}
						>
							<span className="indicator-label">{user ? 'Update' : 'Create'}</span>

						</button>
					</div>
				</div>
			</form>
		</>
	);
};

export { AddParticipantModalForm };
