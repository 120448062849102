/** @format */

import { FC, useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import clsx from "clsx";
import { AddRule, AddSource, editSource } from "../../../../application/_requests";
import { useListView } from "../core/ListViewProvider";
import { Doc, RULE } from "../core/_models";

import { useSourceQueryResponse } from "../core/QueryResponseProvider";
import { useQueryClient } from "react-query";
import { useQueryRequest } from "../core/QueryRequestProvider";
import { stringifyRequestQuery } from "../../../../../../theme/helpers";
import Container from "../../../../../pages/dashboard/components/DatePicker";

// const PercentvalidationSchema = Yup.object().shape({
// 	name: Yup.number()
// 	  .integer('Please enter a whole number')
// 	  .min(1, 'Number must be greater than or equal to 1')
// 	  .max(100, 'Number must be less than or equal to 100')
// 	  .required('Please enter a number'),
//   });

const validationSchema = Yup.object().shape({
	subject: Yup.string()
		.trim()
		.required("Rule name is required")
		.min(3, "Enter at least 3 characters")
		.max(60, "Maximum 60 characters allowed")
		.matches(/^\S+.*\S+$/, "Leading and trailing spaces are not allowed"),

	percentage: Yup.number()
		//   .integer('Please enter a whole number')
		.min(1, "Number must be greater than or equal to 1")
		.max(100, "Number must be less than or equal to 100")
		.required("Please enter a number"),

	status: Yup.string().required("Status is required"),

	// name: Yup.string()
	// 	.trim()
	// 	.required("Name is required")
	// 	.min(3, "Enter at least 3 characters")
	// 	.max(60, "Maximum 60 characters allowed")
	// 	.matches(/^\S+.*\S+$/, "Leading and trailing spaces are not allowed"),
	// fileName: Yup.mixed()
	// 	.required("File is required")
	// 	.test(
	// 		"fileType",
	// 		"Please upload a file type: pdf",
	// 		(value) => value && value[0].type === "application/pdf"
	// 	)
	// 	.test(
	// 		"fileSize",
	// 		// "File size must not exceed 50 MB.",
	// 		"File size is greater than 50 MB",
	// 		(value) => value && value[0].size <= 50 * 1024 * 1024
	// 	),
});

const AddSourceModalForm: FC<any> = ({ user, isUserLoading, onCancel }) => {


// 	const [dropdownVisible, setDropdownVisible] = useState(false);
//   const [status, setStatus] = useState('Active');

//   const handleStatusChange = (newStatus) => {
//     setStatus(newStatus);
//     setDropdownVisible(false);
//   };

//   const toggleDropdown = () => {
//     setDropdownVisible(!dropdownVisible);
//   };

	const btnRef = useRef<HTMLButtonElement | null>(null);
	const queryClient = useQueryClient();
	const { setItemIdForUpdate, itemIdForUpdate } = useListView();
	const { setToastMessage } = useListView();
	const cancel = (withRefresh?: boolean) => {
		setItemIdForUpdate(undefined);
	};
	console.log(user, itemIdForUpdate, "ooooooo");
	const { state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
	const [type, settype] = useState("password");
	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);

	const { refetch } = useSourceQueryResponse();

	console.log(itemIdForUpdate, "mmmzz");
	const HandleSubmit = async (values: any, { setSubmitting }) => {
		btnRef.current?.setAttribute("data-kt-indicator", "on");

		const docRequest: RULE = {
			masterRuleId: itemIdForUpdate?.masterRuleId || "",
			name: values?.subject,
			rule: `<,${values?.percentage}`,
			status: values?.status || "",
			createdBy: "",
			updatedBy: null,
			createdDate: "",
			updatedDate: "",
			suggestion: "",
		};
		// const file = values.fileName[0];

		setSubmitting(true);
		try {
			await AddRule(docRequest);
			setToastMessage("Rule Added Successfully");
			cancel(true);
			setTimeout(() => {
				setToastMessage(undefined);
			}, 2000);
			queryClient.invalidateQueries([`ALL_MASTER_RULE_LISTING`]);
		} catch (error) {
			console.log(error);
		} finally {
			setSubmitting(true);
		}
	};

	const formik = useFormik({
		initialValues: {
			subject: user !== null ? `${user?.name}` : "",
			percentage: user !== null ? `${user?.rule?.replace(/[<,]/g, "")}` : "",
			status: user !== null ? `${user?.status}` : "",
			// fileName: undefined,
		},

		// validationSchema={PercentvalidationSchema}

		validationSchema: validationSchema,
		// validateOnBlur: false,
		onSubmit: async (values, { setSubmitting }) => {
			HandleSubmit(values, { setSubmitting });
		},
	});

	const statuses = ["Active", "InActive"];

	return (
		<>
			<form
				id="kt_modal_add_user_form"
				className="form"
				onSubmit={formik.handleSubmit}
				noValidate
			>
				<div
					className="d-flex flex-column scroll-y me-n7 pe-7"
					id="kt_modal_add_user_scroll"
					data-kt-scroll="true"
					data-kt-scroll-activate="{default: false, lg: true}"
					data-kt-scroll-max-height="auto"
					data-kt-scroll-dependencies="#kt_modal_add_user_header"
					data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
					data-kt-scroll-offset="300px"
				>
					<div className="fv-row mb-7">
						<label className="required fw-bold fs-6 mb-2"> Rule Name</label>
						<input
							placeholder="Rule Name"
							//className="form-select form-select-solid"
							//aria-label="Select example"
							{...formik.getFieldProps("subject")}
							type="text"
							name="subject"
							// value={user?.name || ""}
							className={clsx(
								"form-control form-control-light mb-3 mb-lg-0",
								{
									"is-invalid": formik.touched.subject && formik.errors.subject,
								},
								{
									"is-valid": formik.touched.subject && !formik.errors.subject,
								}
							)}
							autoComplete="off"
							onInput={(e) => {
								e.currentTarget.value = e.currentTarget.value.replace(
									/[^a-zA-Z0-9 ]+/g,
									""
								);
							}}
						></input>
						{formik.touched.subject && formik.errors.subject && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.subject}</span>
								</div>
							</div>
						)}
					</div>

					<div className="fv-row mb-7">
						<label className="required fw-bold fs-6 mb-2">Percentage</label>
						<input
							placeholder="0.0 %"
							{...formik.getFieldProps("percentage")}
							type="integer"
							name="percentage"
							min="1"
							max="100"
							// value={user?.percentage || ""}
							className={clsx(
								"form-control form-control-light mb-3 mb-lg-0",
								{
									"is-invalid":
										formik.touched.percentage && formik.errors.percentage,
								},
								{
									"is-valid":
										formik.touched.percentage && !formik.errors.percentage,
								}
							)}
							autoComplete="off"
						/>
						{formik.touched.percentage && formik.errors.percentage && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.percentage}</span>
								</div>
							</div>
						)}
					</div>

					<div className="fv-row mb-12 dropdown">
						<label className="required fw-bold fs-6 mb-2">Status</label>

						<select
							// className="form-select form-select-light"

							className={clsx(
								"form-select form-select-light mb-3 mb-lg-0",
								{
									"is-invalid": formik.touched.status && formik.errors.status,
								},
								{
									"is-valid": formik.touched.status && !formik.errors.status,
								}
							)}
							aria-label="Select example"
							{...formik.getFieldProps("status")}
						>
							<option
								// selected={true}
								hidden={true}
								value=""
							>
								Select Status
							</option>
							{statuses?.map((item: any, index: any) => (
								<option key={index} value={item}>
									{item}
								</option>
							))}
						</select>
						{formik.touched.status && formik.errors.status && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.status}</span>
								</div>
							</div>
						)}
					</div>

{/* 
<div className="status-button-container">
      <button className={`status-button btn-primary ${status.toLowerCase()}`} onClick={toggleDropdown}>
        {status}
        <i className={`fas fa-chevron-${dropdownVisible ? 'up' : 'down'}`}></i>
      </button>
      {dropdownVisible && (
        <div className="dropdown">
          <button className="dropdown-item" onClick={() => handleStatusChange('Active')}>
            Active
          </button>
          <button className="dropdown-item" onClick={() => handleStatusChange('Inactive')}>
            Inactive
          </button>
		  </div>
      )}
    </div> */}



					<div className="d-flex justify-content-center">
						<button
							type="reset"
							className="btn btn-light mx-10"
							data-kt-users-modal-action="cancel"
							onClick={onCancel}
							title="Discard"
						>
							Discard
						</button>

						<button
							ref={btnRef}
							type="submit"
							className="btn  me-10 text-white btn-primary"
							id="kt_button_1"
							data-kt-users-modal-action="submit"
							title="Submit"
						>
							<span className="indicator-label ">
								{itemIdForUpdate?.masterRuleId ? "Update" : "Add"}
							</span>
							<span className="indicator-progress  ">
								Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

export { AddSourceModalForm };
