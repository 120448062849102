/** @format */

import { FC, useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import { Field, useFormik } from "formik";
import clsx from "clsx";

import { useQueryClient } from "react-query";
import { addDomain, editDomain } from "../../../../application/_requests";
import { useListView } from "../core/ListViewProvider";
import { useOrganizationQueryResponse } from "../core/QueryResponseProvider";
import { useQueryRequest } from "../core/QueryRequestProvider";
import { stringifyRequestQuery } from "../../../../../../theme/helpers";

const editUserSchema = Yup.object().shape({
	domain_name: Yup.string()
		.trim()
		.required("Domain name is required")
		.min(3, "Enter at least 3 characters")
		.max(60, "Maximum 60 characters allowed")
});

const CreateDomainModalForm: FC<any> = ({ user, isUserLoading, onCancel }) => {
	const btnRef = useRef<HTMLButtonElement | null>(null);

	const queryClient = useQueryClient();
	const { setItemIdForUpdate } = useListView();
	const { setToastMessage } = useListView();
	const cancel = (withRefresh?: boolean) => {
		setItemIdForUpdate(undefined);
	};

	const { state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
	const [type, settype] = useState("password");
	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);

	const formik = useFormik({
		initialValues: {
			domain_name: user?.domainName ? `${user?.domainName}` : "",
		},

		validationSchema: editUserSchema,
		// validateOnBlur: false,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			const temp: any = { domainName: values.domain_name }
			try {
				if (user) {
					await editDomain(temp, user?.domainId);
					setToastMessage("Domain Updated Successfully");
					cancel(true);
					setTimeout(() => {
						setToastMessage(undefined);
					}, 2000);
					queryClient.invalidateQueries([`LIST_DOMAIN-${query}`]);
				} else {
					await addDomain(temp);
					setToastMessage("Domain Created Successfully");
					cancel(true);
					setTimeout(() => {
						setToastMessage(undefined);
					}, 2000);
					queryClient.invalidateQueries([`LIST_DOMAIN-${query}`]);
				}
			} catch (ex) {
				console.error(ex);
			} finally {
				setSubmitting(true);
				// cancel(true);
			}
		},
	});
	return (
		<>
			<form
				id="kt_modal_add_user_form"
				className="form"
				onSubmit={formik.handleSubmit}
				noValidate
			>
				<div
					className="d-flex flex-column scroll-y me-n7 pe-7"
					id="kt_modal_add_user_scroll"
					data-kt-scroll="true"
					data-kt-scroll-activate="{default: false, lg: true}"
					data-kt-scroll-max-height="auto"
					data-kt-scroll-dependencies="#kt_modal_add_user_header"
					data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
					data-kt-scroll-offset="300px"
				>

					<div className="fv-row mb-7">
						<label className="fw-bold fs-6 mb-2"> Domain Name</label>
						<input
							placeholder="Domain Name"
							{...formik.getFieldProps("domain_name")}
							type="text"
							name="domain_name"
							// value={undefined}
							//onChange={(e)=>formik.setFieldVAlue("name",e.target.name)}
							className={clsx(
								"form-control form-control-light mb-3 mb-lg-0",
								{
									"is-invalid":
										formik.touched.domain_name && formik.errors.domain_name,
								},
								{
									"is-valid":
										formik.touched.domain_name && !formik.errors.domain_name,
								}
							)}
							autoComplete="off"
						/>
						{formik.touched.domain_name && formik.errors.domain_name && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.domain_name}</span>
								</div>
							</div>
						)}
					</div>

					<div className="d-flex justify-content-center">
						<button
							title="Discard"
							type="reset"
							className="btn btn-light mx-10"
							data-kt-users-modal-action="cancel"
							onClick={onCancel}
						>
							Discard
						</button>

						<button
							// ref={btnRef}
							title={user ? `Update` : `Create`}
							type="submit"
							className="btn me-10 text-white btn-custom"
							id="kt_button_1"
							data-kt-users-modal-action="submit"
							style={{ backgroundColor: "#333E58" }}
						>
							<span className="indicator-label ">{user ? `Update` : `Create`}</span>
							<span className="indicator-progress  ">
								Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

export { CreateDomainModalForm };
