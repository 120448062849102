/** @format */

// @ts-nocheck
// eslint-disable-next-line
import { UserInfoCell } from "./UserInfoCell";
import { UserCustomHeader } from "./UserCustomHeader";
import { Link } from "react-router-dom";
import { ActionCellUser } from "./ActionCellUser";
import "./_columns.scss";

const usersColumns: any = (onDelete) => {
	const location = window.location.pathname.split("/").pop();
	const temp = window.location.pathname.split("/");
	const prevLocation = `${temp[temp?.length - 2]}/${location}`;

	if (location === "users") {
		return [
			{
				Header: (props) => (
					<UserCustomHeader tableProps={props} title="Name" className="w-25" />
				),
				accessor: "username",
				Cell: ({ ...props }) => (
					<div className="text-start">
						{props.data[props.row.index].username}
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader tableProps={props} title="Email" className="w-25" />
				),
				accessor: "email",
				Cell: ({ ...props }) => (
					<div className="text-start">{props.data[props.row.index].email}</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Role"
						className="w-17 text-center ps-0"
					/>
				),
				accessor: "roles",
				Cell: ({ ...props }) => (
					<div className="text-center">{props.data[props.row.index].roles}</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Status"
						className="w-17 text-center"
					/>
				),
				accessor: "status",
				Cell: ({ ...props }) => (
					<div className="text-center">
						{props.data[props.row.index].status}
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Actions"
						className="s-no text-center"
					/>
				),
				id: "actions",
				Cell: ({ ...props }) => (
					<ActionCellUser
						id={props.data[props.row.index]}
						onDelete={onDelete}
						cases={location}
						className="text-center"
					/>
				),
			},
		];
	} else if (location === "dashboard") {
		return [
			{
				Header: (props) => (
					<UserCustomHeader tableProps={props} title="Name" className="w-50" />
				),
				accessor: "organisationName",
			},

			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Actions"
						className="w-25 text-center"
					/>
				),
				id: "actions",

				Cell: ({ ...props }) => (
					<ActionCellUser
						id={props.data[props.row.index]}
						onDelete={onDelete}
						cases={prevLocation}
						className={"s-action"}
					/>
				),
			},
		];
	} else if (location === "source") {
		return [
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Name"
						className="w-15 text-start"
					/>
				),
				accessor: "docName",
				Cell: ({ ...props }) => (
					<>
						<div className="text-start">
							<Link
								to={`${props.data[props.row.index].draftId}`}
								className="text-dark text-hover-primary mb-1"
								state={props.data[props.row.index]}
							>
								{props.data[props.row.index].docName}
							</Link>
						</div>
					</>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="File Name"
						className="w-28 text-start"
					/>
				),
				accessor: "fileName",
				Cell: ({ ...props }) => (
					<div className="text-start">
						{props.data[props.row.index].fileName}
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="No. of Responses"
						className="w-15 text-center ps-0"
					/>
				),
				accessor: "noOfResponses",
				Cell: ({ ...props }) => (
					<div className="text-center">
						{props.data[props.row.index].noOfResponses}
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Uploaded On"
						className="w-20 text-center ps-0"
					/>
				),
				accessor: "uploadedOn",
				Cell: ({ ...props }) => (
					<div className="text-center">
						{props.data[props.row.index].uploadedOn}
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Actions"
						className="w-25 text-center"
					/>
				),
				id: "actions",

				Cell: ({ ...props }) => (
					<ActionCellUser
						id={props.data[props.row.index]}
						onDelete={onDelete}
						cases="source"
						className="text-center"
					/>
				),
			},
		];
	} else if (prevLocation === `source/${location}`) {
		return [
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="File Name"
						className="w-25"
					/>
				),
				id: "Files",
				Cell: ({ ...props }) => (
					<div className="text-start">
						<Link
							to={`view-details/${props.data[props.row.index].responseId}`}
							className="text-gray-800 text-hover-primary mb-1"
							state={props.data[props.row.index]}
						>
							{props.data[props.row.index].fileName}
						</Link>
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Pages"
						className="r-actions"
					/>
				),
				accessor: "Pages",
				Cell: ({ ...props }) => (
					<div className="r-page">{props.data[props.row.index].pages}</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Uploaded By"
						className="w-25 text-center ps-0"
					/>
				),
				id: "uploadedBy",
				Cell: ({ ...props }) => (
					<div className="text-center">
						{props.data[props.row.index].uploadedBy}
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Uploaded On"
						className="w-20 text-center ps-0"
					/>
				),
				accessor: "uploadedOn",
				Cell: ({ ...props }) => (
					<div className="text-center">
						{props.data[props.row.index].uploadedOn}
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Actions"
						className="w-100 text-center"
					/>
				),
				id: "actions",
				Cell: ({ ...props }) => (
					<ActionCellUser
						id={props.data[props.row.index]}
						onDelete={onDelete}
						cases="response"
						className="r-action"
					/>
				),
			},
		];
	} else if (location === "domain") {
		return [
			{
				Header: (props) => (
					<UserCustomHeader tableProps={props} title="Name" className="w-25" />
				),
				accessor: "domainName",
				Cell: ({ ...props }) => (
					<div className="text-start">
						<Link
							to={`${props.data[props.row.index].domainId}`}
							className="text-black text-hover-primary mb-1"
							state={props.data[props.row.index]}
							title={props.data[props.row.index].domainName}
						>
							{props.data[props.row.index].domainName}
						</Link>
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="No. of Participants"
						className="w-20 text-center"
					/>
				),
				accessor: "noOfParticipants",
				Cell: ({ ...props }) => (
					<div className="text-center">
						{props.data[props.row.index].noOfParticipants}
					</div>
				),
			},
			// {
			// 	Header: (props) => (
			// 		<UserCustomHeader
			// 			tableProps={props}
			// 			title="No. of Organizations"
			// 			className="w-15 text-center"
			// 		/>
			// 	),
			// 	accessor: "No of Organisation",
			// 	Cell: ({ ...props }) => (
			// 		<div className="text-center">
			// 			{props.data[props.row.index].No_of_Organisation}
			// 		</div>
			// 	),
			// },
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Created By"
						className="w-20 text-center ps-0"
					/>
				),
				accessor: "createdBy",
				Cell: ({ ...props }) => (
					<div className="text-center">
						{props.data[props.row.index].createdBy}
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Created On"
						className="w-16 text-center ps-0"
					/>
				),
				accessor: "createdDate",
				Cell: ({ ...props }) => (
					<div className="text-center">
						{props.data[props.row.index].createdDate}
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Actions"
						className="w-50 text-center"
					/>
				),
				id: "actions",
				Cell: ({ ...props }) => (
					<ActionCellUser
						id={props.data[props.row.index]}
						onDelete={onDelete}
						cases={location}
					/>
				),
			},
		];
	} else if (prevLocation === `domain/${location}`) {
		return [
			{
				Header: (props) => (
					<UserCustomHeader tableProps={props} title="Name" className="w-30" />
				),
				accessor: "participantName",
				Cell: ({ ...props }) => (
					<div className="s-name">
						<Link
							to={`view-detail/${props.data[props.row.index].participantId}`}
							className="text-gray-800 text-hover-primary mb-1"
							state={props.data[props.row.index]}
						>
							{props.data[props.row.index].participantName}
						</Link>
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Code"
						className="w-12 text-center ps-0"
					/>
				),
				accessor: "participantCode",
				Cell: ({ ...props }) => (
					<div className="text-center">
						{props.data[props.row.index].participantCode}
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="No. of Organizations"
						className="w-25 text-center ps-0"
					/>
				),
				accessor: "noOfOrganization",
				Cell: ({ ...props }) => (
					<div className="text-center">
						{props.data[props.row.index].noOfOrganization}
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Created On"
						className="w-16 text-center ps-0"
					/>
				),
				accessor: "createdDate",
				Cell: ({ ...props }) => (
					<div className="text-center">
						{props.data[props.row.index].createdDate}
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Actions"
						className="w-100 text-center"
					/>
				),
				id: "actions",
				Cell: ({ ...props }) => (
					<ActionCellUser
						id={props.data[props.row.index]}
						onDelete={onDelete}
						cases={location}
					/>
				),
			},
		];
	} else if (prevLocation === `view-detail/${location}`) {
		return [
			{
				Header: (props) => (
					<UserCustomHeader tableProps={props} title="Name" className="w-50" />
				),
				accessor: "organisationName",
			},

			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Actions"
						className="w-25 text-center"
					/>
				),
				id: "actions",

				Cell: ({ ...props }) => (
					<ActionCellUser
						id={props.data[props.row.index]}
						onDelete={onDelete}
						cases={prevLocation}
						className={"s-action"}
					/>
				),
			},
		];
	} else if (location === "organization") {
		return [
			{
				Header: (props) => (
					<UserCustomHeader tableProps={props} title="Name" className="w-40 " />
				),
				accessor: "orgName",
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Created On"
						className="w-30 text-center"
					/>
				),
				accessor: "createdDate",
				Cell: ({ ...props }) => (
					<div className="text-center">
						{props.data[props.row.index].createdDate}
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Actions"
						className="w-30 text-center"
					/>
				),
				id: "actions",

				Cell: ({ ...props }) => (
					<ActionCellUser
						id={props.data[props.row.index]}
						onDelete={onDelete}
						cases={location}
						className="text-center"
					/>
				),
			},
		];
	} else if (location === "configuration") {
		// status active inactive
		//rule name
		//rule description
		//action
		//percentage
		return [
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Rule Name"
						className="w-20 "
					/>
				),
				accessor: "name",
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Rule Description"
						className="w-40 text-center "
					/>
				),
				accessor: "suggestion",
				Cell: ({ ...props }) => (
					<div className="text-center">
						{props.data[props.row.index].suggestion}
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Percentage"
						className="w-15 text-center "
					/>
				),
				accessor: "rule",
				Cell: ({ ...props }) => (
					<div className="text-center">
						{props.data[props.row.index].rule?.replace(/[<,]/g, "")}
						{"%"}
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Status"
						className="w-15 text-center"
					/>
				),
				accessor: "status",
				Cell: ({ ...props }) => (
					<div className={`text-center`}>
						{props.data[props.row.index].status}
					</div>
				),
			},

			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Actions"
						className="w-10  text-center"
					/>
				),
				id: "actions",

				Cell: ({ ...props }) => (
					<ActionCellUser
						id={props.data[props.row.index]}
						onDelete={onDelete}
						cases={location}
						className="text-center"
					/>
				),
			},
		];
	} else {
		return [
			{
				Header: (props) => (
					<UserCustomHeader tableProps={props} title="Name" className="w-25" />
				),
				accessor: "username",
				Cell: ({ ...props }) => (
					<div className="text-center">
						{props.data[props.row.index].username}
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Email"
						className="w-25 text-center"
					/>
				),
				accessor: "email",
				Cell: ({ ...props }) => (
					<div className="text-center">{props.data[props.row.index].email}</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Role"
						className="w-20 text-center"
					/>
				),
				accessor: "roles",
				Cell: ({ ...props }) => (
					<div className="text-center">{props.data[props.row.index].roles}</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Status"
						className="w-20 text-center"
					/>
				),
				accessor: "status",
				Cell: ({ ...props }) => (
					<div className="text-center">
						{props.data[props.row.index].status}
					</div>
				),
			},
			{
				Header: (props) => (
					<UserCustomHeader
						tableProps={props}
						title="Actions"
						className="w-10 text-center"
					/>
				),
				id: "actions",
				Cell: ({ ...props }) => (
					<ActionCellUser
						id={props.data[props.row.index]}
						onDelete={onDelete}
						cases={location}
						className="text-center"
					/>
				),
			},
		];
	}
};
export { usersColumns };
