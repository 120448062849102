import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  FaRegEye,
  FaDollarSign,
  FaMousePointer,
  FaFilter,
  FaRegStickyNote,
  FaRupeeSign,
} from "react-icons/fa";
import MarketingVerticalBar from "./MarketingVerticalBar";
import MarketingVBar from "./MarketingVbar";
import { AiOutlineFieldTime } from "react-icons/ai";
import { MdCampaign } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import {
  URLS,
  getDataFromBackend,
} from "../../../modules/dashboards/DashboardRequests";
import CustomLoader from "../marketting_component/CustomLoader";
import { data } from "jquery";
import CampaignType from "../capaign_components/CampaignType";

type Props = {
  label: string;
  startDate: string;
  endDate: string;
  channelType: string;
  productId: string;
  platformId: string;
  subTypeUrl: string;
  productType: string;
};

const GroupedVbarMarketing: React.FC<Props> = ({
  startDate,
  endDate,
  label,
  channelType,
  productId,
  platformId,
  subTypeUrl,
  productType,
}: Props) => {
  const [selectedName, setSelectedName] = useState<string>("All");
  const [filteredData, setFilteredData] = useState<any>([]);
  const [loader, setLoader] = useState(true);

  const [datas, setDatas] = useState<
    {
      campaignType: string;
      data: {
        title: string;
        displayTextShort: string;
        displayText: string;
        data: {
          campaignName: string;
          value: number;
          baseLine?: any;
          percentageChangeBaseLine?: any;
          profitOrLoss?: any;
        }[];
      }[];
    }[]
  >([]);

  useEffect(() => {
    const getData = async () => {
      let requestData = {
        startDate,
        endDate,
        platformId,
        productId,
        productType,
      };
      let response = await getDataFromBackend(
        requestData,
        URLS.BASE_URL + channelType + subTypeUrl
      );
      console.log(
        "vbarresponse",
        URLS.BASE_URL + channelType + subTypeUrl,
        response?.data
      );

      let data = response?.data;

      // Extract unique campaign names from all data arrays
      const allCampaignNames = Array.from(
        new Set(
          data?.flatMap((item) =>
            item.data.flatMap((subItem) =>
              subItem.data.map((dataItem) => dataItem.campaignName)
            )
          )
        )
      );

      // Update data arrays to ensure all campaign names are present
      const updatedInput = data?.map((item) => ({
        ...item,
        data: item.data.map((subItem) => ({
          ...subItem,
          data: allCampaignNames.map((campaignName) => {
            const existingData = subItem.data.find(
              (dataItem) => dataItem.campaignName === campaignName
            );
            return existingData || { campaignName, value: 0 };
          }),
        })),
      }));
      console.log("updatedInput", updatedInput);

      setDatas(updatedInput);
      setLoader(false);
    };

    getData(); // run it, run it

    return () => {
      // this now gets called when the component unmounts
    };
  }, [startDate, endDate, productId, platformId, productType]);

  const [vbardropdata, setVbardropdata] = useState<string[]>([]);

  useEffect(() => {
    console.log(selectedName, "name");
    if (datas?.length > 0) {
      const vbardropdataop = datas.map((item) => item.campaignType);
      setVbardropdata([...vbardropdataop]);
      const selectedData = datas?.filter(
        (item) => item?.campaignType === selectedName
      );
      const modifiedData = selectedData?.map((item) => {
        item.data.forEach((subItem) => {
          subItem.data.forEach((campaign) => {
            const value = campaign.value;
            const baseLine = campaign.baseLine;

            if (baseLine !== 0) {
              const percentageChange = Number(
                (((value - baseLine) / baseLine) * 100).toFixed(2)
              );
              const formattedPercentage = isFinite(percentageChange)
                ? percentageChange >= 0
                  ? `+${percentageChange.toFixed(2)}`
                  : percentageChange.toFixed(2)
                : "NA";

              campaign.percentageChangeBaseLine = formattedPercentage;
              campaign.profitOrLoss = percentageChange < 0 ? "Loss" : "Profit";
            } else {
              campaign.percentageChangeBaseLine =
                baseLine === 0 ? "Infinity" : "NaN";
              campaign.profitOrLoss = "N/A";
            }
          });
        });
        return item;
      });

      // Replace "Infinity" with "NA" in the modified data
      modifiedData.forEach((item) => {
        item.data.forEach((subItem) => {
          subItem.data.forEach((campaign) => {
            if (campaign.percentageChangeBaseLine === "Infinity") {
              campaign.percentageChangeBaseLine = "NA";
            }
          });
        });
      });
      setFilteredData(modifiedData);
      console.log("selectedData 1", modifiedData);
    }
  }, [datas, selectedName]);

  // const vbardropdata[] = vbardropdatas[]
  console.log(vbardropdata, "dropp");

  return (
    <div
      className="card h-lg-100 p-5"
      style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
    >
      <div className="d-flex justify-content-between align-items-center mb-5">
        <div className="fw-bold text-dark fs-4 ">{label}</div>

        {channelType === "platform" && (
          <div
            className="mr-3"
            style={{ position: "relative", marginRight: "20px" }}
          >
            <div className="dropdown">
              <div
                data-kt-daterangepicker="true"
                data-kt-daterangepicker-opens="left"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary d-flex align-items-center px-0 dropdown-toggle"
              >
                {selectedName || "All"}
              </div>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownMenuButton1"
              >
                {vbardropdata.map((data) => (
                  <li
                    key={data}
                    onClick={() => {
                      setSelectedName(data);
                      console.log(data, "dddhh");
                    }}
                  >
                    <span className="dropdown-item text-hover-primary">
                      {data}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      {!loader ? (
        <div>
          {filteredData?.map((entry, index) => {
            const isLastIndex = index === filteredData.length - 1;
            return (
              <div key={index}>
                {entry?.data?.map((item, subIndex) => (
                  <MarketingVBar
                    key={subIndex}
                    startDate={startDate}
                    endDate={endDate}
                    xAxisConfig={{
                      visible:
                        isLastIndex && subIndex === entry?.data?.length - 1,
                    }}
                    graphdata={item.data}
                    title={
                      channelType === "marketing" ? item.metric : item.title
                    }
                    detailhead={"Graph Details "}
                    shortdetail={item.displayTextShort || item?.displayText}
                    longdetail={item.displayText}
                    channelType={channelType}
                    subTypeUrl={subTypeUrl}
                  />
                ))}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="py-5 d-flex align-items-center justify-content-center">
          <CustomLoader />
        </div>
      )}
    </div>
  );
};

export default GroupedVbarMarketing;
